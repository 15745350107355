import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {makeStyles} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Chip from "@mui/material/Chip";
import deleteImageX from "assets/images/remove_icon_x.png";

const useStyles = makeStyles(theme => ({

  label: {
    color: "var(--Dark-text, #35323C)", 
    fontFamily: "Rubik",
    fontSize:14, 
    fontStyle: "normal", 
    fontWeight:400,
    lineHeight: "140%"
  }
  
}));


const ACCCustomChip = (props) => {
  // expected props
  // props.content
  // props.deleteVocab
  // props.clicked
  // props.element (used with delete)
  // props.customStyle (optional)
  // props.customTextClassName (optional)
  
  const classes = useStyles();

  return (
    <Fragment>
      {
          props.content &&
          <Chip style={!props.customStyle ? {
            justifyContent: "center",
            alignItems: "center",
            color: 'black',
            margin: 2,
            marginBottom: '5px',
            padding: '3px',
            borderRadius: 16,
            border: "1px solid rgba(160, 67, 159, 0.50)",
            background: "var(--White, #FFF)" }
          : props.customStyle} 
                label={<span className={props.customTextClassName ? props.customTextClassName : classes.label}> { props.content } </span>} 
                variant="outlined" onDelete={props.element && props.deleteVocab ? () => props.deleteVocab(props.element) : null} deleteIcon={<img src={deleteImageX}/> } onClick={props.clicked}
          />
      }
    </Fragment>
  );
};

ACCCustomChip.propTypes = {};

const mapStateToProps = (state) => {
  return {
    organization: state.organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCCustomChip)