import React, {useEffect, useState} from "react";

import {connect} from "react-redux";
import {
  Grid,
  makeStyles,
} from "@material-ui/core";

import {history} from "../../../_state/helpers";
import PreviewCourseGroupCard from "./Cards/PreviewCourseGroupCard";
import CreateNewCourseGroupCard from "./Cards/CreateNewCourseGroupCard";


const useStyles = makeStyles(theme => ({
  newCourseIcon: {
    height: '50px',
    width: '50px',
    margin: 'auto auto'
  },
}));


const CreatorCourseGroupComponent = (props) => {
  const classes = useStyles();

  const handleOpenCourseGroup = (courseGroupId) => {
    history.push("/creator/course/group/" + courseGroupId);
  }

  const isNewlyCreatedCourseGroup = (coursegroup) => {
    // if new, move to first in the course group list
    return coursegroup.id === props.newlyCreatedCourseGroupId ? "-1" : coursegroup.name
  }
 
  return (
      <div>
        <div style={{
          marginTop: '20px',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 273px)',
          alignContent: 'space-evenly',
          justifyItems: 'center',
          }}
              >
          {/*<Grid item xs key={"new"} justify="left">*/}
            <CreateNewCourseGroupCard
              // slideDirection={'right'}
              onCreateNewCourseGroup={props.onCreateNewCourseGroup}
            />
          {/*</Grid>*/}
          {
            props.courseGroups.sort((a, b) => isNewlyCreatedCourseGroup(a).localeCompare(b.name)).map((courseGroup, index) =>
              // <Grid item xs key={courseGroup.id} justify="left">
                <PreviewCourseGroupCard
                  courseGroup={courseGroup}
                  index={index}
                  // slideDirection={'right'}
                  openCourseGroup={handleOpenCourseGroup}
                />
              // </Grid>
            )
          }
        {/*</Grid>*/}
        </div>
      </div>
  );
}

function mapStateToProps(state) {
  const {organization, metadata} = state;
  return {
    organization,
    metadata
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatorCourseGroupComponent)