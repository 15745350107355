import { asyncRequest } from './asyncRequest'

export const authConstants = {
    REGISTER: new asyncRequest('USERS_REGISTER'),
    LOGIN: new asyncRequest('USERS_LOGIN'),
    LOGOUT: new asyncRequest('USERS_LOGOUT'),
    FORGOT_PASSWORD_PIN: new asyncRequest('FORGOT_PASSWORD_PIN'),
    UPDATE_FORGOT_PASSWORD: new asyncRequest('UPDATE_FORGOT_PASSWORD'),
    START_OVER: new asyncRequest('START_OVER'),
    CLEAR_ERRORS: new asyncRequest('CLEAR_ERRORS'),
    TEACHER_DEBUG: new asyncRequest('TEACHER_DEBUG'),
};

export const authActions = {
    login(username, password) {
        return {
            type: authConstants.LOGIN.REQUEST,
            payload: {
                email : username,
                password,
                userDevice: {
                    "DeviceGuid": "Test",
                    "AppVersion": "1.0"
                }
            }
        }
    },

    teacherDebug(teacherDebug) {
        return {
            type: authConstants.TEACHER_DEBUG.REQUEST,
            payload: {
                teacherDebug: teacherDebug
            }
        }
    },

    register(username, password, l1Id, l2Id, allowEmailNotifications) {
        return {
            type: authConstants.REGISTER.REQUEST,
            payload: {
                email : username,
                newPassword: password,
                languageFrom: l1Id,
                languageTo: l2Id,
                allowedToSendEmailNotifications: allowEmailNotifications,
                userDevice: {
                    "DeviceGuid": "Admin panel",
                    "AppVersion": "1.0"
                }
            }
        }
    },

    forgotPasswordPin(email) {
        return {
            type: authConstants.FORGOT_PASSWORD_PIN.REQUEST,
            payload: {
              email
              
            }
        }
    },
    updateForgotPassword(email, pin, newPassword) {
        return {
            type: authConstants.UPDATE_FORGOT_PASSWORD.REQUEST,
            payload: {
              email,
              pin,
              newPassword
              
            }
        }
    },
    startOver() {
        return {
            type: authConstants.START_OVER.REQUEST,
            payload: {
            }
        }
    },
    clearErrors() {
        return {
            type: authConstants.CLEAR_ERRORS.REQUEST,
            payload: {
            }
        }
    },
    logout() {
        return {
            type: authConstants.LOGOUT.REQUEST,
        }
    }
};


