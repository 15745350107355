import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {makeStyles, Typography} from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {CapeeshColors} from "../../../../assets/ColorPalette";
import {organizationActions} from "../../../../_state/actions";
import {history} from "../../../../_state/helpers";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
    titleText: {
        color: CapeeshColors.DarkText,
        fontSize: 24,
        fontFamily: 'Rubik',
        fontWeight: '600',
        wordWrap: 'break-word',
    },
    infoText: {
        color: CapeeshColors.DarkText,
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        letterSpacing: 0.25,
        wordWrap: 'break-word',
    },
    orgDivStyle: {
        marginBottom: 16,
        borderRadius: 8,
        border: '3px #C7C7CC solid',
        cursor: "pointer",
        '&:hover': {
            border: '3px #8E8E93 solid',
        },
    },
    orgDivStyleChosen: {
        marginBottom: 16,
        borderRadius: 8,
        border: '3px #44A6EF solid',
        cursor: "pointer",
        '&:hover': {
            border: '3px #8E8E93 solid',
        },
    },
    confirmButton: {
        borderRadius: 8, 
        width: 151, 
        height: 40,
        justifyContent: "center",
        display: "flex", 
        background: CapeeshColors.DeepBlue,
        marginLeft: 16,
        alignItems: "center", 
        cursor: "pointer",
        '&:hover': {
            background: CapeeshColors.BlueBright
        },
    },
    cancelButton: {
        borderRadius: 8,
        width: 83, 
        height: 38,
        border: '2px #003C58 solid', 
        justifyContent: "center", 
        display: "flex", 
        alignItems: "center",
        cursor: "pointer",
        '&:hover': {
            border: '2px #5AB4F1 solid',
        },
    }
}));

const OrganizationChangeDialog = (props) => {
    const {} = props;
    const classes = useStyles();

    const [myOrganizations, setMyOrganizations] = useState([])
    const [tempOrg, setTempOrg] = useState(null)
    const [searchToken, setSearchToken] = useState("");
    const [filteredOrganizations, setFilteredOrganizations] = useState([]);
    
    const filterSearch = (eventSearchToken) => {
        setSearchToken(eventSearchToken);
        let sortedFilteredOrganizations = getFilteredOrganizations(eventSearchToken, myOrganizations);
        setFilteredOrganizations(sortedFilteredOrganizations);
    }
    
    const getFilteredOrganizations = (searchToken, searchOrganizations) => {
        let lowercaseSearchToken = searchToken.toLowerCase()
        let tempFilteredOrganizations = searchOrganizations?.filter(organization => {
            return organization.name.toLowerCase().includes(lowercaseSearchToken)
        })

        return tempFilteredOrganizations.sort((a, b) => a.name.localeCompare(b.name, "no"));
    }

    const setOrgAndCloseDialog = (org) => {
        props.chooseOrg(org);
        closeDialog();
    }
    
    const closeDialog = () => {
        props.closeOrgModal();
        setTempOrg(null);
    }
    
    useEffect(() => {
        setMyOrganizations(props.organization.myOrganizations)
        let sortedFilteredOrganizations = getFilteredOrganizations(searchToken, props.organization.myOrganizations);
        setFilteredOrganizations(sortedFilteredOrganizations);
    }, [props.organization.myOrganizations])
    
    return (
        <div>
            <Dialog  maxWidth={'md'} open={true} onClose={() => closeDialog()}>
                <div style={{ width: '856px', padding: '16px'}}>
                    <div style={{ display: "flex", width: '100%', flexDirection: 'row-reverse', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton onClick={() => closeDialog()} style={{ justifyContent: 'flex-end', display: "flex" }}>
                            <CancelOutlinedIcon style={{fill: "black", width: 24, height: 24}} size="medium"/>
                        </IconButton>
                    </div>
                    <DialogTitle>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "row" }}>
                            <Typography className={classes.titleText}>
                                <FormattedMessage id="dashboard.switch.org.modal.title" defaultMessage="Select organisation to view"/>
                            </Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", marginTop: 16, marginBottom: 16 }}>
                            <Typography className={classes.infoText}>
                                <FormattedMessage id="dashboard.switch.org.modal.description" defaultMessage="You have more than one organzation assigned to you. Here you can select a different organization to view and switch to it."/>
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {
                            props.organization.myOrganizations.length > 4 &&
                            <TextField
                                id="search"
                                label="Filter Organizations"
                                fullWidth
                                variant={"filled"}
                                value={searchToken}
                                onChange={event => filterSearch(event.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />   
                        }
                        <div style={{ marginTop: 32 }}>
                            {
                                filteredOrganizations.map((org, index) => 
                                    <div key={index} className={(org.id === tempOrg) || (tempOrg === null && org.id === props.organization.selectedOrganization) ? classes.orgDivStyleChosen : classes.orgDivStyle} onClick={() => setTempOrg(org.id)}>
                                        <Typography style={{ color: 'black', fontSize: 16, fontFamily: 'Rubik', fontWeight: '400px', wordWrap: 'break-word', padding: '8px 16px 8px 16px' }}>
                                            {org.name}
                                        </Typography>
                                    </div>
                                )
                            }
                        </div>
                        {
                            tempOrg !== null &&
                            <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 36 }}>
                                <div className={classes.confirmButton} onClick={() => setOrgAndCloseDialog(tempOrg)}>
                                    <Typography style={{ color: "white", fontSize: 16, fontFamily: 'Rubik', fontWeight: '400', wordWrap: 'break-word' }}>
                                        <FormattedMessage id="dashboard.switch.org.modal.okbutton" defaultMessage="Confirm change"/>
                                    </Typography>
                                </div>
                                <div className={classes.cancelButton} onClick={() => props.closeOrgModal()}>
                                    <Typography style={{ color: CapeeshColors.DeepBlue, fontSize: 16, fontFamily: 'Rubik', fontWeight: '400', wordWrap: 'break-word' }}>
                                        <FormattedMessage id="globalwords.cancel" defaultMessage="Cancel"/>
                                    </Typography>
                                </div>
                            </div>
                        }
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
};

OrganizationChangeDialog.propTypes = {};

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationChangeDialog)