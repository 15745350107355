import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import CourseViewTopicList from "../CourseView/CourseViewTopicList";
import {GlobalEvents} from "../../assets/GlobalEvents";
import {GlobalLessonPreviewContentSides} from "../../assets/GlobalLessonPreviewContentSides";

const useStyles = makeStyles((theme) => ({
}));

const CourseDialogWithLessonPreview = ({ isOpen, onClose, title, openCourseGroup, dispatch }) => {
    const classes = useStyles();

    const courseOverviewStyle = {    
        backgroundColor: 'white',
        borderRadius: '4px',
        overflow: 'hidden',
        maxWidth: 1000,
        width: 800,
        height: '80vh',
        minHeight: "fit-content",
        overflowY: 'auto', 
    }
    
    const handleLessonPreview = (customLessonId) => {
        document.dispatchEvent(new CustomEvent("initLessonPreviewWithInfo",
            { detail: { customLessonId: customLessonId, l1Id: openCourseGroup.l1Id, nodeId: null, clickableSkipButton: true, l2Id: openCourseGroup.l2Id }}));

    }
    
    const courseOverview = <div style={courseOverviewStyle}>
                                        <CourseViewTopicList
                                            courseGroup={openCourseGroup}
                                            courseGroupProgression={null}
                                            dispatch={dispatch}
                                            onPreviewLesson={handleLessonPreview}/>
                                    </div>

    useEffect(() => {
        
        document.dispatchEvent(GlobalEvents.UnityLessonPreviewHideShowToggle);
        document.dispatchEvent(new CustomEvent("setGlobalLessonPreviewDialogContent",
            {detail: { content: courseOverview,
                    contentPlacement: GlobalLessonPreviewContentSides.left}}));
        
        document.addEventListener("unityLessonPreviewOnHide", () => {
            onClose();
        })
        
        return() => {
            document.removeEventListener("unityLessonPreviewOnHide", () => {
                onClose();
            })
        }
    }, []);

    return null;
};

export default CourseDialogWithLessonPreview;
