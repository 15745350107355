import React, {useState, useRef} from 'react';
import {
  Typography,
  Slide,
  Fade,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  Search,
  Edit,
} from '@material-ui/icons';
import Grid from '@mui/material/Grid';

import {connect} from 'react-redux';
import moment from "moment/moment";
import Card from "@material-ui/core/Card";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ContentEditable from 'react-contenteditable';
import { Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  cardContent: {
    flex: 'auto',
  },
  topicCard: {
    display: 'flex',
    padding: '20px',
    boxShadow: 'none',
  },
  topicCardEnabled: {
    display: 'flex',
    boxShadow: 'none',

    //transition: 'all .25s ease',
    marginTop: '10px',
    //cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
    },
    //padding: '15px 10px',
    //paddingLeft: '10px',
    gap: '5px',
    height: '152px',
    background: '#EFEFF4',
    borderRadius: '5px 5px 5px 5px',
    width: '100%',


  },
  topicCard2: {
    padding: '2px 5px 5px 5px',

    
    //background: '#EFEFF4',
    background: '#FFF',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: 16,
    border: '1px #A0439F solid'

  },
  topicCardAction: {
    right: '15px',
    height: 'fit-content',
    margin: 'auto',
  },
}));

const ACCSectionCard = (props) => {
  
  // expected props
  // props.topicDto
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState(false);
  const [focusedAttribute, setFocusedAttribute] = useState(null);
  const focusedAttributeRef = useRef();
  focusedAttributeRef.current = focusedAttribute;
  
  const [focusedConceptId, setFocusedConceptId] = useState(null);
  const focusedConceptIdRef = useRef();
  focusedConceptIdRef.current = focusedConceptId;

  const [focusedText, setFocusedText] = useState("");
  const focusedTextRef = useRef();
  focusedTextRef.current = focusedText;


  const generateLessonsFromSection = () => {    
    props.accSuggestLessonPlanForSection(props.sectionDto)
  }

  const handleTextChange = (event) => {
    setFocusedText(event.target.value)
  }

  const handleTextFocus = (focusedId, text, attribute, conceptId = null) => {
    setIsFocus(focusedId)
    setFocusedAttribute(attribute)
    setFocusedConceptId(conceptId)
    setFocusedText(text)
  }

  const handleTextBlur = (event) => {
    setIsFocus(false)
    props.onLocalUpdateSuggestSectionsReturnDto(props.sectionDto.sectionId, focusedAttributeRef.current, focusedTextRef.current, focusedConceptIdRef.current)
    }

    const handleEditConcept = (e,  item) => {
      props.onEditConcept(item.conceptId)

    }
    

  return (
    <React.Fragment>
      <Grid container>
        <Grid xs={12} style={{marginLeft: '15px', marginTop: '15px', marginRight: '15px' }}> 
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{color: '#000',fontFamily: 'Rubik', fontSize: '18px', fontStyle: 'normal', fontWeight: '400', lineHeight: '19px', letterSpacing: '0.41px', marginBottom: '10px'}}>
              <ContentEditable
                html={isFocus === props.sectionDto.sectionId ? focusedText: props.sectionDto.title}
                onChange={handleTextChange}
                onFocus={() => handleTextFocus(props.sectionDto.sectionId, props.sectionDto.title, "title")}
                onBlur={handleTextBlur}
                tagName="span"
              />
            </Typography>
          </Grid>
        </Grid>

          <Grid container>
            {
                  <Droppable droppableId={"list"+props.sectionDto.sectionId}>
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {props.sectionDto?.concepts.map((item, index) => (
                        <Draggable key={item.conceptId} draggableId={item.conceptId} index={index}>
                          {(provided, snapshot) => (
                             <React.Fragment>
                             <div
                               ref={provided.innerRef}
                               {...provided.draggableProps}
                               {...provided.dragHandleProps}
                               style={{
                                ...provided.draggableProps.style,
                                 border: '1px #A0439F solid',
                                 borderRadius: 16,
                                //transition: snapshot.isDropAnimating ? "opacity 0.9s ease" : "",
                                opacity: snapshot.isDropAnimating ? 0.3 : 1,
                                 marginBottom: 10
                              }}
                               //style={snapshot.isDragging ? { ...provided.draggableProps.style} : provided.draggableProps.style}
                             >
                                                      <div className={classes.topicCard2} style={{border: snapshot.isDragging ? '2px dashed #000': 'none'}}>
                   
                             <Typography onClick={(e) => handleEditConcept(e, item)} style={{display: 'flex', justifyContent: 'center', color: '#35323C', fontFamily: 'Nunito', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '23.789px', paddingLeft: 4, paddingRight: 4}}>
                                {item.text}
                                {props.autoCourseCreation.creationStage === 2 &&
                                <span style={{marginLeft: 'auto'}}>
                                  <EditIcon style={{color: 'grey'}} />
                                  </span>
                                }
                              </Typography>
                              {props.compactView === false &&
                                <Typography onClick={(e) => handleEditConcept(e, item)} style={{color: 'grey', fontFamily: 'Nunito', fontSize: '16px', textAlign: "center", fontStyle: 'normal', fontWeight: 400, lineHeight: '23.789px', paddingRight: '20px'}}>
                                  {item.explanation}
                                </Typography>
                              }
                               </div>  
                             </div>
                             {snapshot.isDragging && (
                               <div>
                                                        <div className={classes.topicCard2} >
                    <Typography style={{color: '#35323C', fontFamily: 'Nunito', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '23.789px', display: "flex", justifyContent: "center"}}>
                      <ContentEditable
                        html={isFocus === item.conceptId + 'conceptText' ? focusedText: item.text}
                        onChange={handleTextChange}
                        onFocus={() => handleTextFocus(item.conceptId + "conceptText", item.text, "conceptText", item.conceptId)}
                        onBlur={handleTextBlur}
                        tagName="span"
                      />
                    
                    </Typography>
    
       
                  </div>  
                               </div>
                             )}
                           </React.Fragment>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
            }

          </Grid>
        </Grid>

      </Grid>
      </React.Fragment>
  );
};

ACCSectionCard.propTypes = {};

const mapStateToProps = (state) => {
  return {
    languages: state.metadata.metadata.languages,
    autoCourseCreation: state.autoCourseCreation
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCSectionCard)