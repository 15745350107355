import React, {useState} from "react";
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AudioLibraryType,
} from "../../../../Logic/ScenarioConstants";
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import TextFieldWithLanguageSelector from "../ScenarioComponents/TextFieldWithLanguageSelector";

const useStyles = makeStyles(theme => ({
  
}));


const InteractionListenButtonWithSlider = (props) => {

  // WARNING: made really fast for the OUP project

  // expected props
  // props.testOption
  // props.onUpdateTestOptionOnCurrentNode
  // props.canEditLesson
  // props.onAudioLibrary
  // props.testOption
  // props.l1Id
  // props.l2Id
  
  const classes = useStyles();
  
  const setInstructionText = (newText) => {

    props.testOption.text = newText;
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
  }
  
  return (
    <React.Fragment>
      {
        props.testOption &&
        <React.Fragment>
          <Grid item xs={12} sm={12} md={12} style={{marginTop: '20px'}}>
            <Typography
              variant='body1'
              style={{ 
                boxShadow: 'none',
                color: 'black',
                fontFamily: 'Lato',
                fontSize: 22,
                fontWeight: 'bold',
              }}>
              Audio transcript
            </Typography>
            <Typography
              xs={12}
              autoWidth
              variant='body1'
              style={{
                marginTop: '10px',
                fontFamily: 'Lato',
                color: 'gray',
              }}>
              Write the script that will be spoken out
            </Typography>
            <TextFieldWithLanguageSelector 
                text={props.testOption.text} 
                fullWidth={true}
                disabled={!props.canEditLesson} 
                onChange={(event) => setInstructionText(event.target.value)}
                l2Id={null}
                l1Id={null}
                soloLanguageId={props.languageId}
                canChangeLanguage={false}
            />
            
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{marginTop: '20px'}}>
            <Typography
              variant='body1'
              style={{ 
                boxShadow: 'none',
                color: 'black',
                fontFamily: 'Lato',
                fontSize: 22,
                fontWeight: 'bold',
              }}>
              Audio recording
            </Typography>
            <Typography
              xs={12}
              autoWidth
              variant='body1'
              style={{
                marginTop: '10px',
                fontFamily: 'Lato',
                color: 'gray',
              }}>
              Add a voice recording of the script. If none is added, an automated voice will be generated.
            </Typography>
            </Grid>

          {
            props.onAudioLibrary(AudioLibraryType.TestOptionVoice, 0)
          }
          
        </React.Fragment>
      }
    </React.Fragment>

  );
};

InteractionListenButtonWithSlider.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
    organization: state.organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(InteractionListenButtonWithSlider)