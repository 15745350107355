import React, {Fragment, useCallback, useEffect} from "react";
import {connect} from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Dictaphone = (props) => {
    
    const {
        transcript,
        listening,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
        resetTranscript,
        finalTranscript,
        interimTranscript
    } = useSpeechRecognition();
    
    // const listenContinuously = () => {
    //
    //     SpeechRecognition.startListening({ continuous: true, language: languageTag })
    //    
    //     let languageDto = props.metadata.metadata.languages.find(x => x.id === props.lesson.speechRecognitionL2);
    //     if (!languageDto) {
    //         console.log("failed to set language")
    //
    //     } else {
    //         console.log("listening with language " + languageDto.languageTag)
    //         SpeechRecognition.startListening({ continuous: true, language: languageDto.languageTag })
    //     }
    // };
    //
    const handleStartVoice = (l2Id) => {
        resetTranscript();
        let languageDto = props.metadata.metadata?.languages?.find(x => x.id === parseInt(l2Id));
        let languageTag = "en-GB";
        if (languageDto) {
            languageTag = languageDto.languageTag;
        }
        
        console.log("Setting speech recog from handleStartVoice" + l2Id + " tag : " + languageTag)
        SpeechRecognition.startListening({ continuous: true, language: languageTag })
    };

    const handleStopVoice = () => {
        SpeechRecognition.abortListening();
        resetTranscript();
        
    };

    const handleReturnVoiceToUnity = (text) => {
        props.sendMessage('WebBasedSpeechController', 'TranscribeUpdate', text);
    };
    
    const handleSpeech = (l2Id) => {
        console.log("Handle speech called with " + l2Id)
        handleStartVoice(l2Id);
    };

    const handleSpeechOff = (() => {
        handleStopVoice();
    });
    
    useEffect(() => {
        if(finalTranscript !== ""){
            handleReturnVoiceToUnity(finalTranscript);
            resetTranscript();
        }
    }, [interimTranscript, finalTranscript]);

    useEffect(() => {
        if(props.unityIsLoaded === false){
            handleStopVoice();

            props.removeEventListener("StartVoiceWithL2Id", handleSpeech);
            props.removeEventListener("StopVoice", handleSpeechOff);
        }
        else{
            props.addEventListener("StartVoiceWithL2Id", handleSpeech);
            props.addEventListener("StopVoice", handleSpeechOff);
        }
    }, [props.unityIsLoaded]);

    if (!browserSupportsSpeechRecognition) {
        return <span>Speech recognition not supported in this browser</span>
    }

    if (!isMicrophoneAvailable) {
        return <span>Please allow access to the microphone</span>
    }
    
    return (
        
        <Fragment></Fragment>
        
    );
};

function mapStateToProps(state) {
    const {metadata, lesson} = state;
    return {
        metadata,
        lesson
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Dictaphone)
