import React, {useState} from "react";
import {
  Button,
  Grid, MenuItem, Select,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MultiChoiceNodeQuestion from "./MultiChoiceNodeQuestion"
import {
  AudioFieldType,
  AudioSelectionOptionType,
  ScenarioAudioPlayType,
  ScenarioInteractiveElementType,
  ScenarioNodeType
} from "../../../../Logic/ScenarioConstants";
import AudioPlayer from "../ScenarioComponents/AudioPlayer";
import UploadFile from "../ScenarioComponents/UploadFile";
import AudioRecorder from "../ScenarioComponents/AudioRecorder";
import AudioLibrary from "../Audio/AudioLibrary";
import BackgroundImageSelection from "../Images/BackgroundImageSelection";
import InteractionImage from "../Interactions/InteractionImage";

const useStyles = makeStyles(theme => ({
  textFields: {
    width: '380px',
    textColor: 'white'
  },
  nodeTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '22px',
    paddingBottom: '8px',
    color: '#35323C'
  },
  scenario: {
    paddingTop: '30px'
  },
  scenarioDescriptionText: {
    color: '#8e8e93',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  activeVoiceOverButton: {
    backgroundColor: '#143349',
    fontColor: 'white',
    color: 'white',
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '150px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inactiveVoiceOverButton: {
    backgroundColor: 'white',
    fontColor: 'black',
    color: 'black',
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '150px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  }
  
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging && "lightgreen",
  ...draggableStyle
});

const MultiChoiceNodeModal = (props) => {
  const classes = useStyles();
  const [shouldMinimise, setShouldMinimise] = useState(false);
  const [buttonSelected, setButtonSelected] = useState(0);
  const [isBlocked, setIsBlocked] = React.useState(true);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder(
      props.node.testOptions,
      result.source.index,
      result.destination.index
    );
    props.onReorderTestOptions(itemsNew)
  }

  const handleOnNodeInteractionScoringChanged = (testOptionIndex, shouldBeScored) => {
    if (!props.canEditLesson) {
      return;
    }
    
    props.handleOnNodeInteractionScoringChanged(testOptionIndex, shouldBeScored)
  }

  const handleAudioLibraryClick = (urlType, apiUrl) => {
    props.onAudioLibraryClick(AudioFieldType.TestOption, apiUrl)
  }

  const isHumanVoiceAdded = () => {
    return (props.node.testOptions[0]?.textAudioUrl?.includes('snippet') === false)
  }

  const recordModeSelected = (file, testOptionId) => {
    setButtonSelected(AudioSelectionOptionType.RecordAudio)
    if (isBlocked) {
      navigator.getUserMedia({audio: true},
        () => {
          //console.log('Permission Granted');
          setIsBlocked(false)
        },
        () => {
          //console.log('Permission Denied');
          setIsBlocked(true)
        },
      );
    }
  }

  const multiChoiceIntroduction = () => {
    return (
      <React.Fragment>
      <Grid item xs={12} sm={12} md={12} style={{marginTop: '80px'}}>
        <Typography 
          variant='h6'  
          style={{
            boxShadow: 'none',
            color: 'black',
            fontFamily: 'Lato',
            fontSize: 32,
            fontWeight: 'bold'
          }}>
          Quiz
        </Typography>
      <Typography
        style={{
          boxShadow: 'none',
          letterSpacing: '0.02em',
          fontSize: 14,
          color: '#8E8E93',
          fontFamily: 'Lato',
        }}>
        Write questions and add multiple choice answers for your learners to choose between. You can add as many questions as you wish.
      </Typography>
      <Grid item xs={12} sm={12} md={12} style={{marginTop: '20px'}}>
      <Typography 
                variant='h6'  
                style={{
                  boxShadow: 'none',
                  color: 'black',
                  fontFamily: 'Lato',
                  fontSize: 22,
                  fontWeight: 'bold'
                }}>
        Audio dialogues
      </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} style={{marginTop: '0px'}}>
      <Typography
        style={{
          boxShadow: 'none',
          letterSpacing: '0.02em',
          fontSize: 14,
          color: '#8E8E93',
          fontFamily: 'Lato',
        }}>

      If you wish, you can add an audio dialogue for the learner to listen to.
      </Typography>
      </Grid>
      <Grid item xs={12} style={{marginTop: '10px'}}>

      <Typography
        style={{
          boxShadow: 'none',
          letterSpacing: '0.02em',
          fontSize: 14,
          color: '#8E8E93',
          fontFamily: 'Lato',
        }}>

       With an audio dialogue added, you can base the quiz questions on the dialogue.
      </Typography>
      </Grid>

    </Grid>

      </React.Fragment>
    )
  }
  
  const multiChoiceSelectorDropdown = () => {
    return (
      <Grid item xs={12} sm={12} md={12}  style={{paddingRight: '10px', marginTop: '10px', marginBottom: props.node.scenarioNodeType === ScenarioNodeType.ListenMultiChoice ? '0px': '20px' }}>
      <Select
        xs={12}
        className={classes.nodeTypeSelectorStyle}
        disableUnderline
        value={props.node.scenarioNodeType}
        autoWidth
        onChange={(e) => props.handleMultiChoiceDropdownSelectorChanged(e.target.value)}
      >
        <MenuItem 
          disabled={!props.canEditLesson} 
          value={ScenarioNodeType.MultiChoice}>Don't add voice dialogue
        </MenuItem>
        <MenuItem 
          disabled={!props.canEditLesson} 
          value={ScenarioNodeType.ListenMultiChoice}>Add voice dialogue
        </MenuItem>
      </Select>
    </Grid>
    )
  }
  
  const multiChoiceAudioExplanation = () => {
    return (
    <Grid item xs={12} style={{marginTop: '0px'}}>

    <Typography
      style={{
        boxShadow: 'none',
        letterSpacing: '0.02em',
        fontSize: 14,
        color: '#8E8E93',
        fontFamily: 'Lato',
      }}>

     Upload your voice dialogue below!
    </Typography>
    </Grid>
    )
  }
  
  const multiChoiceAudio = () => {
    return (
      props.node.testOptions &&
      props.node.testOptions.length > 0 &&
      props.node.testOptions[0].audioPlayType !== ScenarioAudioPlayType.DontPlay &&
      <Grid container>
        <React.Fragment>
          <Grid container direction="row" justifyContent="center" alignItems="center"
                style={{marginTop: '10px', }}>
            {
              !!!isHumanVoiceAdded() &&
              <React.Fragment>
                <Grid item xs={4} sm={4} md={4}
                      style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
                  <Button 
                    onClick={() => setButtonSelected(AudioSelectionOptionType.UploadAudio)} 
                    disable={!props.canEditLesson}
                    className={buttonSelected === AudioSelectionOptionType.UploadAudio ? classes.activeVoiceOverButton : classes.inactiveVoiceOverButton}
                    variant='outlined' style={{ textTransform: 'none'}}>
                    Upload audio
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4} md={4}
                      style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
                  <Button style={{ textTransform: 'none'}} onClick={() => recordModeSelected()} className={buttonSelected === AudioSelectionOptionType.RecordAudio ? classes.activeVoiceOverButton : classes.inactiveVoiceOverButton} variant='outlined'>
                    Record audio
                  </Button>
                </Grid>

                <Grid item xs={4} sm={4} md={4}
                      style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
                  <Button
                    onClick={() => setButtonSelected(AudioSelectionOptionType.LibraryAudio)}
                    className={buttonSelected === AudioSelectionOptionType.LibraryAudio ? classes.activeVoiceOverButton : classes.inactiveVoiceOverButton} 
                    variant='outlined' style={{ textTransform: 'none'}}>
                    Library
                  </Button>
                </Grid>
              </React.Fragment>
            }
            <Grid item xs={12} sm={12} md={12}
                  style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', minHeight: '50px'}}>
              <AudioPlayer scenarioNodeType={props.node.scenarioNodeType}
                           humanAudioAdded={isHumanVoiceAdded()} audioPlaying={props.audioPlaying}
                           isAudioDownloading={false} onPlayVoice={props.onPlayVoice}
                           onRemoveVoice={props.onRemoveVoice} textAudioUrl={props.node.testOptions[0].textAudioUrl}
                           testOptionId={props.node.testOptions[0].testOptionId}/>
              {
                buttonSelected === AudioSelectionOptionType.UploadAudio && !!!isHumanVoiceAdded() && props.canEditLesson &&
                <UploadFile onVoiceDrop={(file) => props.onVoiceDrop(file, props.node.testOptions[0].testOptionId)}/>
              }
              {
                buttonSelected === AudioSelectionOptionType.RecordAudio && !!!isHumanVoiceAdded() && props.canEditLesson &&
                <AudioRecorder 
                  scenarioNodeType={props.node.scenarioNodeType} 
                  onMicVoiceDrop={props.onMicVoiceDrop} 
                  isBlocked={isBlocked} 
                  humanAudioAdded={isHumanVoiceAdded()} 
                  audioPlaying={props.audioPlaying} 
                  isAudioDownloading={false} 
                  onPlayVoice={props.onPlayVoice}
                  onRemoveVoice={props.onRemoveVoice} 
                  textAudioUrl={props.node.testOptions[0].textAudioUrl} 
                  testOptionId={props.node.testOptions[0].testOptionId}/>
              }
              
              {
                buttonSelected === AudioSelectionOptionType.LibraryAudio && !!!isHumanVoiceAdded() && props.canEditLesson &&
                <div style={{marginTop: '10px', textAlign: 'center', alignItems: 'center',justifyContent: 'center'}}>
                  <AudioLibrary
                    isAudioDownloading={props.isAudioDownloading}
                    onPlayVoice={props.onPlayVoice}
                    audioPlaying={props.audioPlaying}
                    lesson={props.lesson}
                    onAudioLibraryClick={handleAudioLibraryClick}
                    audioFieldType={AudioFieldType.TestOption}
                    filterForVoiceOnlyAudio={true}
                  />
                </div>
              } 
            </Grid>
          </Grid>
        </React.Fragment>
      </Grid>
    )
  }

  return (
    <Grid item xs={7} sm={7} md={7} style={{minWidth: '528px'}}>
      { multiChoiceIntroduction()}
      { multiChoiceSelectorDropdown() }
      {
        props.node.scenarioNodeType === ScenarioNodeType.ListenMultiChoice &&
        multiChoiceAudioExplanation() 
      }
          {
        props.node.scenarioNodeType === ScenarioNodeType.ListenMultiChoice &&
        multiChoiceAudio() 
      }
      <BackgroundImageSelection
        canEditLesson={props.canEditLesson}
        node={props.node}
        lesson={props.lesson}
        updateImageDrop={props.updateImageDrop}
        scenarioImages={props.scenarioImages}
        onUpdatePreviewImage={props.onUpdatePreviewImage}
      />
      

      
      <Grid container>
        <Grid item xs={8} sm={8} md={8} className={classes.scenario}>
          <Typography className={classes.nodeTitle} style={{
                                   boxShadow: 'none',
                                   color: 'black',
                                   fontFamily: 'Lato',
                                   fontSize: 22,
                                   fontWeight: 'bold', marginTop: '20px'}}>
            Quiz Questions
          </Typography>
          <Typography style={{ boxShadow: 'none', letterSpacing: '0.02em', fontSize: 14, color: '#8E8E93', fontFamily: 'Lato', marginBottom: '20px' }}>
            The order of each answer box is randomised in the app.
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} className={classes.scenario}>
          <FormControlLabel control={<Switch checked={shouldMinimise}
                                             onChange={() => setShouldMinimise(!!!shouldMinimise)}/>}
                            label="Compact view"/>
        </Grid>
      </Grid>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {
                props.node.testOptions && 
                props.node.testOptions.length > 0 && 
                props.node.testOptions.map((testOption, index) => (
                  testOption.scenarioInteractiveElementType === ScenarioInteractiveElementType.MultiChoice &&
                  <Draggable 
                    key={testOption.testOptionId} 
                    draggableId={testOption.testOptionId}
                    index={index}
                    draggable={!props.canEditLesson}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <MultiChoiceNodeQuestion
                          onTestOptionTextLanguageTypeChange={props.onTestOptionTextLanguageTypeChange}
                          handleMultiChoiceQuestionDeleted={props.handleMultiChoiceQuestionDeleted}
                          handleUpdateMultiChoiceQuestionChanged={props.handleUpdateMultiChoiceQuestionChanged}
                          handleUpdateMultiChoiceAlternativeChanged={props.handleUpdateMultiChoiceAlternativeChanged}
                          languageId={props.languageId}
                          onVoiceDrop={props.onVoiceDrop}
                          onRemoveVoice={props.onRemoveVoice}
                          audioPlaying={props.audioPlaying}
                          isAudioDownloading={props.isAudioDownloading}
                          onPlayVoice={props.onPlayVoice}
                          testOption={testOption}
                          index={index}
                          node={props.node}
                          canEditLesson={props.canEditLesson}
                          shouldMinimise={shouldMinimise}
                          onNodeInteractionScoringChanged={handleOnNodeInteractionScoringChanged}
                          l1Id={props.l1Id}
                          onSetQuizQuestionInFocus={props.onSetQuizQuestionInFocus}
                        />
                      </div>
                    )}
                  </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {
        <React.Fragment>
          {
            props.node.testOptions && props.node.testOptions.length === 0 &&
            <Typography variant="body1" className={classes.scenarioDescriptionText}>
              No questions added.
            </Typography>
          }
          <Grid item xs={12} sm={12} md={12} style={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            marginTop: '20px',
            justifyContent: 'left'
          }}>
            {
              props.canEditLesson &&
              <Button onClick={() => props.onAddMultiChoiceQuestion()} style={{
                backgroundColor: '#143349',
                fontColor: 'white',
                color: 'white',
                marginRight: '5px',
              }} variant='outlined'>
                Add Question
              </Button>
            }
          </Grid>
        </React.Fragment>
      }

      {props.node?.validationErrors?.length > 0 && props.displayErrorsSection()}
      {props.saveOrCancelStage()}
    </Grid>
  );
};

MultiChoiceNodeModal.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
    organization: state.organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(MultiChoiceNodeModal)