import {connect} from "react-redux";
import {Grid, makeStyles} from "@material-ui/core";
import AutoCourseSuggestLessonDetailsLoading
  from "../../AutoCourseCreate/AutoCourseCreateContentEditMode/LessonDetailsComponents/AutoCourseSuggestLessonDetailsLoading";
import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import AutoCourseCreationLessonCard from "./AutoCourseCreationLessonCard";
import {history} from "../../../../_state/helpers";
import {CapeeshColors} from "../../../../assets/ColorPalette";
import CapeeshStyledModal from "../../../../Components/CapeeshStyledModal";
import {autoCourseCreationActions} from "../../../../_state/actions";
import {AutoCourseCreateType} from "../../../../Logic/AutoCourseCreationConstants";
import ACCLanguageLessonCard from "./ACCLanguageLessonCard";



const useStyles = makeStyles(theme => ({
  languageDropdown: {
    width: '100%',
  },
  scenariosHeader: {},
  button: {
    marginRight: '10px',
    textTransform: 'none'
  },
  scenarioCard: {
    background: "white",
    paddingBottom: '20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)'
  },
  scenarioContent: {
    marginTop: '10px',
    background: 'white',
  },
  vocabularyCard: {
    background: "#e5e5ea",
    marginBottom: '10px',
    marginTop: '10px',
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)'
  },

  editLessonButton: {
    height: '40px',
    width: '230px',
    align: 'right',
    textAlign: 'center',
  },
  publishedLanguagesText: {
    boxShadow: 'none',
    flex: 1,
    fontSize: 14,
    color: 'black',
    fontFamily: 'Lato',
    textAlign:'left',
    marginTop:'10px'
  },
  publishedInfoText: {
    boxShadow: 'none',
    flex: 1,
    fontSize: 14,
    color: 'black',
    fontFamily: 'Lato',
    textAlign:'right',
    marginTop:'10px'
  },
  cancel: {
    alignItems: 'right',
  },
  media: {
    height: '100px'
  },
  vocabularyContent: {
    background: '#e5e5ea',
    height: '40px'
  },
  draggable: {
    background: "#f8f8f8"
  },
  wordsTeaching: {
    marginTop: "5px",
    color: "#979ca1"
  },
  scenarioDescription: {
    color: "#979ca1",
    marginTop: '10px',
    marginBottom: '20px',
  },
  listHeader: {
    marginBottom: '10px'
  },
  gridScenarioQuiz: {
    paddingTop:'5px',
    paddingLeft: '1px'
  },
  divider: {
    borderBottom: "1px solid lightgray",
    width: "100%"
  },

}));


const ACCState =
{
  "NotSet": 0,
  "CreateNew": 1,
  "ContinueOnSameTopic": 2,
  "ACCFromAnotherTopic": 3
}


const AutoCourseCreationOrder = (props) => {

  const classes = useStyles();
  
  const [isFocus, setIsFocus] = useState(false);
  let counter = 0
  
  const [showAccInteraction, setShowAccInteraction] = useState(false);
  const [accState, setACCState] = useState(ACCState.NotSet);

  const [showResetACCConfirmation, setShowResetACCConfirmation] = useState(false);
  const [resetACCConfirmed, setResetACCConfirmed] = useState(false);
  const [otherTopicName, setOtherTopicName] = useState(false);
  
  const [showLessonList, setShowLessonList] = useState(false);
  
  
  
  useEffect(() => {
    if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') {
       setShowAccInteraction(true);
    }
    
    if (!props.autoCourseCreation?.authorInputMetadata) {
      setACCState(ACCState.CreateNew)
    }
    else if (props.autoCourseCreation?.authorInputMetadata.topicId === props.topicId) {
      if (props.autoCourseCreation?.suggestSectionsReturnDto?.sections?.length > 0) {
        setACCState(ACCState.ContinueOnSameTopic)
      }  else {
        setACCState(ACCState.CreateNew)
      }
    } else if (props.autoCourseCreation?.authorInputMetadata?.topicId) {
      let otherTopic = props.topic.topics.find(x => x.id == props.autoCourseCreation?.authorInputMetadata.topicId)
      if (otherTopic) {
        setOtherTopicName(otherTopic.name);
      }
      setACCState(ACCState.ACCFromAnotherTopic);
      
      
    }
    
    setShowLessonList(true);
    return () => {
      setShowLessonList(false);
      setACCState(ACCState.NotSet);
    }
    
  }, []);

  const handleClick = (lessonPlanId, index) => {
    console.log("handleClick on lessonPlan " + lessonPlanId + " index: " + index);
  }

  const getGradiantColour = (type) => {

    switch (type){
      case "Introduction":
        return "#15D0BC"
        break;
      case "Repetition":
        return "#A0439F"
        break;

      default:
        return "#FF7A3B"
    }
  }
  
  const openAutoCourseCreate = (autoCourseCreateType) => {
    props.onGoToCreateAiLanguage(autoCourseCreateType)
  };
  
  const handleLessonPlanClicked = (lessonPlanId) => {

    const queryParams = "?createFromTopic=true&topicId=" + props.topicId + "&l2Id=" + props.l2Id + "&defaultL1Id=" + props.defaultL1Id + "&selectedLessonPlanId="  + lessonPlanId
    if (props.courseGroupId && props.topicId) {
      if (props.autoCourseCreation.authorInputMetadata?.autoCourseCreateType === AutoCourseCreateType.Language) {
        let accUrl = '/creator/course/group/' + props.courseGroupId + '/topic/' + props.topicId + '/acc/language' + queryParams;
        history.push(accUrl);
      } else {
        let accUrl = '/creator/course/group/' + props.courseGroupId + '/topic/' + props.topicId + '/acc' + queryParams;
        history.push(accUrl);
      }
    }
  }
  
  const resetAutoCourseCreateFromAnotherTopicConfirmed = () => {
    setShowResetACCConfirmation(false);
    
    let prevAutoCourseCreateType = null
    if (props.autoCourseCreation.authorInputMetadata && (props.autoCourseCreation.authorInputMetadata.autoCourseCreateType === AutoCourseCreateType.Language || props.autoCourseCreation.authorInputMetadata.autoCourseCreateType === AutoCourseCreateType.Complience)) {
      prevAutoCourseCreateType = props.autoCourseCreation.authorInputMetadata.autoCourseCreateType;      
    }
    
    props.dispatch(autoCourseCreationActions.accResetAll())
    props.dispatch(autoCourseCreationActions.accResetAllLessonPlans())
    props.dispatch(autoCourseCreationActions.accResetAllSections())
    
    openAutoCourseCreate(prevAutoCourseCreateType)
  }
  
  
  return (
    <div style={{borderRadius: '5px', padding: '10px', paddingLeft: '20px'}}>
      <CapeeshStyledModal open={showResetACCConfirmation}
                          textTitle={"Do you want to remove the AI work from the other topic and start again?"}
                          textLine1={"Currently it is only possible to keep the state from one AI interaction at one point. To work with AI in this topic the context needs to be reset and will work for this topic."}
                          onButton1={() => setShowResetACCConfirmation(false)}
                          onButton2={() => resetAutoCourseCreateFromAnotherTopicConfirmed()}
                          button1Variant={'outlined'}
                          button1Text={"Cancel"}
                          button2Text={"Yes"}
                          onClose={() => setShowResetACCConfirmation(false)}
      />
      
      <Grid container style={{height: 'calc(100vh - 189px)', overflowY:'auto', alignContent: "flex-start"}}>
        <Grid item xs={12}>
          <Typography variant='h4'style={{ display: 'inline-block', color: CapeeshColors.DeepBlue, marginTop: '20px'}}>
            Create with AI
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
            <Grid item>
              <Typography style={{fontFamily: 'Rubik',fontStyle: 'normal',fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#8E8E93', marginTop: '3px', marginBottom: '10px'}}>
                Create lessons in minutes. When happy, you can transfer the AI lessons to your lesson list to the right.  
              </Typography>
              {
                  showAccInteraction &&
                  <React.Fragment>
           
                      <React.Fragment>
                        <Button
                            className={classes.button}
                            style={{background: CapeeshColors.GreenBright, textTransform: 'none',}}
                            variant='contained'
                            onClick={() => props.onGoToCreateAiComplience()}                        >
                          Workplace Lessons
                        </Button>

                        <Button
                            className={classes.button}
                          style={{marginLeft: '15px', background: CapeeshColors.GreenBright, textTransform: 'none',}}
                          variant='contained'
                          onClick={() => props.onGoToCreateAiLanguage()}                           >
                          Language Lessons
                        </Button>
                      </React.Fragment>

                    {
                        accState === ACCState.ACCFromAnotherTopic &&
                        <React.Fragment>
                          <Button
                              className={classes.button}
                              style={{background: CapeeshColors.OrangeBright, textTransform: 'none'}}
                              variant='contained'
                              onClick={() => setShowResetACCConfirmation(true)}>
                            Start again (used in {otherTopicName})
                          </Button>
                          
                          {/*<Button*/}
                          {/*  className={classes.button}*/}
                          {/*  style={{background: CapeeshColors.BlueBright, marginLeft:'25px', textTransform: 'none'}}*/}
                          {/*  variant='contained'*/}
                          {/*  onClick={() => openOtherTopic()}>*/}
                          {/*  Go to {otherTopicName}*/}
                          {/*</Button>*/}
                        </React.Fragment>
                    }
                  </React.Fragment>
              }
              
            </Grid>
            
          </Grid>
        </Grid>
        {
            showLessonList && (accState !== ACCState.ACCFromAnotherTopic && accState !== ACCState.NotSet) && props.autoCourseCreation.authorInputMetadata?.autoCourseCreateType !== AutoCourseCreateType.Language &&
            <Grid item xs={12}>
              {
                props.autoCourseCreation?.suggestSectionsReturnDto?.sections?.length > 0 &&
                props.autoCourseCreation.suggestSectionsReturnDto.sections.map((section, indexSections) => (
                  <div style={{marginBottom: '20px', position: 'relative'}}>
                    {
                      props.autoCourseCreation?.lessonPlansDtos?.map((lessonPlanDto, index) =>
                          lessonPlanDto.lessons?.map((lessonSuggestionDto, index2) => {
                            if (lessonSuggestionDto.sectionId === section.sectionId && (lessonSuggestionDto.createdLessonId?.length > 0 && !props.topicCustomLessonOrderIds.includes(lessonSuggestionDto.createdLessonId[lessonSuggestionDto.createdLessonId.length -1]))) {
                              counter++
                              return            <div>

                                <AutoCourseCreationLessonCard
                                    selectedLesson={null}
                                    onLessonPlanClicked={handleLessonPlanClicked}
                                    onClick={(e)=>handleClick(e, index2)}
                                    lessonNumber={counter}
                                    lessonSuggestionDto={lessonSuggestionDto}
                                    key={index2}
                                    isLastInTopic={lessonPlanDto.lessons.length === index2 + 1}
                                    onPreviewLesson={props.onPreviewLesson}
                                    onAddLesson={props.onAddLesson}
                                />
                              </div>
                            }
                            return null;
                          })
                      )}
                  </div>
                ))
              }
            </Grid>
        }


        {
          showLessonList && (accState !== ACCState.ACCFromAnotherTopic && accState !== ACCState.NotSet) && props.autoCourseCreation.authorInputMetadata?.autoCourseCreateType === AutoCourseCreateType.Language &&
          <Grid item xs={12}>
            {
              props.autoCourseCreation?.languageSectionPlanDto?.languageLessonPlanDtos?.length > 0 &&
              <div style={{marginBottom: '20px', position: 'relative'}}>
                {
                  props.autoCourseCreation?.languageSectionPlanDto?.languageLessonPlanDtos?.map((languageLessonPlanDto, index2) => {
                    if (languageLessonPlanDto.createdLessonIds?.length > 0 && !props.topicCustomLessonOrderIds.includes(languageLessonPlanDto.createdLessonIds[languageLessonPlanDto.createdLessonIds.length - 1])) {
                      counter++
                      return <div>
                        <ACCLanguageLessonCard
                          selectedLesson={null}
                          onLessonPlanClicked={handleLessonPlanClicked}
                          onClick={(e) => handleClick(e, index2)}
                          lessonNumber={counter}
                          lessonSuggestionDto={languageLessonPlanDto}
                          key={index2}
                          isLastInTopic={props.languageSectionPlanDto?.languageLessonPlanDtos.length === index2 + 1}
                          onPreviewLesson={props.onPreviewLesson}
                          onAddLesson={props.onAddLesson}
                        />
                      </div>
                    }
                  })
                }
              </div>
            }
          </Grid>
        }
        
        
      </Grid>
    </div>
  )
} 


function mapStateToProps(state) {
  const {autoCourseCreation, user, topic} = state;
  return {
    autoCourseCreation,
    user,
    topic
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseCreationOrder)