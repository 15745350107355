import { all, fork } from 'redux-saga/effects';
import { userSagas } from './user.sagas';
import { authSagas } from './auth.sagas';
import { organizationSagas } from './organization.sagas';
import { topicSagas } from './topic.sagas';
import { metadataSagas } from './metadata.sagas';
import { usergroupSagas } from './usergroup.sagas';
import { backgroundJobsSagas } from './backgroundJobs.sagas';
import { translatorSagas } from './translator.sagas';
import { lessonSagas } from './lesson.sagas';

import { randdSagas } from './randd.sagas';
import { courseGroupSagas } from './coursegroup.sagas';
import { overallSagas } from "./overall.sagas";
import { autoCourseCreationSagas } from './autocoursecreation.sagas';

function* rootSaga() {
    yield all ([
        userSagas(),
        authSagas(),
        organizationSagas(),
        topicSagas(),
        metadataSagas(),
        usergroupSagas(),
        backgroundJobsSagas(),
        translatorSagas(),
        lessonSagas(),
        randdSagas(),
        courseGroupSagas(),
        overallSagas(),
        autoCourseCreationSagas(),
    ])
}

export default rootSaga;