import React, {useState, useRef} from 'react';
import {Typography,makeStyles, } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import {AutoCourseCreatorQuizPlotTypes } from "../../../../../Logic/AutoCourseCreationConstants";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import AutoCourseSuggestLessonDetailsQuizItem from '../AutoCourseSuggestLessonDetailsQuizItem'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ButtonBase from '@mui/material/ButtonBase';


const useStyles = makeStyles(theme => ({
  stagePlotType: {
    display: 'inline-block', color: "#35323C",
    fontFamily: "Nunito",
    fontSize:14,
    fontStyle: "normal",
    fontWeight:400,
    letterSpacing:0.41,borderRadius:5, border: "1px solid var(--Gray-1, #8E8E93)", marginTop: '10px', padding: '5px'
  },
  stagePlotTypeSelected: {
    display: 'inline-block', color: "white",
    fontFamily: "Nunito",
    fontSize:14,
    fontStyle: "normal",
    fontWeight:400,
    letterSpacing:0.41,borderRadius:5, border: "1px solid var(--Gray-1, #8E8E93)", marginTop: '10px', padding: '5px'
    ,background: '#44A6EF'
  }
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging && "grey",
  ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};





const ACCStageSettingsQuizContaner = (props) => {
  const classes = useStyles();
  let circleStyle = {
    padding:10,
    margin:20,
    //display:"inline",
   // backgroundColor: 'green',
    borderRadius: "50%",
    width:30,
    height:30,
  };

  const [openGenericDialog, setOpenGenericDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogExistingText, setDialogExistingText] = useState("");
  const [dialogType, setDialogType] = useState(null);

  const handleCloseModal = () => {
    setOpenGenericDialog(false);
  setDialogExistingText("")
//  setNewQuestion({conceptId: "", correctAnswer: "", incorrectAnswer1: "", incorrectAnswer2: "",})
  };

  const handleDeleteQuizQuestion = () => {
    props.onDeleteQuizQuestion()
  //  setNewQuestion({conceptId: "", correctAnswer: "", incorrectAnswer1: "", incorrectAnswer2: "",})
  };

  const handleSaveModal = () => {
    setOpenGenericDialog(false);
    setDialogExistingText("")
  //  setNewQuestion({conceptId: "", correctAnswer: "", incorrectAnswer1: "", incorrectAnswer2: "",})
  };
  const handleWriteQuizQuestion = (element) => {
    setOpenGenericDialog(true)
    setDialogMessage("Write a question and the answer. Also add at least one incorrect answer.")
    setDialogTitle("Add a new quiz question")
    setDialogType("quiz")
    //setFocusedAttribute("quizQuestion")
  }

  const onDragEnd =(result) => {
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder(
      props.selectedLesson.lessonPlanConfigurationDto.quizQuestions,
      result.source.index,
      result.destination.index
    );
    props.onReorderQuiz(itemsNew)
    //tempQuizItems = itemsNew
  }

  const handleTextChangeQuizQuestion = (attribute, event) => {
   // let newQuestionId = newQuestion.questionId

    switch(attribute) {
      case "question":
        //setNewQuestion({...newQuestion, question:event.target.value})
        break;
      case "correctAnswer":
        //setNewQuestion({...newQuestion, correctAnswer: event.target.value})
        break;
      case "incorrectAnswer1":
        //setNewQuestion({...newQuestion, incorrectAnswer1: event.target.value})
        break;
      case "incorrectAnswer2":
        //setNewQuestion({...newQuestion, incorrectAnswer2: event.target.value})
        break;
      case "incorrectAnswer3":
        //setNewQuestion({...newQuestion, incorrectAnswer3: event.target.value})
        break;
    }
  }
  console.log("QWEQWE",props.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions )

  return (
    <React.Fragment>
      <Dialog open={openGenericDialog} onClose={handleCloseModal}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMessage}
          </DialogContentText>
          {dialogType === "quiz" &&
              <React.Fragment>
                <TextField label="Question" multiline defaultValue={dialogExistingText} autoFocus margin="dense" id="name" fullWidth variant="standard"
                           onChange={(e) => handleTextChangeQuizQuestion("question", e)}
                />
                <TextField label="Correct answer" multiline defaultValue={dialogExistingText} margin="dense" id="name" fullWidth variant="standard"
                           onChange={(e) => handleTextChangeQuizQuestion("correctAnswer", e)}
                />
                <TextField label="Incorrect answer 1" multiline defaultValue={dialogExistingText} margin="dense" id="name" fullWidth variant="standard"
                           onChange={(e) => handleTextChangeQuizQuestion("incorrectAnswer1", e)}
                />

                <TextField label="Incorrect answer 2" multiline defaultValue={dialogExistingText} margin="dense" id="name" fullWidth variant="standard"
                           onChange={(e) => handleTextChangeQuizQuestion("incorrectAnswer2", e)}
                />
                <TextField label="Incorrect answer 3" multiline defaultValue={dialogExistingText} margin="dense" id="name" fullWidth variant="standard"
                           onChange={(e) => handleTextChangeQuizQuestion("incorrectAnswer3", e)}
                />
              </React.Fragment>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{textTransform: 'none'}}>Cancel</Button>
          <Button onClick={handleSaveModal} style={{textTransform: 'none'}}>OK</Button>
        </DialogActions>
      </Dialog>
      
     <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
               {(provided, snapshot) => (
                 <div
                   {...provided.droppableProps}
                   ref={provided.innerRef}
                 >

                 
      {   props.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.map((question, index) => (
            
                
                         <Grid container alignItems="center">

                                     <Grid item xs={11}>
                              <AutoCourseSuggestLessonDetailsQuizItem key={question.questionId} questionNumber={index + 1} question={question.question} incorrectAnswer1={question.incorrectAnswer1} incorrectAnswer2={question.incorrectAnswer2} incorrectAnswer3={question.incorrectAnswer3} correctAnswer={question.correctAnswer}/>

                            </Grid>
                    
                            <Grid item xs={1}>
                            <ButtonBase> {/* Apply drag handling here. */}
                              <DeleteOutlineIcon onClick={() => props.onDeleteQuizQuestion(props.selectedLesson.lessonPlanId, question.questionId, )}  />
                              </ButtonBase>
                            </Grid>
                            </Grid>

                   
                   ))}
                 </div>
               )}
          </Droppable>
        </DragDropContext>
    </React.Fragment>
  );
};

ACCStageSettingsQuizContaner.propTypes = {};

const mapStateToProps = (state) => {
  return {
    languages: state.metadata.metadata.languages,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCStageSettingsQuizContaner)