import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {TextField} from "@mui/material";
import Divider from "@mui/material/Divider";
import {CapeeshColors} from "../../../../assets/ColorPalette";
import ACCCustomChip from "./Components/ACCCustomChip";
import quitImage from "assets/images/Navigation.png";
import ACCVocabSelectionChip from "./Components/ACCVocabSelectionChip";
import editImage from "assets/images/editPencil.svg";
import editCheckmark from "assets/images/editCheckmark.svg";

const useStyles = makeStyles(theme => ({
    mainDialog:
        {
            width: 635,
            padding: 16,
            background: 'white',
            borderRadius: 16,
            flexDirection: 'column',
            display: "flex",
            gap: 16,
        },
    chapterEditTitleText:
        {
            color: CapeeshColors.DarkText,
            fontSize: 20,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginBottom: 16
        },
    chapterSummaryTitleText:
        {
            color: CapeeshColors.DarkText,
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '500',
            wordWrap: 'break-word',
            marginBottom: 8,
            marginRight: 8
        },
    inputFieldChapterSummary:
        {
            width: 600,
            height: '100%',
            padding: 16,
            background: CapeeshColors.OffWhite,
            borderRadius: 8,
            overflow: 'hidden',
            border: '2px solid',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 10,
            display: 'inline-flex',
            marginBottom: 16
        },
    vocabContainer:
        {
            width: '100%',
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            marginBottom: 8
        },
    buttonContainer:
        {
            width: '100%',
            height: '100%',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            gap: 10,
            display: 'inline-flex'
        },
    cancelButton:
        {
            width: 156,
            height: 48,
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: 8,
            overflow: 'hidden',
            border: '2px rgba(96, 96, 96, 0.30) solid',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
            display: 'flex',
            marginRight: 10,
            cursor: "pointer"
        },
    saveButton:
        {
            width: 136,
            height: 48,
            paddingLeft: 16,
            paddingRight: 16,
            background: CapeeshColors.Blue,
            borderRadius: 8,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
            display: 'flex',
            cursor: "pointer",
        },
    saveButtonLocked:
        {
            width: 136,
            height: 48,
            paddingLeft: 16,
            paddingRight: 16,
            background: CapeeshColors.Gray1,
            borderRadius: 8,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
            display: 'flex'
        },
    cancelButtonText:
        {
            textAlign: 'center',
            color: CapeeshColors.DarkText,
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    saveButtonText:
        {
            textAlign: 'center',
            color: 'white',
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    topOfDialogContainer:
        {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
    label: {
        color: 'white', 
        fontSize: 14, 
        fontFamily: 'Rubik', 
        fontWeight: '300', 
        wordWrap: 'break-word'
    },
    editSelectionButton:
        {
            paddingLeft: 8, 
            paddingRight: 16,
            background: '#44A6EF',
            borderRadius: 8,
            justifyContent: 'center', 
            alignItems: 'center',
            gap: 4,
            padding: 4,
            display: 'flex',
            alignSelf: "start",
            marginBottom: 16,
            cursor: "pointer"
        },
    editSelectionButtonText:
        {
            textAlign: 'center',
            color: 'white', 
            fontSize: 16, 
            fontFamily: 'Rubik', 
            fontWeight: '400', 
            wordWrap: 'break-word'
        },
    vocabAndWarningTextContainer:
        {
            display: "flex",
            flexDirection: "row",
            marginTop: 16
        },
    vocabWarningText:{
        color: "red",    
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word'
    }
}));

const EditChapterModal = (props) => {

    // props.openDialog
    // props.isEditMode
    // props.chapterNumber
    // props.chapterSummaryText
    // props.closeModal(bool)
    // props.vocabList
    // props.allVocabsList

    const classes = useStyles();

    const [chapterSummaryTextValue, setChapterSummaryTextValue] = useState("");
    const [vocabAddSelectionModalOpen, setVocabAddSelectionModalOpen] = useState(false);
    const [finalVocabsAdded, setFinalVocabsAdded] = useState([]);

    const handleAddVocab = (vocab) => {
        let tempSelectedVocabs = [...finalVocabsAdded];
        tempSelectedVocabs.push(vocab.conceptId);
        setFinalVocabsAdded(tempSelectedVocabs);
    }
    
    const handleDeSelectVocab = (vocab) => {
        let filteredList = finalVocabsAdded.filter(item => item !== vocab.conceptId);
        setFinalVocabsAdded(filteredList);
    }

    const handleCloseAndSave = () => {
        if(finalVocabsAdded.length <= 0 || chapterSummaryTextValue.length <= 0){
            return;
        }
        
        props.handleSave(chapterSummaryTextValue, finalVocabsAdded, props.isEditMode);
        props.closeModal(false);
    }

    useEffect(() => {
        setChapterSummaryTextValue(props.chapterSummaryText ? props.chapterSummaryText : "");
        setFinalVocabsAdded([...props.vocabList]);
    }, []);

    return (
        <Fragment>
            <Dialog open={props.openDialog} onClose={() => props.closeModal(false)} maxWidth={"lg"}>
                <DialogContent className={classes.mainDialog}>
                    {
                        <Fragment>
                            <div className={classes.topOfDialogContainer}>
                                {
                                    props.isEditMode ?
                                    <Typography className={classes.chapterEditTitleText}>
                                        {
                                            "Edit chapter " + props.chapterNumber
                                        }
                                    </Typography>
                                        :
                                    <div></div>
                                }
                                <img src={quitImage} style={{ cursor: "pointer" }} onClick={() => props.closeModal(false)}/>
                            </div>
                            <Typography className={classes.chapterSummaryTitleText}>
                                Chapter summary
                            </Typography>
                            <TextField fullWidth variant="filled"
                                       label={"Write your chapter summary"}
                                       defaultValue={props.chapterSummaryText}
                                       value={chapterSummaryTextValue}
                                       onChange={(event) => setChapterSummaryTextValue(event.target.value)}
                                       inputProps={{ maxLength: 150 }}
                                       className={classes.inputFieldChapterSummary} 
                                       multiline 
                                       minRows={ 3 }
                                       helperText={<span style={{ textAlign: "right", color: chapterSummaryTextValue.length >= 150 ? "red" : "inherit" }}> {chapterSummaryTextValue.length}/150 </span>}
                                       FormHelperTextProps={{ style: { position: "absolute", bottom: 0, right: 0 } }}>
                            </TextField>
                            <div className={classes.vocabAndWarningTextContainer}>
                                <Typography className={classes.chapterSummaryTitleText}>
                                    Vocabularies
                                </Typography>
                                {
                                    finalVocabsAdded.length < 4 &&
                                    <Typography className={classes.vocabWarningText}>
                                        (4 or more recommended)
                                    </Typography>
                                }
                                {
                                    finalVocabsAdded.length > 6 &&
                                    <Typography className={classes.vocabWarningText}>
                                        (6 or less recommended)
                                    </Typography>
                                }
                            </div>
                            <div className={classes.vocabContainer}>
                                {
                                    !vocabAddSelectionModalOpen && props.vocabsLeft.filter(item => finalVocabsAdded.includes(item.conceptId)).map((element, index) =>
                                        <ACCCustomChip index={index} content={element.text} customStyle={{
                                            margin: 2,
                                            borderRadius: 16,
                                            overflow: 'hidden',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 4,
                                            display: 'inline-flex'
                                        }}/>
                                    )
                                }
                                {
                                    vocabAddSelectionModalOpen && props.vocabsLeft.map((element, index) =>
                                        <ACCVocabSelectionChip index={index} content={element.text} 
                                                               selectElement={handleAddVocab} deSelectElement={handleDeSelectVocab} 
                                                               preSelected={finalVocabsAdded.includes(element.conceptId)}
                                                               element={element}/>
                                    )
                                }
                            </div>
                            <div className={classes.editSelectionButton}
                                 onClick={vocabAddSelectionModalOpen ? () => setVocabAddSelectionModalOpen(false) : () => setVocabAddSelectionModalOpen(true)}>
                                <img src={vocabAddSelectionModalOpen ? editCheckmark : editImage} style={{ marginRight: 4 }}/>
                                <Typography className={classes.editSelectionButtonText}>
                                    {
                                        vocabAddSelectionModalOpen ? "Confirm selection" : "Edit selection"
                                    }
                                </Typography>
                            </div>
                            <Divider/>
                            <div style={{ marginBottom: 16 }}/>
                            <div className={classes.buttonContainer}>
                                <div className={classes.cancelButton} onClick={() => props.closeModal(false)}>
                                    <Typography className={classes.cancelButtonText}>
                                        Cancel
                                    </Typography>
                                </div>
                                <div className={(finalVocabsAdded.length <= 0 || chapterSummaryTextValue.length <= 0) 
                                    ? classes.saveButtonLocked : classes.saveButton}
                                     onClick={() => handleCloseAndSave()}>
                                    <Typography className={classes.saveButtonText}>
                                        Save
                                    </Typography>
                                </div>
                            </div>
                        </Fragment>
                    }
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

EditChapterModal.propTypes = {};

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChapterModal)