import React, {useEffect, useState} from "react";

import {connect} from "react-redux";
import {
  Button,
  Card, CardContent, CardHeader,
  Grid,
  makeStyles
} from "@material-ui/core";
import {organizationActions} from "../../../../_state/actions";
import {FormattedMessage} from "react-intl";
import CourseGroupCard from "../../Courses/CourseGroupCard";
import CapeeshStyledModal from "../../../../Components/CapeeshStyledModal";
import {LibraryAdd} from "@material-ui/icons";
import CourseGroupSelector from "../../../../Components/CourseGroupSelector/CourseGroupSelector";


const useStyles = makeStyles(theme => ({
  newCourseIcon: {
    height: '50px',
    width: '50px',
    margin: 'auto auto'
  },
}));


const OrganizationCourseGroupComponent = (props) => {
  const {organization, metadata} = props;
  const classes = useStyles();

  const [showAddCourseGroup, setShowAddCourseGroup] = useState( false);
  const [showRemoveCourseGroupNotification, setShowRemoveCourseGroupNotification] = useState( false);
  const [removeCourseGroupIdAfterConfirm, setRemoveCourseGroupIdAfterConfirm] = useState( null);

  useEffect(() => {
    props.getAvailableCourseGroups(organization.selectedOrganization);
    props.getOrganizationCourseGroups(organization.selectedOrganization);
    
  }, []);
  
  const handleAddCourseGroup = () => {
    setShowAddCourseGroup(true);
  }

  const handleAddCourseGroupById = (addCourseGroupId) => {
    const {organization} = props;
    
    
    
    
    
    
    let newCourseGroupIds = [];
    props.addCourseGroupToOrganization(organization.selectedOrganization, addCourseGroupId);
    
    if (props.keepVisibleAfterAdd) {
      return;
    }
    setShowAddCourseGroup(false);
  }

  const handleRemoveCourseGroup = (courseGroupId) => {
    setShowRemoveCourseGroupNotification(true);
    setRemoveCourseGroupIdAfterConfirm(courseGroupId);
  }


  const handleAddCourseGroupCancel = () => {
    setShowAddCourseGroup(false);
  }

  const handleCancelRemoveCourseGroupNotification = () => {
    setShowRemoveCourseGroupNotification(false);
    setRemoveCourseGroupIdAfterConfirm(null);
  }

  const handleUnenrollCourseGroupConfirmed = (courseGroupId) => {
    setShowRemoveCourseGroupNotification(false);
    props.removeCourseGroupFromOrganization(organization.selectedOrganization, courseGroupId)
    setRemoveCourseGroupIdAfterConfirm(null);
  }
  
  return (
    <Grid container spacing={3}>
      <CapeeshStyledModal open={showRemoveCourseGroupNotification}
                          textTitle={"Do you want to remove this course?"}
                          textLine1={"All courses in the course will be removed as options for adding to classrooms for the organization."}
                          onButton1={() => handleCancelRemoveCourseGroupNotification()}
                          onButton2={() => handleUnenrollCourseGroupConfirmed(removeCourseGroupIdAfterConfirm)}
                          button1Variant={'outlined'}
                          button1Text={"Cancel"}
                          button2Text={"Yes"}
                          onClose={() => handleCancelRemoveCourseGroupNotification()}
      />

      <CourseGroupSelector
        open={showAddCourseGroup}
        dialogTitle="Capeesh Course Library"
        dialogContentText="Select Courses to add to this classroom."
        handleCancel={handleAddCourseGroupCancel}
        onAddCourseGroupId={handleAddCourseGroupById}
        availableCourseGroups={organization.availableCourseGroups}
        classroomCourseGroupIds={organization.organizationCourseGroups?.map(x => x.id)}
      />
      
      <Grid item xs={12} sm={12} md={12}>
        <Button color="primary"
                onClick={handleAddCourseGroup}
        >
          <LibraryAdd className={classes.newCourseIcon}/>
          <FormattedMessage id="admin.edit.org.course.group.add_course_group"
                            defaultMessage="Add Course"/>
        </Button>
        
        <Card>
          <CardHeader
            title={<FormattedMessage id="admin.edit.org.course.group.orgcoursegroupheader"
                                     defaultMessage="Organization Course"/>}
            subheader={<FormattedMessage id="admin.edit.org.course.group.orgcoursegroupheader"
                                         defaultMessage="These course will show up when customers create classrooms"/>}
          />
          <CardContent>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1}>
                {
                  organization.organizationCourseGroups?.length > 0 &&
                  organization.organizationCourseGroups.sort((a, b) => a.name.localeCompare(b.name)).map((courseGroup, index) =>
                    <Grid item xs={12} key={courseGroup.id}>
                      <CourseGroupCard
                        courseGroup={courseGroup}
                        index={index}
                        slideDirection={'right'}
                        handleRemoveCourseGroup={courseGroup.organizationId === props.organization.selectedOrganization ? null : handleRemoveCourseGroup}
                      />
                    </Grid>
                  )
                }
              </Grid>
            </Grid>            
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  const {organization, metadata} = state;
  return {
    organization,
    metadata
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAvailableCourseGroups: (organizationId) => dispatch(organizationActions.getAvailableCourseGroups(organizationId)),
    getOrganizationCourseGroups: (organizationId) => dispatch(organizationActions.getOrganizationCourseGroups(organizationId)),
    removeCourseGroupFromOrganization: (organizationId, courseGroupId) => dispatch(organizationActions.removeCourseGroupFromOrganization(organizationId, courseGroupId)),
    addCourseGroupToOrganization: (organizationId, courseGroupId) => dispatch(organizationActions.addCourseGroupToOrganization(organizationId, courseGroupId))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCourseGroupComponent)