import React, {useEffect, useState} from "react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {connect} from 'react-redux';

import {
  Grid,
  Typography, Button, makeStyles
} from '@material-ui/core';

import {history} from "_state/helpers";
import {TopicLessonType} from "../TopicConstants";
import {CapeeshColors} from "../../../assets/ColorPalette";
import {FormattedMessage} from "react-intl";
import CustomLessonOrderCard from "./Cards/CustomLessonOrderCard";
import {props} from "react-csv/lib/metaProps";
import {topicActions} from "../../../_state/actions";

const useStyles = makeStyles(theme => ({
  languageDropdown: {
    width: '100%',
  },
  scenariosHeader: {},
  button: {
    marginRight: '10px',
    textTransform: 'none'
  },
  cancel: {
    alignItems: 'right',
  },
  media: {
    height: '100px'
  },
  draggable: {
    background: "#f8f8f8"
  },
  divider: {
    borderBottom: "1px solid lightgray",
    width: "100%"
  },

}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getListStyle = isDraggingOver => ({
  padding: grid,
});

const TopicLessonOrder = (props) => {

  // expected props
  // props.topicId
  // props.courseGroupId optional
  // props.dispatch


  const classes = useStyles();

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [showAttachScenarioModal, setShowAttachScenarioModal] = useState(true);

  useEffect(() => {
    props.dispatch(topicActions.getLessonOrderForDifficulty(props.topicId, 0));
  }, []);
  
  const createNewScenario = (event) => {
    
  };

  const addScenarioItem = (event) => {
    props.onAddScenario()
    setShowAttachScenarioModal(true)
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      props.topic.lessonOrder,
      result.source.index,
      result.destination.index
    );
    props.onDragEnd(items)
  }

  const handleToggleGateable = (customLessonId) => {
    props.onToggleGateable(customLessonId)
  }

  const handleDetachScenario = (customLessonId) => {
    props.onDetachScenario(customLessonId)
  }

  const handleEditScenario = (customLessonId) => {

    if (props.courseGroupId && props.topicId) {
      history.push("/creator/course/group/" + props.courseGroupId + "/topic/" + props.topicId + "/lesson/edit/" + customLessonId)
    } else {
      history.push("/creator/topic/list/" + props.topicId + "/lessons/edit/" + customLessonId)
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "stretch"
      }}>
      <Typography variant='h4' style={{display: 'inline-block', color: CapeeshColors.DeepBlue, marginTop: '20px'}}>
        Lesson list
      </Typography>
      <Grid item xs={12} style={{marginTop: '10px'}}>
        <Typography style={{
          fontFamily: 'Rubik',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#8E8E93',
          marginTop: '3px',
          marginBottom: '10px'
        }}>

          {
            props.topic.lessonOrder?.length > 0 &&
            <FormattedMessage 
              id="dashboard.creator.lesson.description"
              defaultMessage={"Reorder lessons by dragging and dropping. You can also set whether the learner must complete a lesson in order to unlock the next."}/>
          }
          {
            props.topic.lessonOrder?.length === 0 &&
            <FormattedMessage
              id="dashboard.creator.lesson.descriptionEmptyList"
              defaultMessage={"Create lessons for the topic using the \"Create Lessons\" button above."}/>
          }
          
          
        </Typography>
      </Grid>
      
      {
        props.topic.lessonOrder.length === 0 &&
        <Typography variant='h4' style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '23px'
        }}>
          No lessons have been created or added
        </Typography>
      }

      {
        props.topic.lessonOrder.length > 0 &&
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {
              (provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {
                    props.topic.lessonOrder.map((item, index) => (
                        <Draggable className={classes.draggable} key={item.tempId} draggableId={item.tempId + ''}
                                   isDragDisabled={(!(item.topicLessonType === TopicLessonType.Quiz || item.topicLessonType === TopicLessonType.Scenario))}
                                   index={index}>
                          {
                            (provided, snapshot) =>
                              (item.topicLessonTypeName === 'Scenario' || item.topicLessonType === TopicLessonType.Scenario) &&
                              <CustomLessonOrderCard
                                lessonOrderItem={item}
                                provided={provided}
                                index={index}
                                onEditScenario={handleEditScenario}
                                onDetachScenario={handleDetachScenario}
                                onToggleGateable={handleToggleGateable}
                                onPreviewLesson={props.onPreviewLesson}
                              />
                          }
                        </Draggable>
                      )
                    )

                  }
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      }
    </div>
  );
};

TopicLessonOrder.propTypes = {};

const mapStateToProps = (state) => {
  const {lesson, topic, user, organization} = state;
  return {
    lesson,
    topic,
    organization,
    user
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicLessonOrder)