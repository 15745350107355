import {connect} from "react-redux";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({

}));

const UserRoleView = (props) => {
  // expected props:

  const classes = useStyles();


  return (
    <React.Fragment>

    </React.Fragment>
  );
}

UserRoleView.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(UserRoleView)