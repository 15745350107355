import React, {useState, useRef} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

const FocusAreaListeningToken = (props) => {
  const color = (!props.disabled ? props.selected ? props.hover ? "rgba(128, 211, 135, 0.32)" : "#E5E5EA" :  "url(#paint0_linear_1433_23121)" : "#E5E5EA");
  return (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill={color}/>
    <g clip-path="url(#clip0_1433_23121)">
    <path d="M20.6667 17.3337V19.3337C20.6667 19.7003 20.3667 20.0003 20 20.0003H19.3333V17.3337H20.6667ZM12.6667 17.3337V20.0003H12C11.6333 20.0003 11.3333 19.7003 11.3333 19.3337V17.3337H12.6667ZM16 8.66699C12.6867 8.66699 10 11.3537 10 14.667V19.3337C10 20.4403 10.8933 21.3337 12 21.3337H14V16.0003H11.3333V14.667C11.3333 12.087 13.42 10.0003 16 10.0003C18.58 10.0003 20.6667 12.087 20.6667 14.667V16.0003H18V21.3337H20C21.1067 21.3337 22 20.4403 22 19.3337V14.667C22 11.3537 19.3133 8.66699 16 8.66699Z" fill="white"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_1433_23121" x1="0" y1="0" x2="32" y2="32" gradientUnits="userSpaceOnUse">
    <stop stop-color="#9BD8A0"/>
    <stop offset="1" stop-color="#80D387"/>
    </linearGradient>
    <clipPath id="clip0_1433_23121">
    <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
    </clipPath>
    </defs>
  </svg>
  )
}

FocusAreaListeningToken.propTypes = {
  
};


function mapStateToProps(state) {
  const {} = state;
  return {
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FocusAreaListeningToken)