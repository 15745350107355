import { call, put, takeLatest } from 'redux-saga/effects'
import { userConstants, translatorConstants, topicConstants } from '../actions'
import { userService, translatorService, topicService } from '../services'
import {snackbarActions} from "../actions/snackbar.actions";

export function* getTranslationJobs(data) {
    try {
        const response = yield call(translatorService.getTranslationJobs, data.payload);
        yield put({type: translatorConstants.GET_TRANSLATION_JOBS.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to get the translation jobs.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: translatorConstants.GET_TRANSLATION_JOBS.FAILURE, message: e.message});
    }
}

export function* createTranslatorJob(data) {
    try {
        const response = yield call(translatorService.createTranslatorJob, data.payload);
        yield put({type: translatorConstants.CREATE_TRANSLATOR_JOB.SUCCESS, data: response.data});
        const responseTopic = yield call(topicService.getCurrentTopic, data.payload);
        yield put({type: topicConstants.GET_CURRENT_TOPIC.SUCCESS, data: responseTopic.data});
        yield put(snackbarActions.enqueueSnackbar({
                message: "The translator has been assigned.",
                options: {
                    variant: "success"
            }
        }));
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable assign the translation job.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: translatorConstants.CREATE_TRANSLATOR_JOB.FAILURE, message: e.message});
    }
}

export function* getTranslators(data) {
    try {
        const response = yield call(translatorService.getTranslators, data.payload);
        yield put({type: translatorConstants.GET_TRANSLATORS.SUCCESS, data: response.data});
        // yield put({type: snackbarConstants.SET_SNACKBAR.REQUEST, payload: {message: 'The invitation has been sent to the user.'}});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able to get the organization translators.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: translatorConstants.GET_TRANSLATORS.FAILURE, message: e.message});
    }

}

export function* addTranslatorLanguage(data) {
    try {
        const response = yield call(translatorService.addTranslatorLanguage, data.payload);
        yield put({type: translatorConstants.ADD_TRANSLATOR_LANGUAGE.SUCCESS, data: response.data});
        const userResponse = yield call(userService.getUserInfo);
        yield put({type: userConstants.GETUSERINFO.SUCCESS, data: userResponse.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able to edit the languages. You must have at least one language.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: translatorConstants.ADD_TRANSLATOR_LANGUAGE.FAILURE, message: e.message});
    }

}

export function* getTranslatorJobs(data) {
    try {
        const response = yield call(translatorService.getTranslatorJobs, data.payload);
        yield put({type: translatorConstants.GET_TRANSLATOR_JOBS.SUCCESS, data: response.data});
    }
    catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able to get your translation assignments.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: translatorConstants.ADD_TRANSLATOR_LANGUAGE.FAILURE, message: e.message});
    }

}

export function* updateJob(data) {
    try {
        const response = yield call(translatorService.updateJob, data.payload);
        yield put({type: translatorConstants.UPDATE_JOB.SUCCESS, data: response.data});
        const responseTopic = yield call(topicService.getTopic, {topicId: data.payload.topicId});
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopic.data});
    }
    catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able update the translation job",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: translatorConstants.UPDATE_JOB.FAILURE, message: e.message});
    }
}

//Listeners
export function* translatorSagas() {
    yield takeLatest(translatorConstants.GET_TRANSLATION_JOBS.REQUEST, getTranslationJobs);
    yield takeLatest(translatorConstants.CREATE_TRANSLATOR_JOB.REQUEST, createTranslatorJob);
    yield takeLatest(translatorConstants.GET_TRANSLATORS.REQUEST, getTranslators);
    yield takeLatest(translatorConstants.ADD_TRANSLATOR_LANGUAGE.REQUEST, addTranslatorLanguage);
    yield takeLatest(translatorConstants.GET_TRANSLATOR_JOBS.REQUEST, getTranslatorJobs);
    yield takeLatest(translatorConstants.UPDATE_JOB.REQUEST, updateJob);
}