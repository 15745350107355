import React, {useEffect, useState} from "react";
import {
  Typography,
  makeStyles, Grid,
} from '@material-ui/core';
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";
import {CapeeshColors} from "../../../../assets/ColorPalette";


const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: '16px',
    backgroundColor: CapeeshColors.OffWhite,
    color: 'black',
    minWidth: '300px',
    maxWidth: '700px',
    height: '150px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    flex:1,
    alignSelf: 'stretch',
  },

  title: {
    marginTop: '5px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontWeight: 500,
    width: '200px',
    color: CapeeshColors.DarkText,
  },

  topPart: {
    minWidth: '300px',
    maxWidth: '700px',
    display: 'flex',
    flexDirection: 'row',
    flex:1,
  },
  
  bottomPart: {
    marginTop: '-30px',
    minWidth: '300px',
    maxWidth: '700px',
    display: 'flex',
    flexDirection: 'row',
    flex:1,
    gap: '8px'
  },

  stats: {
    // marginLeft: '10px',
    fontSize: '40px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    width: '95px',
  },
  changeDescription: {
    fontSize: '14px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'right'
  },

  licenseBox: {
    borderRadius: "1em",
    height: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px'
  },
  licenseBoxHeader: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Rubik',
    color: 'black',
    textAlign: 'center',
    align: 'center'
  },
  licenseBoxNumber: {
    marginTop: '8px',
    fontSize: '24px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    color: 'black',
    textAlign: 'center'
  }
  
}));


const LicenseBox = (props) => {

  // expected props
  // props.title
  // props.totalLicenses
  // props.usedLicenses
  // props.remainingLicenses

  const classes = useStyles();

  const [showLicenseBox, setShowLicenseBox] = useState(false);

  const [numberOfLicenses, setNumberOfLicenses] = useState(0);
  const [numberOfUsedLicenses, setNumberOfUsedLicenses] = useState(0);
  const [remainingLicenses, setRemainingLicenses] = useState(0);

  useEffect(() => {
    recalculateLicenseData();
  }, [])

  useEffect(() => {
    recalculateLicenseData();

  }, [props.organization])

  const recalculateLicenseData = () => {
    let currentOrganization;

    if (props.organization.myOrganizations && props.organization.selectedOrganization)
      currentOrganization = props.organization.myOrganizations.find(x => x.id === props.organization.selectedOrganization);

    const tempNumberOfLicenses = currentOrganization?.numberOfLicenses && currentOrganization.numberOfLicenses > 0 ? currentOrganization.numberOfLicenses : 0;
    const tempNumberOfUsedLicenses = currentOrganization?.usedLicenses ? currentOrganization.usedLicenses : 0;

    let tempRemainingLicenses = tempNumberOfLicenses - tempNumberOfUsedLicenses;

    if (tempRemainingLicenses < 0) {
      tempRemainingLicenses = 0;
    }

    let tempShowLicenseBox = true;
    if (props.user.organizationRole === "reporting" && props.user.classroomOnlyReportingUser || (tempNumberOfLicenses === 0)) {
      tempShowLicenseBox = false;
    }

    setNumberOfUsedLicenses(tempNumberOfUsedLicenses);
    setNumberOfLicenses(tempNumberOfLicenses);
    setRemainingLicenses(tempRemainingLicenses);
    setShowLicenseBox(tempShowLicenseBox);

  }


  return (
    <React.Fragment>
      {
        showLicenseBox &&
        <div className={classes.container}>
          <div className={classes.topPart}>
            <Typography className={classes.title}>
              <FormattedMessage id="dashboard.license.title"
                                defaultMessage="Licensing information"/>
            </Typography>
            {/*<Typography className={classes.title}>*/}
            {/*  <AddIcon/> <FormattedMessage id="dashboard.license.addmore"*/}
            {/*                               defaultMessage="Add more licenses"/>*/}
            {/*</Typography>*/}
          </div>

          <div className={classes.bottomPart}>
            <div className={classes.licenseBox}>
              <Typography className={classes.licenseBoxHeader}>
                <FormattedMessage id="dashboard.license.totallicenses"
                                  defaultMessage="Total licenses"/>
              </Typography>
              <Typography className={classes.licenseBoxNumber}>
                {numberOfLicenses}
              </Typography>
            </div>


            <div className={classes.licenseBox}>
              <Typography className={classes.licenseBoxHeader}>
                <FormattedMessage id="dashboard.license.usedlicenses"
                                  defaultMessage="Used licenses"/>
              </Typography>
              <Typography className={classes.licenseBoxNumber}>
                {numberOfUsedLicenses}
              </Typography>
            </div>
            <div className={classes.licenseBox}>
              <Typography className={classes.licenseBoxHeader}>
                <FormattedMessage id="dashboard.license.remaininglicenses"
                                  defaultMessage="Remaining licenses"/>
              </Typography>
              <Typography className={classes.licenseBoxNumber}>
                {remainingLicenses}
              </Typography>
            </div>
          </div>
        </div>
      }

    </React.Fragment>
  );
};

LicenseBox.propTypes = {};

const mapStateToProps = (state) => {
  const {user, organization, metadata} = state;
  return {
    metadata,
    user,
    organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseBox)