import React, {useState, useRef} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

const FocusAreaListeningToken = (props) => {
  const color = (!props.disabled ? props.selected ? props.hover ? "rgba(134, 196, 240, 0.32)" : "#E5E5EA" :  "url(#paint0_linear_1433_23235)" : "#E5E5EA");
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill={color}/>
      <g clip-path="url(#clip0_1433_23235)">
      <path d="M18.0007 10.6663V15.333H11.4473L10.6673 16.113V10.6663H18.0007ZM18.6673 9.33301H10.0007C9.63398 9.33301 9.33398 9.63301 9.33398 9.99967V19.333L12.0007 16.6663H18.6673C19.034 16.6663 19.334 16.3663 19.334 15.9997V9.99967C19.334 9.63301 19.034 9.33301 18.6673 9.33301ZM22.0007 11.9997H20.6673V17.9997H12.0007V19.333C12.0007 19.6997 12.3007 19.9997 12.6673 19.9997H20.0007L22.6673 22.6663V12.6663C22.6673 12.2997 22.3673 11.9997 22.0007 11.9997Z" fill="white"/>
      </g>
      <defs>
      <linearGradient id="paint0_linear_1433_23235" x1="0" y1="0" x2="32" y2="32" gradientUnits="userSpaceOnUse">
      <stop stop-color="#86C4F0"/>
      <stop offset="1" stop-color="#5AB4F1"/>
      </linearGradient>
      <clipPath id="clip0_1433_23235">
      <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

FocusAreaListeningToken.propTypes = {
  
};

function mapStateToProps(state) {
  return {
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FocusAreaListeningToken)