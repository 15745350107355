import React, {useState, useRef} from 'react';
import {
  Typography,
  TextField,
  makeStyles,
  Box,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ContentEditable from 'react-contenteditable';
import AutoCourseSuggestLessonDetailsQuizView from '../AutoCourseSuggestLessonDetailsQuizView'
import AutoCourseSuggestLessonDetailsSimulation from '../AutoCourseSuggestLessonDetailsSimulation'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import AccStageSettingsInteractionSelection from './ACCStageSettingsInteractionSelection'

import {v4 as uuidv4} from "uuid";

import { Droppable, Draggable } from "react-beautiful-dnd";


const useStyles = makeStyles(theme => ({
  cardContent: {
    flex: 'auto',
  },
  topicCard: {
    display: 'flex',
    padding: '20px',
    boxShadow: 'none',
  },
  topicCardAction: {
    right: '15px',
    height: 'fit-content',
    margin: 'auto',
  },
  editable: {
    border: '2px solid transparent',
    transition: 'all 0.3s ease',
    '&:focus, &:hover, &$active': {
      border: '2px solid #AAC8E'
    },
}
}));

const ACCStageSettingsTest = (props) => {
  const [isFocus, setIsFocus] = useState(false);
  const [focusedAttribute, setFocusedAttribute] = useState(null);
  const focusedAttributeRef = useRef();
  focusedAttributeRef.current = focusedAttribute;
  const [focusedText, setFocusedText] = useState("");
  const focusedTextRef = useRef();
  focusedTextRef.current = focusedText;
  const [openGenericDialog, setOpenGenericDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogExistingText, setDialogExistingText] = useState("");
  const [dialogType, setDialogType] = useState(null);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [dalogueText, setDialogueText] = useState(false);

  
  
  const [newQuestion, setNewQuestion] = useState(
    {
      questionId: "", 
      conceptId: "",
      correctAnswer: "",
      incorrectAnswer1: "",
      incorrectAnswer2: "",
      incorrectAnswer3: "",
      question: "",
  }); 

  const classes = useStyles();

  let circleStyle = {
    padding:10,
    margin:20,
    //display:"inline",
   // backgroundColor: 'green',
    borderRadius: "50%",
    width:30,
    height:30,
  };

  const handleTextChange = (event) => {
    setDialogueText(event.target.value)

  }

  const handleTextFocus = (focusedId, text, attribute) => {
    setIsFocus(focusedId)
    setFocusedAttribute(attribute)
    setFocusedText(text)
  }

  const handleSelectChangePlot = (element) => {
    console.log("change plot text", element.target.value)
    props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "plotText", element.target.value)
   }

   const handleClickOpen = (roleType, title, message, existingText = "") => {
    setFocusedAttribute(roleType)
    setOpenRoleDialog(true);
    setDialogTitle(title)
    setDialogMessage(message)
    setDialogExistingText(existingText)
    //handleTextFocus(roleType, props.selectedLesson.lessonPlanConfigurationDto.dialogue., roleType)
  };

  const handleCloseModal = () => {
    setOpenRoleDialog(false);
    setDialogExistingText("")
  };

  const handleSaveModal = () => {
    setOpenRoleDialog(false);
    props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "plotText", dalogueText)
  };
  return (
      <React.Fragment>
      
        <Grid item xs={12} style={{}}>
          <Typography style={{marginTop: '2px', color: '#35323C', fontFamily: 'Rubik', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '19px', letterSpacing: '0.41px',  marginBottom: '10px'}}>
            This is a traditional type of stage where you can test knowledge by asking questions. The learner can answer the questions in different ways, which you can set below.
          </Typography>
        </Grid>
        <AccStageSettingsInteractionSelection headerText="How should the learner answer the questions?" selectedInteractionStyle={props.interactionType} onUpdateLessonSuggestionDto={props.onUpdateLessonSuggestionDto} exerciseType={"Test"} selectedLesson={props.selectedLesson} />
      </React.Fragment>
        );
};

ACCStageSettingsTest.propTypes = {};

const mapStateToProps = (state) => {
  return {
    languages: state.metadata.metadata.languages,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCStageSettingsTest)