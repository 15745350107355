import React, {useState} from 'react';
import {connect} from "react-redux";
import { makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import ButtonBase from '@mui/material/ButtonBase';
import ACCSectionCard from "./../AutoCourseCreateContentEditMode/Cards/ACCSectionCard";
import ACCSectionCardRounded from "./ACCSectionCardRounded";

const useStyles = makeStyles((theme) => ({
  textSection: {
    fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '16px', color: '#FFFFFF', letterSpacing: '0.5px', textAlign:'left'
  },
}))

const AutoCourseSuggestTextSectionsImproved = (props) => {
  const classes = useStyles();
  const [isHovered, setHovered] = useState(false); 
  return (
      <Grid container style={{ display: "flex", justifyContent: "center", width: "max-content", borderRadius: '10px', height: 820}}>

        {
          props.autoCourseCreation.suggestSectionsReturnDto && !props.autoCourseCreation.isSuggestingTextSections &&
          <React.Fragment>

            <Grid 
              item xs={12} 
              style={{ display: "flex", marginRight: '16px', borderRadius: '10px', background: '#FFF', position: 'relative',  maxWidth: 250, height: '100%', overflow: "auto", flexDirection: "column" }}
              onMouseEnter={() => setHovered(true)} 
              onMouseLeave={() => setHovered(false)}
            >
              {
                props.autoCourseCreation.suggestSectionsReturnDto?.sections?.map((sectionDto, index) => (
                  <React.Fragment key={index}>
                    <ACCSectionCardRounded 
                        onEditConcept={props.onEditConcept}
                        onLocalUpdateSuggestSectionsReturnDto={props.onLocalUpdateSuggestSectionsReturnDto} 
                        isSectionGenerating={props.autoCourseCreation.isSuggestingLessonPlans}
                        accSuggestLessonPlanForSection={props.accSuggestLessonPlanForSection} 
                        sectionDto={sectionDto}
                        compactView={props.compactView}
                    />
                  </React.Fragment>
                ))
              }
             <ButtonBase onClick={props.onAddConcept} style={{marginLeft: '20px', marginTop: '5px', paddingBottom: '10px'}}> 
                <Typography style={{display: 'inline',  color: "black", textAlign: "left",fontFamily: "Rubik", fontSize:14, fontStyle: "normal",marginTop: '0 px', fontWeight: 'normal'}}>
                     <u>Add new material</u>
                </Typography>
            </ButtonBase>
      {props.compactView === false && 
      
        <Grid container justifyContent="flex-end" style={{marginTop: '20px', padding: '20px'}}>
          <Button
            onClick={props.onGenerateAllLessons}
            style={{
              position: 'fixed',
              bottom: '90px',
              borderRadius: '5px',
              background: "var(--Blue, #44A6EF)",
              textTransform: 'none',
              color: "var(--White, #FFF)",
              textAlign: "center",
              fontFamily: "Rubik",
              fontSize:14,
              fontStyle: "normal",
              letterSpacing:0.41,
              //height: '45px',
              padding: '15px'
            }}>
            Generate lessons
          </Button>
        </Grid>
      }
            </Grid>
          </React.Fragment>

        }
      </Grid>
  )
}


AutoCourseSuggestTextSectionsImproved.propTypes = {

};


function mapStateToProps(state) {
  const {autoCourseCreation} = state;
  return {
    autoCourseCreation
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseSuggestTextSectionsImproved)