import React, {useEffect, useState} from "react";

import {
  Typography,
  makeStyles, MenuItem, Button, CardActions, CardContent,
} from '@material-ui/core';
import {connect} from 'react-redux';
import Card from "@material-ui/core/Card";
import Zoom from '@mui/material/Zoom';
import LimitedTypography from "../LimitedTypography";

import {
  getFlagAssetForLanguageId
} from "../../Logic/FlagUnicodeLogic";
import Grid from "@material-ui/core/Grid";
import {makeGetRequest} from "../../_state/helpers/api";
import {configWithAuth} from "../../_state/helpers";
import {convertDataURIToBinary} from "../../_state/helpers/base64Logic";
import LinearProgress from '@mui/material/LinearProgress';

import {FormattedMessage} from "react-intl";
import checkmarkImage from "../../assets/images/Lessonstatusribbons-1.png";
import checkmarkImageGray from "../../assets/images/Lessonstatusribbons.png";
import linePattern from "../../assets/images/Line18.png";

import DefaultCourseGroupImage from "../../assets/images/courseGroup/FallbackCourseGroupImage.jpeg"
import {CapeeshColors} from "../../assets/ColorPalette";
import boxShadow from "@iconify-icons/mdi/box-shadow";
import mouse from "@iconify-icons/mdi/mouse";

const useStyles = makeStyles(theme => ({
  cardContent: {
    flex: 'auto',
  },
  verticalContainer: {
    flex:1,
  },
  courseGroupCard: {
    display: 'flex',
    paddingTop: '20px',
    paddingBottom:'16px',
    marginBottom:'16px',
    // padding: '20px',
    transition: 'all .25s ease',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 4px 8px rgba(110, 110, 110, 0.1)',
      transition: "box-shadow 300ms ease-in-out"
    },
    height: "332px",
    width: '239px',
    borderRadius: 16,
    boxShadow: "0px 0px 0px rgba(110, 110, 110, 0.1)"
  },
  courseGroupCardAction: {
    right: '15px',
    height: 'fit-content',
    margin: 'auto',
  },
  button: {
    backgroundColor: 'primary',
    fontColor: 'white',
    color: 'white',
    borderRadius: 4,
    marginRight: '10px',
    textTransform: 'none'
  },

  imageContainer: {
    marginTop: '-20px',
    position:'relative',
    overflow: 'hidden',
    height: '124px',
  },
  requestAccessButton: {
    height: 40,
    width: 192, 
    border: '2px #FF5159 solid',
    borderRadius: 8,
    display: "inline-flex", 
    justifyContent: "center",
    alignItems:"center",
    cursor: "pointer",
    marginTop: -100,
    '&:hover': {
      border: '2px #8E8E93 solid',
    },
  }
}));

const CourseViewCard = (props) => {
  // expected props: 
  // props.courseGroup CourseGroupDto
  // props.courseGroupProgression optional user progression for this course group
  // props.openCourseGroup
  // props.handleRequestAccess
  // props.index
  
  
  const {organization, metadata} = props;
  const classes = useStyles();

  const [description, setDescription] = useState("");
  
  const [imageUrl, setImageUrl] = useState("");
  const [imageBlobUrl, setImageBlobUrl] = useState("");

  const [supportedLanguagesString, setSupportedLanguagesString] = useState("");

  const [userCourseGroupProgression, setUserCourseGroupProgression] = useState(null);

  const [mouseOverRequestButton, setMouseOverRequestButton] = useState(false);
  
  useEffect(() => {
    if (imageUrl && !imageBlobUrl) {
      let imageReferenceId = imageUrl.replace('image/file/', '');
      makeGetRequest('admin/organization/' + organization.selectedOrganization + '/image/file/reference/' + imageReferenceId + '/base64', configWithAuth())
        .then(result => {
          let type = null
          const typeImage = result.data.split(';')[0].split(':')[1];
          switch (typeImage) {
            case "image/jpeg":
              type = 'jpeg'
              break;
            case "image/png":
              type = 'png'
              break;
            default:
              type = 'unknown'
              break;
          }
          var binary = convertDataURIToBinary(result.data);
          var blob = new Blob([binary], {type: type});
          var blobUrl = URL.createObjectURL(blob);
          setImageBlobUrl(blobUrl);

        })
        .catch(error => {
          console.log("error", error);
        })
    }
  }, [imageUrl])

  useEffect(() => {
    recalculateCourseGroupData(props.courseGroup)
  }, [])

  useEffect(() => {
    recalculateCourseGroupData(props.courseGroup)
  }, [props.courseGroup])

  const recalculateCourseGroupData = (courseGroup) => {
    if (!courseGroup) {
     
      setImageUrl("");
      setImageBlobUrl("");
      return;
    }

    if (courseGroup.imageUrl) {
      setImageUrl(courseGroup.imageUrl);
    } else {
      setImageUrl("");
      setImageBlobUrl("");
    }
  }

  const handleCardClick = () => {
    if (props.openCourseGroup) {
      props.openCourseGroup(props.courseGroup.id);
    }
  }
  
  return (
    <Zoom in={true}>
      <div>
        <Card key={props.courseGroup?.id} className={classes.courseGroupCard} onClick={() => handleCardClick()}>
          <div className={classes.cardContent}>
            <div style={{ height: '200px' }}>
              <Grid container
                    style={{
                      flex:1,
                      flexDirection:"row"
                    }}>
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.imageContainer}>
                  {
                      imageUrl && imageBlobUrl &&
                      <img src={imageBlobUrl}
                           style={{
                             maxWidth:'100%',
                             maxHeight:'150%',
                             position:'absolute',
                             top:'50%',
                             left:'50%',
                             transform: 'translateY(-50%) translateX(-50%)'
                           }}/>
                  }
                  {
                      imageUrl && !imageBlobUrl &&
                      <Typography style={{ marginTop: '130px'}}>
                      </Typography>
                  }
                  {
                      !imageUrl &&
                      <img
                          src={DefaultCourseGroupImage}
                          style={{
                            maxWidth:'100%',
                            maxHeight:'150%',
                            position:'absolute',
                            top:'50%',
                            left:'50%',
                            transform: 'translateY(-50%) translateX(-50%)'
                          }}/>
                  }

                  {
                    props.showProgression && props.courseGroupProgression ?
                        <div style={{width: '45px'}}>
                          <div style={{borderRadius: '16px', position: 'absolute', top: 10, bottom:0, left:'78%', right: 0, background: props.courseGroupProgression.courseGroupNormalizedProgression > 0.99 ? CapeeshColors.GreenBright : CapeeshColors.BlueBright, height: '30px', width: '50px'}}>
                            <Typography align={"center"} style={{
                              fontFamily: 'Rubik',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '140%',
                              color: '#fff',
                              marginTop: '5px'
                            }}>
                              {Math.round(props.courseGroupProgression.courseGroupNormalizedProgression * 100)}%
                            </Typography>
                          </div>
                        </div>
                        :
                        <div style={{width: '32px'}}>
                          <img style={{borderRadius: '50%', position: 'absolute', top: 10, bottom:0, left:'83%', right: 0}}
                               width="32" src={getFlagAssetForLanguageId(props.courseGroup?.l2Id)}/>
                        </div>
                  }

                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography style={{
                            color: '#35323C',
                            fontFamily: 'Rubik',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '140%',
                            maxWidth: '199px',
                            maxHeight:'100px',
                            marginBottom: '4px',
                            marginTop: '15px',
                            marginLeft: '12px',
                            marginRight: '12px'
                          }}>
                            {props.courseGroup?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <div style={{marginLeft: '12px', marginRight: '12px'}}>
                    {
                        props.courseGroup.description &&
                        <LimitedTypography text={props.courseGroup?.description} limit={145} customStyle={{
                          color: '#6E6C71',
                          fontFamily: 'Rubik',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '140%'
                        }}/>
                    }
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              {
                  props.showProgression && props.courseGroupProgression &&
                  <Grid container>
                    {
                        props.showProgression && props.courseGroupProgression &&
                        <div>
                          <LinearProgress
                              style={{
                                height: '17px',
                                marginTop: '100px',
                                borderRadius: '50px',
                                width: '150px',
                                marginLeft: '15px'
                              }}

                              sx={{
                                backgroundColor: CapeeshColors.Gray2,
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: props.courseGroupProgression.courseGroupNormalizedProgression > 0.99 ? CapeeshColors.GreenBright : CapeeshColors.BlueBright,
                                  borderRadius: '50px',
                                  backgroundImage: `url(${linePattern})`,
                                  backgroundRepeat: 'repeat-x',
                                  backgroundSize: '10px 30px'
                                }
                              }}
                              variant="determinate"
                              value={props.courseGroupProgression.courseGroupNormalizedProgression * 100} />
                        </div>
                    }
                    {
                        props.showProgression && !props.courseGroupProgression &&
                        <div>
                          <LinearProgress
                              style={{
                                height: '17px',
                                marginTop: '100px',
                                borderRadius: '50px',
                                width: '150px',
                                marginLeft: '15px'
                              }}

                              sx={{
                                backgroundColor: CapeeshColors.Gray2,
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: CapeeshColors.Gray2
                                }
                              }}
                              variant="determinate"
                              value={0} />
                        </div>
                    }
                    {
                      props.courseGroupProgression.courseGroupNormalizedProgression > 0.99 ?
                          <img src={checkmarkImage} style={{ height: '30px', marginTop: '95px', marginLeft: '15px' }}/>
                          :
                          <img src={checkmarkImageGray} style={{ height: '30px', marginTop: '95px', marginLeft: '15px' }}/>
                    }
                  </Grid>
              }
            </div>
          </div>

        </Card>
        {
            props.handleRequestAccess !== null &&
            <div style={{ display: "flex", flexDirection: "column"}}>
              <div style={{justifyContent: "center", alignItems: "center", display: "flex" }}>
                <div className={classes.requestAccessButton} onClick={() => props.handleRequestAccess(props.courseGroup.id)} 
                     onMouseEnter={() => setMouseOverRequestButton(true)}
                     onMouseLeave={() => setMouseOverRequestButton(false)}>
                  <Typography style={{
                    color: mouseOverRequestButton ? '#8E8E93' : '#FF5159',
                    fontSize: 16,
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    wordWrap: 'break-word'}}>
                    <FormattedMessage id="dashboard.courses.courseviewcard" defaultMessage="Request access"/>
                   
                  </Typography>
                </div>
              </div>
            </div>
        }
      </div>
    </Zoom>
  );
};

CourseViewCard.propTypes = {};

const mapStateToProps = (state) => {
  const {user, organization, metadata} = state;
  return {
    metadata,
    user,
    organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseViewCard)