import React, {useEffect, useState} from "react";
import {
    Switch,
    Button,
    Grid,
    TextField,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CapeeshHighlighter from "./CapeeshHighlighter";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'black',
    color: "black",
  },
  body: {
    fontSize: 28,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: '5px',
    marginBottom: '5px',
  },
  scenario: {
    paddingTop: '30px'
  },
  textFields: {
    marginBottom: '5px'
  },
  textFieldsL1: {
    width: '428px',
    textColor: 'white',
    marginBottom: '5px'
   },
}));


const InteractionFillInBlankDistractors = (props) => {
  
  // expects these props:
  // props.canEditLesson

  const classes = useStyles();
  const [newAnswer, setNewAnswer] = useState("");

  const handleDelete = (alternative) => {
    //textSnippetId, type, index, testOption
    props.onDeleteDistractor(alternative.textSnippetId, "textImageMultichoice", null, props.testOption.testOptionId)
  };

  const handleClick = (alternative) => {
    props.onToggleCorrect(props.testOption.testOptionId, alternative.textSnippetId)
  };


  const handleDetectDistractorType = () => {
    let isSentenceInsertion = "fillInGaps"
    props.alternatives.forEach(alt => {
      if(alt.startIndex === -1) isSentenceInsertion = "chooseAnswer"
      
    });
    //props.onToggleCorrect(props.testOption.testOptionId, alternative.textSnippetId)
    return isSentenceInsertion
  };

    

  return (
    <React.Fragment> 
      {handleDetectDistractorType() === "chooseAnswer" 
        ?
        <React.Fragment>
          <Grid className={classes.header} item xs={12} sm={12} md={12}  >
            <TextField value={newAnswer} onChange={(event) => setNewAnswer(event.target.value)}  label={"Add an answer"} style={{borderRadius: '10px'}} InputProps={{ disableUnderline: true, shrink: "false"  }} className={classes.textFields}  variant="filled" />
            {props.type === "rulecardMultichoice" ? 
              <Button disabled={!props.canEditLesson} onClick={() => props.onAddRuleCardDistractor(newAnswer)} style={ {}} variant="outlined"   >Add</Button>
            :
              <Button disabled={!props.canEditLesson} onClick={() => props.onAddDistractor(props.type, newAnswer, null,  props.testOption.testOptionId)} style={ {}} variant="outlined"   >Add</Button>
            }
          </Grid>
    </React.Fragment>
    :
      <CapeeshHighlighter 
        value={props.l2Question} 
        text={""} 
        alternatives={props.testOption.alternatives} 
        testOption={props.testOption} 
        onAddDistractorWithinSentence={props.onAddDistractorWithinSentence} 
        onAddAlternative={props.onAddAlternative}
        disabled={!props.canEditLesson}
      />
    }
    <Grid item xs={12} sm={12} md={12}  >
        {handleDetectDistractorType() === "chooseAnswer" ?
          props.alternatives.sort((a, b) => a.text > b.text).map((alternative, index) => (
            <Chip color="secondary" 
                  style= {{ deleteIconColor: 'red', color: alternative.correct === true ? '#63C276' : 'red', borderColor: alternative.correct === true ? '#63C276' : 'red' }} 
                  className={classes.chip} variant="outlined" 
                  label={alternative.text} 
                  onDelete={() => handleDelete(alternative)}
                  onClick={() => props.isKeyboard ? null : handleClick(alternative)}
                  //icon={<MoodIcon style= {{ color: alternative.correct === true ? '#63C276' : 'red', fill: alternative.correct === true ? '#63C276' : 'red' }} />} key={index} 
              />
          ))
        :
        <React.Fragment>
          <Button disabled={!props.canEditLesson} onClick={() => props.onAddDistractorWithinSentence(newAnswer)} style={ {}} variant="outlined"   >Add from fillinblank</Button>
          
      </React.Fragment>
        }

      </Grid>
    </React.Fragment>  
  );
};

InteractionFillInBlankDistractors.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(InteractionFillInBlankDistractors)