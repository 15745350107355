import {configACCWithoutAuth, configWithAuth} from "../helpers";
import {makeACCGetRequest, makeACCPostRequest} from "../helpers/auto-course-creation-api";


export const autoCourseCreationService = {
  
  // parse request nr 1: with {authorInputMetadata, text} -> suggestSectionsReturnDto
  accSuggestTextSections: (payload) => makeACCPostRequest('suggest/text/sections/', JSON.stringify(payload.parseTextRequestDto), configACCWithoutAuth()),
  
  // parse request nr 2: dto with {authorInputMetadata, text, topics, section} (current section
  accSuggestLessonPlan: (payload) => makeACCPostRequest('suggest/lesson/plan/v2', JSON.stringify(payload.parseTextRequestDto), configACCWithoutAuth()),
  
  // actually go and create the lessons
  accCreteLessons: (payload) => makeACCPostRequest('create/from/sections/', JSON.stringify(payload.parseTextRequestDto), configACCWithoutAuth()),
  accCreteTopic: (payload) => makeACCPostRequest('create/course/', JSON.stringify(payload.parseTextRequestDto), configWithAuth()),

  // v2 create a single lesson
  accCreateLesson: (payload) => makeACCPostRequest('create/lesson/', JSON.stringify(payload), configWithAuth()),

  // create quiz to see questions in lesson panel
  accCreateQuiz: (payload) => makeACCPostRequest('create/quiz/', JSON.stringify(payload), configACCWithoutAuth()),
  
  // create stories
  accCreateStory: (payload) => makeACCPostRequest('create/story/', JSON.stringify(payload), configACCWithoutAuth()),
  
  // create dialogue plots etc
  accCreateDialogue: (payload) => makeACCPostRequest('create/dialogue/', JSON.stringify(payload), configACCWithoutAuth()),

  // create fill in the blank suggestions
  accCreateFillInBlanks: (payload) => makeACCPostRequest('create/fill/in/blanks/questions/', JSON.stringify(payload), configACCWithoutAuth()),

  // update the title and description of a lesson plan
  accUpdateTitleAndDescription: (payload) => makeACCPostRequest('regenerate/title/description/', JSON.stringify(payload), configACCWithoutAuth()),

  // create a scenario or scenarios to if the author clicks the "generate suggested scenarios" option
  accSuggestScenario: (payload) => makeACCPostRequest('suggest/scenario/', JSON.stringify(payload), configACCWithoutAuth()),

  // create a compliance lesson with the specified stages. Used for rapid user testing
  accCreateLessonWithSelectedStages: (payload) => makeACCPostRequest('create/selected/stages', JSON.stringify(payload), configWithAuth()),

  accParseTextStatus: () => makeACCGetRequest('parse/text/status', configACCWithoutAuth()),

  // image generation
  accSuggestImages: (payload) => makeACCPostRequest('suggest/images/', JSON.stringify(payload), configWithAuth()),

  accSuggestImage: (payload) => makeACCPostRequest('generate/image/', JSON.stringify(payload), configWithAuth()),
  
  accSuggestGenericImage: (payload) => makeACCPostRequest('generate/lesson/image', JSON.stringify(payload), configWithAuth()),
  
  /////
  // LANGUAGE
  /////
  
  accLanguageSuggestVocabSection: (payload) => makeACCPostRequest('suggest/vocab/concepts/', JSON.stringify(payload.vocabRequestDto), configACCWithoutAuth()),
  
  accLanguageChapterOptions:  (payload) => makeACCPostRequest('suggest/narrative', JSON.stringify(payload.narrativeRequestDto), configACCWithoutAuth()),
  
  accLanguageLessonDistribution: (payload) => makeACCPostRequest('suggest/language/lesson/plans', JSON.stringify(payload.languageLessonDistributionRequestDto), configACCWithoutAuth()),
  
  accRecreateLanguageSingleLessonPlan: (payload) => makeACCPostRequest('recreate/language/single/lesson/plan', JSON.stringify(payload), configACCWithoutAuth()),
  
  // expand on chapter to construct a lessonPlanChapter
  accChapterConceptTranslate: (payload) => makeACCPostRequest('translate/vocab/concepts/', JSON.stringify(payload), configACCWithoutAuth()),
  accChapterConceptsInflectedForms: (payload) => makeACCPostRequest('add/inflected/forms/', JSON.stringify(payload), configACCWithoutAuth()),
  accChapterNarrative: (payload) => makeACCPostRequest('make/narrative', JSON.stringify(payload), configACCWithoutAuth()),
  accChapterConceptExamples: (payload) => makeACCPostRequest('make/examples', JSON.stringify(payload), configACCWithoutAuth()),
  accChapterNarrativeDialogue: (payload) => makeACCPostRequest('make/narrative/dialogue', JSON.stringify(payload), configACCWithoutAuth()),
  accChapterTranslateNarrative: (payload) => makeACCPostRequest('translate/narrative', JSON.stringify(payload), configACCWithoutAuth()),
  
  
  // create lessons with LanguageLessonPlanDto
  
  accGenerateLesson: (payload) => makeACCPostRequest('generate/language/lesson/', JSON.stringify(payload), configWithAuth())
  
}