import React, { memo, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Zoom from '@mui/material/Zoom';
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CircleIcon from "@mui/icons-material/Circle";
import {ScenarioNodeType} from "../../../../Logic/ScenarioConstants";

export default memo(({ data }) => {
  const [hasEdited, setHasEdited] = useState(false);
  const [hasRequestedAdd, setHasRequestedAdd] = useState(false);
  const [isTextInFocus, setIsTextInFocus] = useState(false);

  const handleDuplicate = (event) => {
    data.onDuplicate(data.id, ScenarioNodeType.Brief)
    
    event.stopPropagation();
    event.preventDefault();
  };
  
  const handleAdd = (event) => {
    data.onAdd(data.id, 2)
    event.stopPropagation();
    event.preventDefault();
  };
  
  const handleAddOther = (event) => {
    data.onAddOther(data.id)
    event.stopPropagation();
    event.preventDefault();
  };
  
  const handleEndSelection = () => {
    if(hasEdited === true) {
      data.onUpdateSelection(data.id, hasRequestedAdd)
      setHasEdited(false)
    }
    setIsTextInFocus(false)
  }
  
  const changeText = (e, index) => {
    setHasEdited(true)
    data.onUpdateAnswerText(e.target.value, index, data.id, false, 0, 0, true)
  }

  const handlePlayClick = () => {
    data.onPlayAppFromNode(data.id)
  }

  const handleDeleteClick = () => {
    data.onDeleteNode(data.id)
  }
    
    const handleChangeSelectedNode = () => {
      setIsTextInFocus(true)
     }
     
  return (
    <>
      <Zoom in={true}>
        <div>
          <div style={{marginLeft: '20px', width: '150px' ,display:"flex", flexDirection:"row", textAlign: 'center', height: '25px', borderradius: '5px 5px 0px 0px'}}>
            <div style={{backgroundColor: '#EFEFF4', width: '41px'}} sx={{ float: 'left'}}>
              <IconButton onMouseDown={(event) => handlePlayClick()} sx={{ fontSize: "17px",  display: 'center', alignItems: 'left', marginLeft: '4px', justifyContent: 'center',  height: '26px', color: 'black'}}>
                <PlayCircleOutlineIcon sx={{ fontSize: "17px"}}/>
              </IconButton>
            </div>
          </div>
          <Card style={{minHeight: '135px', width: '220px', backgroundColor: 'white', borderStyle: 'solid',
            borderWidth: data?.validationErrors?.length > 0 ? '2px' : 0,
            borderColor: data?.validationErrors?.length > 0 ? 'red' : 'white',
            boxShadow: '0px 4px 4px 0px #00000040'
          }}>
            <Grid container >
              <Grid style={{backgroundColor: '#35323C', height: '35px'}} item xs={12} sm={12} md={12}>
                <Typography variant="body1" style={{
                  height: '100px',
                  paddingTop: '4px',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontFamily: 'Lato',
                }}>
                  Brief Stage
                </Typography>
              </Grid>
              
              <Grid item xs={12} sm={12} md={12} style={{marginTop: '5px'}}>
                <Typography variant="body1" style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  letterSpacing: '0.5px',
                  marginLeft: '11px',
                  marginRight: '5px',
                  marginBottom: '5px',
                  color: '#000000'
                }}>
                  Brief:
                </Typography>
                <Zoom in={true} style={{ transitionDelay:  0}}>
                  <TextField
                    style={{
                      fontStyle: 'normal', fontWeight: 400,fontSize: '14px',
                      letterSpacing: '0.5px',
                      marginLeft: '10px',width: '200px',
                      color: '#000000',
                      boxSizing: 'border-box',
                      borderRadius: '10px',
                        minHeight: '34px',
                    }}
                    disabled={data?.options?.length < 0 || data.isBusy || !data.canEditLesson }
                    onChange={(event) => changeText(event, 0)}  
                    hiddenLabel id="filled-basic"  
                    variant="filled"
                    multiline 
                    value={data?.options?.length > 0 ? data.options[0]: ""}  
                    InputProps={{ disableUnderline: true,margin:'dense' }} 
                    onBlur={handleEndSelection} onFocus={handleChangeSelectedNode} />
                </Zoom> 
              </Grid>


              <Divider style={{width:'100%', marginTop: '5px'}}/>
              {
                
                data.canEditLesson &&
                <Grid style={{backgroundColor: 'white', height: '40px'}} item xs={12} sm={12} md={12}>
                  <Button onClick={handleDuplicate} disabled={data.isBusy} style={{
                    color: data.isBusy? 'lightgrey' : '#4aa7ec',
                    width: '67px',
                    boxShadow: 'none',
                    borderRadius: '0',
                    borderLeft: '0px',
                    borderTop: '0px',
                    borderBottom: '0px',
                    borderColor: '#D1D1D6',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    height: '40px'
                  }} variant="outlined" size="small" color="primary">
                    Cutscene
                  </Button>
                  <Button onClick={handleAdd} disabled={data.isBusy} style={{
                    color: data.isBusy? 'lightgrey' : '#4aa7ec',
                    width: '66px',
                    boxShadow: 'none',
                    borderRadius: '0',
                    borderLeft: '0px',
                    borderTop: '0px',
                    borderBottom: '0px',
                    borderColor: '#D1D1D6',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    height: '40px'
                  }} variant="outlined" size="medium" color="primary">
                    Listen
                  </Button>
                  <Button
                    disabled={data.isBusy || isTextInFocus === true}
                    onClick={handleAddOther}
                    style={{
                      color: (data.isBusy || isTextInFocus) ? 'lightgrey' : '#4aa7ec',
                      width: '72px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }}
                    className={'nodrag'}
                    variant="outlined"
                    size="medium"
                    color="primary">
                    Other
                  </Button>
                </Grid>
              }
            </Grid>
          </Card>
        </div>
      </Zoom>

      <CircleIcon
        disabled={true} style={{position: 'absolute', top: '150px', right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
      />
      <Handle
        type="source"
        position="right"
        id="exit0"
        style={{ top: '154px',  width: '30px', height: '60px', borderRadius: 0, right: '-6px', background: 'transparent', zIndex: 999,border: 'none',
        }}
      />
    </>
  );
});