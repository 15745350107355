import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {makeStyles} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(theme => ({
  
  rowFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
    
}));


const ACCConceptChipsLessonPlan = (props) => {
  
  const classes = useStyles();

  const StyledChip = withStyles({
    label: {
      color: "var(--Dark-text, #35323C)", fontFamily: "Rubik", fontSize:14, fontStyle: "normal", fontWeight:400, lineHeight: "140%",},
  })(Chip);

  return (
    <div className={classes.rowFlex}>
      <Grid container>
      {
        props.concepts &&
        props.concepts.map((concept, index) => (
        <Grid item>
            <StyledChip variant="outlined" label={concept.text} index={index}  style={{ justifyContent: "center", alignItems: "center", color: 'black', marginRight: '3px', marginBottom: '5px', padding: '3px',  borderRadius:16, border: "1px solid rgba(102, 67, 160, 0.12)", background: "var(--White, #FFF)",margin: '1px'}}
            >
          </StyledChip>
        </Grid>
      ))
      }
      </Grid>
    </div>
  );
};

ACCConceptChipsLessonPlan.propTypes = {};

const mapStateToProps = (state) => {
  return {
    organization: state.organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCConceptChipsLessonPlan)