import React, {useState} from "react";
import {Typography,Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@mui/material/Box';
import moment from "moment";
import { CustomLessonAuthorNoteType } from "../../../Logic/ScenarioConstants";
import RestoreIcon from '@mui/icons-material/Restore';


const useStyles = makeStyles(theme => ({
}));



const ScenarioAuthorComment = (props) => {
    const classes = useStyles();
    const [mentorX, setMentorX] = useState(250)
    const [mentorY, setMentorY] = useState(0)
    const [mentorWidth, setMentorWidth] = useState(50)
    const [mentorHeight, setMentorHeight] = useState(100)
    const [screenWidth, setScreenWidth] = useState(245)
    const [placementWidth, setPlacementWidth] = useState(436)
    const [placementHeightPadding, setPlacementHeightPadding] = useState(200)

    const handleMoveMentor = (x, y) => {
      let localScreenHeight = 0

      let newMentorWidth = parseFloat(mentorWidth, 10)
      let newMentorHeight = parseFloat(mentorHeight, 10)
      
      setMentorX(x)
      setMentorY(y)
  
    };
    const BoxMentor = () => (
      <div
        className="box"
        style={{
          margin: 0,
          height: "100%",
          background:
            "url(../../../assets/images/Ron.png) no-repeat bottom",
          backgroundSize: "100% auto"
        }}
      >
        <article className="media">
          <div className="media-left"></div>
          <div className="media-content">
            <div className="content"></div>
          </div>
        </article>
      </div>
    );

    return (

      <Box style={{ marginLeft: '19px', marginTop: '19px',marginRight: '19px', background: '#FFFFFF', border: '1.8px solid #DFDFDF', boxSizing: 'borderBox', borderRadius: '5px', elevation: '0'}} sx={{ boxShadow: 0 }}>
       <Grid container>
        <Grid item xs={12} sm={12} md={12} style={{}}> 
          <Grid container style={{background: '#F8F8F8'}}>
            <Grid item xs={8} sm={8} md={8} style={{}}> 
              <Typography 
                variant='body1'  
                style={{                            
                fontSize: 18,
                fontFamily: 'Lato',
                fontWeight: 550,
                marginLeft: '12px',
                marginTop: '10px',
                marginBottom: '2px'
              }}>
                {props.comment.authorName}
              </Typography>
            </Grid>
           
            <Grid item xs={3} sm={3} md={3} style={{}}> 
                <Grid container style={{display: 'flex', justifyContent: 'center', marginLeft: '15px', textAlign: 'center', alignItems: 'center'}}>
                    <Grid item xs={6} sm={6} md={6} style={{}}> 
                      {props.comment.authorNoteType === CustomLessonAuthorNoteType.Created && 
                        <IconButton size="medium" color={'white'} onClick={() => props.onUpdateCommentState(CustomLessonAuthorNoteType.Accepted, props.comment.createdAt)} >
                          <DoneIcon style={{fill: "#143349", width: 22, height: 22, paddingRight: '3px'}}   /> 
                        </IconButton>
                      }
                      {props.comment.authorNoteType === CustomLessonAuthorNoteType.Accepted && 
                        <IconButton size="medium" color={'white'} onClick={() => props.onUpdateCommentState(CustomLessonAuthorNoteType.Created, props.comment.createdAt)} >
                          <RestoreIcon style={{fill: "#143349", width: 22, height: 22, paddingRight: '3px'}}   /> 
                        </IconButton>
                      }
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} > 
                    { props.userId === props.comment.authorUserId &&
                      <IconButton size="medium" color={'white'} onClick={() => props.onDeleteComment(props.comment.createdAt)} style={{
                        justifyContent: 'right',
                        textAlign: 'right',
                        alignItems: 'right',
                        fontWeight: 'bold'}} >
                          <DeleteIcon style={{fill: "#143349", width: 22, height: 22,}} /> 
                      </IconButton>
                    }
                    </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{}}> 
          <Typography 
              variant='body1'  
              style={{                            
              fontSize: 14,
              fontFamily: 'Lato',
              marginTop: '7px',
              marginBottom: '5px',
              marginLeft: '12px',
              color: '#35323C'
            }}>
              {props.comment.note}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{}}> 
          <Typography 
              variant='body1'  
              style={{                            
              fontSize: 14,
              fontFamily: 'Lato',
              fontWeight: 'bold',
              marginLeft: '12px',
              marginBottom: '9px',
              color:'#585858'
            }}>
              {moment(props.comment.createdAt).fromNow()}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    );
};

ScenarioAuthorComment.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(ScenarioAuthorComment)