import React from 'react';
// @material-ui/icons
import {
    Dashboard,
    Business,
    School,
    Person,
    Settings
} from '@material-ui/icons'


import { IntlProvider, FormattedMessage } from 'react-intl';

// Private views
import TopLevelDashboard from "Views/Private/Dashboard";
import CreatorDashboard from "Views/Private/Creator/CreatorDashboard";

import CreatorEditTopic from 'Views/Private/CreatorTopic/CreatorEditTopic';
 
import NewClassroom from "Views/Private/Classroom/NewClassroom";

import StudentOverview from "Views/Private/Students/StudentOverview";

import ClassroomDashboard from "Views/Private/Classroom/ClassroomDashboard";
import ClassroomSettings from "Views/Private/Classroom/ClassroomSettings";
import ClassroomInvite from "Views/Private/Classroom/ClassroomInvite";
import SettingsView from "Views/Private/Settings";
import CreateOrganization from "Views/Private/OrganizationAdministration/CreateOrganization";
import EditOrganization from "Views/Private/OrganizationAdministration/EditOrganization";

import ResearchAndDevelopment from "Views/Private/ResearchAndDevelopment/ResearchAndDevelopment";
import ImageMatcher from "Views/Private/ResearchAndDevelopment/ImageMatcher";

import ScenarioCreate from 'Views/Private/Scenarios/ScenarioCreate';
import ScenarioGenius from 'Views/Private/Scenarios/ScenarioGenius';

import TutorialMain from 'Views/Private/Scenarios/Tutorials/TutorialMain';
import LessonBuilderEncapsulator from "../Views/Private/Scenarios/LessonBuilderEncapsulator";
import ClassroomStatistics from "../Views/Private/Classroom/ClassroomStatistics";
import OverallStats from "../Views/Private/OverallDashboard/OverallStats";
import OverallSearch from "../Views/Private/OverallDashboard/OverallSearch";
import ClassroomAdminSettings from "../Views/Private/Classroom/ClassroomAdminSettnings";
import ClassroomCourses from "../Views/Private/Classroom/ClassroomCourses";
import CourseGroupOverview from "../Views/Private/Creator/CourseGroupOverview";
import OrganizationsOverview from "../Views/Private/OrganizationAdministration/OrganizationsOverview";
import CreatorCourseList from "../Views/Private/Creator/CreatorCourseList";

import AutoLanguageCourseCreateController from "../Views/Private/AutoCourseCreate/AutoLanguageCourseCreateController";


import OrganizationCourses from "../Views/Private/Organization/OrganizationCourses";
import OrganizationSettings from "../Views/Private/Organization/OrganizationSettings";
import OrganizationAdmins from "../Views/Private/Organization/OrganizationAdmins";
import OrganizationStatistics from "../Views/Private/Organization/OrganizationStatistics";
import OrganizationLicenses from "../Views/Private/Organization/OrganizationLicenses";
import OrganizationDashboard from "Views/Private/Organization/OrganizationDashboard";
import ClassroomStudentListView from "Views/Private/Classroom/ClassroomStudentListView";
import OrganizationStudentsOverview from "../Views/Private/Organization/OrganizationStudentsOverview";
import OrganizationClassrooms from "../Views/Private/Organization/OrganizationClassrooms";
import EditOrganizationCourses from "../Views/Private/OrganizationAdministration/EditOrganizationCourses";
import EditOrganizationImageAssets from "../Views/Private/OrganizationAdministration/EditOrganizationImageAssets";
import EditOrganizationAdmins from "../Views/Private/OrganizationAdministration/EditOrganizationAdmins";
import CapeeshSkillsOverview from "../Views/Private/Skills/CapeeshSkillsOverview";
import CreatorDashboardShowcase from "../Views/Private/Creator/CreatorDashboardShowcase";

import AdministratorReporting from "../Views/Private/AdminReporting/AdministratorReporting";
import AutoCourseCreateControllerImproved
    from "../Views/Private/AutoCourseCreate/AutoCourseCreateCompliance/AutoCourseCreateControllerImproved";

const privateRoutes = [
    {
        path: "/",
        name: <FormattedMessage id="globalwords.dashboard" defaultMessage="Dashboard" />,
        id: 'Dashboard',
        icon: Dashboard,
        component: TopLevelDashboard,
        acceptRoles: [
            "Editor",
            "Teacher",
            "Admin",
            "Tutor",
            "Translator",
            "Reporting",
            "QA"
        ]
    },

    
    {
        path: "/creator/",
        name: <FormattedMessage id="globalwords.creator" defaultMessage="Creator"/>,
        id: 'CreatorDashboard',
        icon: Dashboard,
        component: CreatorDashboard,
        acceptRoles: [
            "Editor",
            "Teacher",
            "Admin",
            "QA"
        ]
    },

    {
        path: "/creator/overview",
        name: <FormattedMessage id="globalwords.creator" defaultMessage="Creator"/>,
        id: 'CreatorDashboard',
        icon: Dashboard,
        component: CreatorDashboardShowcase,
        acceptRoles: [
            "Editor",
            "Teacher",
            "Admin",
            "QA",
            "Reporting",
        ]
    },
  
    {
        path: "/creator/topic/list",
        name: <FormattedMessage id="globalwords.courselist" defaultMessage="Course List"/>,
        id: 'CreatorCourseList',
        icon: Dashboard,
        component: CreatorCourseList,
        acceptRoles: [
            "Admin",
        ]
    },
    

    {
        path: "/creator/course/group/:courseGroupId",
        name: <FormattedMessage id="globalwords.coursegroup" defaultMessage="Course"/>,
        id: 'CourseGroupOverview',
        icon: Dashboard,
        component: CourseGroupOverview,
        acceptRoles: [
            "Editor",
            "Teacher",
            "Admin",
            "QA"
        ]
    },
    {
        path: "/creator/course/group/:courseGroupId/topic/:topicId",
        ame: <FormattedMessage id="privateroutes.editcourse" defaultMessage="Creator Edit Course"/>,
        id: 'Creator Edit course',
        icon: Business,
        component: CreatorEditTopic,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Translator",
            "QA"
        ]
    },
    {
        path: "/creator/course/group/:courseGroupId/topic/:topicId/lesson",
        ame: <FormattedMessage id="privateroutes.editcourse" defaultMessage="Creator Edit Course"/>,
        id: 'Creator Edit course',
        icon: Business,
        component: CreatorEditTopic,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Translator",
            "QA"
        ]
    },

    {
        path: "/creator/course/group/:courseGroupId/topic/:topicId/acc",
        name: <FormattedMessage id="privateroutes.build.a.new.course" defaultMessage="Auto course create"/>,
        id: 'Auto course create',
        icon: Business,
        component: AutoCourseCreateControllerImproved,
        acceptRoles: [
            "Admin",
            "Teacher",
        ]
    },

    {
        path: "/creator/course/group/:courseGroupId/topic/:topicId/acc/language",
        name: <FormattedMessage id="privateroutes.build.a.new.lanugage.course" defaultMessage="Auto Lanugage course create"/>,
        id: 'Auto Language course create',
        icon: Business,
        component: AutoLanguageCourseCreateController,
        acceptRoles: [
            "Admin",
            "Teacher",
        ]
    },

    
    
  
    {
        path: "/creator/course/group/:courseGroupId/topic/:topicId/lesson/edit/:customLessonId",
        name: <FormattedMessage id="privateroutes.lessonbuilder" defaultMessage="Lesson builder"/>,
        id: 'Lesson Builder',
        icon: Business,
        component: LessonBuilderEncapsulator,
        acceptRoles: [
            "Admin",
            "Teacher",
            "QA"
        ]
    },

    {
        path: "/creator/course/group/:courseGroupId/topic/:topicId/lesson/setup",
        name: <FormattedMessage id="privateroutes.build.a.new.scenario" defaultMessage="Build a new scenario in a course"/>,
        id: 'Build a new scenario in a course',
        icon: Business,
        component: ScenarioCreate,
        acceptRoles: [
            "Admin",
            "Teacher",
        ]
    },
    {
        path: "/creator/course/group/:courseGroupId/topic/:topicId/genius/setup",
        name: <FormattedMessage id="privateroutes.build.a.new.scenario" defaultMessage="Build a new scenario in a course"/>,
        id: 'Build a new scenario in a course',
        icon: Business,
        component: ScenarioGenius,
        acceptRoles: [
            "Admin",
            "Teacher",
        ]
    },


    {
        path: "/dashboard/",
        name: <FormattedMessage id="globalwords.usermanagement" defaultMessage="User management" />,
        id: 'ClassroomDashboardView',
        icon: Dashboard,
        component: OrganizationDashboard,
        acceptRoles: [
            "Editor",
            "Teacher",
            "Admin",
            "Tutor",
            "Translator",
            "Reporting",
            "QA"
        ]
    },

    {
        path: "/skills",
        name: <FormattedMessage id="globalwords.usermanagement" defaultMessage="User management" />,
        id: 'ClassroomDashboardView',
        icon: Dashboard,
        component: CapeeshSkillsOverview,
        acceptRoles: [
            "Editor",
            "Teacher",
            "Admin",
            "Tutor",
            "Translator",
            "Reporting",
            "QA"
        ]
    },
  
    {
        path: "/dashboard/dashboard",
        name: <FormattedMessage id="privateroutes.customerdashboard" defaultMessage="Customer Dashboard" />,
        id: 'Customer Dashboard',
        icon: Settings,
        component: OrganizationDashboard,
        acceptRoles: [
            "Admin",
            "Reporting",
            "Teacher"
        ]
    },

    {
        path: "/dashboard/classrooms",
        name: <FormattedMessage id="privateroutes.customerdashboard" defaultMessage="Customer Classrooms" />,
        id: 'Customer Classrooms',
        icon: Settings,
        component: OrganizationClassrooms,
        acceptRoles: [
            "Admin",
            "Reporting",
            "Teacher",
            "QA"
        ]
    },


    {
        path: "/dashboard/licenses",
        name: <FormattedMessage id="privateroutes.customerdashboard" defaultMessage="Customer Licenses" />,
        id: 'Customer Dashboard',
        icon: Settings,
        component: OrganizationLicenses,
        acceptRoles: [
            "Admin",
            "Reporting"
        ]
    },

    {
        path: "/dashboard/courses",
        name: <FormattedMessage id="privateroutes.customercourses" defaultMessage="Customer Courses" />,
        id: 'Customer Courses',
        icon: Settings,
        component: OrganizationCourses,
        acceptRoles: [
            "Admin",
            "Reporting",
            "Teacher"
        ]
    },

    {
        path: "/dashboard/settings",
        name: <FormattedMessage id="privateroutes.customersettings" defaultMessage="Customer Settings" />,
        id: 'Customer Settings',
        icon: Settings,
        component: OrganizationSettings,
        acceptRoles: [
            "Admin",
            "Reporting"
        ]
    },
    {
        path: "/dashboard/admins",
        name: <FormattedMessage id="privateroutes.customeradmins" defaultMessage="Customer Admins" />,
        id: 'Customer Admins',
        icon: Settings,
        component: OrganizationAdmins,
        acceptRoles: [
            "Admin",
            "Reporting"
        ]
    },
    
    {
        path: "/dashboard/statistics",
        name: <FormattedMessage id="privateroutes.customerorgstatistics" defaultMessage="Customer org Statistics" />,
        id: 'Customer Dashboard',
        icon: Settings,
        component: OrganizationStatistics,
        acceptRoles: [
            "Admin",
            "Reporting"
        ]
    },

    {
        path: "/dashboard/students",
        name: <FormattedMessage id="privateroutes.customerorgstudents" defaultMessage="Customer org Students" />,
        id: 'Customer Dashboard',
        icon: Settings,
        component: OrganizationStudentsOverview,
        acceptRoles: [
            "Admin",
            "Reporting",
            "Teacher"
        ]
    },

    
  

    {
        path: "/dashboard/admin/editorg",
        name: <FormattedMessage id="privateroutes.editorganization" defaultMessage="Edit Organization"/>,
        id: 'Edit Organization',
        icon: Settings,
        component: EditOrganization,
        acceptRoles: [
            "Admin",
        ]
    },

    {
        path: "/dashboard/admin/organization/courses",
        name: <FormattedMessage id="privateroutes.editorganization" defaultMessage="Edit Organization Courses"/>,
        id: 'Edit Organization Courses',
        icon: Settings,
        component: EditOrganizationCourses,
        acceptRoles: [
            "Admin",
        ]
    },
    {
        path: "/dashboard/admin/organization/assets",
        name: <FormattedMessage id="privateroutes.editorganization" defaultMessage="Edit Organization Courses"/>,
        id: 'Edit Organization Courses',
        icon: Settings,
        component: EditOrganizationImageAssets,
        acceptRoles: [
            "Admin",
        ]
    },
    {
        path: "/dashboard/admin/organization/admins",
        name: <FormattedMessage id="privateroutes.editorganization" defaultMessage="Edit Organization Administrators"/>,
        id: 'Edit Organization Administrators',
        icon: Settings,
        component: EditOrganizationAdmins,
        acceptRoles: [
            "Admin",
        ]
    },
    {
        path: "/dashboard/admin/organization/admins/:userId",
        name: <FormattedMessage id="privateroutes.customerorgstudents" defaultMessage="Admin single user page" />,
        id: 'Customer Dashboard',
        icon: Settings,
        component: StudentOverview,
        acceptRoles: [
            "Admin",
        ]
    },
  
    {
        path: "/dashboard/students/user/:userId",
        name: <FormattedMessage id="globalwords.user" defaultMessage="Student" />,
        id: 'Student',
        icon: Person,
        component: StudentOverview,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Reporting"
        ]
    },
    
    {
        path: "/dashboard/classroom/:classroomId",
        name: <FormattedMessage id="globalwords.classroomdashboard" defaultMessage="Classroom Dashboard" />,
        id: 'Classroom',
        icon: School,
        component: ClassroomDashboard,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Reporting",
            "QA"
        ]
    },
    {
        path: "/settings",
        name: <FormattedMessage id="globalwords.settings" defaultMessage="Settings" />,
        id: 'Settings',
        icon: School,
        component: SettingsView,
        acceptRoles: [
            "Admin",
        ]
    },
    {
        path: "/dashboard/classroom/:classroomId/dashboard",
        name: <FormattedMessage id="globalwords.classroomdashboard" defaultMessage="Classroom Dashboard" />,
        id: 'ClassroomDashboard',
        icon: School,
        component: ClassroomDashboard,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Translator",
            "Reporting",
            "QA"
        ]
    },
    {
        path: "/dashboard/classroom/:classroomId/courses",
        name: <FormattedMessage id="globalwords.courses" defaultMessage="Courses" />,
        id: 'Courses',
        icon: School,
        component: ClassroomCourses,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Translator",
            "QA",
            "Reporting"
        ]
    },
    {
        path: "/dashboard/classroom/:classroomId/students",
        name: <FormattedMessage id="globalwords.users" defaultMessage="Students" />,
        id: 'Classroom',
        icon: Person,
        component: ClassroomStudentListView,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Reporting",
            "QA"
        ]
    },
    {
        path: "/dashboard/classroom/:classroomId/students/invite-students",
        name: <FormattedMessage id="globalwords.users" defaultMessage="Students" />,
        id: 'ClassroomInvite',
        icon: Person,
        component: ClassroomInvite,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Reporting"
        ]
    },
    {
        path: "/dashboard/classroom/:classroomId/students/user/:userId",
        name: <FormattedMessage id="globalwords.user" defaultMessage="Student" />,
        id: 'Student',
        icon: Person,
        component: StudentOverview,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Reporting",
            "QA"
        ]
    },
    
    {
        path: "/creator/topic/list/:topicId",
        ame: <FormattedMessage id="privateroutes.editcourse" defaultMessage="Creator Edit Course"/>,
        id: 'Creator Edit course',
        icon: Business,
        component: CreatorEditTopic,
        acceptRoles: [
            "Admin",
            "Teacher",
            "QA"
        ]
    },
    
    {
        path: "/creator/topic/list/:topicId/lessons/edit/:customLessonId",
        name: <FormattedMessage id="privateroutes.assigncoursetoclassroom" defaultMessage="Upload images for scenarios"/>,
        id: 'Edit scenarios',
        icon: Business,
        component: LessonBuilderEncapsulator,
        acceptRoles: [
            "Admin",
            "Teacher",
            "QA",
        ]
    },

    {
        path: "/creator/topic/list/:topicId/lesson/setup",
        name: <FormattedMessage id="privateroutes.build.a.new.scenario" defaultMessage="Build a new scenario in a course"/>,
        id: 'Build a new scenario in a course',
        icon: Business,
        component: ScenarioCreate,
        acceptRoles: [
            "Admin",
            "Teacher",
        ]
    },
    {
        path: "/org/stats",
        name: <FormattedMessage id="globalwords.organizationreporting" defaultMessage="Organization Reporting" />,
        id: 'Organization Reporting',
        icon: Business,
        component: AdministratorReporting,
        acceptRoles: [
            "Admin",
            "Reporting",
        ]
    },
    {
        path: "/admin",
        name: <FormattedMessage id="globalwords.organizations" defaultMessage="Organizations" />,
        id: 'Organizations',
        icon: Business,
        component: OrganizationsOverview,
        acceptRoles: [
            "Admin",
            "Reporting",
            "Teacher"
        ]
    },
    {
        path: "/admin/organizations",
        name: <FormattedMessage id="globalwords.organizations" defaultMessage="Organizations" />,
        id: 'Organizations',
        icon: Business,
        component: OrganizationsOverview,
        acceptRoles: [
            "Admin", 
        ]
    },
    {
        path: "/admin/edit-organization",
        name: <FormattedMessage id="privateroutes.editorganization" defaultMessage="Edit Organization"/>,
        id: 'Edit Organization',
        icon: Settings,
        component: EditOrganization,
        acceptRoles: [
            "Admin",
        ]
    },
    {
        path: "/admin/create-organization",
        name: <FormattedMessage id="privateroutes.createorganization" defaultMessage="Create Organization" />,
        id: 'Create organization',
        icon: Settings,
        component: CreateOrganization,
        acceptRoles: [
            "Admin",
        ]
    },

    {
        path: "/creator/courses/:topicId/lessons/setup",
        name: <FormattedMessage id="privateroutes.build.a.new.scenario" defaultMessage="Build a new scenario in a course"/>,
        id: 'Build a new scenario in a course',
        icon: Business,
        component: ScenarioCreate,
        acceptRoles: [
            "Admin",
            "Teacher",
        ]
    },
    
    {
        path: "/creator/courses/:topicId/genius/setup",
        name: <FormattedMessage id="privateroutes.build.a.new.scenario" defaultMessage="Build a new scenario in a course"/>,
        id: 'Build a new scenario in a course',
        icon: Business,
        component: ScenarioGenius,
        acceptRoles: [
            "Admin",
            "Teacher",
        ]
    },
    {
        path: "/dashboard/new-classroom",
        name: <FormattedMessage id="privateroutes.newcohort" defaultMessage="New Cohort"/>,
        id: 'New classroom',
        //icon: ContentPaste,
        component: NewClassroom,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Reporting"
        ]
    },
    {
        path: "/dashboard/classroom/:classroomId/settings",
        name:  <FormattedMessage id="privateroutes.editcohort" defaultMessage="Edit Cohort"/>,
        id: 'Edit cohort',
        //icon: ContentPaste,
        component: ClassroomSettings,
        acceptRoles: [
            "Admin",
            "Reporting"
        ]
    },
    {
        path: "/dashboard/classroom/:classroomId/admins",
        name:  <FormattedMessage id="privateroutes.editcohort" defaultMessage="Edit Classroom Admins"/>,
        id: 'Edit Classroom Admins',
        component: ClassroomAdminSettings,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Reporting"
        ]
    },
    {
        path: "/dashboard/classroom/:classroomId/statistics",
        name:  <FormattedMessage id="privateroutes.statistics" defaultMessage="Statistics"/>,
        id: 'Statistics',
        component: ClassroomStatistics,
        acceptRoles: [
            "Admin",
            "Teacher",
            "Reporting",
        ]
    },
    {
        path: "/research-and-development",
        name:  <FormattedMessage id="privateroutes.rnd" defaultMessage="Research and Development"/>,
        id: 'Research and Development',
        //icon: ContentPaste,
        component: ResearchAndDevelopment,
        acceptRoles: [
            "Admin",
        ]
    },
    {
        path: "/research-and-development/image-matcher",
        name:  <FormattedMessage id="privateroutes.rnd.image.matcher" defaultMessage="Image Matching Service"/>,
        id: 'Image Matching Service',
        //icon: ContentPaste,
        component: ImageMatcher,
        acceptRoles: [
            "Admin",
        ]
    },

    {
        path: "/creator/tutorials",
        name:  <FormattedMessage id="privateroutes.tutorials" defaultMessage="Tutorials"/>,
        id: 'Tutorials',
        component: TutorialMain,
        acceptRoles: [
            "Admin",
            "Teacher",
            "QA"
        ]
    },

    {
        path: "/overall",
        name:  <FormattedMessage id="privateroutes.overall.homepage.title" defaultMessage="Capeesh stats"/>,
        id: 'CapeeshStats',
        component: OverallStats,
        acceptRoles: [
            "Admin",
        ]
    },
    {
        path: "/admin/overall/stats",
        name:  <FormattedMessage id="privateroutes.overall.stats.title" defaultMessage="Capeesh stats"/>,
        id: 'CapeeshStats',
        component: OverallStats,
        acceptRoles: [
            "Admin",
        ]
    },
    {
        path: "/admin/overall/search",
        name:  <FormattedMessage id="privateroutes.overall.search.title" defaultMessage="Capeesh search"/>,
        id: 'CapeeshSearch',
        component: OverallSearch,
        acceptRoles: [
            "Admin",
        ],
        hideFooter: true
    },
  
  
];

export default privateRoutes;