import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {makeStyles, Typography} from "@material-ui/core";
import {AnimatePresence, motion} from "framer-motion";
import  { ReactComponent as SmallSpinner } from "assets/images/spinner-large.svg";
import  { ReactComponent as SmallCheckmark } from "assets/images/smallCircleCheckmark.svg";
import {CapeeshColors} from "../../../../../assets/ColorPalette";

const useStyles = makeStyles(theme => ({
    lessonGenerationLoadingBar:
        {
            width: '55%',
            height: 40,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 8,
            background: '#09284F',
            boxShadow: '0px 4px 16px rgba(9, 40, 79, 0.32)',
            borderRadius: 16,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
            display: 'inline-flex',
            zIndex: 4,
            bottom: 0,
            position: "fixed"
        },
    lessonGeneratingLoadingBarText:
        {
            color: 'white',
            fontSize: 14,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    lessonGeneratingLoadingBarLessonNameText:
        {
            color: 'white',
            fontSize: 14,
            fontFamily: 'Rubik',
            fontStyle: 'italic',
            fontWeight: '400',
            wordWrap: 'break-word'
        },

    saveButton:
        {
            marginLeft: '20px',
            width: 139,
            height: 35,
            paddingLeft: 16,
            paddingRight: 16,
            background: CapeeshColors.Blue,
            borderRadius: 8,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
            display: 'flex',
            cursor: "pointer",
        },
}));

const LessonPlanRegenBar = (props) => {
    
    const classes = useStyles();

    return (
        <motion.div initial={{ y: 75 }} animate={{ y: !props.shouldChangeToFinishMode ? 0 : 75 }} transition={{ duration: 0.3, delay: props.shouldChangeToFinishMode ? 1.5 : 0 }} className={classes.lessonGenerationLoadingBar}>
            {
                <Fragment>
                    {
                        props.isInGenerationList &&
                        <Fragment>
                            <motion.div animate={{rotate: 360}}
                                        transition={{duration: 1, ease: "linear", repeat: Infinity}}
                                        style={{transformOrigin: '50% 45%', marginRight: 8}}>
                                <SmallSpinner style={{width: 24, height: 24}}/>
                            </motion.div>
                            <Typography className={classes.lessonGeneratingLoadingBarText}>
                            <span className={classes.lessonGeneratingLoadingBarLessonNameText}>
                                {props.lessonName}
                            </span>
                                {" is being generated."}
                            </Typography>
                        </Fragment>
                    }
                    {
                        ((!props.shouldChangeToFinishMode && !props.isInGenerationList && props.hasChanges) ||
                        (props.shouldChangeToFinishMode && !props.isInGenerationList && props.hasChanges)) &&
                        <Fragment>
                            <Typography className={classes.lessonGeneratingLoadingBarText}>
                                <span className={classes.lessonGeneratingLoadingBarLessonNameText}>
                                    {props.lessonName}
                                </span>
                                {" has changes."}
                            </Typography>
                            <div className={classes.saveButton} onClick={() => props.onMakeLessonFromLessonPlan(props.lessonPlanId)}>
                                <Typography className={classes.saveButtonText}>
                                    Regenerate lesson
                                </Typography>
                            </div>
                        </Fragment>
                    }
                    {
                        ((props.shouldChangeToFinishMode && !props.isInGenerationList && !props.hasChanges) || (!props.isInGenerationList && !props.hasChanges)) &&
                        <Fragment>
                            {
                                <Fragment>
                                    <SmallCheckmark style={{ marginRight: 8 }}></SmallCheckmark>
                                    <Typography className={classes.lessonGeneratingLoadingBarLessonNameText}>
                                        {props.lessonName}
                                        <span className={classes.lessonGeneratingLoadingBarText}>
                                        {" has been updated."}
                                    </span>
                                    </Typography>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
        </motion.div>
    );
};

LessonPlanRegenBar.propTypes = {};

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonPlanRegenBar)