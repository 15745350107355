import React, {useEffect, useState} from "react";
import {
    Card,
    Button,
    Grid,
    CardContent,
    Typography,
    List,
    ListItem
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AudioPlayerMini from "../ScenarioComponents/AudioPlayerMini";
import {FileReferenceAudioUsageType, FileReferenceGenderType} from "../../../../Logic/FileReferenceConstants";

import moment from "moment/moment";

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: '5px',
    marginBottom: '5px',
  },
  scenario: {
    paddingTop: '30px'
  },
  textFields: {
    marginBottom: '5px'
  },
  divList: {
    maxHeight: '405px',
    overflowY: 'scroll',
  }
}));


const AudioLibrary = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);    
  const [requestedAudioUrl, setRequestedAudioUrl] = useState(null);

  const [selectedAudioUrl, setSelectedAudioUrl] = useState(null);
  
  const handlePlayRequest = (apiUrl) => {
    setRequestedAudioUrl(apiUrl);
    setSelectedAudioUrl(apiUrl);
  };
  
  const handleAudioItemClick = (audioFileReference) => {
    setSelectedAudioUrl(audioFileReference.apiUrl)
  };
  
  const handleUseVoice = () => {
    setOpen(false);
    props.onAudioLibraryClick(props.audioFieldType, selectedAudioUrl)
  }
  
  const getAudioPostfixTextFromFileReference = (audio) => {
    var genderPostfix = "";
    if (audio.fileReferenceGenderType === FileReferenceGenderType.Male) {
      genderPostfix = "Male"
    } else if (audio.fileReferenceGenderType === FileReferenceGenderType.Female) {
      genderPostfix = "Female"
    }
    if (props.filterForVoiceOnlyAudio) {
        return genderPostfix;
    }
    switch (audio.fileReferenceAudioUsageType) {
      case FileReferenceAudioUsageType.BackgroundAudio:
        return "(Background) " + genderPostfix;
      case FileReferenceAudioUsageType.SfxAudio:
        return "(Sound effect) " + genderPostfix;
      case FileReferenceAudioUsageType.TextAudio:
        return "(Recorded voice) " + genderPostfix;
      default:
        return ""
    }
  }
  
  let audioReferences = []
  if (props.lesson?.lessonAudioReferences?.length > 0) {
    if (props.filterForVoiceOnlyAudio) {
      audioReferences = props.lesson.lessonAudioReferences.filter(x => x.fileReferenceAudioUsageType === FileReferenceAudioUsageType.TextAudio);
    } else {
      audioReferences = props.lesson.lessonAudioReferences;
    }  
  }
  
  return (
      <div>
        <Dialog
          open={open}
          onClose={(event) => { setOpen(false)}}          
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ paddingLeft: '100px', paddingRight: '100px', cursor: 'pointer' }}
          maxWidth="lg"
          fullWidth
         >
            <DialogTitle id="alert-dialog-title">{"Select audio"}</DialogTitle>
            <DialogContent>
              <Card>    
                <CardContent className={classes.card} >
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
                      <div className={classes.divList}>
                        <List component="nav">
                          {
                            audioReferences.sort((a, b) => moment(b.modifiedAt) - moment(a.modifiedAt)).map((audio, index) => (
                              <React.Fragment>
                                <Grid container >
                                  <Grid item xs={1} sm={1} lg={1}>
                                    <AudioPlayerMini 
                                      onPlayRequest={handlePlayRequest}
                                      currentAudioUrl={requestedAudioUrl}
                                      audioPlaying={props.audioPlaying} 
                                      isAudioDownloading={props.isAudioDownloading} 
                                      onPlayVoice={props.onPlayVoice}
                                      textAudioUrl={audio.apiUrl}
                                    />

                                  </Grid>
                                  <Grid item xs={11} sm={11} lg={11}>
                                  <ListItem
                                    key={index}
                                    button
                                    selected={selectedAudioUrl === audio.apiUrl}
                                    onClick={(event) => handleAudioItemClick(audio)}>
                                    <Typography variant='body1' className={classes.nameyourimage}>
                                        {audio.description ? audio.description : audio.niceName} {getAudioPostfixTextFromFileReference(audio)}
                                    </Typography>  
                                  </ListItem>
                                  </Grid>
           
                                </Grid>
         
                              </React.Fragment>
                            ))
                          }
                        </List>
                      </div>
                    </Grid>
                  </Grid> 
                </CardContent>
            </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleUseVoice()} color="primary" autoFocus>
                Use Voice
              </Button>
              <Button onClick={(event) => { setOpen(false)}} color="primary" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <Button size="small" variant='outlined' 
              onClick={event => setOpen(true)}>
                  Lesson Library
              </Button>
            </Grid>
        </Grid>
      </div>
    );
};

AudioLibrary.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(AudioLibrary)