import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { organizationActions} from '_state/actions';
import {UserStatisticsVisibility} from "../CapeeshConstants";
import OrganizationOverviewSidebar from "./Components/OrganizationOverviewSidebar";
import OrganizationClassroomList from "./Components/OrganizationClassroomList"
import SimpleFactBox from "../../../Components/Factbox/SimpleFactBox";
import LicenseBox from "./Components/LicenseBox";
import OrganizationCourseUsageList from "./Components/OrganizationCourseUsageList";
import {CapeeshColors} from "../../../assets/ColorPalette";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
    marginLeft: 'calc(15% + 25px)',
    marginRight:'25px',

  },
  main: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  mainSection: {
    marginBottom: '20px',
  },
  
}))

const OrganizationDashboard = (props) => {
  const classes = useStyles();

  const [showAddStudentsOption, setShowAddStudentsOption] = useState(false);
  const [canCreateNewClassroom, setCanCreateNewClassroom] = useState(false);
  
  const [currentOrganization, setCurrentOrganization] = useState(null);
  
  const [dayOfWeekUsageStats, setDayOfWeekUsageStats] = useState([]);
  const [showDayOfWeekUsageStats, setShowDayOfWeekUsageStats] = useState(false);
  
  const [showTimeOfDayStats, setShowTimeOfDayStats] = useState(false);
  const [timeOfDayStats, setTimeOfDayStats] = useState([]);
  const [timeOfDayStatsIndex, setTimeOfDayStatsIndex] = useState({});
  
  
  const [numberOfHoursPlayedText, setNumberOfHoursPlayedText] = useState("")
  const [numberOfHoursPlayedChangedText, setNumberOfHoursPlayedChangedText] = useState(""); 
  
  const [numberOfLessonsPassedText, setNumberOfLessonsPassed] = useState("");
  const [numberOfLessonsPassedChangedText, setNumberOfLessonsPassedChangedText] = useState("")
  
  const [numberOfOnboardedUsersText, setNumberOfOnboardedUsersText] = useState("");
  const [numberOfOnboardedUsersChangedText, setNumberOfOnboardedUsersChangedText] = useState("0")

  

  useEffect(() => {
    props.dispatch(organizationActions.getOrganizationFactBoxStats(props.organization.selectedOrganization));
    
    recalculateViewData();
  }, []);
  
  
  
  
  useEffect(() => {
    recalculateViewData();
  }, [props.organization])
  
  const recalculateViewData = () => {
    let tempCurrentOrganization;

    if (props.organization.myOrganizations && props.organization.selectedOrganization)
      tempCurrentOrganization = props.organization.myOrganizations.find(x => x.id === props.organization.selectedOrganization);
  
    setCurrentOrganization(tempCurrentOrganization);
    
    let tempDayOfWeekUsageStats = []
    let tempShowDayOfWeekUsageStats = false;
    if (props.organization.graphDataDayOfWeekOrganization && props.organization.graphDataDayOfWeekOrganization.organizationId === props.organization.selectedOrganization && props.organization.graphDataDayOfWeekOrganization.canShowGraphData) {
      tempDayOfWeekUsageStats = props.organization.graphDataDayOfWeekOrganization.graphStatsDayOfWeekDtos.map(pie => (
        {name: pie.dayOfWeekShort, value: pie.secondsCombined}
      ));
      tempShowDayOfWeekUsageStats = true;
    }
    setShowDayOfWeekUsageStats(tempShowDayOfWeekUsageStats);
    setDayOfWeekUsageStats(tempDayOfWeekUsageStats);


    let tempShowTimeOfDayStats = false;
    let tempTimeOfDayStats = []
    let tempTimeOfDayStatsIndex = {};
    if (props.organization.graphDataHourlyOrganization && props.organization.graphDataHourlyOrganization.organizationId === props.organization.selectedOrganization && props.organization.graphDataHourlyOrganization.canShowGraphData) {
      tempTimeOfDayStats = props.organization.graphDataHourlyOrganization.graphStatsHourlyDtos;
      tempTimeOfDayStats.forEach((el, index) => timeOfDayStatsIndex[el.hourOfTheDay] = el.minutesCombined);
      tempShowTimeOfDayStats = true;
    }
    setShowTimeOfDayStats(tempShowTimeOfDayStats);
    setTimeOfDayStats(tempTimeOfDayStats);
    setTimeOfDayStatsIndex(tempTimeOfDayStatsIndex);


    let tempShowAddStudentsOption = false;
    if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') {
      tempShowAddStudentsOption = true;
    } else if (props.user.organizationRole === 'reporting') {
      if (!props.user.classroomOnlyReportingUser && tempCurrentOrganization?.userStatisticsVisibility === UserStatisticsVisibility.SchoolMode) {
        tempShowAddStudentsOption = true;
      } else if (props.user.classroomOnlyReportingUser) {
        tempShowAddStudentsOption = true;
      }
    }
    setShowAddStudentsOption(tempShowAddStudentsOption);


    let tempCanCreateNewClassroom = false;
    if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher' && tempCurrentOrganization?.customerCreateNewClassroom) {
      tempCanCreateNewClassroom = true;
    } else if (tempCurrentOrganization?.customerCreateNewClassroom && props.user.organizationRole === 'reporting' && !props.user.classroomOnlyReportingUser) {
      tempCanCreateNewClassroom = true;
    }
    setCanCreateNewClassroom(tempCanCreateNewClassroom);
    
    
    const organizationId = props.organization.selectedOrganization
    if (props.organization.organizationIdToFactBoxStats?.hasOwnProperty(organizationId)) {
      const factBoxData = props.organization.organizationIdToFactBoxStats[props.organization.selectedOrganization]

      if (factBoxData.sessionMinutes > 60000) {
        const kHoursPlayed = factBoxData.sessionMinutes / 60 / 1000;
        setNumberOfHoursPlayedText(kHoursPlayed.toFixed(0) + "K");
      } else {
        const hoursPlayed =factBoxData.sessionMinutes / 60
        setNumberOfHoursPlayedText(hoursPlayed.toFixed(0));
      }
      var numbHoursPlayedChanged = factBoxData.newSessionMinutesLastThirtyDays / 60
      setNumberOfHoursPlayedChangedText(numbHoursPlayedChanged.toFixed(1))
      
      if (factBoxData.numberOfLessonsPassed > 2000) {
        setNumberOfLessonsPassed(factBoxData.numberOfLessonsPassed / 1000 + "K")
      } else {
        setNumberOfLessonsPassed(factBoxData.numberOfLessonsPassed.toString())
      }
      setNumberOfLessonsPassedChangedText(factBoxData.newLessonsPassedLastThirtyDays)
      
      
      setNumberOfOnboardedUsersText(factBoxData.numberOfOnboardedUsers.toString())
      setNumberOfOnboardedUsersChangedText(factBoxData.newNumberOfOnboardUsersLastThirtyDays.toString())
      
    }
    
  }
  return (
    <div className={classes.root} style={{marginTop: '25px'}}>
      <OrganizationOverviewSidebar
        path={props.computedMatch?.path}
      />
      <div>
        <div>
          <Grid container spacing={2}>

            <Grid items xs={12} style={{marginBottom: "0px", marginLeft:'20px', marginTop:"25px"}}>
              <Grid item xs={12}>
                <Typography variant='h4'>
                  Dashboard
                </Typography>
              </Grid>
            </Grid>
            
            <Grid item xs={12}>
              <div style={{display: 'flex', flexWrap:'wrap', gap: '16px', flexDirection: 'row', flex:1}}>
                <SimpleFactBox
                  title={<FormattedMessage id="globalwords.hoursspentintheapp" defaultMessage="Hours spent in the app"/>}
                  statsText={numberOfHoursPlayedText}
                  changeText={numberOfHoursPlayedChangedText}
                  changeDescription={<FormattedMessage id="globalwords.last30days" defaultMessage="Last 30 days"/>}
                />
                <SimpleFactBox
                  title={<FormattedMessage id="globalwords.passedlessons" defaultMessage="Passed lessons"/>}
                  statsText={numberOfLessonsPassedText}
                  changeText={numberOfLessonsPassedChangedText}
                  changeDescription={<FormattedMessage id="globalwords.last30days" defaultMessage="Last 30 days"/>}
                  backgroundColor={CapeeshColors.OffWhite}
                />
                <SimpleFactBox
                  title={<FormattedMessage id="globalwords.onboardedusers" defaultMessage="Onboarded users"/>}
                  statsText={numberOfOnboardedUsersText}
                  changeText={numberOfOnboardedUsersChangedText}
                  changeDescription={<FormattedMessage id="globalwords.last30days" defaultMessage="Last 30 days"/>}
                />

                <div style={{minWidth:'300px', maxWidth: '700px'}}>
                  <LicenseBox/>
                </div>
                
              </div>
            </Grid>
            
            <Grid item xs={12} sm={5} md={5}>
              <OrganizationCourseUsageList
                dispatch={props.dispatch}
              >
              </OrganizationCourseUsageList>
            </Grid>
            
            <Grid item xs={12} sm={7} md={7}>
              <OrganizationClassroomList
                showAddStudentsOption={showAddStudentsOption}
                dispatch={props.dispatch}
                canCreateNewClassroom={canCreateNewClassroom}
                minimizedView={true}
                showGoToAllClassrooms={true}
                showHeader={true}
              />
              
            </Grid>

            
            
          </Grid>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const {user, organization, usergroup, translator, metadata, lesson, topic} = state;
  return {
    user,
    organization,
    usergroup,
    translator,
    metadata,
    lesson,
    topic
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDashboard)
