
export const CapeeshLanguage = {

  "EnglishUK": 1,
  "French": 2,
  "Norwegian": 3,
  "Spanish": 4,
  "Dutch": 5,
  "German": 6,
  "KurdishKurmanji": 7,
  "Somali": 8,
  "Persian": 9,
  "Polish": 10,
  "Tamil": 11,
  "Thai": 12,
  "Turkish": 13,
  "Urdu": 14,
  "Vietnamese": 15,
  "Pashto": 16,
  "Bengali": 17,
  "Lithuanian": 18,
  "Italian": 19,
  "Arabic": 20,
  "Amharic": 21,
  "Romanian": 22,
  "ChineseSimplified": 23,
  "Greek": 24,
  "Ukrainian": 25,
  "EnglishUS": 26,
  "Russian": 27,
  "Tigrinya": 28,
  "SpanishLA": 29,
  "Czech": 30,
  "Bulgarian": 31,
  "Slovak": 32,
  "Nynorsk": 33,
  "Dari": 34,
  "Nepali": 35,
  "KurdishSorani": 36,
  "PortugueseBrazil": 37,
  "Latvian": 38,
  "Albanian": 39
}
Object.freeze(CapeeshLanguage);


export const CapeeshOrganizationType =
{
  "Normal": 0,
  "SelfServeSingleUserOrganization": 1,
  "SelfServeOrganization": 2,
  "Demo": 3,
  "Trial": 4,
  "Customer": 5,
  "Churned": 6,
  "ClosedLost": 7,
  "CapeeshInternal": 8, 
  "Partner": 9,
  
}
Object.freeze(CapeeshOrganizationType);


export const AdminUserCredentialType =
{
  "NotDefined": 0,
  "OpenAI": 1,
  "Pexels": 2,
  "Unsplash":  3
}
Object.freeze(AdminUserCredentialType);
