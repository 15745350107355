import React, {useState} from "react";
import {connect} from "react-redux";
import {
  Button,
  Grid, makeStyles, 
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {CheckCircleOutline, PanoramaFishEye} from "@material-ui/icons";
import CapeeshStyledModal from "../../../../Components/CapeeshStyledModal";


const useStyles = makeStyles(theme => ({
}));


const ScenarioSupportedLanguagePane = (props) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('count');
  const [thenOrderBy, setThenOrderBy] = useState('value');
  const [l1IdToAdd, setL1IdToAdd] = useState( null);
  
  const [l1IdToImportTranslationsFor, setL1IdToImportTranslationsFor] = useState(null);
  
  const [openCustomLessonTranslationFileSelector, setOpenCustomLessonTranslationFileSelector] = useState(null)
  
  
  const [showDeleteConfirmationMessage, setShowDeleteConfirmationMessage] = useState(false);
  const [toDeleteL1IdAfterConfirmation, setToDeleteL1IdAfterConfirmation] = useState(null);
  
  const languageSupportColumns = [
    {
      id: 'l1Name',
      isDefaultLanguage: 'isDefaultLanguage',
      disablePadding: true,
      label: <FormattedMessage id="cap.course.supportlanguages.pane.name" defaultMessage="Language"/>
    },
    {
      id: 'l1Id',
      disablePadding: true,
      label: <FormattedMessage id="cap.course.supportlanguages.pane.name" defaultMessage="L1Id"/>
    },
    {
      id: 'published',
      numeric: false,
      isBadge: true,
      disablePadding: true,
      label: <FormattedMessage id="cap.course.supportlanguages.pane.needsrepublish" defaultMessage="Published"/>
    },
    {
      id: 'publishedGenerationIndex',
      numeric: true,
      isBadge: false,
      disablePadding: true,
      label: <FormattedMessage id="cap.course.supportlanguages.pane.needsrepublish" defaultMessage="Pub Generation"/>
    },
    {
      id: 'needsTranslation',
      numeric: false,
      isBadge: true,
      disablePadding: true,
      label: <FormattedMessage id="cap.course.supportlanguages.pane.needsrepublish" defaultMessage="Needs translation"/>
    },
    {
      id: 'hasValidContent',
      numeric: false,
      isBadge: true,
      disablePadding: true,
      label: <FormattedMessage id="cap.course.supportlanguages.pane.lessonsavailable" defaultMessage="Has valid content."/>
    },
    // {
    //   id: 'l1IdExport',
    //   numeric: false,
    //   isExportTranslationButton: true,
    //   disablePadding: true,
    //   isButton: true,
    //   label: <FormattedMessage id="cap.course.supportlanguages.pane.lessonsavailable" defaultMessage="Export translation."/>
    // },
    // {
    //   id: 'l1IdImport',
    //   numeric: false,
    //   isImportTranslationButton: true,
    //   disablePadding: true,
    //   isButton: true,
    //   label: <FormattedMessage id="cap.course.supportlanguages.pane.lessonsavailable" defaultMessage="Import translation."/>
    // },
    {
      id: 'l1IdDelete',
      numeric: false,
      isDeleteSupportedLanguage: true,
      disablePadding: true,
      isButton: true,
      label: <FormattedMessage id="cap.course.supportlanguages.pane.lessonsavailable" defaultMessage="Deleted"/>
    }
  ];
  
  
  const handleExportClick = (row, column) => {
    props.onExportTranslationForL1Id(row.l1Id);
  };
  
  const handleImportConfirmed = (l1Id) =>  {
    setOpenCustomLessonTranslationFileSelector(false);
    if (l1Id) {
      props.onImportTranslationForL1Id(l1Id);
    }
    setL1IdToImportTranslationsFor(null);
  }
  
  // const handleImportClick = (row, column) => {
  //   setL1IdToImportTranslationsFor(row.l1Id);
  //   setOpenCustomLessonTranslationFileSelector(true);
  // };
  
  
  const onDeleteShowConfirmation = (row, column) => {
    setToDeleteL1IdAfterConfirmation(row.l1Id);
    setShowDeleteConfirmationMessage(true);
  }
  
  const onDeleteConfirmed = () => {
    props.onDeleteL1Id(toDeleteL1IdAfterConfirmation);
  };
  
  const renderCellValue = (row, column) => {
    if (column.isExportTranslationButton) {
      return <Button variant="outlined" onClick={event => handleExportClick(row, column)} color="primary" >
        {'Export translations'}
      </Button>
    }

    if (column.isDeleteSupportedLanguage) {
      if (row.deleted) {
        return "deleted";
      }
      return <Button variant="outlined" onClick={event => onDeleteShowConfirmation(row, column)} color="primary" >
        {'Mark as deleted'}
      </Button>
    }
    
    // if (column.isImportTranslationButton) {
    //   return <Button variant="outlined" onClick={event => handleImportClick(row, column)} color="primary" >
    //     {'Import Translations'}
    //   </Button>
    // }
    
    if (column.isBadge) {
      return  row[column.id] === true ? <CheckCircleOutline style={{color: "green"}}/> : <PanoramaFishEye style={{color: "red"}}/>
    }
    
    if (column.isDefaultLanguage) {
      return row.isDefaultLanguage ? row[column.id] + " (default)" : row[column.id]
    }
    return ''+ row[column.id]
  };
  
  const classes = useStyles();
  const alreadySupportedLanguages = props.supportedLanguages?.filter(x => !x.deleted).map(function(x) { return x.l1Id; });


  function handleCancel() {
    setOpenCustomLessonTranslationFileSelector(false);
    setL1IdToImportTranslationsFor(null);
    
    setShowDeleteConfirmationMessage(false);
    setToDeleteL1IdAfterConfirmation(null);
  }

  return (
    <Grid container spacing={3}>
      {
        openCustomLessonTranslationFileSelector &&
        <CapeeshStyledModal 
          open={openCustomLessonTranslationFileSelector}
          textTitle={"Do you want to upload new translations?"}
          textLine1={"The existing translations for this scenario will be overwritten, do you want to continue?"}
          onButton2={() => handleImportConfirmed(l1IdToImportTranslationsFor)}
          onButton1={() => handleCancel()}
          button1Text={"Cancel"}
          button1Variant={'outlined'}
          button2Text={"Yes"}
          onClose={() => handleCancel()}
        />
      }
      {
        showDeleteConfirmationMessage &&
        <CapeeshStyledModal
          open={showDeleteConfirmationMessage}
          textTitle={"Do you really want to delete the language?"}
          textLine1={"This can remove the lesson from the course! It can be fixed by re importing translations for the whole course in the language"}
          onButton2={() => onDeleteConfirmed()}
          onButton1={() => handleCancel()}
          button1Text={"Cancel"}
          button1Variant={'outlined'}
          button2Text={"Yes"}
          onClose={() => handleCancel()}
        />
      }
      <Grid item xs={12} sm={12} className={classes.tablescroller}>
        <TableHead>
          <TableRow>
            {languageSupportColumns?.map(column => {
              return (
                <TableCell
                  key={column.id}
                  align={column.numeric ? 'right' : 'left'}
                  padding={column.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {
            props.supportedLanguages?.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.l1Id ? row.l1Id : rowIndex}
                  >
                    {
                      languageSupportColumns?.map((column, index) => (
                        
                        <TableCell key={index}
                                   component="th"
                                   scope="row"
                                   padding="none"
                                   style={{width: '85px'}}
                        >
                          {
                            renderCellValue(row, column)
                          }
                        </TableCell>
                      ))
                    }
                  </TableRow>
                );
              })
          }
        </TableBody>
      </Grid>
    </Grid>
    );
};

      
function mapStateToProps(state) {
  const {organization, lesson, metadata} = state;
  return {
    organization,
    lesson,
    metadata
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioSupportedLanguagePane)