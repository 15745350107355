import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Grid,
  Button, Typography,
} from '@material-ui/core'
import {CapeeshColors} from "../../../assets/ColorPalette";
import CapeeshCreateLogo from "../../../assets/images/dashboard/CapreeshCreatelogo.png";

import SendIcon from '@mui/icons-material/Send';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CapeeshOkInformation from "../../../Components/NewCapeeshModals/CapeeshOkInformation";
import RequestAccessCreateForm from "./Forms/RequestAccessCreateForm";


const useStyles = makeStyles(theme => ({

  mainContainer: {
    marginTop: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: CapeeshColors.EggShell1,
    backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(238, 237, 234  ) 1px, transparent 0)',
    backgroundSize: '12px 12px',
  },

  cardLargeText: {
    color: CapeeshColors.DeepBlue,
    fontSize: 64,
    fontFamily: 'Rubik',
    fontWeight: '500',
    wordWrap: 'break-word',
    letterSpacing: 0.75,
    textAlign: 'center'
  },
  cardDescription: {
    color: CapeeshColors.DeepBlue,
    fontSize: 20,
    fontFamily: 'Rubik',
    fontWeight: '400',
    wordWrap: 'break-word',
    letterSpacing: 0.75,
    textAlign: 'center'
  },
  
  buttonContainer: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  
  videoContainer: {
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: '16px',
    width: '1200',
    height: '700px',
  },
  
  getAccessNowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  
  getAccessNowTitle: {
    fontSize: 24,
    fontFamily: 'Rubik',
    fontWeight: '500',
    color: CapeeshColors.DarkText
  },
  getAccessNowDescription: {
    fontSize: 14,
    fontFamily: 'Rubik',
    fontWeight: '400',
    color: CapeeshColors.DarkText
  },
  
}));

const CreatorDashboardShowcase = (props) => {
  const classes = useStyles();
    
  
  const [openGetAccessNow, setOpenGetAccessNow] = useState(false);
  const [showConfirmGetAccessSent, setShowConfirmGetAccessSent] = useState(false);
  
  const handleGetAccessNow = () => {
    setOpenGetAccessNow(true);
  }
  const closeGetAccessNowModal = () => {
    setOpenGetAccessNow(false);
  }
  
  
  const handleReadMore = () => {
    window.open("https://www.capeesh.com/capeesh-create", '_blank')
  }
  
  const handleRequestAccessWithText = (text) => {
    
    
    
    setOpenGetAccessNow(false);
    setShowConfirmGetAccessSent(true);
    
  }
  
  
  return (
    <div className={classes.mainContainer}>


      {
        showConfirmGetAccessSent &&
        <CapeeshOkInformation
          shouldOpen={showConfirmGetAccessSent}
          onClose={() => setShowConfirmGetAccessSent(false)}
          maxWidth={"600px"}
          titleText={"Thank you for your request"}
          okButtonText={"Ok"}
          messageText={"We will soon be in touch with you or your organisation."}
          buttonColor={CapeeshColors.Purple}

        />


      }

      {
        openGetAccessNow &&
        <RequestAccessCreateForm
          shouldOpen={openGetAccessNow}
          onClose={() => setOpenGetAccessNow(false)}
          maxWidth={'800px'}
          minWidth={'800px'}
        />
      }

      <div style={{width: '60%', marginLeft: '25%', marginRight: '25%'}}>
        <div
          style={{marginTop: '64px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <img style={{height: '38px'}} src={CapeeshCreateLogo}/>
        </div>

        <div>
          <Typography comopnent={"div"} className={classes.cardLargeText} style={{marginTop: '16px'}}>
            Create engaging and game-based training <Typography display={"inline"}
                                                                className={classes.cardLargeText}
                                                                style={{
                                                                  color: CapeeshColors.Purple
                                                                }}>in minutes</Typography>

          </Typography>

          <Typography className={classes.cardDescription} style={{marginTop: '16px'}}>
            Capeesh Create is a revolutionary tool that, with the help of AI, makes it very easy to create engaging,
            interactive and educational courses for your employees.
          </Typography>

        </div>
        <div className={classes.buttonContainer}>
          <Button variant="outlined"
                  onClick={() => handleGetAccessNow()}
                  style={{
                    backgroundColor: CapeeshColors.Purple,
                    color: 'white',
                    fontSize: 16,
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    textTransform: 'none'

                  }}>
            Get access now
            <SendIcon sx={{marginLeft: '5px', fontSize: '15px', color: 'white', paddingRight: '5px'}}/>
          </Button>

          <Button variant="outlined"
                  onClick={() => handleReadMore()}
                  style={{
                    backgroundColor: 'white',
                    color: CapeeshColors.Purple,
                    fontSize: 16,
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    textTransform: 'none',
                    marginLeft: '10px'

                  }}>
            Read more
            <OpenInNewIcon
              sx={{marginLeft: '5px', fontSize: '15px', color: CapeeshColors.Purple, paddingRight: '5px'}}/>
          </Button>
        </div>


      </div>
      <div style={{width: '1200px', height: '800px' }}>
        <div className={classes.videoContainer}
             style={{borderRadius: '20px', overflow: 'hidden', backgroundColor: CapeeshColors.Purple}}>
          <iframe
            src="https://player.vimeo.com/video/884416291?h=9eceb52ec0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            style={{alignItems: 'center'}}
            width="100%"
            height="100%"
            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
            title="Capeesh Create - 6 minute demo"
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const {organization, user, courseGroup, topic} = state;
  return {
    organization,
    user,
    courseGroup,
    topic,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatorDashboardShowcase)
