import React from "react";
import {
    Typography,
    Slide,
    Fade,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {
    Search,
    Edit,
} from '@material-ui/icons';
import {connect} from 'react-redux';
import moment from "moment/moment";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles(theme => ({
    cardContent: {
        flex: 'auto',
    },
    topicCard: {
        display: 'flex',
        padding: '20px',
        boxShadow: 'none',
    },
    topicCardEnabled: {
        display: 'flex',
        padding: '20px',
        transition: 'all .25s ease',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
        },
    },
    topicCardAction: {
        right: '15px',
        height: 'fit-content',
        margin: 'auto',
    },
}));

const CourseCard = (props) => {
    const {theme, topic, index, slideDirection, handleEditCourse, languages, selectedOrganization, disabled} = props;
    const classes = useStyles();

    let isDisabled = true;
    if(selectedOrganization === topic.organizationId && !disabled) {
        isDisabled = false;
    }
    
    const handleEditClicked = (event) => {
        if (!handleEditCourse) {
            return
        }
        
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if(handleEditCourse)
            handleEditCourse(topic.id);
    };
    
    return (
        <div>
        {
            isDisabled ?
                <Card key={topic.id} className={classes.topicCard}>
                    <div className={classes.cardContent}>
                        <Typography variant={"h6"} color={"primary"}>
                            {`${topic.name} ${topic.sharedCourse ? `(Unable to edit shared course)` : ''}`}
                        </Typography>
                        {
                            topic.supportedLanguages.filter(x => x.published).length > 0 ?
                                <Typography variant={"body2"}>
                                    {`${languages.find(x => x.id == topic.l2Id).friendlyName} course published for `}
                                    {
                                        topic.supportedLanguages.filter(x => x.published).map((language, index) => {
                                            if(index > 0)
                                                return ", " + language.l1Name;
                                            else
                                                return language.l1Name;
                                        })
                                    }
                                </Typography>
                                :
                                <Typography variant={"body2"}>
                                    {`${languages.find(x => x.id == topic.l2Id).friendlyName} course`}
                                </Typography>
                        }
                        <Typography variant={"caption"}>
                            {`${moment(topic.createdAt).format("LLL")} by ${topic.organizationName}`}
                        </Typography>
                    </div>
                </Card>
                :
                <Card key={topic.id} className={classes.topicCardEnabled}
                      onClick={() => handleEditClicked()}>
                    <div className={classes.cardContent}>
                        <Typography variant={"h6"} color={"primary"}>
                            {topic.name}
                        </Typography>
                        {
                            topic.supportedLanguages?.length > 0 && topic.supportedLanguages.filter(x => x.published).length > 0 ?
                                <Typography variant={"body2"}>
                                    {`${languages.find(x => x.id == topic.l2Id).friendlyName} course published for `}
                                    {
                                        topic.supportedLanguages.filter(x => x.published).map((language, index) => {
                                            if(index > 0)
                                                return ", " + language.l1Name;
                                            else
                                                return language.l1Name;
                                        })
                                    }
                                </Typography>
                                :
                                <Typography variant={"body2"}>
                                    {`${languages.find(x => x.id == topic.l2Id).friendlyName} course`}
                                </Typography>
                        }
                        <Typography variant={"caption"}>
                            {`${moment(topic.createdAt).format("LLL")} by ${topic.organizationName}`}
                        </Typography>
                    </div>
                    {
                        props.handleEditCourse &&
                        <IconButton className={classes.topicCardAction}
                                    onClick={handleEditClicked}
                        >
                            {topic.published ? <Search/> : <Edit/>}
                        </IconButton>
                    }
                    
                    {/*{*/}
                    {/*    props.handleCourseSelected &&*/}
                    {/*    <Button onClick={() => (props.handleCourseSelected(topic.id))}>*/}
                    {/*        Select*/}
                    {/*    </Button>*/}
                    {/*}*/}
                    
                </Card>
        }
        </div>
    );
};

CourseCard.propTypes = {};

const mapStateToProps = (state) => {
    return {
        languages: state.metadata.metadata.languages,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseCard)