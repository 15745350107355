import {Grid, makeStyles} from "@material-ui/core";
import React from "react";
import {connect} from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DataPickerNodeItemAdd from "./DataPickerNodeItemAdd";

// const openai = new OpenAIApi(configuration);
const useStyles = makeStyles(theme => ({
  leftPanel: {
    paddingTop: '10px',
    width: '700px'
  },
}));


const DataPickerSidebar = (props) => {

  const [tabValue, setTabValue] = React.useState(0);
  const [customLessonId, setCustomLessonId] = React.useState(null);
  const [block, setBlock] = React.useState(null);
  const [addedItems, setAddedItems] = React.useState([]);
  const [hoveredItemId, setHoveredItemId] = React.useState(-1);
  const [modeSelect, setModeSelect] = React.useState(0);
  const [aiSearchText, setAiSearchText] = React.useState('');
  const [aiRelevantText, setAiRelevantText] = React.useState('');

  const handleChangeTab = (e, value) => {
    setTabValue(value)
  }

  const handleSetAiSearchText = (event) => {
    setAiSearchText(event.target.value)
  }

  const handleSetAiRelevantText = (event) => {
    setAiRelevantText(event.target.value)
  }

  const handleAddItemsOrNodes = (itemsToAdd, type, shouldConnect) => {
    props.onCreateItemsOrNodesFromDatapicker(itemsToAdd, type, shouldConnect)
  }


  const handleToggleAddItem = (item) => {
    let shouldAdd = false
    let found = false
    addedItems.forEach(addedItem => {
      if(addedItem.nodeId === item.nodeId ) {
        found = true
      }    
    });
    if (found === true){
      setAddedItems(addedItems.filter(x => x.nodeId !== item.nodeId ))
    }
    else if(!!!shouldAdd) {
      setAddedItems([...addedItems, item])
    }
  }
  
  const classes = useStyles();
  return (
    <Grid container>

      <Grid className={classes.leftPanel} item xs={12} sm={12} md={12}>
          <Grid container>
            <Tabs value={tabValue} onChange={handleChangeTab} centered>
              <Tab label="Lessons" />
              <Tab label="Vocabulary" />
              <Tab label="Sentence" />
              <Tab label="Ask Aistein" />
            </Tabs>
          </Grid>

        
        <Grid container>
          {
            tabValue === 0 &&
            <DataPickerNodeItemAdd onAddItemsOrNodes={handleAddItemsOrNodes} topic={props.topic} topicId={props.topicId} onGetLessonStages={props.onGetLessonStages}/>
          }

          {
            tabValue === 1 &&
            <Grid item xs={12}>
             <p> Coming soon </p>
              {
              //  props.topic.topicDataPickerForNLP?.topicId == props.topicId &&
              //  props.topic.topicDataPickerForNLP?.blocks?.map((block, index) => {
              //      return (
              //       
              //      );
              //    }
              //  )
              }
            </Grid>
          }

          {
            tabValue === 2 &&
            <Grid item xs={12}>
            <p> Coming soon </p>
              {
              //  props.topic.topicDataPickerForSentenceSuggestion?.topicId == props.topicId &&
              //  props.topic.topicDataPickerForSentenceSuggestion?.blocks?.map((block, index) => {
              //      return (
              //        <p> Coming soon </p>
              //      );
              //    }
              //  )
              }
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  )
};

function mapStateToProps(state) {
  const {topic} = state;
  return {
    topic,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(DataPickerSidebar)