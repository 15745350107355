import React, {Component} from "react";
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
  Grid
} from '@material-ui/core'
import {history} from "_state/helpers";
import CreatorCourseMetadata from "./CreatorTopicMetadata";


const styles = theme => ({
  languageDropdown: {
    width: '100%',
  },
  scenariosHeader: {
    paddingBottom: '20px'
  },
  button: {
    marginTop:'10px',
    marginRight: '10px',
    textTransform: 'none',
    color: 'white'
  },
  difficultyForm: {
    width: '120px',
  },
});

class TopicAddComponent extends React.Component {

  // expected props
  // props.topicId
  // props.courseGroupId (optional)
  
  state = {
    scenarios: [],
    showAttachQuizModal: false,
    showAttachScenarioModal: false,
    deleteConfirmation: false

  };


  componentDidMount = () => {
    const {lesson} = this.props;
    let scenarios = lesson?.organizationLessons?.filter(x => x.customLessonTypeName == "Scenario");
    this.setState({scenarios: scenarios})
  }
  
  addQuizItem = (event) => {
    this.props.onAddQuiz()
    this.setState({showAttachQuizModal: true})

  };

  addScenarioItem = (event) => {
    this.props.onAddScenario()
    this.setState({showAttachScenarioModal: true})
  };


  showLanguagesPane = (event) => {
    this.props.onShowLanguagesPane();
  };

  createNewScenario = (event) => {
    
    const queryParams = "?createFromTopic=true&topicId=" + this.props.topicId + "&l2Id=" + this.props.l2Id + "&defaultL1Id=" + this.props.defaultL1Id

    let scenarioCreateUrl = ""
    if (this.props.courseGroupId && this.props.topicId) {
      scenarioCreateUrl = '/creator/course/group/' + this.props.courseGroupId + '/topic/' + this.props.topicId + '/lesson/setup' + queryParams;
    } else {
      scenarioCreateUrl = '/creator/topic/list/' + this.props.topicId + '/lesson/setup' + queryParams;
    }
    history.push(scenarioCreateUrl);
  };

   handleCancel =   () => {
      this.setState({deleteConfirmation:false});
  };

   deleteTopic = () => {
    this.props.deleteTopic(this.props.organization.selectedOrganization, this.props.topicData.id);
    this.setState({deleteConfirmation: false});

    //TODO: MORTEN show course group instead?
    if (this.props.computedMatch?.params?.courseGroupId) {
      history.push('/creator/course/group/' + this.props.computedMatch.params.courseGroupId);
    } else {
      history.push("/creator/");
    }
  };
  render() {
    const {classes, metadata, user} = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <CreatorCourseMetadata topicData={this.props.topicData} computedMatch={this.props.computedMatch} role={this.props.role}/>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TopicAddComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {lesson, topic, user, organization} = state;
  return {
    user,
    lesson,
    topic,
    organization
  };
}

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles)
);

export default enhance(TopicAddComponent)
