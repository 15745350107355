import React, {useEffect, useState} from "react";
import {
    Card,
    Typography,
    Grid,
    Button
} from '@material-ui/core';
import {connect} from 'react-redux';
import makeStyles from "@material-ui/core/styles/makeStyles";
import PushPinIcon from '@mui/icons-material/PushPin';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
}));

const ScenarioAuthorAddComment = (props) => {
    const classes = useStyles();
    const [authorNote, setAuthorNote] = React.useState('');
    return (
      <Card style={{ background:'#FFFFFF', border:'1px solid #DFDFDF', borderRadius: '5px', filter: 'dropShadow(0px 2px 5px rgba(0, 0, 0, 0.11))', paddingLeft: '10px', marginLeft: '15px', marginRight: '9px', paddingBottom: '20px'}}>
       <Grid container>
          <Grid item xs={10} sm={10} md={10}>
            <Typography 
              variant='body1'  
              style={{                            
              fontSize: 18,
              fontFamily: 'Lato',
              fontWeight: '500',
              marginLeft: '5px',
              marginTop: '10px',
              marginBottom: '10px'
            }}>
              Leave a comment
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField value={authorNote} onChange={(event) => setAuthorNote(event.target.value)} label={"Type an answer"} InputProps={{disableUnderline: true, shrink: "false"}} style={{borderRadius: '10px', marginLeft: '5px', width: '280px'}} className={classes.textFields} variant="filled"/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{marginTop: '20px'}}> 
            <Button 
              className={classes.saveButton} 
              onClick={(event) => props.onCreateAuthorNote(authorNote)} 
              style={{backgroundColor: '#143349', marginLeft: '5px',fontColor: 'white', color: 'white'}} 
              variant='outlined'>
                Save Changes
              </Button>
            <Button 
              className={classes.saveButton} 
              onClick={(event) => props.onCloseAuthorNote()} 
              style={{marginRight: '5px', marginLeft: '15px', textDecoration: 'underline', fontColor: '#143349', color: '#143349'}} variant='text'>
                Cancel
              </Button>
          </Grid>   
      </Grid>
    </Card>
    );
};

ScenarioAuthorAddComment.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(ScenarioAuthorAddComment)