import { call, put, takeLatest, all } from 'redux-saga/effects'
import {lessonConstants, topicConstants} from '../actions'
import {lessonService, topicService} from '../services'
import fileDownload from 'js-file-download';
import {snackbarActions} from "../actions/snackbar.actions";
import { history } from '../helpers'
import { v4 as uuidv4 } from 'uuid';
import {
    ScenarioNodeType,
    PromptTypes,
    GPTPrompt,
    ScenarioInteractiveElementType,
    AudioFieldType, ImageFieldType,
} from "../../Logic/ScenarioConstants";
import {convertDataURIToBinary} from '../helpers/base64Logic'
import { createClient } from 'pexels';
import {FileReferenceImageUsageType} from "../../Logic/FileReferenceConstants";
import {
    FileReferenceAudioUsageTypeFromAudioFieldType,
    GetFileReferenceImageUsageTypeForNode
} from "../../Logic/FileReferenceMetadataLogic";

import {select} from 'redux-saga/effects';
import * as lessonSelectors from '../selectors/lessonSelectors'

// const pexelClient = createClient('563492ad6f91700001000001577b64e16c5e4bd8b6945f703bdafc13');


export function* getOrganizationLessons(action) {
    try {
        const response = yield call(lessonService.getOrganizationLessons, action.payload.organizationId);
        yield put({type: lessonConstants.GET_ORGANIZATION_LESSONS.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: lessonConstants.GET_ORGANIZATION_LESSONS.FAILURE, message: e.message});
    }
}

export function* createCustomLesson(action) {
    try {
        const response = yield call(lessonService.createCustomLesson, action.payload);
        yield put({type: lessonConstants.CREATE_CUSTOM_LESSON.SUCCESS, data: response.data});
        const questions= [
            {
              question: "",
              questionAnsweredWrongMessage: "",
              answers: [
                {
                    answerText: "",
                    answerPos: "",
                    correct: false
                },
                {
                    answerText: "",
                    answerPos: "",
                    correct: true
                  }
              ]
            }
          ]
          let myobj = {payload:{customLessonId: response.data.id, questions: questions}}
        const responseAddQuestion = yield call(lessonService.addLessonQuestion, {customLessonId: response.data.id, questions: questions});
        yield put({type: lessonConstants.ADD_LESSON_QUESTION.SUCCESS, data: responseAddQuestion.data});
    } catch (e) {
        yield put({type: lessonConstants.CREATE_CUSTOM_LESSON.FAILURE, message: e.message});
    }
}

export function* addLessonQuestion(action) {
    try {
        const response = yield call(lessonService.addLessonQuestion, action.payload);
        yield put({type: lessonConstants.ADD_LESSON_QUESTION.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: lessonConstants.ADD_LESSON_QUESTION.FAILURE, message: e.message});
    }
}

export function* getCustomLesson(action) {
    try {
        const response = yield call(lessonService.getCustomLesson, action.payload);
        yield put({type: lessonConstants.GET_CUSTOM_LESSON.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: lessonConstants.GET_CUSTOM_LESSON.FAILURE, message: e.message});
    }
}

export function* editCustomLessonDetails(action) {
    try {
        const response = yield call(lessonService.editCustomLessonDetails, action.payload);
        yield put({type: lessonConstants.EDIT_CUSTOM_LESSON_DETAILS.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: lessonConstants.EDIT_CUSTOM_LESSON_DETAILS.FAILURE, message: e.message});
    }
}

export function* editQuestion(action) {
    try {
        const response = yield call(lessonService.editQuestion, action.payload);
        yield put({type: lessonConstants.EDIT_QUESTION.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: lessonConstants.EDIT_QUESTION.FAILURE, message: e.message});
    }
}

export function* generalReset(action) {
    try {
        // yield put({type: topicConstants.GENERAL_RESET.REQUEST});
        // yield put({type: topicConstants.GENERAL_RESET.SUCCESS});
    } catch (e) {
        // yield put({type: lessonConstants.GENERAL_RESET.FAILURE, message: e.message});
    }
}

export function* deleteCustomLesson(action) {
    try {
        const response = yield call(lessonService.deleteCustomLesson, action.payload);
        yield put({type: lessonConstants.DELETE_CUSTOM_LESSON.SUCCESS, data: response.data});
        const responseOrg = yield call(lessonService.getOrganizationLessons, action.payload.organizationId);
        yield put({type: lessonConstants.GET_ORGANIZATION_LESSONS.SUCCESS, data: responseOrg.data});
    } catch (e) {
        yield put({type: lessonConstants.DELETE_CUSTOM_LESSON.FAILURE, message: e.message});
    }
}

export function* deleteQuestion(action) {
    try {
        const response = yield call(lessonService.deleteQuestion, action.payload);
        yield put({type: lessonConstants.DELETE_QUESTION.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: lessonConstants.DELETE_QUESTION.FAILURE, message: e.message});
    }
}

export function* assignLessonToTopic(action) {
    try {
        const response = yield call(lessonService.assignLessonToTopic, action.payload);
        yield put({type: lessonConstants.ASSIGN_LESSON_TO_TOPIC.SUCCESS, data: response.data});
        const responseGet = yield call(lessonService.getOrganizationLessons, action.payload.organizationId);
        yield put({type: lessonConstants.GET_ORGANIZATION_LESSONS.SUCCESS, data: responseGet.data});
    } catch (e) {
        yield put({type: lessonConstants.ASSIGN_LESSON_TO_TOPIC.FAILURE, message: e.message});
    }
}

export function* getCustomLessonRelationships(action) {
    try {
        const response = yield call(lessonService.getCustomLessonRelationships, action.payload);
        yield put({type: lessonConstants.GET_CUSTOM_LESSON_RELATIONSHIPS.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: lessonConstants.GET_CUSTOM_LESSON_RELATIONSHIPS.FAILURE, message: e.message});
    }
}

export function* exportLesson(action) {
    try {
        const response = yield call(lessonService.exportLesson, action.payload);
        fileDownload(response.data, action.payload.filename);
        yield put({type: lessonConstants.EXPORT_LESSON.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: lessonConstants.EXPORT_LESSON.FAILURE, message: e.message});
    }
}

export function* importLesson(action) {
    try {
        const response = yield call(lessonService.importLesson, action.payload);
        yield put({type: lessonConstants.IMPORT_LESSON.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;

        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to import the content. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
   
        yield put({type: lessonConstants.IMPORT_LESSON.FAILURE, message: e.message});
    }
}

export function* importScenario(action) {
    try {
        const response = yield call(lessonService.importScenario, action.payload);
        yield put({type: lessonConstants.IMPORT_SCENARIO.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;

        yield put(snackbarActions.enqueueSnackbar({
            message: "We were unable to import the content. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));

        yield put({type: lessonConstants.IMPORT_SCENARIO.FAILURE, message: e.message});
    }
}

export function* importScenarioLinear(action) {
    try {
        const response = yield call(lessonService.importScenarioLinear, action.payload);
        yield put({type: lessonConstants.IMPORT_SCENARIO_LINEAR.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;

        yield put(snackbarActions.enqueueSnackbar({
            message: "We were unable to import the content. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));

        yield put({type: lessonConstants.IMPORT_SCENARIO_LINEAR.FAILURE, message: e.message});
    }
}


export function* deleteFromCourse(action) {
    try {
        const response = yield call(lessonService.deleteFromCourse, action.payload);
        yield put({type: lessonConstants.DELETE_FROM_COURSE.SUCCESS, data: response.data});
        const responseGet = yield call(lessonService.getOrganizationLessons, action.payload.organizationId);
        yield put({type: lessonConstants.GET_ORGANIZATION_LESSONS.SUCCESS, data: responseGet.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable remove the custom lesson from the course. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.DELETE_FROM_COURSE.FAILURE, message: e.message});
    }
}

export function* addImage(action) {
    try {
        const response = yield call(lessonService.addImage, action.payload);
        yield put({type: lessonConstants.ADD_IMAGE.SUCCESS, data: response.data});
        if (!action.payload.queryParams) {
            action.payload.queryParams = ""
        }
        const responseImageList = yield call(lessonService.getImageReferences, action.payload);
        yield put({type: lessonConstants.GET_IMAGE_REFERENCES.SUCCESS, data: responseImageList.data});
        yield put(snackbarActions.enqueueSnackbar({
            message: "Your image has been uploaded.",
            options: {
                variant: "success"
        }
    }));
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able to upload the image. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
   
        yield put({type: lessonConstants.ADD_IMAGE.FAILURE, message: e.message});
    }
}

export function* getImageReferences(action) {
    try {
        const response = yield call(lessonService.getImageReferences, action.payload);
        yield put({type: lessonConstants.GET_IMAGE_REFERENCES.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able get the image references. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
   
        yield put({type: lessonConstants.GET_IMAGE_REFERENCES.FAILURE, message: e.message});
    }
}

export function* getImage(action) {
    try {
        const response = yield call(lessonService.getImage, action.payload);
        const type = response.data.split(';')[0].split('/')[1];
        yield put({type: lessonConstants.GET_IMAGE.SUCCESS, data: {selectedImage:response.data }});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data 
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able download the image. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.GET_IMAGE.FAILURE, message: e.message});
    }
}

export function* getAudio(action) {
    try {
        const response = yield call(lessonService.getAudio, action.payload);
        const type = response.data.split(';')[0].split('/')[1];
        yield put({type: lessonConstants.GET_AUDIO.SUCCESS, data: {selectedAudio:response.data }});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data 
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able download the audio file. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.GET_AUDIO.FAILURE, message: e.message});
    }
}

export function* addAudio(action) {
    try {
        const response = yield call(lessonService.addAudio, action.payload);
        yield put({type: lessonConstants.ADD_AUDIO.SUCCESS, data: response.data});
        const responseImageList = yield call(lessonService.getAudioReferences, action.payload);
        yield put({type: lessonConstants.GET_AUDIO_REFERENCES.SUCCESS, data: responseImageList.data});
        yield put(snackbarActions.enqueueSnackbar({
            message: "Your audio has been uploaded.",
            options: {
                variant: "success"
        }
    }));
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able to upload the audio. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
   
        yield put({type: lessonConstants.ADD_AUDIO.FAILURE, message: e.message});
    }
}

export function* getAudioReferences(action) {
    try {
        const response = yield call(lessonService.getAudioReferences, action.payload);
        yield put({type: lessonConstants.GET_AUDIO_REFERENCES.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able get the image references. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.GET_AUDIO_REFERENCES.FAILURE, message: e.message});
    }
}

export function* getLessonAudioReferences(action) {
    try {
        const response = yield call(lessonService.getLessonAudioReferences, action.payload);
        yield put({type: lessonConstants.GET_LESSON_AUDIO_REFERENCES.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;

        yield put(snackbarActions.enqueueSnackbar({
            message: "We were not able get the image references. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: lessonConstants.GET_LESSON_AUDIO_REFERENCES.FAILURE, message: e.message});
    }
}


export function* getCustomLessonSupportedLanguage(action) {
    try {
        const response = yield call (lessonService.getCustomLessonSupportedLanguages, action.payload);
        yield  put({type: lessonConstants.GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;

        yield put(snackbarActions.enqueueSnackbar({
            message: "We were not able get get custom lesson supported languages. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: lessonConstants.GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES.FAILURE, message: e.message});
    }
}

export function* addCustomLessonSupportedLanguage(action) {
    try {
        const response = yield call (lessonService.addCustomLessonSupportedLanguage, action.payload);
        yield  put({type: lessonConstants.ADD_CUSTOM_LESSON_SUPPORTED_LANGUAGE.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;

        yield put(snackbarActions.enqueueSnackbar({
            message: "We were not able to add custom lesson supported languages. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: lessonConstants.ADD_CUSTOM_LESSON_SUPPORTED_LANGUAGE.FAILURE, message: e.message});
    }
}

export function* deleteCustomLessonSupportedLanguage(action) {
    try {
        const response = yield call (lessonService.deleteCustomLessonSupportedLanguage, action.payload);
        yield  put({type: lessonConstants.DELETE_CUSTOM_LESSON_SUPPORTED_LANGUAGE.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;

        yield put(snackbarActions.enqueueSnackbar({
            message: "We were not able to delete custom lesson supported languages. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: lessonConstants.DELETE_CUSTOM_LESSON_SUPPORTED_LANGUAGE.FAILURE, message: e.message});
    }
}

export function* importCustomLessonTranslations(data) {
    try {
        const response = yield call(lessonService.importExcelCustomLessonTranslations, data.payload);
        yield put({type: lessonConstants.IMPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.SUCCESS, data: response.data});
        const responseRefresh = yield call(lessonService.getCustomLessonSupportedLanguages, data.payload);
        yield put({type: lessonConstants.GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES.SUCCESS, data: responseRefresh.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not import translations for the custom lesson. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: lessonConstants.GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES.FAILURE, message: e.message});
    }
}

export function* exportCustomLessonTranslations(data) {
    try {
        const response = yield call(lessonService.exportExcelCustomLessonTranslations, data.payload);
        var binary= convertDataURIToBinary(response.data);
        var blob=new Blob([binary], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        fileDownload(blob, data.payload.filename);
        yield put({type: lessonConstants.EXPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; 
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not export the translations for the custom lesson. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: lessonConstants.EXPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.FAILURE, message: e.message});
    }
}

function connectStages(currentNodeId, nextNodeId, type, options, scenarioNodes, xPos, yPos) {
    let needsSmartEdge = true
    // create the connection between the scenario stages
    if(options.length === 0) return
    let edge = []
    if(type !== 3 && type !== 5 && options[0].nextScenarioNodeId != null) {
        scenarioNodes.forEach(otherNode => {
            if(otherNode.nodeId === options[0].nextScenarioNodeId) {
                if(otherNode.xPos - xPos <600 && otherNode.xPos - xPos > 0 && (otherNode.yPos - yPos < 300 && otherNode.yPos - yPos > -300)) {
                    needsSmartEdge = false
                }
            }
        });
            edge.push({
            animated: true,
            isConnector: true,
            id: "edge"+currentNodeId,
            source: currentNodeId,
            sourceHandle: "exit0",
            pathfindingPaddingIndex: 6,
            target: options[0].nextScenarioNodeId,
            targetHandle: "target1",
            type: "buttonedge",
            arrowHeadType: 'arrowclosed',
            style: { stroke: 'black' },
            data: {isConnector: true, needsSmartEdge: needsSmartEdge, showLabels: false}
        });
        return edge
    } if(type === 3 || type === 5) { // speak and clicking stages
        options.forEach((option, index) => {
            let style= {stroke: 'green', pathfindingPaddingIndex: (index + 1) * 6 + 10}
            if(option.scoreAlteration < 0) {
                style = { stroke: 'orange', pathfindingPaddingIndex: (index + 1) * 6 + 10}
            } if(option.failure === true) {
                style = { stroke: 'red', pathfindingPaddingIndex: (index + 1) * 6 + 10
            }
            }
            needsSmartEdge = true
            scenarioNodes.forEach(otherNode => {
                
                if(otherNode.nodeId === option.nextScenarioNodeId) {
                    if(otherNode.xPos - xPos <600 && otherNode.xPos - xPos > 0 && (otherNode.yPos - yPos < 300 && otherNode.yPos - yPos > -300)) {
                        needsSmartEdge = false
                    }
                }
            });
            if(option.nextScenarioNodeId != null)
            edge.push({
                animated: true,
                isConnector: true,
                id: "edge"+index+currentNodeId,
                source: currentNodeId,
                sourceHandle: "exit"+index,
                target: option.nextScenarioNodeId,
                targetHandle: "target1",
                type: "buttonedge",
                style: style,
                arrowHeadType: 'arrowclosed',
                data: {isConnector: true, needsSmartEdge: needsSmartEdge, showLabels:false}
            });
        });
        return edge
    }
    return null
}

let sortedAnswers = []
let ender = []
function listPositioning(nodes, debriefNodeId, i) {
    i++
    nodes.forEach(node => {
        node.options.forEach(option => {
            let shouldAdd = true
            ender.forEach(added => {
                if(added === (debriefNodeId+""+option.nextScenarioNodeId+option.text)) {
                    shouldAdd=false
                }
            });
            if(shouldAdd && debriefNodeId!= null && debriefNodeId  === option.nextScenarioNodeId) {
                let displayText = ""
                node.options.forEach(o => {
                    displayText+=" "+o.text
                })
                ender.push(debriefNodeId+""+option.nextScenarioNodeId+option.text)
                let shouldOrder = true
                sortedAnswers.forEach(answer => {
                    if(answer.nodeId === node.nodeId) {
                        shouldOrder=false
                    }
                });
                if(shouldOrder) {
                    sortedAnswers.push({position: i, y: -1, text: displayText, nodeId: node.nodeId, scenarioNodeType: node.scenarioNodeType})
                }
                listPositioning(nodes, node.nodeId, i); 
            }
        });
    }); 
    return i
}

function detectCommentsUnresolved(scenarioNodes) {
    let unresolvedComments = false
    scenarioNodes.forEach((node, index) => {
        if(node.authorNoteDtos) {
           if (node.authorNoteDtos.filter(x => x.authorNoteType === 0).length > 0) {
               unresolvedComments = true
           }
        }})
    return unresolvedComments
}




function createNode(informationObject) {
    let newNode = {}
    switch(informationObject.role) {
       case ScenarioNodeType.TestDefinition:
        let testDefinitionOptionGuid0 = uuidv4();
        let testDefinitionOptionGuid1 = uuidv4();
        newNode.texts = []
        let definition = informationObject.text
        let explanation = ""
        newNode.testOptions = [
            {
            testOptionId: testDefinitionOptionGuid0,
            alternatives: [],
            scenarioInteractiveElementType: 7,
            },
            {
            testOptionId: testDefinitionOptionGuid1,
            alternatives: [{
                correct: true,
                text: definition,
                textLanguageId: -1,
                startIndex: -1
            }],
            explanation: explanation,
            explanationLanguageId: informationObject.languageId,
            scenarioInteractiveElementType: 10,
            text: definition,
            textLanguageId: -1,
            audioPlayType: 1,
            }
        ]
        break;
       case ScenarioNodeType.Listen:
        newNode.texts = [{
            text: informationObject.text,
            textLanguageId: informationObject.languageId,
            textSnippetId: uuidv4(),
            textType: 4
            },
            {
                text: informationObject.l1Text,
                textLanguageId: informationObject.l1Id,
                textSnippetId: uuidv4(),
                textType: 4
            }
        ]
       break;

       case ScenarioNodeType.TestImage:
        let testOptionGuid0 = uuidv4();
        let testOptionGuid1 = uuidv4();
        let testOptionGuid2 = uuidv4();
        let textSnippetIdTitle = uuidv4();
        let l1 = ""
        newNode.testOptions = [
            {
            testOptionId: testOptionGuid0,
            alternatives: [],
            scenarioInteractiveElementType: 7
            },
            {
            testOptionId: testOptionGuid1,
            alternatives: [],
            scenarioInteractiveElementType: 4,
            text: l1,
            textLanguageId: -1,
            elementAlignmentType: 1
            },
            {
            testOptionId: testOptionGuid2,
            alternatives: [],
            scenarioInteractiveElementType: 3,
            text: informationObject.text,
            textLanguageId: -1,
            textAudioUrl: null,
            elementAlignmentType: 1,
            audioPlayType: 2,
            }
        ]

        newNode.texts = [{
            text: "",
            textLanguageId: -1,
            textSnippetId: textSnippetIdTitle,
            textType: 2
        }
        ]
       break;
       case ScenarioNodeType.TestRule:
       let textSnippetIdRuleTitle = uuidv4();
       let testOptions = []
       informationObject.rules.forEach((rule, index) => {
           testOptions.push({
            alternatives: [],
            audioPlayType: 0,
            elementAlignmentType: 1,
            explanation: "",
            explanationLanguageId: -1,
            mentor: 1,
            scenarioInteractiveElementType: 4,
            testOptionId: uuidv4(),
            text:  rule,
            textLanguageId: informationObject.languageId,
            textSnippetId: uuidv4()
           })
       });
       newNode.testOptions = testOptions

       newNode.texts = [{
         text: "",
         textLanguageId: -1,
         textSnippetId: textSnippetIdRuleTitle,
         textType: 2
       }
       ]
       break;
    default: 
       newNode.testOptions = []
       break;
   }
   newNode.isNew = true
   let optionOne = {
    textLanguageId: informationObject.languageId,
    text: informationObject.text
    }
    let nodeGuid = uuidv4();
    newNode.nodeId = nodeGuid
    newNode.options = [optionOne]
    newNode.scenarioNodeType = informationObject.role
    newNode.type = informationObject.role
    newNode.xPos= informationObject.xPos
    newNode.yPos= 1
    newNode.position = {x: informationObject.xPos, y: 1}
   return newNode
}
function linearlyConnectStages(createdNodes) {
        // connect each node to the previous node
        let finalObjectAfterConnections = []
        createdNodes.forEach((finalNode, index) => {
            finalObjectAfterConnections.push(finalNode)
            if(index > 0) {
                finalObjectAfterConnections[index - 1].options[0].nextScenarioNodeId = finalNode.nodeId
            }
        });
    return finalObjectAfterConnections
 }

function prepareScenarioForGraph(response) {
    let subtitleType = response.data.subtitleType
    let tempNodes = [] 
    let tempEdges = []
    let x = response.data.scenarioNodes.length- 1
    let startingNode = null
    response.data.scenarioNodes.forEach((node, index) => {
        if(node.scenarioNodeType === 4) {
            startingNode = node
            sortedAnswers.push({position: 0, y: -1, text: node.options.length > 0 ? node.options[0].text : "", nodeId: node.nodeId, scenarioNodeType: node.scenarioNodeType})
            listPositioning(response.data.scenarioNodes, startingNode.nodeId, 1)
        }
    })
    let totalCreatedPositions = 0
    sortedAnswers.forEach(answer => {
        if(totalCreatedPositions < answer.position) {
            totalCreatedPositions = answer.position
        }
    });
    sortedAnswers.forEach(answer => {
        // invert horizontal order positioning
        answer.position = (answer.position - totalCreatedPositions -1) * -1
    });
    sortedAnswers = sortedAnswers.sort((a, b) => (a.position > b.position) ? 1 : -1)
    
    let speakStagePrecidence = 0
    sortedAnswers.forEach(answer => {
        // give speak and clicking stages their own horizontal slot
        answer.position = answer.position + speakStagePrecidence
        if(answer.scenarioNodeType === 3 || answer.scenarioNodeType === 5) {
            sortedAnswers.forEach(a => {
                if(answer.position === a.position && a.nodeId != answer.nodeId) {
                    a.position = a.position + 1
                }
            })
            speakStagePrecidence += 1
        }

        let yOrder = 0;
        sortedAnswers.forEach(b => {
            if(answer.position === b.position ) {
                yOrder += 300
                b.y = yOrder
            }
        })
    });

    sortedAnswers = sortedAnswers.sort((a, b) => (a.position > b.position) ? 1 : -1)
    // re-order after the above
    let yOrphaned = 0 // orphaned stages that have no connections nor defined positions
    response.data.scenarioNodes.forEach((node, index) => {
        let edge = connectStages(node.nodeId, node.nextScenarioNodeId, node.scenarioNodeType, node.options, response.data.scenarioNodes, node.xPos, node.yPos)
        if(edge){
            tempEdges.push(...edge)
        }

        // old TestImages created in admin before September 22nd do not have a texts attribute. In this event, we force it to be created here
        if(node.scenarioNodeType === 12) {
            // no access to enum object from within src folder as is special
            if( !!!node?.texts || node.texts.length === 0) {
                let imageGuid = uuidv4();
                node.texts = [{
                    text: "",
                    textSnippetId: imageGuid
                }]
            }
        }

        let data = []
        let dataTestOptions = []
        let validationErrors = []
        let texts = []
        let listenTextAudioUrl = null
        let x = 0
        if (node.texts) {
            node.texts.forEach((text) => {
                texts.push(text)
            });
        }
        
        if (node.options) {
            node.options.forEach((option) => {
                if(node.scenarioNodeType === 2 ) {
                    if(option.textAudioUrl) {
                        listenTextAudioUrl = option.textAudioUrl
                    }
                }
                
                data.push(option.text)
                x++
            }); 
        }
        
        if (node.validationErrors) {
            node.validationErrors.forEach((validationError) => {
                validationErrors.push(validationError)
            });    
        }
        
        if(node.testOptions) {
            node.testOptions.forEach((testOption) => {
                    dataTestOptions.push(testOption)
                
            });
        }
 
        let xPosition = 1
        let yPosition = 1
        
        if(response.data.scenarioNodes.length !== sortedAnswers.length) {
            // orphaned or satellite nodes likely missing
           
                let found = false
                sortedAnswers.forEach(sa => {
                    
                    if(node.nodeId === sa.nodeId)   {
                        found = true
                    }
                });
                if(found === false && node.scenarioNodeType !== 6) {
                    yOrphaned = yOrphaned - 200
                    sortedAnswers.push({position: 0, y: yOrphaned, text: "", nodeId: node.nodeId, scenarioNodeType: node.scenarioNodeType})

                }
            
        }

        sortedAnswers.forEach(answer => {
            // space the gap between node horizontal boundaries by the specified stepper
            if(answer.nodeId === node.nodeId) {
                xPosition = answer.position * 300
                yPosition = answer.y
            }
        });
        let subtitle = ""
        if (node.scenarioNodeType === ScenarioNodeType.Listen && (subtitleType === 2 || subtitleType === 1 && node?.texts.length > 1 && node?.texts[1].text !== ''))  {
            if (subtitleType === 1) {
                subtitle = node.texts[1].text  
            }  else {
                subtitle = node.options[0].text
            }
        }
        let authorNoteDtos = null
        if(node?.authorNoteDtos) {
            authorNoteDtos = node.authorNoteDtos
        }

        let hasSuitableParent = false
        response.data.scenarioNodes.forEach(n => {
            n.options.forEach(o => {
                if(o.nextScenarioNodeId === node.nodeId) {
                    response.data.scenarioNodes.forEach(innerNode => {
                        if(innerNode.nodeId === n.nodeId) {
                            if(innerNode.scenarioNodeType !== 6) { // TODO need to replace this with a lookup of previous connected X nodes to see if contain suitable content
                                hasSuitableParent = true
                            }
                        }
                    });
                }
            })
        });
        tempNodes.push({...node, 
            position:{x: node.xPos, y: node.yPos}, 
        id: node.nodeId,
        targetPosition: 'left',
        type: node.scenarioNodeType,
        data: {isBusy: false, hasSuitableParent: hasSuitableParent, id: node.nodeId, options: data, texts:texts, testOptions: dataTestOptions, validationErrors: validationErrors, backgroundImageUrl: node.backgroundImageUrl, listenTextAudioUrl: listenTextAudioUrl, authorNoteDtos: authorNoteDtos, subtitle: subtitle},
        automaticPosition: { x: xPosition , y:yPosition }}) //currentY
    });

    let newTempNodes = {nodes: tempNodes, edges: tempEdges}
    return newTempNodes
}

export function* resyncScenario(action) {
    try {
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response ;
        
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able refresh the lesson. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.RESYNC_SCENARIO.FAILURE, message: e.message});
    }
}


export function* getScenario(action) {
    try {
        sortedAnswers = []
        ender = []
        // yield put({type: topicConstants.GENERAL_RESET.REQUEST});
        if (!action.payload.queryParams) {
            action.payload.queryParams = ""
        }
        const responseImageList = yield call(lessonService.getImageReferences, action.payload);
        yield put({type: lessonConstants.GET_IMAGE_REFERENCES.SUCCESS, data: responseImageList.data});
        
        const response = yield call(lessonService.getScenario, action.payload);
        const areCommentsUnresolved = detectCommentsUnresolved(response.data.scenarioNodes)
        const tempNodes = prepareScenarioForGraph(response)
        yield put({type: lessonConstants.GET_SCENARIO.SUCCESS, data: {...response.data, scenarioNodes: tempNodes.nodes, scenarioEdges: tempNodes.edges, areCommentsUnresolved: areCommentsUnresolved}});
        
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response ;
        
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able download the lesson. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.GET_SCENARIO.FAILURE, message: e.message});
    }
}

export function* canPublishScenario(action) {
    try {
        const lessonGenerationIndex = yield select(lessonSelectors.lessonGenerationIndex);

        const canPublishResponse = yield call(lessonService.canPublishScenario, action.payload.customLessonId, lessonGenerationIndex);
        yield put({type: lessonConstants.CAN_PUBLISH_SCENARIO.SUCCESS, data: canPublishResponse.data})
    } catch (e) {
        yield put({type: lessonConstants.CAN_PUBLISH_SCENARIO.FAILURE, message: e.message});
    }
}


export function* publishScenario(action) {
    try {
        const publishResponse = yield call(lessonService.publishScenario, action.payload);
        yield put({type: lessonConstants.PUBLISH_SCENARIO.SUCCESS, data: publishResponse.data})
        
        // redownload scenario 
        yield put({type: lessonConstants.RESYNC_SCENARIO.REQUEST, data: action.data});
        const response = yield call(lessonService.getScenario, action.payload);
        const areCommentsUnresolved = detectCommentsUnresolved(response.data.scenarioNodes)
        const tempNodes = prepareScenarioForGraph(response)
        yield put({type: lessonConstants.GET_SCENARIO.SUCCESS, data: {...response.data, scenarioNodes: tempNodes.nodes, scenarioEdges: tempNodes.edges, areCommentsUnresolved: areCommentsUnresolved}});

    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
            message: "We were not able download the lesson. " + e.response,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: lessonConstants.PUBLISH_SCENARIO.FAILURE, message: e.message});

    }
}

export function* getScenarioImages(action) {
    try {
        let scenarioImageFiles = []
        for (var imageReference of action.payload.imageReferences) {
            
             const response = yield call(lessonService.getImage, {organizationId: action.payload.organizationId, fileReferenceId: imageReference});
            const type = response.data.split(';')[0].split('/')[1];
            scenarioImageFiles.push(response.data)
        }
        yield put({type: lessonConstants.GET_SCENARIO_IMAGES.SUCCESS, data: scenarioImageFiles});

    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able get the image references. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
   
        yield put({type: lessonConstants.GET_SCENARIO_IMAGES.FAILURE, message: e.message});
    }
}

// const addImageToNode = async (term, organizationId) => {
//     let payload = null
//     let query = term 
//     const lessonsRaw = await pexelClient.photos.search({ query, per_page: 10 })
//     if(lessonsRaw.photos.length > 0) {
//
//     try {
//     const response = await fetch(
//         lessonsRaw.photos[0].src.original + "?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=280&w=280"
//       );
//       if (response.status === 200) {
//         lessonsRaw.photos.forEach(photo => {
//              // TODO plug in NLP to choose most probable suitable image from descriptions
//           });
//         const blob = await response.blob();
//         let guidUniqueImageName = uuidv4();
//         let localBlob = blob
//         localBlob.name = guidUniqueImageName;
//         localBlob.lastModifiedDate = new Date();
//         var finalisedFile = new File([localBlob], guidUniqueImageName,{type: "image/jpeg", lastModified:new Date()})
//         const formData = new FormData();
//         formData.append("cxs", finalisedFile);
//         payload = { 
//             organizationId: organizationId, 
//             name: guidUniqueImageName,
//             formData: formData,
//             isSharable: true }
//         }
//     }
//     catch(e){
//         console.error("error in image search", e)
//     }
//         return {payload: payload, term: term}
//       }
// }


 
export function* createScenario(action) {
    try {
        const response = yield call(lessonService.createScenario, action.payload);
        yield put({type: lessonConstants.CREATE_SCENARIO.SUCCESS, data: response.data});
        let customLessonId = response.data.id
        let tempNode = response.data.scenarioNodes[0]
        if(action.payload.backgroundImageUrl) {
            // Then an existing image was selected. Add a record to the brief node.
            tempNode.backgroundImageUrl = action.payload.backgroundImageUrl
        }
        if(action.payload.backgroundImageBlob) {
            // Then a new image was uploaded. Add a record of it to the organisation.
            const formData = new FormData();
            formData.append(action.payload.backgroundImageBlob.name, action.payload.backgroundImageBlob);

            let queryParams = ['sharable=true']
            queryParams.push("fileReferenceImageUsageType=" + FileReferenceImageUsageType.BriefStageBackground)
            let queryParamAsText = "?" + queryParams.join("&");
            
            let addImageData = { 
                organizationId: action.payload.organizationId, 
                name: action.payload.backgroundImageBlob.name,
                formData: formData,
                queryParams: queryParamAsText
            }

            const responseImageUpload = yield call(lessonService.addImage, addImageData);
            // Add a record of the newly added image to the new scenario's brief node.
            tempNode.backgroundImageUrl = responseImageUpload.data.apiUrl
        }

        if (action.payload.attachToTopicId) {
            const attachPayload = {
                topicId: action.payload.attachToTopicId,
                topicLessonDifficultyId: 0,
                customLessonId: customLessonId
            }
            const attachCustomLessonToTopic = yield call(topicService.attachCustomLessonToTopic, attachPayload);
            yield put ({type: topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.SUCCESS, data: attachCustomLessonToTopic.data});
        }
        
        const responseUpdateScenarioNode = yield call(lessonService.updateScenarioNode, {...tempNode, customLessonId});
        
        if (action.payload.createdInCourseGroupId && action.payload.attachToTopicId) {
            history.push('/creator/course/group/' + action.payload.createdInCourseGroupId + '/topic/' + action.payload.attachToTopicId + '/lesson/edit/' + response.data.id);
        }
        else if (action.payload.attachToTopicId) {
            history.push('/creator/topic/list/' + action.payload.attachToTopicId + '/lessons/edit/' + response.data.id);
        } else {
            history.push('/lessons/edit/' + response.data.id);    
        }
        
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able get the create the lesson. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
   
        yield put({type: lessonConstants.CREATE_SCENARIO.FAILURE, message: e.message});
    }
}

    function prepareScenarioNodesForUpload(nodes) {
        let parsedNodes = []
        nodes.forEach(node => {
            if(!!!node.isConnector) {
                parsedNodes.push(node)
            }
        });
        return parsedNodes
}

export function* updateScenario(action) {
    try {
        yield put({type: lessonConstants.RESYNC_SCENARIO.REQUEST, data: action.data});
        const response = yield call(lessonService.updateScenario, {...action.payload, scenarioNodes: prepareScenarioNodesForUpload(action.payload.scenarioNodes) });
        const tempNodes = prepareScenarioForGraph(response)
        yield put({type: lessonConstants.GET_SCENARIO.SUCCESS, data: {...response.data, scenarioNodes: tempNodes.nodes, scenarioEdges: tempNodes.edges,}});
        
        yield put(snackbarActions.enqueueSnackbar({
            message: "The lesson changes have been saved.",
            options: {
                variant: "success"
        }
    }))
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able to update the lesson. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
   
        yield put({type: lessonConstants.UPDATE_SCENARIO.FAILURE, message: e.message});
    }
}

export function* checkForChanges(action) {
    try {

        const lessonModifiedAt = yield select(lessonSelectors.lessonModifiedAt);
        const lessonGenerationIndex = yield select(lessonSelectors.lessonGenerationIndex);
        if (!lessonModifiedAt) {
            return;
        }
        
        action.payload.ifModifiedSince = lessonModifiedAt;
        const response = yield call(lessonService.getScenario, action.payload);
        
        if (!action.payload.queryParams) {
            action.payload.queryParams = ""
        }
        // 200 ok so the scenario has changed
        yield put( {type: lessonConstants.CHECK_FOR_CHANGES_CHANGED_CONTENT.REQUEST})
        
        const responseImageList = yield call(lessonService.getImageReferences, action.payload);
        yield put({type: lessonConstants.GET_IMAGE_REFERENCES.SUCCESS, data: responseImageList.data});
        
        
        const lessonVersionsReponse = yield call(lessonService.getLessonVersions, action.payload);
        yield put( {type: lessonConstants.GET_LESSON_VERSIONS.SUCCESS, data: lessonVersionsReponse.data});
        
        // same logic as updating the scenario, should be extracted if possible and called in the same way
        const areCommentsUnresolved = detectCommentsUnresolved(response.data.scenarioNodes)
        const tempNodes = prepareScenarioForGraph(response)
        
        
        yield put({type: lessonConstants.GET_SCENARIO.SUCCESS, data: {...response.data, scenarioNodes: tempNodes.nodes, scenarioEdges: tempNodes.edges, areCommentsUnresolved: areCommentsUnresolved}});
        yield put( {type: lessonConstants.CHECK_FOR_CHANGES.SUCCESS})

    } catch (e) {
        
        if (e.response?.status === 304) {
            // console.debug("No changes in scenario");
        } else {
            console.error("The Capeesh Authoring Platform could not connect with the Capeesh servers. Retrying...", e)
        }
    }
}

export function* updateScenarioNode(action) {
  try {
    let node = action.payload.node
    if (node.isNew === true) {
      const responseUpdateScenario = yield call(lessonService.updateScenario, {
        ...action.payload,
        scenarioNodes: prepareScenarioNodesForUpload(action.payload.scenarioNodes)
      });
      yield put({type: lessonConstants.UPDATE_SCENARIO.SUCCESS, data: responseUpdateScenario.data});
    }
    
    if (node?.testOptions) {
      node.testOptions.forEach(testOption => {
        if (testOption.scenarioInteractiveElementType === ScenarioInteractiveElementType.FillInBlankWriting || testOption.scenarioInteractiveElementType === ScenarioInteractiveElementType.FillInBlankDistractors) {

            if (testOption.alternatives.length === 0) {
            testOption.scenarioInteractiveElementType = ScenarioInteractiveElementType.Text;
          } else {
             testOption.alternatives = testOption.alternatives.filter(x => x.text !== "")
          }
        }
      });
    }
      
    if (action.payload.imageFilename !== null && action.payload.imageFilename !== "") {
        
        let queryParams = ['sharable=true']
        queryParams.push("fileReferenceImageUsageType=" + GetFileReferenceImageUsageTypeForNode(node.scenarioNodeType));
        queryParams.push("description=" + action.payload.imageFilename);
        let queryParamAsText = "?" + queryParams.join("&");
    
        const responseImageUpload = yield call(lessonService.addImage, {
            ...action.payload,
            name: action.payload.imageFilename,
            queryParams: queryParamAsText
        });
       
        if (action.payload.imageFieldType === ImageFieldType.TestOption) {
            // TODO: make generic and look for the blob url set on the test option
            node.testOptions[0].imageUrl = responseImageUpload.data.apiUrl
        } else if (action.payload.imageFieldType === ImageFieldType.BackgroundImageUrl) {
            node.backgroundImageUrl = responseImageUpload.data.apiUrl
        } 
        
        yield put({type: lessonConstants.ADD_IMAGE.SUCCESS, data: responseImageUpload.data});
        if (!action.payload.queryParams) {
          action.payload.queryParams = ""
        }
        const responseImageList = yield call(lessonService.getImageReferences, action.payload);
        yield put({type: lessonConstants.GET_IMAGE_REFERENCES.SUCCESS, data: responseImageList.data});
    }
        
    // loop through all new audio tracker objects and add them as file references in the api

    var uploadableAudioTrackers = action.payload.selectedNodeAudioTrackers?.filter(x => x.localFile);
    for (let i = 0; i < uploadableAudioTrackers?.length; i++) {
        let audioTracker = uploadableAudioTrackers[i];
        const formData = new FormData();
        const audioTrackerFileReferenceAudioUsageType = FileReferenceAudioUsageTypeFromAudioFieldType(audioTracker.audioFieldType);

        formData.append(audioTracker.niceName, audioTracker.audioBlob);

        // need to check for meta info for the audio
        var description = audioTracker.audioNiceName;
        
        var niceName = audioTracker.audioNiceName;

        var snippetId = null;
        var languageId = -1;
        switch (audioTracker.audioFieldType) {
            case AudioFieldType.Option:
                if (node.options?.length > 0 && node.options[0].text) {
                    let option = node.options[0];
                    if (option.text) {
                        description = node.options[0].text;
                    }
                    if (option.textLanguageId > 0) {
                        languageId = option.textLanguageId;
                    }
                    if (option.textSnippetId) {
                        snippetId = option.textSnippetId;
                    }
                }

                break;
            case AudioFieldType.TestOption:
                if (!audioTracker.testOptionId) {
                    break;
                }
                node.testOptions.map((testOption) => {
                    if (testOption.testOptionId === audioTracker.testOptionId) {
                        description = testOption.text;
                        languageId = testOption.textLanguageId;
                        snippetId = testOption.textSnippetId;
                    }
                });
                break;

        }

        var queryParams = ['sharable=true']
        queryParams.push("fileReferenceAudioUsageType=" + audioTrackerFileReferenceAudioUsageType)

        if (languageId > 0) {
            queryParams.push("languageId=" + languageId);
        }
        if (snippetId) {
            queryParams.push("connectedTextSnippetId=" + snippetId);
        }
        if (niceName) {
            queryParams.push("niceName=" + niceName);
        }
        if (description) {
            queryParams.push("description=" + description);
        }
        if (audioTracker.recordedInCap) {
            queryParams.push("recordedInCap=true");
        }

        let queryParamAsText = "?" + queryParams.join("&");
        let responseAddAudio = yield call(lessonService.addAudio,
          {
              organizationId: action.payload.organizationId,
              name: description,
              formData: formData,
              queryParams: queryParamAsText
          });

        let newAudioApiUrl = null;
        if (responseAddAudio?.data?.apiUrl) {
            newAudioApiUrl = responseAddAudio.data.apiUrl;
        }

        if (!newAudioApiUrl) {
            console.log("uploaded new audio but got no url back");
            continue;
        }


        switch (audioTracker.audioFieldType) {
            case AudioFieldType.Option:
                if (node.options?.length > 0 && node.options[0].text) {
                    node.options[0].textAudioUrl = newAudioApiUrl;
                }
                break;
            case AudioFieldType.TestOption:
                if (!audioTracker.testOptionId) {
                    console.log("ERROR: adding test option audio url with no test Option id")
                    break;
                }
                let found = false;
                node.testOptions.map((testOption) => {
                    if (testOption.testOptionId === audioTracker.testOptionId) {
                        testOption.textAudioUrl = newAudioApiUrl;
                    }
                });
                break;

            case AudioFieldType.BackgroundAudioUrl:
                node.backgroundAudioUrl = newAudioApiUrl;
                break;
            case AudioFieldType.SfxAudioUrl:
                node.sfxAudioUrl = newAudioApiUrl;
                break;
        }
    }
    
    const customLessonId = action.payload.customLessonId
    const responseUpdateScenarioNode = yield call(lessonService.updateScenarioNode, {...node, customLessonId});
    yield put({type: lessonConstants.UPDATE_SCENARIO_NODE.SUCCESS, data: node});
    // let nodeTemp = null
    //
    // if (node.options && node.options.length > 0 && node.options[0].textAudioUrl) {
    //   responseUpdateScenarioNode.data.scenarioNodes.forEach(newNode => {
    //     if (newNode.nodeId === node.nodeId) {
    //       nodeTemp = JSON.parse(JSON.stringify(newNode));
    //       nodeTemp.options[0].textAudioUrl = node.options[0].textAudioUrl
    //     }
    //   });
    // }
    //
    // if (node.testOptions && node.testOptions.length > 1 && node.testOptions[1].textAudioUrl) {
    //   responseUpdateScenarioNode.data.scenarioNodes.forEach(newNode => {
    //     if (newNode.nodeId === node.nodeId) {
    //       nodeTemp = JSON.parse(JSON.stringify(newNode));
    //       nodeTemp.testOptions[1].textAudioUrl = node.testOptions[1].textAudioUrl
    //     }
    //   });
    // }
    //
    // if (node.scenarioNodeType === ScenarioNodeType.ListenMultiChoice ||
    //     node.scenarioNodeType === ScenarioNodeType.MultiChoice) {
    //   if (node.testOptions && node.testOptions.length > 0 && node.testOptions[0].textAudioUrl) {
    //     responseUpdateScenarioNode.data.scenarioNodes.forEach(newNode => {
    //       if (newNode.nodeId === node.nodeId) {
    //         nodeTemp = JSON.parse(JSON.stringify(newNode));
    //         nodeTemp.testOptions[0].textAudioUrl = node.testOptions[0].textAudioUrl
    //       }
    //     });
    //   }
    // }
    //
    // if (nodeTemp) {
    //   const responseUpdateScenarioNodeSnippetAudio = yield call(lessonService.updateScenarioNode, {
    //     ...nodeTemp,
    //     customLessonId
    //   });
    //   yield put({type: lessonConstants.UPDATE_SCENARIO_NODE.SUCCESS, data: responseUpdateScenarioNodeSnippetAudio});
    //
    // }

    //Scenario resync
    const responseReload = yield call(lessonService.getScenario, action.payload);
    const areCommentsUnresolved = detectCommentsUnresolved(responseReload.data.scenarioNodes)
    const tempNodes = prepareScenarioForGraph(responseReload)
    yield put({
      type: lessonConstants.GET_SCENARIO.SUCCESS,
      data: {
        ...responseReload.data,
        scenarioNodes: tempNodes.nodes,
        scenarioEdges: tempNodes.edges,
        areCommentsUnresolved: areCommentsUnresolved
      }
    });
    
    const responseImageList = yield call(lessonService.getLessonAudioReferences, action.payload);
    yield put({type: lessonConstants.GET_LESSON_AUDIO_REFERENCES.SUCCESS, data: responseImageList.data});
        //TODO: also add organiation file references here at som later point?
    
    yield put({type: lessonConstants.LESSON_BUILDER_BUSY.SUCCESS, payload: {isBusy: false}});
    yield put(snackbarActions.enqueueSnackbar({
        message: "The lesson stage has been updated.",
        options: {
          variant: "success"
        }
      })
    );

  } catch (e) {
    let usefulInformation = ""; // in case response data is empty.
    usefulInformation += e;
    yield put(snackbarActions.enqueueSnackbar({
      message: "We were not able update the lesson node. " + usefulInformation,
      options: {
        variant: "warning"
      }
    }));

    yield put({type: lessonConstants.UPDATE_SCENARIO_NODE.FAILURE, message: e.message});
  }
}

export function* updateScenarioNodeComment(action) {
    try {
        const customLessonId = action.payload.customLessonId
        let newData = {}
        if(action.payload.node?.authorNoteDtos) {
            const areCommentsUnresolved = detectCommentsUnresolved([action.payload.node])

            newData = {
                authorNoteDtos: action.payload.node.authorNoteDtos, 
                nodeId: action.payload.node.nodeId,
                xPos: action.payload.node.xPos,
                yPos: action.payload.node.yPos,
                areCommentsUnresolved: areCommentsUnresolved}
        }
        const responseUpdateScenarioNode = yield call(lessonService.updateScenarioNode, {...action.payload.node, customLessonId});
        yield put({type: lessonConstants.UPDATE_SCENARIO_NODE_COMMENT.SUCCESS, data: newData});

    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data 
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able save the comment. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.UPDATE_SCENARIO_NODE_COMMENT.FAILURE, message: e.message});
    }
}
export function* updateScenarioNodePosition(action) {
    try {
        const customLessonId = action.payload.customLessonId
        const responseUpdateScenarioNode = yield call(lessonService.updateScenarioNode, {...action.payload.node, customLessonId});

        const areCommentsUnresolved = detectCommentsUnresolved(responseUpdateScenarioNode.data.scenarioNodes)
        const tempNodes = prepareScenarioForGraph(responseUpdateScenarioNode)
        yield put({type: lessonConstants.GET_SCENARIO.SUCCESS, data: {...responseUpdateScenarioNode.data, scenarioNodes: tempNodes.nodes, scenarioEdges: tempNodes.edges, areCommentsUnresolved: areCommentsUnresolved}});
        
        yield put({type: lessonConstants.UPDATE_SCENARIO_NODE_POSITION.SUCCESS, data: {nodeId: action.payload.node.nodeId, xPos: action.payload.node.xPos, yPos: action.payload.node.yPos}});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data 
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able save the node's new position. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.UPDATE_SCENARIO_NODE_POSITION.FAILURE, message: e.message});
    }
}

export function* deleteImage(action) {
    try {
        const response = yield call(lessonService.deleteImage, action.payload);
        yield put({type: lessonConstants.DELETE_IMAGE.SUCCESS, data: response.data});

    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data 
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able to delete the existing image. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.DELETE_IMAGE.FAILURE, message: e.message});
    }
}

export function* lessonBuilderBusy(action) {
    try {
        // Request is automatically called.
    } catch (e) {
        
    }
}

export function* replaceImage(action) {
    try {
        const response = yield call(lessonService.deleteImage, action.payload);
        yield put({type: lessonConstants.REPLACE_IMAGE.SUCCESS, data: response.data});
        const responseAdd = yield call(lessonService.addImage, action.payload);
        yield put({type: lessonConstants.ADD_IMAGE.SUCCESS, data: responseAdd.data});
        if (!action.payload.queryParams) {
            action.payload.queryParams = ""
        }
        const responseImageList = yield call(lessonService.getImageReferences, action.payload);
        yield put({type: lessonConstants.GET_IMAGE_REFERENCES.SUCCESS, data: responseImageList.data});
        yield put(snackbarActions.enqueueSnackbar({
            message: "Your image has been uploaded.",
            options: {
                variant: "success"
        }
    }));

    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data 
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were not able to replace the existing image. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: lessonConstants.REPLACE_IMAGE.FAILURE, message: e.message});
    }
}

export function* getLessonVersions(action) {
    try {
        const response = yield call(lessonService.getLessonVersions, action.payload);
        yield put({type: lessonConstants.GET_LESSON_VERSIONS.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data
        yield put(snackbarActions.enqueueSnackbar({
            message: "We were not able to fetch lesson versions. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: lessonConstants.GET_LESSON_VERSIONS.FAILURE, message: e.message});
    }
}

export function* revertToLessonVersion(action) {
    try {
        const response = yield call(lessonService.revertToLessonVersion, action.payload);
        yield put({type: lessonConstants.REVERT_TO_LESSON_VERSION.SUCCESS, data: response.data});
            
        // response now contains the reverted to scenario dto
        if (!action.payload.queryParams) {
            action.payload.queryParams = ""
        }
        const responseImageList = yield call(lessonService.getImageReferences, action.payload);
        yield put({type: lessonConstants.GET_IMAGE_REFERENCES.SUCCESS, data: responseImageList.data});

        // same logic as updating the scenario, should be extracted if possible and called in the same way
        const areCommentsUnresolved = detectCommentsUnresolved(response.data.scenarioNodes)
        const tempNodes = prepareScenarioForGraph(response)

        yield put( {type: lessonConstants.CHECK_FOR_CHANGES_CHANGED_CONTENT.REQUEST})
        yield put({type: lessonConstants.GET_SCENARIO.SUCCESS, data: {...response.data, scenarioNodes: tempNodes.nodes, scenarioEdges: tempNodes.edges, areCommentsUnresolved: areCommentsUnresolved}});
        yield put( {type: lessonConstants.CHECK_FOR_CHANGES.SUCCESS})
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data
        yield put(snackbarActions.enqueueSnackbar({
            message: "We were not revert to the lesson version. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: lessonConstants.REVERT_TO_LESSON_VERSION.FAILURE, message: e.message});
    }
}

//Listeners
export function* lessonSagas() {
    yield takeLatest(lessonConstants.GET_ORGANIZATION_LESSONS.REQUEST, getOrganizationLessons);
    yield takeLatest(lessonConstants.CREATE_CUSTOM_LESSON.REQUEST, createCustomLesson);
    yield takeLatest(lessonConstants.ADD_LESSON_QUESTION.REQUEST, addLessonQuestion);
    yield takeLatest(lessonConstants.GET_CUSTOM_LESSON.REQUEST, getCustomLesson);
    yield takeLatest(lessonConstants.EDIT_CUSTOM_LESSON_DETAILS.REQUEST, editCustomLessonDetails);
    yield takeLatest(lessonConstants.EDIT_QUESTION.REQUEST, editQuestion);
    yield takeLatest(lessonConstants.GENERAL_RESET.REQUEST, generalReset);
    yield takeLatest(lessonConstants.DELETE_CUSTOM_LESSON.REQUEST, deleteCustomLesson);
    yield takeLatest(lessonConstants.DELETE_QUESTION.REQUEST, deleteQuestion);
    yield takeLatest(lessonConstants.ASSIGN_LESSON_TO_TOPIC.REQUEST, assignLessonToTopic);
    yield takeLatest(lessonConstants.GET_CUSTOM_LESSON_RELATIONSHIPS.REQUEST, getCustomLessonRelationships);
    yield takeLatest(lessonConstants.EXPORT_LESSON.REQUEST, exportLesson);
    yield takeLatest(lessonConstants.IMPORT_LESSON.REQUEST, importLesson);
    yield takeLatest(lessonConstants.IMPORT_SCENARIO.REQUEST, importScenario);
    yield takeLatest(lessonConstants.IMPORT_SCENARIO_LINEAR.REQUEST, importScenarioLinear);
    yield takeLatest(lessonConstants.DELETE_FROM_COURSE.REQUEST, deleteFromCourse);
    yield takeLatest(lessonConstants.ADD_IMAGE.REQUEST, addImage);   
    yield takeLatest(lessonConstants.GET_IMAGE_REFERENCES.REQUEST, getImageReferences);  
    yield takeLatest(lessonConstants.GET_IMAGE.REQUEST, getImage); 
    yield takeLatest(lessonConstants.ADD_AUDIO.REQUEST, addAudio); 
    yield takeLatest(lessonConstants.GET_AUDIO_REFERENCES.REQUEST, getAudioReferences); 
    yield takeLatest(lessonConstants.GET_LESSON_AUDIO_REFERENCES.REQUEST, getLessonAudioReferences); 
    yield takeLatest(lessonConstants.GET_AUDIO.REQUEST, getAudio); 
    yield takeLatest(lessonConstants.GET_SCENARIO.REQUEST, getScenario); 
    yield takeLatest(lessonConstants.PUBLISH_SCENARIO.REQUEST, publishScenario);
    yield takeLatest(lessonConstants.GET_SCENARIO_IMAGES.REQUEST, getScenarioImages); 
    yield takeLatest(lessonConstants.CREATE_SCENARIO.REQUEST, createScenario); 
    yield takeLatest(lessonConstants.UPDATE_SCENARIO.REQUEST, updateScenario); 
    yield takeLatest(lessonConstants.RESYNC_SCENARIO.REQUEST, resyncScenario); 
    yield takeLatest(lessonConstants.UPDATE_SCENARIO_NODE.REQUEST, updateScenarioNode); 
    yield takeLatest(lessonConstants.UPDATE_SCENARIO_NODE_POSITION.REQUEST, updateScenarioNodePosition); 
    yield takeLatest(lessonConstants.DELETE_IMAGE.REQUEST, deleteImage); 
    yield takeLatest(lessonConstants.REPLACE_IMAGE.REQUEST, replaceImage); 
    yield takeLatest(lessonConstants.GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES.REQUEST, getCustomLessonSupportedLanguage);
    yield takeLatest(lessonConstants.ADD_CUSTOM_LESSON_SUPPORTED_LANGUAGE.REQUEST, addCustomLessonSupportedLanguage);
    yield takeLatest(lessonConstants.DELETE_CUSTOM_LESSON_SUPPORTED_LANGUAGE.REQUEST, deleteCustomLessonSupportedLanguage);
    yield takeLatest(lessonConstants.EXPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.REQUEST, exportCustomLessonTranslations);
    yield takeLatest(lessonConstants.IMPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.REQUEST, importCustomLessonTranslations);
    yield takeLatest(lessonConstants.UPDATE_SCENARIO_NODE_COMMENT.REQUEST, updateScenarioNodeComment);
    yield takeLatest(lessonConstants.CHECK_FOR_CHANGES.REQUEST, checkForChanges);
    yield takeLatest(lessonConstants.GET_LESSON_VERSIONS.REQUEST, getLessonVersions);
    yield takeLatest(lessonConstants.REVERT_TO_LESSON_VERSION.REQUEST, revertToLessonVersion);
    yield takeLatest(lessonConstants.CAN_PUBLISH_SCENARIO.REQUEST, canPublishScenario);

}