import React, {useEffect, useState} from "react";
import {
    Grid,
} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';


const useStyles = makeStyles(theme => ({
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    //marginRight: '15px',
    cursor: 'pointer',
  },
  leftBox: {
/* Gray 3 */
background: '#EFEFEF',
border: '1px solid #D1D1D6',
boxSizing: 'border-box',
boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
borderRadius: '20px'
  },
  leftPanel: {
    minWidth: '400px'
  },
  mainContainer: {
    paddingLeft: '25px',
    paddingRight: '25px'
  }, 
  rightPaper: {
    /* White */
  //  marginLeft: '20px',
marginTop: '30px',
background: '#FFFFFF',
/* Gray 3 */

border: '1px solid #D1D1D6',
boxSizing: 'border-box',
borderRadius: '15px',
  }


}));

const TutorialDebrief = (props) => {
    const classes = useStyles();
    const tutorialParagraph = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontSize: '16px', marginBottom: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialSubHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', marginTop: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px'}} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', marginBottom: '20px'}}>
            {text}
          </Typography>
        </Grid>

      )
     }


    return (
      <Grid container style={{paddingLeft: '50px', paddingTop: '40px', paddingRight: '20px'}}> 
          {tutorialHeader("The Debrief Stage")}
        <Grid item xs={12} sm={12} md={12} style={{display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: '35px'}}>
              <iframe src="https://player.vimeo.com/video/692174555?h=c715460a81" style={{alignItems: 'center'}} width="768" height="432"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Grid>
          {tutorialSubHeader("What is it?")}
          {tutorialParagraph("Every lesson has to end with a Debrief stage that shows the result and gives feedback in either L1 or L2.")}
          {tutorialSubHeader("Learner's score is revealed")}
          {tutorialParagraph("The debrief stage is always the final stage that the learner reaches. It reveals the learner's score. A score can be betwen zero and three stars.")}
          {tutorialSubHeader("Personalised feedback")}
          {tutorialParagraph("In a role play, the learner can also receive personalised feedback based on the decisions they made during the role play. For instance if the author set feedback in a Branching stage, this appears in the debrief. The Branching section of this guide explains this in detail.")}
          {tutorialSubHeader("Feedback text in the debrief")}
          {tutorialParagraph("In addition to displaying any Branching stage feedback, the Debrief Stage can also contain feedback to present to the learner.")}
          {tutorialSubHeader("Feedback when user passes the lesson")}
          {tutorialParagraph("The first debrief text field is an opportunity for you as a course author to add text that appears when the learner reaches this particular brief stage, having passed the lesson. In other words, they scored at least one star. Write suitable text here to congratulate the learner for passing.")}
          {tutorialSubHeader("Feedback when user fails the lesson")}
          {tutorialParagraph("The second Debrief Stage text field gives the opportunity to write feedback text that appears in the event that the learner failed the course (scored zero stars).")}
          {tutorialSubHeader("Tip: Mention the next lesson in the debrief text")}
          {tutorialParagraph("It can be a great idea to reference in the debrief pass text what the next lesson in the course contains. This helps to give a feeling of a single, cohesive course.")}
          {tutorialParagraph("For instance, if the lesson was focusing on teaching Drive Thru restaurant language, and the next lesson is a role play of working in a drive thru, good debrief text might look like the following:.")}
          {tutorialParagraph("'Well done, you have learnt some key language and concepts from our Drive Thru! In the next lesson we will put this language in action by taking a customer's Drive Thru order.'")}
       </Grid>
    );
    }

    TutorialDebrief.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(TutorialDebrief)