import { call, put, takeLatest } from 'redux-saga/effects'
import {lessonConstants, topicConstants} from '../actions'
import {lessonService, topicService} from '../services'
import { history } from '../helpers'
import fileDownload from 'js-file-download';
import {snackbarActions} from "../actions/snackbar.actions";
import {convertDataURIToBinary} from "../helpers/base64Logic";
import JSZip from "jszip";
import {TranslationImportExportVersion} from "../../Logic/TopicTranslationConstants";

export function* addFile(action) {
    try {
        const response = yield call(topicService.addFile, action.payload);
        yield put({type: topicConstants.ADD_FILE.SUCCESS, data: response.data});
        if (action.payload.lessonsOnly) {
            yield put({type: topicConstants.GET_TOPIC_DOCUMENTS.REQUEST, payload: action.payload});    
        } else {
            yield put({type: topicConstants.GET_FILES.REQUEST, payload: action.payload}); // TODO: remove when vocab is phased out
            yield put({type: topicConstants.GET_TOPIC_WORDS.REQUEST, payload: action.payload}); // TODO: remove when vocab is phased out             
        }
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "Sorry, we were unable to add the file. We are looking into this.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.ADD_FILE.FAILURE, message: e.message});
    }
}

export function* deleteFile(action) {
    try {
        const response = yield call(topicService.deleteFile, action.payload);
        yield put({type: topicConstants.DELETE_FILE.SUCCESS, data: response.data});
        yield put({type: topicConstants.GET_FILES.REQUEST, payload: action.payload});
        yield put({type: topicConstants.GET_TOPIC_WORDS.REQUEST, payload: action.payload});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to delete the file. Please try again or contact support.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.DELETE_FILE.FAILURE, message: e.message});
    }
}


export function* deleteTopicDocument(action) {
    try {
        const response = yield call(topicService.deleteTopicDocument, action.payload);
        yield put({type: topicConstants.DELETE_TOPIC_DOCUMENT.SUCCESS, data: response.data});
        yield put({type: topicConstants.GET_TOPIC_DOCUMENTS.REQUEST, payload: action.payload});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
            message: "We were unable to delete the file. Please try again or contact support.",
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.DELETE_TOPIC_DOCUMENT.FAILURE, message: e.message});
    }
}



export function* addUrl(action) {
    try {
        const response = yield call(topicService.addUrl, action.payload);
        yield put({type: topicConstants.ADD_URL.SUCCESS, data: response.data});
        yield put({type: topicConstants.GET_FILES.REQUEST, payload: action.payload});
        yield put({type: topicConstants.GET_TOPIC_WORDS.REQUEST, payload: action.payload});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
                message: "Sorry, we were not able to add that website. " +usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.ADD_URL.FAILURE, message: e.message});
    }
}

export function* generalReset(action) {
    try {
        yield put({type: topicConstants.GENERAL_RESET.REQUEST});
        yield put({type: topicConstants.GENERAL_RESET.SUCCESS});
    } catch (e) {
        yield put({type: topicConstants.GENERAL_RESET.FAILURE, message: e.message});
    }
}

export function* setCompletedProcessing(action) {
    try {
        yield put({type: topicConstants.SET_COMPLETED_PROCESSING.REQUEST, data: action.payload});
        yield put({type: topicConstants.SET_COMPLETED_PROCESSING.SUCCESS, data: action.payload});
    } catch (e) {
        yield put({type: topicConstants.SET_COMPLETED_PROCESSING.FAILURE, message: e.message});
    }
}

export function* getFiles(action) {
    try {
        const response = yield call(topicService.getFiles, action.payload);
        yield put({type: topicConstants.GET_FILES.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: topicConstants.GET_FILES.FAILURE, message: e.message});
    }
}

export function* getTopicDocuments(action) {
    try {
        const response = yield call(topicService.getTopicDocuments, action.payload);
        yield put({type: topicConstants.GET_TOPIC_DOCUMENTS.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: topicConstants.GET_TOPIC_DOCUMENTS.FAILURE, message: e.message});
    }
}

export function* getDocumentWords(action) {
    try {
        const response = yield call(topicService.getDocumentWords, action.payload);
        yield put({type: topicConstants.GET_DOCUMENT_WORDS.SUCCESS, data: response.data.tags});
    } catch (e) {
        yield put({type: topicConstants.GET_DOCUMENT_WORDS.FAILURE, message: e.message});
    }
}

export function* updateTopic(action) {
    try {
        const response = yield call(topicService.updateTopic, action.payload);
        yield put({type: topicConstants.UPDATE_TOPIC.SUCCESS, data: response.data.tags});
        const responseGet = yield call(topicService.getCurrentTopic, action.payload);
        yield put({type: topicConstants.GET_CURRENT_TOPIC.SUCCESS, data: responseGet.data});

        yield put({type: topicConstants.GET_ALL_TOPICS.REQUEST, payload: action.payload});
        yield put(snackbarActions.enqueueSnackbar({
                message: "Changes saved.",
                options: {
                    variant: "success"
            }
        }));
    } catch (e) {
        yield put({type: topicConstants.UPDATE_TOPIC.FAILURE, message: e.message});
    }
}

export function* updateTopicLanguageStatus(action) {
    try {
        const response = yield call(topicService.updateTopicLanguageStatus, action.payload);
        yield put({type: topicConstants.UPDATE_TOPIC_LANGUAGE_STATUS.SUCCESS, data: response.data.tags});
        yield put({type: topicConstants.GET_ALL_TOPICS.REQUEST, payload: action.payload});
        yield put(snackbarActions.enqueueSnackbar({
                message: "Changes saved.",
                options: {
                    variant: "success"
            }
        }));
    } catch (e) {
        yield put({type: topicConstants.UPDATE_TOPIC_LANGUAGE_STATUS.FAILURE, message: e.message});
    }
}

export function* createTopic(data) {
    try {
        const response = yield call(topicService.createTopic, data.payload);
        yield put({type: topicConstants.CREATE_TOPIC.SUCCESS, data: response.data});
        
        //TODO: MORTEN refactor to point to creator instead
        if(data.payload.classroomId) {
            history.push('/classroom/' + data.payload.classroomId + '/courses/' + response.data.id);
        }
        else {
            history.push('/courses/' + response.data.id);
        }
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "Sorry, we could not create the course. It may be that a course exists with that name.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.CREATE_TOPIC.FAILURE, message: e.message});
    }
}

export function* publishTopic(data) {
    try {
        const response = yield call(topicService.publishTopic, data.payload);
        yield put({type: topicConstants.PUBLISH_TOPIC.SUCCESS, data: response.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
        const responseTopicDifficulties = yield call(topicService.getTopicDifficulties, data.payload);
        yield put({type: topicConstants.GET_TOPIC_DIFFICULTIES.SUCCESS, data: responseTopicDifficulties.data});
        if(responseTopicDifficulties.data.length > 0) {
            const lessonOrderRequest = {topicId: data.payload.topicId, topicLessonDifficultyId: responseTopicDifficulties.data[0].id}
            
            const responseTopicLessonOrder = yield call(topicService.getLessonOrderForDifficulty, lessonOrderRequest);
            yield put({type: topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS, data: responseTopicLessonOrder.data});
        }
        yield put(snackbarActions.enqueueSnackbar({
                message: "The course has been published. You can now add quizzes and scenarios to the course.",
                options: {
                    variant: "success"
            }
        }));
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to publish the course. Ensure enough words have been approved.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.PUBLISH_TOPIC.FAILURE, message: e.message});
    }
}

export function* getAllTopics(data) {
    try {
        const response = yield call(topicService.getAllTopics, data.payload.organizationId);
        yield put({type: topicConstants.GET_ALL_TOPICS.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: topicConstants.GET_ALL_TOPICS.FAILURE, message: e.message});
    }
}

export function* getTopicWords(data) {
    try {
        const response = yield call(topicService.getTopicWords, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS.SUCCESS, data: response.data});
        const responseIgnored = yield call(topicService.getIgnoredWords, data.payload);
        yield put({type: topicConstants.GET_IGNORED_WORDS.SUCCESS, data: responseIgnored.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to retrieve the words for this topic. We are looking into this.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_WORDS.FAILURE, message: e.message});
    }
}

function checkEnglishSupport(data) {
    let englishSupport = false
    if (data.payload.supportedLanguages == null || data.payload.supportedLanguages.length == 0) {
        return false;
    }
    if( data.payload.l2Id === 1 || data.payload.l2Id === 26) {
        return false;
    }
    data.payload.supportedLanguages.forEach(language => {
        if(language.l1Id == 1) {
            englishSupport =  true;
        }
    });
    return englishSupport
    
}

export function* getTopicWordsForL1(data) {
    try {
        const response = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: response.data});
        yield put({type: topicConstants.CHANGE_SELECTED_L1.SUCCESS, data: data.payload.l1Id});
        const englishSupport = checkEnglishSupport(data)
        if(englishSupport) {
            const responseEnglish = yield call(topicService.getTopicWordsForEnglish, data.payload);
            yield put({type: topicConstants.GET_TOPIC_WORDS_ENGLISH.SUCCESS, data: responseEnglish.data});
        } else {
            yield put({type: topicConstants.UPDATE_TOGGLE.SUCCESS, data: 'false'});
        }
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to retrieve the words for this topic. We are looking into this.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.FAILURE, message: e.message});
    }
}

export function* getTopicWordsForEnglish(data) {
    try {
        const response = yield call(topicService.getTopicWordsForEnglish, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_ENGLISH.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to retrieve the words for this topic. We are looking into this.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_WORDS_ENGLISH.FAILURE, message: e.message});
    }
}

export function* getIgnoredWords(data) {
    try {
        const response = yield call(topicService.getIgnoredWords, data.payload);
        yield put({type: topicConstants.GET_IGNORED_WORDS.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to retrieve ignored words for this topic. We are looking into this.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_IGNORED_WORDS.FAILURE, message: e.message});
    }
}

export function* deleteTopic(data) {
    try {
        const response = yield call(topicService.deleteTopic, data.payload);
        yield put({type: topicConstants.DELETE_TOPIC.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to delete the topic. Please try again or contact support.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.DELETE_TOPIC.FAILURE, message: e.message});
    }
}

export function* restoreTopicWord(data) {
    try {
        const response = yield call(topicService.restoreTopicWord, data.payload);
        yield put({type: topicConstants.RESTORE_TOPIC_WORD.SUCCESS, data: response.data});
        yield put({type: topicConstants.GET_TOPIC_WORDS.REQUEST, payload:  {topicId: data.payload.topicId}});
        yield put({type: topicConstants.GET_IGNORED_WORDS.REQUEST, payload:  {topicId: data.payload.topicId}});
        yield put(snackbarActions.enqueueSnackbar({
                message: "The word(s) have been added to the course.",
                options: {
                    variant: "success"
            }
        }));
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to restore the content. Please try again or contact support.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.RESTORE_TOPIC_WORD.FAILURE, message: e.message});
        yield put({type: topicConstants.GET_IGNORED_WORDS.REQUEST, payload:  {topicId: data.payload.topicId}});
    }
}

export function* deleteTopicWords(data) {
    try {
        const response = yield call(topicService.deleteTopicWords, data.payload);
        yield put({type: topicConstants.DELETE_TOPIC_WORDS.SUCCESS, data: response.data});
        yield put(snackbarActions.enqueueSnackbar({
                message: "The content has been removed from the course.",
                options: {
                    variant: "success"
            }
        }));
          const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        yield put({type: topicConstants.GET_IGNORED_WORDS.REQUEST, payload:  {topicId: data.payload.topicId}});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to delete the content. Please try again or contact support.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.DELETE_TOPIC_WORDS.FAILURE, message: e.message});
        yield put({type: topicConstants.GET_TOPIC_WORDS.REQUEST, payload:  {topicId: data.payload.topicId}});
    }
}

export function* updateTopicWords(data) {
    try {
        const response = yield call(topicService.updateTopicWords, data.payload);
        yield put({type: topicConstants.UPDATE_TOPIC_WORDS.SUCCESS, data: response.data});
        yield put(snackbarActions.enqueueSnackbar({
                message: "The content has been updated.",
                options: {
                    variant: "success"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_WORDS.REQUEST, payload:  {topicId: data.payload.topicId}});
        yield put({type: topicConstants.GET_IGNORED_WORDS.REQUEST, payload:  {topicId: data.payload.topicId}});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to update the content. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.UPDATE_TOPIC_WORDS.FAILURE, message: e.message});
        yield put({type: topicConstants.GET_TOPIC_WORDS.REQUEST, payload:  {topicId: data.payload.topicId}});
    }
}

export function* deleteTopicSnippet(data) {
    try {
        const response = yield call(topicService.deleteTopicSnippet, data.payload);
        yield put({type: topicConstants.DELETE_TOPIC_SNIPPET.SUCCESS, data: response.data});
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.REQUEST, payload: data.payload});
    } catch (e) {
        yield put({type: topicConstants.DELETE_TOPIC_SNIPPET.FAILURE, message: e.message});
    }
}

export function* deleteTopicWordDistractor(data) {
    try {
        const response = yield call(topicService.deleteTopicWordDistractor, data.payload);
        yield put({type: topicConstants.DELETE_TOPIC_WORD_DISTRACTOR.SUCCESS, data: response.data});
        const responseL1 =  yield call(topicService.getTopicWordsForL1,  data.payload);   
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to delete the distractor. Please try again or contact support.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.DELETE_TOPIC_WORD_DISTRACTOR.FAILURE, message: e.message});
    }
}

export function* getAllUserGroups(data) {
    try {
        const response = yield call(topicService.getAllUserGroups, data.payload.organizationId);
        yield put({type: topicConstants.GET_ALL_USERGROUPS.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to load the cohort. Please try again or contact support.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_ALL_USERGROUPS.FAILURE, message: e.message});
    }
}

export function* getAllUserGroupsForTopics(data) {
    try {
        const response = yield call(topicService.getAllUserGroupsForTopics, data.payload);
        yield put({type: topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to load the classrooms assigned to this course.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.FAILURE, message: e.message});
    }
}

export function* getClusters(data) {
    try {
        const response = yield call(topicService.getClusters, data.payload);
        yield put({type: topicConstants.GET_CLUSTERS.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to get clusters for this course.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_CLUSTERS.FAILURE, message: e.message});
    }
}

export function* getTopicGenerateStatus(data) {
    try {
        const response = yield call(topicService.getTopicGenerateStatus, data.payload);
        yield put({type: topicConstants.GET_TOPIC_GENERATE_STATUS.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "There was a problem creating the course.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_GENERATE_STATUS.FAILURE, message: e.message});
    }
}

export function* forceRegeneration(data) {
    try {
        const response = yield call(topicService.forceRegeneration, data.payload);
        yield put({type: topicConstants.FORCE_REGENERATION.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "Error when trying to force regeneration.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.FORCE_REGENERATION.FAILURE, message: e.message});
    }
}

export function* cloneCourse(data) {
    try {
        const response = yield call(topicService.cloneCourse, data.payload);
        yield put({type: topicConstants.CLONE_COURSE.SUCCESS, data: response.data});
        yield put(snackbarActions.enqueueSnackbar({
                message: "The course has been duplicated.",
                options: {
                    variant: "success"
            }
        }));
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "Unable to clone the course.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.CLONE_COURSE.FAILURE, message: e.message});
    }
}

export function* approveSnippet(data) {
    try {
        const response = yield call(topicService.approveSnippet, data.payload);
        yield put({type: topicConstants.APPROVE_SNIPPET.SUCCESS, data: response.data});
      
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        const englishSupport = checkEnglishSupport(data)
        if(englishSupport) {
            const responseEnglish = yield call(topicService.getTopicWordsForEnglish, data.payload);
            yield put({type: topicConstants.GET_TOPIC_WORDS_ENGLISH.SUCCESS, data: responseEnglish.data});
        } else {
            yield put({type: topicConstants.UPDATE_TOGGLE.SUCCESS, data: 'false'});
        }
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "The snippet could not be approved.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.APPROVE_SNIPPET.FAILURE, message: e.message});
    }
}

export function* disapproveSnippet(data) {
    try {
        const response = yield call(topicService.disapproveSnippet, data.payload);
        yield put({type: topicConstants.DISAPPROVE_SNIPPET.SUCCESS, data: response.data});
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const englishSupport = checkEnglishSupport(data)
        if(englishSupport) {
            const responseEnglish = yield call(topicService.getTopicWordsForEnglish, data.payload);
            yield put({type: topicConstants.GET_TOPIC_WORDS_ENGLISH.SUCCESS, data: responseEnglish.data});
        } else {
            
            yield put({type: topicConstants.UPDATE_TOGGLE.SUCCESS, data: 'false'});
        }
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "The snippet could not be approved.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.APPROVE_SNIPPET.FAILURE, message: e.message});
    }
}

export function* approveWord(data) {
    try {
        const response = yield call(topicService.approveWord, data.payload);
        yield put({type: topicConstants.APPROVE_WORD.SUCCESS, data: response.data});
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
        const englishSupport = checkEnglishSupport(data)
        if(englishSupport) {
            const responseEnglish = yield call(topicService.getTopicWordsForEnglish, data.payload);
            yield put({type: topicConstants.GET_TOPIC_WORDS_ENGLISH.SUCCESS, data: responseEnglish.data});
        } else {
            
            yield put({type: topicConstants.UPDATE_TOGGLE.SUCCESS, data: 'false'});
        }
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "The word could not be approved.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.APPROVE_WORD.FAILURE, message: e.message});
    }
}

export function* disapproveWord(data) {
    try {
        const response = yield call(topicService.disapproveWord, data.payload);
        yield put({type: topicConstants.DISAPPROVE_WORD.SUCCESS, data: response.data});
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
        const englishSupport = checkEnglishSupport(data)
        if(englishSupport) {
            const responseEnglish = yield call(topicService.getTopicWordsForEnglish, data.payload);
            yield put({type: topicConstants.GET_TOPIC_WORDS_ENGLISH.SUCCESS, data: responseEnglish.data});
        } else {
            yield put({type: topicConstants.UPDATE_TOGGLE.SUCCESS, data: 'false'});
        }
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "The word could not be approved.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.DISAPPROVE_WORD.FAILURE, message: e.message});
    }
}

export function* changeToAltTranslation(data) {
    try {
        const response = yield call(topicService.changeToAltTranslation, data.payload);
        yield put({type: topicConstants.CHANGE_TO_ALT_TRANSLATION.SUCCESS, data: response.data});
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "The translation could not be altered.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.DISAPPROVE_WORD.FAILURE, message: e.message});
    }
}

export function* addSupportedLanguage(data) {
    try {
        const response = yield call(topicService.addSupportedLanguage, data.payload);
        yield put({type: topicConstants.ADD_SUPPORTED_LANGUAGE.SUCCESS, data: data});

        const topicResponse = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: topicResponse.data});

    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "The new language could not be added",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.DISAPPROVE_WORD.FAILURE, message: e.message});
    }
}

export function* getTopic(data) {
    try {
        let englishSupport = false
        const response = yield call(topicService.getTopic, data.payload);
        const responseRequestedLanguages = yield call(topicService.getRequestedL1s, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: response.data});
        yield put({type: topicConstants.GET_REQUESTED_L1S.SUCCESS, data: responseRequestedLanguages.data});
    } catch (e) {
        yield put({type: topicConstants.GET_TOPIC_GENERATE_STATUS.REQUEST, message: "The course  could not be refreshed"});
        yield put({type: topicConstants.GET_TOPIC.FAILURE, message: e.message});
    }
}

export function* changeTranslation(data) {
    try {
        const response = yield call(topicService.changeTranslation, data.payload);
        yield put({type: topicConstants.CHANGE_TRANSLATION.SUCCESS, data: response.data});
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: "The translation could not be changed. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.CHANGE_TRANSLATION.FAILURE, message: e.message});
    }
}

export function* changeSnippetL1(data) {
    try {
        const response = yield call(topicService.changeSnippetL1, data.payload);
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});

    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
                message: "The snippet translation could not be changed. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.CHANGE_SNIPPET_L1.FAILURE, message: e.message});
    }
}

export function* changeSnippetL2(data) {
    try {
        const response = yield call(topicService.changeSnippetL2, data.payload);
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});

    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
                message: "The snippet translation could not be changed. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.CHANGE_SNIPPET_L2.FAILURE, message: e.message});
    }
}

export function* changeSnippetQuestionL1(data) {
    try {
        const response = yield call(topicService.changeSnippetQuestionL1, data.payload);
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});

    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Adding a question to the snippet failed with errors: " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.CHANGE_SNIPPET_QUESTION_L1.FAILURE, message: e.message});
    }
}


export function* createSnippetL2(data) {
    try {
        const response = yield call(topicService.createSnippetL2, data.payload);
        yield put({type: topicConstants.CREATE_SNIPPET_L2.SUCCESS, data: response.data});
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: "The snippet translation could not be created. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.CREATE_SNIPPET_L2.FAILURE, message: e.message});
    }
}

export function* getSharableCourses(data) {
    try {
        const response = yield call(topicService.getSharableCourses, null);
        yield put({type: topicConstants.GET_SHARABLE_COURSES.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: "Could not get the sharable course list. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.CREATE_SNIPPET_L2.FAILURE, message: e.message});
    }
}

export function* getCurrentTopic(data) {
    console.log("getCurrentTopic");
    try {
        yield put({type: topicConstants.EDIT_TOPIC_RESET.REQUEST, data: data});
        yield put({type: topicConstants.EDIT_TOPIC_RESET.SUCCESS, data});
        const response = yield call(topicService.getCurrentTopic, data.payload);
        yield put({type: topicConstants.GET_CURRENT_TOPIC.SUCCESS, data: response.data});
        
        if(response.data.published === true) {
            const responseTopicDifficulties = yield call(topicService.getTopicDifficulties, data.payload);
            yield put({type: topicConstants.GET_TOPIC_DIFFICULTIES.SUCCESS, data: responseTopicDifficulties.data});
            if(responseTopicDifficulties.data.length > 0) {
                const lessonOrderRequest = {topicId: data.payload.topicId, topicLessonDifficultyId: responseTopicDifficulties.data[0].id}
                const responseTopicLessonOrder = yield call(topicService.getLessonOrderForDifficulty, lessonOrderRequest);
                yield put({type: topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS, data: responseTopicLessonOrder.data});
            }
        }
       
    } catch (e) {
        let  usefulInformation = "Could not get the selected course"; // in case response data is empty.
        usefulInformation = e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_CURRENT_TOPIC.FAILURE, message: e.message});
    }
}

export function* updateLocalCourseName(data) {
    try {
        yield put({type: topicConstants.UPDATE_LOCAL_COURSE_NAME.SUCCESS, data: data});
    } catch (e) {
        yield put({type: topicConstants.UPDATE_LOCAL_COURSE_NAME.FAILURE, message: e.message});
    }
}

export function* getRequestedL1s(data) {
    try {
        const response = yield call(topicService.getRequestedL1s, data.payload);
        yield put({type: topicConstants.GET_REQUESTED_L1S.SUCCESS, data: data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: "Could not get L1s requested by students. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_REQUESTED_L1S.FAILURE, message: e.message});
    }
}

export function* exportCourse(data) {
    try {
        const response = yield call(topicService.exportCourse, data.payload);
        fileDownload(response.data, data.payload.filename);
        yield put({type: topicConstants.EXPORT_COURSE.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: "Could not export the course. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.EXPORT_COURSE.FAILURE, message: e.message});
    }
}

export function* importCourse(data) {
    try {
        const response = yield call(topicService.importCourse, data.payload);
        yield put({type: topicConstants.IMPORT_COURSE.SUCCESS, data: response.data});
        const responseRefresh = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseRefresh.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not import the course. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.IMPORT_COURSE.FAILURE, message: e.message});
    }
}

export function* deleteTopicWord(data) {
    try {
        const response = yield call(topicService.deleteTopicWord, data.payload);
        yield put({type: topicConstants.DELETE_TOPIC_WORD.SUCCESS, data: response.data});
        const responseRefresh = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseRefresh.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not delete the word. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.IMPORT_COURSE.FAILURE, message: e.message});
    }
}

export function* changeDescription(data) {
    try {
        const response = yield call(topicService.changeDescription, data.payload);
        yield put({type: topicConstants.CHANGE_DESCRIPTION.SUCCESS, data: response.data});
        const responseL1 = yield call(topicService.getTopicWordsForL1, data.payload);
        yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseL1.data});
        const responseTopicRefresh = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
   } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not update the description " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.CHANGE_DESCRIPTION.FAILURE, message: e.message});
    }
}

export function* getTopicDifficulties(data) {
    try {
        const response = yield call(topicService.getTopicDifficulties, data.payload);
        yield put({type: topicConstants.GET_TOPIC_DIFFICULTIES.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: "Could not get the topic's supported difficulty levels. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_DIFFICULTIES.FAILURE, message: e.message});
    }
}

export function* attachCustomLessonToTopic(data) {
    try {
        const response = yield call(topicService.attachCustomLessonToTopic, data.payload);
        yield put({type: topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.SUCCESS, data: response.data});

        const updatedTopicLessonOrderResponse = yield call(topicService.getLessonOrderForDifficulty, data.payload);
        yield put({type: topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS, data: updatedTopicLessonOrderResponse.data});
        
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could attach the lesson to the topic. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.FAILURE, message: e.message});
    }
}

export function* getLessonOrderForDifficulty(data) {
    try {
        const response = yield call(topicService.getLessonOrderForDifficulty, data.payload);
        yield put({type: topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: "Could not get the topic's lesson order for the specified difficulty. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.FAILURE, message: e.message});
    }
}

export function* fetchLessonOrderForIndexing(data) {
    try {
        const response = yield call(topicService.getLessonOrderForDifficulty, data.payload);
        
        const topicLessonOrderForIndexingData = {
            topicId: data.payload.topicId,
            topicLessonOrder: response.data
        }
        
        yield put({type: topicConstants.GET_LESSON_ORDER_FOR_INDEXING.SUCCESS, data: topicLessonOrderForIndexingData});
        
        
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not get the topic's lesson order for the Topic. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_LESSON_ORDER_FOR_INDEXING.FAILURE, message: e.message});
    }
}



export function* updateLessonOrderForDifficulty(data) {
    try {
        const response = yield call(topicService.updateLessonOrderForDifficulty, data.payload);
        yield put({type: topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS, data: response.data});
        yield put(snackbarActions.enqueueSnackbar({
            message: "The course curriculum has been updated.",
            options: {
                variant: "success"
        }
    }));
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: "Could not get the topic's lesson order for the specified difficulty. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.FAILURE, message: e.message});
    }
}


export function* updateLocalLessonOrder(data) {
    try {
        console.log("data1", data.payload)
        
        const responseUpdate = yield call(topicService.updateLessonOrderForDifficulty, data.payload);
        yield put({type: topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS, data: responseUpdate.data});
        //yield put({type: topicConstants.UPDATE_LOCAL_LESSON_ORDER.SUCCESS, data: data});
        const response = yield call(topicService.getCurrentTopic, data.payload);
        yield put({type: topicConstants.GET_CURRENT_TOPIC.SUCCESS, data: response.data});
        
        if (response.data && !!!response.data.lessonsOnly) {
            const responseWords = yield call(topicService.getTopicWords, data.payload);
            yield put({type: topicConstants.GET_TOPIC_WORDS.SUCCESS, data: responseWords.data});
            const responseIgnored = yield call(topicService.getIgnoredWords, data.payload);
            yield put({type: topicConstants.GET_IGNORED_WORDS.SUCCESS, data: responseIgnored.data});
            const responseRequested = yield call(topicService.getRequestedL1s, data.payload);
            yield put({type: topicConstants.GET_REQUESTED_L1S.SUCCESS, data: responseRequested.data});    
        }
        
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
                message: "Could not update the local lesson order. " + usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: topicConstants.UPDATE_LOCAL_LESSON_ORDER.FAILURE, message: e.message});
    }
}



export function* exportExcelTopicTranslationForAllLanguages(data) {


    let zip = new JSZip();
    var errors = []
    for (const languageConfig of data.payload.exportLanguageConfigs)
    {
        var tempPayload = {
            topicId: data.payload.topicId,
            l1Id: languageConfig.l1Id,
            filename: languageConfig.filename,
            queryParams: data.payload.queryParams
        }
        try {
            const response = yield call(topicService.exportExcelTopicTranslation, tempPayload);
            var binary= convertDataURIToBinary(response.data);
            var blob=new Blob([binary], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

            zip.file(languageConfig.filename, blob);
            
            // fileDownload(blob, languageConfig.filename);
        } catch (e) {
            let  usefulInformation = ""; // in case response data is empty.
            usefulInformation += e.response.data;
            errors.appendChild("Failed to create for file name " + languageConfig.filename);
        }
    }
    if (errors.length > 0) {
        yield put(snackbarActions.enqueueSnackbar({
            message: "Failed to export wit errors" + errors,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.FAILURE, message: errors});
    } else {
        zip.generateAsync({type:"blob"})
        .then(function (blob) {
            fileDownload(blob, data.payload.zipFileName);
        });
        yield put({type: topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.SUCCESS, data: {}});    
    }
}


export function* exportExcelTopicTranslation(data) {
    try {
        if (data.payload.translationVersion === TranslationImportExportVersion.V1)  {
            const response = yield call(topicService.exportExcelTopicTranslation, data.payload);
            var binary= convertDataURIToBinary(response.data);
            var blob=new Blob([binary], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            fileDownload(blob, data.payload.filename);
            yield put({type: topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.SUCCESS, data: response.data});    
        } else if (data.payload.translationVersion === TranslationImportExportVersion.V2) {
            const response = yield call(topicService.exportExcelTopicTranslationV2, data.payload);
            var binary= convertDataURIToBinary(response.data);
            var blob=new Blob([binary], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            fileDownload(blob, data.payload.filename);
            yield put({type: topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.SUCCESS, data: response.data});
        } else {
            yield put(snackbarActions.enqueueSnackbar({
                message: "Export for machine tranlations not currently supported",
                options: {
                    variant: "warning"
                }
            }));
            yield put({type: topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.FAILURE, message: "Export for machine tranlations not currently supported"});
            return;
        }
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not export topic translations to excel file. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.FAILURE, message: e.message});
    }
}

export function *exportSentenceSuggestionsFromFile(data) {
    try {
        const response = yield call(topicService.exportSentenceSuggestionsFromFile, data.payload);

        var binary= convertDataURIToBinary(response.data);
        var blob=new Blob([binary], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        fileDownload(blob, data.payload.filename);
        yield put({type: topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.SUCCESS, data: response.data});
    }  catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not export sentence suggestions to excel file. " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.FAILURE, message: e.message});
    }
}


export function* importExcelTopicTranslation(data) {
    try {
        if (data.payload.version === TranslationImportExportVersion.V1) {
            const response = yield call(topicService.importExcelTopicTranslation, data.payload);
            yield put({type: topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.SUCCESS, data: response.data});
        } else if (data.payload.version === TranslationImportExportVersion.V2) {
            const response = yield call(topicService.importExcelTopicTranslationV2, data.payload);
            yield put({type: topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.SUCCESS, data: response.data});
        } else if (data.payload.version === TranslationImportExportVersion.MachineTranslationV1) {
            const response = yield call(topicService.importCsvTopicMachineTranslationV1, data.payload);
            yield put({type: topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.SUCCESS, data: response.data});
        } else {
            yield put(snackbarActions.enqueueSnackbar({
                message: "Import translation not possible, no file versioning provided.",
                options: {
                    variant: "warning"
                }
            }));
            yield put({type: topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.FAILURE, message: "Import translation not possible, no file versioning provided."});
            return;
        }
        
        const topicInfoResponse = yield call(topicService.getTopic, data.payload);
        yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: topicInfoResponse.data});

        yield put(snackbarActions.enqueueSnackbar({
            message: "Successfully imported translations for topic from the excel file",
            options: {
                variant: "success"
            }
        }));
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not import translations for topic from the excel file " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.FAILURE, message: e.message});
    }
}

export function* getTopicDataPickerForLessonData(data) {
    try {
        const response = yield call(topicService.getDataPickerContentForLessonData, data.payload);
        yield put({type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not get data from other lessons " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.FAILURE, message: e.message});
    }
}


export function* getTopicDataPickerForNLP(data) {
    try {
        const response = yield call(topicService.getDataPickerContentForNLP, data.payload);
        yield put({type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not get data for nlp " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.FAILURE, message: e.message});
    }
}


export function* getTopicDataPickerForSentenceSuggestion(data) {
    try {
        const response = yield call(topicService.getDataPickerContentForSentenceSuggestion, data.payload);
        yield put({type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not get data for sentence suggestion " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.FAILURE, message: e.message});
    }
}


export function *getTopicDataPickerForNLPDistractors(data) {
    try {
        const response = yield call(topicService.getDataPickerContentForNLPDistractors, data.payload);
        yield put({type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
            message: "Could not get data for distractor selection " + usefulInformation,
            options: {
                variant: "warning"
            }
        }));
        yield put({type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.FAILURE, message: e.message});
    }
}

//Listeners
export function* topicSagas() {
    yield takeLatest(topicConstants.ADD_FILE.REQUEST, addFile);
    yield takeLatest(topicConstants.DELETE_FILE.REQUEST, deleteFile);
    yield takeLatest(topicConstants.ADD_URL.REQUEST, addUrl);
    yield takeLatest(topicConstants.GET_FILES.REQUEST, getFiles);
    yield takeLatest(topicConstants.GET_TOPIC_DOCUMENTS.REQUEST, getTopicDocuments);
    yield takeLatest(topicConstants.DELETE_TOPIC_DOCUMENT.REQUEST, deleteTopicDocument)
    yield takeLatest(topicConstants.GET_DOCUMENT_WORDS.REQUEST, getDocumentWords);
    yield takeLatest(topicConstants.UPDATE_TOPIC.REQUEST, updateTopic);
    yield takeLatest(topicConstants.UPDATE_TOPIC_LANGUAGE_STATUS.REQUEST, updateTopicLanguageStatus);
    yield takeLatest(topicConstants.CREATE_TOPIC.REQUEST, createTopic);
    yield takeLatest(topicConstants.PUBLISH_TOPIC.REQUEST, publishTopic);
    yield takeLatest(topicConstants.GET_ALL_TOPICS.REQUEST, getAllTopics);
    yield takeLatest(topicConstants.GET_TOPIC_WORDS.REQUEST, getTopicWords);
    yield takeLatest(topicConstants.GET_TOPIC_WORDS_L1.REQUEST, getTopicWordsForL1);
    yield takeLatest(topicConstants.GET_IGNORED_WORDS.REQUEST, getIgnoredWords);
    yield takeLatest(topicConstants.DELETE_TOPIC.REQUEST, deleteTopic);
    yield takeLatest(topicConstants.RESTORE_TOPIC_WORD.REQUEST, restoreTopicWord);
    yield takeLatest(topicConstants.DELETE_TOPIC_WORDS.REQUEST, deleteTopicWords);
    yield takeLatest(topicConstants.UPDATE_TOPIC_WORDS.REQUEST, updateTopicWords);
    yield takeLatest(topicConstants.DELETE_TOPIC_SNIPPET.REQUEST, deleteTopicSnippet);
    yield takeLatest(topicConstants.DELETE_TOPIC_WORD_DISTRACTOR.REQUEST, deleteTopicWordDistractor);
    yield takeLatest(topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.REQUEST, getAllUserGroupsForTopics);
    yield takeLatest(topicConstants.GET_TOPIC_GENERATE_STATUS.REQUEST, getTopicGenerateStatus);
    yield takeLatest(topicConstants.GET_CLUSTERS.REQUEST, getClusters);
    yield takeLatest(topicConstants.FORCE_REGENERATION.REQUEST, forceRegeneration);
    yield takeLatest(topicConstants.APPROVE_SNIPPET.REQUEST, approveSnippet);
    yield takeLatest(topicConstants.DISAPPROVE_SNIPPET.REQUEST, disapproveSnippet);
    yield takeLatest(topicConstants.APPROVE_WORD.REQUEST, approveWord);
    yield takeLatest(topicConstants.DISAPPROVE_WORD.REQUEST, disapproveWord);
    yield takeLatest(topicConstants.CHANGE_TO_ALT_TRANSLATION.REQUEST, changeToAltTranslation);
    yield takeLatest(topicConstants.ADD_SUPPORTED_LANGUAGE.REQUEST, addSupportedLanguage);
    yield takeLatest(topicConstants.CLONE_COURSE.REQUEST, cloneCourse);
    yield takeLatest(topicConstants.GET_TOPIC.REQUEST, getTopic);
    yield takeLatest(topicConstants.CHANGE_TRANSLATION.REQUEST, changeTranslation);
    yield takeLatest(topicConstants.CHANGE_SNIPPET_L1.REQUEST, changeSnippetL1);
    yield takeLatest(topicConstants.CHANGE_SNIPPET_L2.REQUEST, changeSnippetL2);
    yield takeLatest(topicConstants.CREATE_SNIPPET_L2.REQUEST, createSnippetL2);
    yield takeLatest(topicConstants.CHANGE_SNIPPET_QUESTION_L1.REQUEST, changeSnippetQuestionL1);
    yield takeLatest(topicConstants.GET_SHARABLE_COURSES.REQUEST, getSharableCourses);
    yield takeLatest(topicConstants.GET_TOPIC_WORDS_ENGLISH.REQUEST, getTopicWordsForEnglish);
    yield takeLatest(topicConstants.GET_CURRENT_TOPIC.REQUEST, getCurrentTopic);
    yield takeLatest(topicConstants.UPDATE_LOCAL_COURSE_NAME.REQUEST, updateLocalCourseName); 
    yield takeLatest(topicConstants.GET_REQUESTED_L1S.REQUEST, getRequestedL1s); 
    yield takeLatest(topicConstants.EXPORT_COURSE.REQUEST, exportCourse); 
    yield takeLatest(topicConstants.IMPORT_COURSE.REQUEST, importCourse); 
    yield takeLatest(topicConstants.DELETE_TOPIC_WORD.REQUEST, deleteTopicWord); 
    yield takeLatest(topicConstants.CHANGE_DESCRIPTION.REQUEST, changeDescription); 
    yield takeLatest(topicConstants.GET_TOPIC_DIFFICULTIES.REQUEST, getTopicDifficulties); 
    yield takeLatest(topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.REQUEST, getLessonOrderForDifficulty); 
    yield takeLatest(topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.REQUEST, updateLessonOrderForDifficulty); 
    yield takeLatest(topicConstants.UPDATE_LOCAL_LESSON_ORDER.REQUEST, updateLocalLessonOrder); 
    yield takeLatest(topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.REQUEST, exportExcelTopicTranslation);
    yield takeLatest(topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.REQUEST, exportExcelTopicTranslationForAllLanguages);
    yield takeLatest(topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.REQUEST, importExcelTopicTranslation);
    yield takeLatest(topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.REQUEST, exportSentenceSuggestionsFromFile);
    yield takeLatest(topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.REQUEST, getTopicDataPickerForLessonData);
    yield takeLatest(topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.REQUEST, getTopicDataPickerForSentenceSuggestion);
    yield takeLatest(topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.REQUEST, getTopicDataPickerForNLP);
    yield takeLatest(topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.REQUEST, getTopicDataPickerForNLPDistractors);
    yield takeLatest(topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.REQUEST, attachCustomLessonToTopic);
    yield takeLatest(topicConstants.GET_LESSON_ORDER_FOR_INDEXING.REQUEST, fetchLessonOrderForIndexing);
 }