import React, {useState} from "react";
import {
    Card,
    Button,
    Grid,
    CardContent,
    Typography,
    List,
    ListItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeGetRequest} from "../../../../_state/helpers/api";
import {configWithAuth} from "../../../../_state/helpers";
import {convertDataURIToBinary} from "../../../../_state/helpers/base64Logic";
import {FileReferenceImageUsageType} from "../../../../Logic/FileReferenceConstants";
import {ExtractMimeType} from "../../../../Logic/Base64Helpers";
import {v4 as uuidv4} from "uuid";


const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: '5px',
    marginBottom: '5px',
  },
  scenario: {
    paddingTop: '30px'
  },
  textFields: {
    marginBottom: '5px'
  },
  divList: {
    maxHeight: '405px',
    overflowY: 'scroll',
  }
}));


const ImageLibrary = (props) => {
    const classes = useStyles();
    const [images, setImages] = useState([])
    const [keywords, setKeywords] = useState("");
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState("")
    const [blobUrlLocal, setBlobUrlLocal] = useState(null)

    const handleImageItemSelected = (image, index) => {
      setOpen(false)
    }
    
    const getImagePostfixTextFromFileReference = (image) => {
        switch (image.fileReferenceImageUsageType) {
          case FileReferenceImageUsageType.BriefStageBackground:
            return "(Brief/Debrief)"
          case FileReferenceImageUsageType.SpeakStageBackground:
            return "(Branching)"
          case FileReferenceImageUsageType.ListenStageBackground:
            return "(Listen, Multichoice)"
          case FileReferenceImageUsageType.TestImage:
            return "(Test, Definition)"
          case FileReferenceImageUsageType.WatchStageBackground:
            return "(Watch)"
          default:
            return "(Not defined)"
        }
    }
    
    
    const handleImageItemClick = (image, index) => {
      const {organization} = props;
      //setReadyToLoad(false)
       // FIXME set a spinner on the images graphic for each node graphic
         return new Promise((resolve, reject) => {
           makeGetRequest('admin/organization/'+organization.selectedOrganization + '/image/file/reference/' + image.id+ '/base64', configWithAuth())
           .then(result => {
               let type = null
               const mimeTypeImage = ExtractMimeType(result.data);
               var binary= convertDataURIToBinary(result.data);
               var uniqueFileName = uuidv4();
               var fileLocal =new File([binary], uniqueFileName, {type : mimeTypeImage});
               var fileUrlLocal = window.URL.createObjectURL(fileLocal);
               setBlobUrlLocal(fileUrlLocal)
               resolve({files: fileLocal , fileUrl: fileUrlLocal, imageReferenceId: image.id});
               props.updateImageDrop(
                 image.apiUrl,
                 props.node.nodeId,
                 image.fileReferenceImageUsageType, 
                 {
                   type: mimeTypeImage, 
                   image: fileLocal,
                   imageUrl: fileUrlLocal
                 }
                )
               })
           .catch(error => {
               console.log("error",error);
               return reject(error);
           })
       });
    };


    return (
      <div>
        <Dialog
          open={open}
          onClose={(event) => { setOpen(false)}}          
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ paddingLeft: '100px', paddingRight: '100px', cursor: 'pointer' }}
          maxWidth="lg"
          fullWidth
         >
            <DialogTitle id="alert-dialog-title">{"Select an image"}</DialogTitle>
            <DialogContent>
              <Card>    
                <CardContent className={classes.card} >
                  <Grid container>
                    <Grid item xs={6} sm={6} lg={6} className={classes.gridItem}>
                      <div className={classes.divList}>
                        <List component="nav">
                          {
                            props.lesson && props.lesson.imageReferences && props.lesson.imageReferences.length > 0 && props.lesson.imageReferences.filter(x => props.scenarioImages?.find(y => x.id === y.imageReferenceId)).filter(x => props.scenarioImages.find(y => x.id === y.imageReferenceId)).map((image, index) => (
                              <ListItem
                                key={index}
                                button
                                // selected={this.state.imageReferenceId === image.id}
                                onClick={(event) => handleImageItemClick(image, 0)}                        >
                                <Typography variant='body1' className={classes.nameyourimage}>
                                    {image.description ? image.description : image.niceName} {getImagePostfixTextFromFileReference(image)}
                                </Typography>  
                              </ListItem>
                              ))
                          }
                        </List>
           
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6} className={classes.gridItem}>
                      <img
                        component="img" 
                        style={{maxWidth: '300px',  display: 'flex',
                        justify: "flex-start",
                        alignItems: 'center',
                        textAlign: 'center'}}
                        src={blobUrlLocal}
                        onClick={(event) => handleImageItemSelected()} 
                      />
                    </Grid>
                  </Grid> 
                </CardContent>
            </Card>
            </DialogContent>
            <DialogActions>
              <Button disabled={blobUrlLocal === null} onClick={(event) => { setOpen(false)}} color="primary" autoFocus>
                Use Image
              </Button>
              <Button onClick={(event) => { setOpen(false)}} color="primary" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <Button size="small" variant='outlined' 
              onClick={event => setOpen(true)}>
                  Lesson Library
              </Button>
            </Grid>
        </Grid>
      </div>
    );
};

ImageLibrary.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(ImageLibrary)