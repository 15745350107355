import { configWithAuth } from '../helpers';
import {makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest, makePatchRequest} from "../helpers/api";

export const topicService = {
    addFile: (payload) => makePostRequest('admin/document/organization/' + payload.organizationId + '/topic/' + payload.topicId + '/file', payload.formData, configWithAuth('multipart/form-data')),
    deleteFile: (payload) => makeDeleteRequest('admin/topic/' + payload.topicId + '/document/' + payload.documentId, null, configWithAuth()),
    addUrl: (payload) => makePostRequest('admin/document/organization/url', JSON.stringify(payload), configWithAuth()),
    getFiles: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/documents', configWithAuth()),
    getTopicDocuments: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/documents/v2', configWithAuth()),
    deleteTopicDocument: (payload) => makeDeleteRequest('admin/topic/' + payload.topicId + '/documents/v2/' + payload.topicDocumentId, null, configWithAuth()),
    updateTopic: (payload) => makePutRequest('admin/topic', JSON.stringify(payload), configWithAuth()),
    updateTopicLanguageStatus: (payload) => makePutRequest('admin/topic', JSON.stringify(payload), configWithAuth()),
    createTopic: (payload) => makePostRequest('admin/topic', JSON.stringify(payload), configWithAuth()),
    publishTopic: (payload) => makePostRequest('admin/topic/' + payload.topicId + '/publish/' + payload.l1Id, JSON.stringify(payload), configWithAuth()),
    getAllTopics: (organizationId) => makeGetRequest('admin/topic/organization/' + organizationId, configWithAuth()),
    getTopicWords: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/words', configWithAuth()),
    getTopicWordsForL1: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/words/' + payload.l1Id + '/review', configWithAuth()),
    getTopicWordsForEnglish: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/words/1/review', configWithAuth()),
    getIgnoredWords: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/ignoredwords/importance', configWithAuth()),
    deleteTopic: (payload) => makeDeleteRequest('admin/topic/' + payload.topicId, null, configWithAuth()),
    deleteTopicWord: (payload) => makeDeleteRequest('admin/topic/' + payload.topicId + '/word/' + payload.wordId, null, configWithAuth()),
    restoreTopicWord: (payload) => makePostRequest('admin/topic/' + payload.topicId + '/word/' + payload.wordId +'/removeIgnore', null, configWithAuth()),
    deleteTopicWords: (payload) => makePutRequest('admin/topic/' + payload.topicId + '/words', JSON.stringify(payload), configWithAuth()),
    updateTopicWords: (payload) => makePutRequest('admin/topic/' + payload.topicId + '/words', JSON.stringify(payload), configWithAuth()),
    deleteTopicSnippet: (payload) => makeDeleteRequest('admin/topic/' + payload.topicId +'/snippet/' + payload.ignoreSnippetIds, null, configWithAuth()),
    getDocumentWords: (payload) => makeGetRequest('documents/getAllWords/' + payload.documentId, configWithAuth()),
    deleteTopicWordDistractor: (payload) => makeDeleteRequest('admin/topic/' + payload.topicId + '/distractor/' + payload.distractorId, null, configWithAuth()),
    getAllUserGroupsForTopics: (payload) => makeGetRequest('admin/topic/' + payload.topicId+'/groups', configWithAuth()),
    getClusters: (payload) => makeGetRequest('admin/topic/'+payload.topicId+'/clusters', configWithAuth()),
    forceRegeneration: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/translations/l1Id/' + payload.l1Id + '/disambiguate/force', configWithAuth()),
    approveSnippet: (payload) => makePutRequest('admin/topic/' + payload.topicId + '/approve/approval/disambiguatedSnippet/' + payload.disambiguatedSnippetId, JSON.stringify(payload), configWithAuth()),
    disapproveSnippet: (payload) => makeDeleteRequest('admin/topic/' + payload.topicId + '/approve/approval/disambiguatedSnippet/' + payload.disambiguatedSnippetId, JSON.stringify(payload), configWithAuth()),
    approveWord: (payload) => makePutRequest('admin/topic/' + payload.topicId + '/approve/', JSON.stringify(payload), configWithAuth()),
    disapproveWord: (payload) => makeDeleteRequest('admin/topic/' + payload.topicId + '/approve/' + payload.disambiguatedWordId, JSON.stringify(payload), configWithAuth()),
    changeToAltTranslation: (payload) => makePutRequest('admin/topic/' + payload.topicId + '/translate/disambiguate/alternative/' + payload.alternativeDisambiguationId, JSON.stringify(payload), configWithAuth()),
    addSupportedLanguage: (payload) => makePostRequest('admin/topic/' + payload.topicId + '/supportedLanguage/' + payload.l1Id, JSON.stringify(payload), configWithAuth()),
    cloneCourse: (payload) => makePostRequest('admin/topic/' + payload.topicId + '/duplicate/' , JSON.stringify(payload), configWithAuth()),
    getTopic: (payload) => makeGetRequest('admin/topic/' + payload.topicId , configWithAuth()),
    changeTranslation: (payload) => makePutRequest('admin/topic/'+ payload.topicId + '/translate', JSON.stringify(payload), configWithAuth()),
    changeSnippetL1: (payload) => makePutRequest('admin/topic/'+ payload.topicId + '/translate/snippet/l1', JSON.stringify(payload), configWithAuth()),
    changeSnippetL2: (payload) => makePutRequest('admin/topic/'+ payload.topicId + '/snippet', JSON.stringify(payload), configWithAuth()),
    createSnippetL2: (payload) => makePostRequest('admin/topic/'+ payload.topicId + '/snippet', JSON.stringify(payload), configWithAuth()),
    changeSnippetQuestionL1: (payload) => makePostRequest('admin/topic/'+ payload.topicId + '/snippet/question', JSON.stringify(payload), configWithAuth()),
    getSharableCourses: (payload) => makeGetRequest('admin/sharable/topic' , configWithAuth()),
    getCurrentTopic: (payload) => makeGetRequest('admin/topic/' + payload.topicId , configWithAuth()),
    getRequestedL1s: (payload) => makeGetRequest('admin/topic/' + payload.topicId +'/language/requested', configWithAuth()),
    exportCourse: (payload) => makePostRequest('admin/export/topic/' + payload.topicId + '/translation/csv' , JSON.stringify(payload), configWithAuth()),
    importCourse: (payload) => makePostRequest('admin/import/topic/' + payload.topicId + '/language/' + payload.l1Id + '/translation/csv' , payload.formData, configWithAuth('multipart/form-data')),
    changeDescription: (payload) => makePutRequest('admin/topic/'+ payload.topicId + '/word/information', JSON.stringify(payload), configWithAuth()),
    getTopicDifficulties: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/lesson/order/difficulty/supported' , configWithAuth()),
    getLessonOrderForDifficulty: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/lesson/order/difficulty/' + payload.topicLessonDifficultyId , configWithAuth()),
    updateLessonOrderForDifficulty: (payload) => makePostRequest('admin/topic/' + payload.topicId + '/lesson/order/difficulty/' + payload.topicLessonDifficultyId  , JSON.stringify(payload), configWithAuth()),
    attachCustomLessonToTopic: (payload) => makePostRequest('admin/topic/' + payload.topicId + '/lesson/order/difficulty/' + payload.topicLessonDifficultyId + '/custom/lesson/' + payload.customLessonId, JSON.stringify(payload), configWithAuth()),
    
    importExcelTopicTranslation: (payload) => makePostRequest('admin/topic/' + payload.topicId + '/language/' + payload.l1Id + '/translation/excel/import', payload.formData, configWithAuth('multipart/form-data')),
    importExcelTopicTranslationV2: (payload) => makePostRequest('admin/topic/' + payload.topicId + '/language/' + payload.l1Id + '/translation/excel/import/v2', payload.formData, configWithAuth('multipart/form-data')),
    
    importCsvTopicMachineTranslationV1: (payload) => makePostRequest('admin/topic/' + payload.topicId + '/language/' + payload.l1Id + '/translation/csv/import/machine/translation/v1', payload.formData, configWithAuth('multipart/form-data')),
    
    exportExcelTopicTranslation: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/language/' + payload.l1Id + '/translation/excel/export' + payload.queryParams, configWithAuth()),
    exportExcelTopicTranslationV2: (payload) => makeGetRequest('admin/topic/' + payload.topicId + '/language/' + payload.l1Id + '/translation/excel/export/V2' + payload.queryParams, configWithAuth()),
    
    exportSentenceSuggestionsFromFile: (payload) => makePostRequest('admin/content/nlp/file/sentence/selector/' + payload.l2Id + "?useBase64=true", payload.formData, configWithAuth('multipart/form-data')),
    
    
    
    // data picking for lesson builder type endpoints
    getDataPickerContentForLessonData: (payload) => makeGetRequest('admin/topic/'  + payload.topicId +'/data/picker/lesson/data' , configWithAuth()),
    getDataPickerContentForNLP: (payload) => makeGetRequest('admin/topic/'  + payload.topicId +'/data/picker/nlp/data' , configWithAuth()),
    getDataPickerContentForSentenceSuggestion: (payload) => makeGetRequest('admin/topic/'  + payload.topicId +'/data/picker/sentence/suggestion/data' , configWithAuth()),
    getDataPickerContentForNLPDistractors: (payload) => makeGetRequest('admin/topic/'  + payload.topicId +'/data/picker/nlp/distractors' , configWithAuth()),

};