import React, {useEffect, useState} from "react";
import {
  Typography,
  makeStyles, MenuItem, Button,
} from '@material-ui/core';
import {connect} from 'react-redux';
import Card from "@material-ui/core/Card";
import {Add, Delete} from "@material-ui/icons";

import {
  getFlagAssetForLanguageId
} from "../../../../Logic/FlagUnicodeLogic";
import Grid from "@material-ui/core/Grid";
import {makeGetRequest} from "../../../../_state/helpers/api";
import {configWithAuth} from "../../../../_state/helpers";
import {convertDataURIToBinary} from "../../../../_state/helpers/base64Logic";

import Checkbox from "@material-ui/core/Checkbox";
import {FormattedMessage} from "react-intl";

import DefaultCourseGroupImage from "../../../../assets/images/courseGroup/FallbackCourseGroupImage.jpeg"
import Zoom from "@mui/material/Zoom";

const useStyles = makeStyles(theme => ({
  cardContent: {
    flex: 'auto',
  },
  verticalContainer: {
    flex:1,
  },
  courseGroupCard: {
    display: 'flex',
    paddingTop: '20px',
    paddingBottom:'20px',
    marginBottom:'60px',
    // padding: '20px',
    transition: 'all .25s ease',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
    },
    height: "327px",
    width: '233px',
    marginTop: '-3px',
    marginLeft: '-15px',
    border: '5px dashed #44A6EF',
    // borderRadius: '15px',
  },
  courseGroupCardAction: {
    right: '15px',
    height: 'fit-content',
    margin: 'auto',
  },
  button: {
    backgroundColor: 'primary',
    fontColor: 'white',
    color: 'white',
    borderRadius: 4,
    marginRight: '10px',
    textTransform: 'none'
  },
  
  imageContainer: {
    marginTop: '-20px',
    position:'relative',
    overflow: 'hidden',
    height: '120px',
    backgroundColor: '44A6EF',
    background: '#44A6EF'
  },
  
  
}));

const CreateNewCourseGroupCard = (props) => {
  const {theme, courseGroup, index, slideDirection, handleRemoveCourseGroup, user, organization, metadata} = props;
  const classes = useStyles();


  const handleCardClick = () => {
    props.onCreateNewCourseGroup()
  }


  return (
    <div>
      <Card className={classes.courseGroupCard} onClick={() => handleCardClick()}>
        <div className={classes.cardContent}>
          <Grid container
                style={{
                  flex:1,
                  flexDirection:"row"
                }}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.imageContainer} style={{alignItems: 'center', textAlign: 'center', justifyContent: 'center', height: '120px'}}>
                <Grid container alignItems='center' justifyContent='center'>
                  <Grid item xs={12} style={{height: '120px'}}>
                    <Add style={{ fontSize: '40px', color: 'white', alignItems: 'center', textAlign: 'center', justifyContent: 'center', height: '120px' }} />
                </Grid>
                </Grid>

              
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{

            }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>

                      <Typography style={{fontSize: '20px', lineHeight: '18px',  fontWeight:700, fontFamily: 'Lato', marginTop: '15px', marginBottom: '5px', color: 'black', marginLeft: '12px', marginRight: '12px'}}>
                        Create New
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*  <Typography style={{*/}
                {/*    fontSize:'15px',*/}
                {/*    // fontWeight: 'bold'*/}
                {/*  }}>*/}
                {/*    <FormattedMessage id="dashboard.classroom.coursegroup.card.teaches" defaultMessage="Teaches"/> {metadata.metadata?.languages?.find(x => x.id === courseGroup?.l2Id)?.fullName}*/}
                {/*  </Typography>*/}
                {/*</Grid>*/}

   
                  <Grid item xs={12} style={{marginTop: '3px'}}>
                    <Typography variant={"body1"} style={{fontFamily: 'Lato', fontSize: '16px', color: 'black', marginLeft: '13px', marginRight: '15px'}} color={"black"}>
                      Create a new course
                    </Typography>
                  </Grid>

              </Grid>
            </Grid>
          </Grid>
        </div>

      </Card>
    </div>
  );
};

CreateNewCourseGroupCard.propTypes = {};

const mapStateToProps = (state) => {
  const {user, organization, metadata} = state;
  return {
    metadata,
    user,
    organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCourseGroupCard)