import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import { Grid, TextField, makeStyles} from "@material-ui/core";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {
  AutoCourseCreatorLanguagePos
} from "../../../../Logic/AutoCourseCreationConstants";
import Zoom from '@mui/material/Zoom';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles((theme) => ({
}))

const AutoLanguageCourseSuggestionBox = (props) => {
  const [showNewVocabTextField, setShowNewVocabTextField] = useState(false);
  const [newVocabText, setNewVocabText] = useState("");
  const formatDuration = (minutes) => {
    //Formatting for Matt's crude temporary lesson length estimator :)
    if (minutes < 60) {
      return `${Math.floor(minutes)} minutes`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} hour${hours > 1 ? 's' : ''}${Math.floor(remainingMinutes) > 0 ? ` and ${Math.floor(remainingMinutes)} minute${Math.floor(remainingMinutes) > 1 ? 's' : ''}` : ''}`;
    }
  }

  const handleToggleVocabularyItem = (vocabularyItem) => {
    props.onToggleVocabularyItem(vocabularyItem)
  };

  const handleAddNewVocabularyItem = () => {
    setShowNewVocabTextField(true)
  };

  const handleChangeNewVocabText = (text) => {
    setNewVocabText(text)
  };

  const handleFinishedTyping = () => {
    setShowNewVocabTextField(false)
    if(newVocabText !== "" && newVocabText !== " ") {
      props.onAddVocabularyItem(newVocabText)
    }
    setNewVocabText("")
  };

  const handleChangeTab = (e, value) => {
    props.onSetPosSelection(value)
  };
  const StyledChip = withStyles({
    label: {
      color: "var(--Dark-text, #35323C)", fontFamily: "Rubik", fontSize:14, fontStyle: "normal", fontWeight:400, lineHeight: "140%",},
  })(Chip);

  const StyledChipAdd = withStyles({
    label: {
      color: "white",
      fontFamily: "Rubik",
      fontSize:14,
      fontStyle: "normal", 
      fontWeight:400,
      lineHeight: "140%"
    },
  })(Chip);

  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} style={{marginBottom: '5px'}}>
        <Box style={{
              display: 'flex', 
              justifyContent: 'center', 
              color: "var(--Dark-text, #35323C)",
              fontFamily: "Rubik",
              fontSize:20,
              fontStyle: "normal",
              fontWeight:400,
              lineHeight: "118%",
            }}>
            Words and phrases
            </Box>
            </Grid>
            <Grid item xs={12} >
            <Box style={{ 
              color: "rgba(53, 50, 60, 0.80)",
              textAlign: "center",
              fontFamily: "Rubik",
              fontSize:14,
              fontStyle: "normal",
              fontWeight:400,
              lineHeight: "140%",
            }}>
              {props.categoryVocabularyItems.filter(item => item.usedInLesson).length} items selected (est. {formatDuration(props.categoryVocabularyItems.filter(item => item.usedInLesson).length * 1.8)} learning time)
            </Box>
          </Grid>
      {props.lessonContentViewMode === "pos" &&
            <Grid item xs={12} style={{marginTop: '10px', marginBottom: '10px'}}>
              <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", gap:8, alignSelf: "stretch",}}>
              <Zoom in={true}>
                <Tabs
                  value={props.posSelection}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  style={{
                  }}
                  centered
                  >  
                    <Tab 
                      value={AutoCourseCreatorLanguagePos.All}
                      label={"All"} /> 
                    <Tab 
                      value={AutoCourseCreatorLanguagePos.Nouns}
                      label={"Nouns"} 
                      />
                    <Tab 
                      value={AutoCourseCreatorLanguagePos.Verbs}
                      label={"Verbs"} />
                    <Tab 
                      value={AutoCourseCreatorLanguagePos.Adjectives}
                      label={"Adjectives"} 
                      />
                    <Tab 
                      value={AutoCourseCreatorLanguagePos.Sentence}
                      label={"Sentences"} 
                      />
                </Tabs>
              </Zoom>
             </div>
          </Grid>
      }
      
      <Grid item xs={12}>
        <div style={{ 
            width: '100%', 
            padding: '5px', 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'center', 
            flexWrap: 'wrap'
        }}>
          

          {props.lessonContentViewMode === "all" && props.categoryVocabularyItems.map((vocabularyItem, index) => (
          <StyledChip label={vocabularyItem.text} index={index} variant="outlined" style={{ justifyContent: "center", alignItems: "center", color: 'black', marginRight: '3px', marginBottom: '5px', padding: '3px',  borderRadius: 16, border: vocabularyItem.usedInLesson  ? "none" : "2px solid rgba(160, 67, 159, 0.12)", background: vocabularyItem.usedInLesson ?  "rgba(204, 133, 198, 0.50)" : "var(--White, #FFF)",margin: '4px'}}
              onClick={(event) => handleToggleVocabularyItem(vocabularyItem)}
              >
          </StyledChip>
          ))}
          {
  props.lessonContentViewMode === "pos" && props.categoryVocabularyItems
    .map((vocabularyItem, index) => (
      <StyledChip
        label={<span style={{
          display: "flex",
          height:32,
          justifyContent: "center",
          alignItems: "center",
          }}> 
          {vocabularyItem.usedInLesson && <Box component="span" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1 /* gap between icon and the vocab item*/, fontSize: '0.92rem'  }}>
        <DoneIcon sx={{ fontSize: 'inherit'  }} />
      </Box> } {vocabularyItem.text} </span>}
        index={index}
        variant="outlined"
        style={{
          justifyContent: "center",
          alignItems: "center",
          opacity: vocabularyItem.partOfSpeech === props.posSelection || props.posSelection === "All" ? 1: 0.4,
          filter: vocabularyItem.partOfSpeech === props.posSelection || props.posSelection === "All" ? null : "blur(1.5px)",
          color: "black",
          marginRight: "3px",
          marginBottom: "5px",
          padding: "3px",
          borderRadius: 16,
          border: vocabularyItem.usedInLesson || !!!(vocabularyItem.partOfSpeech === props.posSelection || props.posSelection === "All" )
            ? "none"
            : "1px solid var(--Purple, #A0439F)",
          background: 
          vocabularyItem.partOfSpeech !== props.posSelection && props.posSelection !== "All" ? "var(--Grey-5, #EFEFF4)" :
          vocabularyItem.usedInLesson
            ? "#F3E2F3"
            : "var(--White, #FFF)",
          margin: "4px",
        }}
        onClick={(event) => handleToggleVocabularyItem(vocabularyItem)}
      >
      </StyledChip>
    ))
    }
        </div>
      </Grid>
      <Grid item xs={12}>
        <div style={{ 
            width: '100%', 
            padding: '5px', 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'center', 
            flexWrap: 'wrap'
        }}>    

          { showNewVocabTextField === true ?
            <TextField
            onChange={(e) => handleChangeNewVocabText(e.target.value)}
            onBlur={handleFinishedTyping} // the function to call when TextField loses focus
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                handleFinishedTyping(ev) // the function to call when Enter is pressed
                ev.preventDefault();
              }
            }}
            value={newVocabText}
            autoFocus
            placeholder='Write a word or phrase'
            InputProps={{
              disableUnderline: true,
              style: {
                height: '20px',
                paddingLeft: '20px',
                fontSize:14
              }
            }}
            style={{
              justifyContent: "center", 
              alignItems: "center", 
              color: 'black', 
              marginRight: '3px', 
              marginBottom: '5px', 
              padding: '3px',  
              borderRadius:16, 
              border: "2px solid var(--Darker30, rgba(96, 96, 96, 0.30))", 
              background: "var(--White, #FFF)",
            }} 
            > 
          </TextField>
          : 
          <StyledChipAdd 
            label="+ Add another item" 
            variant="outlined" 
            style={{ 
              justifyContent: "center", 
              alignItems: "center", 
              marginRight: '3px', 
              marginBottom: '5px', 
              padding: '3px',  
              borderRadius: 16,
              background: "var(--Dark-text, #35323C)",
            }}
            onClick={(event) => handleAddNewVocabularyItem()}
          />
          }
        </div>
      </Grid>
    </Grid>
  )
}

AutoLanguageCourseSuggestionBox.propTypes = {
  
};


function mapStateToProps(state) {
  const {autoCourseCreation} = state;
  return {
    autoCourseCreation
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoLanguageCourseSuggestionBox)