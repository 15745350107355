import {authConstants, organizationConstants, snackbarConstants} from '../actions';

const initialState = {
    notifications: [],
};

export function snackbar(state = initialState, action) {
    switch (action.type) {
        case snackbarConstants.ENQUEUE_SNACKBAR.REQUEST:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...action.notification,
                    },
                ],
            };

        case snackbarConstants.REMOVE_SNACKBAR.REQUEST:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };

        case snackbarConstants.CLEAR_SNACKBAR.REQUEST:
            return initialState;

        case authConstants.LOGOUT.SUCCESS:
            return initialState;
        default:
            return state
    }
}