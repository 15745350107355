import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles/";
import {history} from "_state/helpers";
import {
    Paper,
    Grid,
    Typography,
} from '@material-ui/core'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

const useStyles = makeStyles(theme => ({
    gridContainer: {
        // height: '100vh',
         padding: 30,
     },
     formPaper: {
         padding: '25px 50px',
         
         boxShadow: 'none',
     },
     rightIcon: {
         marginLeft: theme.spacing(1),
     },
     margin: {
         margin: theme.spacing(1),
     },
     iconSmall: {
         fontSize: 20,
     },
     passwordHelp: {
         fontSize: 12,
     },
     headerText: {
         padding: '10px 0px 10px 0px', 
     },
     loginButton: {
         display: 'flex',
         margin: 'auto',
         marginTop: 25,
         height: 50,
         background: '#E2505A',
         '&:hover': {
             background: '#2CB06E',
         }
     },
     signInButton: {
         display: 'flex',
         margin: 'auto',
         marginTop: 25,
         height: 50,
 
     },
     emailText: {
         marginTop: 25,
     },
     inputField: {
         //marginTop: 25,
     },
     capeeshLogo: {
         display: 'inherit',
         margin: '20px auto',
     },
     listview: {
         width: '600px',
     }
}));



const ResearchAndDevelopment = (props) => {
    const classes = useStyles();

    const handleOpenImageMatcher = () => {
        history.push("/research-and-development/image-matcher");
    };

        return (
            <div>
                <Grid container 
                      alignItems="center" 
                      justify="center"
                      className={classes.gridContainer}
                >
                    <Grid item xs={12} sm={8} md={6}>
                        <Paper className={classes.formPaper}>
                            <Typography variant="h5"align="center" className={classes.headerText}>
                                Capeesh Rapid Research and Development Lab 
                            </Typography>
                            <Grid item xs={12} sm={5}>
                                <List component="nav" aria-label="main mailbox folders" className={classes.listview}>
                                    <ListItem button onClick={handleOpenImageMatcher}>
                                        <ListItemText primary="Image matching services" />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
}


ResearchAndDevelopment.propTypes = {};

const mapToProps = (state) => {
    return {
        auth: state.auth, 
        user: state.user, 
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
   }
};

export default connect(mapToProps, mapDispatchToProps)(ResearchAndDevelopment)