import {authConstants, organizationConstants, topicConstants} from '../actions';

const initialState = {
    loadingOrganizationInfo: false,
    myOrganizations: [],
    loadingOrganizations: false,
    selectedOrganization: '',
    organizationUsers: [],
    availableFileReferences: [],
    availableCourseGroups: [],
    organizationCourseGroups: [],
    isFetchingOrganizationUsers: false,
    organizationFactBoxStats: null,
    organizationIdToFactBoxStats: {},
    organizationCourseGroupUsage: null,
    isExportingOrgExcelReport: false,
};

export function organization(state = initialState, action) {
    switch (action.type) {
        case organizationConstants.GET_ORGANIZATION_INFO.REQUEST:
            return {
                ...state,
                loadingOrganizationInfo: true,
            };
        case organizationConstants.GET_ORGANIZATION_INFO.SUCCESS:
            return {
                ...state,
                loadingOrganizationInfo: false, 
                organizationInfo: action.data
            };
        case organizationConstants.GET_ORGANIZATION_INFO.FAILURE:
            return {
                ...state,
                loadingOrganizationInfo: false,
                error: action.message
            };
            
        case organizationConstants.SET_ORGANIZATION_INFO.REQUEST:
            const updatedOrganizations = state.myOrganizations.map(org => {
                if(org.id === action.payload.id){
                    return { ...org, ...action.payload }
                }
                return org
            });
            return {
                ...state,
                myOrganizations: updatedOrganizations
            };
        case organizationConstants.SET_ORGANIZATION_INFO.SUCCESS:
            return {
                ...state,
            };
        case organizationConstants.SET_ORGANIZATION_INFO.FAILURE:
            return {
                ...state,
                error: action.message
            };

        case organizationConstants.GET_MY_ORGANIZATIONS.REQUEST:
            return {
                ...state,
                loadingOrganizations: true
            };
        case organizationConstants.GET_MY_ORGANIZATIONS.SUCCESS:
            return {
                ...state,
                myOrganizations: action.data,
                loadingOrganizations: false,
                selectedOrganization: state.selectedOrganization ? state.selectedOrganization : action.data[0].id
            };
        case organizationConstants.GET_MY_ORGANIZATIONS.FAILURE:
            return {
                ...state,
                loadingOrganizations: false,
                error: action.message
            };

        case organizationConstants.SET_CURRENT_ORGANIZATION.REQUEST:
            return {
                organizationUsers: [],
                usergroups: [],
                userGroupUsers: [],
                availableFileReferences: [],
                inviteRolesAvailable: [],
                organizationFileAssets: [],
                availableCourseGroups: [],
                organizationCourseGroups: [],
                ...state,
            };
        case organizationConstants.SET_CURRENT_ORGANIZATION.SUCCESS:
            return {
                ...state,
                selectedOrganization: action.data,
            };
        case organizationConstants.SET_CURRENT_ORGANIZATION.FAILURE:
            return {
                ...state,
                error: action.message
            };
            
        case organizationConstants.GET_SCHOOL.REQUEST:
            return {
                ...state,
                loadingSchool: true,
            };
        case organizationConstants.GET_SCHOOL.SUCCESS:
            return {
                ...state,
                loadingSchool: false,
                school: action.data.school
            };
        case organizationConstants.GET_SCHOOL.FAILURE:
            return {
                ...state,
                loadingSchool: false,
                error: action.message
            };
            
        case organizationConstants.CREATE_SCHOOL.REQUEST:
            return {
                ...state,
                creatingSchool: true,
            };
        case organizationConstants.CREATE_SCHOOL.SUCCESS:
            return {
                ...state,
                creatingSchool: false,
                //TODO: Add this to the above schools
                createdSchool: action.data.school
            };
        case organizationConstants.CREATE_SCHOOL.FAILURE:
            return {
                ...state,
                creatingSchool: false,
                error: action.message,
            };
            
        case organizationConstants.INVITE_STUDENTS_SCHOOL.REQUEST:
            return {
                ...state,
                addingStudents: true,
            };
        case organizationConstants.INVITE_STUDENTS_SCHOOL.SUCCESS:
            return {
                ...state,
                studentAddedSuccess: true,
                addingStudents: false,
            };
        case organizationConstants.INVITE_STUDENTS_SCHOOL.FAILURE:
            return {
                ...state,
                error: action.message
            };
            
        case organizationConstants.GET_ORGANIZATION_USERS.REQUEST:
            return {
                ...state,
                isFetchingOrganizationUsers: true
            };
        case organizationConstants.GET_ORGANIZATION_USERS.SUCCESS:
            return {
                ...state,
                organizationUsers: action.data,
                isFetchingOrganizationUsers: false
            };
        case organizationConstants.GET_ORGANIZATION_USERS.FAILURE:
            return {
                ...state,
                error: action.message,
                organizationUsers: [],
                isFetchingOrganizationUsers: false
            };

        case organizationConstants.GET_INVITE_ROLES_AVAILABLE.REQUEST:
            return {
                ...state,
            };
        case organizationConstants.GET_INVITE_ROLES_AVAILABLE.SUCCESS:
            return {
                ...state,
                inviteRolesAvailable: action.data
            };
        case organizationConstants.GET_INVITE_ROLES_AVAILABLE.FAILURE:
            return {
                ...state,
                error: action.message,
                inviteRolesAvailable: []
            };
        case organizationConstants.DELETE_ORGANIZATION_USER.REQUEST:
            return {
                ...state,
            };
        case organizationConstants.DELETE_ORGANIZATION_USER.SUCCESS:
            return {
                ...state,
            };
        case organizationConstants.DELETE_ORGANIZATION_USER.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case organizationConstants.GET_ORGANIZATION_ASSETS.SUCCESS:
            return {
                ...state,
                organizationFileAssets: action.data
            };
        case organizationConstants.GET_ORGANIZATION_ASSETS.FAILURE:
            return {
                ...state,
                organizationFileAssets: [],
                error: action.message
            };

        case organizationConstants.UPDATE_ORGANIZATION_INFO.REQUEST:
            return {
                ...state,
            };
        case organizationConstants.UPDATE_ORGANIZATION_INFO.SUCCESS:
            return {
                ...state,
            };
        case organizationConstants.UPDATE_ORGANIZATION_INFO.FAILURE:
            return {
                ...state,
            };
        
        case organizationConstants.GET_ORGANIZATION_ASSETS.REQUEST:
            return {
                ...state,
            };
            
        case organizationConstants.UPDATE_ORGANIZATION_ASSET.SUCCESS:
            return {
                ...state,
                organizationFileAssets: action.data
            };
        case organizationConstants.UPDATE_ORGANIZATION_ASSET.FAILURE:
            return {
                ...state,
                organizationFileAssets: [],
                error: action.message
            };
        case organizationConstants.UPDATE_ORGANIZATION_ASSET.REQUEST:
            return {
                ...state,
            };

        case organizationConstants.DELETE_ORGANIZATION_ASSET.SUCCESS:
            return {
                ...state,
                organizationFileAssets: action.data
            };
        case organizationConstants.DELETE_ORGANIZATION_ASSET.FAILURE:
            return {
                ...state,
                organizationFileAssets: [],
                error: action.message
            };
        case organizationConstants.DELETE_ORGANIZATION_ASSET.REQUEST:
            return {
                ...state,
            };
        case organizationConstants.
          EXPORT_EXCEL_FROM_TEMPLATE_ORG_STATS.REQUEST:
            return {
                ...state,
                isExportingOrgExcelReport: true,
            };
        case organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_ORG_STATS.SUCCESS:
            return {
                ...state,
                isExportingOrgExcelReport: false,
            };
        case organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_ORG_STATS.FAILURE:
            return {
                ...state,
                error: action.message,
                isExportingOrgExcelReport: false,
            };
        case organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_CLASSROOM_STATS.REQUEST:
            return {
                ...state,
            };
        case organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_CLASSROOM_STATS.SUCCESS:
            return {
                ...state,
            };
        case organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_CLASSROOM_STATS.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case organizationConstants.GET_AVAILABLE_COURSE_GROUPS.SUCCESS:
            return {
                ...state,
                availableCourseGroups: action.data
            };
        case organizationConstants.GET_AVAILABLE_COURSE_GROUPS.FAILURE:
            return {
                ...state,
                availableCourseGroups: [],
                error: action.message
            };
        case organizationConstants.GET_AVAILABLE_COURSE_GROUPS.REQUEST:
            return {
                ...state,
            };
            
        case organizationConstants.GET_GRAPH_DATA_HOURLY_ORG.SUCCESS:
            return {
                ...state,
                graphDataHourlyOrganization: action.data
            };
        case organizationConstants.GET_GRAPH_DATA_HOURLY_ORG.FAILURE:
            return {
                ...state,
                graphDataHourlyOrganization: null,
                error: action.message
            };
        case organizationConstants.GET_GRAPH_DATA_HOURLY_ORG.REQUEST:
            return {
                ...state,
            };

        case organizationConstants.GET_GRAPH_DATA_HOURLY_USER_GROUP.SUCCESS:
            return {
                ...state,
                graphDataHourlyUserGroup: action.data
            };
        case organizationConstants.GET_GRAPH_DATA_HOURLY_USER_GROUP.FAILURE:
            return {
                ...state,
                graphDataHourlyUserGroup: null,
                error: action.message
            };
        case organizationConstants.GET_GRAPH_DATA_HOURLY_USER_GROUP.REQUEST:
            return {
                ...state,
            };

        case organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_ORG.SUCCESS:
            return {
                ...state,
                graphDataDayOfWeekOrganization: action.data
            };
        case organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_ORG.FAILURE:
            return {
                ...state,
                graphDataDayOfWeekOrganization: null,
                error: action.message
            };
        case organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_ORG.REQUEST:
            return {
                ...state,
            };

        case organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_USER_GROUP.SUCCESS:
            return {
                ...state,
                graphDataDayOfWeekUserGroup: action.data
            };
        case organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_USER_GROUP.FAILURE:
            return {
                ...state,
                graphDataDayOfWeekUserGroup: null,
                error: action.message
            };
        case organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_USER_GROUP.REQUEST:
            return {
                ...state,
            };

        case organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_USER_GROUP.SUCCESS:
            return {
                ...state,
                graphDataDailyUsageAggregatedUserGroup: action.data
            };
        case organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_USER_GROUP.FAILURE:
            return {
                ...state,
                graphDataDailyUsageAggregatedUserGroup: null,
                error: action.message
            };
        case organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_USER_GROUP.REQUEST:
            return {
                ...state,
            };

        case organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_ORG.SUCCESS:
            return {
                ...state,
                graphDataDailyUsageAggregatedOrg: action.data
            };
        case organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_ORG.FAILURE:
            return {
                ...state,
                graphDataDailyUsageAggregatedOrg: null,
                error: action.message
            };
        case organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_ORG.REQUEST:
            return {
                ...state,
            };
            
        case organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_USER_GROUP.SUCCESS:
            return {
                ...state,
                graphDataDailyLessonAggregatedUserGroup: action.data
            };
        case organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_USER_GROUP.FAILURE:
            return {
                ...state,
                graphDataDailyLessonAggregatedUserGroup: null,
                error: action.message
            };
        case organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_USER_GROUP.REQUEST:
            return {
                ...state,
            };

        case organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_ORG.SUCCESS:
            return {
                ...state,
                graphDataDailyLessonAggregatedOrg: action.data
            };
        case organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_ORG.FAILURE:
            return {
                ...state,
                graphDataDailyLessonAggregatedOrg: null,
                error: action.message
            };
        case organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_ORG.REQUEST:
            return {
                ...state,
            };
            
        case organizationConstants.GET_ORGANIZATION_TIME_ZONE_LOCATIONS.REQUEST:
            return {
                ...state
            };
        case organizationConstants.GET_ORGANIZATION_TIME_ZONE_LOCATIONS.SUCCESS:
            return {
                ...state,
                timeZoneLocations: action.data
            };
        case organizationConstants.GET_ORGANIZATION_TIME_ZONE_LOCATIONS.FAILURE:
            return {
                ...state,
                error: action.message
            };

        case organizationConstants.SET_ORGANIZATION_TIME_ZONE_LOCATION.REQUEST:
            return {
                ...state
            };
        case organizationConstants.SET_ORGANIZATION_TIME_ZONE_LOCATION.SUCCESS:
            return {
                ...state,
            };
        case organizationConstants.SET_ORGANIZATION_TIME_ZONE_LOCATION.FAILURE:
            return {
                ...state,
                error: action.message
            };

        case organizationConstants.GET_ORGANIZATION_COURSE_GROUPS.REQUEST:
            return {
                ...state,
            };
        case organizationConstants.GET_ORGANIZATION_COURSE_GROUPS.SUCCESS:
            return {
                ...state,
                organizationCourseGroups: action.data
            };
        case organizationConstants.GET_ORGANIZATION_COURSE_GROUPS.FAILURE:
            return {
                ...state,
                error: action.message
            };

        case organizationConstants.ADD_COURSE_GROUP_TO_ORGANIZATION.REQUEST:
            return {
                ...state
            };
        case organizationConstants.ADD_COURSE_GROUP_TO_ORGANIZATION.SUCCESS:
            return {
                ...state,
                organizationCourseGroups: action.data
            };
        case organizationConstants.ADD_COURSE_GROUP_TO_ORGANIZATION.FAILURE:
            return {
                ...state,
                error: action.message
            };

        case organizationConstants.REMOVE_COURSE_GROUP_FROM_ORGANIZATION.REQUEST:
            return {
                ...state
            };
        case organizationConstants.REMOVE_COURSE_GROUP_FROM_ORGANIZATION.SUCCESS:
            return {
                ...state,
                organizationCourseGroups: action.data
            };
        case organizationConstants.REMOVE_COURSE_GROUP_FROM_ORGANIZATION.FAILURE:
            return {
                ...state,
                error: action.message
            };
            
        case organizationConstants.GET_ORGANIZATION_FACT_BOX_STATS.REQUEST:
            return {
                ...state
            };
         case organizationConstants.GET_ORGANIZATION_FACT_BOX_STATS.SUCCESS:
             
             const tempOrganizationIdToFactBoxStats = state.organizationIdToFactBoxStats ? state.organizationIdToFactBoxStats : {}
             tempOrganizationIdToFactBoxStats[action.data.organizationId] = action.data 
             
            return {
                ...state,
                organizationIdToFactBoxStats: tempOrganizationIdToFactBoxStats
            };
         case organizationConstants.GET_ORGANIZATION_FACT_BOX_STATS.FAILURE:
            return {
                ...state
            };

        case organizationConstants.GET_ORGANIZATION_COURSE_GROUP_USAGE.REQUEST:
            return {
                ...state
            };
        case organizationConstants.GET_ORGANIZATION_COURSE_GROUP_USAGE.SUCCESS:
            return {
                ...state,
                organizationCourseGroupUsage: action.data
            };
        case organizationConstants.GET_ORGANIZATION_COURSE_GROUP_USAGE.FAILURE:
            return {
                ...state
            };
            
        case organizationConstants.SKILLS_COURSES_FOR_ORG.REQUEST: 
            return {
                ...state
            }
        case organizationConstants.SKILLS_COURSES_FOR_ORG.SUCCESS:
            return {
                ...state,
                skillsCourses: action.data
            }
        case organizationConstants.SKILLS_COURSES_FOR_ORG.FAILURE:
            return {
                ...state,
                skillsCourses: []
            }
        case authConstants.LOGOUT.SUCCESS:
            return initialState;
        default:
            return state
    }
}