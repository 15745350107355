import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {
    MultilineChart,
    Group,
    School,
    Settings,
} from '@material-ui/icons';
import { makeStyles} from '@material-ui/core/styles';
import {AutoGraph} from "@mui/icons-material";
import {UserStatisticsVisibility} from "../../CapeeshConstants";
import {FormattedMessage} from "react-intl";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {CapeeshColors} from "../../../../assets/ColorPalette";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    menu: {
        position: 'fixed',
        left: '0px',
        top: '120px',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        width: '15%',
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '60px',
        },
      "& .Mui-selected": {
        backgroundColor: CapeeshColors.Pink,
        color: 'white',
        borderRadius: '8px'
      },
      
    },
    menuItem: {
        margin: '5px',
        [theme.breakpoints.down('sm')]: {
            margin: '5px 0px',
        },
    },
}));

const ClassroomSidebarOptions = {
  "Dashboard": 0,
  "Courses": 1,
  "Students": 2,
  "Statistics": 3,
  "Admins": 4,
  "Settings": 5
}

const ClassroomSidebar = (props) => {
  
    // expected props
    // props.userGroupId
    const {computedMatch} = props;  
    const classes = useStyles();
    const {user} = props;
    
    const [currentSelectedSidebarItem, setCurrentSelectedSidebarItem] = useState(null);

  
    useEffect(() => {
      if (props.path) {
        switch (props.path) {
          case "/dashboard/classroom/:classroomId":
            setCurrentSelectedSidebarItem(ClassroomSidebarOptions.Dashboard);
            break;
          case "/dashboard/classroom/:classroomId/dashboard":
            setCurrentSelectedSidebarItem(ClassroomSidebarOptions.Dashboard);
            break;
          case "/dashboard/classroom/:classroomId/courses":
            setCurrentSelectedSidebarItem(ClassroomSidebarOptions.Courses);
            break;
          case "/dashboard/classroom/:classroomId/students":
            setCurrentSelectedSidebarItem(ClassroomSidebarOptions.Students);
            break;
          case "/dashboard/classroom/:classroomId/statistics":
            setCurrentSelectedSidebarItem(ClassroomSidebarOptions.Statistics);
            break;
          case "/dashboard/classroom/:classroomId/admins":
            setCurrentSelectedSidebarItem(ClassroomSidebarOptions.Admins);
            break; 
          case "/dashboard/classroom/:classroomId/settings":
            setCurrentSelectedSidebarItem(ClassroomSidebarOptions.Settings);
            break;
  
        }
  
      }
    }, []);
    
    const getSideMenuListForUser = () => { 
      var {organization} = props;
      var shouldShowStudents = true;
      const currentOrganization = organization.myOrganizations.find(x => x.id === organization.selectedOrganization);
      if (user.organizationRole === "reporting" && !user.classroomOnlyReportingUser && currentOrganization?.userStatisticsVisibility === UserStatisticsVisibility.Strict) {
        // for organization type reporting user in strict mode should not see single students at all
        shouldShowStudents = false;
      }
      
      let shouldShowSettings = user.organizationRole === "admin";
      if (user.organizationRole === "reporting" && user.classroomOnlyReportingUser === false) {
        shouldShowSettings = true;
      }
      var shouldShowAdmins = !user.classroomOnlyReportingUser;
      
      return (
        <MenuList className={classes.menu}>

          <MenuItem className={classes.menuItem} button key='dashboard' component={Link} to={'/dashboard'}>
            <ListItemIcon><ArrowBackIcon/></ListItemIcon>
            <ListItemText primary={<FormattedMessage id="dashboard.backtoorganizatino" defaultMessage="Back to Organization"/>}/>
          </MenuItem>


          <MenuItem selected={currentSelectedSidebarItem === ClassroomSidebarOptions.Dashboard} className={classes.menuItem} button key='classroomdashboard' component={Link} to={'/dashboard/classroom/' + props.userGroupId + '/dashboard'}>
            <ListItemIcon><MultilineChart/></ListItemIcon>
            <ListItemText primary={<FormattedMessage id="dashboard.dashboard" defaultMessage="Dashboard"/>}/>
          </MenuItem>

          <MenuItem selected={currentSelectedSidebarItem === ClassroomSidebarOptions.Courses} className={classes.menuItem} button key='courses' component={Link} to={'/dashboard/classroom/' + props.userGroupId + '/courses'}>
            <ListItemIcon><School/></ListItemIcon>
            <ListItemText primary={<FormattedMessage id="dashboard.courses" defaultMessage="Courses"/>}/>
          </MenuItem>

          { 
            shouldShowStudents && 
            <MenuItem selected={currentSelectedSidebarItem === ClassroomSidebarOptions.Students} className={classes.menuItem} button key='students' component={Link} to={'/dashboard/classroom/' + props.userGroupId + '/students'}>
              <ListItemIcon><Group/></ListItemIcon>
              <ListItemText primary={<FormattedMessage id="dashboard.students" defaultMessage="Students"/>}/>
            </MenuItem>
          }

          <MenuItem selected={currentSelectedSidebarItem === ClassroomSidebarOptions.Statistics} className={classes.menuItem} button key='statistics' component={Link} to={'/dashboard/classroom/' + props.userGroupId + '/statistics'}>
            <ListItemIcon><AutoGraph/></ListItemIcon>
            <ListItemText primary={<FormattedMessage id="dashboard.statistics" defaultMessage="Statistics"/>}/>
          </MenuItem>

          {
            shouldShowAdmins &&
            <MenuItem selected={currentSelectedSidebarItem === ClassroomSidebarOptions.Admins} className={classes.menuItem} button key='admins' component={Link} to={'/dashboard/classroom/' + props.userGroupId + '/admins'}>
              <ListItemIcon><Settings/></ListItemIcon>
              <ListItemText primary={<FormattedMessage id="dashboard.sidebar" defaultMessage="Admins"/>}/>
            </MenuItem>
          }
          
          {
            shouldShowSettings &&
            <MenuItem selected={currentSelectedSidebarItem === ClassroomSidebarOptions.Settings} className={classes.menuItem} button key='settings' component={Link} to={'/dashboard/classroom/' + props.userGroupId + '/settings'}>
              <ListItemIcon><Settings/></ListItemIcon>
              <ListItemText primary={<FormattedMessage id="dashboard.settings" defaultMessage="Settings"/>}/>
            </MenuItem>
          }
        </MenuList>
      )
    }
    
    return (
        getSideMenuListForUser()
    );
};

ClassroomSidebar.propTypes = {
};

const mapStateToProps = (state) => {
    const {user, organization} = state;
    return {
        user,
        organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomSidebar)