import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Typography,
    Button,
    ListItemIcon
} from '@material-ui/core'
import {
    Snooze,
    Warning,
} from '@material-ui/icons'
import ClassroomSidebar from "./Components/ClassroomSidebar";
import {usergroupActions} from "_state/actions";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {userActions} from "_state/actions/user.actions";
import moment from "moment/moment";
import {organizationActions} from "../../../_state/actions";
import {UserStatisticsVisibility} from "../CapeeshConstants";
import DayOfTheWeekPieChart from "../../../Components/Graphs/DayOfTheWeekPieChart";
import HourOfDayGraph from "../../../Components/Graphs/HourOfDayGraph";
import Footer from "../../../Components/Footer";
import {FormattedMessage} from "react-intl";

const styles = theme => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight:'25px'
    },
    button: {
        margin: theme.spacing(1),
    },
    graph: {
        marginTop: 20,
    },
    leaderBoardIndex: {
        background: theme.palette.primary.main,
    }
});

class ClassroomDashboard extends React.Component {
    state = {
        leaderBoardTab: 0,
    };

    componentDidMount = () => {
        const {computedMatch, dispatch, organization} = this.props;
        dispatch(usergroupActions.getUserGroupStats(organization.selectedOrganization, computedMatch.params.classroomId));
        dispatch(organizationActions.getGraphDataDayOfWeekUserGroup(organization.selectedOrganization, computedMatch.params.classroomId));
        dispatch(organizationActions.getGraphDataHourlyUserGroupId(organization.selectedOrganization, computedMatch.params.classroomId));
        dispatch(organizationActions.getGraphDataDailyUsageAggregatedUserGroup(organization.selectedOrganization, computedMatch.params.classroomId));
    };

    getClassroomExcelReportFromTemplate = () => {
        let date = moment(moment.now()).format('YYYY-MM-DD');
        const { usergroup, computedMatch, dispatch, organization} = this.props;
        var filename = "Classroom Report for " + computedMatch.params.classroomId + " " + date + ".xlsx";
        var currentUserGroup = usergroup.usergroups.find(x => x.id === computedMatch.params.classroomId);
        var currentOrganization = organization.myOrganizations.find(x => x.id === organization.selectedOrganization);

        if (currentUserGroup) {
            filename = "Classroom Report for " + currentUserGroup.name + " " + date + ".xlsx";
        }
        dispatch(organizationActions.exportExcelFromTemplateClassroomStats(currentOrganization.id, computedMatch.params.classroomId, filename));
    }
    
    resendInvite = (event) => {
        const { dispatch, usergroup, organization} = this.props;
        const usersNotSignedUp = usergroup.userGroupStats.users.filter(x => x.signUpDate == '0001-01-01T00:00:00+00:00');
        usersNotSignedUp.forEach(element => {
                dispatch(userActions.resendInvite(organization.selectedOrganization, element.userId))
               });
        };

    createActionPoint = (userGroupStats, showEngagementButtons, showNotActiveLast7Days) => {
        let actionCards = [];
        
        if(!userGroupStats?.users)
            return actionCards;

        const usersSignedUp = userGroupStats.users.filter(x => x.signUpDate != '0001-01-01T00:00:00+00:00');
        const usersNotSignedUp = userGroupStats.users.filter(x => x.signUpDate == '0001-01-01T00:00:00+00:00');
        const usersNotActiveInLast7Days = usersSignedUp.filter(x => x.hasActivityLast7Days);
        
        if (usersNotSignedUp?.length > 0) {
            actionCards.push(
                <ListItem
                    key='usersNotSignedUp'
                >
                    <ListItemIcon>
                        <Warning color='primary'/>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <FormattedMessage id="dashboard.classroom.dash.usersnotsignedupyet"
                                              defaultMessage="users have not signed up yet">
                                {usersNotSignedUpYetText => usersNotSignedUp.length + ' ' + usersNotSignedUpYetText}
                            </FormattedMessage>
                        }
                    />
                    {
                        showEngagementButtons && 
                        <ListItemSecondaryAction>
                            <Button variant='contained' onClick={() => this.resendInvite()}  >
                                <FormattedMessage id="globalwords.sendreminder" defaultMessage="Send reminder"/>
                            </Button>
                        </ListItemSecondaryAction>
                    }
                </ListItem>
            )
        }
        if (showNotActiveLast7Days && usersNotActiveInLast7Days?.length > 0) {
            actionCards.push(
                <ListItem
                    key='usersNotActive'
                >
                    <ListItemIcon>
                        <Snooze/>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <FormattedMessage id="dashboard.classroom.dash.havenotbeenactive" defaultMessage="have not been active for 7 days"> 
                                {userHaveNotBeenActiveFor7DaysText => usersNotActiveInLast7Days.length + ' ' + userHaveNotBeenActiveFor7DaysText}
                             </FormattedMessage>
                        }
                    />
                    {
                        showEngagementButtons &&
                        <ListItemSecondaryAction>
                            <Button variant='contained' disabled>
                                <FormattedMessage id="globalwords.encourage" defaultMessage="Encourage"/>
                            </Button>
                        </ListItemSecondaryAction>
                    }
                </ListItem>
            )
        }

        return actionCards;
    };

    handleChange = (event, value, stateProp) => {
        this.setState({[stateProp]: value});
    };

    removeDuplicates = (myArr, prop) => {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    };


    render() {
        const {classes, usergroup, user, organization, computedMatch} = this.props;
        const {leaderBoardTab} = this.state;
        const showEngagementButtons = user.organizationRole === "admin";
        const showNotActiveLast7Days = user.organizationRole === "admin";
        let actionPoints = this.createActionPoint(usergroup.userGroupStats, showEngagementButtons, showNotActiveLast7Days);
        
        let leaderboardUsers = usergroup?.userGroupStats?.users;
        
        if (user.organizationRole !== "admin") {
            leaderboardUsers = leaderboardUsers?.filter(x => !x.email.includes('@itsoundsgood.no') && !x.email.includes('@capeesh.com'))
        }

        
        
        var showLeaderboard = false;
        var showActionPoints = true;
        var currentOrganization = null;
        if (organization.myOrganizations) {
            currentOrganization = organization.myOrganizations.find(x => x.id === organization.selectedOrganization);
        }
        if (currentOrganization && (user.organizationRole !== 'reporting' || currentOrganization?.userStatisticsVisibility === UserStatisticsVisibility.SchoolMode)) {
            showLeaderboard = true;
        } 
        
        let dayOfWeekUsageStats = []
        let showDayOfWeekUsageStats = false;
        if (organization.graphDataDayOfWeekUserGroup && organization.graphDataDayOfWeekUserGroup.userGroupId === computedMatch.params.classroomId && organization.graphDataDayOfWeekUserGroup.canShowGraphData) {
            dayOfWeekUsageStats = organization.graphDataDayOfWeekUserGroup.graphStatsDayOfWeekDtos.map(pie => (
              {name: pie.dayOfWeekShort, value: pie.secondsCombined}
            ));
            showDayOfWeekUsageStats = true;
        }

        let showTimeOfDayStats = false;
        let timeOfDayStats = []
        let timeOfDayStatsIndex = {};
        if (organization.graphDataHourlyUserGroup && organization.graphDataHourlyUserGroup.userGroupId === computedMatch.params.classroomId && organization.graphDataHourlyUserGroup.canShowGraphData) {
            timeOfDayStats = organization.graphDataHourlyUserGroup.graphStatsHourlyDtos;
            timeOfDayStats.forEach((el, index) => timeOfDayStatsIndex[el.hourOfTheDay] = el.minutesCombined);
            showTimeOfDayStats = true;
        }
        
        return (
            <div className={classes.root}>
                <ClassroomSidebar
                  userGroupId={computedMatch.params.classroomId}
                  path={computedMatch.path}
                />
                <Grid container spacing={5} style={{marginTop: '25px'}}>

                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardHeader
                                title={<FormattedMessage id="dashboard.classroom.dash.reporting" defaultMessage="Reporting"/>}
                            />
                            <CardContent>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        disabled={user.organizationRole !== "admin" && user.organizationRole !== "teacher" && user.organizationRole !== 'reporting' }
                                        onClick={() => this.getClassroomExcelReportFromTemplate()}
                                        style={{float: 'middle'}}
                                >
                                    <FormattedMessage id="dashboard.classroom.dash.downloadexcelreport" defaultMessage="Download excel report"/>
                                </Button>
                            </CardContent>
                            

                        </Card>
                    </Grid>
                    {
                        showActionPoints &&
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardHeader
                                  
                                    title={<FormattedMessage id="dashboard.classroom.dash.actionpointstitle" defaultMessage="Action points"/>}
                                />
                                <CardContent>
                                    {
                                        actionPoints?.length > 0 ?
                                            <List>
                                                {
                                                    actionPoints.map((actionCard, index) => (
                                                        actionCard
                                                    ))
                                                }
                                            </List>
                                            :
                                            <Typography variant='body2'>
                                                <FormattedMessage id="dashboard.classroom.dash.noactionpoints" defaultMessage="No action points, all good!"/>
                                            </Typography>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    }

                    <Grid item xs={12} sm={6}>
                        <DayOfTheWeekPieChart
                            dayOfWeekChartData={dayOfWeekUsageStats}
                            canShowChart={showDayOfWeekUsageStats}
                        />
                    </Grid>    

                    <Grid item xs={12} sm={6}>
                        <HourOfDayGraph
                          graphData={timeOfDayStats}
                          graphDataIndex={timeOfDayStatsIndex}
                          canShowChart={showTimeOfDayStats}
                          showYAxisLabel={false}
                        />
                    </Grid>
                    
                    {
                        showLeaderboard &&
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardHeader
                                  
                                    title={<FormattedMessage id="dashboard.classroom.dash.leaderboard.title" defaultMessage="Leaderboards"/>}
                                />
                                <CardContent>
                                    <Tabs
                                        value={leaderBoardTab}
                                        name='leaderBoardTab'
                                        onChange={(event, value) => this.handleChange(event, value, 'leaderBoardTab')}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        centered
                                    >
                                        <Tab label={<FormattedMessage id="globalwords.languagepoints" defaultMessage="Language points"/>}/>
                                        <Tab label={<FormattedMessage id="globalwords.activity" defaultMessage="Activity"/>}/>
                                    </Tabs>
                                    {
                                        leaderBoardTab === 0 &&
                                        <List>
                                            {
                                                leaderboardUsers &&
                                                leaderboardUsers
                                                    .sort((a, b) => b.experience - a.experience)
                                                    .slice(0, 5)
                                                    .map((user, index) => (
                                                        <ListItem
                                                            key={index}
                                                        >
                                                            <ListItemAvatar>
                                                                <Avatar className={classes.leaderBoardIndex}>
                                                                    {index + 1}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={user.name + ' (' + user.experience.toLocaleString() + 'LP)'}
                                                            />
                                                        </ListItem>
                                                    ))
                                            }
                                        </List>
                                    }
                                    {
                                        leaderBoardTab === 1 &&
                                        <List>
                                            {
                                                leaderboardUsers && 
                                                leaderboardUsers
                                                    .sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive))
                                                    .slice(0, 5)
                                                    .map((user, index) => (
                                                        <ListItem
                                                            key={index}
                                                        >
                                                            <ListItemAvatar>
                                                                <Avatar className={classes.leaderBoardIndex}>
                                                                    {index + 1}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={user.name}
                                                            />
                                                        </ListItem>
                                                    ))
                                            }
                                        </List>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    }
}

ClassroomDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const {organization, usergroup, user} = state;
    return {
        user,
        organization,
        usergroup
    };
}

const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles),
    withRouter
);

export default enhance(ClassroomDashboard)