

import EnglishFlag1 from '../assets/images/flags/262-united-kingdom.png'
import FrenchFlag2 from '../assets/images/flags/077-france.png'
import NorwegianFlag3 from '../assets/images/flags/058-norway.png'
import SpanishFlag4 from '../assets/images/flags/044-spain.png'
import DutchFlag5 from '../assets/images/flags/195-netherlands.png'
import GermanFlag6 from '../assets/images/flags/066-germany.png'
import KurdishKurmanjiFlag7 from '../assets/images/flags/NoFlag_icon_background_tinyRadius.png'
import SolmaliFlag8 from '../assets/images/flags/198-somalia.png'
import PersianFlag9 from '../assets/images/flags/063-iran.png'
import PolishFlag10 from '../assets/images/flags/108-poland.png'
import TamilFlag11 from '../assets/images/flags/Tamil_256.png'
import ThaiFlag12 from '../assets/images/flags/184-thailand.png'
import TurkishFlag13 from '../assets/images/flags/119-turkey.png'
import UrduFlag14 from '../assets/images/flags/031-pakistan.png'
import VietnameseFlag15 from '../assets/images/flags/164-vietnam.png'
import PashtoFlag16 from '../assets/images/flags/NoFlag_icon_background_tinyRadius.png'
import BengaliFlag17 from '../assets/images/flags/NoFlag_icon_background_tinyRadius.png'
import LithuanianFlag18 from '../assets/images/flags/025-lithuania.png'
import ItalianFlag19 from '../assets/images/flags/011-italy.png'
import ArabicFlag20 from '../assets/images/flags/Arabic Flag.png'
import AmharicFlag21 from '../assets/images/flags/001-ethiopia.png'
import RomanianFlag22 from '../assets/images/flags/050-romania.png'
import ChineseSimplifiedFlag23 from '../assets/images/flags/261-china.png'
import GreekFlag24 from '../assets/images/flags/071-greece.png'
import UkranianFlag25 from '../assets/images/flags/065-ukraine.png'
import EnglishUsFlag26 from '../assets/images/flags/153-united-states-of-america.png'
import RussianFlag27 from '../assets/images/flags/228-russia.png'
import TigrinyaFlag28 from '../assets/images/flags/210-eritrea.png'
import SpanishLaFlag29 from '../assets/images/flags/LA Spanish.png'
import CzechFlag30 from '../assets/images/flags/061-czech-republic.png'
import BulgrairanFlag31 from '../assets/images/flags/135-bulgaria.png'
import SlovakFlag32 from '../assets/images/flags/045-slovakia.png'
import NyNorskFlag33 from '../assets/images/flags/058-norway.png'
import DariFlag34 from '../assets/images/flags/122-afghanistan.png'
import NepaliFlag35 from '../assets/images/flags/249-nepal.png'
import KurdishSoraniFlag36 from '../assets/images/flags/Kurdish_256.png'
import PortugueseBrazilFlag37 from '../assets/images/flags/250-brazil.png'
import LatvianFlag38 from '../assets/images/flags/032-latvia.png'
import AlbanianFlag39 from '../assets/images/flags/117-albania.png'




export const CapeeshLanguageIdToFlagContent =
  {
    1: EnglishFlag1,
    2: FrenchFlag2,
    3: NorwegianFlag3,
    4: SpanishFlag4,
    5: DutchFlag5,
    6: GermanFlag6,
    7: KurdishKurmanjiFlag7,
    8: SolmaliFlag8,
    9: PersianFlag9,
    10: PolishFlag10,
    11: TamilFlag11,
    12: ThaiFlag12,
    13: TurkishFlag13,
    14: UrduFlag14,
    15: VietnameseFlag15,
    16: PashtoFlag16,
    17: BengaliFlag17,
    18: LithuanianFlag18,
    19: ItalianFlag19,
    20: ArabicFlag20,
    21: AmharicFlag21,
    22: RomanianFlag22,
    23: ChineseSimplifiedFlag23,
    24: GreekFlag24,
    25: UkranianFlag25,
    26: EnglishUsFlag26,
    27: RussianFlag27,
    28: TigrinyaFlag28,
    29: SpanishLaFlag29,
    30: CzechFlag30,
    31: BulgrairanFlag31,
    32: SlovakFlag32,
    33: NyNorskFlag33,
    34: DariFlag34,
    35: NepaliFlag35,
    36: KurdishSoraniFlag36,
    37: PortugueseBrazilFlag37,
    38: LatvianFlag38,
    39: AlbanianFlag39,
  };

Object.freeze(CapeeshLanguageIdToFlagContent);


export function getFlagAssetForLanguageId(languageId) {
  return CapeeshLanguageIdToFlagContent[languageId];
}