import { call, put, takeLatest } from 'redux-saga/effects'
import {randdConstants, topicConstants} from '../actions'
import { randdService } from '../services'
import fileDownload from 'js-file-download';
import {snackbarActions} from "../actions/snackbar.actions";

export function* sentenceSimilarity(action) {
    try {
        const response = yield call(randdService.sentenceSimilarity, action.payload);
        yield put({type: randdConstants.SENTENCE_SIMILARITY.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
                message: "We could not test the sentence similarity. " + usefulInformation,
                options: {
                    variant: "error"
            }
        }));
        yield put({type: topicConstants.SENTENCE_SIMILARITY.FAILURE, message: e.message});
    }
}

export function* sentenceSuitable(action) {
    try {
        const response = yield call(randdService.sentenceSuitable, action.payload);
        yield put({type: randdConstants.SENTENCE_SUITABLE.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
                message: "We could not test the sentence suitability for the writing test. " + usefulInformation,
                options: {
                    variant: "error"
            }
        }));
        yield put({type: topicConstants.SENTENCE_SUITABLE.FAILURE, message: e.message});
    }
}

export function* generalReset(action) {
    try {
        const response = yield call(randdService.generalReset, action.payload);
        yield put({type: randdConstants.GENERAL_RESET.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = ""; // in case response data is empty.
        usefulInformation += e.response.data ;
        yield put(snackbarActions.enqueueSnackbar({
                message: "Error resetting " + usefulInformation,
                options: {
                    variant: "error"
            }
        }));
        yield put({type: topicConstants.GENERAL_RESET.FAILURE, message: e.message});
    }
}

//Listeners
export function* randdSagas() {
    yield takeLatest(randdConstants.SENTENCE_SIMILARITY.REQUEST, sentenceSimilarity);
    yield takeLatest(randdConstants.SENTENCE_SUITABLE.REQUEST, sentenceSuitable);
}