import React, {useEffect, useState} from "react";
import {
    Card,
    Button,
    Grid,
    TextField,
    Input,
    Switch
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {makeGetRequest} from "../../../../_state/helpers/api";
import {configWithAuth} from "../../../../_state/helpers";
import ChipInput from 'material-ui-chip-input'
import Slider from '@material-ui/core/Slider';
import { HighlightWithinTextarea } from 'react-highlight-within-textarea'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'black',
    color: "black",
  },
  body: {
    fontSize: 28,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    //width: 300,
  },
  chip: {
    marginRight: '5px',
    marginBottom: '5px',
  },
  scenario: {
    paddingTop: '30px'
  },
  textFields: {
    marginBottom: '5px'
  },
  slider: {
    marginLeft: '20px',
    marginRight: '50px'
  }
}));



const SentenceBuilderSlider = withStyles({
  root: {
    color: '#143349',
    height: 8,
    marginLeft: '10px',
    marginRight: '10px'
  },
  thumb: {
    height: 24,
    width: 24,
    background: '#F8F8F8',
    border: '7px solid #143349',
    boxSizing: 'border-box',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);


const CapeeshHighlighter = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState(2);
    const [firstWordInRange, setFirstWordInRange] = React.useState([0,0]);
    const [selectionStart, setSelectionStart] = React.useState(-1);
    const [selectionEnd, setSelectionEnd] = React.useState(-1);
    const [isSelection, setIsSelection] = React.useState(false);
    const [highlighter, setHighlighter] = React.useState([]);
    const [newAnswer, setNewAnswer] = useState("");

    
    useEffect(() => {
     // TODO handleToggle(value, "props")
    }, [props.text]);

    useEffect(() => {
      setHighlighter(highlightIterator(props.alternatives))
  }, [props.alternatives]);

    
  const firstInRange = (localStartIndex, localEndIndex) => {
    let firstInRangeStartIndex = localStartIndex
    let firstInRangeEndIndex = localEndIndex
    let isFound = false
    props.testOption.alternatives.forEach(alternative => {
        if(!!!isFound && localStartIndex >= alternative.startIndex && localEndIndex <= (alternative.startIndex + alternative.text.length)){
          firstInRangeStartIndex = alternative.startIndex
          firstInRangeEndIndex = alternative.startIndex + alternative.text.length
          isFound = true
        }
      
    });
    return [firstInRangeStartIndex,firstInRangeEndIndex]
  }

  const checkExistingAlternative = (alternatives, selectionStart, selectionEnd) => {
    let isAvailableWord = true
    alternatives.forEach(alternative => {    
      if((selectionStart > alternative.startIndex && selectionStart <= (alternative.startIndex + alternative.text.length)) ) { 
        isAvailableWord = false
      }
      if((selectionStart < alternative.startIndex && selectionEnd > (alternative.startIndex + alternative.text.length)) && 
      (selectionEnd - selectionStart > alternative.startIndex + alternative.text.length - alternative.startIndex) ) { 
        isAvailableWord = false
      }
      if((selectionEnd > alternative.startIndex && selectionEnd < (alternative.startIndex + alternative.text.length)) ) { 
        isAvailableWord = false
      }
    });
    return isAvailableWord
  }

  const highlightIterator = (alternatives) => {
    let highlightArray = []
    let addedStartIndeces = []
    alternatives.forEach(alternative => {
      if(!!!addedStartIndeces.includes(alternative.startIndex)) {
        highlightArray.push([alternative.startIndex, alternative.startIndex + alternative.text.length])
        addedStartIndeces.push(alternative.startIndex)
      }
    });
    return highlightArray
  }

  const isValidAlternativeToDisplay = (alternative) => {
    return selectionStart >= alternative.startIndex && selectionStart <= alternative.startIndex + alternative.text.length
    //return selectionStart >= firstWordInRange[0] && selectionStart <= (firstWordInRange[1]) 
  }

    return (
      <React.Fragment>

      <HighlightWithinTextarea
      style={{ border: 'none', width: '450px'}}
      disabled={props.disabled}
      fullWidth
      multiline="true"
      value={props.value}
      highlight={highlighter}
      onChange= {event => setValue(event.target.value)}
      onClick={event=>{
        event.stopPropagation();
          //setSelectionStart(event.target.selectionStart)
          //setSelectionEnd(event.target.selectionEnd)
          setFirstWordInRange(firstInRange(event.target.selectionStart, event.target.selectionEnd))
          let firstInRangeStartEnd = firstInRange(event.target.selectionStart, event.target.selectionEnd)
          setSelectionStart(firstInRangeStartEnd[0])
          setSelectionEnd(firstInRangeStartEnd[1])

          if(event.target.selectionEnd - event.target.selectionStart > 0) {
            
            setIsSelection(checkExistingAlternative(props.testOption.alternatives, event.target.selectionStart, event.target.selectionEnd))
          
          } else {
            setIsSelection(false)
          }
      }}
      onBlur={event=>{
        event.stopPropagation();

        //setSelectionStart(-1)
        //setSelectionEnd(-1)
        //setIsSelection(false)
      }}
    />
              { isSelection &&
            <React.Fragment>
              <Button disabled={props.disabled} variant='outlined' size='small' onClick={event=>{props.onAddAlternative(selectionStart, props.testOption.text.substring(selectionStart, selectionEnd), props.testOption)}} className={classes.button}>
                Add distractor test
              </Button>
              <Button disabled={props.disabled} variant='outlined' size='small' className={classes.button}>
                Add keyboard test
              </Button>
           </React.Fragment>
          }
    <React.Fragment>
    <Button disabled={props.disabled} onClick={() => props.onAddDistractorWithinSentence(newAnswer)} style={ {}} variant="outlined"   >Add</Button>
   
    <TableContainer >
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Word</StyledTableCell>
            <StyledTableCell>Correct</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.alternatives.sort((a, b) => a.text > b.text).map((alternative, index) => (
            isValidAlternativeToDisplay(alternative) &&
            <TableRow key={index}>
              <TableCell >      
                <Input
                  value={alternative.text}
                  //name={name}
                  //onChange={e => onChange(e.target.value, alternative)}
                  className={classes.input}
                />
              </TableCell>
              <TableCell >                  
                  <Switch
                  //checked={alternative.correct === true }
                  //onChange={e => onChange(e.target.value, alternative)}

                  //onChange={e => handleChangeCorrect(alternative)}
                  color="primary"
                  name="checkedB"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
            </TableRow>
          
          ))
          }
        </TableBody>
      </Table>
    </TableContainer>
</React.Fragment>
</React.Fragment>

    );
};

CapeeshHighlighter.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(CapeeshHighlighter)