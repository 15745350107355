import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { organizationActions} from "_state/actions";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography, makeStyles, Button
} from "@material-ui/core";

import { FormattedMessage } from 'react-intl';
import MaterialTable from "material-table";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {history} from "../../../_state/helpers";

import EmailInvite from "../../../Components/EmailInvite";
import {
  InviteRolesToDisplayName,
} from "../../../Logic/OrganizationAssetConstants";
import OrganizationOverviewSidebar from "../Organization/Components/OrganizationOverviewSidebar";
import CapeeshStyledModal from "../../../Components/CapeeshStyledModal";
import {CapeeshColors} from "../../../assets/ColorPalette";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
    marginLeft: 'calc(15% + 25px)',
    marginRight:'25px',

  },
  divider: {
    margin: '0 30'
  },
  dropZone: {
    position: 'relative',
    height: '100px',
    color: '#ccc',
    border: 'dashed 2px #ccc',
    borderRadius: '15px',
    backgroundColor: '#f9f9f9',
    margin: '15px auto',
    padding: 15,
    transition: 'all 200ms ease-out',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'block',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  card: {
    minHeight: '250px',
    marginTop: '25px',
    marginRight: '40px',
  },
  userBody: {
    marginTop: '10px'
  },
  inviteButton: {
    float: 'right',
    clear: 'both',
    marginTop: '15px',
    marginRight: '15px'
  },


  tableHeaderCell: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: 'Rubik',
    textAlign: 'left',
  },

  tableHeaderCellSmallText: {
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontFamily: 'Rubik',
    textAlign: 'left',
  },


  tableRowCellName: {
    color: CapeeshColors.Pink,
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontWeight: 500,
    textTransform: 'none',
    textOverflow: 'ellipsis',
    overflow:'hidden',
    whiteSpace:'nowrap',
    width:'190px'
  }
  
}));

const flexContainer = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
};

const EditOrganizationAdmins = (props) => {

  const classes = useStyles();
  // panes
  const [inviteEmailsOpen, setInviteEmailsOpen] = useState(false);


  // admin users
  const [currentOrganizationAdminUsers, setCurrentOrganizationAdminUsers] = useState([]);
  
  
  // delete users
  const [toDeleteUser, setToDeleteUser] = useState(null);
  const [showDeleteUserConfirmation, setShowDeleteUserConfirmation] = useState(false);

  const isEmailOfUserSameAsAdmin = (email) => {
    return props.user.info.email === email;
  }
  
  useEffect( () => {
    const {location} = props;

    const organizationId = (location.state && location.state.organizationId) ? location.state.organizationId : props.organization.selectedOrganization;

    setCurrentOrganizationAdminUsers([]);

    props.dispatch(organizationActions.getMyOrganizations());
    props.dispatch(organizationActions.getOrganizationAssets(organizationId));

  }, []);


  useEffect( () => {
    let adminUsers = []

    if (props.organization.organizationUsers) {
      props.organization.organizationUsers.filter(x => x.roles.includes("Admin") || x.roles.includes("Teacher") || x.roles.includes("Reporting") || x.roles.includes("QA")).map((user, index) => {
         if (user.roles.includes("Admin")) {
          adminUsers.push({
            id: user.id,
            email: user.email,
            name: user.name,
            role: InviteRolesToDisplayName["Admin"],
            hasCreate: true,
            hasDashboard: true,
            hasCreateClassrooms: true,
            hasAddStudents: true,
            hasAddAdministrators: true,
            hasAdministerCourses: true,
            hasClassroomStatistics: true,
            hasOrganizationStatistics: true

          })
        }
        else if (user.roles.includes("Teacher")) {
          adminUsers.push({
            id: user.id,
            email: user.email,
            name: user.name,
            role: InviteRolesToDisplayName["Teacher"],
            hasCreate: true,
            hasDashboard: true,
            hasCreateClassrooms: true,
            hasAddStudents: true,
            hasAddAdministrators: true,
            hasAdministerCourses: true,
            hasClassroomStatistics: true,
            hasOrganizationStatistics: true
          })
        }
        else if (user.roles.includes("Reporting")) {
          if (user.classroomOnlyReportingUser) {
            adminUsers.push({
              id: user.id,
              email: user.email,
              name: user.name,
              role: "Classroom Administrator",
              hasCreate: false,
              hasDashboard: true,
              hasCreateClassrooms: false,
              hasAddStudents: true,
              hasAddAdministrators: false,
              hasAdministerCourses: true,
              hasClassroomStatistics: true,
              hasOrganizationStatistics: false
            })
          } else {
            adminUsers.push({
              id: user.id,
              email: user.email,
              name: user.name,
              role: InviteRolesToDisplayName["Reporting"],
              hasCreate: false,
              hasDashboard: true,
              hasCreateClassrooms: true,
              hasAddStudents: true,
              hasAddAdministrators: true,
              hasAdministerCourses: true,
              hasClassroomStatistics: true,
              hasOrganizationStatistics: true
            })
          }
        }
        else {
          adminUsers.push({
            id: user.id,
            email: user.email,
            name: user.name,
            role: user.roles.join(", "),
            hasCreate: false,
            hasDashboard: false,
            hasCreateClassrooms: false,
            hasAddStudents: false,
            hasAddAdministrators: false,
            hasAdministerCourses: false,
            hasClassroomStatistics: false,
            hasOrganizationStatistics: false
          })
        }
      });

    }
    setCurrentOrganizationAdminUsers(adminUsers);

  }, props.organization.organizationUsers);

  
  
  const handleDeleteOrganizationUser = (userToDelete) => {
    if (userToDelete?.id) {
      setToDeleteUser(userToDelete);
      setShowDeleteUserConfirmation(true);
    }
  }
  
  const handleDeleteOrganizationUserCanceled = () => {
    setToDeleteUser(null);
    setShowDeleteUserConfirmation(false);
  }
  const handleDeleteOrganizationUserConfirmed = () => {
    setShowDeleteUserConfirmation(false);
    
    if (toDeleteUser?.id) {
      props.dispatch(organizationActions.deleteOrganizationUser(props.organization.selectedOrganization, toDeleteUser.id, null));
    }
  };


  return (
    <div className={classes.root} style={{padding: '20px'}}>

      {
        showDeleteUserConfirmation &&
        <CapeeshStyledModal
          open={showDeleteUserConfirmation}
          textTitle={"Delete confirmation"}
          textLine1={"Do you really want delete the \"" + toDeleteUser.email + "\"? The user will loose access to Capeesh Dashboard and Capeesh Create and App for this organization."}
          onButton2={() => handleDeleteOrganizationUserConfirmed()}
          onButton1={() => handleDeleteOrganizationUserCanceled()}
          button1Text={"Cancel"}
          button1Variant={'outlined'}
          button2Text={"Yes"}
          onClose={() => handleDeleteOrganizationUserCanceled()}
        />
      }
      
      <OrganizationOverviewSidebar
        path={props.computedMatch?.path}
      />
      
      <Grid container spacing={2}>
        <Grid item xs={6}>
            <Typography variant='h4' style={{marginTop: '25px'}}>
              <FormattedMessage id="admin.edit.org.orgAdminsHeader"
                                defaultMessage="Administrators"/>
            </Typography>
        </Grid>        
        <Grid item xs={6} 
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'baseline'
              }}>
          {
            <Button color={"primary"}
                    variant={"contained"}
                    className={classes.inviteButton}
                    onClick={() => setInviteEmailsOpen(true)}>
              <FormattedMessage id="dashboard.admins.inviteadministrator"
                                defaultMessage="Invite administrators"/>
            </Button>
          }
        </Grid>

        <Grid xs={12}>
          <Typography variant='body2' className={classes.userBody}>
            <FormattedMessage id="admin.edit.org.orgAdminsSubHeader"
                              defaultMessage="These are users with administrator access to your organization"/>
          </Typography>

        </Grid>
        
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardContent>
              <EmailInvite inviteEmailsOpen={inviteEmailsOpen}
                           title={"Invite new administrators"}
                           description={"Enter emails for user to invite. Use comma to separate and add more than one."}
                           handleInviteEmailsClose={() => setInviteEmailsOpen(false)}
                           availableRoles={["Reporting", "Teacher", "Admin", "QA"]}
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer sx={{marginTop: '30px', minWidth: 400, backgroundColor: CapeeshColors.Gray5, borderRadius: '16px'}}>
                  <Table style={{marginTop: '20px', marginBottom: '20px'}}  size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell width="20%">
                          <Typography className={classes.tableHeaderCell}>
                            <FormattedMessage id="dashboard.classroom.table.email" defaultMessage="Email"/>
                          </Typography>
                        </TableCell>
                        <TableCell width="20%">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.classroom.table.role" defaultMessage="Role"/>
                          </Typography>
                        </TableCell>
                        <TableCell width="7%" align="right">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.administrators.classroom.table.create" defaultMessage="Create"/>
                          </Typography>
                        </TableCell>
                        <TableCell width="7%" align="right">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.administrators.classroom.table.dashboard" defaultMessage="Dashboard"/>
                          </Typography>
                        </TableCell>
                        <TableCell width="7%" align="right">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.administrators.classroom.table.addclassrooms" defaultMessage="Add Classrooms"/>
                          </Typography>
                        </TableCell>
                        <TableCell width="7%" align="right">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.administrators.classroom.table.addStudents" defaultMessage="Add students"/>
                          </Typography>
                        </TableCell>
                        <TableCell width="7%" align="right">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.administrators.classroom.table.addadministrators" defaultMessage="Add administrators"/>
                          </Typography>
                        </TableCell>
                        <TableCell width="7%" align="right">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.administrators.classroom.table.administercourses" defaultMessage="Administer courses"/>
                          </Typography>
                        </TableCell>
                        <TableCell width="7%" align="right">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.administrators.classroom.table.organizationstats" defaultMessage="Statistics"/>
                          </Typography>
                        </TableCell>
                        <TableCell width="10%" align="right">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.administrators.classroom.table.delete" defaultMessage="Delete"/>
                          </Typography>
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentOrganizationAdminUsers.map((adminUser) => (
                        <TableRow
                          key={adminUser.id}
                          // onClick={() => handleGoToSingleClassroom(userGroup.id)}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                          <TableCell width="20%" component="th" scope="row">
                            <Typography className={classes.tableRowCellName} onClick={() => (history.push("/dashboard/admin/organization/admins/" + adminUser.id))}>
                              {adminUser.email}
                            </Typography>
                          </TableCell>
                          <TableCell width="20%" align="right">
                            <Typography style={{
                              textAlign: 'left',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontFamily: 'Rubik',}}>
                              {adminUser.role}
                            </Typography>
                          </TableCell>
                          <TableCell width="7%" align="right"><CheckCircleIcon style={{color: adminUser.hasCreate ? CapeeshColors.Green : CapeeshColors.Gray2}}/></TableCell>
                          <TableCell width="7%" align="right"><CheckCircleIcon style={{color: adminUser.hasDashboard ? CapeeshColors.Green : CapeeshColors.Gray2}}/></TableCell>
                          <TableCell width="7%" align="right"><CheckCircleIcon style={{color: adminUser.hasCreateClassrooms ? CapeeshColors.Green : CapeeshColors.Gray2}}/></TableCell>
                          <TableCell width="7%" align="right"><CheckCircleIcon style={{color: adminUser.hasAddStudents ? CapeeshColors.Green : CapeeshColors.Gray2}}/></TableCell>
                          <TableCell width="7%" align="right"><CheckCircleIcon style={{color: adminUser.hasAddAdministrators ? CapeeshColors.Green : CapeeshColors.Gray2}}/></TableCell>
                          <TableCell width="7%" align="right"><CheckCircleIcon style={{color: adminUser.hasAdministerCourses ? CapeeshColors.Green : CapeeshColors.Gray2}}/></TableCell>
                          <TableCell width="7%" align="right"><CheckCircleIcon style={{color: adminUser.hasClassroomStatistics ? CapeeshColors.Green : CapeeshColors.Gray2}}/></TableCell>
                          <TableCell width="7%" align="right">
                            <DeleteIcon style={isEmailOfUserSameAsAdmin(adminUser.email) ? { opacity: 0.25 } : {}}
                                        onClick={() => {
                                          if(isEmailOfUserSameAsAdmin(adminUser.email)) return;
                                          handleDeleteOrganizationUser(adminUser)
                                        }}/>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                </Grid>
              </Grid>
              
              
              
            </CardContent>
          </Card>

          <Grid container style={{marginTop: '70px'}}>

            <Grid item xs={12}>
              <Typography variant='h4'>
                Customer User Types
              </Typography>
              <Typography variant='body2' className={classes.userBody}>
                Under follows a overview of the different types of admin users we give to customers of Capeesh
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardHeader
                  title="Administrator"
                />
                <CardContent>
                  <Typography variant='subtitle1'>
                    Customer administrators for the whole organization
                  </Typography>
                  <Typography variant='subtitle1' className={classes.userBody}>
                    This user can see and edit organization specific information like: administrators, statistics, billing information. In addition they can Create classrooms and assign Classroom Administrators, Courses and Students to classrooms.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardHeader
                  title="Classroom Administrator"
                />
                <CardContent>
                  <Typography variant='subtitle1'>
                    Customer administrator role for one or more single Classrooms
                  </Typography>
                  <Typography variant='subtitle1' className={classes.userBody}>
                    This user can see 1 or more Classrooms. They can add students, change the Courses for classrooms and see statistics from the Students in those classrooms.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardHeader
                  title="Creator"
                />
                <CardContent>
                  <Typography variant='subtitle1'>
                    Customer role for course Creation
                  </Typography>
                  <Typography variant='subtitle1' className={classes.userBody}>
                    These users get access to Capeesh Create to create courses. The user also has access to create classrooms to test out the courses.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>


          <Grid container style={{marginTop: '70px'}}>

            <Grid item xs={12}>
              <Typography variant='h4'>
                Capeesh Internal Admin users
              </Typography>
              <Typography variant='body2' className={classes.userBody}>
                Under follows user types which we can use internally in Capeesh
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardHeader
                  title="Capeesh Admin"
                />
                <CardContent>
                  <Typography variant='subtitle1'>
                    Internal Capeesh Admin user that is mean for us in Capeesh to use for administration of Customers.
                  </Typography>
                  <Typography variant='subtitle1' className={classes.userBody}>
                    This user can edit see the whole organization and edit the organization specific attributes, like: licenses, logo, background image, admnistrators
                  </Typography>

                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardHeader
                  title="QA"
                />
                <CardContent>
                  <Typography variant='subtitle1'>
                    Internal Capeesh Admin user that can be used with Partners
                  </Typography>
                  <Typography variant='subtitle1' className={classes.userBody}>
                    Partners might want test users that can play the courses and comment / give feedback on courses in the app. This user gets access to the App but can give feedback directly into the Create Platform from the Capeesh Learning App
                  </Typography>

                </CardContent>
              </Card>
            </Grid>
            
          </Grid>
          
        </Grid>
      </Grid>
    </div>
  );
}


EditOrganizationAdmins.propTypes = {
};

const mapStateToProps = (state) => {
  const { organization, user } = state;
  return {
    organization,
    user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganizationAdmins)
