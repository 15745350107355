import { call, put, takeLatest, takeEvery} from 'redux-saga/effects'
import {userConstants, organizationConstants, } from '../actions'
import {userService, organizationService,} from '../services'
import {snackbarActions} from "../actions/snackbar.actions";


export function* getUserInfo(data) {
    try {
        const response = yield call(userService.getUserInfo);
        yield put({type: userConstants.GETUSERINFO.SUCCESS, data: response.data});

        let role = "";
        let classroomOnlyReportingUser = false;
        
        response.data.roles.forEach(element => {
            if(element.organizationId === data.payload.selectedOrganization) {   
                switch(element.role) {
                    case "Admin": 
                        role = "admin";
                        break;
                    case "Teacher": 
                        role = "teacher";
                        break;
                    case "Translator": 
                        role = "translator";
                        break;
                    case "Reporting":
                        role = "reporting";
                        if (element.classroomOnlyReportingUser === true) {
                            classroomOnlyReportingUser = true;    
                        }
                        break;
                    case "QA":
                        role = "qa";
                        break;
                    default:
                        role = "undefined";
                        
                }
            }
        });
        let roleData = {
            role,
            classroomOnlyReportingUser
        }
        yield put({type: userConstants.ASSIGN_ROLE.SUCCESS, data: roleData});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "Sorry, we were unable to get user statistics. We are looking into this.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: userConstants.GETUSERINFO.FAILURE, message: e.message});
    }
}

export function* updateUser(data) {
    try {
        const response = yield call(userService.updateUser, data.payload);
        yield put({type: userConstants.UPDATEUSER.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "Sorry, we were unable to get user statistics. We are looking into this.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: userConstants.UPDATEUSER.FAILURE, message: e.message});
    }
}

export function* getUserStats(data) {
    try {
        const response = yield call(userService.getUserStats, data.payload);
        yield put({type: userConstants.GET_USER_STATS.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "Sorry, we were unable to get user statistics. We are looking into this.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: userConstants.GET_USER_STATS.FAILURE, message: e.message});
    }
}

export function* resendInvite(data) {
    try {
        const response = yield call(userService.resendInvite, data.payload);
        yield put({type: userConstants.RESEND_INVITE.SUCCESS, data: response.data});
        yield put(snackbarActions.enqueueSnackbar({
                message: "The invitation has been sent to the user.",
                options: {
                    variant: "info"
            }
        }));
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to send the invitation. Please try again or contact support.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: userConstants.RESEND_INVITE.FAILURE, message: e.message});
    }
}

export function* createUserMessage(data) {
    try {
        const response = yield call(userService.createUserMessage, data.payload);
        yield put({type: userConstants.CREATE_USER_MESSAGE.SUCCESS, data: response.data});
        yield put(snackbarActions.enqueueSnackbar({
            message: "Done! Open the Capeesh app on your phone and the lesson will load from this stage.",
            options: {
                variant: "success"
            }
        }));
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
            message: "We were unable to send the user message. Please try again or contact support.",
            options: {
                variant: "warning"
            }
        }));
        yield put({type: userService.createUserMessage.FAILURE, message: e.message});
    }
}


export function* adminUpdateUserMasterOrganization(data) {
    try {
        const response = yield call(userService.adminUpdateUserMasterOrganization, data.payload);
        yield put({type: userConstants.ADMIN_UPDATE_USER_MASTER_ORGANIZATION.SUCCESS, data: response.data});

        const responseUserStats = yield call(organizationService.getOrganizationUsers, data.payload.currentOrganizationId);
        yield put({type: organizationConstants.GET_ORGANIZATION_USERS.SUCCESS, data: responseUserStats.data.users});
        yield put(snackbarActions.enqueueSnackbar({
            message: "The master organization has been updated.",
            options: {
                variant: "info"
            }
        }));

        if(data.payload.updateUserComplete)
            data.payload.updateUserComplete();
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
            message: "We are unable to change the master organization.",
            options: {
                variant: "warning"
            }
        }));
        yield put({type: userConstants.ADMIN_UPDATE_USER_MASTER_ORGANIZATION.FAILURE, message: e.message});
    }
}

export function* adminUpdateUser(data) {
    try {
        const response = yield call(userService.adminUpdateUser, data.payload);
        yield put({type: userConstants.ADMIN_UPDATE_USER.SUCCESS, data: response.data});
        const responseStats = yield call(userService.getUserStats, data.payload);
        yield put({type: userConstants.GET_USER_STATS.SUCCESS, data: responseStats.data});
        yield put(snackbarActions.enqueueSnackbar({
                message: "The language has been updated.",
                options: {
                    variant: "info"
            }
        }));
        
        if(data.payload.updateUserComplete)
            data.payload.updateUserComplete();
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: "We were unable to update the user's language.",
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: userConstants.ADMIN_UPDATE_USER.FAILURE, message: e.message});
    }
}

export function* getUserActivity(data) {
    try {
        const response = yield call(userService.getUserActivity, data.payload);
        yield put({type: userConstants.GET_USER_ACTIVITY.SUCCESS, data: response.data});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
            message: "Sorry, we were unable to get user statistics. We are looking into this.",
            options: {
                variant: "warning"
            }
        }));
        yield put({type: userConstants.GET_USER_ACTIVITY.FAILURE, message: e.message});
    }
}

export function* updateUserOrganizationRole(data) {
    try {
        const response = yield call(userService.updateUserOrganizationRole, data.payload);
        yield put({type: userConstants.UPDATE_USER_ORGANIZATION_ROLE.SUCCESS, data: response.data});

        const responseUserStats = yield call(organizationService.getOrganizationUsers, data.payload.organizationId);
        yield put({type: organizationConstants.GET_ORGANIZATION_USERS.SUCCESS, data: responseUserStats.data.users});
        
        yield put(snackbarActions.enqueueSnackbar({
            message: "The user role has been changed to " + data.payload.userRole + ".",
            options: {
                variant: "info"
            }
        }));
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
            message: "Sorry, .we were unable update the user role for the organization.",
            options: {
                variant: "warning"
            }
        }));
        yield put({type: userConstants.UPDATE_USER_ORGANIZATION_ROLE.FAILURE, message: e.message});
    }
}

export function* getUserCourseGroups(data) {
    try {
        const response = yield call(userService.getUserCourseGroups, data.payload);
        
        var userCourseGroupsData = {
            userId: data.payload.userId,
            courseGroups: response.data
        }
        
        yield put({type: userConstants.GET_USER_COURSE_GROUPS.SUCCESS, data: userCourseGroupsData});
    } catch (e) {
        
        var s = e
        
        yield put(snackbarActions.enqueueSnackbar({
            message: "Sorry, we were unable fetch user Courses.",
            options: {
                variant: "warning"
            }
        }));
        yield put({type: userConstants.GET_USER_COURSE_GROUPS.FAILURE, message: e.message});
    }
}

export function* getUserCourseGroupProgression(data) {
    try {
        const response = yield call(userService.getUserCourseGroupProgression, data.payload);
        
        var userCourseGroupProgressionData = {
            userId: data.payload.userId,
            courseGroupId: data.payload.courseGroupId,
            courseGroupProgression: response.data
        }
        
        yield put({type: userConstants.GET_USER_COURSE_GROUP_PROGRESSION.SUCCESS, data: userCourseGroupProgressionData});
    } catch (error) {

        if(error.response.status !== 404) {
            yield put(snackbarActions.enqueueSnackbar({
                message: "Sorry, we were unable fetch user Courses.",
                options: {
                    variant: "warning"
                }
            }));
            yield put({type: userConstants.GET_USER_COURSE_GROUP_PROGRESSION.FAILURE, message: error.message});
        }
    }
}

export function* getUserLessonProgressions(data) {
    try {
        const response = yield call(userService.getUserLessonProgressions, data.payload);

        yield put({type: userConstants.GET_USER_LESSON_PROGRESSIONS.SUCCESS, data: response.data});
    } catch (error) {
        yield put({type: userConstants.GET_USER_LESSON_PROGRESSIONS.FAILURE, message: error.message});
    }
}


//Listeners
export function* userSagas() {
    yield takeLatest(userConstants.GETUSERINFO.REQUEST, getUserInfo);
    yield takeLatest(userConstants.UPDATEUSER.REQUEST, updateUser);
    yield takeLatest(userConstants.GET_USER_STATS.REQUEST, getUserStats);
    yield takeLatest(userConstants.RESEND_INVITE.REQUEST, resendInvite);
    yield takeLatest(userConstants.ADMIN_UPDATE_USER.REQUEST, adminUpdateUser);
    yield takeLatest(userConstants.GET_USER_ACTIVITY.REQUEST, getUserActivity);
    yield takeLatest(userConstants.UPDATE_USER_ORGANIZATION_ROLE.REQUEST, updateUserOrganizationRole);
    yield takeLatest(userConstants.ADMIN_UPDATE_USER_MASTER_ORGANIZATION.REQUEST, adminUpdateUserMasterOrganization);
    yield takeLatest(userConstants.CREATE_USER_MESSAGE.REQUEST, createUserMessage);
    yield takeLatest(userConstants.GET_USER_COURSE_GROUPS.REQUEST, getUserCourseGroups);
    yield takeEvery(userConstants.GET_USER_COURSE_GROUP_PROGRESSION.REQUEST, getUserCourseGroupProgression);
    yield takeLatest(userConstants.GET_USER_LESSON_PROGRESSIONS.REQUEST, getUserLessonProgressions)
}