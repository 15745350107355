import { call, put, takeLatest } from 'redux-saga/effects'
import { metadataConstants} from '../actions'
import { metadataService } from '../services'

export function* getMetadata(action) {
    try {
        const response = yield call(metadataService.getMetadata);
        yield put({type: metadataConstants.GET_METADATA.SUCCESS, data: response.data});
    } catch (e) {
        yield put({type: metadataConstants.GET_METADATA.FAILURE, message: e.message});
    }
}

//Listeners
export function* metadataSagas() {
    yield takeLatest(metadataConstants.GET_METADATA.REQUEST, getMetadata);
}