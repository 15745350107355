import { asyncRequest } from './asyncRequest'


export const lessonConstants = {
    GET_ORGANIZATION_LESSONS: new asyncRequest('GET_ORGANIZATION_LESSONS'),
    CREATE_CUSTOM_LESSON: new asyncRequest('CREATE_CUSTOM_LESSON'),
    ADD_LESSON_QUESTION: new asyncRequest('ADD_LESSON_QUESTION'),
    GET_CUSTOM_LESSON: new asyncRequest('GET_CUSTOM_LESSON'),
    EDIT_CUSTOM_LESSON_DETAILS: new asyncRequest('EDIT_CUSTOM_LESSON_DETAILS'),
    EDIT_QUESTION: new asyncRequest('EDIT_QUESTION'),
    GENERAL_RESET: new asyncRequest('LESSON_GENERAL_RESET'),
    DELETE_CUSTOM_LESSON: new asyncRequest('DELETE_CUSTOM_LESSON'),
    DELETE_QUESTION: new asyncRequest('DELETE_QUESTION'),
    ASSIGN_LESSON_TO_TOPIC: new asyncRequest('ASSIGN_LESSON_TO_TOPIC'),
    GET_CUSTOM_LESSON_RELATIONSHIPS: new asyncRequest('GET_CUSTOM_LESSON_RELATIONSHIPS'),
    EXPORT_LESSON: new asyncRequest('EXPORT_LESSON'),
    IMPORT_LESSON: new asyncRequest('IMPORT_LESSON'),
    IMPORT_SCENARIO: new asyncRequest('IMPORT_SCENARIO'),
    IMPORT_SCENARIO_LINEAR: new asyncRequest('IMPORT_SCENARIO_LINEAR'),
    DELETE_FROM_COURSE: new asyncRequest('DELETE_FROM_COURSE'),
    ADD_IMAGE:  new asyncRequest('ADD_IMAGE'),
    GET_IMAGE_REFERENCES:  new asyncRequest('GET_IMAGE_REFERENCES'),
    GET_IMAGE:  new asyncRequest('GET_IMAGE'),
    ADD_AUDIO:  new asyncRequest('ADD_AUDIO'),
    GET_AUDIO_REFERENCES:  new asyncRequest('GET_AUDIO_REFERENCES'),
    GET_LESSON_AUDIO_REFERENCES:  new asyncRequest('GET_LESSON_AUDIO_REFERENCES'),
    GET_AUDIO:  new asyncRequest('GET_AUDIO'),
    GET_SCENARIO:  new asyncRequest('GET_SCENARIO'),
    PUBLISH_SCENARIO: new asyncRequest('PUBLISH_SCENARIO'),
    CAN_PUBLISH_SCENARIO: new asyncRequest('CAN_PUBLISH_SCENARIO'),
    GET_SCENARIO_IMAGES:  new asyncRequest('GET_SCENARIO_IMAGES'),
    PROGRESS_CHANGE: new asyncRequest('PROGRESS_CHANGE'),
    CREATE_SCENARIO: new asyncRequest('CREATE_SCENARIO'),
    GENIUS_SCENARIO: new asyncRequest('GENIUS_SCENARIO'),
    RESYNC_SCENARIO: new asyncRequest('RESYNC_SCENARIO'),
    UPDATE_SCENARIO: new asyncRequest('UPDATE_SCENARIO'),
    UPDATE_SCENARIO_NODE: new asyncRequest('UPDATE_SCENARIO_NODE'),
    UPDATE_SCENARIO_NODE_POSITION: new asyncRequest('UPDATE_SCENARIO_NODE_POSITION'),
    UPDATE_SCENARIO_NODE_COMMENT: new asyncRequest('UPDATE_SCENARIO_NODE_COMMENT'),

    DELETE_IMAGE: new asyncRequest('DELETE_IMAGE'),
    REPLACE_IMAGE: new asyncRequest('REPLACE_IMAGE'),
    
    GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES: new asyncRequest('GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES'),
    ADD_CUSTOM_LESSON_SUPPORTED_LANGUAGE: new asyncRequest('ADD_CUSTOM_LESSON_SUPPORTED_LANGUAGE'),
    DELETE_CUSTOM_LESSON_SUPPORTED_LANGUAGE: new asyncRequest('DELETE_CUSTOM_LESSON_SUPPORTED_LANGUAGE'),
    
    EXPORT_EXCEL_CUSTOM_LESSON_TRANSLATION: new asyncRequest('EXPORT_EXCEL_CUSTOM_LESSON_TRANSLATION'),
    IMPORT_EXCEL_CUSTOM_LESSON_TRANSLATION: new asyncRequest('IMPORT_EXCEL_CUSTOM_LESSON_TRANSLATION'),

    RESET_CURRENT_ORGANIZATION_LESSONS: new asyncRequest('RESET_CURRENT_ORGANIZATION_LESSONS'),
    CHECK_FOR_CHANGES: new asyncRequest('CHECK_FOR_CHANGES'),
    CHECK_FOR_CHANGES_CHANGED_CONTENT: new asyncRequest('CHECK_FOR_CHANGES_CHANGED_CONTENT'),
    LESSON_BUILDER_BUSY: new asyncRequest('LESSON_BUILDER_BUSY'),    
    LESSON_BUILDER_CAN_EDIT_LESSON: new asyncRequest('LESSON_BUILDER_CAN_EDIT_LESSON'),
    LESSON_BUILDER_VIEW_MODE: new asyncRequest('LESSON_BUILDER_VIEW_MODE'),
    
    // undo functionality
    GET_LESSON_VERSIONS: new asyncRequest('GET_LESSON_VERSIONS'),
    REVERT_TO_LESSON_VERSION: new asyncRequest('REVERT_TO_LESSON_VERSION'),
    
    
    // browser speech recogntion
    SET_SPEECH_RECOGNITION_L2: new asyncRequest('SET_SPEECH_RECOGNITION_L2'),
    
};

export const lessonActions = {
    getOrganizationLessons(organizationId) {
        return {
            type: lessonConstants.GET_ORGANIZATION_LESSONS.REQUEST,
            payload: {
                organizationId
            }
        }
    },
    createCustomLesson(name, sharable, description, organizationId, languageId) {
        return {
            type: lessonConstants.CREATE_CUSTOM_LESSON.REQUEST,
            payload: {
                name,
                sharable,
                description, 
                organizationId, 
                languageId,
                amountCorrectToPass: 0
            }
        }
    },
    addLessonQuestion(customLessonId, questions) {
        return {
            type: lessonConstants.ADD_LESSON_QUESTION.REQUEST,
            payload: {
                customLessonId,
                questions
            }
        }
    },
    getCustomLesson(customLessonId) {
        return {
            type: lessonConstants.GET_CUSTOM_LESSON.REQUEST,
            payload: {
                customLessonId,
            }
        }
    },
    editCustomLessonDetails(customLessonId, name, amountCorrectToPass, languageId, description) {
        return {
            type: lessonConstants.EDIT_CUSTOM_LESSON_DETAILS.REQUEST,
            payload: {
                customLessonId,
                name,
                amountCorrectToPass,
                languageId,
                description
            }
        }
    },
    editQuestion(customLessonId, question, questionId, answers, blockUntilCorrectAnswer) {
        return {
            type: lessonConstants.EDIT_QUESTION.REQUEST,
            payload: {
                customLessonId,
                question,
                questionId,
                answers,
                blockUntilCorrectAnswer
            }
        }
    },
    deleteCustomLesson(customLessonId, organizationId) {
        return {
            type: lessonConstants.DELETE_CUSTOM_LESSON.REQUEST,
            payload: {
                customLessonId,
                organizationId
            }
        }
    },
    deleteQuestion(customLessonId, questionId) {
        return {
            type: lessonConstants.DELETE_QUESTION.REQUEST,
            payload: {
                customLessonId,
                questionId
            }
        }
    },
    generalReset() {
        return {
            type: lessonConstants.GENERAL_RESET.REQUEST,
            payload: {}
        }
    },
    getCustomLessonRelationships(customLessonId, topicId) {
        return {
            type: lessonConstants.GET_CUSTOM_LESSON_RELATIONSHIPS.REQUEST,
            payload: {
                customLessonId,
                topicId
            }
        }
    },
    assignLessonToTopic(customLessonId, topicId, topicRelationshipType, organizationId) {
        return {
            type: lessonConstants.ASSIGN_LESSON_TO_TOPIC.REQUEST,
            payload: {
                customLessonId,
                topicId,
                topicRelationshipType,
                organizationId
            }
        }
    },
    exportLesson(customLessonId, filename) {
        return {
            type: lessonConstants.EXPORT_LESSON.REQUEST,
            payload: {
                customLessonId,
                filename,
            }
        }
    },
    importLesson(customLessonId, file) {
        const formData = new FormData();
        var newfile = new File([file], file.name);
        formData.append(file.name, newfile);
        return {
            type: lessonConstants.IMPORT_LESSON.REQUEST,
            payload: {
                customLessonId,
                formData: formData,
            }
        }
    },
    importScenario(customLessonId, file) {
        const formData = new FormData();
        var newfile = new File([file], file.name);
        formData.append(file.name, newfile);
        return {
            type: lessonConstants.IMPORT_SCENARIO.REQUEST,
            payload: {
                customLessonId,
                formData: formData,
            }
        }
    },
    importScenarioLinear(customLessonId, file) {
        const formData = new FormData();
        var newfile = new File([file], file.name);
        formData.append(file.name, newfile);
        return {
            type: lessonConstants.IMPORT_SCENARIO_LINEAR.REQUEST,
            payload: {
                customLessonId,
                formData: formData,
            }
        }
    },
    
    deleteFromCourse(customLessonId, topicId, topicRelationshipType, organizationId) {
        return {
            type: lessonConstants.DELETE_FROM_COURSE.REQUEST,
            payload: {
                customLessonId,
                topicId,
                topicRelationshipType,
                organizationId
            }
        }
    },
    
    addImage(organizationId, name, file, isSharable, fileReferenceImageUsageType, genderType, actorName, description) {
        const formData = new FormData();
        formData.append(name, file);
        let queryParams = []
        if (isSharable) {
            queryParams.push('sharable=true')
        }

        if (fileReferenceImageUsageType) {
            queryParams.push('fileReferenceImageUsageType=' + fileReferenceImageUsageType)
        }
        if (genderType) {
            queryParams.push('genderType=' + genderType)
        }
        if (actorName) {
            queryParams.push('actorName=' + actorName)
        }
        if (description) {
            queryParams.push('description=' + description)
        }

        var queryParamAsText = ""
        if (queryParams.length > 0) {
            queryParamAsText = "?" + queryParams.join("&");
        }
        
        return {
            type: lessonConstants.ADD_IMAGE.REQUEST,
            payload: {
                organizationId: organizationId,
                name: name,
                formData: formData,
                queryParams: queryParamAsText
            }
        }
    },
    
    getImageReferences(organizationId, queryParams="") {
        return {
            type: lessonConstants.GET_IMAGE_REFERENCES.REQUEST,
            payload: {
                organizationId,
                queryParams: queryParams
            }
        }
    },
    getImage(organizationId, fileReferenceId) {
        return {
            type: lessonConstants.GET_IMAGE.REQUEST,
            payload: {
                organizationId,
                fileReferenceId
            }
            // organization.selectedOrganization, this.state.files.name, this.state.files, true
        }
    },
    
    addAudio(organizationId, name, file, niceName, isSharable, languageId, fileReferenceAudioUsageType, genderType, actorName, description, connectedTextSnippetId) {
        const formData = new FormData();
        formData.append(name, file);

        var queryParams = ["languageId=" + languageId]
        
        if (niceName) {
            queryParams.push('niceName=' + niceName);
        }
        
        if (isSharable) {
            queryParams.push('sharable=true')
        }

        if (fileReferenceAudioUsageType) {
            queryParams.push('fileReferenceAudioUsageType=' + fileReferenceAudioUsageType)
        }
        if (genderType) {
            queryParams.push('genderType=' + genderType)
        }
        if (actorName) {
            queryParams.push('actorName=' + actorName)
        }
        if (description) {
            queryParams.push('actorName=' + description)
        }
        if (connectedTextSnippetId) {
            queryParams.push('connectedTextSnippetId=' + connectedTextSnippetId)
        }

        var queryParamAsText = ""
        if (queryParams.length > 0) {
            queryParamAsText = "?" + queryParams.join("&");
        }

        return {
            type: lessonConstants.ADD_AUDIO.REQUEST,
            payload: {
                organizationId: organizationId,
                name: name,
                formData: formData,
                queryParams: queryParamAsText                
            }
        }
    },
    getAudioReferences(organizationId) {
        return {
            type: lessonConstants.GET_AUDIO_REFERENCES.REQUEST,
            payload: {
                organizationId
            }
        }
    },
    
    getLessonAudioReferences(organizationId, customLessonId) {
        return {
            type: lessonConstants.GET_LESSON_AUDIO_REFERENCES.REQUEST,
            payload: {
                organizationId,
                customLessonId
            }
        }
    },
    
    getAudio(organizationId, fileReferenceId) {
        return {
            type: lessonConstants.GET_AUDIO.REQUEST,
            payload: {
                organizationId,
                fileReferenceId
            }
            // organization.selectedOrganization, this.state.files.name, this.state.files, true
        }
    },
    getScenario(customLessonId, organizationId) {
        return {
            type: lessonConstants.GET_SCENARIO.REQUEST,
            payload: {
                customLessonId,
                organizationId
            }
            // organization.selectedOrganization, this.state.files.name, this.state.files, true
        }
    },
    
    canPublishScenario(customLessonId) {
        return {
            type: lessonConstants.CAN_PUBLISH_SCENARIO.REQUEST,
            payload: {
                customLessonId                
            }
        }
    },
    
    publishScenario(customLessonId, organizationId) {
        return {
            type: lessonConstants.PUBLISH_SCENARIO.REQUEST,
            payload: {
                customLessonId,
                organizationId
            }
        }
    },
    
    getScenarioImages(organizationId, imageReferences) {
        return {
            type: lessonConstants.GET_SCENARIO_IMAGES.REQUEST,
            payload: {
                organizationId,
                imageReferences
            }
            // organization.selectedOrganization, this.state.files.name, this.state.files, true
        }
    },
    createScenario(organizationId, name, nameLanguageId, brief,
                   briefLanguageId, shortDescription, shortDescriptionLanguageId, 
                   backgroundImageUrl, backgroundImageBlob, languageId,
                   defaultL1Id, attachToTopicId, courseGroupId, smartDescription,
                   smartRules, smartPreamble, smartPromptRole,
                   smartCompanyName, lessonType) {
        return {
            type: lessonConstants.CREATE_SCENARIO.REQUEST,
            payload: {
                organizationId,
                name,
                nameLanguageId,
                shortDescription,
                shortDescriptionLanguageId,
                languageId,
                l1Id: defaultL1Id,
                brief: brief,
                briefLanguageId: briefLanguageId,
                sharable: true,
                amountCorrectToPass: 1,
                
                scenarioNodes: [
   
                  ],
                backgroundImageUrl,
                backgroundImageBlob,
                attachToTopicId,
                createdInCourseGroupId: courseGroupId,
                smartDescription,
                smartRules,
                smartPreamble,
                smartPromptRole,
                smartCompanyName,
                lessonType
            }
        }
    },
    // geniusScenario(organizationId, name, nameLanguageId, brief, briefLanguageId, shortDescription, shortDescriptionLanguageId, backgroundImageUrl, backgroundImageBlob, languageId, defaultL1Id, attachToTopicId, smartDescription, smartRules, smartPreamble, smartPromptRole, smartCompanyName, lessonType, languages) {
    //     return {
    //         type: lessonConstants.GENIUS_SCENARIO.REQUEST,
    //         payload: {
    //             organizationId,
    //             name,
    //             nameLanguageId,
    //             shortDescription,
    //             shortDescriptionLanguageId,
    //             languageId,
    //             l1Id: defaultL1Id,
    //             brief: brief,
    //             briefLanguageId: briefLanguageId,
    //             sharable: true,
    //             amountCorrectToPass: 1,
    //            
    //             scenarioNodes: [
    //
    //               ],
    //             backgroundImageUrl,
    //             backgroundImageBlob,
    //             attachToTopicId,
    //             smartDescription,
    //             smartRules,
    //             smartPreamble,
    //             smartPromptRole,
    //             smartCompanyName,
    //             lessonType,
    //             languages
    //         }
    //     }
    // },
    
    updateScenario(scenario, id) {
        return {
            type: lessonConstants.UPDATE_SCENARIO.REQUEST,
            payload: {
                ...scenario,
                customLessonId: id,
            }
        }
    },

    updateScenarioNode(node, id, organizationId, imageFilename, file, isSharable, imageFieldType, customLessonId, 
                       imageReferences, selectedNodeAudioTrackers, audioReferences, scenario) {
        const formData = new FormData();
        formData.append(imageFilename, file);
        return {
            type: lessonConstants.UPDATE_SCENARIO_NODE.REQUEST,
            payload: {
                node,
                customLessonId: id,
                organizationId: organizationId,
                imageFilename: imageFilename,
                formData: formData,
                isSharable: isSharable,
                imageFieldType: imageFieldType,
                imageReferences: imageReferences,
                selectedNodeAudioTrackers,
                audioReferences,
                ...scenario
            }
        }
    },
    updateScenarioNodePosition(customLessonId, node) {
        return {
            type: lessonConstants.UPDATE_SCENARIO_NODE_POSITION.REQUEST,
            payload: {
                customLessonId,
                node
            }
        }
    },

    updateScenarioNodeComment(customLessonId, node) {
        return {
            type: lessonConstants.UPDATE_SCENARIO_NODE_COMMENT.REQUEST,
            payload: {
                customLessonId,
                node
            }
        }
    },
    
    getCustomLessonSupportedLanguages(customLessonId) {
        return {
            type: lessonConstants.GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES.REQUEST,
            payload: {
                customLessonId,
            }
        }
    },
    
    addCustomLessonSupportedLanguage(customLessonId, l1Id) {
        return {
            type: lessonConstants.ADD_CUSTOM_LESSON_SUPPORTED_LANGUAGE.REQUEST,
            payload: {
                customLessonId,
                l1Id
            }
        }
    },
    
    deleteCustomLessonSupportedLanguage(customLessonId, l1Id) {
        return {
            type: lessonConstants.DELETE_CUSTOM_LESSON_SUPPORTED_LANGUAGE.REQUEST,
            payload: {
                customLessonId,
                l1Id
            }
        }
    },
    
    importCustomLessonTranslation(customLessonId, l1Id, file) {
        const formData = new FormData();
        var newfile = new File([file], file.name);
        formData.append(file.name, newfile);
        return {
            type: lessonConstants.IMPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.REQUEST,
            payload: {
                customLessonId,
                l1Id,
                formData: formData,
            }
        }
    },

    exportCustomLessonTranslation(customLessonId, l1Id, filename) {
        return {
            type: lessonConstants.EXPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.REQUEST,
            payload: {
                customLessonId,
                l1Id,
                filename
            }
        }
    },
    

    deleteImage(organizationId, fileReferenceId) {
        return {
            type: lessonConstants.DELETE_IMAGE.REQUEST,
            payload: {
                organizationId,
                fileReferenceId
            }
        }
    },

    
    replaceImage(organizationId, fileReferenceId, file, name, isSharable) {
        const formData = new FormData();
        formData.append(name, file);
        return {
            type: lessonConstants.REPLACE_IMAGE.REQUEST,
            payload: {
                organizationId,
                name,
                formData,
                fileReferenceId,
                isSharable
            }
        }
    },
    
    
    resetCurrentOrganizationLessons(organizationId) {
        return {
            type: lessonConstants.RESET_CURRENT_ORGANIZATION_LESSONS.REQUEST,
            payload: {
                organizationId,
            }
        }
    },

    checkForChanges(customLessonId, organizationId) {
        return {
            type: lessonConstants.CHECK_FOR_CHANGES.REQUEST,
            payload: {
                customLessonId,
                organizationId
            }
        }
    },

    lessonBuilderBusy(isBusy) {
        return {
            type: lessonConstants.LESSON_BUILDER_BUSY.REQUEST,
            payload: {
                isBusy
            }
        }
    },
    
    lessonBuilderCanEditLesson(canEditLesson) {
        return {
            type: lessonConstants.LESSON_BUILDER_CAN_EDIT_LESSON.REQUEST,
            payload: {
                canEditLesson
            }
        }
    },
    lessonBuilderViewMode(lessonBuilderViewMode) {
        return {
            type: lessonConstants.LESSON_BUILDER_VIEW_MODE.REQUEST,
            payload: {
                lessonBuilderViewMode
            }
        }
    },
    getLessonVersions(customLessonId) {
        return {
            type: lessonConstants.GET_LESSON_VERSIONS.REQUEST,
            payload: {
                customLessonId
            }
        }
    },
    
    revertToLessonVersion(organizationId, customLessonId, customLessonVersionId) {
        return {
            type: lessonConstants.REVERT_TO_LESSON_VERSION.REQUEST,
            payload: {
                organizationId,
                customLessonId,
                customLessonVersionId
            }
        }
    },
    
    setSpeechRecognitionL2(l2Id) {
        return {
            type: lessonConstants.SET_SPEECH_RECOGNITION_L2.REQUEST,
            payload: {
                l2Id
            }
        }
    }
    
};


