import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Typography,
} from '@material-ui/core'
import {
} from "@material-ui/icons";

const styles = theme => ({
    footer: {
        textAlign: 'center',
        margin: '30px 0px',
    },
    list: {
        margin: '0',
        padding: '0',
        listStyle: 'none',
    },
    listItem: {
        display: 'inline-block',
        margin: '0px 10px'
    },
    link: {
        textDecoration: 'none',
    },
    linkText: {
        textDecoration: 'none',
        color: '#bcbcbc',
        transition: 'all 200ms',
        '&:hover': {
            color: '#50B4B9'

        }
    }
});

class Footer extends React.Component {
    state = {
    };

    render() {
        const { classes } = this.props;
        
        return (
            <div className={classes.footer}>
                <ul className={classes.list}>
                    <li className={classes.listItem}><a href="https://www.capeesh.com/about-us/privacy/" target="_blank" className={classes.link}><Typography className={classes.linkText}>Privacy</Typography></a></li>
                    <li className={classes.listItem}><a href="https://itunes.apple.com/us/app/capeesh-language-learning/id1390963312" target="_blank" className={classes.link}><Typography className={classes.linkText}>iOS App</Typography></a></li>
                    <li className={classes.listItem}><a href="https://play.google.com/store/apps/details?id=com.capeesh.personalized.language.learning" target="_blank" className={classes.link}><Typography className={classes.linkText}>Android App</Typography></a></li>
                    <li className={classes.listItem}><a href="https://www.capeesh.com" target="_blank" className={classes.link}><Typography className={classes.linkText}>Capeesh.com</Typography></a></li>
                </ul>
            </div>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const {} = state;
    return {
        
    };
}

const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles)
);

export default enhance(Footer)