import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
import CourseGroupCard from "../../Views/Private/Courses/CourseGroupCard";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles(theme => ({
  
}));

const CourseGroupSelector = (props) => {
  const classes = useStyles();
  
  const handleCancel = () =>  {
    props.handleCancel();
  }

  const handleAddCourseGroup = (courseGroupId) => {
    props.onAddCourseGroupId(courseGroupId);
  };
  
  return (
    <div>
      <Dialog
        fullScreen={false}
        maxWidth={'md'}
        open={props.open}
        onClose={() => handleCancel()}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <div id="contained" style={{visibility: 'visible'}}>
              <Grid container spacing={1}>
              {
                props.availableCourseGroups.filter(courseGroup => props.classroomCourseGroupIds?.indexOf(courseGroup.id) === -1)?.map((courseGroup, index) => 
                  <React.Fragment>
                    <Grid item xs={12}>
                      <CourseGroupCard
                        courseGroup={courseGroup}
                        index={index}
                        slideDirection={'right'}
                        handleAddCourseGroup={handleAddCourseGroup}
                      />
                    </Grid>
                    </React.Fragment>
                )
              }
              </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{visibility: 'visible', marginBottom:'10px', marginTop:'10px'}}>
          <Button
            variant='outlined'
            onClick={handleCancel}
            color='primary'
            style={{
              marginLeft:'10px'
            }}
          >
            Cancel
          </Button>
          <div style={{flex: '1 0 0'}} />
          
        </DialogActions>
      </Dialog>
    </div>
    );
}

CourseGroupSelector.propTypes = {

};

function mapStateToProps(state) {
  return {

  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseGroupSelector)
