import React, {useEffect, useState} from "react";
import {
    Grid,
} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import GuideListenBasics from "../../../../assets/images/GuideListenBasics.png";


const useStyles = makeStyles(theme => ({
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    //marginRight: '15px',
    cursor: 'pointer',
  },
  leftBox: {
/* Gray 3 */
background: '#EFEFEF',
border: '1px solid #D1D1D6',
boxSizing: 'border-box',
boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
borderRadius: '20px'
  },
  leftPanel: {
    minWidth: '400px'
  },
  mainContainer: {
    paddingLeft: '25px',
    paddingRight: '25px'
  }, 
  rightPaper: {
    /* White */
  //  marginLeft: '20px',
marginTop: '30px',
background: '#FFFFFF',
/* Gray 3 */

border: '1px solid #D1D1D6',
boxSizing: 'border-box',
borderRadius: '15px',
  }


}));

const TutorialTestImage = (props) => {
    const classes = useStyles();
    const tutorialParagraph = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontSize: '16px', marginBottom: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialSubHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', marginTop: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px'}} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', marginBottom: '20px'}}>
            {text}
          </Typography>
        </Grid>

      )
     }


    return (
<Grid container style={{paddingLeft: '50px', paddingTop: '40px', paddingRight: '20px'}}> 
            {tutorialHeader("The Test Stage")}
          <Grid item xs={12} sm={12} md={12} style={{display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: '35px'}}>
                <iframe src="https://player.vimeo.com/video/689320556?h=36cb0d4727" style={{alignItems: 'center'}} width="768" height="432"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Grid>
            {tutorialSubHeader("How it works")}
            {tutorialParagraph("It tests the spelling and comprehension of a word, a phrase, sentence or an instruction. It always contains a matching picture.")}
            {tutorialParagraph("The test stage is typically, but not always, used after a word, phrase or concept has previously been introduced. The stage has multiple different interaction methods.")}
       
            {tutorialHeader("Three interaction types")}
            {tutorialSubHeader("1. Keyboard")}
            {tutorialParagraph("The keyboard interaction tasks the learner with spelling out a specific word. To use it, ensure you have written your word or sentence in the L2 Interaction textbox and then choose Keyboard from the 'L2 Interaction' dropdown list.")}
            {tutorialParagraph("Next, highlight the area of the sentence that you would like to use for the keyboard test. For instance select an entire word, or a part of the sentence.")}
            {tutorialParagraph("With the word or phrase selected, an 'Add Distractor' button will appear. Click this button.")}
            {tutorialParagraph("This will highlight your selection in yellow. When in the app, the learner will see a gap in the sentence which they have to type out using the keyboard.")}
            {tutorialSubHeader("What happens if I click the 'Add Alternative' button that appears when highlighting a selection area?")}
            {tutorialParagraph("Usually you will not want to do this in a keyboard test. However, it can be used to add synonyms that the test will also accept as correct answers.")}

            {tutorialSubHeader("2. Multiple Choice")}
            {tutorialParagraph("The distractors interaction is simply a multiple choice test. The user must choose the correct answer from the multiple answers given.")}
            {tutorialParagraph("As with the keyboard test, to create a distractor, simply select the area of the L2 sentence that you wish to turn into a gap and click the Add Distractor button.")}
            {tutorialParagraph("At this point, a gap has been created in the sentence, and the correct answer given as an answer option for the learner. .")}
            {tutorialSubHeader("Add additional answers for the learner to choose between")}
            {tutorialParagraph("Next, you can alternative distractor answers. To do this, click the Add Alternative button. This only appears when a distractor is selected within the sentence.")}
            {tutorialParagraph("Each distractor can be marked as correct or incorrect.")}

            {tutorialSubHeader("3. Sentence Builder")}
            {tutorialParagraph("The final interaction option in the sentence builder. This tasks the learner with building up the sentence by selecting the word blocks in the correct order.")}
            {tutorialParagraph("The sentence can be split into individual letters, syllables, words or sentence chunks. Choose between these by sliding the selector bar.")}

            {tutorialHeader("L2 Voice")}
            {tutorialParagraph("It is possible for the Test Stage L2 text to be spoken out. Choose between never speaking it out, speaking it out when the stage loads, and playing it out when the learner has completed the stage test.")}
            {tutorialParagraph("A computer-generated voice will play until it a human voice is added to the stage. For information on how to add human voice, see the 'What is a lesson stage?' page of this guide.")}

            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '5px'}}>
              <img style={{ flex: 1, color: 'black', height: '400px', textAlign: 'center', alignItems: 'center'}} src={GuideListenBasics} />          
            </Grid>
        </Grid>
    );
    }

    TutorialTestImage.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(TutorialTestImage)