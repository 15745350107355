import React, {useEffect, useState} from "react";
import {
    Grid,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    //marginRight: '15px',
    cursor: 'pointer',
  },
  leftBox: {
/* Gray 3 */
background: '#EFEFEF',
border: '1px solid #D1D1D6',
boxSizing: 'border-box',
boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
borderRadius: '20px'
  },
  leftPanel: {
    minWidth: '400px'
  },
  mainContainer: {
    paddingLeft: '25px',
    paddingRight: '25px'
  }, 
  rightPaper: {
    /* White */
  //  marginLeft: '20px',
marginTop: '30px',
background: '#FFFFFF',
/* Gray 3 */

border: '1px solid #D1D1D6',
boxSizing: 'border-box',
borderRadius: '15px',
  }


}));

const TutorialRulecard = (props) => {
    const classes = useStyles();
    const tutorialParagraph = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontSize: '16px', marginBottom: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialSubHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', marginTop: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px'}} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', marginBottom: '20px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

    return (
      <Grid container style={{paddingLeft: '50px', paddingTop: '40px', paddingRight: '20px'}}> 
      {tutorialHeader("The Rulecard Stage")}
    <Grid item xs={12} sm={12} md={12} style={{display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
      <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: '35px'}}>
          <iframe src="https://player.vimeo.com/video/688766534?h=06c323642d" style={{alignItems: 'center'}} width="768" height="432"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </div>
    </Grid>
      {tutorialSubHeader("How it works")}
      {tutorialParagraph("Explain rules, instructions and grammar, or present longer pieces of text and information in either L1 or L2.")}
      {tutorialSubHeader("How to make it?")}
      {tutorialParagraph("- Enter an optional L2 title")}
      {tutorialParagraph("- Add a rule. Each rule is its own box and will appear one after another")}
      {tutorialParagraph("- Write or insert the rule text in either L1 or L2")}
      {tutorialParagraph("- Each rule should be in only L1 or L2, including eventual distractors")}
      {tutorialParagraph("- Select interaction. Reading has no interaction. The other interaction types are similar to Test stage.")}
      {tutorialParagraph("- Add as many rules as you like.")}

      
  </Grid>

    );
    }

    TutorialRulecard.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(TutorialRulecard)