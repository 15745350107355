import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {organizationActions, snackbarActions} from "_state/actions";
import ChipInput from 'material-ui-chip-input';
import EmailValidator from 'email-validator';
import {IntlProvider, FormattedMessage} from 'react-intl';
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {CapeeshColors} from "../assets/ColorPalette";

import {InviteRolesToDisplayName, ReverseInviteRolesToDisplayName} from "../Logic/OrganizationAssetConstants";

const useStyles = makeStyles(theme => ({
    chipRejected: {
        backgroundColor: "#e07676"
    },
    noEntries: {
        padding: '25px 0px'
    },
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

const EmailInvite = (props) => {
    const classes = useStyles();
    const {organization, metadata, inviteEmailsOpen, handleInviteEmailsClose, userGroupId, title, description, fixedRoles, availableRoles, callBackFunctionOnAdd} = props;
    
    const [emails, setEmails] = useState([]);
    const [rejectedEmails, setRejectedEmails] = useState([]);
    const [leftoverChip, setLeftoverChip] = useState('');
    
    // roles are set with display names, and then mapped back to the actual user role when sent to the api
    const [inviteRoleDisplayName, setInviteRoleDisplayName] = useState([]);
    const [inviteRolesAvailable, setInviteRolesAvailable] = useState([]); // with names from display name mapper 
    
   
    useEffect(() => {
        if (!organization.addingStudents) {
            setEmails([]);
            setRejectedEmails([]);
        }
        if (!organization)
            return;

        if (organization.inviteRolesAvailable) {
            let inviteUserRoles = [...organization.inviteRolesAvailable];
            if(availableRoles && availableRoles.length > 0) {
                inviteUserRoles = inviteUserRoles.filter(x => availableRoles.find(y => y == x));
            }
            
            setInviteRoleDisplayName([InviteRolesToDisplayName[inviteUserRoles[0]]]);
            setInviteRolesAvailable(inviteUserRoles.map(role => InviteRolesToDisplayName[role]));
        }
    }, []);
    
    
    useEffect(() => {
        if (organization.inviteRolesAvailable) {
            let inviteUserRoles = [...organization.inviteRolesAvailable];
            if(availableRoles && availableRoles.length > 0) {
                inviteUserRoles = availableRoles.filter(x => inviteUserRoles.find(y => y === x));
            }

            setInviteRoleDisplayName([InviteRolesToDisplayName[inviteUserRoles[0]]]);
            setInviteRolesAvailable(inviteUserRoles.map(role => InviteRolesToDisplayName[role]));
        }
    }, props.availableRoles) 
    
    const handleSendInviteStudentClick = () => {
        let uniqueEmails = emails;

        if (leftoverChip.length > 0 && EmailValidator.validate(leftoverChip)) {
            uniqueEmails.push(leftoverChip);
        }
        uniqueEmails = [...new Set(uniqueEmails)];
        
        let roles = [];
        if ( fixedRoles && fixedRoles.length > 0) {
            roles = fixedRoles;
        } else {
            roles = [ReverseInviteRolesToDisplayName[inviteRoleDisplayName]];
        }
        
        if (callBackFunctionOnAdd) {
            callBackFunctionOnAdd(organization.selectedOrganization, uniqueEmails, roles , userGroupId);
        } else {
            props.inviteStudentsToSchool(organization.selectedOrganization, uniqueEmails, roles, userGroupId);    
        }
        handleInviteEmailsClose();
    };

    const createChips = (newEmails) => {
        const {valid, invalid} = validateEmails(newEmails);
        const updatedValidEmails = [...emails, ...valid];
        setEmails(updatedValidEmails);
        
        if(invalid !== "") {
            const updatedRejectedEmails = [...rejectedEmails, ...invalid];
            setRejectedEmails(updatedRejectedEmails);
        }
    };

    const handlePasteChips = (chips) => {
        chips.preventDefault();
        let emails = chips.clipboardData.getData('Text');
        createChips(emails);
    };

    const handleOnBeforeAdd = (chip, didClick) => {
        const chips = validateEmails(chip);
        return chips.valid !== "";
    };
    

    const validateEmails = (emails) => {
        emails = emails.replace(';', ' ');
        emails = emails.replace(',', ' ');
        emails = emails.split(/\s+/);
        
        let chips = [];
        let rejectedEmails = [];
        emails.forEach(email => {
            EmailValidator.validate(email) ? chips.push(email) : email !== "" && rejectedEmails.push(email);
        });
        return {
            valid: chips,
            invalid: rejectedEmails
        };
    };

    const handleOnDelete = (event, index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    const handleOnDeleteRejected = (index) => {
        setRejectedEmails(rejectedEmails.filter((_, i) => i !== index))
    };

    const handleInviteRoleChange = (event) => {
        setInviteRoleDisplayName([event.target.value]);
    };
    
    return (
        
        <Dialog
            open={inviteEmailsOpen}
            onClose={handleInviteEmailsClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
                {
                    inviteRolesAvailable.length > 0 &&
                    <Grid onPaste={(chips) => handlePasteChips(chips)} item xs={12}>
                        {
                            rejectedEmails.length > 0 &&
                            <div>
                                <div>
                                    <Typography component="p">
                                        <FormattedMessage id="dashboard.student.invite"
                                                          defaultMessage="Invite new students"/>
                                    </Typography>
                                    {
                                        rejectedEmails.map((email, index) => (
                                            <Chip onDelete={(email) => handleOnDeleteRejected(index)}
                                                  label={email}
                                                  key={index} className={classes.chipRejected}/>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                        <ChipInput
                            label={<FormattedMessage id="dashboard.student.typeorpaste"
                                                     defaultMessage="Type of paste emails."/>}
                            className={classes.root}
                            value={emails}
                            onAdd={(chip) => createChips(chip)}
                            onDelete={(chip, index) => handleOnDelete(chip, index)}
                            newChipKeyCodes={[13, 32, 188, 186]}
                            fullWidth={true}
                            onKeyUp={(chip) => setLeftoverChip(chip.target.value)}
                            onBeforeAdd={(chip) => handleOnBeforeAdd(chip, false)}
                            blurBehavior='add'
                            InputProps={{
                                autoFocus: true
                            }}
                        />
                        {
                            (!fixedRoles || fixedRoles.length > 1) && (!inviteRolesAvailable || inviteRolesAvailable.length > 1) &&
                            <FormControl>
                                <InputLabel htmlFor="age-simple">{<FormattedMessage
                                    id="dashboard.student.role" defaultMessage="Role"/>}</InputLabel>
                                <Select
                                    name='inviteRoles'
                                    value={inviteRoleDisplayName}
                                    onChange={handleInviteRoleChange}
                                    input={<Input id="select-multiple-checkbox"/>}
                                    renderValue={selected => selected}
                                >
                                    {
                                        inviteRolesAvailable.map(role => (
                                            <MenuItem key={role} value={role}>
                                                <Checkbox checked={inviteRoleDisplayName.indexOf(role) > -1}/>
                                                <ListItemText style={{ color: role === 'Capeesh Admin' ? CapeeshColors.OrangeBright : '#000000' }} primary={role}/>
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleInviteEmailsClose} 
                        color="primary"
                >
                    <FormattedMessage id="globalwords.cancel" defaultMessage="Cancel"/>
                </Button>
                <Button onClick={handleSendInviteStudentClick} 
                        variant={"contained"}
                        disabled={emails.length === 0}
                        color="primary"
                >
                    <FormattedMessage id="globalwords.invite" defaultMessage="Invite"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EmailInvite.propTypes = {};

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        metadata: state.metadata,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        inviteStudentsToSchool: (organizationId, uniqueEmails, inviteRoles, userGroupId) => dispatch(organizationActions.inviteStudentsToSchool(organizationId, uniqueEmails, inviteRoles, userGroupId)),
        enqueueSnackbar:(notification) => dispatch(snackbarActions.enqueueSnackbar(notification)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailInvite)