import { configWithAuth } from '../helpers';
import { makeGetRequest, makePostRequest, makePatchRequest, makePutRequest } from "../helpers/api";

export const userService = {
    getAll: () => makePostRequest('user/get-all', configWithAuth()),
    getUserInfo: () => makeGetRequest('admin/user', configWithAuth()),
    getById: (id) => makeGetRequest('user' + id, configWithAuth()),
    updateUser: (body) => makePutRequest('user', JSON.stringify(body), configWithAuth()),
    getUserStats: (payload) => makeGetRequest(`admin/stats/organization/${payload.organizationId}/user/${payload.userId}`, configWithAuth()),

    resendInvite: (payload) => makePostRequest('admin/organization/' + payload.organizationId + '/user/'+ payload.userId + '/resendinvite', JSON.stringify(payload), configWithAuth()),
    adminUpdateUser: (payload) => makePatchRequest('admin/user/edit/', JSON.stringify(payload), configWithAuth()),
    adminUpdateUserMasterOrganization: (payload) => makePostRequest(`admin/user/edit/${payload.userId}/master/organization/${payload.organizationId}`, JSON.stringify(payload), configWithAuth()),
    updateUserOrganizationRole: (payload) => makePutRequest('admin/user/edit/role/', JSON.stringify({organizationId: payload.organizationId, userId: payload.userId, schoolRole: payload.userRole}), configWithAuth()),
    createUserMessage: (payload) => makePostRequest(`admin/user/${payload.userId}/create/message`, JSON.stringify(payload), configWithAuth()),

    getUserActivity : (payload) => makePostRequest(`admin/stats/organization/${payload.organizationId}/user/${payload.userId}/activity`, JSON.stringify({fromDate: payload.fromDate, toDate: payload.toDate}), configWithAuth()),

    // course group (Courses) progression for users
    getUserCourseGroups: (payload) => makeGetRequest(`admin/organization/${payload.organizationId}/user/${payload.userId}/course/group`, configWithAuth()),
    getUserCourseGroupProgression: (payload) => makeGetRequest(`admin/organization/${payload.organizationId}/user/${payload.userId}/course/group/${payload.courseGroupId}/progression`, configWithAuth()),
    
    // lesson progressions (all)
    getUserLessonProgressions: (payload) => makeGetRequest(`admin/organization/${payload.organizationId}/user/${payload.userId}/lesson/progressions`, configWithAuth()),
    
};