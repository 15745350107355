export function configWithAuth(contentType = "application/json", ifModifiedSince= null) {
    // return authorization header with jwt token
    let authToken = JSON.parse(localStorage.getItem('authToken'));   

    let config = {
        headers: {
            "Content-Type": contentType,
            "Authorization": "Bearer " + authToken.token
        },
    };
    
    if (ifModifiedSince != null) {
        config.headers["If-Modified-Since"] = ifModifiedSince
    }
    
    return config;
}

export function configWithoutAuth() {
    let config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    
    return config;
}


export function configACCWithoutAuth() {
    let config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    return config;
}



