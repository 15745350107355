import React, {useState, useRef} from 'react';
import {Typography,TextField,makeStyles,Box,} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import {AutoCourseCreatorDialoguePlotTypes} from "../../../../../Logic/AutoCourseCreationConstants"
import ButtonBase from "@mui/material/ButtonBase";

const useStyles = makeStyles(theme => ({
    stagePlotType: {
        display: 'inline-block', color: "#35323C",
        fontFamily: "Nunito",
        fontSize:14,
        fontStyle: "normal",
        fontWeight:400,
        letterSpacing:0.41,borderRadius:5, border: "1px solid var(--Gray-1, #8E8E93)", marginTop: '10px', padding: '5px'
    },
    stagePlotTypeSelected: {
        display: 'inline-block', color: "white",
        fontFamily: "Nunito",
        fontSize:14,
        fontStyle: "normal",
        fontWeight:400,
        letterSpacing:0.41,borderRadius:5, border: "1px solid var(--Gray-1, #8E8E93)", marginTop: '10px', padding: '5px'
        ,background: '#44A6EF'
    }
}));

const ACCStageSettingsDialogue = (props) => {
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogExistingText, setDialogExistingText] = useState("");
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [dalogueText, setDialogueText] = useState(false);
  const classes = useStyles();

  const handleTextChange = (event) => {
    setDialogueText(event.target.value)
  }

  const handleSelectChangePlot = (element) => {
    console.log("change plot text", element.target.value)
    props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "plotText", element.target.value)
   }

   const handleClickOpen = (roleType, title, message, existingText = "") => {
    setOpenRoleDialog(true);
    setDialogTitle(title)
    setDialogMessage(message)
    setDialogExistingText(existingText)
    //handleTextFocus(roleType, props.selectedLesson.lessonPlanConfigurationDto.dialogue., roleType)
  };

  const handleCloseModal = () => {
    setOpenRoleDialog(false);
    setDialogExistingText("")
  };

  const handleSaveModal = () => {
    setOpenRoleDialog(false);
    console.log("change plot text", dalogueText)

    props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "plotText", dalogueText)
  };

  console.log("props", props.selectedLesson)

  return (
<Grid container spacing={1} style={{borderRadius:5, background: "#F8F8F8",}}>
  <Dialog open={openRoleDialog} onClose={handleCloseModal}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMessage}
          </DialogContentText>
          <TextField multiline defaultValue={dialogExistingText} autoFocus margin="dense" id="name" fullWidth variant="standard" 
            onChange={handleTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{textTransform: 'none'}}>Cancel</Button>
          <Button onClick={handleSaveModal} style={{textTransform: 'none'}}>OK</Button>
        </DialogActions>
      </Dialog>
  <Grid item xs={12} style={{}}>
    <Typography style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '16px', lineHeight:'19px', color: 'black', letterSpacing: '0.31px', textAlign:'left'}}>
      Choose your type of dialogue
    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Typography style={{color: "#000", fontFamily: "Nunito", fontSize:16, fontStyle: "normal", fontWeight:400}}>
      A conversation between two people. Choose the correct answers throughout the dialogue.
    </Typography>
  </Grid>
    <Grid item xs={12}>
        <Typography style={{color: "#000", fontFamily: "Nunito", fontSize:16, fontStyle: "normal", fontWeight:400}}>
            There are various different types of dialogue to choose from. Select one of the options below or write your own.
        </Typography>
    </Grid>
    
    <Grid container style={{marginBottom: '20px'}}>
        {AutoCourseCreatorDialoguePlotTypes.map((exercise, index) => (
            <Grid item  style={{marginRight: '20px'}}>
                <ButtonBase onClick={() => props.onToggleStagePlot("Dialogue", exercise.plotType, props.selectedLesson.lessonPlanConfigurationDto.exercises.some(ex => ex.exerciseType === "Dialogue" && ex.plotType === exercise.plotType), true, "FillInBlanks" )}  >
                    <Typography className={
                        props.selectedLesson.lessonPlanConfigurationDto.exercises.some(ex =>
                            ex.exerciseType === "Dialogue" &&
                            ex.plotType === exercise.plotType) ?
                            classes.stagePlotTypeSelected : classes.stagePlotType
                    }>
                        {exercise.friendlyText}
                    </Typography>

                </ButtonBase>
            </Grid>
        ))}
    </Grid>
    

  
          <Grid item xs={12} style={{marginBottom: '10px'}}>
        <Typography style={{  fontFamily: 'Rubik', fontWeight: 500, fontSize: '16px', lineHeight:'19px', color: 'black', letterSpacing: '0.41px', textAlign:'left', marginTop: '20px'}}>
          Or write your own dialogue
        </Typography>
      </Grid>
    <Button onClick={(e) => props.onGetStageDetails(e)} style={{textTransform: 'none', fontSize: '11px', background: '#A0439F', color: 'white', fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '400', padding: '10px', lineHeight: '12px', marginLeft: '10px'}}>
        {props.isDialogueGenerating === true ? <CircularProgress style={{color: 'white', height: '20px', width: '20px'}}/> : (props?.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.length > 0 ? "Generate more dialogue suggestions" : "Generate dialogue suggestions") }
    </Button>
      <Grid item xs={12} style={{marginTop: '5px'}}>
      <FormControl fullWidth variant="outlined" style={{marginTop: '10px'}}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            style={{height: '30px', color: '#35323C', fontFamily: 'Nunito', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '19px', letterSpacing: '0.41px', justifyContent: 'center', paddingTop: '5px', maxWidth: '100%', }}

            value={props.selectedLesson.lessonPlanConfigurationDto?.dialogue?.plot}
            onChange={handleSelectChangePlot}
            renderValue={(selected) => {
              return (
                <Box 
                  component="span"  
                  sx={{ 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    maxWidth: 'calc(100% - 24px)', // 24px is icon's width
                  }}
                >
                  {selected}
                </Box>
              );
            }}
          >
            <div>
              <Button onClick={()=>handleClickOpen("plotText", "Write the lesson plot", "Write the plot that the dialogue will be based on. Give as much detail as you wish on what should happen in the story.")} style={{textTransform: 'none', width: '100%', textAlign: 'left', textDecoration: 'underline'}}>Write a new plot</Button>
            </div>
            {props.selectedLesson.lessonPlanConfigurationDto?.dialogue?.possiblePlots.map((plot) => (
              <MenuItem key={plot} value={plot} style={{color: '#35323C', fontFamily: 'Nunito', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '19px', letterSpacing: '0.41px'}}
              >                        
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {plot}
              <IconButton size="small" onClick={()=>handleClickOpen("plotText", "Write the lesson plot", "Write the plot that the lesson will be based on. Give as much detail as you wish on what should happen in the story.", plot)}>
                <EditIcon fontSize="inherit" />
              </IconButton>
            </div> </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

</Grid>
  );
};

ACCStageSettingsDialogue.propTypes = {};

const mapStateToProps = (state) => {
  return {
    languages: state.metadata.metadata.languages,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCStageSettingsDialogue)