import React, {useEffect, useState} from "react";
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import {getFlagAssetForLanguageId} from "../../../../Logic/FlagUnicodeLogic";
import Switch from '@mui/material/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {ScenarioL1L2SelectorOptions} from "../../../../Logic/ScenarioConstants";

const useStyles = makeStyles(theme => ({
  textFields: {
    background: 'white'
  },
}));

// Textfield with L1 / L2 selector and appropriate flags
// Two modes: allow language selection change, or read only language selection. Select mode using canChangeLanguage (bool)
// Mode 1: If 'read only' mode, pass into soloLanguageId the language ID of the textfield
// Mode 2 If 'allow language selection' mode, pass in the l1Id and l2Id attributes instead
// Mode 2 current flag to display determined by displayL1OrL2

// Can specify if wish to be fullwidth or not. Defaults to false if not provided
// Can optionally specify fixed width of component. Will default to 428px if not specified
// Do not pass in both fullwidth of true and width, since fullWidth would be ignored in this case.
// onBlur used for situations where removing all text should delete an empty answer option behind the scenes, such as Speak stages


const cleanString = (event) => {
  // Remove newlines and replace with a space
  const noNewlines = event.target.value.replace(/(?:\r\n|\r|\n)/g, ' ');

  // Remove double spaces
  const noDoubleSpaces = noNewlines.replace(/\s\s+/g, ' ');

  // Trim the string
  //const trimmed = noDoubleSpaces.trim();

  
  const customEvent = {
    ...event,
    target: {
      ...event.target,
      value: noDoubleSpaces,
    },
  };
  return customEvent 
}
const TextFieldWithLanguageSelector = (props) => {
    const classes = useStyles();
    return ( 
      <TextField
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}} 
        className={classes.textFields}  
        fullWidth={props.fullWidth}
        multiline 
        style={props.fullWidth ? null : {width: props.width !== undefined ? props.width  : '428px'}}
        value={props.text}
        disabled={props.disabled}
        onChange={(event) => props.onChange(cleanString(event))}  
        onBlur={props.onBlur}
        InputProps={{
        disableUnderline: true, shrink: "false",
        startAdornment: (
          <InputAdornment position="start">
            {
              props.canChangeLanguage === true ?
                <Select
                  disableEnforceFocus sx={{
                  width: '70px',
                  height: '60px',
                  boxShadow: 'none',
                  outline: 'none',
                  '.MuiOutlinedInput-notchedOutline': {border: 'none', outline: 'none'}
                }}
                  disableUnderline
                  value={props.displayL1OrL2}

                  autoWidth
                  onChange={(e) => props.onChangeLanguageLanguage(e)}
                >
                  <MenuItem disabled={props.disabled} value={ScenarioL1L2SelectorOptions.L1}>
                    <img src={getFlagAssetForLanguageId(props.l1Id)} width="25" alt={"L1"}/>

                  </MenuItem>
                  <MenuItem disabled={props.disabled} value={ScenarioL1L2SelectorOptions.L2}>
                    <img src={getFlagAssetForLanguageId(props.l2Id)} width="25" alt={"L2"}/>
                  </MenuItem>
                </Select>
                :
                <img src={getFlagAssetForLanguageId(props.soloLanguageId)} width="25" alt={"Language"}/>
            }
          </InputAdornment>
        ),
        style:{height:"100%"}
      }}
     />
    );
}
  
TextFieldWithLanguageSelector.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(TextFieldWithLanguageSelector)