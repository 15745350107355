import { userConstants } from '../actions';
import { authConstants } from '../actions';
import { translatorConstants } from '../actions';

const initialState = {
    translators: [],
    organizationTranslatorJobs: [],
    translatorsForL1: [],
    translatorJobs: []
};

export function translator(state = {}, action) {
    switch (action.type) {
        case translatorConstants.GET_TRANSLATION_JOBS.REQUEST:
            return {
                ...state,
            };
        case translatorConstants.GET_TRANSLATION_JOBS.SUCCESS:
            return {
                ...state,
                organizationTranslatorJobs: action.data
            };
        case translatorConstants.GET_TRANSLATION_JOBS.FAILURE:
            return {
                ...state,
                error: action.error
            };
        case translatorConstants.CREATE_TRANSLATOR_JOB.REQUEST:
            return {
                ...state,
            };
        case translatorConstants.CREATE_TRANSLATOR_JOB.SUCCESS:
            return {
                ...state,
            };
        case translatorConstants.CREATE_TRANSLATOR_JOB.FAILURE:
            return {
                ...state,
                error: action.error
            };
        case translatorConstants.GET_TRANSLATORS.REQUEST:
            return {
                ...state,
            };
        case translatorConstants.GET_TRANSLATORS.SUCCESS:
            return {
                ...state,
                translators: action.data
            };
        case translatorConstants.GET_TRANSLATORS.FAILURE:
            return {
                ...state,
                error: action.error
            };
        case translatorConstants.GET_TRANSLATORS_FOR_L1.REQUEST:
            let compatibleTranslators = []
            state.translators && state.translators.forEach(element => {
                element.translatorLanguages.forEach(lang => {
                    if(action.payload.language === lang) {
                        compatibleTranslators.push(element)
                    }
                });
            });
                return {
                    ...state,
                    translatorsForL1: compatibleTranslators
                };
        case translatorConstants.GET_TRANSLATORS_FOR_L1.SUCCESS:
            return {
                ...state,
                translators: action.data
            };
        case translatorConstants.GET_TRANSLATORS_FOR_L1.FAILURE:
            return {
                ...state,
                error: action.error
            };
        case translatorConstants.ADD_TRANSLATOR_LANGUAGE.REQUEST:
            return {
                ...state,
            };
        case translatorConstants.ADD_TRANSLATOR_LANGUAGE.SUCCESS:
            return {
                ...state,
            };
        case translatorConstants.ADD_TRANSLATOR_LANGUAGE.FAILURE:
            return {
                ...state,
                error: action.error
            };
        case translatorConstants.GET_TRANSLATOR_JOBS.REQUEST:
            return {
                ...state,
            };
        case translatorConstants.GET_TRANSLATOR_JOBS.SUCCESS:
            return {
                ...state,
                translatorJobs: action.data
            };
        case translatorConstants.GET_TRANSLATOR_JOBS.FAILURE:
            return {
                ...state,
                error: action.error
            };
        case translatorConstants.UPDATE_JOB.REQUEST:
            return {
                ...state,
            };
        case translatorConstants.UPDATE_JOB.SUCCESS:
            return {
                ...state,
                //translatorJobs: action.data
            };
        case translatorConstants.UPDATE_JOB.FAILURE:
            return {
                ...state,
                error: action.error
            };
        case authConstants.LOGOUT.SUCCESS:
            return initialState;
        default:
            return state
    }
}