import {authActions, authConstants, backgroundJobsConstants} from '../actions';

const initialState = {
    topicJobs: [],
    generatingNewLang: false,
    refreshActivated: false,
    updatingModel: false
};

export function backgroundJobs(state = initialState, action) {
    switch (action.type) {
        case backgroundJobsConstants.UPDATE_TOPIC_JOB.SUCCESS:
            return {
                ...state,
                topicJobs: state.topicJobs.filter(e => e.topicId != action.data.topicId).push(action.data)
            };

        case backgroundJobsConstants.UPDATE_TOPIC_JOB.FAILURE:
            return {
                ...state,
                error: action.message
            };

        case backgroundJobsConstants.GET_TOPIC_JOBS.REQUEST:
            return {
                ...state,
            };

        case backgroundJobsConstants.GET_TOPIC_JOBS.SUCCESS:
            return {
                ...state,
                topicJobs: action.data,
            };

        case backgroundJobsConstants.GET_TOPIC_JOBS.FAILURE:
            return {
                ...state,
                topicJobs: []
            };
        case backgroundJobsConstants.WAIT_FOR_JOBS.REQUEST:
            return {
                ...state,
                generatingNewLang: true,
                updatingModel: true
            };

        case backgroundJobsConstants.WAIT_FOR_JOBS.SUCCESS:
            return {
                ...state,
                generatingNewLang: false,
                refreshActivated: true,
                updatingModel: false,
                // topicJobs: action.data,
            };

        case backgroundJobsConstants.WAIT_FOR_JOBS.FAILURE:
            return {
                ...state,
                topicJobs: [],
                generatingNewLang: false,
                updatingModel: false
            };

        case backgroundJobsConstants.REMOVE_TOPIC_JOB.REQUEST:
            return {
                ...state,
                topicJobs: state.topicJobs.filter(e => e.topicId != action.data.topicId)
            };
        case backgroundJobsConstants.GENERAL_RESET.REQUEST:
            return {
                ...state,
                generatingNewLang: false,
                updatingModel: false

            };
        case backgroundJobsConstants.GENERAL_RESET.SUCCESS:
            return {
                ...state,
            };
        case backgroundJobsConstants.GENERAL_RESET.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case authConstants.LOGOUT.SUCCESS:
            return initialState;

        default:
            return state
    }
}