import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Grid,
  Typography,
} from '@material-ui/core'
import {organizationActions, usergroupActions} from '_state/actions';
import {userActions} from "../../_state/actions";
import {history} from "_state/helpers";

import DashboardImage from "../../assets/images/dashboard/dashboard_img.png";
import CreateImage from "../../assets/images/dashboard/create_img.png";
import {CapeeshColors} from "../../assets/ColorPalette";
import {Button} from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import OrganizationSelectFooter from "../../Components/Footers/OrganizationSelectFooter";
import OrganizationChangeDialog from "./Organization/Components/OrganizationChangeDialog";
import Set from "@iconify-icons/mdi/set";
import {FormattedMessage} from "react-intl";


const useStyles = makeStyles(theme => ({
    root: {
      marginLeft: '80px',
      marginRight: '80px',
      backgroundColor: 'transparent',
    },
    main: {
      backgroundColor: CapeeshColors.EggShell1,
      backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(238, 237, 234  ) 1px, transparent 0)',
      backgroundSize: '12px 12px',
      marginTop: "-40px", 
      height: 'calc(100vh - 65px)'
    },
    
    goToAreaButton: {
      marginTop: -7,
      height: '40px',
      align: 'right',
      minWidth: '120px',
      textAlign: 'center',
      textTransform: 'none',
    },
    
    sectionHolder: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    
    cardHolder: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    cardLeft: {
      maxWidth: '800px',
      minWidth: '300px',
      justifyContent:"flex-start",
      marginRight: '80px',
    },
    cardRight: {
      maxWidth: '800px',
      minWidth: '300px',
      justifyContent:"flex-end"
    },
    
    card: {
      minHeight: '250px',
      marginTop: '25px'
    },
    
    cardTitle: {
      color: CapeeshColors.DeepBlue,
      fontSize: 16,
      fontFamily: 'Rubik',
      fontWeight: '400',
      textTransform: 'uppercase',
      letterSpacing: 0.64,
      wordWrap: 'break-word'
    },
    
    
    cardLargeText: {
      color: CapeeshColors.DeepBlue,
      fontSize: 40,
      fontFamily: 'Rubik',
      fontWeight: '500',
      wordWrap: 'break-word',
      letterSpacing: 0.64
    },
    cardDescription: {
      color: CapeeshColors.DeepBlue,
      fontSize: 20,
      fontFamily: 'Rubik',
      fontWeight: '400',
      wordWrap: 'break-word',
      letterSpacing: 0.64
    }
  }
));

const TopLevelDashboard = (props) => {
  const classes = useStyles();
  
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  
  
  useEffect(() => {
    props.dispatch(usergroupActions.getAllUserGroupsForOrganisation(props.organization.selectedOrganization));
    props.dispatch(userActions.getUserInfo(props.organization.selectedOrganization));
  }, []);
  
  
  useEffect(() => {
    var org = props.organization.myOrganizations.find(x => x.id === props.organization.selectedOrganization);
    if (org) {
      setCurrentOrganization(org);
    }
  }, [props.organization.myOrganizations]);
    
  const handleGoToCreator = () => {
    if (props.user.organizationRole === "admin" || props.user.organizationRole === "teacher") {
      history.push('/creator/');  
    } else {
      history.push('/creator/overview');
    }
    
    
  }

  const handleOrganizationSwapToDashboard = (organizationId) => {
      props.dispatch(organizationActions.setCurrentOrganization(organizationId, false));
      props.dispatch(organizationActions.getGraphDataDayOfWeekOrganization(organizationId));
      props.dispatch(organizationActions.getGraphDataHourlyOrganization(organizationId));
      props.dispatch(organizationActions.getAvailableCourseGroups(organizationId));
      history.push("/");
      
      setCurrentOrganization(organizationId);
  }
  
  const handleGoToSkillsOverview = () => {
    history.push('/skills/');
  }
  
  const handleGoToUserManagementDashboard = () => {
    history.push('/dashboard/');
  }
  const handleGoToOrganizations = () => {
    history.push('/admin/organizations');
  }
  
  return (
    <div className={classes.main}>
        {
            openOrgModal &&
            <OrganizationChangeDialog closeOrgModal={() => setOpenOrgModal(false)} chooseOrg={handleOrganizationSwapToDashboard}/>
        }
      <div className={classes.root} >
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '400px'
            }}>
              <div>
                <Typography className={classes.cardTitle} style={{marginTop: "20px"}}>
                  Capeesh Dashboard
                  </Typography>
  
                <Typography comopnent={"div"} className={classes.cardLargeText}>

                  <FormattedMessage id="top.level.dashboard.getfulloverview" defaultMessage="Get a full overview with"/>{" "}
                   <Typography display={"inline"}
                        className={classes.cardLargeText}
                        style={{
                          color: '#FF5159'
                        }}>
                        Capeesh Dashboard
                      </Typography>
                  
                </Typography>
  
  
                <Typography className={classes.cardDescription}>
                  <FormattedMessage id="top.level.dashboard.description" defaultMessage="Administer classrooms, students and courses that users can access. Access statistics and reports"/>
                </Typography>
                
                <Button variant="outlined" 
                        onClick={() => handleGoToUserManagementDashboard()}
                        style={{
                          backgroundColor: CapeeshColors.DeepBlue,
                          color: 'white', 
                          fontSize: 16,
                          fontFamily: 'Rubik',
                          fontWeight: '400',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          textTransform: 'none',
                          marginTop: '10px'
                          
                        }}>
                    <FormattedMessage id="top.level.dashboard.gotodashboardbutton" defaultMessage="Go to dashboard"/>
                  <ArrowRightAltIcon sx={{marginLeft: '5px', fontSize: '15px', color: 'white', paddingRight: '5px'}}/>
                </Button>
              </div>
            </div>
          </Grid>
  
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.cardRight}>
              <img style={{marginTop: "20px", minWidth: '50%', maxWidth: '120%'}} src={DashboardImage}/>
            </div>
          </Grid>
  
  
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.cardLeft}>
              <img style={{minWidth: '50%', maxWidth: '120%'}} src={CreateImage}/>
            </div>
          </Grid>
  
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.cardRight} style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '500px',
              marginLeft: '40px',
            }}>
              <div>
                <Typography className={classes.cardTitle}>
                  Capeesh Create
                </Typography>
  
                <Typography className={classes.cardLargeText}>
                  <FormattedMessage id="top.level.dashboard.createyourowncourseswith" defaultMessage="Create your own courses with"/>{" "}
                  <Typography display={"inline"}
                                                          className={classes.cardLargeText}
                                                          style={{
                                                            color: '#A0439F'
                                                          }}>
                  Capeesh Create
                  </Typography>
                </Typography>
  
                <Typography className={classes.cardDescription}>
                  <FormattedMessage id="top.level.dashboard.createdescription" defaultMessage="Create engaging and game-based training in minutes. Or browse our off-the-shelf Skills courses."/>
                </Typography>

                <div 
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}>

                  <Button variant="outlined"
                          onClick={() => handleGoToCreator()}
                          style={{
                            backgroundColor: CapeeshColors.DeepBlue,
                            color: 'white',
                            fontSize: 16,
                            fontFamily: 'Rubik',
                            fontWeight: '400',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            textTransform: 'none'

                          }}>
                    <FormattedMessage id="top.level.dashboard.gotocreatebuttontext" defaultMessage="Go to Create"/>
                    <ArrowRightAltIcon sx={{marginLeft: '5px', fontSize: '15px', color: 'white', paddingRight: '5px'}}/>
                  </Button>

                  <Button variant="outlined"
                          onClick={() => handleGoToSkillsOverview()}
                          style={{
                            color: CapeeshColors.DeepBlue,
                            backgroundColor: 'white',
                            fontSize: 16,
                            fontFamily: 'Rubik',
                            fontWeight: '400',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            textTransform: 'none',
                            marginLeft: '10px'
                          }}>
                    <FormattedMessage id="top.level.dashboard.seeallskillsbuttontext" defaultMessage="See all our Skills courses"/>
                  </Button>
                </div>
                
              </div>
            </div>
          </Grid>
  
        </Grid>
  
      </div>

      {
        props.organization.myOrganizations?.length > 1 &&
        <OrganizationSelectFooter openOrgModal={() => setOpenOrgModal(true)}>
          
        </OrganizationSelectFooter>
      }
    </div>

  )
}

function mapStateToProps(state) {
  const {organization, user} = state;
  return {
    organization,
    user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(TopLevelDashboard)
