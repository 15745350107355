import React, { useState} from 'react';
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import ACCLessonPlanIntroductionCard from "./Cards/ACCLessonPlanIntroductionCard";

import {
  AutoCourseCreatorFocusAreas,
  AutoCourseCreatorFocusAreas2,
  AutoCourseCreatorStoryTypes
} from "../../../../Logic/AutoCourseCreationConstants";
import {autoCourseCreationActions} from "../../../../_state/actions";
import ACCLessonTypeSelectionModal from "./Components/ACCLessonTypeSelectionModal";

const useStyles = makeStyles(theme => ({
}));

const AutoLanguageCourseLessonPlanController = (props) => {
  const {} = props;
  const [selectedLesson, setSelectedLesson] = useState("");
  const [openLessonTypeModal, setOpenLessonTypeModal] = useState(false);
  const [openLessonType, setOpenLessonType] = useState({});
  
  const classes = useStyles();
let tempFocusAreas = [
  {"type": "Reading", "friendlyName": "Reading", number: 5},
  {"type": "Writing","friendlyName": "Writing", number: 2},
  {"type": "Listening","friendlyName": "Listening", number: 3},
  {"type": "SpokenProduction","friendlyName": "Spoken Production", number: 1},
  {"type": "SpokenInteraction","friendlyName": "Spoken Interaction", number: 2},
]
  
  const availableTeachingStyles =
      [{
        teachingStyleId: "1",
        name: "Intro Writing",
        suitableFocusAreas: [ AutoCourseCreatorFocusAreas.Reading, AutoCourseCreatorFocusAreas.Writing],
        lockedFocusAreas: [ AutoCourseCreatorFocusAreas.Listening, AutoCourseCreatorFocusAreas.SpokenProduction, AutoCourseCreatorFocusAreas.SpokenInteraction]
        },
        {
          teachingStyleId: "2",
          name: "Intro Reading",
          suitableFocusAreas: [ AutoCourseCreatorFocusAreas.Reading, AutoCourseCreatorFocusAreas.Writing],
          lockedFocusAreas: [ AutoCourseCreatorFocusAreas.Listening, AutoCourseCreatorFocusAreas.SpokenProduction, AutoCourseCreatorFocusAreas.SpokenInteraction]
        },
        {
          teachingStyleId: "3",
          name: "Intro Speaking",
          suitableFocusAreas: [ AutoCourseCreatorFocusAreas.Listening, AutoCourseCreatorFocusAreas.SpokenProduction, AutoCourseCreatorFocusAreas.SpokenInteraction],
          lockedFocusAreas: [ AutoCourseCreatorFocusAreas.Reading, AutoCourseCreatorFocusAreas.Writing]
        }]

    const handleStoreLessonPlanFocusAreaAndRegenerate = (lessonPlanId, focusAreaObject) => {
      // TODO: Morten wire up to backend.
      
    }
  
    const setLessonTypeModalOpenOrClosed = (open) => {
      setOpenLessonTypeModal(open)
    }    

    const handleSelectLesson = (id) => {
      if(id === selectedLesson) {
        setSelectedLesson("")
      } else {
        setSelectedLesson(id)
      }
    };

    const handleUpdateLanguageLessonData = (mainElement, attributeToUpdate, updateData) => {
      props.dispatch(autoCourseCreationActions.accLanguageUpdateData(mainElement, attributeToUpdate, updateData));
    };
    
  
  
  return (
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', }}>
    <div style={{
      paddingLeft: '20%',
      paddingRight: '20%',
      
    }}>
      <div style={{
        background: "#FFF",
        borderRadius:16,
        paddingTop: '5px',
        paddingBottom: '5px',
        marginTop: '10px',
        marginBottom: '10px'
      }}>

        {
          props.autoCourseCreation.languageSectionPlanDto?.languageLessonPlanDtos?.length > 1 &&
          props.autoCourseCreation.languageSectionPlanDto.languageLessonPlanDtos.map((lessonPlan, index) => {
            return <ACCLessonPlanIntroductionCard
              lessonPlanDto={lessonPlan}
              lessonNumber={index+1}
              focusAreas={tempFocusAreas}
              onSelectLesson={handleSelectLesson}
              showLesson={selectedLesson ===lessonPlan.lessonPlanId }
              storyType={AutoCourseCreatorStoryTypes.DialogueStory}
              onOpenLessonTypeModal={setLessonTypeModalOpenOrClosed}
              onUpdateLanguageLessonData={handleUpdateLanguageLessonData}
              onMakeLessonFromLessonPlan={props.onMakeLessonFromLessonPlan}
              onPreviewLessson={props.onPreviewLessson}
            />
          })
        }
        
      </div>
    </div>
      <ACCLessonTypeSelectionModal setOpen={openLessonTypeModal} 
                                   closeDialog={setLessonTypeModalOpenOrClosed}
                                   lessonPlanId={selectedLesson} 
                                   currentFocusDistribution={tempFocusAreas} 
                                   currentTeachingStyleId={"1"}
                                   availableTeachingStyles={availableTeachingStyles}
                                   storeLessonPlanFocusAreaAndRegenerate={props.onMakeLessonPlanFromFocusAreaChanges}
                                   focusAreaPoints={props.autoCourseCreation.languageSectionPlanDto?.languageLessonPlanDtos?.find(item => {
                                     return item.lessonPlanId === selectedLesson;
                                   })?.focusDistributionDto}/>
    </Grid>
  );
};

AutoLanguageCourseLessonPlanController.propTypes = {};

const mapStateToProps = (state) => {
  const { organization, topic, lesson, metadata, autoCourseCreation } = state;
  return {
    organization,
    topic,
    lesson,
    metadata,
    autoCourseCreation
  };
};

export default connect(mapStateToProps)(AutoLanguageCourseLessonPlanController);