import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Grid, makeStyles, Typography} from "@material-ui/core";
import CourseViewTopicCard from "./Cards/CourseViewTopicCard";
import {CapeeshColors} from "../../assets/ColorPalette";
import {topicActions} from "../../_state/actions";
import CourseViewTopicLessonCard from "./Cards/CourseViewTopicLessonCard";
import Divider from "@material-ui/core/Divider";
import {LessonType} from "../../Logic/ScenarioConstants";


const useStyles = makeStyles(theme => ({

}));

const CourseViewTopicList = (props) => {
    const classes = useStyles();
    // expected props
    // props.courseGroup
    // props.courseGroupProgression (optional if used to show progression for users
    // props.onPreviewLesson
    // props.dispatch
    // props.showPassedOnLessons
    
    const [expandedTopicIds, setExpandedTopicIds] = useState([]);
    const [topicIdsWithLessonOrder, setTopicIdsWithLessonOrder] = useState([]);
    
    const [topicProgressions, setTopicProgressions] = useState({})

    const [userPassedLessonIds, setUserPassedLessonIds] = useState(false);
    
    useEffect(() => {
         // fetch topic lesson orders for the topics
        setExpandedTopicIds([]);
        
        
        
        
    }, []);

    useEffect(() => {
        
        if (props.courseGroupProgression && props.userId && props.user.userIdToLessonProgressionsMapper && props.userId in props.user.userIdToLessonProgressionsMapper) {
            const tempPassedLessonIds = [];
            props.user.userIdToLessonProgressionsMapper[props.userId].forEach(x => {
                if (x.passed) {
                    tempPassedLessonIds.push(x.customLessonId)
                }
            })
            setUserPassedLessonIds(tempPassedLessonIds);
        }
        
    }, [props.user])

    
    
    useEffect(() => {
        if (props.courseGroupProgression && props.courseGroupProgression.courseGroupSubPartProgressions?.length > 0) {
            const tempTopicProgressions = {}
            props.courseGroupProgression.courseGroupSubPartProgressions.forEach(courseGroupSubPartProgression => {
                if (courseGroupSubPartProgression.courseProgressions?.length > 0)
                courseGroupSubPartProgression.courseProgressions.forEach(topicProgression => {
                    tempTopicProgressions[topicProgression.topicId] = topicProgression;
                })
            })
            setTopicProgressions(tempTopicProgressions)
        }

    }, [props.courseGroupProgression])
    
    useEffect(() => {
        expandedTopicIds.forEach(expandedTopicId => {
            if (props.topic.topicLessonOrderIndex && expandedTopicId in props.topic.topicLessonOrderIndex && !topicIdsWithLessonOrder.includes(expandedTopicId)) {
                setTopicIdsWithLessonOrder([...topicIdsWithLessonOrder, expandedTopicId]);
            }
        })
    }, [props.topic])


    const handleTopicClicked = (topicId) => {
        let alreadyAskingForUpdatedTopicLessonOrderIndex = false;
        if (expandedTopicIds.includes(topicId)) {
            let tempExpandedTopicIds = expandedTopicIds.filter(x => x !== topicId)
            setExpandedTopicIds(tempExpandedTopicIds);
        } else {
            setExpandedTopicIds([...expandedTopicIds, topicId]);
            if (!props.topic.topicLessonOrderIndex || !(topicId in props.topic.topicLessonOrderIndex)) {
                props.dispatch(topicActions.getLessonOrderForIndexing(topicId));
                alreadyAskingForUpdatedTopicLessonOrderIndex = true;
                
            } else if (props.topic.topicLessonOrderIndex && topicId in props.topic.topicLessonOrderIndex && !(topicId in topicIdsWithLessonOrder)) {
                setTopicIdsWithLessonOrder([...topicIdsWithLessonOrder, topicId]);
            }
        }
        
        if (!alreadyAskingForUpdatedTopicLessonOrderIndex) {
            props.dispatch(topicActions.getLessonOrderForIndexing(topicId));
        }
    }
    
    const userHasPassedLesson = (customLessonId) => {
        if (userPassedLessonIds.length > 0) {
            const indexOfLessonInPassedList = userPassedLessonIds.indexOf(customLessonId)
            return indexOfLessonInPassedList > -1
        }
        return false;
    }
    
    
    return (
        <Grid container style={{ width: '744px'}}>

            {
                !props.courseGroup &&
                <Typography>
                    Something went wrong, no course to display
                </Typography>
            }
            
            {
                props.courseGroup?.courseGroupSubPartDtos?.length === 1 &&
                <div style={{ background: CapeeshColors.OffWhite, padding: 24, borderRadius: 16, width: 744 }}>
                    <Grid item xs={12}>
                        {
                            props.courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.map((subPartTopicMapperDto, index) => {
                                return (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <CourseViewTopicCard
                                                courseGroupSubPartToTopicMapperDto={subPartTopicMapperDto}
                                                onTopicCardClicked={handleTopicClicked}
                                                topicProgression={subPartTopicMapperDto.topicId in topicProgressions ? topicProgressions[subPartTopicMapperDto.topicId] : null}
                                                showProgression={props.courseGroupProgression != null}
                                                expanded={expandedTopicIds.includes(subPartTopicMapperDto.topicId)}
                                            />
                                            {
                                                props.courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.length !== index + 1 &&
                                                <Divider/>
                                            }
                                            {
                                                props.courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.length === index + 1 &&
                                                expandedTopicIds.includes(subPartTopicMapperDto.topicId) &&
                                                <Divider/>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                expandedTopicIds.includes(subPartTopicMapperDto.topicId) && topicIdsWithLessonOrder.includes(subPartTopicMapperDto.topicId) &&
                                                props.topic.topicLessonOrderIndex[subPartTopicMapperDto.topicId].filter(x => x.published).map((lessonOrderItem, index3) => {
                                                    return (
                                                        <Grid item xs={12} style={{ marginLeft:'50px', marginBottom: 16}}>
                                                            <div>
                                                                <CourseViewTopicLessonCard
                                                                    lessonOrderItem={lessonOrderItem}
                                                                    index={index3}
                                                                    onPreviewLesson={props.onPreviewLesson}
                                                                    isExam={lessonOrderItem.lessonType === LessonType.Exam}
                                                                    passed={userHasPassedLesson(lessonOrderItem.customLessonId)}
                                                                    showPassed={props.showPassedOnLessons}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            {
                                                props.courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.length !== index + 1 &&
                                                expandedTopicIds.includes(subPartTopicMapperDto.topicId) &&
                                                <Divider/>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </div>
            }
            
            {
                props.courseGroup?.courseGroupSubPartDtos?.length > 1 &&
                 <Grid item xs={12}>
                     {
                         props.courseGroup.courseGroupSubPartDtos.map((subPartDto, index) => {
                             return (
                                 <div style={{ background: CapeeshColors.OffWhite, paddingTop: 24, paddingRight: 24, paddingLeft: 24, borderRadius: 16, width: 744, marginBottom: 16 }}>
                                     <Grid container>
                                         <Grid item xs={12} style={{marginTop: '20px' }}>
                                             <Typography style={{ fontFamily:'Rubik', marginBottom:'15px', fontSize: '16px', fontWeight: 500, lineHeight: '140%', display: 'inline-block', color: '#35323C'}}>
                                                 {subPartDto.name}
                                             </Typography>
                                             <Divider/>
                                         </Grid>
                                         {
                                             subPartDto.courseGroupSubPartToTopicMapperDtos.map((subPartTopicMapperDto, index2) => {
                                                 return (
                                                     <Grid container>
                                                         <Grid item xs={12}>
                                                             <CourseViewTopicCard
                                                                 courseGroupSubPartToTopicMapperDto={subPartTopicMapperDto}
                                                                 onTopicCardClicked={handleTopicClicked}
                                                                 topicProgression={subPartTopicMapperDto.topicId in topicProgressions ? topicProgressions[subPartTopicMapperDto.topicId] : null}
                                                                 showProgression={props.courseGroupProgression != null}
                                                                 expanded={expandedTopicIds.includes(subPartTopicMapperDto.topicId)}
                                                             />
                                                             {
                                                                 subPartDto.courseGroupSubPartToTopicMapperDtos.length !== index2 + 1 &&
                                                                 <Divider/>
                                                             }
                                                             {
                                                                 subPartDto.courseGroupSubPartToTopicMapperDtos.length === index2 + 1 &&
                                                                 expandedTopicIds.includes(subPartTopicMapperDto.topicId) &&
                                                                 <Divider/>
                                                             }
                                                         </Grid>
                                                         <Grid item xs={12}>
                                                             {
                                                                 expandedTopicIds.includes(subPartTopicMapperDto.topicId) && topicIdsWithLessonOrder.includes(subPartTopicMapperDto.topicId) &&
                                                                 props.topic.topicLessonOrderIndex[subPartTopicMapperDto.topicId].filter(x => x.published).map((lessonOrderItem, index3) => {
                                                                     return (
                                                                         <Grid item xs={12} style={{ marginLeft:'50px', marginBottom: 16}}>
                                                                             <CourseViewTopicLessonCard
                                                                                 lessonOrderItem={lessonOrderItem}
                                                                                 index={index3}
                                                                                 onPreviewLesson={props.onPreviewLesson}
                                                                                 isExam={lessonOrderItem.lessonType === LessonType.Exam}
                                                                                 passed={userHasPassedLesson(lessonOrderItem.customLessonId)}
                                                                                 showPassed={props.showPassedOnLessons}
                                                                             />
                                                                         </Grid>
                                                                     )
                                                                 })
                                                             }
                                                             {
                                                                 subPartDto.courseGroupSubPartToTopicMapperDtos.length !== index2 + 1 &&
                                                                 expandedTopicIds.includes(subPartTopicMapperDto.topicId) &&
                                                                 <Divider/>
                                                             }
                                                         </Grid>
                                                     </Grid>
                                                 )

                                             })
                                         }
                                     </Grid>
                                 </div>
                             )
                         })
                     }
                     
                 </Grid>
             }
             
        </Grid>
            
        
    );
}


CourseViewTopicList.propTypes = {

};

function mapStateToProps(state) {
    const {topic, user} = state;
    return {
        topic,
        user
    };
}

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseViewTopicList)
