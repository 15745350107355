import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Typography } from '@material-ui/core';
import TeachingItems from "./TeachingItems";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const useStyles = makeStyles(theme => ({
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    //marginRight: '15px',
    cursor: 'pointer',
  },
  cardPlayButton: {
    height: '40px',
    backgroundColor: '#143349',
    display: 'flex',
    justify: "flex-start",
    alignItems: 'center',
    width: '200px',
    marginTop: '5px',
    marginLeft: '10px'
  },
  scenarioDescriptionText: {
    color: '#8e8e93',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    marginTop: '10px'
  }, 
  closeIcon: {
    color: 'white',
    paddingRight: '5px',
    cursor: 'pointer',
  },
  
  circularProgress: {
    color: '#eee',
    fontSize: 36,
    margin: '15px',
  },
}));


const VocabularySelector = (props) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [hasLeftOnLoad, setHasLeftOnLoad] = React.useState(false);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
      let ti = TeachingItems(props.lessonNodes, props.language1, props.language2, true, props.existingSelection)
      setLeft(ti.filter((value, index, self) => self.map(x => x.l2TextSnippetId).indexOf(value.l2TextSnippetId) == index))
      setHasLeftOnLoad(ti.length>0)
  }, [props.lessonNodes]);

  useEffect(() => {
    let ti = TeachingItems(props.lessonNodes, props.language1, props.language2, true, props.existingSelection)
    setLeft(ti.filter((value, index, self) => self.map(x => x.l2TextSnippetId).indexOf(value.l2TextSnippetId) == index))
    setRight([])
}, [props.existingSelection]);

    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    };
  
    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    };
  
    const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    };
  
    const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    };

    const createPuzzleBlocks = () => {
      props.toggleOpen(false)
      props.createPuzzleBlocks(right)
    };
    const customList = (title, items) => (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={
                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
          <Divider />
          <List
            sx={{
              width: 200,
              height: 230,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component="div"
            role="list"
        >
          {items.length > 0 && items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;
            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.l2} />
              </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Card>
      );
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={(event) => { props.toggleOpen(false)}}          
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ paddingLeft: '100px', paddingRight: '100px', cursor: 'pointer', minHeight: '100px' }}
          maxWidth="lg"
          fullWidth
         >
          <DialogTitle id="alert-dialog-title">{"Add lesson items from other stages"}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
              {left.length === 0 && !!!hasLeftOnLoad ? 
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={12} >
                    <Typography 
                    variant='body1'  
                    style={{
                    boxShadow: 'none',
                    flex: 1,
                    fontSize: 14,
                    color: 'black',
                    fontFamily: 'Lato',

                    }}>
                      There are no suitable lesson items that have not already been added. 
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} >
                    <Typography 
                      variant='body1'  
                      style={{
                      boxShadow: 'none',
                      flex: 1,
                      fontSize: 14,
                      color: 'black',
                      fontFamily: 'Lato',
                    }}>
                      If you were expecting other lesson items to appear, check that the L1 / L2 combination you have set for this puzzle stage matches the other stages. 
                    </Typography>
                  </Grid>
                </React.Fragment>
              : 
                <React.Fragment>
                  <Grid item>{customList('Unused Lesson Items', left)}</Grid>
                  <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Button
                          sx={{ my: 0.5 }}
                          variant="outlined"
                          size="small"
                          onClick={handleCheckedRight}
                          disabled={leftChecked.length === 0}
                          aria-label="move selected right"
                        >
                          &gt;
                        </Button>
                        <Button
                          sx={{ my: 0.5 }}
                          variant="outlined"
                          size="small"
                          onClick={handleCheckedLeft}
                          disabled={rightChecked.length === 0}
                          aria-label="move selected left"
                        >
                          &lt;
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item>{customList('New blocks to create', right)}</Grid>
                </React.Fragment>
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={(event) => { props.toggleOpen(false)}} color="primary" autoFocus>
                Cancel
              </Button>
              {hasLeftOnLoad && 
                <Button disabled={right.length === 0} onClick={(event) => { createPuzzleBlocks()}} color="primary" autoFocus>
                Add puzzle blocks
              </Button>
              }
            </DialogActions>
          </Dialog>
      </div>
    );
    }

    VocabularySelector.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(VocabularySelector)