import React, {useState} from 'react';
import { connect } from 'react-redux';
import { organizationActions} from "_state/actions";
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    CardHeader,
    TextField, Select, MenuItem, makeStyles, Typography,
} from "@material-ui/core";

import { FormattedMessage } from 'react-intl';
import {CapeeshOrganizationType} from "../../../Logic/CapeeshConstants";

const useStyles = makeStyles(theme => ({
    divider: {
        margin: '0 30'
    }
}));

const CreateOrganization = (props) => {

    const classes = useStyles();
    
    const [tempName, setTempName] = useState("");
    const [tempWelcomeMessage, setTempWelcomeMessage] = useState("");
    const [tempNumberOfLicenses, setTempNumberOfLicenses] = useState(10);
    const [tempOrganizationType, setTempOrganizationType] = useState(CapeeshOrganizationType.Normal);

    const createOrganization = () => {
        
        if (tempName && tempNumberOfLicenses > 0) {
            props.onCreateOrganization(
                tempName,
                tempOrganizationType,
                tempWelcomeMessage,
                tempNumberOfLicenses
            );
        }
    };


    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader
                        title={<FormattedMessage id="admin.edit.org.createyournew" defaultMessage="Create your new company or school" />}
                        subheader={<FormattedMessage id="admin.edit.org.companyinfo" defaultMessage="Company information" />}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="name"
                                    label={<FormattedMessage id="globalwords.name1" defaultMessage="Name" />}
                                    value={tempName}
                                    onChange={(event) => setTempName(event.target.value)}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="welcomeMessage"
                                    label={<FormattedMessage id="admin.edit.org.welcome" defaultMessage="Welcome message in app" />}
                                    multiline
                                    rows="4"
                                    value={ tempWelcomeMessage }
                                    onChange={(event) => setTempWelcomeMessage(event.target.value)}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <div className={classes.divider} />
                            </Grid>

                            <Grid item xs={6} sm={6}>
                                <TextField
                                  id="region"
                                  label={"Number of licenses"}
                                  value={ tempNumberOfLicenses }
                                  onChange={(event) => setTempNumberOfLicenses(event.target.value)}
                                  margin="normal"
                                  fullWidth
                                />
                            </Grid>
                            
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Typography
                                            variant='h6'
                                            style={{
                                                marginTop: '25px',
                                                marginRight: '10px',
                                                boxShadow: 'none',
                                                color: 'black',
                                                fontFamily: 'Lato',
                                                fontSize: 19,
                                                textAlign: 'right'
                                            }}>
                                            Organization type
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        
                                        <Select
                                          style={{
                                              paddingLeft: '40px',
                                              paddingRight: '10px',
                                              marginTop: '20px',
                                              height: '90px',
                                              border: '5px solid #143349',
                                              boxSizing: 'border-box',
                                              borderRadius: '10px',
                                              maxHeight: '40px',
                                              minHeight: '40px',
                                          }}
                                          disableUnderline
                                          label={"Organization type"}
                                          value={tempOrganizationType}
                                          autoWidth
                                          onChange={(event) => setTempOrganizationType(event.target.value)}
                                        >
                                            <MenuItem disabled={true} key={CapeeshOrganizationType.Normal} value={CapeeshOrganizationType.Normal}>(Not set)</MenuItem>
                                            <MenuItem key={CapeeshOrganizationType.CapeeshInternal} value={CapeeshOrganizationType.CapeeshInternal}>Capeesh Internal</MenuItem>
                                            <MenuItem key={CapeeshOrganizationType.Partner} value={CapeeshOrganizationType.Partner}>Partner</MenuItem>
                                            <MenuItem key={CapeeshOrganizationType.Customer} value={CapeeshOrganizationType.Customer}>Customer</MenuItem>
                                            <MenuItem key={CapeeshOrganizationType.Demo} value={CapeeshOrganizationType.Demo}>Demo</MenuItem>
                                            <MenuItem key={CapeeshOrganizationType.Trial} value={CapeeshOrganizationType.Trial}>Trial</MenuItem>
                                            
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.divider} />
                            </Grid>
                           
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button size="large"
                                onClick={() => createOrganization()}
                                color={"primary"}
                                variant={"contained"}
                                disabled={!tempName || tempNumberOfLicenses <= 0}
                        >
                            <FormattedMessage id="globalwords.create" defaultMessage="Create" />
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
}



function mapStateToProps(state) {
    const {organization} = state;
    return {
        organization,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganization)

