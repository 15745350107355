import React, {useEffect, useState} from 'react';
import {
    Card,
    CardHeader,
    CardActions,
    CardContent,
    Grid,
    Button,
    TextField,
} from '@material-ui/core';
import {connect} from 'react-redux';

import {usergroupActions} from '_state/actions';
import ClassroomSidebar from "./Components/ClassroomSidebar";
import {FormattedMessage} from 'react-intl';
import CapeeshStyledModal from "../../../Components/CapeeshStyledModal";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 'calc(15% + 25px)',
        marginRight:'25px'
    },
  }
));

// regex that can check a text only contains numbers, used for input fields to validate input for max licenses
const numberRegex = /^[0-9\b]+$/;


const ClassroomSettings = (props) => {
    const {organization, usergroup, user, computedMatch} = props;
    
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [maxLicenses, setMaxLicenses] = useState(0);
    const [classroom, setClassroom] = useState(null);
    
    const [showAdminSection, setShowAdminSection] = useState(false);
    
    const handleSetClassroomOnChangeFromServer = (classroom) => {
        setClassroom(classroom);
        setName(classroom.name);
        setDescription(classroom.description);
        setMaxLicenses(classroom.maxLicenses);
    }
    
    useEffect(() => {
        props.dispatch(usergroupActions.getAllUserGroupsForOrganisation(organization.selectedOrganization));
        
        if (usergroup.usergroups?.length > 0) {
            const tempClassroom = usergroup.usergroups.find(x => x.id == computedMatch.params.classroomId);
            if (tempClassroom) {
                handleSetClassroomOnChangeFromServer(tempClassroom);
            }
        }
        
        setShowAdminSection(props.user.organizationRole === 'admin')
        
    }, []);

    useEffect(() => {
        const {computedMatch, usergroup} = props;

        const tempClassroom = usergroup.usergroups.find(x => x.id == computedMatch.params.classroomId);
        handleSetClassroomOnChangeFromServer(tempClassroom);
    }, [usergroup.usergroups]);

    const handleNameChanged = (e) => {
        setName(e.target.value);
    }
    
    const handleDescriptionChanged = (e) => {
        setDescription(e.target.value);
    }
    const handleSetMaxLicenses = (e) => {
        if (e.target.value === '' || numberRegex.test(e.target.value)) {
            setMaxLicenses(e.target.value);
        }
    }
    
    const handleSaveClicked = (e) => {
        if (name) {
            props.dispatch(usergroupActions.updateUserGroup(organization.selectedOrganization, computedMatch.params.classroomId, name, description, maxLicenses));
        }
    };
    
    const handleDeleteClicked = (e) => {
        props.dispatch(usergroupActions.deleteUserGroup(organization.selectedOrganization, computedMatch.params.classroomId));
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ClassroomSidebar
              userGroupId={computedMatch.params.classroomId}
              path={computedMatch.path}
            />
            <Grid container spacing={5} style={{marginTop: '25px'}}>
                <Grid item xs={12} sm={12} md={8}>
                    {
                        showDeleteConfirmation &&
                        <CapeeshStyledModal
                          open={showDeleteConfirmation}
                          textTitle={"Delete confirmation"}
                          textLine1={"Do you really want delete the classroom?"}
                          onButton2={() => handleDeleteClicked()}
                          onButton1={() => setShowDeleteConfirmation(false)}
                          button1Text={"Cancel"}
                          button1Variant={'outlined'}
                          button2Text={"Yes"}
                          onClose={() => setShowDeleteConfirmation(false)}
                        />
                    }
                    <Card>
                        <CardHeader
                            title={<FormattedMessage id="dashboard.classroom.settings.title"
                                                     defaultMessage="Settings"/>}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        label={<FormattedMessage id="globalwords.name5" defaultMessage="Name"/>}
                                        value={name}
                                        onChange={handleNameChanged}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                {
                                    user.organizationRole === "admin" &&
                                    <Grid item xs={12}>
                                        <TextField
                                          id="description"
                                          label={<FormattedMessage id="globalwords.description"
                                                                   defaultMessage="Description"/>}
                                          value={description}
                                          onChange={handleDescriptionChanged}
                                          margin="normal"
                                          fullWidth
                                          multiline
                                          rows="4"
                                        />
                                    </Grid>
                                }

                                {
                                    user.organizationRole === "admin" && showAdminSection &&
                                    <Grid item xs={12}>
                                        <TextField
                                          id="maxLicenses"
                                          label={<FormattedMessage id="dashboard.classroom.settings.maxlicenseslabel"
                                                                   defaultMessage="Max number of licenses"/>}
                                          value={maxLicenses === 0 ? "No max set" : maxLicenses}
                                          onChange={handleSetMaxLicenses}
                                          margin="normal"
                                          fullWidth
                                        />
                                    </Grid>
                                }
                                
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button size="large"
                                    disabled={ !classroom || (classroom.name === name && classroom.description === description && classroom.maxLicenses === maxLicenses)}
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={handleSaveClicked}>
                                <FormattedMessage id="globalwords.save3" defaultMessage="Save"/>
                            </Button>
                            <Button onClick={() => setShowDeleteConfirmation(true)}
                                    variant={"contained"}
                                    style={{color:'red'}}
                                    
                            >
                                Delete Classroom
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

function mapStateToProps(state) {
    const {organization, usergroup, user} = state;
    return {
        organization,
        usergroup,
        user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomSettings)
