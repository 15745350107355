import React, {useEffect, useState} from "react";
import {
    Grid,
} from '@material-ui/core';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {history} from "_state/helpers";


const useStyles = makeStyles(theme => ({
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    //marginRight: '15px',
    cursor: 'pointer',
  },
  leftBox: {
/* Gray 3 */
background: '#EFEFEF',
border: '1px solid #D1D1D6',
boxSizing: 'border-box',
boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
borderRadius: '20px'
  },
  leftPanel: {
    minWidth: '400px'
  },
  mainContainer: {
    paddingLeft: '25px',
    paddingRight: '25px'
  }, 
  rightPaper: {
    /* White */
  //  marginLeft: '20px',
marginTop: '30px',
background: '#FFFFFF',
/* Gray 3 */

border: '1px solid #D1D1D6',
boxSizing: 'border-box',
borderRadius: '15px',
  }


}));

const TutorialAnatomy = (props) => {
    const classes = useStyles();
    const tutorialParagraph = (text1, link, text2) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontSize: '16px', marginBottom: '10px'}}>
            {text1}{link}{text2}
          </Typography>
        </Grid>
      )
     }

     const tutorialSubHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', marginTop: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px'}} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', marginBottom: '20px'}}>
            {text}
          </Typography>
        </Grid>

      )
     }


    return (
      <Grid container style={{paddingLeft: '50px', paddingTop: '40px', paddingRight: '20px'}}> 
      {tutorialHeader("What is a lesson stage?")}
      {tutorialSubHeader("A lesson stage is simply a screen within a lesson")}
      {tutorialParagraph("Each stage corresponds to one screen that the learner sees during the lesson.")}
      {tutorialParagraph("In the visual lesson viewer, each  stage is connected to next the stage that you wish the learner to reach. Branching stages enable a lesson to go in different branches. So within those stages, each branching answer connects to another lesson stage. More on that in the Branching section.")}
      {tutorialSubHeader("Lesson attributes")}
      {tutorialParagraph("Each lesson stage has a number of attributes. These are elements that make up the stage. One example of an attribute is the background image that appears on the screen.")}
      {tutorialParagraph("Whilst differrent lesson stages have different attributes, many share attributes in common with each other.")}
      {tutorialParagraph("Each attribute is set by clicking on a lesson stage, and filling in the details as desired.")}
      {tutorialParagraph("Let's have a brief look at each of the attributes")}
      
      {tutorialHeader("Background Image")}
      {tutorialParagraph("The background image attribute currently exists in Cutscene, Listen and Branching stages. It is simply the background image that fills a large portion of the screen during the lesson.")}
      {tutorialSubHeader("Why use it?")}
      {tutorialParagraph("To help create an immersive learning experiene. It is an essential part of role play stages.")}
      {tutorialParagraph("Images can either be uploaded directly from your computer, from the Capeesh library of previously-used images, or via the stock image searcher.")}
      {tutorialParagraph("When adding an image, Capeesh will prompt you to crop the image to the correct shape, if it is not already so.")}

      {tutorialHeader("Background Audio")}
      {tutorialParagraph("The background audio attribute is used within Cutscene, Listen and Branching stages. It is an optional attribute.")}
      {tutorialSubHeader("Why use it?")}
      {tutorialParagraph("It is designed to help give your role play a little soul, as well as help to build the immersion.")}
      {tutorialParagraph("If connected lesson stages have the same background audio file, then the file will continue playing. It the end of the audio is reached, it will loop around back to the start and continue.")}
      {tutorialSubHeader("Examples of using background audio")}
      {tutorialParagraph("Typical examples include background office ambience, supermarket background sound, birds tweeting during an outdoor role play.")}
      {tutorialSubHeader("Won't it distract the learner from listening or speaking?")}
      {tutorialParagraph("To prevent this, the background audio volume is automatically reduced in Branching and Listen stages compared to in a Cutscene stage..")}

      {tutorialHeader("Sound Effects")}
      {tutorialParagraph("Sound effects play once and then stop. They are used much less frequently than Background Audio, and fulfill a different purpose.")}
      {tutorialSubHeader("How do background audio and sound effect attributes differ?")}
      {tutorialParagraph("Background Audio is usually used over multiple Lesson Stages. It is used purely to help create an ambience and support the narrative. It blends into the background. Sound Effects, on the other hand, are used as audio props to support something in the story.")}
      {tutorialSubHeader("Some examples")}
      {tutorialParagraph("Let's say you are creating a role play where the learner arrives at someone's house. You may start out with a Cutscene stage standing outside the house, next to the door. Here, you may wish to add a doorbell ringing Sound Effect.")}
      {tutorialParagraph("Another example may be creating an airline cabin crew role play. You decide to use an aircraft 'droning' for background audio. In the role play, the learner has to respond to a passenger request for help. Therefore, you decide to use a 'ding dong' sound effect to show the learner that a passenger has pressed the 'request assistance' button.")}

      {tutorialHeader("Description")}
      {tutorialParagraph("Some stages, such as the ", <Link style={{paddingBottom: '2px'}} component="button" variant="body2" onClick={() => { history.push('?section=puzzle')}}>Puzzle Stage</Link>," stage, include the Description attribute. Use this to tell the learner what to do in the stage. The description can appear in the L1 or L2 language, so make sure to choose the correct setting. Which language you give instructions in is up to you. Typically, L2 language is used more if the target audience is already fairly competent with the language,")}

      {tutorialHeader("L2 Title")}
      {tutorialParagraph("This is the title that appears at the top of the screen. It is optional.")}

      {tutorialHeader("L1")}
      {tutorialParagraph("See the ", <Link style={{paddingBottom: '2px'}} component="button" variant="body2" onClick={() => { history.push('?section=test')}}>Test Stage</Link> ," section for information regarding this attribute.")}

      {tutorialHeader("L2 Interaction")}
      {tutorialParagraph("See the ", <Link style={{paddingBottom: '2px'}} component="button" variant="body2" onClick={() => { history.push('?section=test')}}>Test Stage</Link> ," section for information regarding this attribute.")}

      {tutorialHeader("L2 Voice")}
      {tutorialParagraph("See the ", <Link style={{paddingBottom: '2px'}} component="button" variant="body2" onClick={() => { history.push('?section=definition')}}>Definition Stage</Link> ," section for information regarding this attribute.")}

      {tutorialHeader("L1 Subtitle")}
      {tutorialParagraph("See the subtitles area of the ", <Link style={{paddingBottom: '2px'}} component="button" variant="body2" onClick={() => { history.push('?section=listen')}}>Listen Stage</Link> ," guide for information regarding how to use subtitles.")}

      {tutorialHeader("Definition")}
      {tutorialParagraph("See the ", <Link style={{paddingBottom: '2px'}} component="button" variant="body2" onClick={() => { history.push('?section=definition')}}>Definition Stage</Link> ," section for information regarding this attribute.")}

      {tutorialHeader("Explanation")}
      {tutorialParagraph("See the ", <Link style={{paddingBottom: '2px'}} component="button" variant="body2" onClick={() => { history.push('?section=definition')}}>Definition Stage</Link> ," section for information regarding this attribute.")}

      {tutorialHeader("Rules")}
      {tutorialParagraph("See the ",<Link style={{paddingBottom: '2px'}} component="button" variant="body2" onClick={() => { history.push('?section=rulecard')}}>Rule Card</Link>, " section for information regarding this attribute.")}
    </Grid>
    );
    }


const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(TutorialAnatomy)