import {connect} from "react-redux";
import React from "react";
import {Button, Grid, makeStyles, MenuItem, Select, Typography} from "@material-ui/core";
import {TextField} from "@mui/material";
import {
  AudioLibraryType,
  ScenarioAudioPlayType,
  ScenarioL1L2SelectorOptions
} from "../../../../Logic/ScenarioConstants";
import TextFieldWithLanguageSelector from "../ScenarioComponents/TextFieldWithLanguageSelector";


const useStyles = makeStyles(theme => ({
  textFields: {
    width: '428px',
      textColor: 'white'
  },
  stageHeader: {
    boxShadow: 'none',
    color: 'black',
    fontFamily: 'Lato',
    fontSize: 32,
    fontWeight: 'bold'
    },
    sectionHeader: {
      boxShadow: 'none',
      color: 'black',
      fontFamily: 'Lato',
      fontSize: 22,
      fontWeight: 'bold',
    },
  textfieldWithDisable: {
    textTransform: 'none',
    backgroundColor: '#143349',
    color: 'white',
    "&:disabled": {
      backgroundColor: '#143349',
      color: 'grey',
    }
  },
}))

const InteractionInstruction = (props) => {
  const classes = useStyles();

  // expects these props:
  // props.canEditLesson
  // props.onAudioLibrary
  // props.testOption
  // props.l1Id
  // props.l2Id
  
  
  const setInstructionText = (newText) => {
    
    if (newText.length > 270) {
      newText = newText.slice(0, 270)
    } 
    
    props.testOption.text = newText;
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
  }
  const setExplanationText = (newExplanation) => {
    props.testOption.explanation = newExplanation;
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
  }

  const setLanguageForExplanation = (languageSelector) => {
    if (languageSelector === ScenarioL1L2SelectorOptions.L1) {
      props.testOption.explanationLanguageId = props.l1Id;
    } else if (languageSelector === ScenarioL1L2SelectorOptions.L2) {
      props.testOption.explanationLanguageId = props.l2Id;
    }
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
  }
  
  const setAudioPlayType = (value) => {
    if (value === ScenarioAudioPlayType.DontPlay) {
      props.testOption.audioPlayType = ScenarioAudioPlayType.DontPlay;
    }
    if (value === ScenarioAudioPlayType.PlayBefore) {
      props.testOption.audioPlayType = ScenarioAudioPlayType.PlayBefore;
    }
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
  }
  return (
    <React.Fragment>
    {
      props.testOption &&
      <React.Fragment>  
        <Grid item xs={12} sm={12} md={12} style={{marginTop: '20px'}}>
          <Typography
            variant='body1'
            className={classes.stageHeader}>
            Instruction
          </Typography>
          <Typography
              style={{
                boxShadow: 'none',
                color: 'black',
                fontFamily: 'Lato',
                letterSpacing: '0.02em',
                fontSize: 14,
              }}>
              Teach a concept or rule. The Instruction stage gives you enough space to write more complex instructions than most other stages.
            </Typography>
            <Typography
              style={{
                boxShadow: 'none',
                color: 'black',
                fontFamily: 'Lato',
                letterSpacing: '0.02em',
                fontSize: 14,
                marginTop: '10px'
              }}>
              It allows an optional translation of the instruction to appear if desired.
            </Typography>
            <Grid item xs={12} sm={12} md={12} style={{marginTop: '30px'}}>
              <Typography 
                xs={10}
                variant='body1'
                className={classes.sectionHeader}>
                Instruction text
              </Typography>
            </Grid>
          <Typography
            xs={12}
            autoWidth
            variant='body1'
            style={{
              marginTop: '10px',
              fontFamily: 'Lato',
              color: 'gray',
            }}>
            Write your instruction here (270 chars)
          </Typography>
          
          <TextFieldWithLanguageSelector 
            text={props.testOption.text}
            fullWidth='true'
            disabled={!props.canEditLesson} 
            onChange={(event) => setInstructionText(event.target.value)}
            l2Id={null}
            l1Id={null}
            soloLanguageId={props.l2Id}
            canChangeLanguage={false}
            />
        </Grid> 
        
        
        <Grid container style={{marginTop: '30px', marginBottom: '10px'}}>
          <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
            <Typography 
              xs={10}
              variant='body1'
              className={classes.sectionHeader}>
              Translation
            </Typography>
          </Grid>
        
          
          <Grid item xs={12} sm={12} md={12} className={classes.scenario} >
            <Typography
              xs={12}
              autoWidth
              variant='body1'
              style={{
                boxShadow: 'none',
                color: 'black',
                fontFamily: 'Lato',
                letterSpacing: '0.02em',
                fontSize: 14,
              }}>
              If you wish, provide a translation of the instruction text.
            </Typography>
            
            <Grid container>
              <Grid item xs={9}>
                <div style={{marginRight: '10px', marginTop:'10px'}}>
                  <TextFieldWithLanguageSelector
                    text={props.testOption.explanation}
                    fullWidth='true'
                    disabled={!props.canEditLesson}
                    onChange={(event) => setExplanationText(event.target.value)}
                    l2Id={null}
                    l1Id={null}
                    soloLanguageId={props.l1Id}
                    canChangeLanguage={false}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <Button 
                  disabled={!props.canEditLesson} 
                  onClick={() => props.onAutoTranslation(props.testOption.text)} 
                  className={classes.textfieldWithDisable} 
                  style={{marginTop:'15px'}}
                  variant='outlined'>
                  Translate
                </Button>
              </Grid>
            </Grid>
            
            
            
            
            <Grid item xs={12} sm={12} md={12} style={{marginTop: '30px'}}>
              <Typography 
                xs={10}
                variant='body1'
                className={classes.sectionHeader}>
                Voice
              </Typography>
            </Grid>
            <Typography
              xs={12}
              autoWidth
              variant='body1'
              style={{
                boxShadow: 'none',
                color: 'black',
                fontFamily: 'Lato',
                letterSpacing: '0.02em',
                fontSize: 14,
              }}>
              Add a voice recording of the instruction text being spoken out.
            </Typography>
          </Grid>
          {
            props.onAudioLibrary(AudioLibraryType.TestOptionVoice, 2)
          }

          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={4} sm={4} md={4}>
              <Typography
                variant='body1'
                style={{
                  marginTop:'10px',
                  fontFamily: 'Lato',
                  color: 'gray',
                }}>
                Voice options: 
              </Typography>
            </Grid>
            
            <Grid item xs={6} sm={6} md={6}>
              <Select 
                style={{
                  marginLeft: '22px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  marginBottom: '5px',
                  height: '60px',
                  border: '5px solid #143349',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  maxHeight: '40px',
                  minWidth: '200px',
                  minHeight: '40px',
                  marginTop: '10px'
                }} 
                disableUnderline
                value={props.testOption.audioPlayType}
                autoWidth
                onChange={(e) => setAudioPlayType(e.target.value)}
              >
                <MenuItem disabled={!props.canEditLesson} value={0}>Never spoken out</MenuItem>
                <MenuItem disabled={!props.canEditLesson} value={1}>Spoken at the start of the stage</MenuItem>
              </Select>
           </Grid>
          </Grid>
          
        </Grid>
      </React.Fragment>
    }
    </React.Fragment>
  );
}


InteractionInstruction.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(InteractionInstruction)