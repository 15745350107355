
export const CourseGroupType =
{
  "NotDefined": 0,
  "BabbelEnglishProgressives": 1,
  "BabbelGermanProgressives": 2,
  "BabbelPolishProgressives": 3,
  "CapeeshNorwegianProgressives": 4,
  "DirectUserGroupAccessFallback": 5,
  "CapeeshNorwegianProgressivesFree": 6,
  "CapeeshNyNorskProgressive": 7,
}

Object.freeze(CourseGroupType);


export const ImageCropType = {
  "Square": 0,
  "Course": 1,
  "CourseGroup": 2,
}

Object.freeze(ImageCropType)