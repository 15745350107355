import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import { ReactComponent as Graphic } from '../Images/AnalysisCompleteGraphic.svg';

const useStyles = makeStyles(theme => ({
    mainText:
        {
            width: '100%',
            textAlign: 'center',
            color: 'white',
            fontSize: 32, 
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    secondaryText:
        {
            width: 462,
            textAlign: 'center', 
            color: 'rgba(255, 255, 255, 0.80)',
            fontSize: 20, 
            fontFamily: 'Rubik', 
            fontWeight: '400', 
            wordWrap: 'break-word'
        }
}));

const ACCAnalysisCompletedPage = (props) => {
    
    // props.shouldTransition
    
    const classes = useStyles();

    return (
        <Fragment>
            <div style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", display: "flex" }}>
                <Graphic style={{ marginBottom: 32, alignSelf: "center" }}/>
            </div>
            <Typography className={classes.mainText}>
                Analysis completed
            </Typography>
            <Typography className={classes.secondaryText}>
                The analysis is complete and you can now review the generated teaching material.
            </Typography>
        </Fragment>
    );
};

ACCAnalysisCompletedPage.propTypes = {};

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCAnalysisCompletedPage)