import React, {useState, useRef} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {CircularProgress, Grid, makeStyles} from "@material-ui/core";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';

import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({

}))


const AuutoLanguageCourseSuggestionBox = (props) => {


  let margin = "5px"

  const classes = useStyles();
  return (
    <svg
    style={{
      position: 'absolute', // Position the SVG absolutely
      right: '-2px', // Move it to the right to overlap the border
      top: 8, // Align it to the top of the parent container
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="83"
    height="32"
    viewBox="0 0 83 32"
    fill="none"
  >
    <path
      d="M16.6986 32L0 0H83V32H16.6986Z"
      fill="url(#paint0_linear_628_9522)"
    />
  
    <svg
      x="15%" // Adjust this value as needed to position the inner SVG
      y="10%"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      overflow="visible" // Added to ensure the nested SVG isn't clipped
    >
      <g clip-path="url(#clip0_628_9523)">
        <path 
          d="M9.81818 4.36364L10.5 2.86364L12 2.18182L10.5 1.5L9.81818 0L9.13636 1.5L7.63636 2.18182L9.13636 2.86364L9.81818 4.36364ZM5.72727 4.63636L4.36364 1.63636L3 4.63636L0 6L3 7.36364L4.36364 10.3636L5.72727 7.36364L8.72727 6L5.72727 4.63636ZM9.81818 7.63636L9.13636 9.13636L7.63636 9.81818L9.13636 10.5L9.81818 12L10.5 10.5L12 9.81818L10.5 9.13636L9.81818 7.63636Z" 
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_628_9523">
          <rect width="12" height="12" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  
    <text
      x="50%"
      y="50%"
      dominant-baseline="middle"
      text-anchor="middle"
      fill="white"
      fontFamily="Rubik"
      fontSize="10"
      fontWeight="500"
    >
      {/* The first line with "coming" */}
      <tspan x="60%" dy="-5">
        Coming
      </tspan>
      {/* The second line with "soon" */}
      <tspan x="60%" dy="15">
        soon
      </tspan>
    </text>
  
    <defs>
      <linearGradient id="paint0_linear_628_9522" x1="83" y1="32" x2="-2.88193" y2="13.0856" gradientUnits="userSpaceOnUse">
        <stop stop-color="#A0439F"/>
        <stop offset="1" stop-color="#AE59AD"/>
      </linearGradient>
    </defs>
  </svg>

  )
}


AuutoLanguageCourseSuggestionBox.propTypes = {
  
};


function mapStateToProps(state) {
  const {autoCourseCreation} = state;
  return {
    autoCourseCreation
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuutoLanguageCourseSuggestionBox)