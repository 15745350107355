import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import SpeakWithText from "../../../../assets/images/SpeakNoText.png";
import Typography from '@material-ui/core/Typography';
import {
  Grid,
} from '@material-ui/core';

export default memo(({ data }) => {
  var sectionStyle = {
    position: 'absolute',
    top: '18px',
    left: '5px',
    height: '79px',
    width: '90px',
    background: "url(" + data.imageUrl + ") no-repeat bottom / 100% auto",
    // backgroundSize: '100% auto'
  };

  return (
    <>
      <div>
        <div style={sectionStyle }>
        </div>
        <img
          src={SpeakWithText}
          style={{position: 'relative', width: '100px',
            height: 'auto'
          }}
        />

        <Grid style={{position: 'absolute', top: 115, left: 0}} container>
          {
            data?.options?.map((el, index) =>
              <Grid item xs={12} sm={12} key={index}>
                {
                  <Typography
                    variant='body2'
                    style={{
                      marginTop: '10px',
                      width: '70px',
                      boxShadow: 'none',
                      flex: 1,
                      fontSize: '5px',
                      color: 'white',
                      justifyContent: 'left',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      alignItems: 'center'}}>
                    {el}
                  </Typography>
                }
              </Grid>
            )
          }
        </Grid>
      </div>

      <Handle
        type="target"
        position="left"
        id="target1"
        style={{ top: '98.5px', background: '#555' }}
      />
      <Handle
        type="source"
        position="right"
        id="exit0"
        style={{ top: '133px', background: '#555' }}
      />

      <Handle
        type="source"
        position="right"
        id="exit1"
        style={{ top: '148px', background: '#555' }}
      />

      <Handle
        type="source"
        position="right"
        id="exit2"
        style={{ top: '163px', background: '#555' }}
      />
    </>
  );
});

