import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import Watch_NoText from "../../../../assets/images/698wWatch.png";

export default memo(({ data }, areEqual) => {
  var sectionStyle = {
    position: 'absolute',
    top: '18px',
    left: '5px',
    height: '109px',
    width: '90px',
    background: "url(" + data.imageUrl + ") no-repeat bottom / 100% auto",
    // backgroundSize: '100% auto'
  };
  return (
    
    <div> 
      <div style={sectionStyle }> 
      </div>
      
      <img
        src={Watch_NoText}
        style={{position: 'relative', width: '100px',
        height: 'auto'
        }}
      />

      <Handle
        type="target"
        position="left"
        id="target1"
        style={{ top: '98.5px', background: '#555' }}
      />
      <Handle
        type="source"
        position="right"
        id="exit0"
        style={{ top: '98.5px', background: '#555' }}
      />
  </div>
  );
});