import { configWithAuth } from '../helpers';
import { makeGetRequest, makePostRequest, makePatchRequest, makePutRequest } from "../helpers/api";

export const translatorService = {
   getTranslationJobs: (payload) => makeGetRequest('admin/translator/organization/'+ payload.organizationId + '/jobs', configWithAuth()),
   createTranslatorJob: (payload) => makePostRequest('admin/translator/job/', JSON.stringify(payload), configWithAuth()),
   getTranslators:  (payload) => makeGetRequest('admin/translator/organization/'+ payload.organizationId + '/translators', configWithAuth()),
   addTranslatorLanguage: (payload) => makePatchRequest('admin/translator/user', JSON.stringify(payload), configWithAuth()),
   getTranslatorJobs:  (payload) => makeGetRequest('admin/translator/jobs/', configWithAuth()),
   updateJob:  (payload) => makePatchRequest('admin/translator/job', JSON.stringify(payload), configWithAuth()),
};

