import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {Grid} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import Badge from '@mui/material/Badge';
import {CustomLessonAuthorNoteType, ScenarioNodeType} from "../../../../Logic/ScenarioConstants";
import Zoom from '@mui/material/Zoom';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import  { ReactComponent as RecordVoiceOverHuman } from ".././Images/RecordVoiceOverHuman.svg";
import  { ReactComponent as RecordVoiceOverRobot } from ".././Images/RecordVoiceOverRobot.svg";
import {voiceTypeSearch} from './../ScenarioStages/StageHelperFiles'
import CircleIcon from '@mui/icons-material/Circle';

export default memo(({ data, options }) => {
  const handleDuplicate = (event) => {
    data.onDuplicate(data.id, ScenarioNodeType.TestImage) 
    event.stopPropagation();
    event.preventDefault();
   };
 
   const handleAdd = (event) => {
     data.onAdd(data.id, 10)
     event.stopPropagation();
     event.preventDefault();
    };
 
    const handleAddOther = (event) => {
     data.onAddOther(data.id)
     event.stopPropagation();
     event.preventDefault();
    };

    const displayInteractionType = (testOption) => {
      switch(testOption.scenarioInteractiveElementType) {
        case 2:
          return "Keyboard"
          break;
        case 3:
          if (detectInteractionMode(testOption.alternatives, testOption.text)) {
            return "Sentence Builder"
          } else {
            return "Multiple Choice"
          }
          break;
      }
    }

    const detectInteractionMode = (alternatives, text) => {
      if(text === "") return false
      sentenceBuildWordGroups(alternatives)
      let total = text.length
      let next = 0
      let areMultipleAtStartIndex = false
        alternatives.forEach(alt => {
          for(let x = 0; x <= total; x++) {
            if(x >= alt.startIndex && x <= alt.startIndex + alt.text.length) {
              next++
            }
          }
          alternatives.forEach(alt2 => {
            if(alt.startIndex === alt2.startIndex && alt.textSnippetId !== alt2.textSnippetId) {
              areMultipleAtStartIndex = true
            }      
          });   
        });
        return next >= total && !!!areMultipleAtStartIndex && alternatives.length > 1
    }

    const sentenceBuildWordGroups = (alternatives) => {
      let isSentenceBuilder = true
      alternatives.sort((a, b) => b.startIndex + a.startIndex).forEach(alternative => {
      });
    }

    const isHumanVoiceMissing = () => {
      let containsAutoVoice = -1
      data.testOptions.forEach(to => {
        if(to.textAudioUrl && containsAutoVoice < 1 && to.audioPlayType !== 0) {
          if(to.textAudioUrl?.includes('snippet')) {
            containsAutoVoice = 1
          } else {
            containsAutoVoice = 0
          }
        } 
      });
      return containsAutoVoice
    }
  return (
    <>
      <Zoom in={true}>
        <div>
          <Card style={{minHeight: '135px', width: '200px', backgroundColor: 'white', borderStyle: 'solid',
              borderWidth: data?.validationErrors?.length > 0 ? '2px' : 0,
              borderColor: data?.validationErrors?.length > 0 ? 'red' : 'white',
              boxShadow: '0px 4px 4px 0px #00000040',}}>                
            <Grid container >
              <Grid style={{backgroundColor: '#8E8E93', height: '35px'}} item xs={12} sm={12} md={12}>
                <Typography 
                  variant="body1" 
                  style={{
                    height: '100px',
                    paddingTop: '4px',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontFamily: 'Lato',
                  }}>
                    Test Stage
                </Typography>
              </Grid>
              <Grid container style={{marginLeft: '10px', marginRight: '15px', marginTop: '10px'}}>
                <Grid item xs={10} sm={10} md={10}>
                  <Grid container spacing={1}>
                  {data.testOptions.length > 2 && voiceTypeSearch('robot', [data.testOptions[2]]) == 1 && 
                    <Grid item >
                      <div style={{}}>
                          <RecordVoiceOverRobot 
                            style={{
                              justifyContent: 'right',width: '20px', height: '20px',
                              borderColor: 'white', backgroundColor: '#EFEFF4',
                              textAlign: 'right',
                              alignItems: 'right',borderRadius: '10px', padding: '5px'
                            }} 
                            sx={{"& .MuiBadge-badge": {color: "#FF5159", backgroundColor: "#FF5159"}}}>
                              <MessageRoundedIcon />
                          </RecordVoiceOverRobot>
                      </div>
                    </Grid>
                  }
                  {
                    data.testOptions.length > 2 && voiceTypeSearch('human', [data.testOptions[2]]) === 1 && 
                    <Grid item >
                      <div style={{}}>
                        <RecordVoiceOverHuman style={{justifyContent: 'right',width: '20px', height: '20px',
                        borderColor: 'white', backgroundColor: '#EFEFF4',
                        textAlign: 'right',
                        alignItems: 'right',borderRadius: '10px', padding: '5px'}} sx={{"& .MuiBadge-badge": {color: "#FF5159", backgroundColor: "#FF5159"}}}>
                          <MessageRoundedIcon />
                        </RecordVoiceOverHuman>
                      </div>
                    </Grid>
                  }
                  {
                    data.testOptions.length > 2 && voiceTypeSearch('none', [data.testOptions[2]]) === 1 &&
                    <Grid item >
                      <div style={{}}>
                        <VolumeOffIcon style={{justifyContent: 'right',width: '20px', height: '20px',
                        borderColor: 'white', backgroundColor: '#EFEFF4',
                        textAlign: 'right',
                        alignItems: 'right',borderRadius: '10px', padding: '5px'}} sx={{"& .MuiBadge-badge": {color: "#FF5159", backgroundColor: "#FF5159"}}}>
                          <MessageRoundedIcon />
                        </VolumeOffIcon>
                      </div>
                    </Grid>
                  }
                  </Grid>
                </Grid>
                { 
                  data?.authorNoteDtos?.length > 0 && data?.authorNoteDtos?.filter(x => x.authorNoteType === CustomLessonAuthorNoteType.Created).length > 0 &&
                  <Grid item xs={2} sm={2} md={2} style={{display: 'inline', width: '100%'}}>
                    <Badge variant="dot" style={{width: '20px', height: '20px', borderColor: 'white',
                              borderRadius: '10px', padding: '2px',}} sx={{"& .MuiBadge-badge": {color: "#FF5159", backgroundColor: "#FF5159"}}}>
                      <MessageRoundedIcon style={{}}/>
                    </Badge>
                  </Grid>
                }
              </Grid>
              <Grid item xs={12} sm={12}>
                <React.Fragment>
                  <Card 
                    style={{minHeight: '45px', width: '180px',
                    boxShadow: 'none',
                    display: 'inline-flex',
                    flex: 1,
                    justifyContent: 'left',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    alignItems: 'center'}}>  
                      <Typography variant="body1" component="p">
                        { data.testOptions?.length > 1 ?   data.testOptions[1].text : ""}
                      </Typography>
                  </Card>
                  <Divider variant="middle" />
                  <Card 
                    style={{minHeight: '45px', width: '180px',
                    boxShadow: 'none',
                    display: 'inline-flex',
                    flex: 1,
                    justifyContent: 'left',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    alignItems: 'center'}}>  
                      <Typography variant="body1" component="p">
                      { data.testOptions?.length > 2 ?   data.testOptions[2].text : ""}
                      </Typography>
                  </Card>
                  <Divider variant="middle" />
                  <Card 
                    style={{minHeight: '45px', width: '180px',
                    boxShadow: 'none',
                    display: 'inline-flex',
                    flex: 1,
                    justifyContent: 'left',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    alignItems: 'center'}}>  
                      <Typography variant="body1" component="p">
                      { data.testOptions?.length > 2 ? displayInteractionType(data.testOptions[2]) : ""}
                      </Typography>
                  </Card>
                </React.Fragment>             
              </Grid>       
          </Grid>
          <Divider style={{width:'100%'}}/>
          {
            data.canEditLesson &&
            <Grid style={{backgroundColor: 'white', height: '40px'}} className={'nodrag'} item xs={12} sm={12} md={12}>
              <Button 
                disabled={data.isBusy}
                onClick={handleDuplicate} 
                style={{
                  color: data.isBusy? 'lightgrey' : '#4aa7ec',
                  width: '67px',
                  boxShadow: 'none',
                  borderRadius: '0',
                  borderLeft: '0px',
                  borderTop: '0px',
                  borderBottom: '0px',
                  borderColor: '#D1D1D6',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  height: '40px'
                }} 
                variant="outlined"
                size="small"
                color="primary"
              >
                Duplicate
              </Button>
              <Button 
                disabled={data.isBusy}
                onClick={handleAdd}
                style={{
                  color: data.isBusy? 'lightgrey' : '#4aa7ec',
                  width: '66px',
                  boxShadow: 'none',
                  borderRadius: '0',
                  borderLeft: '0px',
                  borderTop: '0px',
                  borderBottom: '0px',
                  borderColor: '#D1D1D6',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  height: '40px'
                }}
                variant="outlined"
                size="medium" 
                color="primary"
              >
                Definition
              </Button>
              <Button
                disabled={data.isBusy}
                onClick={handleAddOther}
                style={{
                  color: data.isBusy? 'lightgrey' : '#4aa7ec',
                  width: '66px',
                  boxShadow: 'none',
                  borderRadius: '0',
                  borderLeft: '0px',
                  borderTop: '0px',
                  borderBottom: '0px',
                  borderColor: '#D1D1D6',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  height: '40px'
                }} 
                variant="outlined"
                size="medium"
                color="primary"
              >
                Other
              </Button>
            </Grid>
          }
        </Card>
      </div>
      </Zoom>
      <CircleIcon
      disabled={true} style={{position: 'absolute', top: '70px', left: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
    <Handle
        type="target"
        position="left"
        id="target1"
        style={{ top: '77px', left: -9, width: '30px', height: '60px', background: 'transparent',
        zIndex: 999,
        border: 'none', }}
      >
    </Handle>
    <CircleIcon
      disabled={true} style={{position: 'absolute', top: '70px', right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
    <Handle
      type="source"
      position="right"
      id="exit0"
      style={{ top: '74px',  width: '30px', height: '60px', borderRadius: 0, right: '-6px', background: 'transparent', zIndex: 999,border: 'none',
    }}
  />
    </>
  );
});