import React, {useEffect, useState} from "react";
import {
    Grid,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import GuideListenBasics from "../../../../assets/images/GuideListenBasics.png";

const useStyles = makeStyles(theme => ({
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    //marginRight: '15px',
    cursor: 'pointer',
  },
  leftBox: {
/* Gray 3 */
background: '#EFEFEF',
border: '1px solid #D1D1D6',
boxSizing: 'border-box',
boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
borderRadius: '20px'
  },
  leftPanel: {
    minWidth: '400px'
  },
  mainContainer: {
    paddingLeft: '25px',
    paddingRight: '25px'
  }, 
  rightPaper: {
    /* White */
  //  marginLeft: '20px',
marginTop: '30px',
background: '#FFFFFF',
/* Gray 3 */

border: '1px solid #D1D1D6',
boxSizing: 'border-box',
borderRadius: '15px',
  }


}));

const TutorialPuzzle = (props) => {
    const classes = useStyles();
    const tutorialParagraph = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontSize: '16px', marginBottom: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialSubHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', marginTop: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px'}} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', marginBottom: '20px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }


    return (
      <Grid container style={{paddingLeft: '50px', paddingTop: '40px', paddingRight: '20px'}}> 
          {tutorialHeader("The Puzzle Stage")}
        <Grid item xs={12} sm={12} md={12} style={{display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: '35px'}}>
              <iframe src="https://player.vimeo.com/video/692172812?h=35dc55e622" style={{alignItems: 'center'}} width="768" height="432"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Grid>
          {tutorialSubHeader("What is it?")}
          {tutorialParagraph("Practice comprehension and retention by match pairs of words, short phrases, sentences or instructions in a sliding puzzle game.")}
          {tutorialSubHeader("How to make it?")}
          {tutorialParagraph("- Write an optional title in either L1 or L2")}
          {tutorialParagraph("- Click 'Add new block pair'")}
          {tutorialParagraph("- Make a pair that matches, for example from a definition stage. A pair can be vocabulary practice such as 'How are you?' and 'Wie geht's?' or using the same language, for instance 'I am feeling' and 'happy', which together make a sentence.")}
          {tutorialParagraph("- You need at least three pairs in total in the puzzle.")}
          {tutorialParagraph("- The alternatives will appear randomised in the app")}
          {tutorialSubHeader("Make puzzle blocks from other lesson stages in a couple of clicks")}
          {tutorialParagraph("If you have Definition and Test stages already in the lesson, the puzzle stage is a great way of repeating the content of these stages.")}
          {tutorialParagraph("To make this quick and easy, there is a 'Create blocks from other stages' button.")}
          {tutorialParagraph("Clicking this block will allow you to rapidly import suitable items from the lesson's other stages.")}
      </Grid>
    );
    }

    TutorialPuzzle.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(TutorialPuzzle)