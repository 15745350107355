import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core'
import {topicActions} from '../../../_state/actions';

import CreatorCoursesComponent from "./CreatorCoursesComponent";


const useStyles = makeStyles(theme => ({
    root: {
      marginLeft: '15%',
    },
    leftSideButtons: {
      marginTop: -7,
      height: '40px',
      align: 'right',
      minWidth: '120px',
      textAlign: 'center',
    },

  }
));

const CreatorCourseList = (props) => {
  const classes = useStyles();

  const [openCreateCourseGroup, setOpenCreateCourseGroup] = useState(false);
  const [organizationCourseGroups, setOrganizationCourseGroups] = useState([]);
  const [organizationTopics, setOrganizationTopics] = useState([]);

  useEffect(() => {
    props.dispatch(topicActions.getAllTopicsForOrganisation(props.organization.selectedOrganization))

  }, []);

  useEffect(() => {
    if (props.topic.topics?.length > 0) {
      setOrganizationTopics(props.topic.topics);
    }
  }, [props.topic.topics])


  return (
    <div className={classes.root}>
      
      <Grid container>
        <Grid item xs={12}>
          {
            (props.user.organizationRole === "admin") &&
            <CreatorCoursesComponent
              topics={organizationTopics}
            />
          }
        </Grid>

      </Grid>
    </div>
  )
}

function mapStateToProps(state) {
  const {organization, user, courseGroup, topic} = state;
  return {
    organization,
    user,
    courseGroup,
    topic,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatorCourseList)
