import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import compose from "recompose/compose";
import {connect} from "react-redux";
import SGTable from "Components/SGTable/SGTable";

const styles = theme => ({

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  button: {
    backgroundColor: 'primary',
    fontColor: 'white',
    color: 'white',
    borderRadius: 4,
  },
  button1: {
    backgroundColor: 'primary',
    fontColor: 'black',
    color: 'white',
    borderRadius: 4,
  },
});

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class CustomizedDialogDemo extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { open = false, textTitle, textLine1, onButton1, onButton2, button1Text, button2Text, textfield1Text, textfield1Value, onTextFieldChange, textfieldid, classes, list, listTitle, button1Variant='contained', button2Variant='contained' } = this.props;
    let listArray = []
    list && Object.keys(list).forEach(function (item) {
      listArray.push({"item": item, "value": list[item] == null || list[item] == false ? list[item] == false ? "False" : "null" : list[item]})
    })
    const columnData = [
      {
          id: 'item',
          numeric: false,
          disablePadding: true,
          label: 'item'
      },
      {
          id: 'value',
          numeric: false,
          disablePadding: true,
          label: 'value'
      },
  ];
 
    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle  onClose={this.handleClose}>
            {textTitle}
          </DialogTitle>
          <DialogContent>            
            <Typography gutterBottom>
              {textLine1}
            </Typography>
            <Typography gutterBottom>
           
            </Typography>
            <Typography gutterBottom>
         
            </Typography>
            {textfield1Text &&  
            <TextField
              id={textfieldid}
              label={textfield1Text}
              className={classes.textField}
              value={textfield1Value}
              onChange={onTextFieldChange}
            />
            }
            {list &&
            <SGTable
              showTableOptions={true}
              data={listArray}
              columnData={columnData}
              order='asc'
              title={listTitle}
              orderBy='name'
              disableDelete
              rowsPerPage={30}
            />
            }
          </DialogContent>
          <DialogActions style={{marginTop:'10px'}}>
            {
              button1Text &&
              <Button
                style={{
                  marginLeft:'10px'
                }}
                variant= {button1Variant}
                onClick={onButton1} color="primary">
                {button1Text}
              </Button>
            }
            <div style={{flex: '1 0 0'}} />
            {
              button2Text &&
              <Button 
                className={classes.button}
                style={{
                  marginRight:'10px'
                }}
                variant={button2Variant}
                onClick={onButton2}
                color="primary"
              >
                {button2Text}
            </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {} = state;
  return {};
}

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
);

export default enhance(CustomizedDialogDemo);

