import {asyncRequest} from './asyncRequest'

export const userConstants = {
    GETALL: new asyncRequest('USERS_GETALL'),
    GETUSERINFO: new asyncRequest('USERS_GETUSERINFO'),
    DELETE: new asyncRequest('USERS_DELETE'),
    UPDATEUSER: new asyncRequest("USERS_UPDATEUSER"),
    GET_USER_STATS: new asyncRequest("GET_USER_STATS"),
    RESEND_INVITE: new asyncRequest("RESEND_INVITE"),
    ASSIGN_ROLE: new asyncRequest("ASSIGN_ROLE"),
    ADMIN_UPDATE_USER: new asyncRequest("ADMIN_UPDATE_USER"),
    ADMIN_UPDATE_USER_MASTER_ORGANIZATION: new asyncRequest("ADMIN_UPDATE_USER_MASTER_ORGANIZATION"),
    GET_USER_ACTIVITY: new asyncRequest("GET_USER_ACTIVITY"),
    UPDATE_USER_ORGANIZATION_ROLE: new asyncRequest("UPDATE_USER_ORGANIZATION_ROLE"),
    CREATE_USER_MESSAGE: new asyncRequest("CREATE_USER_MESSAGE"),
    
    SET_USER_DASHBOARD_LANGUAGE: new asyncRequest('SET_USER_DASHBOARD_LANGUAGE'),
    
    GET_USER_COURSE_GROUPS: new asyncRequest('GET_USER_COURSE_GROUPS'),
    GET_USER_COURSE_GROUP_PROGRESSION: new asyncRequest('GET_USER_COURSE_GROUP_PROGRESSION'),
    GET_USER_LESSON_PROGRESSIONS: new asyncRequest('GET_USER_LESSON_PROGRESSIONS'),
};

export const userActions = {
    getAllSchools() {
        return {
            type: userConstants.GETALL.REQUEST,
        }
    },
    
    createUserMessage(userId, startLessonMessage, userMessageType, organizationId) {
        return {
            type: userConstants.CREATE_USER_MESSAGE.REQUEST,
            payload: {
                userId: userId,
                message: startLessonMessage,
                userMessageType: userMessageType,
                organizationId: organizationId,
            }
        }
    },

    getUserInfo(selectedOrganization) {
        return {
            type: userConstants.GETUSERINFO.REQUEST,
            payload: {
                selectedOrganization,
            }
        }
    },

    _delete(userId) {
        return {
            type: userConstants.DELETE.REQUEST,
            payload: {
                userId,
            }
        }
    },

    updateUser(name, l1Id, l2Id, email, completedOnboarding) {
        return {
            type: userConstants.UPDATEUSER.REQUEST,
            payload: {
                name: name,
                l1Id: l1Id,
                l2Id: l2Id,
                email: email,
                completedOnboarding: completedOnboarding,
            }
        }
    },

    getUserStats(organizationId, userId) {
        return {
            type: userConstants.GET_USER_STATS.REQUEST,
            payload: {
                organizationId,
                userId,
            }
        }
    },

    resendInvite(organizationId, userId) {
        return {
            type: userConstants.RESEND_INVITE.REQUEST,
            payload: {
                organizationId,
                userId,
            }
        }
    },


    adminUpdateUserMasterOrganization(userId, organizationId, currentOrganizationId) {
        return {
            type: userConstants.ADMIN_UPDATE_USER_MASTER_ORGANIZATION.REQUEST,
            payload: {
                userId,
                organizationId,
                currentOrganizationId
            }
        }
    },
    
    adminUpdateUser(l1Id, l2Id, userId, organizationId, updateUserComplete) {
        return {
            type: userConstants.ADMIN_UPDATE_USER.REQUEST,
            payload: {
                l1Id,
                l2Id, 
                userId,
                organizationId,
                updateUserComplete
            }
        }
    },

    getUserWordProgress(organizationId, userId) {
        return {
            type: userConstants.GET_USER_WORD_PROGRESS.REQUEST,
            payload: {
                organizationId,
                userId,
            }
        }
    },

    getUserActivity(organizationId, userId, fromDate, toDate) {
        return {
            type: userConstants.GET_USER_ACTIVITY.REQUEST,
            payload: {
                organizationId,
                userId,
                fromDate,
                toDate
            }
        }
    },
    
    updateUserRole(organizationId, userId, userRole) {
        return {
            type: userConstants.UPDATE_USER_ORGANIZATION_ROLE.REQUEST,
            payload: {
                organizationId,
                userId,
                userRole,
            }
        }
    },

    setDashboardLanguage(languageId) {
        return {
            type: userConstants.SET_USER_DASHBOARD_LANGUAGE.REQUEST,
            payload: {
                languageId
            }
        }
    },
    
    
    getUserCourseGroups(organizationId, userId) {
        return {
            type: userConstants.GET_USER_COURSE_GROUPS.REQUEST,
            payload: {
                organizationId,
                userId
            }
        }
    },
    
    getUserCourseGroupProgression(organizationId, userId, courseGroupId) {
        return {
            type: userConstants.GET_USER_COURSE_GROUP_PROGRESSION.REQUEST,
            payload: {
                organizationId,
                userId,
                courseGroupId
            }
        }
    },
    getUserLessonProgressions(organizationId, userId) {
        return {
            type: userConstants.GET_USER_LESSON_PROGRESSIONS.REQUEST,
            payload: {
                organizationId,
                userId
            }
        }
    }
    
};

