import React, {useEffect, useState} from "react";
import {
  Grid, MenuItem, Select,
  TextField,
  Typography,
} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '98px',
    top: 0,
    position: 'fixed',
    zIndex: 100,
  },
  
  alternativeTextFieldStyle: {
    boxShadow: 'none',
    color: 'black',
    fontFamily: 'Lato',
    fontWeight: '500',
    letterSpacing: '0.02em',
    display: 'flex',
  },

  correctInputField: {
    background: "rgba(155, 216, 160, 0.25)",
    disableUnderline: true,
    shrink: 'false',
  },
  
  wrongInputField: {
    background: "rgba(255, 102, 110, 0.25)",
    disableUnderline: true,
    shrink: 'false'
  },
}));

const MultiChoiceAlternativeEditor = (props) => {
  const classes = useStyles();

  return (
    <Grid container 
          spacing={1}
          alignItems="center">
      {
        props.testOption &&
        props.testOption.text.length > 0 &&
        <Grid item xs={12} sm={12} md={12}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.alternativeTextFieldStyle}>
              Correct Answer
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{}}>
            <TextField
              fullWidth
              multiline
              defaultValue={
                props.testOption.alternatives && props.testOption.alternatives.length > 0 ?
                  props.testOption.alternatives[0].text : ""
              }
              disabled={!props.canEditLesson}
              onChange={(e) => props.handleUpdateMultiChoiceAlternativeChanged(e.target.value, props.testOptionIndex, 0)}
              label={"Type the correct answer"} style={{borderRadius: '10px', paddingRight: '5px'}}
              InputProps={{className: classes.correctInputField}}
              className={classes.textFieldAnswers}
              variant="filled"
            />
          </Grid>
        </Grid>
      }

      {
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            className={classes.alternativeTextFieldStyle}>
            Incorrect answers (min 1, max 3)
          </Typography>
        </Grid>
      }

      {
        props.testOption &&
        props.testOption.text.length > 0 &&
        props.testOption.alternatives &&
        props.testOption.alternatives.length > 0 &&
        <Grid item xs={12} sm={12} md={12} style={{}}>
          <TextField
            fullWidth
            multiline
            defaultValue={props.testOption.alternatives[1] ? props.testOption.alternatives[1].text : ""}
            disabled={!props.canEditLesson}
            onChange={(e) => props.handleUpdateMultiChoiceAlternativeChanged(e.target.value, props.testOptionIndex, 1)}
            label={"Type an incorrect answer"} style={{borderRadius: '10px', paddingRight: '5px'}}
            InputProps={{className: classes.wrongInputField}}
            className={classes.textFieldAnswers}
            variant="filled"
          />
        </Grid>
      }

      {
        props.testOption &&
        props.testOption.text.length > 0 &&
        props.testOption.alternatives &&
        props.testOption.alternatives.length > 1 &&
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            multiline
            defaultValue={props.testOption.alternatives[2] ? props.testOption.alternatives[2].text : ""}
            disabled={!props.canEditLesson}
            onChange={(e) => props.handleUpdateMultiChoiceAlternativeChanged(e.target.value, props.testOptionIndex, 2)}
            label={"Type another incorrect answer"} style={{borderRadius: '10px', paddingRight: '5px'}}
            InputProps={{className: classes.wrongInputField}}
            className={classes.textFieldAnswers}
            variant="filled"
          />
        </Grid>
      }

      {
        props.testOption &&
        props.testOption.text.length > 0 &&
        props.testOption.alternatives &&
        props.testOption.alternatives.length > 2 &&
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            multiline
            defaultValue={props.testOption.alternatives[3] ? props.testOption.alternatives[3].text : ""}
            disabled={!props.canEditLesson}
            onChange={(e) => props.handleUpdateMultiChoiceAlternativeChanged(e.target.value, props.testOptionIndex, 3)}
            label={"Type another incorrect answer"} style={{borderRadius: '10px', paddingRight: '5px'}}
            InputProps={{className: classes.wrongInputField}}
            className={classes.textFieldAnswers}
            variant="filled"
          />
        </Grid>
      }

    </Grid>
  );
};

MultiChoiceAlternativeEditor.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
    organization: state.organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(MultiChoiceAlternativeEditor)