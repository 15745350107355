import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Grid,
  Button, Typography,
} from '@material-ui/core'
import {courseGroupActions, organizationActions, topicActions} from '../../../_state/actions';
import {FormattedMessage} from 'react-intl';
import CreatorCourseGroupComponent from "./CreatorCourseGroupComponent";
import CreateCourseGroupModal from "./CreateCourseGroupModal";
import {history} from "../../../_state/helpers";
import {CourseGroupType} from "../../../Logic/CourseGroupConstants";
import HomeIcon from '@mui/icons-material/Home';
import {SvgIcon} from "@mui/material";
import {CapeeshColors} from "../../../assets/ColorPalette";

const useStyles = makeStyles(theme => ({
    root: {
      marginLeft: '15%',
    },
    leftSideButtons: {
      color: 'white',
      textTransform: 'none',
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '20px',
      paddingRight: '20px',
      borderRadius: '9.56863px'
    },
    capHeader: {
      margiTop: '20px',
      display: "flex",
      flexWrap: 'nowrap',
      flexGrow: 1,
      width: "100%",
      borderTop: "4px solid lightgray",
    },
}));

const CreatorDashboard = (props) => {
  const classes = useStyles();
  
  const [openCreateCourseGroup, setOpenCreateCourseGroup] = useState(false);
  const [organizationCourseGroups, setOrganizationCourseGroups] = useState([]);
  const [organizationTopics, setOrganizationTopics] = useState([]);
  const [newlyCreatedCourseGroupId, setNewlyCreatedCourseGroupId] = useState(false);

  
  useEffect(() => {
    props.dispatch(courseGroupActions.createCourseGroupReset())
    props.dispatch(organizationActions.getAvailableCourseGroups(props.organization.selectedOrganization))
  }, []);

  useEffect(() => {
    if (props.organization.availableCourseGroups) {
      setOrganizationCourseGroups(props.organization.availableCourseGroups.filter(x => x.organizationId === props.organization.selectedOrganization));
    }
  }, [props.organization.availableCourseGroups])

  useEffect(() => {
    // listen for new course group successfully created and reset redux layer
    if (props.courseGroup.newlyCreatedCourseGroupId && props.courseGroup.creatingCourseGroup) {
      let courseGroupId = props.courseGroup.newlyCreatedCourseGroupId
      setNewlyCreatedCourseGroupId(props.courseGroup.newlyCreatedCourseGroupId)
      props.dispatch(courseGroupActions.createCourseGroupReset())
      props.dispatch(organizationActions.getAvailableCourseGroups(props.organization.selectedOrganization));
      //history.push("/creator/course/group/" + courseGroupId)

    }
  }, [props.courseGroup.newlyCreatedCourseGroupId])
  
  
  const handleGoToCourseListClicked = () => {
    history.push("/creator/topic/list");
  }
  
  const handleCreateCourseGroup = (name, description, l2Id, l1Id) => {
    let courseGroupDto = {
      name: name,
      nameLanguageId: l2Id,
      description: description,
      descriptionLanguageId: l2Id,
      l2Id: l2Id,
      l1Id: l1Id,
      organizationId: props.organization.selectedOrganization,
      shared: false,
      courseGroupType: CourseGroupType.NotDefined
    }
    
    setOpenCreateCourseGroup(false);
    
    props.dispatch(courseGroupActions.createCourseGroup(courseGroupDto))
    
  }

  
  const handleEditCourse = (topicId) => {
    this.props.dispatch(topicActions.clearTopicData());
    history.push('courses/' + topicId);
  }
  
  return (
    <div>
      {
        openCreateCourseGroup &&
        
        <CreateCourseGroupModal
          onCreateCourseGroup={handleCreateCourseGroup}
          onCancel={() => setOpenCreateCourseGroup(false)}
          open={openCreateCourseGroup}
        />
      }
      
      
      <Grid container style={{marginTop: '20px', marginLeft: '10%', width: '80%', paddingTop: '20px'}}>
        <Grid item xs={9}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
          <HomeIcon sx={{ fontSize: '40px', paddingTop: '2px', color:CapeeshColors.PurpleBright }} />
          <Typography variant="h4"  style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 700, fontSize: '38px', lineHeight: '44px', letterSpacing: '0.4px', }}>
            <FormattedMessage id="dashboard.creator.header.title" defaultMessage="Create" />
          </Typography>
        </div>
        </Grid>
        <Grid item xs={3}>
          {
            (props.user.organizationRole === "admin") &&
            <Button
              className={classes.leftSideButtons}
              style={{marginLeft: '30px', float: 'right'}}
              variant={"contained"}
              color={"primary"}
              onClick={() => handleGoToCourseListClicked()}
            >
              <FormattedMessage id="dashboard.creator.button.gototopiclist" defaultMessage="Go to topic list"/>
            </Button>
          }
        </Grid>

      </Grid>
      <Grid item xs={12} className={classes.capHeader}>
      </Grid>
      <div style={{
        background: '#EFEFF4',
        backgroundColor: CapeeshColors.EggShell1,
        backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(238, 237, 234  ) 1px, transparent 0)',
        backgroundSize: '12px 12px',
        paddingTop: '20px',
        paddingBottom: '20px',
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "stretch",}}>


      
      <Grid item xs={12} style={{marginLeft: '10%', width: '80%'}}>
            <Typography variant="h4"  style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '23px', background: 'white', backgroundColor:'white', borderRadius: '10px', padding: '20px',}}>
              <FormattedMessage id="dashboard.creator.header.description" defaultMessage="Welcome to the Capeesh Authoring Platform! From here you can create fantastic learning content. Your list of courses is shown below. Each course contains topics, which themselves contain lessons." />
            </Typography>
      </Grid>
      <Grid container style={{marginLeft: '10%', marginTop: '20px', width: '80%'}}>
        <Grid item xs={12}>
          <CreatorCourseGroupComponent
            onCreateNewCourseGroup={() => setOpenCreateCourseGroup(!openCreateCourseGroup)}
            courseGroups={organizationCourseGroups}
            newlyCreatedCourseGroupId={newlyCreatedCourseGroupId}
          />
        </Grid>
      </Grid>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const {organization, user, courseGroup, topic} = state;
  return {
    organization,
    user,
    courseGroup,
    topic,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatorDashboard)
