import React, {useState, useRef} from 'react';
import {
  Typography,
  makeStyles,
} from '@material-ui/core';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
  cardContent: {
    flex: 'auto',
  },
  topicCard: {
    display: 'flex',
    padding: '20px',
    boxShadow: 'none',
  },
  topicCardAction: {
    right: '15px',
    height: 'fit-content',
    margin: 'auto',
  },
  editable: {
    border: '2px solid transparent',
    transition: 'all 0.3s ease',
    '&:focus, &:hover, &$active': {
      border: '2px solid #AAC8E'
    },
}
}));

const ACCStageSettingsInteractionSelection = (props) => {
  const [focusedAttribute, setFocusedAttribute] = useState(null);
  const focusedAttributeRef = useRef();
  focusedAttributeRef.current = focusedAttribute;
  const [focusedText, setFocusedText] = useState("");
  const focusedTextRef = useRef();
  focusedTextRef.current = focusedText;
  const classes = useStyles();

  return (
<Grid container spacing={1} style={{borderRadius:5, background: "#F8F8F8",}}>
    <Grid item xs={12} style={{marginTop: '30px'}}>
        <Typography style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '16px',   color: 'black', letterSpacing: '0.31px', textAlign:'left', }}>
            {props.headerText}
        </Typography>
    </Grid>


  <Grid item xs={12} > 
                <Button
                    onClick={() =>  props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "interactionType", {"interactionType":"Keyboard", "exerciseType": props.exerciseType})
                } 
                 style={{color: props.selectedInteractionStyle === "Keyboard" ? 'white' : '#35323C', borderRadius: '5px', background: props.selectedInteractionStyle === "Keyboard" ? '#44A6EF': 'white', border: props.selectedInteractionStyle === "Keyboard" ? 0 :'1px solid var(--gray-1, #8E8E93)'  , display: 'inline', height: '31px', padding: '0 15px', alignItems: 'center', justifyContent: 'center', gap: '20px', textTransform: 'none', marginRight: '10px'}}>
                  Write the answers
                </Button>
                <Button
                    onClick={() =>  props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "interactionType", {"interactionType":"MultipleChoice", "exerciseType": props.exerciseType})}
                style={{color: props.selectedInteractionStyle === "MultipleChoice" ? 'white' : '#35323C', borderRadius: '5px', background: props.selectedInteractionStyle === "MultipleChoice" ? '#44A6EF': 'white', border: props.selectedInteractionStyle === "MultipleChoice" ? 0 :'1px solid var(--gray-1, #8E8E93)'  , display: 'inline', height: '31px', padding: '0 15px', alignItems: 'center', justifyContent: 'center', gap: '20px', textTransform: 'none', marginRight: '10px'}}>
                  Multiple Choice
                </Button>
                <Button 
                onClick={() =>  props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "interactionType", {"interactionType":"SentenceBuilder", "exerciseType": props.exerciseType})}
                style={{color: props.selectedInteractionStyle === "SentenceBuilder" ? 'white' : '#35323C', borderRadius: '5px', background: props.selectedInteractionStyle === "SentenceBuilder" ? '#44A6EF': 'white', border: props.selectedInteractionStyle === "SentenceBuilder" ? 0 :'1px solid var(--gray-1, #8E8E93)'  , display: 'inline', height: '31px', padding: '0 15px', alignItems: 'center', justifyContent: 'center', gap: '20px', textTransform: 'none', marginRight: '10px'}}>
                  Sentence Builder
                </Button>
                <Button
                    onClick={() =>  props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "interactionType", {"interactionType":"FillInBlanks", "exerciseType": props.exerciseType})}
                style={{color: props.selectedInteractionStyle === "FillInBlanks" ? 'white' : '#35323C', borderRadius: '5px', background: props.selectedInteractionStyle === "FillInBlanks" ? '#44A6EF': 'white', border: props.selectedInteractionStyle === "FillInBlanks" ? 0 :'1px solid var(--gray-1, #8E8E93)'  , display: 'inline', height: '31px', padding: '0 15px', alignItems: 'center', justifyContent: 'center', gap: '20px', textTransform: 'none', marginRight: '10px'}}>
                  Fill in the blanks
                </Button>
              </Grid>
</Grid>
  );
};

ACCStageSettingsInteractionSelection.propTypes = {};

const mapStateToProps = (state) => {
  return {
    languages: state.metadata.metadata.languages,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCStageSettingsInteractionSelection)