import {overallConstants} from "../actions/overall.actions";
import {authConstants} from "../actions";


const initialState = {
  dataDailyUsageAggregated: [],
  dataDailyUserOnboarding: [],
  searchResult: [],
};

export function overall(state = initialState, action) {
  
  switch (action.type) {
    case overallConstants.GET_GRAPH_DATA_DAILY_USER_ONBOARDING.REQUEST:
      return {
        ...state
      };
    case overallConstants.GET_GRAPH_DATA_DAILY_USER_ONBOARDING.SUCCESS:
      return {
        ...state,
        dataDailyUserOnboarding: action.data
      };
      
    case overallConstants.GET_GRAPH_DATA_DAILY_USER_ONBOARDING.FAILURE:
      return {
        ...state,
        error: action.message
      };

    case overallConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED.REQUEST:
      return {
        ...state
      };
    case overallConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED.SUCCESS:
      return {
        ...state,
        dataDailyUsageAggregated: action.data
      };

    case overallConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED.FAILURE:
      return {
        ...state,
        error: action.message
      };

    case overallConstants.GET_OVERALL_SEARCH_RESULT.REQUEST:
      return {
        ...state,
        searchResult: [],
        searching: true
      };
    case overallConstants.GET_OVERALL_SEARCH_RESULT.SUCCESS:
      return {
        ...state,
        searchResult: action.data,
        searching: false
      };

    case overallConstants.GET_OVERALL_SEARCH_RESULT.FAILURE:
      return {
        ...state,
        error: action.message,
        searching: false
      };
      
    case overallConstants.RESET_OVERALL_SEARCH.REQUEST:
      return {
        ...state,
        searchResult: [],
        searching: false
      }
      
    case authConstants.LOGOUT.SUCCESS:
      return initialState;
      
    default:
      return state
  }
}