import React, { memo, useRef, createRef, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import Badge from '@mui/material/Badge';
import {CustomLessonAuthorNoteType, ScenarioNodeType} from "../../../../Logic/ScenarioConstants";
import Zoom from '@mui/material/Zoom';
import CircleIcon from '@mui/icons-material/Circle';

export default memo(({ data }) => {

  const elementsRef = useRef(data.options.map(() => createRef()));
  const  optionsRefs  = ['0' , '1', '2']
  const [isReady, setIsReady] = useState(false);
  const refsArray  =  optionsRefs.map(() => createRef());
  //stateRef.current = nodes;
  const [heights, setHeights] = useState([]);
  const  heightsRef  = useRef()
  heightsRef.current = heights

  const handleDuplicate = (event) => {
   data.onDuplicate(data.id, ScenarioNodeType.Speak)

   event.stopPropagation();
   event.preventDefault();
  };

  const handleAdd = (event) => {
    data.onAdd(data.id, 2)
    event.stopPropagation();
    event.preventDefault();
   };

   const handleAddOther = (event) => {
    data.onAddOther(data.id, 2)
    event.stopPropagation();
    event.preventDefault();
   };

   const handleSuggest = (event) => {
    data.onSuggest(data.id)
    event.stopPropagation();
    event.preventDefault();
   };
   

  return (
    <>
      <Zoom in={true}>
        <div>
        <Card style={{minHeight: '135px', width: '200px', backgroundColor: 'white', borderStyle: 'solid',
          borderWidth: data?.validationErrors?.length > 0 ? '2px' : 0,
          borderColor: data?.validationErrors?.length > 0 ? 'red' : 'white',
          boxShadow: '0px 4px 4px 0px #00000040'
        }}>
            <Grid container >
              <Grid style={{backgroundColor: '#80D387', height: '35px'}} item xs={12} sm={12} md={12}>
                <Typography variant="body1" style={{
                  height: '100px',
                  paddingTop: '4px',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontFamily: 'Lato',
                }}>
                    Branching Stage
                    {
                      data?.authorNoteDtos?.filter(x => x.authorNoteType === CustomLessonAuthorNoteType.Created).length > 0 && 
                      <Badge variant="dot" style={{marginLeft: '7px'}} sx={{"& .MuiBadge-badge": {color: "#FF5159", backgroundColor: "#FF5159"}}}>
                        <MessageRoundedIcon style={{}}/>
                      </Badge>
                    }
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{backgroundColor: 'white', minHeight: '70px'}}>
                {
                  data?.options?.map((el, index) =>
                    <Grid item xs={12} sm={12} key={index}>
                      {
                        <React.Fragment>
                          <Card 
                            ref={elementsRef.current[index]}
                            style={{minHeight: '40px', width: '180px',
                            boxShadow: 'none',
                            display: 'inline-flex',
                            flex: 1,
                            justifyContent: 'left',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            alignItems: 'center'}}>  
                              <Typography variant="body1" component="p">
                                {el}
                              </Typography>
                          </Card>
                          {index +1 !== data.options.length && 
                            <Divider variant="middle" />
                          }
                        </React.Fragment>
                      }
                      </Grid>
                  )
              }
              </Grid>
              <Divider style={{width:'100%'}}/>
              {
                data.canEditLesson &&
                <Grid style={{backgroundColor: 'white', height: '40px'}} item xs={12} sm={12} md={12}>
                  <Button 
                    disabled={data.isBusy} 
                    onClick={handleDuplicate}
                    style={{
                      color: data.isBusy? 'lightgrey' : '#4aa7ec',
                      width: '67px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }} 
                    variant="outlined" 
                    size="small" 
                    color="primary"
                    className={'nodrag'}>
                    Duplicate
                  </Button>
                  <Button 
                    disabled={data.isBusy} 
                    onClick={handleAdd} 
                    style={{
                      color: data.isBusy? 'lightgrey' : '#4aa7ec',
                      width: '66px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }}
                    variant="outlined"
                    size="medium" 
                    color="primary"
                    className={'nodrag'}>
                    Listen
                  </Button>
                  <Button
                    disabled={data.isBusy} 
                    onClick={handleAddOther}
                    style={{
                      color: data.isBusy? 'lightgrey' : '#4aa7ec',
                      width: '66px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }} 
                    className={'nodrag'}
                    variant="outlined"
                    size="medium" 
                    color="primary">
                    Other
                  </Button>
                </Grid>
              }
          </Grid>
        </Card>
      </div>
    </Zoom>
    <CircleIcon
      disabled={true} style={{position: 'absolute', top: '70px', left: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
    <Handle
        type="target"
        position="left"
        id="target1"
        style={{ top: '77px',background: 'transparent', left: -9, width: '30px', height: '60px', zIndex: 999, border: 'none', }}
      >
      </Handle>

    <CircleIcon
        disabled={true} style={{position: 'absolute', top: 54, right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
      <Handle
        type="source"
        position="right"
        id="exit0"
        style={{ top:  58, background: 'transparent', borderRadius: 0, left: '188px', width: '20px', height: '40px', zIndex: 999, border: 'none'}}
      />

     {data?.options?.length > 1 &&
     <React.Fragment>
          <CircleIcon
        disabled={true} style={{position: 'absolute', top: 93, right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
   
      <Handle
          type="source"
          position="right"
          id="exit1"
          style={{ top:  98, background: 'transparent', borderRadius: 0, left: '188px', width: '20px', height: '40px',zIndex: 999, border: 'none'}}
        // style={{ top: heights[0] + heights[1], background: '#555' }}
        />
     </React.Fragment>

     }
     {data?.options?.length > 2 &&
     <React.Fragment>
        <CircleIcon
          disabled={true} style={{position: 'absolute', top: 133, right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', borderRadius: '50%',disabled:'true', zIndex: 1, border: 'none'}} // Fix the position of the icon over here
        />
        <Handle
          type="source"
          position="right"
          id="exit2"
          style={{ top: '138px', background: 'transparent', borderRadius: 0, left: '188px', width: '20px', height: '40px',zIndex: 999, border: 'none'}}
        >
        </Handle>
      </React.Fragment>
     }
    </>
  );
});