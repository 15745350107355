import React, {useEffect, useState} from "react";
import {
    Card,
    Typography,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    //marginRight: '15px',
    cursor: 'pointer',
  },
  cardPlayButton: {
    height: '40px',
    backgroundColor: '#143349',
    display: 'flex',
    justify: "flex-start",
    alignItems: 'center',
    width: '200px',
    marginTop: '5px',
    marginLeft: '10px'
  },
  scenarioDescriptionText: {
    color: '#8e8e93',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    marginTop: '10px'
  }, 
  closeIcon: {
    color: 'white',
    paddingRight: '5px',
    cursor: 'pointer',
  },
  
  circularProgress: {
    color: '#eee',
    fontSize: 36,
    margin: '15px',
  },
}));


const AudioPlayer = (props) => {
    const classes = useStyles();
    
    const [isCurrentAudio, setIsCurrentAudio] = useState(false);
    
    useEffect(() => {
      if (props.textAudioUrl && props.currentAudioTracker?.audioUrl == props.textAudioUrl) {
        setIsCurrentAudio(true);
      } else if (props.textAudioUrl && props.currentAudioTracker?.audioBlobUrl == props.textAudioUrl) {
        setIsCurrentAudio(true);
      } else {
        setIsCurrentAudio(false);
      }
    }, [props.currentAudioTracker]);
    
    return (
      
      <React.Fragment> 
        {
          props.humanAudioAdded ?
          <React.Fragment> 
          <Card className={classes.cardPlayButton}>
            {
              props.audioPlaying && isCurrentAudio && props.isAudioDownloading &&
              <CircularProgress className={classes.circularProgress}/>
            }
            { 
              props.audioPlaying && !props.isAudioDownloading && isCurrentAudio
              ?
              <StopIcon className={classes.playIcon} onClick={() => props.onPlayVoice(props.textAudioUrl )} />
              :
              <PlayArrowIcon className={classes.playIcon} onClick={() => props.onPlayVoice(props.textAudioUrl)} />
            }
            <Typography 
            variant='body1'  
            style={{
            width: '65px',
            boxShadow: 'none',
            flex: 1,
            fontSize: 14,
            color: 'white',
            fontFamily: 'Lato'
  
          }}>
            Recorded voice
          </Typography>
          <CloseIcon className={classes.closeIcon} onClick={() => props.onRemoveVoice(props.scenarioNodeType, props.testOptionId)} />
        </Card>
        </React.Fragment>
      :
        <Typography variant="body1" className={classes.scenarioDescriptionText}  >
          No voice uploaded. Using automatic voice.
        </Typography>
        }
    </React.Fragment> 
    );
}
  
AudioPlayer.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(AudioPlayer)