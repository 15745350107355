import React, {useState} from "react";
import {
  Button,
  Grid,
  Select,
  Typography,
  MenuItem
} from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import {
  AudioFieldType,
  AudioSelectionOptionType,
  ScenarioInteractiveElementType,
  ScenarioNodeType,
} from "../../../../Logic/ScenarioConstants";
import InteractionSelection from "../ScenarioComponents/InteractionSelection";
import UploadFile from "../ScenarioComponents/UploadFile";
import AudioPlayer from "../ScenarioComponents/AudioPlayer";
import AudioRecorder from "../ScenarioComponents/AudioRecorder";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import AudioLibrary from "../Audio/AudioLibrary";
import TextFieldWithLanguageSelector from "../ScenarioComponents/TextFieldWithLanguageSelector";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '98px',
    top: 0,
    position: 'fixed',
    zIndex: 100,
  },
  grow: {
    flexGrow: 1,
  },
  imageViewer: {
    maxWidth: '150px',
    minWidth: '50px',
    height: 'auto'
  },

  textFields: {
    width: '428px',
    background: 'white'
  },
  textFieldAnswers: {
    width: '348px',
    textColor: 'white'
  },

  feedback: {
    width: '428px',
    textColor: 'white',
    marginBottom: '20px'
  },

  answerHeaders: {
    paddingTop: '12px',
    paddingBottom: '8px',
    marginLeft: '13px'
  },
  answersSection: {
    marginBottom: '10px',
  },
  tabs: {
    backgroundColor: 'white',
  },
  buttongroupSection: {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: "left",
    alignItems: 'left',
    paddingRight: '50px'
  },

  cardPlayButton: {
    height: '40px',
    backgroundColor: '#143349',
    display: 'flex',
    justify: "flex-start",
    alignItems: 'center',
    width: '363px'
  },
  formControl: {
    borderRadius: '15px',
  },
  dropZone: {
    position: 'relative',
    height: '30px',
    width: '300px',
    color: '#ccc',
    border: 'dashed 2px #ccc',
    borderRadius: '15px',
    backgroundColor: '#f9f9f9',
    transition: 'all 200ms ease-out',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'block',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },

  dropzoneContainer: {
    marginTop: '20px'
  },

  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    marginRight: '15px',
    cursor: 'pointer',
  },

  nodeTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '22px',
    paddingBottom: '8px',
    color: '#35323C'
  },

  l1ChooserStyle: {
    marginLeft: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '60px',
    height: '60px',
    border: '5px solid #143349',
    boxSizing: 'border-box',
    borderRadius: '10px',
    maxWidth: '7px',
    maxHeight: '40px',
    minWidth: '78px',
    minHeight: '40px',
  },

  buttonAnnouncement: {
    border: '5px solid #143349',
    boxSizing: 'border-box',
    borderRadius: '10px',
    maxWidth: '40px', maxHeight: '40px', minWidth: '40px', minHeight: '40px',
    marginTop: '7px'
  },

  circularProgress: {
    color: '#eee',
    fontSize: 36,
    margin: '15px',
  },

  closeIcon: {
    color: 'white',
    paddingRight: '5px',
    cursor: 'pointer',
  },

  interactions: {
    paddingTop: '10px',
    marginLeft: '10px'
  },

  scenario: {
    paddingTop: '30px'
  },

  saveButton: {
    backgroundColor: '#143349',
    fontColor: 'white',
    color: 'white'
  },

  scenarioDescription: {
    paddingTop: '3px'
  },

  multilineColor: {
    backgroundColor: 'white',
    border: '1px solid #E5E5EA',
    borderRadius: '5px'
  },

  scenarioDescriptionText: {
    color: '#8e8e93',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
  },

  scenarioScenesPreviewSection: {
    marginTop: '20px'
  },

  scenarioMentorPreviewComponent: {
    marginRight: '13px'
  },

  uploadIcon: {
    width: '100%',
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'block',
  },
  divList: {
    maxHeight: 430,
    overflow: 'auto'
  },

  list: {
    height: '1000px'
  },

  ruleTextField: {
    marginBottom: '10px'
  },
  ruleTitle: {
    paddingTop: '30px',
    marginBottom: '10px'
  },
  definitionText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  testImageText: {
    fontSize: 11,
  },
  explanationText: {
    fontSize: 12,
  },
  typographySpeakout: {
    marginBottom: '5px',
    color: '#8e8e93',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  editNodeSectionTitle: {
    width: '70px',
    boxShadow: 'none',
    flex: 1,
    fontSize: 36,
    color: 'black',
    fontFamily: 'Lato'
  },
  editNodeSectionDescription: {
    color: '#8E8E93',
    fontFamily: 'Lato',
    marginBottom: '10px'
  },
  activeVoiceOverButton: {
    backgroundColor: '#143349',
    fontColor: 'white',
    color: 'white',
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '150px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inactiveVoiceOverButton: {
    backgroundColor: 'white',
    fontColor: 'black',
    color: 'black',
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '150px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));


const RulecardRule = (props) => {
  const classes = useStyles();
  const [newAnswer, setNewAnswer] = useState("");
  const [buttonSelected, setButtonSelected] = useState(0);
  const [isBlocked, setIsBlocked] = React.useState(true);

  const handleOnVoiceDrop = (file, testOptionId) => {
    props.onVoiceDrop(file, testOptionId)
  }

  const handleTestOptionTextLanguageTypeChange = (e) => { 
    props.onTestOptionTextLanguageTypeChange(e.target.value, props.testOption, props.index)    
  }
  

  const recordModeSelected = (file, testOptionId) => {
    setButtonSelected(AudioSelectionOptionType.RecordAudio)
    if (isBlocked) {
      navigator.getUserMedia({audio: true},
        () => {
          console.log('Permission Granted');
          setIsBlocked(false)
        },
        () => {
          console.log('Permission Denied');
          setIsBlocked(true)
        },
      );
    }
  }

  const handleAudioLibraryClick = (urlType, apiUrl) => {
    props.onAudioLibraryClick(AudioFieldType.TestOption, apiUrl, props.node.testOptions[props.index].testOptionId)
  } 
  
  const isHumanVoiceAdded = () => {
    return (props.testOption?.textAudioUrl?.includes('snippet') === false)
  }
  return (
    <React.Fragment>
      <Box style={{
        background: '#F2F2F2',
        border: '2px solid #E5E5EA',
        boxSizing: 'border-box',
        borderRadius: '5px',
        marginBottom: '10px'
      }} sx={{boxShadow: 0}}>
        <Grid container>
          {props.shouldMinimise &&
            <Grid item xs={1} sm={1} md={1} style={{background: '#EDEDED'}}>
              <DragIndicatorIcon  style={{ width: 32, height: 32, marginTop: '5px'}} size="small"/>
            </Grid>
        }
          <Grid item xs={props.shouldMinimise ? 11 : 12} sm={props.shouldMinimise ? 11 : 12} md={props.shouldMinimise ? 11 : 12} style={{background: '#EDEDED'}}>               
            <Typography style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: props.shouldMinimise ? 'normal' : 'bold',
              fontSize: props.shouldMinimise ? '12px' : '22px',
              lineHeight: '22px',
              marginTop: '10px',
              marginLeft: props.shouldMinimise ? '-10px' : '15px',
              marginBottom: '10px',
              color: '#35323C'
            }}>
              {props.shouldMinimise ? props.node.testOptions[props.index].text === '' ? 'No rule text added': props.node.testOptions[props.index].text : "Rule " + (props.index + 1)}
            </Typography>
            
            { !!! props.shouldMinimise &&
            <FormControlLabel
              style={{
                marginTop: '-40px',
                marginRight: '10px',
                minWidth: '200px',
                display: 'flex',
                textAlign: 'right',
                alignItems: 'right',
                justifyContent: 'right',
                boxShadow: 'none',
                fontSize: 6,
                color: 'black',
                fontFamily: 'Lato'
              }}
              control={
                <Switch
                  disabled={!props.canEditLesson || props.testOption.scenarioInteractiveElementType === ScenarioInteractiveElementType.Text}
                  checked={props.node.testOptions[props.index].interactionScoringDynamicConfigurationV1Dto?.shouldBeScored}
                  onChange={(e, checked) => {
                    props.onNodeInteractionScoringChanged(props.index, checked)
                  }}
                />
              }
              label={props.node.testOptions[props.index].interactionScoringDynamicConfigurationV1Dto?.shouldBeScored ? "Scoring Enabled" : "Scoring Disabled"}
              labelPlacement="start"
            />
            }
          </Grid>
          {
            props.shouldMinimise === false &&
            <React.Fragment>
              <Grid item xs={12} sm={12} md={12} style={{marginBottom: '15px', marginLeft: '10px', marginTop: '10px'}}>
                <InteractionSelection 
                  testOption={props.testOption}
                  stageType={"rule"}
                  onChangeInteraction={props.onChangeInteraction}
                  testOptionsIndex={props.index}
                  title={"What interaction should this rule use?"}
                  detectInteractionMode={props.detectInteractionMode}
                  canEditLesson={props.canEditLesson}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{
                    boxShadow: 'none',
                    color: 'black',
                    fontFamily: 'Lato',
                    marginLeft: '15px',
                    fontWeight: '500',
                    letterSpacing: '0.02em'
    
                  }}>
                  Rule text
                </Typography>
              </Grid>
            </React.Fragment>
          }
          {
            props.shouldMinimise === false && props.detectInteractionMode(props.node.testOptions[props.index].alternatives, props.node.testOptions[props.index].text) === true &&
            <Grid item xs={12} sm={12} md={12} style={{margin: '10px'}}>
              <TextFieldWithLanguageSelector 
                text={props.node.testOptions[props.index].text}  
                fullWidth={true}
                disabled={!props.canEditLesson} 
                onChange={(event) => props.onChangeTextImageQuestionL2(event.target.value, props.index)}
                onChangeLanguageLanguage={(event) => handleTestOptionTextLanguageTypeChange(event)}
                l2Id={props.languageId}
                l1Id={props.l1Id}
                soloLanguageId={props.l1Id}
                canChangeLanguage={true}
                displayL1OrL2={props.testOption.textLanguageId === props.languageId ? 1 : 0}
              /> 
            </Grid>
          }

        </Grid>
        {
          props.shouldMinimise === false &&
          <Grid item xs={12} sm={12} md={12} className={classes.interactions}>
          
            {props.displayInteraction(props.testOption.alternatives, props.testOption.scenarioInteractiveElementType, props.index, ScenarioNodeType.TestRule, "Rule Text", true, handleTestOptionTextLanguageTypeChange)}
          </Grid>
}
{ !!! props.shouldMinimise &&
  <React.Fragment>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{
                    boxShadow: 'none',
                    color: 'black',
                    fontFamily: 'Lato',
                    marginLeft: '15px',
                    fontWeight: '500',
                    letterSpacing: '0.02em'
    
                  }}>
                  When should the text be spoken out?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{marginBottom: '20px'}} >
                <Select style={{
                  marginLeft: '22px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  marginBottom: '5px',
                  width: '60px',
                  height: '60px',
                  border: '5px solid #143349',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  maxHeight: '40px',
                  minWidth: '200px',
                  minHeight: '40px',
                  marginTop: '10px',
                }} disableUnderline
                        value={props.node.testOptions[props.index].audioPlayType}
                        autoWidth
                        disabled={props.testOption.textLanguageId !== props.languageId}
                        onChange={(e) => props.onChangeWhenSpoken(e.target.value, props.index)}
                >
                  <MenuItem disabled={!props.canEditLesson} value={0}>Never spoken out</MenuItem>
                  <MenuItem disabled={!props.canEditLesson} value={1}>At the start</MenuItem>
                  <MenuItem disabled={!props.canEditLesson} value={2}>At the end</MenuItem>
                </Select>
              </Grid>
              </React.Fragment>
              }
        {
          props.shouldMinimise === false && props.node.testOptions[props.index].audioPlayType !== 0 &&
          <React.Fragment>
            <Grid item xs={12} sm={12} md={12} style={{marginTop: '20px'}}>
              <Typography
                style={{
                  boxShadow: 'none',
                  color: 'black',
                  fontFamily: 'Lato',
                  marginLeft: '15px',
                  fontWeight: '500',
                  letterSpacing: '0.02em'
                }}>
                Voice-over
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center"
                  style={{marginTop: '10px', marginBottom: '20px'}}>
              {
                !!!isHumanVoiceAdded() && props.canEditLesson &&
                <React.Fragment>
                  <Grid item xs={4} sm={4} md={4}
                        style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center',  textTransform: 'none',}}>
                    <Button onClick={() => setButtonSelected(AudioSelectionOptionType.UploadAudio)} className={buttonSelected === AudioSelectionOptionType.UploadAudio ? classes.activeVoiceOverButton : classes.inactiveVoiceOverButton } variant='outlined'>
                      Upload audio
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}
                        style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center',  textTransform: 'none',}}>
                    <Button onClick={() => recordModeSelected()} className={buttonSelected === AudioSelectionOptionType.RecordAudio ? classes.activeVoiceOverButton : classes.inactiveVoiceOverButton }  variant='outlined'>
                      Record audio
                    </Button>
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}
                        style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center',  textTransform: 'none',}}>
                    <Button onClick={() => setButtonSelected(AudioSelectionOptionType.LibraryAudio)} className={buttonSelected === AudioSelectionOptionType.LibraryAudio ? classes.activeVoiceOverButton : classes.inactiveVoiceOverButton }  variant='outlined'>
                      Library
                    </Button>
                  </Grid>
                  
                </React.Fragment>
              }
              
              <Grid item xs={12} sm={12} md={12}
                    style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', minHeight: '50px'}}>

                <AudioPlayer
                  scenarioNodeType={ScenarioNodeType.TestRule} 
                  humanAudioAdded={isHumanVoiceAdded()} 
                  audioPlaying={props.audioPlaying}
                  isAudioDownloading={false} 
                  onPlayVoice={props.onPlayVoice}
                  onRemoveVoice={props.onRemoveVoice}
                  textAudioUrl={props.testOption.textAudioUrl}
                  testOptionId={props.testOption.testOptionId}
                  currentAudioBlobUrl={props.currentAudioBlobUrl}
                  currentAudioTracker={props.currentAudioTracker}
                />
                {
                  buttonSelected === AudioSelectionOptionType.UploadAudio && !!!isHumanVoiceAdded() && props.canEditLesson &&
                  <UploadFile onVoiceDrop={(file) => handleOnVoiceDrop(file, props.testOption.testOptionId)}/>
                }
                {
                  buttonSelected === AudioSelectionOptionType.RecordAudio && !!!isHumanVoiceAdded() && props.canEditLesson &&
                  <AudioRecorder 
                    scenarioNodeType={ScenarioNodeType.TestRule} 
                    onMicVoiceDrop={props.onMicVoiceDrop} 
                    isBlocked={isBlocked} 
                    humanAudioAdded={isHumanVoiceAdded()}
                    audioPlaying={props.audioPlaying} 
                    isAudioDownloading={false}
                    onPlayVoice={props.onPlayVoice} 
                    onRemoveVoice={props.onRemoveVoice}
                    textAudioUrl={props.testOption.textAudioUrl} 
                    testOptionId={props.testOption.testOptionId}
                    noiseSuppression={props.noiseSuppression}
                    onToggleNoiseSuppression={() => props.onToggleNoiseSuppression()}/>
                }

                {
                  buttonSelected === AudioSelectionOptionType.LibraryAudio && !!!isHumanVoiceAdded() && props.canEditLesson &&
                  <div style={{marginTop: '10px', textAlign: 'center', alignItems: 'center',justifyContent: 'center'}}>
                    <AudioLibrary
                      isAudioDownloading={props.isAudioDownloading}
                      onPlayVoice={props.onPlayVoice}
                      audioPlaying={props.audioPlaying}
                      lesson={props.lesson}
                      onAudioLibraryClick={handleAudioLibraryClick}
                      audioFieldType={AudioFieldType.TestOption}
                      filterForVoiceOnlyAudio={true}
                    /> 
                  </div>
                }
                
              </Grid>
            </Grid>
          </React.Fragment>
        }
      </Box>
    </React.Fragment>

  );
};

RulecardRule.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
    organization: state.organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(RulecardRule)