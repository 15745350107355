import React, {Fragment, useEffect, useRef, useState} from 'react';

import {CircularProgress, makeStyles} from "@material-ui/core";

import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import ChapterOptionCard from "./Cards/ChapterOptionCard";
import EditChapterModal from "./EditChapterModal";
import ripImage from "assets/images/paper_tear.svg";
import lockImage from "assets/images/lock.svg";
import editImage from "assets/images/edit.svg";
import deleteImage from "assets/images/deleteX.svg";
import arrowImage from "assets/images/expand_more.svg";
import {autoCourseCreationActions} from "../../../../_state/actions";
import {CapeeshColors} from "../../../../assets/ColorPalette";
import ACCCustomChip from "./Components/ACCCustomChip";
import {v4 as uuidv4} from "uuid";
import ChapterOptionWaitingCard from "./Cards/ChapterOptionWaitingCard";
import {motion} from "framer-motion";

const useStyles = makeStyles(theme => ({
    corePage:
        {
            height: '100%',
            width: '100%'
        },
    centerElements:
        {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
    mainText:
        {
            width: '100%',
            textAlign: 'center',
            color: 'white',
            fontSize: 32,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginBottom: 16
        },
    numberText:
        {
            textAlign: 'center',
            color: 'white',
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginBottom: 16,
            cursor: "pointer"
        },
    courseNarrativeBox:
        {
            width: 632,
            paddingTop: 32,
            paddingLeft: 16,
            paddingRight: 16,
            background: 'white',
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 16,
            display: 'inline-flex',
        },
    courseNarrativeBoxFinished:
        {
            width: 632,
            paddingTop: 32,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 16,
            background: 'white',
            borderRadius: 16,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 16,
            display: 'inline-flex',
            marginBottom: 16
        },
    courseNarrativeText:
        {
            textAlign: 'center',
            color: CapeeshColors.DarkText,
            fontSize: 20,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    courseNarrativeInfoText:
        {
            width: '100%',
            color: CapeeshColors.DarkText,
            fontSize: 14,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginTop: 16,
            marginBottom: 16,
        },
    chapterWithNumberText:
        {
            color: CapeeshColors.DarkText,
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '500',
            wordWrap: 'break-word',
            marginBottom: 8
        },
    chapterWithNumberTextLocked:
        {
            color: 'rgba(96, 96, 96, 0.60)',
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '500',
            wordWrap: 'break-word',
            marginBottom: 8
        },
    chapterDragIntoBox:
        {
            width: 600,
            height: '100%',
            padding: 16,
            background: CapeeshColors.OffWhite,
            borderRadius: 8,
            border: '2px rgba(160, 67, 159, 0.32) dotted',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 10,
            display: 'inline-flex'
        },
    chapterDragIntoBoxText:
        {
            width: '100%',
            color: 'rgba(96, 96, 96, 0.60)',
            fontSize: 14,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    chooseNextChapterText:
        {
            width: '100%',
            textAlign: 'center',
            color: 'white',
            fontSize: 20,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    bottomElementsParentBox:
        {
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 16,
            borderRadius: 8,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 16,
            display: 'inline-flex'
        },
    chapterOptionCardsContainer:
        {
            marginBottom: 16,
            marginTop: 16,
            flexDirection: "row",
            display: "flex",
            justifyContent: "center"
        },
    suggestionBoxesContainer:
        {
            display: "flex"
        },
    suggestionBox:
        {
            width: 172,
            height: 40,
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: 8,
            overflow: 'hidden',
            border: '2px white solid',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
            display: 'inline-flex',
            cursor: "pointer",
            marginRight: 8,
            '&:hover': {
                background: '2px #F8F8F8 solid',
                border: '2px #C7C7CC solid',
            },
        },
    suggestionBoxText:
        {
            textAlign: 'center',
            color: 'white',
            fontSize: 14,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    chosenSummaryText:
        {
            width: '100%',
            color: CapeeshColors.DarkText,
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginBottom: 16
        },
    chosenSummaryTextLocked:
        {
            color: 'rgba(96, 96, 96, 0.60)',
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginBottom: 16,
            width: '100%',
        },
    vocabNumberTextChosenChapter:
        {
            color: CapeeshColors.DarkText,
            fontSize: 14,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginLeft: 8
        },
    vocabNumberTextChosenChapterLocked:
        {
            fontSize: 14,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginLeft: 8,
            color: 'rgba(96, 96, 96, 0.60)'
        },
    mainDialog:
        {
            width: 635,
            padding: 16,
            background: 'white',
            borderRadius: 16,
            flexDirection: 'column',
            display: "flex",
            gap: 16,
        },
    chapterEditTitleText:
        {
            color: CapeeshColors.DarkText,
            fontSize: 20,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginBottom: 16
        },
    chapterSummaryTitleText:
        {
            color: CapeeshColors.DarkText,
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '500',
            wordWrap: 'break-word',
            marginBottom: 8
        },
    buttonContainer:
        {
            width: '100%',
            height: '100%',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            gap: 10,
            display: 'inline-flex'
        },
    cancelButton:
        {
            width: 156,
            height: 48,
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: 8,
            overflow: 'hidden',
            border: '2px rgba(96, 96, 96, 0.30) solid',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
            display: 'flex',
            marginRight: 10,
            cursor: "pointer"
        },
    saveButton:
        {
            width: 136,
            height: 48,
            paddingLeft: 16,
            paddingRight: 16,
            background: CapeeshColors.Blue,
            borderRadius: 8,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
            display: 'flex',
            cursor: "pointer",
        },
    cancelButtonText:
        {
            textAlign: 'center',
            color: CapeeshColors.DarkText,
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    saveButtonText:
        {
            textAlign: 'center',
            color: 'white',
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    topOfDialogContainer:
        {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
    deleteDialogSummaryText:{
        color: 'rgba(52.93, 49.83, 59.50, 0.80)', 
        fontSize: 16, 
        fontFamily: 'Rubik', 
        fontWeight: '400', 
        wordWrap: 'break-word',
        marginBottom: 16
    },
    vocabContainerDropdown:
        {
            width: 631,
            height: 212,
            display: "flex",
            padding: 16,
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            marginBottom: 16,
            background: "white",
            borderRadius: 16
        },
    selectedOverviewChipText:
        {
            color: 'rgba(96, 96, 96, 0.30)',
            fontFamily: "Rubik",
            fontSize:14,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "140%"
        },
    dotDivs:
        {
            width: 4,
            height: 4,
            backgroundColor: "white",
            marginLeft: 2,
            borderRadius: '50%',
            display: "flex",
            alignSelf: "end",
            marginBottom: 8
        }
}));

const AutoLanguageCourseChapterController = (props) => {

    // expected props:

    // props.dispatch

    const {} = props;
    const classes = useStyles();
    const firstOptionDefaultState = "";
    
    const isInitialState = useRef(true);
    
    const [selectedChapterOptions, setSelectedChapterOptions] = useState([]);
    const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
    const [prevChapterIndex, setPrevChapterIndex] = useState(0);
    const [openChapterEditModal, setOpenChapterEditModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [editChapterNumber, setEditChapterNumber] = useState(0);
    const [editChapterSummary, setEditChapterSummary] = useState('');
    const [modalVocabList, setModalVocabList] = useState([]);
    const [usedConcepts, setUsedConcepts] = useState([]);
    const [additionalVocabList, setAdditionalVocabList] = useState([]);
    const [vocabDropdownOpen, setVocabDropdownOpen] = useState(false);
    const [lockedVocabList, setLockedVocabList] = useState([]);
    const [optionsOnScreenSetter, setOptionsOnScreenSetter] = useState([]);
    const [currentChapterOptionsOnScreen, setCurrentChapterOptionsOnScreen] = useState([]);
    const [unwantedChapterOptions, setUnwantedChapterOptions] = useState([]);
    const [isPageVisible, setPageVisible] = useState(true);
    
    const handleChapterOptionClicked = (option) => {
        let tempOptions = selectedChapterOptions;
        
        if(tempOptions?.length > 0){
            let tempVocabsToLock = tempOptions[tempOptions.length - 1].vocabularyInChapter;
            handleAddToLockedList(tempVocabsToLock);   
        }
        
        tempOptions.push(option);
        setSelectedChapterOptions(tempOptions);
        
        handleAddSelectedChaptersToStorage(tempOptions);

        setPrevChapterIndex(currentChapterIndex);
        setCurrentChapterIndex(currentChapterIndex + 1);
        
        if(option.nextChapterOptions !== undefined
            || option.nextChapterOptions?.length > 0
            && usedConcepts.length < props.conceptsAmount) {
            option.nextChapterOptions.forEach(item => handleConstructChaptersOnClick(item));
        }
        
        handleAddVocabsToList(option.vocabularyInChapter, usedConcepts);
        
        setAdditionalVocabList([]);
    }
    
    const handleConstructChaptersOnClick = (item) => {
        if(item.nextChapterOptions === undefined || item.nextChapterOptions?.length <= 0){
            let tempSelectedOptions = [...selectedChapterOptions];
            tempSelectedOptions.push(item);
            handleConstructChapters(tempSelectedOptions, false, false, item.chapterId);
        }
    }
    
    const handleAddToLockedList = (vocabs) => {
        let tempList = [...lockedVocabList];
        for (let i = 0; i < vocabs.length; i++) {
            tempList.push(vocabs[i]);
        }
        
        setLockedVocabList(tempList);
    }
    
    const handleAddSelectedChaptersToStorage = (selectedChapterOptions) => {
      
      
      props.dispatch(autoCourseCreationActions.accLanguageStoreSelectedChapters(selectedChapterOptions));
      
      if (selectedChapterOptions.length === 0) {
        return;
      }
      
      const newChapterDto = selectedChapterOptions[selectedChapterOptions.length-1]

      const previousChapters = selectedChapterOptions.filter(x => x.chapterId !== newChapterDto.chapterId);
      
      const alreadyProcessed = props.autoCourseCreation.lessonPlanChapterDtos?.find(x => x.chapterId === newChapterDto.chapterId)
      const alreadyStartedGenerating = props.autoCourseCreation.expandingChapterIds.findIndex(x => x === newChapterDto.chapterId) > -1;
      if (alreadyProcessed || alreadyStartedGenerating) {
        return;
      }
      let concepts = []
      newChapterDto.vocabularyInChapter.forEach(conceptId => {
        const concept = props.concepts?.find(x => x.conceptId === conceptId);
        if (concept) {
          concepts.push(concept)
        }
      })
      
      props.dispatch(autoCourseCreationActions.accLessonPlanChapterExpand(newChapterDto, concepts, previousChapters, props.autoCourseCreation.authorInputMetadata))
      
    }
    
    const handleRefresh = () => {
        // If this is start of session, create the selected chapter options in redux and generate.
        if(!props.autoCourseCreation.selectedChapterOptions){
            props.dispatch(autoCourseCreationActions.accLanguageStoreSelectedChapters([]));

            setCurrentChapterOptionsOnScreen([]);

            props.handleIsConstructingChapters(true);
            handleConstructChapters([], false, false);

            return;
        }

        // Set current options on screen after refresh if there are options, else construct new chapters
        if(props.autoCourseCreation.selectedChapterOptions?.length <= 0){
            if(props.autoCourseCreation.chapterIdToChapterOptionsMapper.length > 0){
                setOptionsOnScreenSetter(props.autoCourseCreation.chapterIdToChapterOptionsMapper.find(item => {
                    return item.chapterId === firstOptionDefaultState;
                })?.chapterSuggestions);
            }
            else {
                props.handleIsConstructingChapters(true);
                handleConstructChapters([], false, false);
            }

            return;
        }

        // Set local selected chapter options on refresh
        let tempSelectedChapters = props.autoCourseCreation.selectedChapterOptions;
        setSelectedChapterOptions(tempSelectedChapters);

        // Set Used vocabs back after refresh
        let tempUsedVocabs = [];
        tempSelectedChapters.forEach(item => {
            tempUsedVocabs.push(...item.vocabularyInChapter);
        });
        setUsedConcepts(tempUsedVocabs);

        // Set locked vocabs back after refresh
        let tempLockedVocabs = [...tempUsedVocabs];
        tempLockedVocabs = tempLockedVocabs.filter(item => !tempSelectedChapters[tempSelectedChapters.length - 1].vocabularyInChapter.includes(item))
        setLockedVocabList(tempLockedVocabs);

        let tempCurrentCourseId = tempSelectedChapters[tempSelectedChapters.length - 1].chapterId ? tempSelectedChapters[tempSelectedChapters.length - 1].chapterId : firstOptionDefaultState;

        // Set to current options on screen on refresh
        if(props.autoCourseCreation.selectedChapterOptions?.length > 0){

            let tempSelectedOptions = props.autoCourseCreation.selectedChapterOptions;
            if(tempSelectedOptions[tempSelectedOptions.length - 1].nextChapterOptions !== undefined){
                setOptionsOnScreenSetter(tempSelectedOptions[tempSelectedOptions.length - 1].nextChapterOptions);
            }
            else {
                let options = props.autoCourseCreation.chapterIdToChapterOptionsMapper.find(item => {
                    return item.chapterId === tempCurrentCourseId;
                });

                if(options !== undefined){
                    setOptionsOnScreenSetter(options.chapterSuggestions);
                }
                else {
                    handleConstructChapters(props.autoCourseCreation.selectedChapterOptions, false, false,
                        props.autoCourseCreation.selectedChapterOptions[props.autoCourseCreation.selectedChapterOptions.length - 1].chapterId)
                }
            }
        }
    }
    
    const handleRemoveFromLockedList = (vocabs) => {
        let tempList = [];
        for (let i = 0; i < vocabs.length; i++) {
            tempList.push(vocabs[i]);
        }
      
        setLockedVocabList(lockedVocabList.filter(item => !tempList.includes(item)));
    }
    
    const handleConstructChapters = (prevChapters, isTwist, isConclusion, currentChapterId, unwantedOptions) => {
        
        let tempChapterId = currentChapterId ? currentChapterId : firstOptionDefaultState;
        let tempUnwantedOptions = unwantedOptions ? unwantedOptions : unwantedChapterOptions;
        const narrativeRequestDto = {
            authorInputMetadata: props.authorMetadataDto,
            concepts: props.concepts.filter(x => x.usedInLesson),
            previousChapters: prevChapters,
            chapterRequestId: tempChapterId,
            twist: isTwist,
            conclusion: isConclusion,
            unwantedChapterOptions: tempUnwantedOptions
        }

        props.dispatch(autoCourseCreationActions.accLanguageChapterOptions(narrativeRequestDto));
    }

    const handleOpenEditDialog = (element, isEdit, index, vocabList) => {
        setEditChapterNumber(index);
        setEditChapterSummary(element?.chapterSummary);
        setIsEditModal(isEdit);
        setModalVocabList(vocabList);
        openOrCloseModal(true);
    }

    const handleAddVocabsToList = (vocabs, fullListUsed) => {
        let tempListOfVocabs = [...fullListUsed];
        for (let i = 0; i < vocabs.length; i++) {
            if(!tempListOfVocabs.includes(vocabs[i]))
            {
                tempListOfVocabs.push(vocabs[i]);
            }
        }
        
        setUsedConcepts(tempListOfVocabs);
    }

    const handleRemoveVocabsFromList = (vocabs) => {
        let filteredList = usedConcepts.filter(item => !vocabs.includes(item));
        
        if(selectedChapterOptions.length <= 0){
            filteredList = [];
        }

        setUsedConcepts(filteredList);
        
        return filteredList;
    }

    const openOrCloseModal = (setOpen) => {
        setOpenChapterEditModal(setOpen);
    }

    const handleDeleteChapter = () => {
        setCurrentChapterIndex(prevChapterIndex);

        let tempSelectedChapterOptions = selectedChapterOptions;
        let option = tempSelectedChapterOptions.pop();
        setSelectedChapterOptions(tempSelectedChapterOptions);

        handleAddSelectedChaptersToStorage(tempSelectedChapterOptions);
        
        if(tempSelectedChapterOptions.length > 0){
            let tempLockedListVocabs = tempSelectedChapterOptions[tempSelectedChapterOptions.length - 1].vocabularyInChapter;
            handleRemoveFromLockedList(tempLockedListVocabs);   
        }

        handleRemoveVocabsFromList(option.vocabularyInChapter);
    }

    const handleEditChapter = (chapterText, finalVocabs, isEdit) => {

        if(chapterText.length <= 0 || finalVocabs.length <= 0){
            return;
        }
        
        // Is edit modal, not create new chapter.
        if (isEdit){
            let tempSelectedChapterOptions= [...selectedChapterOptions];
            let tempRemovedOption = tempSelectedChapterOptions.pop();

            let nextChapterOptions = undefined;
            if(tempRemovedOption.nextChapterOptions && isEdit){
                nextChapterOptions = tempRemovedOption.nextChapterOptions;
            }

            let tempVocabList = [];
            for (let i = 0; i < finalVocabs.length; i++) {
                tempVocabList.push(finalVocabs[i])
            }

            let tempChapterId = uuidv4().toString();
            let tempChapterOption = {
                chapterSummary: chapterText,
                chapterId: tempChapterId,
                name: tempRemovedOption.name,
                nextChapterOptions: nextChapterOptions,
                vocabularyInChapter: tempVocabList
            };

            let allUsedVocabs = handleRemoveVocabsFromList(tempRemovedOption.vocabularyInChapter);

            tempSelectedChapterOptions.push(tempChapterOption);
            setSelectedChapterOptions(tempSelectedChapterOptions);

            handleAddSelectedChaptersToStorage(tempSelectedChapterOptions);

            setAdditionalVocabList([...finalVocabs]);

            handleAddVocabsToList(tempVocabList, allUsedVocabs);
            handleConstructChapters(tempSelectedChapterOptions, false, false, tempChapterId);   
        }
        else { // Is create new chapter, not edit chapter.
            let tempVocabList = [];
            for (let i = 0; i < finalVocabs.length; i++) {
                tempVocabList.push(finalVocabs[i])
            }

            let tempChapterId = uuidv4().toString();
            let tempChapterOption = {
                chapterSummary: chapterText,
                chapterId: tempChapterId,
                name: "Custom Chapter",
                nextChapterOptions: [],
                vocabularyInChapter: tempVocabList
            };
            
            
            let tempSelectedChapterOptions = [...selectedChapterOptions];
            tempSelectedChapterOptions.push(tempChapterOption);
            setSelectedChapterOptions(tempSelectedChapterOptions);

            handleAddSelectedChaptersToStorage(tempSelectedChapterOptions);

            setAdditionalVocabList(finalVocabs);

            handleAddVocabsToList(tempVocabList, usedConcepts);
            handleConstructChapters(tempSelectedChapterOptions, false, false, tempChapterId);
        }
        
        setOptionsOnScreenSetter([]);
    }

    useEffect(() => {
        props.handleIsConstructingChapters(usedConcepts.length < props.conceptsAmount);
    }, [usedConcepts.length]);

    // Functionality for when the list of selected chapters in redux grows or shrinks.
    useEffect(() => {
        let tempOptions = props.autoCourseCreation.selectedChapterOptions;
        if(tempOptions.length > 0){
            if(tempOptions[tempOptions.length - 1].nextChapterOptions === undefined){
                let option = props.autoCourseCreation.chapterIdToChapterOptionsMapper.find(item => item.chapterId === tempOptions[tempOptions.length - 1].chapterId);
                
                if(option !== undefined){
                    setOptionsOnScreenSetter(option.chapterSuggestions);
                }
                else {
                    setOptionsOnScreenSetter([]);
                    
                    if(!props.autoCourseCreation.isSuggestingForChapterIds.includes(tempOptions[tempOptions.length - 1].chapterId)){
                        handleConstructChapters(tempOptions, false, false, tempOptions[tempOptions.length - 1].chapterId);
                    }
                }
            }
            else if(tempOptions[tempOptions.length - 1]){
                setOptionsOnScreenSetter(tempOptions[tempOptions.length - 1].nextChapterOptions);
            }
            
            return;
        }

        let option =  props.autoCourseCreation.chapterIdToChapterOptionsMapper.find(item => item.chapterId === firstOptionDefaultState);
        if(option){
            setOptionsOnScreenSetter(option.chapterSuggestions);
        }
        
    }, [props.autoCourseCreation.selectedChapterOptions?.length]);

    // Functionality for when mapper in redux updates in any way.
    useEffect(() => {
        let tempMapper = props.autoCourseCreation.chapterIdToChapterOptionsMapper;
        
        if(tempMapper === undefined) return;
        if(tempMapper.length <= 0) return;
        
        let tempSelectedOptions = props.autoCourseCreation.selectedChapterOptions;
        if(tempSelectedOptions !== undefined){
            if(tempMapper.length > 0 && tempSelectedOptions.length > 0){

                if(tempSelectedOptions[tempSelectedOptions.length - 1]?.nextChapterOptions === undefined ||
                    optionsOnScreenSetter.length <= 0){
                    let option =  tempMapper.find(item => item.chapterId === tempSelectedOptions[tempSelectedOptions.length - 1].chapterId);
                    if(option !== undefined){
                        setOptionsOnScreenSetter(option.chapterSuggestions);
                        
                    }
                }

                return;
            }

            let option =  tempMapper.find(item => item.chapterId === firstOptionDefaultState);
            if(option){
                setOptionsOnScreenSetter(option.chapterSuggestions);
            }
        }
    }, [props.autoCourseCreation.chapterIdToChapterOptionsMapper, props.autoCourseCreation.chapterIdToChapterOptionsMapper?.length]);
    
    // Initial functionality for start/refresh
    useEffect(() => {
        handleRefresh();
        
        isInitialState.current = false;
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            setPageVisible(!document.hidden);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        if(isInitialState.current) return;
        
        if (isPageVisible && currentChapterOptionsOnScreen?.length <= 0 
            && props.autoCourseCreation.isSuggestingForChapterIds?.length <= 0) {
            handleRefresh();
        }
    }, [isPageVisible]);

    useEffect(() => {
        setCurrentChapterOptionsOnScreen([]);
        setTimeout(() => setCurrentChapterOptionsOnScreen(optionsOnScreenSetter), 2);
    }, [optionsOnScreenSetter]);
    
    return (
        <Fragment>
            <Typography className={classes.mainText}>
                Create the narrative for the course
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignSelf: "center", justifyContent: "center" }}>
                <Typography className={classes.numberText} onClick={() => setVocabDropdownOpen(!vocabDropdownOpen)}>
                    {
                        usedConcepts.length + "/" + props.conceptsAmount + " items added to a chapter"
                    }
                </Typography>
                <img src={arrowImage} style={!vocabDropdownOpen ? 
                    { marginLeft: 4, marginTop: 4, cursor: "pointer", height: 18, width: 18 } :
                    { marginLeft: 4, marginTop: 4, cursor: "pointer", height: 18, width: 18, transform: 'rotate(180deg)'}}
                     onClick={() => setVocabDropdownOpen(!vocabDropdownOpen)}/>
            </div>
            <motion.div transition={{ duration: 0.25, type: "spring", bounce: 0 }} layout className={classes.corePage}>
                <div className={classes.centerElements}>
                    {
                        vocabDropdownOpen &&
                        <div className={classes.vocabContainerDropdown}>
                            {
                                props.concepts?.map((element, index) =>
                                    !usedConcepts.includes(element.conceptId) ?
                                        <ACCCustomChip key={element.conceptId} index={index} content={element.text}
                                                       customStyle={{
                                                           margin: 2,
                                                           background: '#EFEFF4',
                                                           borderRadius: 16,
                                                           overflow: 'hidden',
                                                           justifyContent: 'center',
                                                           alignItems: 'center',
                                                           gap: 8,
                                                           display: 'inline-flex' ,}}
                                                       customTextClassName={classes.selectedOverviewChipText}/>
                                        :
                                        <ACCCustomChip key={element.conceptId} index={index} content={element.text}
                                                       customStyle={{
                                                           justifyContent: "center",
                                                           alignItems: "center",
                                                           color: 'black',
                                                           margin: 2,
                                                           marginBottom: '5px',
                                                           padding: '3px',
                                                           borderRadius: 16,
                                                           background: '#F3E2F3',}}/>
                                )
                            }
                        </div>
                    }
                    <div className={usedConcepts.length >= props.conceptsAmount ? classes.courseNarrativeBoxFinished : classes.courseNarrativeBox}>
                        <Typography className={classes.courseNarrativeText}>
                            My course narrative
                        </Typography>
                        <Typography className={classes.courseNarrativeInfoText}>
                            To start off your story, select one of the options below. Each option contains a plot summary and a list of vocab items.
                            You may also edit the chapter or choose to write your own.
                        </Typography>
                        {
                            selectedChapterOptions.map((element, index) =>

                                <Fragment key={element.chapterId}>
                                    <div style={{ display: "flex", flexDirection: "row", width: '100%', alignItems: "center", justifyContent: "space-between"  }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Typography className={selectedChapterOptions.length > index + 1 ? classes.chapterWithNumberTextLocked : classes.chapterWithNumberText}>
                                                Chapter {index + 1}
                                            </Typography>
                                            <Typography className={selectedChapterOptions.length > index + 1 ? classes.vocabNumberTextChosenChapterLocked : classes.vocabNumberTextChosenChapter}>
                                                {element.vocabularyInChapter?.length} vocab items
                                            </Typography>
                                        </div>
                                        {
                                            selectedChapterOptions.length > index + 1 &&
                                            <div style={{ alignItems: "end", justifyContent: "end", flexDirection: "row" }}>
                                                <img src={lockImage} style={{ marginRight: 2 }}/>
                                            </div>
                                        }
                                        {
                                            selectedChapterOptions.length <= index + 1 &&
                                            <div style={{ alignItems: "end", justifyContent: "end", flexDirection: "row" }}>
                                                <img src={editImage}  style={{ cursor: "pointer", marginBottom: 2 }}
                                                     onClick={() => handleOpenEditDialog(element, true, selectedChapterOptions.length, () => {
                                                         let tempAdditionalVocabs = [];
                                                         for (let i = 0; i < additionalVocabList.length; i++) {
                                                             tempAdditionalVocabs.push(additionalVocabList[i].conceptId)
                                                         }

                                                         return element.vocabularyInChapter.filter(item => !tempAdditionalVocabs.includes(item));
                                                     })
                                                     }/>
                                                <img src={deleteImage} style={{ cursor: "pointer" }} onClick={() => handleDeleteChapter()}/>
                                            </div>
                                        }
                                    </div>
                                    <Typography className={selectedChapterOptions.length > index + 1 ? classes.chosenSummaryTextLocked : classes.chosenSummaryText}>
                                        {
                                            element.chapterSummary
                                        }
                                    </Typography>
                                </Fragment>

                            )
                        }
                    </div>
                    {
                        usedConcepts.length < props.conceptsAmount &&
                        <div>
                            <img src={ripImage} style={{ marginBottom: 16 }}/>
                        </div>
                    }
                    {
                        usedConcepts.length < props.conceptsAmount &&
                        <div className={classes.bottomElementsParentBox}>
                            {
                                selectedChapterOptions.length <= 0 &&
                                currentChapterOptionsOnScreen?.length > 0 &&
                                <Typography className={classes.chooseNextChapterText}>
                                    Choose your first chapter
                                </Typography>
                            }
                            {
                                selectedChapterOptions.length > 0 &&
                                currentChapterOptionsOnScreen?.length > 0 &&
                                <Typography className={classes.chooseNextChapterText}>
                                    Choose your next chapter
                                </Typography>
                            }
                            {
                                (currentChapterOptionsOnScreen?.length <= 0 || !currentChapterOptionsOnScreen) &&
                                <div style={{ flexDirection: "row", display: "flex", justifyContent: "center"}}>
                                    <Typography className={classes.chooseNextChapterText}>
                                        Generating more chapters
                                    </Typography>
                                    <motion.div className={classes.dotDivs} inital={{ opacity: 0.2 }} animate={{ opacity: [0.2, 1, 0.2] }} transition={{ duration: 0.5, ease: "easeInOut", repeat: Infinity, delay: 0, repeatDelay: 1 }}/>
                                    <motion.div className={classes.dotDivs} inital={{ opacity: 0.2 }} animate={{ opacity: [0.2, 1, 0.2] }} transition={{ duration: 0.5, ease: "easeInOut", repeat: Infinity, delay: 0.5, repeatDelay: 1 }}/>
                                    <motion.div className={classes.dotDivs} inital={{ opacity: 0.2 }} animate={{ opacity: [0.2, 1, 0.2] }} transition={{ duration: 0.5, ease: "easeInOut", repeat: Infinity, delay: 1, repeatDelay: 1 }}/>
                                </div>
                            }
                            <div className={classes.chapterOptionCardsContainer}>
                                {
                                    (currentChapterOptionsOnScreen?.length <= 0 || !currentChapterOptionsOnScreen) &&
                                    <Fragment>
                                        <ChapterOptionWaitingCard/>
                                        <ChapterOptionWaitingCard/>
                                        <ChapterOptionWaitingCard/>
                                    </Fragment>
                                }
                                {
                                    currentChapterOptionsOnScreen?.length > 0 &&
                                    currentChapterOptionsOnScreen?.map((element, index) =>
                                        <ChapterOptionCard
                                            key={element.chapterId}
                                            amountOfVocabs={element.vocabularyInChapter?.length}
                                            optionNumber={index}
                                            optionText={element.chapterSummary}
                                            optionElement={element}
                                            optionClicked={handleChapterOptionClicked}
                                            vocabTexts={props.concepts.filter(x => element.vocabularyInChapter.includes(x.conceptId))}
                                            shouldShowBottomRip={element.vocabularyInChapter.length + usedConcepts.length < props.conceptsAmount}
                                            animationDelay={index / 3}
                                            isTwist={element.twist}
                                        />
                                    )
                                }
                            </div>
                            <div className={classes.suggestionBoxesContainer}>
                                <div className={classes.suggestionBox}
                                     onClick={() => {
                                         setOptionsOnScreenSetter([]);
                                         let unwantedOptions = [...unwantedChapterOptions];
                                         unwantedOptions.push(...currentChapterOptionsOnScreen);
                                         setUnwantedChapterOptions(unwantedOptions);
                                         handleConstructChapters(selectedChapterOptions, false, false,
                                             props.autoCourseCreation.selectedChapterOptions.length > 0 ?
                                                 props.autoCourseCreation.selectedChapterOptions[props.autoCourseCreation.selectedChapterOptions.length - 1].chapterId :
                                                 firstOptionDefaultState, unwantedOptions)}
                                     }>
                                    <Typography className={classes.suggestionBoxText}>
                                        Suggest new options
                                    </Typography>
                                </div>
                                <div className={classes.suggestionBox} onClick={() => {
                                    setAdditionalVocabList([]);
                                    handleOpenEditDialog(null, false, 0, []);
                                }}>
                                    <Typography className={classes.suggestionBoxText}>
                                        Write your own chapter
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {
                    openChapterEditModal &&
                    <EditChapterModal openDialog={openChapterEditModal}
                                      closeModal={openOrCloseModal}
                                      isEditMode={isEditModal}
                                      chapterNumber={editChapterNumber}
                                      chapterSummaryText={editChapterSummary}
                                      vocabList={modalVocabList}
                                      handleSave={handleEditChapter}
                                      vocabTexts={props.concepts.filter(x => modalVocabList.includes(x.conceptId))}
                                      vocabsLeft={props.concepts.filter(item => isEditModal ? !lockedVocabList.includes(item.conceptId) : !usedConcepts.includes(item.conceptId))}
                                      additionalVocabList={additionalVocabList}
                    />
                }
            </motion.div>
        </Fragment>
    );
};

AutoLanguageCourseChapterController.propTypes = {};

const mapStateToProps = (state) => {
    const {autoCourseCreation} = state;
    return {
        autoCourseCreation
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoLanguageCourseChapterController)