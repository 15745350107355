import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';


export default function AutoCourseSuggestLessonDetailsLoading(props) {
	return (
    <React.Fragment>
<div style={{ paddingTop: '14px', paddingBottom: '10px', width: '100%', marginTop: '10px', marginBottom: '10px', background: 'white', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: '5px'}}>
<Grid container style={{marginLeft: '10px', }}>
  <Grid item style={{marginRight:'13px', }}>
  <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center', width: '28px', height: 28, borderRadius: 28 / 2, backgroundColor: "#15D0BC" , marginRight: 5, textAlign: 'center', color: 'white', fontSize: '18.781px', fontFamily: 'Nunito', fontWeight: 700, lineHeight: '19.824px' }} >
                  {props.lessonNumber}
                </div>
  </Grid>
  <Grid item xs={5} style={{}}>
    <Typography component="div">
      <span style={{marginLeft: '6px', color: "var(--Gray-1, #8E8E93)", textAlign: "center", fontFamily: "Rubik", fontSize:20, fontStyle: "normal", fontWeight:400, letterSpacing:0.41, marginRight: '12px'}}>
        Introduction
      </span>
      <span style={{color: "#000", textAlign: "center", fontFamily: "Rubik", fontSize:20, fontStyle: "normal", fontWeight:400, letterSpacing:0.41,}}>
        <CircularProgress 
                style={{
                  height: '20px', 
                  width: '20px', 
                  pointerEvents: 'none',
                  color: "var(--Blue, #44A6EF)",
                }}
              />
      </span>
    </Typography>
  </Grid>
  <Grid container>
  <Grid item xs={2} style={{marginTop: '12px', marginLeft: '40px',}}>
    <Skeleton variant="rectangular" height={26} style={{marginBottom: '3px'}} />
  </Grid>
  </Grid>

</Grid>

</div>
<div style={{ paddingTop: '14px', paddingBottom: '10px', width: '100%', marginTop: '10px', marginBottom: '10px', background: 'white', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: '5px'}}>
<Grid container style={{marginLeft: '10px', }}>
  <Grid item style={{marginRight:'13px', }}>
    <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center', width: '28px', height: 28, borderRadius: 28 / 2, backgroundColor: "#A0439F" , marginRight: 5, textAlign: 'center', color: 'white', fontSize: '18.781px', fontFamily: 'Nunito', fontWeight: 700, lineHeight: '19.824px' }} >
                  {props.lessonNumber + 1}
                </div>
  </Grid>
  <Grid item xs={5} style={{}}>
    <Typography component="div">
      <span style={{marginLeft: '6px', color: "var(--Gray-1, #8E8E93)", textAlign: "center", fontFamily: "Rubik", fontSize:20, fontStyle: "normal", fontWeight:400, letterSpacing:0.41, marginRight: '12px'}}>
        Repetition
      </span>
      <span style={{color: "#000", textAlign: "center", fontFamily: "Rubik", fontSize:20, fontStyle: "normal", fontWeight:400, letterSpacing:0.41,}}>
        <CircularProgress 
                style={{
                  height: '20px', 
                  width: '20px', 
                  pointerEvents: 'none',
                  color: "var(--Blue, #44A6EF)",
                }}
              />
      </span>
      </Typography>
  </Grid>
  <Grid container>
  <Grid item xs={2} style={{marginTop: '12px', marginLeft: '40px',}}>
    <Skeleton variant="rectangular" height={26} style={{marginBottom: '3px'}} />
  </Grid>
  </Grid>

</Grid>

</div>
</React.Fragment>


	)
}



