import React, {useEffect, useState} from "react";
import {
    Grid,
    Select,
    Typography,
    MenuItem
} from '@material-ui/core';
import {ScenarioInteractiveElementType} from "../../../../Logic/ScenarioConstants";

import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {props} from "react-csv/lib/metaProps";

const useStyles = makeStyles(theme => ({
  root: {
      width: '100%',
      height: '98px',
      top: 0,
      position: 'fixed',
      zIndex: 100,
  },
  grow: {
      flexGrow: 1,
  },
  imageViewer: {
    maxWidth: '150px',
    minWidth: '50px',
    height: 'auto'
  },
  
  textFields: {
    width: '428px',
    textColor: 'white'
  },
  textFieldAnswers: {
    width: '348px',
    textColor: 'white'
  },
  
  feedback: {
    width: '428px',
    textColor: 'white',
    marginBottom: '20px'
  },
  
  answerHeaders: {
    paddingTop: '12px',
    paddingBottom: '8px',
    marginLeft: '13px'
  },
  answersSection: {
    marginBottom: '10px',
  },
  tabs: {
    backgroundColor: 'white',
  },
    
  cardPlayButton: {
    height: '40px',
    backgroundColor: '#143349',
    display: 'flex',
    justify: "flex-start",
    alignItems: 'center',
    width: '363px'
  },
  formControl: {
    borderRadius: '15px',
  },
  
  dropzoneContainer: {
    marginTop: '20px'
  },
  
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    marginRight: '15px',
    cursor: 'pointer',
  },

  nodeTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '22px',
    paddingBottom: '8px',
    color: '#35323C'
  },
  
  l1ChooserStyle: {
    marginLeft: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '60px',
    height: '60px',
    border: '5px solid #143349',
    boxSizing: 'border-box',
    borderRadius: '10px',
    maxWidth: '7px',
    maxHeight: '40px',
    minWidth: '78px',
    minHeight: '40px',
  },
  
  buttonAnnouncement: {
    border: '5px solid #143349',
    boxSizing: 'border-box',
    borderRadius: '10px',
    maxWidth: '40px', maxHeight: '40px', minWidth: '40px', minHeight: '40px',
    marginTop: '7px'
  },
  
  circularProgress: {
    color: '#eee',
    fontSize: 36,
    margin: '15px',
  },
  
  closeIcon: {
    color: 'white',
    paddingRight: '5px',
    cursor: 'pointer',
  },
  
  interactions: {
    paddingTop: '10px'
  },
  
  scenario: {
    paddingTop: '30px'
  },
  
  saveButton: {
    backgroundColor: '#143349',
    fontColor: 'white',
    color: 'white'
  },
  
  scenarioDescription: {
    paddingTop: '3px'
  },
  
  multilineColor:{
    color:'white'
  },
  
  scenarioDescriptionText: {
    color: '#8e8e93',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
  }, 
  
  scenarioScenesPreviewSection: {
    marginTop: '20px'
  },
  
  scenarioMentorPreviewComponent: {
    marginRight: '13px'
  },
  
  divList: {
    maxHeight: 430,
    overflow: 'auto'
  },
  
  list: {
    height: '1000px'
  },

  ruleTextField: {
    marginBottom: '10px'
  },
  ruleTitle: {
    paddingTop: '30px',
    marginBottom: '10px'
  },
  definitionText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  testImageText: {
    fontSize: 11,
  },
  explanationText: {
    fontSize: 12,
  },
  typographySpeakout: {
    marginBottom: '5px',
    color: '#8e8e93',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  editNodeSectionTitle: {
    width: '70px',
    boxShadow: 'none',
    flex: 1,
    fontSize: 36,
    color: 'black',
    fontFamily: 'Lato'
  },
  editNodeSectionDescription: {
    color: '#8E8E93',
    fontFamily: 'Lato',
    marginBottom: '10px'
  }
}));


const InteractionType = {
  "Keyboard": 0,
  "MultipleChoice":1,
  "SentenceBuilder":2,
  "Reading":3
};

const InteractionSelection = (props) => {
    const classes = useStyles();
    const [newAnswer, setNewAnswer] = useState("");
    
    const [currentInteractionType, setCurrentInteractionType] = useState(InteractionType.MultipleChoice);

  useEffect(() => {
    
    if (props.testOption.scenarioInteractiveElementType === ScenarioInteractiveElementType.FillInBlankWriting) {
      setCurrentInteractionType(InteractionType.Keyboard) 
    } else if ( props.testOption.scenarioInteractiveElementType === ScenarioInteractiveElementType.Text) {
      setCurrentInteractionType(InteractionType.Reading);
    } else if (props.detectInteractionMode(props.testOption.alternatives, props.testOption.text) === true) {
      setCurrentInteractionType(InteractionType.SentenceBuilder);
    } else {
      setCurrentInteractionType(InteractionType.MultipleChoice);
    }
  }, [props.testOption.scenarioInteractiveElementType]);
    
    return (
      <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography   
          style={{
          //width: '300px',
          boxShadow: 'none',
          //flex: 1,
          //fontSize: 36,
          color: 'black',
          fontFamily: 'Lato',
          marginLeft: '10px',
          fontWeight: '500',
          letterSpacing: '0.02em'
  
        }}>
          {props.title}
      </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12 }>
        <Select 
          style={{
            marginLeft: '10px', 
            paddingLeft: '10px',
            width: '60px',
            height: '60px',
            border: '5px solid #143349',
            boxSizing: 'border-box',
            borderRadius: '10px',
            maxWidth: '7px', 
            maxHeight: '40px', 
            minWidth: '200px', 
            minHeight: '40px',
            marginBottom: '10px'}}
          disableUnderline
          value={currentInteractionType}
            autoWidth
            disabled={props.disableInteraction}
            onChange={(e) =>  props.onChangeInteraction(e.target.value, props.stageType, props.testOptionsIndex)}
            >
            {
              props.stageType !== "testImage" &&  
              <MenuItem disabled={!props.canEditLesson || props.disableInteraction} value={3}>Reading</MenuItem>
            }
            <MenuItem disabled={!props.canEditLesson || props.disableInteraction} value={0}>Keyboard</MenuItem>
            <MenuItem disabled={!props.canEditLesson || props.disableInteraction} value={1}>Multiple Choice</MenuItem>
            <MenuItem disabled={!props.canEditLesson || props.disableInteraction} value={2}>Sentence Builder</MenuItem>
          </Select>
          
      </Grid>
    </Grid>
    );
};

InteractionSelection.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(InteractionSelection)