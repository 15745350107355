import React from 'react';
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({

}))
const AutoCourseSuggestLessonDetailsQuizItem = (props) => {
 const classes = useStyles();
    return (
    <div style={{marginBottom: '20px', paddingLeft: '20px'}}>
        <Typography style={{borderBottom: 'none', paddingBottom: '0px', width: '100%', marginTop: '10px'}} >
          Q: {props.question}
        </Typography>
        <Typography style={{width: '30px', display: 'inline', marginRight: '20px' }}>
          A: 
        </Typography>
        <Typography style={{width: '300px', display: 'inline', color: '#63C276', marginRight: '20px' }}>
          {props.correctAnswer}
        </Typography>
        <Typography style={{width: '300px', display: 'inline', color: '#FF5159', marginRight: '20px' }}>
          {props.incorrectAnswer1}
        </Typography>
        <Typography style={{width: '300px', display: 'inline', color: '#FF5159', marginRight: '20px' }}>
         {props.incorrectAnswer2}
        </Typography>
        <Typography style={{width: '300px', display: 'inline', color: '#FF5159' }}>
         {props.incorrectAnswer3}
        </Typography>
        <Divider style={{width:'100%', marginTop: '10px'}}/>

    </div>
  )
}

AutoCourseSuggestLessonDetailsQuizItem.propTypes = {
  
};


function mapStateToProps(state) {
  const {autoCourseCreation} = state;
  return {
    autoCourseCreation
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseSuggestLessonDetailsQuizItem)