import {connect} from "react-redux";
import React, {useState} from "react";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import InteractionDialog from "../Interactions/InteractionDialog";
import {makeStyles} from "@material-ui/core/styles";
import TextFieldWithLanguageSelector from "../ScenarioComponents/TextFieldWithLanguageSelector";

const useStyles = makeStyles(theme => ({

  scenario: {
    paddingTop: '30px'
  },
  scenarioDescriptionText: {
    color: '#8e8e93',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  ruleTitle: {
    paddingTop: '30px',
    marginBottom: '10px'
  },
  stageHeader: {
    boxShadow: 'none',
    color: 'black',
    fontFamily: 'Lato',
    fontSize: 32,
    fontWeight: 'bold'
    },
    sectionHeader: {
      boxShadow: 'none',
      color: 'black',
      fontFamily: 'Lato',
      fontSize: 22,
      fontWeight: 'bold',
    },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging && "lightgreen",
  ...draggableStyle
});


const DialogStageEditNode = (props) => {

  // expected props
  // props.node
  // props.node.testOptions
  
  const classes = useStyles();
  const [shouldMinimise, setShouldMinimise] = useState(false);

  const onDragEnd =(result) => {
    if (!result.destination) {
      return;
    }

    const reOrderedTestOptions = reorder(
      props.node.testOptions,
      result.source.index,
      result.destination.index
    );
    
    props.onUpdateAllTestOptionOnCurrentNode(reOrderedTestOptions);
  }

  const handleOnNodeInteractionScoringChanged = (testOptionIndex, shouldBeScored) => {

    if (testOptionIndex >= props.node.testOptions.length) {
      return;
    }
    
    let testOption = props.node.testOptions[testOptionIndex];
    testOption.interactionScoringDynamicConfigurationV1Dto = {
      'shouldBeScored': shouldBeScored
    }
    props.onUpdateTestOptionOnCurrentNode(testOption);
  }

  return (
      <React.Fragment>
          <Grid item xs={12} style={{marginTop: '70px'}}>
            <Typography 
              variant='h6' 
              className={classes.stageHeader} >
              Dialogue
            </Typography>
            <Typography
              style={{
                boxShadow: 'none',
                color: 'black',
                fontFamily: 'Lato',
                letterSpacing: '0.02em',
                fontSize: 14,
              }}>
              Create a written or spoken dialogue between different people. The dialogue appears like a chat thread.
            </Typography>
            <Typography
              style={{
                boxShadow: 'none',
                color: 'black',
                fontFamily: 'Lato',
                letterSpacing: '0.02em',
                fontSize: 14,
                marginTop: '10px'
              }}>
              The learner can take the part of one of the people in the dialogue, or alternatively listen to the dialogue and give opinions on what was said.
            </Typography>
          </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.ruleTitle} style={{paddingRight: '50px' }}>
          <Typography className={classes.sectionHeader} >
            Title
          </Typography>
        </Grid>
        { 
          props.node.texts?.map((text, index) => (
          <Grid container >
            <Grid item xs={12} sm={12} md={12} className={classes.ruleTextField}>
              <TextFieldWithLanguageSelector 
                text={props.node.texts.length > 0 ? props.node.texts[index].text : ""}
                //width="210px"
                disabled={!props.canEditLesson} 
                onChange={(event) => props.onRuleCardTitleChange(event, 0)}              
                l1Id={null}
                l2Id={null}
                soloLanguageId={props.languageId}
                canChangeLanguage={false}
              />
            </Grid>
          </Grid>
        ))
        }
        <Grid container >
          <Grid item xs={9} sm={9} md={9} className={classes.scenario} >
            <Typography className={classes.sectionHeader} style={{marginBottom: '20px'}}>
              Dialogues
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} className={classes.scenario} >
            <FormControlLabel control={<Switch checked={shouldMinimise} onChange={() => setShouldMinimise(!!!shouldMinimise)} />} label="Reorder dialogues" />
          </Grid>
        </Grid>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  props.node?.testOptions?.map((testOption, index) => (
                  <Draggable isDragDisabled={!!!shouldMinimise} key={testOption.testOptionId} draggableId={testOption.testOptionId} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <InteractionDialog
                          onUpdateTestOptionOnCurrentNode={props.onUpdateTestOptionOnCurrentNode}
                          onChangeTextImageQuestionL2={props.onChangeTextImageQuestionL2}
                          onChangeWhenSpoken={props.onChangeWhenSpoken}
                          onChangeInteraction={props.onChangeInteraction}
                          languageId={props.languageId}
                          onTestOptionTextLanguageTypeChange={props.onTestOptionTextLanguageTypeChange}
                          onVoiceDrop={props.onVoiceDrop}
                          onMicVoiceDrop={props.onMicVoiceDrop}
                          onRemoveVoice={props.onRemoveVoice}
                          audioPlaying={props.audioPlaying}
                          isAudioDownloading={props.isAudioDownloading}
                          onPlayVoice={props.onPlayVoice}
                          testOption={testOption}
                          index={index}
                          node={props.node}
                          detectInteractionMode={props.detectInteractionMode}
                          displayInteraction={props.displayInteraction}
                          canEditLesson={props.canEditLesson}
                          shouldMinimise={shouldMinimise}
                          onNodeInteractionScoringChanged={handleOnNodeInteractionScoringChanged}
                          currentAudioBlobUrl={props.currentAudioBlobUrl}
                          currentAudioTracker={props.currentAudioTracker}
                          onAudioLibraryClick={props.onAudioLibraryClick}
                          lesson={props.lesson}
                          l1Id={props.l1Id}
                          onUpdateSelectedDialogueUtterance={props.onUpdateSelectedDialogueUtterance}
                          
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {
          <React.Fragment>
            { props.node.testOptions?.length === 0 &&
            <Typography variant="body1" className={classes.scenarioDescriptionText}  >
              No dialog added.
            </Typography>
            }
            <Grid item xs={12} sm={12} md={12} style={{display: 'flex', textAlign: 'center', alignItems: 'center', marginTop:'20px', justifyContent:'left' }} >
              {
                props.canEditLesson &&
                <Button onClick={() => props.onAddRule()}  style={{backgroundColor: '#143349', fontColor: 'white', color: 'white', marginRight: '5px', textTransform: 'none',}} variant='outlined'>
                  Add Dialog step
                </Button>
              }
            </Grid>
          </React.Fragment>
        }
  </React.Fragment>
  );
}


DialogStageEditNode.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(DialogStageEditNode)