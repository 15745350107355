import React, {useState, useRef} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

const FocusAreaListeningToken = (props) => {
  const color = (!props.disabled ? (props.selected ? (props.hover ? "rgba(255, 141, 69, 0.32)" : "#E5E5EA") :  "url(#paint0_linear_1433_23164)") : "#E5E5EA");
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill={color}/>
      <g clip-path="url(#clip0_1433_23164)">
      <path d="M21.9993 11.3333C21.2593 11.1 20.446 11 19.666 11C18.366 11 16.966 11.2667 15.9993 12C15.0327 11.2667 13.6327 11 12.3327 11C11.0327 11 9.63268 11.2667 8.66602 12V21.7667C8.66602 21.9333 8.83268 22.1 8.99935 22.1C9.06602 22.1 9.09935 22.0667 9.16602 22.0667C10.066 21.6333 11.366 21.3333 12.3327 21.3333C13.6327 21.3333 15.0327 21.6 15.9993 22.3333C16.8993 21.7667 18.5327 21.3333 19.666 21.3333C20.766 21.3333 21.8993 21.5333 22.8327 22.0333C22.8993 22.0667 22.9327 22.0667 22.9993 22.0667C23.166 22.0667 23.3327 21.9 23.3327 21.7333V12C22.9327 11.7 22.4993 11.5 21.9993 11.3333ZM21.9993 20.3333C21.266 20.1 20.466 20 19.666 20C18.5327 20 16.8993 20.4333 15.9993 21V13.3333C16.8993 12.7667 18.5327 12.3333 19.666 12.3333C20.466 12.3333 21.266 12.4333 21.9993 12.6667V20.3333Z" fill="white"/>
      <path d="M19.666 15C20.2527 15 20.8193 15.06 21.3327 15.1733V14.16C20.806 14.06 20.2393 14 19.666 14C18.5327 14 17.506 14.1933 16.666 14.5533V15.66C17.4193 15.2333 18.466 15 19.666 15Z" fill="white"/>
      <path d="M16.666 16.3268V17.4334C17.4193 17.0068 18.466 16.7734 19.666 16.7734C20.2527 16.7734 20.8193 16.8334 21.3327 16.9468V15.9334C20.806 15.8334 20.2393 15.7734 19.666 15.7734C18.5327 15.7734 17.506 15.9734 16.666 16.3268Z" fill="white"/>
      <path d="M19.666 17.5537C18.5327 17.5537 17.506 17.747 16.666 18.107V19.2137C17.4193 18.787 18.466 18.5537 19.666 18.5537C20.2527 18.5537 20.8193 18.6137 21.3327 18.727V17.7137C20.806 17.607 20.2393 17.5537 19.666 17.5537Z" fill="white"/>
      </g>
      <defs>
      <linearGradient id="paint0_linear_1433_23164" x1="0" y1="0" x2="32" y2="32" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FFAE65"/>
      <stop offset="1" stop-color="#FF8D45"/>
      </linearGradient>
      <clipPath id="clip0_1433_23164">
      <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

FocusAreaListeningToken.propTypes = {
};

function mapStateToProps(state) {
  return {
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FocusAreaListeningToken)