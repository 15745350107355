import React, {useEffect, useRef, useState} from 'react';
import {Grid, makeStyles, Paper} from '@material-ui/core';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {connect} from 'react-redux';
import 'react-image-crop/dist/ReactCrop.css';
import "@fontsource/nunito";
import {autoCourseCreationActions} from "../../../_state/actions";
import {
  AutoCourseCreateDialogStepType,
  AutoCourseCreatorLessonType,
  AutoCourseCreatorLevelType,
  AutoCourseCreatorToneOfVoice,
  AutoCourseCreatorProcessStages,
  AutoLanguageCourseLanguageLevels,
  AutoLanguageCourseLanguageIntents,
  AutoCourseCreatorLanguageProcessStages,
  AutoCourseCreatorNarrativeType,
  AutoCourseCreatorFocusAreas,
  AutoCourseCreatorFocusAreas2,
  AutoCourseCreatorTotalPointsFocusAreas,
  AutoCourseCreatorPointsPerFocusArea, AutoCourseCreateType
} from "../../../Logic/AutoCourseCreationConstants";
import {v4 as uuidv4} from "uuid";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import {useLocation} from "react-router-dom";
import  { ReactComponent as RocketsAndStars } from "./Images/rocket_and_stars4.svg";
import  { ReactComponent as FullLogoLanguage } from "./Images/full_logo_language.svg";
import  { ReactComponent as UpcomingFeaturesStarts } from "./Images/UpcomingFeaturesStars.svg";

import ButtonBase from '@mui/material/ButtonBase';
import AutoLanguageCourseSuggestionBox from './AutoCourseCreateLanguage/AutoLanguageCourseSuggestionBox'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import {CapeeshColors} from "../../../assets/ColorPalette";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { motion, AnimateSharedLayout } from "framer-motion"
import {AnimatePresence} from "framer-motion";
import Switch from '@mui/material/Switch';
import ComingSoonSvg from "./Images/ComingSoonSvg.jsx";
import UpcomingFeatures from "./Images/UpcomingFeatures.jsx";
import AutoLanguageCourseChapterController from "./AutoCourseCreateLanguage/AutoLanguageCourseChapterController";
import AutoLanguageCourseLessonPlanController from "./AutoCourseCreateLanguage/AutoLanguageCourseLessonPlanController";
import AutoLanguageCourseNarrativeTypeScreen from "./AutoCourseCreateLanguage/AutoLanguageCourseNarrativeTypeScreen";
import ACCLanguageSidebar from "./AutoCourseCreateLanguage/ACCLanguageSidebar";
import ACCFocusArea from './AutoCourseCreateLanguage/Components/ACCFocusArea';
import {props} from "react-csv/lib/metaProps";
import ACCLanguageLoadingSpinner from "./AutoCourseCreateLanguage/Components/ACCLanguageLoadingSpinner";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import UnityLessonPreview from "../Scenarios/UnityLessonPreview/UnityLessonPreview";
import ACCScrollingTexts from "./AutoCourseCreateLanguage/Components/ACCScrollingTexts";
import {GlobalEvents} from "../../../assets/GlobalEvents";

const useStyles = makeStyles((theme) => ({
  grid: {
    transition: 'all 1s'
  },
  button: {
    borderRadius:5,
    background: "var(--Blue, #44A6EF)",
    textTransform: "none",
    color: "white",
    marginLeft:260,
    marginTop:90,
    paddingTop:16,
    paddingBottom:16,
    paddingLeft:25,
    paddingRight:25,
  },
  buttonclicked: {
      transition: "background 1s",
  },

  dialogTitle: {
    backgroundColor: '#143349',
    fontColor: 'white',
  },
  dialogTitleDiv: {
    display: 'flex'
  },
  hoverEffect: {
    '&:hover': {
      border: '2px solid',
      borderColor: 'var(--White, #FFF)',
    },
  },


  unitySizeStyle: {
    height: '85vh',
    width: 'calc(85vh * 0.62)',
  },
  infoTextFirstPage:
      {
        color: "white",
        textAlign: "center", 
        fontFamily: "Rubik",
        fontSize:14,
        fontStyle: "italic",
        fontWeight:400
      }
  
}))

const AutoLanguageCourseCreateController = (props) => {
  const classes = useStyles();
  const {computedMatch} = props;
  // Language-specific variables
  const [learningOutcomes, setLearningOutcomes] = useState(['']);
  const [lessonContentViewMode, setLessonContentViewMode] = useState("pos");
  const [posSelection, setPosSelection] = useState("All");
  const [learningAreaInView, setLearningAreaInView] = useState(AutoLanguageCourseLanguageIntents.Vocabulary);
  const [tempVocabularyItems, setTempVocabularyItems] = useState([]);

  const texts = [
    'Teach health and safety language for a construction site.',
    'Teach nouns and phrases related to working in a coffee shop.',
    'Everyday language when travelling by train. Focus on useful phrases.',
  ];


  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 7000);

    return () => clearInterval(timer);
  }, [texts.length]);
  
  const tempVocabularyItemsRef = useRef([]); // Create a ref to store text field elements
  const [tempRules, setTempRules] = useState([
    "Learn the vocabulary",
    "Learn to distinguish if singular or plural"
  ]);

  const [tempVocabularyItemsCategory, setTempVocabularyItemsCategory] = useState([
    "I need a new car",
    "Winter tyres",
    "Summer tyres",
    "Do you need to top up the engine oil?",
  ]);

  const [tempVocabularyCategories, setTempVocabularyCategories] = useState([
    "All",
    "Rules & Grammar",
    "Engine parts",
    "Petrol station",
    "Car interior"
  ]);
  
  const initialStateFocusAreas = AutoCourseCreatorFocusAreas2.reduce((obj, item) => {
    return {
      ...obj,
      [item.type]: 2
    };
  }, {});

  const [tempFocusAreas, setTempFocusAreas] = useState(initialStateFocusAreas);

  const [selectedVocabularyCategory, setSelectedVocabularyCategory] = useState("All");
  const [visible, setVisible] = useState(true);
  // end of language-specific variables

  // lesson preview
  const [showLessonPreview, setShowLessonPreview] = useState(false);
  const unityStagePreviewControllerRef = useRef(null);
  const [customLessonIdToPreview, setCustomLessonIdToPreview] = useState(null);

  let [checkSaveLanguageLessonPlanTimeout, setCheckSaveLanguageLessonPlanTimeout] = useState();

  const [isConstructingChapters, setIsConstructingChapters] = useState(false);
  const [courseLength, setCourseLength] = useState(20);
  const [showTopics, setShowTopics] = useState(true);
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [buttonOpacity, setButtonOpacity] = useState(1);
  const [finishedCreateSections, setFinishedCreateSections] = useState(false);
  const [showStartScreen, setShowStartScreen] = useState(false);
  const statusTexts = [
    "Thanks! We are now analysing your content.",
    "This will take one to two more minutes - please don’t close the page.",
    "The text you added is being transformed into core teaching material.",
    "This core teaching material will be used to make your interactive lessons.",
    "You'll be able to make as many lessons as you wish.",
    "OK, around one minute left! The next step will be to review the suggested teaching material.",
  ]
  const [isResetConfirmationDialogueOpen, setIsResetConfirmationDialogueOpen] = useState(false);
  const [processingStatusText, setProcessingStatusText] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isStarted, setIsStarted] = useState(false);
  // All done! I've turned your text into a syllabus of things to teach.
  const [entryPoint, setEntryPoint] = React.useState(1);
  // language learning functions
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#15D0BCE5",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "pink",
    },
  }));


    const [shouldShake, setShouldShake] = useState(false);

    const shakeAnimation = {
        start: { x: -7, color: 'white'},
        end: { x: 7, color: 'pink' },
    };


  const handleRemoveVocabularyItem = (index) => {
    const updatedVocabularyItems = [
      ...tempRules.slice(0, index),
      ...tempRules.slice(index + 1),
    ];
    setTempRules(updatedVocabularyItems);
  };

  useEffect(() => {
    if (shouldShake) {
      const timer = setTimeout(() => {
        setShouldShake(false);
      }, 1000);
      
      return () => clearTimeout(timer); // Clear timer when component unmounts or shouldShake state changes
    }
  }, [shouldShake]);

  const onUpdateFocusPoints = (newValue, type) => {
    if(Object.values(tempFocusAreas).reduce((prev, curr) => prev + curr, 0) + newValue > AutoCourseCreatorTotalPointsFocusAreas) {
      // not enough focus points available, so let the author know and add what points are available, if any
      const nearestValue = AutoCourseCreatorTotalPointsFocusAreas - (Object.values(tempFocusAreas).reduce((prev, curr) => prev + curr, 0) + newValue )
            setTempFocusAreas((prevTempFocusAreas) => ({
        ...prevTempFocusAreas,
        [type]: tempFocusAreas[type] + (newValue + nearestValue)
    }))
      setShouldShake(true)
      return
    }
    if(tempFocusAreas[type] + newValue < 0) return
    // current focus area already at 0 so don't remove more

    if(tempFocusAreas[type] + newValue > AutoCourseCreatorPointsPerFocusArea) return
    // current focus area already at max of 5 so don't add another

    setTempFocusAreas((prevTempFocusAreas) => ({
      ...prevTempFocusAreas,
      [type]: tempFocusAreas[type] + newValue
  }))
    
    
    
    

  };

  useEffect(() => {
    if (tempFocusAreas) {
      // set the result on the author metadata object
      const topicFocusDistributionDto = {
        "reading": tempFocusAreas[AutoCourseCreatorFocusAreas.Reading],
        "writing": tempFocusAreas[AutoCourseCreatorFocusAreas.Writing],
        "listening": tempFocusAreas[AutoCourseCreatorFocusAreas.Listening],
        "spokenProduction": tempFocusAreas[AutoCourseCreatorFocusAreas.SpokenProduction],
        "spokenInteraction": tempFocusAreas[AutoCourseCreatorFocusAreas.SpokenInteraction]
      }

      let localMetaData = {...authorMetadataDto}
      localMetaData.focusAreas = topicFocusDistributionDto
      props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localMetaData))
      setAuthorMetadataDto(localMetaData)
    }
    
  }, [tempFocusAreas]);
  
  const handleToggleUseAIGeneralKnowledge = () => {
    let localMetaData = {...authorMetadataDto}  
    localMetaData.useOnlyText = !!!localMetaData.useOnlyText ;
    props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localMetaData))
    setAuthorMetadataDto(localMetaData)
  };
  
  const handleSelectNarrativeType = (choice) => {
    let localMetaData = {...authorMetadataDto};
    localMetaData.narrativeType = choice;
    props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localMetaData));
    setAuthorMetadataDto(localMetaData);
  };

  const handleChangeLearningOutcomes = (index, event) => {
    const newItems = [...tempRules];
    newItems[index] = event.target.value;
    setTempRules(newItems);
    // Automatically add a new line if the current line is the last line and it's not empty
      if (index === newItems.length - 1 && event.target.value) {
        setTempRules([...newItems, '']);
      }
      if(index > 0 && index < tempVocabularyItemsRef.current.length  && event.target.value === "") {
        const newVocabularyItems = tempRules.filter((_, itemIndex) => itemIndex !== index);
        setTempRules(newVocabularyItems);
      }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Enter' || event.key === 'ArrowDown') {
      // Put cursor in next learning outcome if there is one
      event.preventDefault(); // Prevent the default action of 'Enter'
      // Check if next item exists, and if so, focus it
      if (index < tempRules.length - 1) {
        tempVocabularyItemsRef.current[index + 1].focus(); // Focus the next TextField
      } 
    }
    if (event.key === 'ArrowUp') {
      if(index -1 >= 0){
        tempVocabularyItemsRef.current[index - 1].focus(); // Focus the next TextField
      }
    };
    if (event.key === 'Backspace') {
      if(index > 0 && index < tempVocabularyItemsRef.current.length - 1 && event.target.value === "") {
        const newVocabularyItems= learningOutcomes.filter((_, itemIndex) => itemIndex !== index);
        setTempRules(newVocabularyItems);
      }
    };

  }
  
  const handleSetIsConstructingChapters = (isConstructing) => {
    setIsConstructingChapters(isConstructing);
  }

  const whatSection = () => {    
    return (
      <React.Fragment>
        <Box style={{color: 'white', textAlign: 'center',  fontSize:24, fontFamily: "Rubik", fontWeight: '300', marginBottom: '18px'}}>
          What should the course teach?
        </Box>
        <Grid item xs={12} style={{ marginBottom: '10px'}}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center',}}>
              <TextField disabled={props.autoCourseCreation.processingState!== AutoCourseCreatorProcessStages.StartSequence} multiline
               style={{
                borderRadius: "16px 16px 0px 0", borderTop: "2px solid rgba(255, 255, 255, 0.32)",  borderRight: "2px solid rgba(255, 255, 255, 0.32)",  borderLeft: "2px solid rgba(255, 255, 255, 0.32)",  background: "rgba(255, 255, 255, 0.12)",  padding: '15px',textAlign: "center", minWidth: '600px',}}
               sx={{
                '& .MuiInputBase-input::placeholder': {
                  color: 'white',
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                  marginLeft: '8px', 
                },}}
              autoFocus id="name" variant="standard" 
                value={props?.autoCourseCreation?.authorInputMetadata?.authorWhatText || ""}
                onChange={(e) => handleSetAuthorInputMetaData(AutoCourseCreateDialogStepType.What, e.target.value)}
                rows={5} placeholder="This course should teach..."
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            <div style={{ width: '605px', padding: '15px', textAlign: 'center', borderRadius: "0px 0px 8px 8px",  background: "rgba(255, 255, 255, 0.24)", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}>     
              <Box> 
                <ACCScrollingTexts text={texts[currentIndex]} textStyle={ classes.infoTextFirstPage }/>
              </Box>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const [isOpen, setIsOpen] = useState(false)
  const variantsPasteText = {
    open: { opacity: 1, x: 0, height: '110px'},
    closed: { opacity: 0, x: 0, height: 0},
  }

  const handleOnClickPasteText = (index, event) => {
    setIsOpen(!!!isOpen)
  };
  
  const continueButtonSection = () => {    
    return (
      <React.Fragment>
          <Grid item xs={12} style={{position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {buttonClicked===false && 
              <Button  
                disabled={props?.autoCourseCreation?.authorInputMetadata?.authorWhatText.length === 0}  
                onClick={(e) => proceedToNextStage(AutoCourseCreatorLanguageProcessStages.ExistingContentSelection)} 
                style={{
                  borderRadius: 8,  
                  background: props.autoCourseCreation?.authorInputMetadata?.authorWhatText.length === 0 ? "lightgrey" :  "var(--Blue, #44A6EF)",
                  transition: "border-radius 1s ease, opacity 1.1s ease",
                  opacity: buttonOpacity, 
                  textTransform: 'none',  
                  color: 'white',
                  paddingTop: '16px', 
                  paddingBottom: '16px',
                  paddingLeft: '25px', 
                  paddingRight: '25px', 
                  height: '50px',
                  width: '90px', 
                  fontSize: '16px', 
                  fontFamily: "Rubik",
                }}>
                  Continue
              </Button>
            }
        </Grid>
   </React.Fragment>
    )
  }

  const uploadDocument = () => {   
    return (
      <Grid container>
      <Grid item xs={12}>
        <div style={{
          borderRadius: 8,
          marginTop: '10px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}>
          <ButtonBase disabled={true}>
            <div style={{
              width: '580px',
              padding: '16px',
              borderRadius: 8,
              border: "2px solid rgba(255, 255, 255, 0.32)",
              textAlign: 'left',
              position: 'relative',
            }}>

  
              <Grid container>
                <Grid item xs={12}>
                <div style={{ marginLeft: '-25px', marginTop: '-10px'}}>
                <UpcomingFeatures />
              </div>
                </Grid>
                <Grid item xs={1} style={{}}>
                <Box style={{
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'space-between', 
                opacity: 1,
                marginTop: '40px',
                //height: '70px'
                
              }}>
                <UpcomingFeaturesStarts />
                </Box>

                </Grid>

                <Grid item xs={10} style={{height: '70px'}}>
                <Box style={{
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'space-between', 
                opacity: 1,
                marginTop: '20px',

              }}>
                <span style={{
                  color: "var(--White, #FFF)",
                  fontFamily: "Rubik",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "140%",
                  marginBottom: '3px',
                }}>
                  
                  Uploading documents
                </span>
              </Box>
              <Box style={{
                color: "var(--White, #FFF)",
                fontFamily: "Rubik",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "140%",
                textAlign: 'left',
              }}>
                You can upload any document containing teaching material, and we’ll base the lesson content on this.
              </Box>
                  </Grid>

              </Grid>
              <Grid container>
                <Grid item xs={1} style={{}}>
                <Box style={{
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'space-between', 
                opacity: 1,
                marginTop: '35px',
                height: '70px'
                
              }}>
                <UpcomingFeaturesStarts />
                </Box>

                </Grid>
                <Grid item xs={10} style={{height: '70px', marginTop: '20px'}}>
                <Box style={{
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'space-between', 
                opacity: 1,
                marginTop: '20px',

              }}>
                <span style={{
                  color: "var(--White, #FFF)",
                  fontFamily: "Rubik",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "140%",
                  marginBottom: '3px',
                }}>
                  
                  Build upon an existing lesson
                </span>
              </Box>
              <Box style={{
                color: "var(--White, #FFF)",
                fontFamily: "Rubik",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "140%",
                textAlign: 'left',
              }}>
                This may be an existing language lesson at a different CEFR lesson, or a Compliance lesson that you wish to base language lessons on.
              </Box>
                  </Grid>

              </Grid>
  

            </div>
          </ButtonBase>
        </div>
      </Grid>
    </Grid>
    )
  }

  const pasteText = () => {  
    return (
      <div style={{ borderRadius: 8, marginTop: '10px', marginBottom: '10px',   display: 'flex', justifyContent: 'center', width: '100%',  }} >
        <div style={{cursor: 'pointer', borderRadius: 8, background: "rgba(255, 255, 255, 0.24)", width: '580px', padding: '16px', border: '2px solid rgba(255, 255, 255, 0.32)'}} className={classes.hoverEffect}
          onClick={handleOnClickPasteText}
        >
          <Box style={{ color: "var(--White, #FFF)", fontFamily: "Rubik", fontSize: 16, fontStyle: "normal", fontWeight: 500, lineHeight: "140%", marginBottom: '3px', }} >
            Copy/pasted text
          </Box>
          <Box style={{ color: "var(--White, #FFF)", fontFamily: "Rubik", fontSize: 14, fontStyle: "normal", fontWeight: 400, lineHeight: "140%", }}>
              Paste content that will be used as a basis for the content taught in the lessons.
          </Box>
          <AnimatePresence>
            {isOpen && 
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={"open"}
                exit={{ opacity: 0, height: 0 }}
                transition={{ opacity: { duration: 0.2 }, }}
                variants={variantsPasteText}>
                    <Box style={{ color: "var(--White, #FFF)", fontFamily: "Rubik", fontSize: 16, fontStyle: "normal", fontWeight: 500, lineHeight: "140%", minHeight: '50px' }}>
                      <TextField 
                        placeholder={"Copy and paste your text in here"} 
                        multiline style={{ borderRadius:8, border: "1px solid var(--White, #FFF)", background: "rgba(255, 255, 255, 0.16)", }} autoFocus margin="dense" id="name" fullWidth variant="standard"
                        sx={{
                        '& .MuiInputBase-input::placeholder': {
                          color: 'white',
                        },
                        '& .MuiInputBase-input': {
                          color: 'white',
                          fontSize: '14px', 
                          marginLeft: '8px', 
                        },
                       }}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          setCopyPastTextValue(e.target.value);
                          handleSetAuthorInputMetaData(AutoCourseCreateDialogStepType.SupportingText, e.target.value)}
                        }
                        rows={4} 
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Box>
                </motion.div>
              }
          </AnimatePresence>
       </div>
    </div>
    )
  }

  const continueFromContentAdder = () => {  
    
    
    
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0, marginBottom: '20px' }}
          animate={{ opacity: 1, height: 'auto', marginBottom: '20px', transition: { opacity: { duration: 1 } }  }}
          exit={{ opacity: 0, height: 0, marginBottom: 'auto', transition: { opacity: { duration: 0.2 } } }}
        >
          <Grid item xs={12}>
              <div style={{ borderRadius: 8, display: 'flex', justifyContent: 'center', width: '100%',  }}>
                <Button disabled={ props?.autoCourseCreation?.authorInputMetadata?.useOnlyText && copyPasteTextValue.length <= 0 } 
                  onClick={(e) => proceedToNextStage(AutoCourseCreatorLanguageProcessStages.LoadingVocabulary)}
                  style={{ borderRadius:8, background: props?.autoCourseCreation?.authorInputMetadata?.useOnlyText && copyPasteTextValue.length <= 0 ? CapeeshColors.Gray2 : "var(--Blue, #44A6EF)", textTransform: 'none', color: 'white',}}>
                      Continue
                </Button>   
              </div>
          </Grid>
        </motion.div>
    </AnimatePresence>
    )
  }
  const continueFromNarrativeSelection = () => {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0, marginBottom: '20px' }}
          animate={{ opacity: 1, height: 'auto', marginBottom: '20px', transition: { opacity: { duration: 1 } }  }}
          exit={{ opacity: 0, height: 0, marginBottom: 'auto', transition: { opacity: { duration: 0.2 } } }}
        >
          <Grid item xs={12}>
            <div style={{ borderRadius: 8, display: 'flex', justifyContent: 'center', width: '100%',  }}>
              <Button
                onClick={(e) => proceedToNextStage(AutoCourseCreatorLanguageProcessStages.ConstructChapters)}
                style={{ borderRadius:8, background: "var(--Blue, #44A6EF)", textTransform: 'none', color: 'white',}}>
                Continue
              </Button>
            </div>
          </Grid>
        </motion.div>
      </AnimatePresence>
    )
  }

  const continueFromChapterConstruction = () => {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0, marginBottom: '20px' }}
          animate={{ opacity: 1, height: 'auto', marginBottom: '20px', transition: { opacity: { duration: 1 } }  }}
          exit={{ opacity: 0, height: 0, marginBottom: 'auto', transition: { opacity: { duration: 0.2 } } }}
        >
          <Grid item xs={12}>
            <div style={{ borderRadius: 8, display: 'flex', justifyContent: 'center', width: '100%',  }}>
              <Button
                onClick={(e) => proceedToNextStage(AutoCourseCreatorLanguageProcessStages.FocusAreaSelection)}
                style={!isConstructingChapters ? { width: 100, height: 48, borderRadius:8, background: "var(--Blue, #44A6EF)", textTransform: 'none', color: 'white',}
                       : {  width: 100, height: 48, borderRadius:8, background: "#C7C7CC", textTransform: 'none', color: 'white',}}
                disabled={isConstructingChapters}>
                Continue
              </Button>
            </div>
          </Grid>
        </motion.div>
      </AnimatePresence>
    )
  }
  

  const continueFromVocabularySelection = () => {

    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0, marginBottom: '20px' }}
          animate={{ opacity: 1, height: 'auto', marginBottom: '20px', transition: { opacity: { duration: 1 } }  }}
          exit={{ opacity: 0, height: 0, marginBottom: 'auto', transition: { opacity: { duration: 0.2 } } }}
        >
          <Grid item xs={12}>
            <div style={{ borderRadius: 8, display: 'flex', justifyContent: 'center', width: '100%',  }}>
              <Button
                onClick={(e) => proceedToNextStage(AutoCourseCreatorLanguageProcessStages.ChooseNarrativeType)}
                style={{ borderRadius:8, background: "var(--Blue, #44A6EF)", textTransform: 'none', color: 'white',}}>
                Continue
              </Button>
            </div>
          </Grid>
        </motion.div>
      </AnimatePresence>
    )
  }

  const continueFromFocusAreaSelection = () => {
    
    
    
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0, marginBottom: '20px' }}
          animate={{ opacity: 1, height: 'auto', marginBottom: '20px', transition: { opacity: { duration: 1 } }  }}
          exit={{ opacity: 0, height: 0, marginBottom: 'auto', transition: { opacity: { duration: 0.2 } } }}
        >
          <Grid item xs={12}>
            <div style={{ borderRadius: 8, display: 'flex', justifyContent: 'center', width: '100%',  }}>
              <Button
                onClick={(e) => proceedToNextStage(AutoCourseCreatorLanguageProcessStages.TransitioningToReviewLessonPlans)}
                style={{ borderRadius:8, background: "var(--Blue, #44A6EF)", textTransform: 'none', color: 'white',}}>
                Continue
              </Button>
            </div>
          </Grid>
        </motion.div>
      </AnimatePresence>
    )
  }

  const selectFocusAreas = () => {    
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
          <Box style={{
            color: "var(--White, #FFF)",
            textAlign: "center",
            fontFamily: "Rubik",
            fontSize:32,
            fontStyle: "normal",
            fontWeight:400,
            lineHeight: "118%",
              }}>
            Select the focus areas for your course
          </Box>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '20px' }}>
          <Box style={{
            color: "rgba(255, 255, 255, 0.80)",
            textAlign: "center",
            fontFamily: "Rubik",
            fontSize:20,
            fontStyle: "normal",
            fontWeight:400,
            lineHeight: "118%",
              }}>
            The focus area will be distributed across the lessons.
          </Box>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '6px', marginBottom: '30px' }}>
          <div style={{borderRadius:16, background: "#FFF", width: '620px', padding: '30px'}}>
            <ACCFocusArea onUpdateFocusPoints={onUpdateFocusPoints}  number={tempFocusAreas['Listening']} focusAreaType={AutoCourseCreatorFocusAreas.Listening} focusAreaText="Listening" description="Understanding spoken language through different listening comprehension exercises."/>
            <ACCFocusArea onUpdateFocusPoints={onUpdateFocusPoints}  number={tempFocusAreas['Reading']} focusAreaType={AutoCourseCreatorFocusAreas.Reading} focusAreaText="Reading" description="Understanding different types of written texts in different reading comprehension exercises."/>  
            <ACCFocusArea onUpdateFocusPoints={onUpdateFocusPoints}  number={tempFocusAreas['SpokenProduction']} focusAreaType={AutoCourseCreatorFocusAreas.SpokenProduction} focusAreaText="Spoken Production" description="Expressing oneself orally, in exercises using speech recognition."/> 
            <ACCFocusArea onUpdateFocusPoints={onUpdateFocusPoints}  number={tempFocusAreas['SpokenInteraction']} focusAreaType={AutoCourseCreatorFocusAreas.SpokenInteraction} focusAreaText="Spoken Interaction" description="Engaging in simulated conversations in our voice-driven role plays."/>  
            <ACCFocusArea onUpdateFocusPoints={onUpdateFocusPoints}  number={tempFocusAreas['Writing']} focusAreaType={AutoCourseCreatorFocusAreas.Writing} focusAreaText="Writing" description="Writing and spelling of words and short phrases and sentences in different writing exercises."/>  
          </div>
        </Grid>
        {continueFromFocusAreaSelection()}
   </React.Fragment>
    )
  }
  
  const selectExistingMaterial = () => {    
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box style={{
                color: "var(--White, #FFF)",
                textAlign: "center",
                fontFeatureSettings: "cv11 on, cv01 on, ss01 on",
                fontFamily: "Rubik",
                fontSize: 24,
                fontStyle: "normal",
                fontWeight: 400,
                maxWidth: '580px', 
                margin: '0 auto' 
              }}>
            What content should your lessons be built on?
          </Box>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} style={{marginTop: '20px'}}>
            <Grid container>
            <Grid item xs={12}>
  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      //alignSelf: "stretch",
      width: '450px'
    }}>
      <Box style={{
        color: "var(--White, #FFF)",
        fontFamily: "Rubik",
        fontSize:16,
        fontStyle: "normal",
        fontWeight:500,
        lineHeight: "140%",
      }}>
        AI's general knowledge
      </Box>  
      <div style={{ marginLeft: 'auto' }}>
        <GreenSwitch inputProps={{ 'aria-label': 'controlled' }} defaultChecked onChange={handleToggleUseAIGeneralKnowledge} checked={!!!props?.autoCourseCreation?.authorInputMetadata?.useOnlyText}  />
      </div>
    </div>
  </div>
</Grid>
            <Grid item xs={12}>
              <div style={{  display: 'flex', justifyContent: 'center', width: '100%', }} >
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  width: '450px'
                }}>
                  <Box style={{
                    color: "var(--White, #FFF)",
                    fontFamily: "Rubik",
                    fontSize:14,
                    fontStyle: "normal",
                    fontWeight:400,
                    lineHeight: "140%",
                  }}>
                    Allow the AI to use general knowledge to build your lessons. You may choose to add additional content too.
                  </Box>
                  </div>
                </div>
              </Grid>
              <div style={{width: '610px',margin: 'auto', marginTop: '10px', marginBottom: '10px',  }}>
                <Divider style={{ borderColor: 'rgba(255, 255, 255, 0.32)', }}
                  />
              </div>
            </Grid>
          </Grid>
        <Grid item xs={12}>
            {pasteText()}
          </Grid>
          <Grid item xs={12}>
           {uploadDocument()}
          </Grid>
          <Grid item xs={12}>
            <div style={{width: '420px',margin: 'auto' }}>
              {continueFromContentAdder()}
            </div>
          </Grid>
              <div style={{
                  width: '420px',}}
              >
            </div>
      </Grid>
   </React.Fragment>
    )
  }


  const loadingLessonPlansSpinner = () => {
    return (
      <React.Fragment>

        {
          props.autoCourseCreation.lessonDistributionFailed &&
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
               <Typography>
                 Failed to generate lessons, try again, but might fail again
               </Typography>           
                <Button onClick={() => doLessonDistribution()}>
                  Try again
                </Button>
          </Grid>
          }
 
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <ACCLanguageLoadingSpinner mainText={"Generating lesson plans"} infoTexts={["We are now building your lessons. They will be ready to play and edit in around one minute...", "We are now building your lessons. They will be ready to play and edit in around one minute...", "We are now building your lessons. They will be ready to play and edit in around one minute...", "We are now building your lessons. They will be ready to play and edit in around one minute..."]} estimatedDuration={110}/>
        </Grid>  
        
        
        
      </React.Fragment>
    )
  }
  
  const loadingCourseContentSuggestion = () => {    
    return (
    <React.Fragment>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <ACCLanguageLoadingSpinner mainText={"Generating vocabulary"} infoTexts={["We are generating suggested vocabulary items. This will take up to 30 seconds...", "We are generating suggested vocabulary items. This will take up to 30 seconds...", "We are generating suggested vocabulary items. This will take up to 30 seconds...", "We are generating suggested vocabulary items. This will take around 30 seconds..."]} estimatedDuration={60}/>
      </Grid>
    </React.Fragment>
    )
  }

  const languageLevelSelection = () => {    
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography style={{color: "#FFF", textAlign: "center", fontFamily: "Rubik", fontSize:18, fontStyle: "normal", fontWeight:400, letterSpacing:0.41, marginBottom: '5px'}}>
        What language level should the lessons be set at?
      </Typography>
      <motion.div
        className="box"
        whileHover={{ scale: [null, 1.05, 1.05] }}
        transition={{ duration: 0.3 }}
       >
        <FormControl style={{ minWidth: '100px', border: '0px',  }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.autoCourseCreation?.authorInputMetadata?.level || 1}
            // disableUnderline
            onChange={handleChangeCefr}
            style={{ color: '#FFF', fontFamily: 'Nunito', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, letterSpacing: '0.41px', textAlign: 'center', paddingTop: '0px', height: '35px',background: "rgba(255, 255, 255, 0.24)", }}
          >
            {Object.entries(AutoLanguageCourseLanguageLevels).map(([level, value]) => (
              <MenuItem
                key={value}
                value={value}
                style={{ color: '#35323C', fontFamily: 'Nunito', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, letterSpacing: '0.41px' }}
              >
                {level}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </motion.div>
    </div>
    )
  }

  const titleSection = (array, id) => {    
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ zIndex: 1}}>
          <div style={{display: 'flex', justifyContent: 'center', zIndex: 1}}>
            <FullLogoLanguage style={{width: '500px', zIndex: 1}}></FullLogoLanguage>
          </div>
        </Grid>
        <motion.div
          className="box"
          animate={{
            scale: [0.98, 1],
            rotate: [0, 0],
            borderRadius: ["0%", "0%"]
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.1,],
            repeat: 0,
            repeatDelay: 1
          }}
        >
          <Box style={{ marginTop: '5px', color: 'white', fontSize: 30, fontFamily: 'Rubik', fontWeight: '200', textAlign: "center",}}>
                Language Lessons
          </Box>
        </motion.div>
      </React.Fragment>

    )
  }
  
  const vocabularySection = () => {    
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '4px', marginTop: '50px' }}>
          <Typography style={{color: "var(--White, #FFF)", textAlign: "center", fontFeatureSettings: "cv11 on, cv01 on, ss01 on", fontFamily: "Rubik", fontSize:32, fontStyle: "normal", fontWeight:400, lineHeight: "118%",}}>
            Choose your content
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
          <Typography style={{color: "rgba(255, 255, 255, 0.80)", fontFamily: "Rubik", fontSize:20, fontStyle: "normal", fontWeight:400, lineHeight: "118%",}}>
            Select the vocabulary items that you want the lessons to teach.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', }}>
            <div style={{borderRadius:8, border: "2px solid rgba(255, 255, 255, 0.32)", marginBottom: '30px', background: 'white', width: '900px', padding: '16px' }}>
              <Grid item xs={12} style={{marginLeft: '20px', marginRight: '20px', marginTop: '20px'}}>
                <AutoLanguageCourseSuggestionBox
                  lessonContentViewMode={lessonContentViewMode} 
                  onSetPosSelection={handleSetPosSelection} 
                  posSelection={posSelection} 
                  onToggleLessonContentViewMode={handleToggleLessonContentMode} 
                  onAddVocabularyItem={handleAddVocabularyItem}
                  onToggleVocabularyItem={handleToggleVocabularyItem}
                  categoryVocabularyItems={tempVocabularyItems} 
                />
              </Grid>
              {/*<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px', marginBottom: '10px' }}>
                <TextField
                  id="outlined-textarea"
                  placeholder="Generate more like..."
                  variant="standard"
                  InputProps={{
                        disableUnderline: true,
                      }}
                  sx={{
                  '& .MuiInputBase-input': {
                    borderBottom: "2px solid rgba(204, 133, 198, 0.50)", 
                    minWidth: '300px',
                    marginRight: '3px'
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: "var(--Darker60, rgba(96, 96, 96, 0.60))",
                    fontFamily: "Rubik",
                    fontSize:18,
                    fontStyle: "normal",
                    fontWeight:400,
                    lineHeight: "140%",
                      },
                    }}       
                >
                </TextField>
                <Button style={{textTransform: 'none', color: 'white', borderRadius:16, background: "var(--Purple, #A0439F)",}}>
                  Generate
                </Button>
            </Grid>*/}
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '10px', marginLeft: '20px', marginRight: '20px' }}>
              <ButtonBase
                disabled={true}
                style={{
                  borderRadius:8,
                  border: "1px solid var(--Darker30, rgba(96, 96, 96, 0.30))",
                  width: '100%'
                }}
              >
                <span>
                  <ComingSoonSvg />
                </span>
                <div
                  style={{
                    padding: '16px 0',
                    borderRadius: 8,
                    position: 'relative',
                    width: '100%',
                  }}
                >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', // Center the contents.
                    opacity: 1,
                  }}
                >
                  <span
                    style={{
                      color: "var(--Darker60, rgba(96, 96, 96, 0.60))",
            textAlign: "center",
            /* Heading 3 */
            fontFamily: "Rubik",
            fontSize:20,
            fontStyle: "normal",
            fontWeight:400,
            lineHeight: "118%",
                    }}
                  >
                    Grammar, culture and language rules
                  </span>
                </Box>
                <Box
                  style={{
                    color: "var(--Darker60, rgba(96, 96, 96, 0.60))",
                    fontFamily: "Rubik",
                    fontSize:14,
                    fontStyle: "normal",
                    fontWeight:400,
                    lineHeight: "140%",
                    textAlign: 'center', // Ensuring the description is also centered.
                    marginLeft: '20px',
                    marginRight: '20px'
                  }}
                >
                  Add specialised lessons that teach grammar, cultural information, or other language rules.
                </Box>
              </div>
            </ButtonBase>
          </Grid>
        </div>
      </Grid>
   </React.Fragment>
    )
  }
  useEffect(() => {
    if (props.autoCourseCreation.suggestSectionsReturnDto?.sections?.length > 0) {
      const firstSection = props.autoCourseCreation.suggestSectionsReturnDto.sections[0]
      if (firstSection.concepts?.length > 0) {
        setTempVocabularyItems(firstSection.concepts)
      }
    }
  }, [props.autoCourseCreation.suggestSectionsReturnDto])
  const handleAddVocabularyItem = (vocabularyItem) => {    
    let found = false
    let localAtempVocabularyItems= [...tempVocabularyItems]
    localAtempVocabularyItems.forEach(item => {
      if(item.text === vocabularyItem) {
        found = true
      }
    });
    if(found === false) {
      localAtempVocabularyItems.push({
        conceptId: uuidv4(),
        imageDescription: "",
        imageUrl: "",
        partOfSpeech: "",
        pos: "",
        sectionId: tempVocabularyItems[0].sectionId,
        selectedByAi: true,
        text: vocabularyItem,
        translatedExplanation: "",
        translation: "",
        usedInLesson: true
      })
    }    
    setTempVocabularyItems(localAtempVocabularyItems);
    // add it here
    props.dispatch(autoCourseCreationActions.accLanguageAddVocabItem(localAtempVocabularyItems))

  }

  const handleToggleVocabularyItem = (vocabularyItem) => {    
    let localAtempVocabularyItems= [...tempVocabularyItems]
    let tempUsedInLesson = !!!vocabularyItem.usedInLesson

    props.dispatch(autoCourseCreationActions.accLanguageToggleConceptInUse(vocabularyItem.sectionId, vocabularyItem.conceptId, tempUsedInLesson))

    const firstSection = props.autoCourseCreation.suggestSectionsReturnDto.sections[0]
    if (firstSection.concepts?.length > 0) {
      setTempVocabularyItems(firstSection.concepts)
    }
  }

  const handleToggleLessonContentMode = (lessonContentViewMode) => {    
    setLessonContentViewMode(lessonContentViewMode)
  }

  const handleSetPosSelection = (pos) => {   
    if(pos === "Noun Phrase"){
      // so that nouns and noun phrases appear in the same tab
      setPosSelection("Noun")
    } else if(pos === "Verb Phrase") {
      // same for verb and verb phrases
      setPosSelection("Verb")
    } else {
      setPosSelection(pos)
    }
  }
  

  const handleChangeCefr = (event) => {    
    let localAuthorInputMetadata = {...props.autoCourseCreation?.authorInputMetadata}
    localAuthorInputMetadata.level = event.target.value
     props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localAuthorInputMetadata))
  }
  
  const handleSelectVocabularyCategory = (category) => {    
    setSelectedVocabularyCategory(category)
  }
  
  useEffect(() => {
    // Add a new ref for the new TextField when a new item is added to the list
    tempVocabularyItemsRef.current = tempVocabularyItemsRef.current.slice(0, tempVocabularyItems.length);
  }, [tempRules]);
  
  const courseGroupId = computedMatch.params.courseGroupId;
  
  let topicIdAsInt = parseInt(computedMatch.params.topicId);
  if (topicIdAsInt <= 0) {
    topicIdAsInt = null;
  }
  const topicId = topicIdAsInt;
  const queryParamsSearch = useLocation().search;
  
  
  const initialFocusArea = {
    "reading": tempFocusAreas[AutoCourseCreatorFocusAreas.Reading],
    "writing": tempFocusAreas[AutoCourseCreatorFocusAreas.Writing],
    "listening": tempFocusAreas[AutoCourseCreatorFocusAreas.Listening],
    "spokenProduction": tempFocusAreas[AutoCourseCreatorFocusAreas.SpokenProduction],
    "spokenInteraction": tempFocusAreas[AutoCourseCreatorFocusAreas.SpokenInteraction]
  }
  
  const tempAuthorMetadataDto = {
    autoCourseCreateType: AutoCourseCreateType.Language,
    authorCourseName: "",
    authorWhatText: "",
    authorWhyText: "",
    authorTargetAudienceText: "Young adults",
    authorToneOfVoice: AutoCourseCreatorToneOfVoice.AbePersonality,
    authorLessonType: AutoCourseCreatorLessonType.Vocabulary,
    organizationId: props.organization.selectedOrganization,
    l2Id: 1, // Gets swapped out in the initial useEffect
    defaultL1Id: 2, // Gets swapped out in the initial useEffect
    monolingual: false,
    authorTextLanguageId: 2,
    includeTranslation: true,
    includeExplanation: true,
    includeRuleTranslations: true,
    includeExam: false,
    teachAllText: false,
    level: AutoCourseCreatorLevelType.Beginner,
    numberOfLessons: 4,
    wordsPerLesson: 7,
    supportingText: "",
    topicId: topicId,
    courseGroupId: courseGroupId,
    narrativeType: AutoCourseCreatorNarrativeType.Fictional,
    focusAreas: initialFocusArea
  }
  
  const [authorMetadataDto, setAuthorMetadataDto] = useState(tempAuthorMetadataDto);
  const [copyPasteTextValue, setCopyPastTextValue] = useState("");
  
  useEffect(() => {
    if (!isStarted) {
      return;
    }
    setIsVisible(true);
    const timer = setInterval(() => {
      setIsVisible(false);
      
      setTimeout(() => {
        setProcessingStatusText((prev) => {
          if (prev + 1 >= statusTexts.length) {
            // We reached the last status. Clear the interval!
            clearInterval(timer);
            return prev;
          } else {
            setIsVisible(true);
            return prev + 1;
          }
        });
      }, 1000);
      
    }, 12000);
  
    return () => {
      clearInterval(timer);
    };
  }, [isStarted, statusTexts.length]);


  const containerRef = useRef(null);
  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    props.dispatch(autoCourseCreationActions.accOnRefreshResetSome());
    //props.dispatch(autoCourseCreationActions.accResetAllLessonPlans())
    if(props.autoCourseCreation.processingState === AutoCourseCreatorProcessStages.ReviewLessonPlans) {
      setEntryPoint(AutoCourseCreatorProcessStages.ReviewLessonPlans)
    } else {
      setEntryPoint(1)
    }

    const createFromTopic = new URLSearchParams(queryParamsSearch).get("createFromTopic");
    if (createFromTopic === "true") {
      const l2Id = new URLSearchParams(queryParamsSearch).get("l2Id");
      const defaultL1Id = new URLSearchParams(queryParamsSearch).get("defaultL1Id");
      if (l2Id && defaultL1Id) {
        let localMetaData = {...authorMetadataDto}
        localMetaData.l2Id = parseInt(l2Id);
        localMetaData.defaultL1Id = parseInt(defaultL1Id);
        localMetaData.authorTextLanguageId = parseInt(defaultL1Id);
        localMetaData.topicId = topicId;
        props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localMetaData))
        setAuthorMetadataDto(localMetaData)
      }
    }
    
    const selectedLessonPlanId = new URLSearchParams(queryParamsSearch).get("selectedLessonPlanId");
    if (selectedLessonPlanId) {
      handleUpdateSelectedLesson(selectedLessonPlanId);  
    }
    window.addEventListener('beforeunload', alertUser)
    props.dispatch(autoCourseCreationActions.accOnRefreshResetSome());
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
    
  useEffect(() => {
    if(props.autoCourseCreation.suggestSectionsReturnDto !== null) {
      setFinishedCreateSections(true)
    }
  }, [props.autoCourseCreation.suggestSectionsReturnDto])
  
  useEffect(() => {
    if (isStarted && buttonClicked && !finishedCreateSections && props.autoCourseCreation.suggestingTextSectionsFailed) {
      // failed to create the text suggestions, resetting to original state
      setFinishedCreateSections(true);
      setIsStarted(false);
      setButtonClicked(false);
      setButtonOpacity(1)
    }
  }, [props.autoCourseCreation.suggestingTextSectionsFailed])
  
  useEffect(() => {
    if(containerRef!== null && containerRef !== undefined) {
    } 
  }, [props.autoCourseCreation])


  const handleResetData = () => {
    setIsResetConfirmationDialogueOpen(false)
    props.dispatch(autoCourseCreationActions.accResetAll())
    props.dispatch(autoCourseCreationActions.accResetAllLessonPlans())
    props.dispatch(autoCourseCreationActions.accResetAllSections())
    setEntryPoint(1)
    setShowTopics(true)
    props.dispatch(autoCourseCreationActions.transitionStage({stage: 1}))
    setSelectedLesson(null)
    setFinishedCreateSections(false)
    setShowStartScreen(true)
    setButtonClicked(false)
    setButtonOpacity(1)
    let localMetaData = {...authorMetadataDto}  
    localMetaData.authorWhatText = "";
    localMetaData.authorCourseName = ""
    localMetaData.supportingText = "";
    props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localMetaData))
    setAuthorMetadataDto(localMetaData)
  }

  const handleResetConfirmation  = () => {
   setIsResetConfirmationDialogueOpen(true)
  }


 const handleSetAuthorInputMetaData = (attribute, text) =>{ 
  let localMetaData = {...authorMetadataDto}
  
  switch (attribute) {
    case AutoCourseCreateDialogStepType.CourseName:
      localMetaData.authorCourseName = text;
      break;
    case AutoCourseCreateDialogStepType.What:
      localMetaData.authorWhatText = text;
      localMetaData.authorCourseName = text
      break;
    case AutoCourseCreateDialogStepType.Why:
      localMetaData.authorWhyText = text;
      break;
    case AutoCourseCreateDialogStepType.SupportingText:
      localMetaData.supportingText = text;
      break;
    case AutoCourseCreateDialogStepType.ToneOfVoice:
      localMetaData.authorToneOfVoice = text;
      break;
  }
  props.dispatch(autoCourseCreationActions.accStoreAuthorInputMetadata(localMetaData))
  setAuthorMetadataDto(localMetaData);
 }

  const handleOpenLessonPreview = (customLessonId) => {
    
    document.dispatchEvent(GlobalEvents.UnityLessonPreviewHideShowToggle);
    document.dispatchEvent(new CustomEvent("initLessonPreviewWithInfo",
        { detail: { customLessonId: customLessonId, l1Id: authorMetadataDto.defaultL1Id, nodeId: null,
            clickableSkipButton: true, l2Id: authorMetadataDto.l2Id }}));

  }
 
 const proceedToNextStage = (stage) => {
  setVisible(false);
  setIsOpen(false)
  
  if (stage === AutoCourseCreatorLanguageProcessStages.LoadingVocabulary) {

    const vocabRequestDto = {
      authorInputMetadata: authorMetadataDto
    }
    
    props.dispatch(autoCourseCreationActions.accLanguageSuggestVocab(vocabRequestDto));
  }
  
  if (stage === AutoCourseCreatorLanguageProcessStages.ChooseNarrativeType) {
    let selectedConcepts = []
    const firstSection = props.autoCourseCreation.suggestSectionsReturnDto.sections[0]
    if (firstSection.concepts?.length > 0) {
      firstSection.concepts.forEach(x => {
        if (x.usedInLesson) {
          selectedConcepts.push(x)
        }
      })
    }
    props.dispatch(autoCourseCreationActions.accLanguageSuggestImagesForSelected(props.autoCourseCreation.authorInputMetadata, selectedConcepts))
  }
   
   if (stage === AutoCourseCreatorLanguageProcessStages.TransitioningToReviewLessonPlans) {
     
     // store focus area distribution on authorMetadataDto
     
     
     //TODO: look thought the list of selected chapters and make sure they are either being generated or already available 
     
     
   }
   
  props.dispatch(autoCourseCreationActions.transitionLanguageStage({ stage: stage }));
    
};


  useEffect(() => {
    if (props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.TransitioningToReviewLessonPlans) {

      let initExpansionForChapterIds = []
      props.autoCourseCreation.selectedChapterOptions.forEach(selectedChapterOption => {
        const alreadyProcessed = props.autoCourseCreation.lessonPlanChapterDtos?.find(x => x.chapterId === selectedChapterOption.chapterId)
        const alreadyStartedGenerating = props.autoCourseCreation.expandingChapterIds.findIndex(x => x === selectedChapterOption.chapterId) > -1;
        if (!alreadyProcessed && !alreadyStartedGenerating) {
          // need to fire off expansion of the chapter option
          initExpansionForChapterIds.push(selectedChapterOption.chapterId)
        }
      })

      if (initExpansionForChapterIds.length > 0) {
        let prevChapters = []
        props.autoCourseCreation.selectedChapterOptions.forEach(selectedChapterOption => {
          if (initExpansionForChapterIds.indexOf(x => x === selectedChapterOption.chapterId)) {
            // fire off 
            let concepts = []
            selectedChapterOption.vocabularyInChapter.forEach(conceptId => {
              const concept = props.autoCourseCreation.suggestSectionsReturnDto.sections[0].concepts.find(x => x.conceptId === conceptId);
              if (concept) {
                concepts.push(concept)
              }
            })
            props.dispatch(autoCourseCreationActions.accLessonPlanChapterExpand(selectedChapterOption, concepts, prevChapters, props.autoCourseCreation.authorInputMetadata))

          }
          prevChapters.push(selectedChapterOption)
        })
        return;
      }
      
      if (props.autoCourseCreation.isDistributingLessons) {
        return;
      } else if (props.autoCourseCreation.languageSectionPlanDto) {
        // now done and can transition to lesson plans
        
        // trigger generation of all lessons
        
        if (props.autoCourseCreation.languageSectionPlanDto.languageLessonPlanDtos?.length > 0) {
          props.autoCourseCreation.languageSectionPlanDto.languageLessonPlanDtos.forEach(languageLessonPlan => {
            props.dispatch(autoCourseCreationActions.accLessonPlanGeneration(props.autoCourseCreation.authorInputMetadata, languageLessonPlan))
          })
        }
         
        proceedToNextStage(AutoCourseCreatorLanguageProcessStages.ReviewLessonPlans);
        return;
      }
      
      // not generartion lesson plans or finished, so need to check if the expanding is done
      if (props.autoCourseCreation.expandingChapterIds.length === 0 && !props.autoCourseCreation.lessonDistributionFailed && !props.autoCourseCreation.accLanguageSuggestingImages) {
        doLessonDistribution()
      }
    }
  }, [props.autoCourseCreation]);


  const handlePreviewLesson = (customLessonId) => {
    setCustomLessonIdToPreview(customLessonId);
    setShowLessonPreview(true);
  }

  const handleResetUnityState = async () => {
    if (unityStagePreviewControllerRef.current) {
      await unityStagePreviewControllerRef.current.unloadUnity();
    }
  }
  
  const doLessonDistribution = () => {
    let lessonPlanChapterDtosInOrder = []
    props.autoCourseCreation.selectedChapterOptions.forEach(selectedChapterOption => {
      let lessonPlanChapterDto = props.autoCourseCreation.lessonPlanChapterDtos.find(x => x.chapterId === selectedChapterOption.chapterId)
      if (lessonPlanChapterDto) {
        lessonPlanChapterDtosInOrder.push(lessonPlanChapterDto)
      }
    })
    
    // move over images from section concepts
    lessonPlanChapterDtosInOrder.forEach(lessonPlanChapterDto => {
      lessonPlanChapterDto.concepts.forEach(concept => {
        props.autoCourseCreation.suggestSectionsReturnDto.sections[0].concepts.forEach(imageConcept => {
          if (concept.conceptId === imageConcept.conceptId && imageConcept.imageUrl) {
            concept.imageUrl = imageConcept.imageUrl
            concept.imageDescription = imageConcept.imageDescription
          }
        }) 
      })
    })
    
    
    const languageLessonDistributionRequestDto = {
      authorInputMetadata: props.autoCourseCreation.authorInputMetadata,
      lessonPlanChapters: lessonPlanChapterDtosInOrder,
      section: props.autoCourseCreation.suggestSectionsReturnDto.sections[0]
    }

    props.dispatch(autoCourseCreationActions.accLessonDistribution(languageLessonDistributionRequestDto))
  }
  
  const handleMakeNewLessonPlanWithFocusAreaChanges = (lessonPlanId, focusAreas) => {
    if (props.autoCourseCreation.languageSectionPlanDto?.languageLessonPlanDtos?.length <= 0) {
      return;
    }
    
    const languageLessonPlan = props.autoCourseCreation.languageSectionPlanDto.languageLessonPlanDtos.find(x => x.lessonPlanId === lessonPlanId)
    if (!languageLessonPlan) {
      return;
    }
    const editableLanguageLessonPlan = {...languageLessonPlan}
    editableLanguageLessonPlan.focusDistributionDto = focusAreas
    editableLanguageLessonPlan.majorChanges = true
    
    props.dispatch(autoCourseCreationActions.accRecreateSingleLessonPlan(props.autoCourseCreation.authorInputMetadata, editableLanguageLessonPlan))
  }
  
  const checkForNewChangesOrSaveLessonPlan = (lessonPlanId) => {
    const languageLessonPlanToCheck = props.autoCourseCreation.languageSectionPlanDto.languageLessonPlanDtos.find(x => x.lessonPlanId === lessonPlanId);

    console.log("Checking..")
    // if (languageLessonPlanToCheck && languageLessonPlanToCheck.lastChange) {
    //   const tempDate = new Date().valueOf()
    //   console.log("Checking timestamp:" + languageLessonPlanToCheck.lastChange + " with change " + (tempDate - languageLessonPlanToCheck.lastChange)/1000)
    //   if (tempDate - languageLessonPlanToCheck.lastChange > 9000) {
    //     console.log("Updating lesson")
    //     handleMakeLessonFromLessonPlan(lessonPlanId)
    //   }
    // }
  }
  
  // useEffect(() => {
  //   if (!props.autoCourseCreation.languageSectionPlanDto?.languageLessonPlanDtos.length > 0) {
  //     return;
  //   }
  //   props.autoCourseCreation.languageSectionPlanDto.languageLessonPlanDtos.forEach(x => {
  //     if (x.majorChanges || x.lastChange) {
  //       if (checkSaveLanguageLessonPlanTimeout) {
  //         console.log("Clearing previous timeout.")
  //         clearTimeout(checkSaveLanguageLessonPlanTimeout);
  //       }
  //       console.log("Scheduling checking in 10 seconds")
  //       const tempTimeoutId = setTimeout(checkForNewChangesOrSaveLessonPlan, 10000, x.lessonPlanId)
  //       setCheckSaveLanguageLessonPlanTimeout(tempTimeoutId)
  //     }
  //   })
  // }, [props.autoCourseCreation.languageSectionPlanDto])
  
  const handleMakeLessonFromLessonPlan = (lessonPlanId) => {
    if (props.autoCourseCreation.languageSectionPlanDto?.languageLessonPlanDtos?.length <= 0) {
      return;
    }
    
    const languageLessonPlan = props.autoCourseCreation.languageSectionPlanDto.languageLessonPlanDtos.find(x => x.lessonPlanId === lessonPlanId)
    if (!languageLessonPlan) {
      return;
    }
    props.dispatch(autoCourseCreationActions.accRecreateSingleLessonPlan(props.autoCourseCreation.authorInputMetadata, languageLessonPlan))
  }
  
  const handleUpdateSelectedLesson = (lessonPlanId) => {
    if(selectedLesson !== null && lessonPlanId === selectedLesson.lessonPlanId) {
      setSelectedLesson(null)
      return
    }
    const foundObject = findObjectWithId(props.autoCourseCreation.lessonPlansDtos, lessonPlanId);
    if(foundObject !== null) {
      setSelectedLesson(foundObject)
      setSelectedStage(null)
    }
   // setSelectedLesson(lessonPlanId)
  }
  
   // This ref is used to reference the next section we want to scroll to.

  const findObjectWithId = (array, id) => {    
    // Loop through each inner array
    for (let i = 0; i < array.length; i++) {
      // Find the first object that matches the given id
      const foundObject = array[i].lessons.find((obj) => obj.lessonPlanId === id);
  
      if (foundObject) {
        return foundObject;
      }
    }
    
    return null; // If no matching object is found
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid
      container
      justifyContent="center" 
      style={{
        minHeight: "100vh",
        //overflow: 'auto', Removed to fix the strange scrolling topping then restarting issue
        position: 'relative',
        zIndex: 1,
        overflow: "hidden",
      }}
    >
      
      <div style={{position: 'fixed', top: 'calc(50% - 120px)', transform: 'translateY(-50%)', height: '300px', width: '230px', zIndex:0}}>
      <ACCLanguageSidebar
        onStartAgain={handleResetData}  
        currentStage={props.autoCourseCreation.processingStateLanguage}
      /> 
      </div>
    <Grid container style={{display: 'grid', alignContent: 'center', marginLeft: 23 + 4 * (100 - windowWidth / 23)}}>
      <div style={{
        position: 'fixed', 
        zIndex: -1,
        width: '125%',
        height: '100vh',
        //height: '125%',
        left: '0%',
        top: '0%',
        background: "linear-gradient(0deg, rgba(9, 40, 79, 0.24) 0%, rgba(9, 40, 79, 0.24) 100%), linear-gradient(72deg, rgba(162, 68, 160, 0.00) 47.32%, #1971DD 98.15%), linear-gradient(284deg, #A0439F 13.94%, #5B1E80 55.99%, #09284F 98.04%)",       
        backgroundSize: 'cover'
      }}>
        
      </div>
      <AnimatePresence>
        { 
          props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.StartSequence &&
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -1000, opacity: 0,  }}
            transition={{    duration: 0.6,
              ease: "easeInOut", }}
          >
            
            <div style={{ height: '100%', display: 'grid', alignContent: "center", }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} style={{ marginBottom: '20px', marginTop: '50px' }}>
                  {titleSection()}
                </Grid>
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                  {whatSection()}
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px', marginBottom: '10px' }}>
                  {languageLevelSelection()}
                </Grid>
                <Grid item xs={12} style={{ marginTop: '0px' }}>
                  {continueButtonSection()}
                </Grid>
              </Grid>
            </div>          
       </motion.div>
       }
    </AnimatePresence>
      
    <AnimatePresence> 
      {
        props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.ExistingContentSelection &&
        <motion.div
           initial={{y: 1000}}
           animate={{ y: 0, opacity: 1 }}
           exit={{ y: -1000, opacity: 0, transition: { delay: 0, duration: 0.6, ease: "easeInOut" } }}
           transition={{ delay:0.2,   duration: 0.6,
             ease: "easeInOut", }}
           >
            <div style={{ height: '100%', display: 'grid', }}>
              <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    {selectExistingMaterial()}
                  </Grid>
                </Grid>
              </div>
          </motion.div>
          }   
    </AnimatePresence>

    <AnimatePresence> 
      {
        props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.FocusAreaSelection &&
        <motion.div
           initial={{y: 1000}}
           animate={{ y: 0, opacity: 1 }}
           exit={{ y: -1000, opacity: 0, transition: { delay: 0, duration: 0.6, ease: "easeInOut" } }}
           transition={{ delay:0.2,   duration: 0.6,
             ease: "easeInOut", }}
           >
            <div style={{ height: '100%', display: 'grid', }}>
              <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    {selectFocusAreas()}
                  </Grid>
                </Grid>
              </div>
          </motion.div>
          }   
    </AnimatePresence>
      
    <AnimatePresence>
      {
        props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.SelectVocabularyAndGrammar &&
        <motion.div
          initial={{y: 1000, }} 
          animate={{ y: 0, opacity: 1 , }}
          exit={{ opacity: 0, transition: { delay: 0, duration: 0 }  }}
          transition={{ delay:0.2,   duration: 0.6,
            ease: "easeInOut", }}
          >
          {learningAreaInView === AutoLanguageCourseLanguageIntents.Vocabulary &&
            <div style={{ height: '100%', display: 'grid', width: '100%'}}>
            <Grid container spacing={2} >
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                  {vocabularySection()}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div style={{width: '420px',margin: 'auto' }}>
                  {continueFromVocabularySelection()}
                </div>
              </Grid>
            </div>
          }
        </motion.div>
      }
    </AnimatePresence>
      
      <AnimatePresence>
        {
          props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.ChooseNarrativeType &&
          <motion.div
            initial={{y: 1000,}}
            animate={{y: 0, opacity: 1,}}
            exit={{opacity: 0, transition: {delay: 0, duration: 0}}}
            transition={{
              delay: 0.2, duration: 0.6,
              ease: "easeInOut",
            }}
          >
              <div style={{height: '100%', display: 'grid', width: '100%'}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{marginBottom: '20px'}}>
                    <div> 
                        <AutoLanguageCourseNarrativeTypeScreen
                            selectedNarrativeOption={handleSelectNarrativeType}
                        />
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: '20px'}}>
                    {continueFromNarrativeSelection()}
                  </Grid>
                </Grid>
              </div>
          </motion.div>
        }
      </AnimatePresence>

      <AnimatePresence>
        {
          props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.ConstructChapters &&
          <motion.div
            initial={{y: 1000,}}
            animate={{y: 0, opacity: 1,}}
            exit={{opacity: 0, transition: {delay: 0, duration: 0}}}
            transition={{
              delay: 0.2, duration: 0.6,
              ease: "easeInOut",
            }}
          >
            <div style={{height: '100%', display: 'grid', width: '100%'}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    <AutoLanguageCourseChapterController
                      conceptsAmount={props.autoCourseCreation.suggestSectionsReturnDto.sections[0].concepts.filter(x => x.usedInLesson).length}
                      dispatch={props.dispatch}
                      authorMetadataDto={authorMetadataDto}
                      concepts={props.autoCourseCreation.suggestSectionsReturnDto.sections[0].concepts.filter(x => x.usedInLesson)}
                      handleIsConstructingChapters={handleSetIsConstructingChapters}
                    >
                      
                    </AutoLanguageCourseChapterController>
                  </div>
                </Grid>
                <Grid item xs={12} style={{marginBottom: '20px'}}>
                  
                  {continueFromChapterConstruction()}
                </Grid>
              </Grid>
            </div>
          </motion.div>
        }
      </AnimatePresence>

      <AnimatePresence>
        {
          props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.TransitioningToReviewLessonPlans &&
          <motion.div
              initial={{opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.8, duration: 1, ease: "easeInOut" }}}
              exit={{opacity: 0, transition: {delay: 0, duration: 0.2, ease: "easeInOut"}}}>
            {loadingLessonPlansSpinner()}  
          </motion.div>
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.ReviewLessonPlans &&
          <motion.div
          initial={{y: 1000}}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -1000, opacity: 0, transition: { delay: 0, duration: 0.6, ease: "easeInOut" } }}
          transition={{ delay:0.2,   duration: 0.6,
            ease: "easeInOut", }}
          >
           <div style={{ height: '100%', display: 'grid', }}>
             <Grid container spacing={2} justifyContent="center">
                 <Grid item xs={12} style={{ marginBottom: '20px' }}>
                 {<AutoLanguageCourseLessonPlanController
                    onMakeLessonFromLessonPlan={handleMakeLessonFromLessonPlan}
                    onMakeLessonPlanFromFocusAreaChanges={handleMakeNewLessonPlanWithFocusAreaChanges}
                    onPreviewLessson={handleOpenLessonPreview}
                 >
                    
                    </AutoLanguageCourseLessonPlanController>}
                 </Grid>
               </Grid>
             </div>
         </motion.div>
        }
        
      </AnimatePresence>
      
      
      
      {
        props.autoCourseCreation.processingStateLanguage === AutoCourseCreatorLanguageProcessStages.LoadingVocabulary &&
        <motion.div
            initial={{opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.8, duration: 1, ease: "easeInOut" }}}
            exit={{opacity: 0, transition: {delay: 0, duration: 0.2, ease: "easeInOut"}}}>
          {loadingCourseContentSuggestion()}
        </motion.div>
      }
    </Grid>
    </Grid>
    
  );
};


AutoLanguageCourseCreateController.propTypes = {};

const mapStateToProps = (state) => {
  const {organization, topic, lesson, metadata, autoCourseCreation} = state;
  return {
    organization,
    topic,
    lesson,
    metadata,
    autoCourseCreation
  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoLanguageCourseCreateController)
