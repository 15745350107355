import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import TestImageWizard from "../../../../assets/images/TestImageWizard.png";

import Typography from '@material-ui/core/Typography';
import {
  Grid,
} from '@material-ui/core';

export default memo(({ data }) => {
var sectionStyle = {
  position: 'absolute',
  top: '18px',
  left: '5px',
  height: '79px',
  width: '90px',
  background: "url(" + data.imageUrl + ") no-repeat bottom / 100% auto",
  // backgroundSize: '100% auto'
};

  return (
    <>
    <div> 
    
      <img
        src={TestImageWizard}
        style={{position: 'relative', width: '100px',
        height: 'auto'
      }}
    />
          <Grid style={{position: 'absolute', top: 25, left: 0}} container>
                {data?.texts?.length > 0 &&
                    <Grid item xs={12} sm={12}>
                      {
                        <Typography 
                          variant='body2'  
                          style={{
                          marginTop: '10px',
                          marginBottom: '3px',
                          boxShadow: 'none',
                          flex: 1,
                          fontSize: '5px',
                          color: 'white',
                          justifyContent: 'center',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          textAlign: 'center',
                          alignItems: 'center'}}>
                            {data.texts[0].text}
                        </Typography>
                      }
                    </Grid>
                }
                <Grid item xs={12} sm={12}>
                  {
                    <Typography 
                      variant='body2'  
                      style={{
                      width: '69px',
                      boxShadow: 'none',
                      flex: 1,
                      fontSize: '4px',
                      color: 'black',
                      backgroundColor: 'white',
                      justifyContent: 'center',
                      marginLeft: '15px',
                      marginRight: '15px',
                      textAlign: 'center',
                      alignItems: 'center'
                  }}>
                      {data.texts && data.texts[0].text} TITLE
                    </Typography>
                  }
            </Grid>
                {
                  data.testOptions && data.testOptions.map((testOption, index) =>
                    <Grid item xs={12} sm={12} key={index}>
                      {
                        <Typography 
                              variant='body2'  
                              style={{
                              width: '69px',
                              boxShadow: 'none',
                              flex: 1,
                              fontSize: '4px',
                              color: 'black',
                              backgroundColor: 'white',
                              justifyContent: 'center',
                              marginLeft: '15px',
                              marginRight: '15px',
                              textAlign: 'center',
                              alignItems: 'center'
                      }}>
                            {testOption.text}
                        </Typography>
                      }
                      </Grid>
                  )
                }
            </Grid>
      </div>

      <Handle
        type="target"
        position="left"
        id="target1"
        style={{ top: '98.5px', background: '#555' }}
      />
      <Handle
        type="source"
        position="right"
        id="exit0"
        style={{ top: '98.5px', background: '#555' }}
      />
    </>
  );
});

