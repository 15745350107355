import React, { memo, useRef, createRef, useEffect, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Zoom from '@mui/material/Zoom';
import TextField from '@mui/material/TextField';
import { useUpdateNodeInternals } from 'react-flow-renderer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import IconButton from '@mui/material/IconButton';
import DeleteCircleOutline from "@mui/icons-material/Delete";
import CircleIcon from '@mui/icons-material/Circle';
import {ScenarioNodeType} from "../../../../Logic/ScenarioConstants";

export default memo(({ data }) => {

  const elementsRef = useRef(data.options.map(() => createRef()));
  const  optionsRefs  = ['0' , '1', '2']
  const [isReady, setIsReady] = useState(false);
  const [isTextInFocus, setIsTextInFocus] = useState(false);

  const refsArray  =  optionsRefs.map(() => createRef());
  const [heights, setHeights] = useState([]);
  const [hasEdited, setHasEdited] = useState(false);
  const [hasRequestedAdd, setHasRequestedAdd] = useState(false);
  const  heightsRef  = useRef()
  heightsRef.current = heights
  const updateNodeInternals = useUpdateNodeInternals();
  const handleDuplicate = (event) => {
   data.onDuplicate(data.id, ScenarioNodeType.MultiChoice)

   event.stopPropagation();
   event.preventDefault();
  };

  const handleAdd = (event) => {
    data.onAdd(data.id, 2)
    event.stopPropagation();
    event.preventDefault();
   };

   const handleAddOther = (event) => {
    data.onAddOther(data.id, 2)
    event.stopPropagation();
    event.preventDefault();
   };

   const handleChangeSelectedNode = () => {
    setIsTextInFocus(true)
   }

   const handleEndSelection = () => {
     if(hasEdited === true) {
      data.onUpdateSelection(data.id, hasRequestedAdd)
      setHasEdited(false)
     }
     setIsTextInFocus(false)
  }

   const changeText = (e, index) => {
    setHasEdited(true)
    data.onUpdateAnswerText(e.target.value, index, data.id, false, 0, 0)
   }

   const addAlternative = (e, index) => {
    e.stopPropagation();
    setHasRequestedAdd(true)
    data.onAddAlternative(data.id)
  }
   
  const handlePlayClick = () => {
    data.onPlayAppFromNode(data.id)
  }

  const handleDeleteClick = () => {
    data.onDeleteNode(data.id)
  }
  
  return (
    <>
      <Zoom in={true}>
        <div>
          <div style={{marginLeft: '20px', width: '150px' ,display:"flex", flexDirection:"row", textAlign: 'center', height: '25px', borderradius: '5px 5px 0px 0px'}}>
            <div style={{backgroundColor: '#EFEFF4', width: '41px'}} sx={{ float: 'left'}}>
              <IconButton onMouseDown={(event) => handlePlayClick()} sx={{ fontSize: "17px",  display: 'center', alignItems: 'left', marginLeft: '4px', justifyContent: 'center',  height: '26px', color: 'black'}}>
                <PlayCircleOutlineIcon sx={{ fontSize: "17px"}}/>
              </IconButton>
            </div>

            {
              data.canEditLesson &&
              <div style={{backgroundColor: '#EFEFF4',marginLeft: '105px', width: '41px'}} sx={{ float: 'right'}}>
                <IconButton onMouseDown={(event) => handleDeleteClick()} sx={{ fontSize: "17px",  display: 'center', alignItems: 'left', marginLeft: '4px', justifyContent: 'center',  height: '26px', color: 'black'}}>
                  <DeleteCircleOutline sx={{ fontSize: "17px"}}/>
                </IconButton>
              </div>
            }
          </div>
        <Card style={{minHeight: '135px', width: '220px', backgroundColor: 'white', borderStyle: 'solid',
          borderWidth: data?.validationErrors?.length > 0 ? '2px' : 0,
          borderColor: data?.validationErrors?.length > 0 ? 'red' : 'white',
          boxShadow: '0px 4px 4px 0px #00000040'
        }}>
            <Grid container >
              <Grid style={{backgroundColor: '#80D387', height: '35px'}} item xs={12} sm={12} md={12}>
                <Typography variant="body1" style={{
                  height: '100px',
                  paddingTop: '4px',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontFamily: 'Lato',
                }}>
                    Multichoice Stage
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{marginTop: '12px'}}>
                <Typography variant="body1" style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  letterSpacing: '0.5px',
                  marginLeft: '11px',
                  marginRight: '5px',
                  marginBottom: '5px',
                  color: '#000000'
                  }}>
                    Write alternatives:
                </Typography>
                <Zoom in={true} style={{ transitionDelay:  0}}>

                <TextField 
                  style={{  
                    fontStyle: 'normal', fontWeight: 400,fontSize: '14px',
                    letterSpacing: '0.5px',
                    marginLeft: '10px',width: '200px',
                    color: '#000000',
                    boxSizing: 'border-box',
                    borderRadius: '10px',
                  }} 
                    disabled={data.isBusy || !data.canEditLesson} 
                    onChange={(event) => changeText(event, 0)}  
                    hiddenLabel 
                    variant="filled" 
                    multiline 
                    value={data?.options?.length > 0 ? data.options[0]: ""}  
                    InputProps={{ disableUnderline: true, padding: 0, borderRadius: '100px' }} 
                    onBlur={handleEndSelection} 
                    onFocus={handleChangeSelectedNode} 
                  />
                  </Zoom>
              </Grid>
              {data?.options?.length > 1 &&
              <Grid item xs={12} sm={12} md={12} style={{marginTop: '5px'}}>
              <Zoom in={true} style={{ transitionDelay:  0}}>
                <TextField 
                  style={{  
                    fontStyle: 'normal', fontWeight: 400,fontSize: '14px',
                    letterSpacing: '0.5px',
                    marginLeft: '10px',width: '200px',
                    color: '#000000',
                    boxSizing: 'border-box',
                    borderRadius: '10px',
                    minHeight: '34px',
                  }} 
                    disabled={data?.options?.length < 1 || data.isBusy || !data.canEditLesson } onChange={(event) => changeText(event, 1)}  hiddenLabel id="filled-basic" variant="filled" multiline value={data?.options?.length > 1 ? data.options[1]: ""}  InputProps={{ disableUnderline: true,margin:'dense' }} onBlur={handleEndSelection} onFocus={handleChangeSelectedNode} />
              </Zoom>
              </Grid>
            
              }
              {data?.options?.length > 2 &&
              <Grid item xs={12} sm={12} md={12} style={{marginTop: '5px'}}>
              <Zoom in={true} style={{ transitionDelay:  0}}>
                <TextField style={{  
                  fontStyle: 'normal', fontWeight: 400,fontSize: '14px',
                   letterSpacing: '0.5px',
                  marginLeft: '10px',width: '200px',
                  color: '#000000',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  minHeight: '34px',}} 
                  disabled={data?.options?.length < 2 || data.isBusy  || !data.canEditLesson } onChange={(event) => changeText(event, 2)}  hiddenLabel id="filled-basic" variant="filled" multiline value={data?.options?.length > 2 ? data.options[2]: ""}  InputProps={{ disableUnderline: true,margin:'dense' }} onBlur={handleEndSelection} onFocus={handleChangeSelectedNode} />
             </Zoom>
              </Grid>
              }

              {data?.options?.length <= 2 && data.canEditLesson &&
              <Grid item xs={12} sm={12} md={12} style={{marginTop: '5px', backgroundColor: 'white', minHeight: '70px'}}>
                <Button onMouseDown={(event) => addAlternative(event)} style={{textTransform: 'none',fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '16px',
                  /* identical to box height, or 100% */
                  display: 'flex',
                  alignItems: 'center',
                  letterSpacing: '0.5px',
                  marginLeft: '8px',
                  marginRight: '8px',
                  padding: '0px 0px 0px 10px',
                  width: '90%',

                  color: '#000000',
                  boxSizing: 'border-box',
                  border: '2px solid #E2F0FB',
                  borderEadius: '10px',
                  height: '44px',
                  }} 
                >
                  <AddCircleOutlineIcon style={{paddingRight: '5px'}}/>Add alternative
                </Button>
              </Grid>
              }
              <Divider style={{width:'100%', marginTop: '5px'}}/>
              {
                data.canEditLesson &&
                <Grid style={{backgroundColor: 'white', height: '40px'}} item xs={12} sm={12} md={12}>
                  <Button 
                    disabled={data.isBusy || isTextInFocus === true} 
                    onMouseDown={(event) => handleDuplicate(event)}
                    style={{
                      color: (data.isBusy || isTextInFocus) ? 'lightgrey' : '#4aa7ec',
                      width: '74px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }} 
                    variant="outlined" 
                    size="small" 
                    color="primary"
                    className={'nodrag'}>
                    Duplicate
                  </Button>
                  <Button 
                    disabled={data.isBusy || isTextInFocus === true} 
                    onMouseDown={(event) => handleAdd(event)}
                    style={{
                      color: (data.isBusy || isTextInFocus) ? 'lightgrey' : '#4aa7ec',
                      width: '74px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }}
                    variant="outlined"
                    size="medium" 
                    color="primary"
                    className={'nodrag'}>
                    Listen
                  </Button>
                  <Button
                    disabled={data.isBusy || isTextInFocus === true} 
                    onClick={handleAddOther}
                    style={{
                      color: (data.isBusy || isTextInFocus) ? 'lightgrey' : '#4aa7ec',
                      width: '72px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }} 
                    className={'nodrag'}
                    variant="outlined"
                    size="medium" 
                    color="primary">
                    Library
                  </Button>
                </Grid>
              }
          </Grid>
        </Card>
      </div>
    </Zoom>
    <CircleIcon
      disabled={true} style={{position: 'absolute', top: '117px', left: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
    <Handle
        type="target"
        position="left"
        id="target1"
        style={{ top: '124px',background: '#555', borderRadius: 0,left: -9, width: '30px', height: '60px', background: 'transparent',
        zIndex: 999,
        border: 'none', }}
      >
      </Handle>


      <CircleIcon
        disabled={true} style={{position: 'absolute', top: 115, right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
      <Handle
        type="source"
        position="right"
        id="exit0"
        style={{ top:  119, background: '#555', borderRadius: 0, left: '218', width: '20px', height: '40px', zIndex: 999, background: 'transparent', border: 'none'}}
      />
     {data?.options?.length > 1 &&

           <React.Fragment>
          <CircleIcon
        disabled={true} style={{position: 'absolute', top: 182, right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
   
      <Handle
          type="source"
          position="right"
          id="exit1"
          style={{ top:  183, background: '#555', borderRadius: 0, left: '218px', width: '20px', height: '40px',zIndex: 999, background: 'transparent', border: 'none'}}
        // style={{ top: heights[0] + heights[1], background: '#555' }}
        />
     </React.Fragment>
     }
     {data?.options?.length > 2 &&


<React.Fragment>
<CircleIcon
  disabled={true} style={{position: 'absolute', top: 233, right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', borderRadius: '50%',disabled:'true', zIndex: 1, border: 'none'}} // Fix the position of the icon over here
/>
<Handle
  type="source"
  position="right"
  id="exit2"
  style={{ top: '238px', background: '#555', borderRadius: 0, left: '218px', width: '20px', height: '40px',zIndex: 999, background: 'transparent', border: 'none'}}
>
</Handle>
</React.Fragment>
     }
    </>
  );
});