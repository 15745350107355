
export const AutoLanguageCourseLanguageLevels =
  {
    "Beginner (A1)":1,
    "Advanced beginner (A2)":2,
    "Intermediate (B1)":3,
    "Upper intermediate (B2)": 4,
    "Advanced (C1)": 5,
    "Native proficiency (C2)": 6
  }
Object.freeze(AutoLanguageCourseLanguageLevels);

export const AutoLanguageCourseLanguageIntents =
  {
    "Vocabulary":1,
    "Grammar":2,
  }
Object.freeze(AutoLanguageCourseLanguageIntents);

export const AutoCourseCreatorLessonType =
  {
    "Vocabulary":1,
    "Roleplay":2,
    "Rules":3,
  }
Object.freeze(AutoCourseCreatorLessonType);

export const AutoCourseCreatorLessonTeachingStage =
  [
    "Introduction",
    "Repetition",
    "Assessment",
  ]
Object.freeze(AutoCourseCreatorLessonTeachingStage);

export const AutoCourseCreatorLessonTeachingStageTypes =
  {
    "Definition": "Definition",
    "Test": "Test",
    "AudioStory": "AudioStory",
    "RuleCard": "RuleCard",
    "Dialogue": "Dialogue",
    "Puzzle": "Puzzle",
    "MultiChoice": "Multichoice", // MultiChoice
    "Instruction": "Instruction",
  }
Object.freeze(AutoCourseCreatorLessonTeachingStageTypes);

export const AutoCourseCreatorStageFriendlyNames =
  {
    "Definition": "Pronunciation",
    "Test": "Test",
    "AudioStory": "Story",
    "RuleCard": "Rule Card",
    "Dialogue": "Dialogue",
    "Puzzle": "Puzzle",
    "Multichoice": "Quiz", // MultiChoice
    "Instruction": "Instruction",
  }
Object.freeze(AutoCourseCreatorStageFriendlyNames);

export const AutoCourseCreatorProcessStages =
  {
    "StartSequence":1,
    "ConfirmTeachingMaterial":2,
    "TransitioningToReviewLessonPlans":3,
    "ReviewLessonPlans":4,
    "Finished":5,
  }
Object.freeze(AutoCourseCreatorProcessStages);

export const AutoCourseCreatorNarrativeType =
    {
        "NotDefined": 0,
        "Fictional": 1,
        "NonFictional": 2,
    }
Object.freeze(AutoCourseCreatorNarrativeType);

export const AutoCourseCreatorStoryTypes =
    {
        "InstructionStory": 0,
        "DialogueStory": 1,
        "SelfContainedRolePlay": 2,
    }
Object.freeze(AutoCourseCreatorStoryTypes);

export const AutoCourseCreatorLanguageProcessStages =
  {
    "StartSequence":1,
    "ExistingContentSelection": 2,
    "UseAIKnowledge": 3,
    "LoadingVocabulary": 4,
    "SelectVocabularyAndGrammar":5,
    "ChooseNarrativeType": 6,
    "ConstructChapters": 7,
    "FocusAreaSelection":8,
    "TransitioningToReviewLessonPlans":9,
    "ReviewLessonPlans":10,
    "Finished":11,
  }
Object.freeze(AutoCourseCreatorLanguageProcessStages);

export const AutoCourseCreatorLanguagePos=
  {
    "All":"All",
    "Verbs":"Verb",
    "Nouns": "Noun",
    "Adjectives": "Adjective",
    "Adverbs": "Adverb",
    "Phrases":"Phrase",
    "Sentence":"Sentence",
  }
Object.freeze(AutoCourseCreatorLanguagePos);

export const AutoCourseCreatorFocusAreas=
  {
    "Reading":"Reading",
    "Writing": "Writing",
    "Listening": "Listening",
    "SpokenProduction": "SpokenProduction",
    "SpokenInteraction":"SpokenInteraction",
  }
Object.freeze(AutoCourseCreatorFocusAreas);

export const AutoCourseCreatorFocusAreas2=
  [
    {"type": "Reading", "friendlyName": "Reading"},
    {"type": "Writing","friendlyName": "Writing"},
    {"type": "Listening","friendlyName": "Listening"},
    {"type": "SpokenProduction","friendlyName": "Spoken Production"},
    {"type": "SpokenInteraction","friendlyName": "Spoken Interaction"},
  ]
Object.freeze(AutoCourseCreatorFocusAreas2);

export const AutoCourseCreatorPointsPerFocusArea = 5
Object.freeze(AutoCourseCreatorPointsPerFocusArea);

export const AutoCourseCreatorTotalPointsFocusAreas= 25
Object.freeze(AutoCourseCreatorTotalPointsFocusAreas);

export const AutoCourseCreatorEstimatedTime =
    [
        {"AmountMin": 0, "AmountMax": 5, "FriendlyAmount": "5 mins", "Feedback": "Too short", "DisplayColor": "#FFDD81"},
        {"AmountMin": 5, "AmountMax": 10, "FriendlyAmount": "10 mins", "Feedback": "A little bit short", "DisplayColor": "#9BD8A0"},
        {"AmountMin": 10, "AmountMax": 15, "FriendlyAmount": "15 mins", "Feedback": "Ideal length", "DisplayColor": "#63C276"},
        {"AmountMin": 15, "AmountMax": 20, "FriendlyAmount": "20 mins", "Feedback": "Ideal length", "DisplayColor": "#63C276"},
        {"AmountMin": 20, "AmountMax": 25, "FriendlyAmount": "25 mins", "Feedback": "Ideal length", "DisplayColor": "#63C276"},
        {"AmountMin": 25, "AmountMax": 30, "FriendlyAmount": "30 mins", "Feedback": "Ideal length", "DisplayColor": "#63C276"},
        {"AmountMin": 30, "AmountMax": 35, "FriendlyAmount": "35 mins", "Feedback": "A little bit too long", "DisplayColor": "#9BD8A0"},
        {"AmountMin": 35, "AmountMax": 40, "FriendlyAmount": "40 mins + minutes", "Feedback": "Too long", "DisplayColor": "#FFDD81"},

    ]
Object.freeze(AutoCourseCreatorEstimatedTime);

export const AutoCourseCreatorStageDefaultInteractions =
    // should be moved to backend when we have time, like most in here...
    [
        {"type": "Definition", "interaction": "Reading", "coversMultpleConcepts": false, "plotType": "Introduction" },
        {"type": "Test", "interaction":  "MultipleChoice", "coversMultpleConcepts": false, "plotType": 'TestYourKnowledge'},
        {"type": "AudioStory", "interaction": "MultipleChoice", "coversMultpleConcepts": true, "plotType": 'TestYourKnowledge'},
        {"type": "RuleCard", "interaction":  "FillInBlanks", "coversMultpleConcepts": true, "plotType": "Welcome"},
        {"type": "Dialogue", "interaction":  "FillInBlanks", "coversMultpleConcepts": true, "plotType": "TestYourKnowledge"},
        {"type": "Puzzle", "interaction":  "Reading", "coversMultpleConcepts": false, "plotType": "TrueOrFalse"},
        {"type": "Multichoice", "interaction":  "MultipleChoice", "coversMultpleConcepts": false, "plotType": 'TestYourKnowledge'}, // MultiChoice
        {"type": "Instruction", "interaction":  "Reading", "coversMultpleConcepts": false, "plotType": "Introduction"},
    ]
Object.freeze(AutoCourseCreatorStageDefaultInteractions);

export const AutoCourseCreatorRuleCardPlotTypes =
  [
    {"plotType": "Welcome", "friendlyText": "A screen welcoming the learner", "coversMultpleConcepts": true},
    {"plotType": "TestYourKnowledge", "friendlyText": "Reading explanations and answering questions", "coversMultpleConcepts": false},
    {"plotType": "Summary", "friendlyText": "A screen summarising what was learnt in the lesson", "coversMultpleConcepts": true},
  ]
Object.freeze(AutoCourseCreatorRuleCardPlotTypes);

export const AutoCourseCreatorInstructionPlotTypes =
    [
        {"plotType": "Introduction", "friendlyText": "Introduce the learner to the lesson's teaching material", "coversMultpleConcepts": false},
    ]
Object.freeze(AutoCourseCreatorInstructionPlotTypes);


export const AutoCourseCreatorPuzzlePlotTypes =
    [
        {"plotType": "StepsSequence", "friendlyText": "What happens next in a sequence", "coversMultpleConcepts": true,  "description": "If your learners are being taught to complete steps in a set order, this is the perfect exercise."},
        {"plotType": "TrueOrFalse", "friendlyText": "True or False questions", "coversMultpleConcepts": true, "description": "Your learners will be asked whether a series of statements are true or false."},
        {"plotType": "TestYourKnowledge", "friendlyText": "Complete statements about the concepts being taught", "coversMultpleConcepts": true, "description": "The learner will have to connect the correct two halves of a statement together to show their understanding."},
    ]
Object.freeze(AutoCourseCreatorPuzzlePlotTypes);

export const AutoCourseCreatorDialoguePlotTypes =
    [
        {"plotType": "Violation", "friendlyText": "A violation occurs. Decide what to do.", "coversMultpleConcepts": true,  "description": "If your learners are being taught to complete steps in a set order, this is the perfect exercise."},
        {"plotType": "Disagreement", "friendlyText": "Deal with a customer complaint.", "coversMultpleConcepts": true, "description": "Your learners will be asked whether a series of statements are true or false."},
        {"plotType": "WhoIsRight", "friendlyText": "Listen to colleagues debating", "coversMultpleConcepts": true, "description": "The learner will have to connect the correct two halves of a statement together to show their understanding."},
        {"plotType": "StepsSequence", "friendlyText": "Decide what should happen next in a sequence", "coversMultpleConcepts": true, "description": "The learner will have to connect the correct two halves of a statement together to show their understanding."},
        {"plotType": "CorrectProcedure", "friendlyText": "A discussion about the correct procedure", "coversMultpleConcepts": true, "description": "The learner will have to connect the correct two halves of a statement together to show their understanding."},
        {"plotType": "CorectCustomerInteraction", "friendlyText": "Help the customer.", "coversMultpleConcepts": true, "description": "The learner will have to connect the correct two halves of a statement together to show their understanding."},
        {"plotType": "TestYourKnowledge", "friendlyText": "General knowledge.", "coversMultpleConcepts": true, "description": "Hello, this is your friendly Capeesh AI bot. No long description has been written yet."},
        ]
Object.freeze(AutoCourseCreatorDialoguePlotTypes);

export const AutoCourseCreatorQuizPlotTypes =
  [
    {"plotType": "TestYourKnowledge", "friendlyText": "Ask a series of questions with multiple choice answers", "coversMultpleConcepts": false},
  ]
Object.freeze(AutoCourseCreatorQuizPlotTypes);

export const AutoCourseCreatorLessonTeachingPlotTypes =
  {
    "Violation": "Violation",
    "Disagreement": "Disagreement",
    "WhoIsRight": "WhoIsRight",
    "StepsSequence": "StepsSequence",
    "CorrectProcedure": "CorrectProcedure",
    "CorectCustomerInteraction": "CorectCustomerInteraction",
    "TrueOrFalse": "TrueOrFalse",
  }
Object.freeze(AutoCourseCreatorLessonTeachingPlotTypes);

export const AutoCourseCreatorLessonTeachingInteractionTypes =
  {
    "Reading": "Reading",
    "Keyboard": "Keyboard",
    "MultipleChoice": "MultipleChoice",
    "SentenceBuilder": "SentenceBuilder",
    "FillInBlanks": "FillInBlanks",
  }
Object.freeze(AutoCourseCreatorLessonTeachingInteractionTypes);


export const AutoCourseCreatorStages =
  {
    "IntroRuleCard":0,
    "DefinitionStage":2,
    "InstructionStage":3,
    "Dialog":4,
    "SummaryNode":6,
    "RuleCardSimple":8,
    "RuleCardWithTest":9,
    "MultipleChoiceQuestion":10,
    "SentenceBuilder":11,
    "TestImageVocab":12,
    "PuzzleVocab":13,
    "VocabIntroRuleCard":14,
    "VocabMultiChoice":15,
    "SummaryListenNode":16,
    "PuzzleStage":18,
    "TrueOrFalse":19,
  }
Object.freeze(AutoCourseCreatorStages);


export const AutoCourseCreatorLessonSubType =
  [
    "Normal",
    "Simulation",
  ]
Object.freeze(AutoCourseCreatorLessonSubType);

export const AutoCourseCreatorLessonToneOfVoiceType =
  [
    {id: "Serious", value: "Serious", name: "Serious", friendlyName: "Serious"},
    {id: "Playful", value: "Playful", name: "Playful", friendlyName: "Playful"},
  ]
Object.freeze(AutoCourseCreatorLessonToneOfVoiceType);


export const AutoCourseCreatorSimulationType =
  [
    {name: "ConversationWithSomeone", friendlyName: "Conversation with someone"},
    {name: "TaskSimulation", friendlyName: "Task simulation"},
    {name: "ChooseYourOwnAdventure", friendlyName: "Choose your own adventure"},
    {name: "Exploratory", friendlyName: "Exploratory with no incorrect answers"},
  ]
Object.freeze(AutoCourseCreatorSimulationType);

export const AutoCourseCreatorNormalType =
  [
    {id: "Traditional", value: "Traditional", name: "Traditional", friendlyName: "Compliance"},
   // {id: "Vocabulary", value: "Vocabulary", name: "Vocabulary", friendlyName: "Vocabulary"},
    {id: "LanguageSupport", value: "LanguageSupport", name: "LanguageSupport", friendlyName: "Language lesson"},
    {id: "Quiz", value: "Quiz", name: "Quiz", friendlyName: "Quiz"},
  //  {id: "Preparation", value: "Preparation", name: "Preparation", friendlyName: "Preparation"},
    {id: "Instruction", value: "Instruction", name: "Instruction", friendlyName: "Instruction"},
  ]
Object.freeze(AutoCourseCreatorNormalType);

export const lengthInMinutes =
  [
    {id: "5", value: "5", name: 10, friendlyName: "5 mins"},
    {id: "10", value: "10", name: 10, friendlyName: "10 mins"},
    {id: "15", value: "15", name: 15, friendlyName: "15 mins"},
    {id: "20", value: "20", name: 20, friendlyName: "20 mins"},
  ]
Object.freeze(lengthInMinutes);

export const AutoCourseCreatorLevelType =
  {
    "Beginner":1,
    "Intermediate":2,
    "Advanced":3,
  }
Object.freeze(AutoCourseCreatorLevelType);



export const AutoCourseCreatorToneOfVoice =
  {
    "NotDefined":0,
    "AbePersonality":1,
    "RonPersonality":2,
  }
Object.freeze(AutoCourseCreatorToneOfVoice);


export const AutoCourseCreateDialogStepType = {
  "AIText" : 0,
  "CourseName": 2,
  "What": 3,
  "Why": 4,
  "SupportingText": 5,
  "ToneOfVoice": 6,
  
  "ConfirmTopics": 10,
  "ConfirmSections": 11,
  "ConfirmLessonPlan": 12,
  "ConfirmLessonSections": 13,
  
  "EndConfirmation": 100,
}
Object.freeze(AutoCourseCreateDialogStepType);


export const AutoCourseCreteEditModeType = {
  "NotDefined": 0,
  "TopicSummary": 1,
  "SectionSummary": 2,
  "LessonPlan": 3,
  "Concepts": 4,
  "SuggestTextSections": 5,
  "CreatingTopic": 6
  
}



export const AutoCourseCreateType = {
  "Complience": 0,
  "Language": 1
}
Object.freeze(AutoCourseCreateType)


export const ChapterTextRowType = {
  "Break": "Break",
  "TextRow":  "TextRow",
  "DialogueRow":  "DialogueRow",
  "Example": "Example",
  "Question": "Question",
}
Object.freeze(ChapterTextRowType)

