import {overallConstants} from "../actions/overall.actions";
import {call, put, takeLatest} from "redux-saga/effects";
import {overallService} from "../services/overall.service";

export function* getGraphDataDailyUsageAggregated(data) {
  try {
    const response = yield call(overallService.getGraphDataDailyUsageAggregated, data.payload);
    yield put({type: overallConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED.SUCCESS, data: response.data})
  } catch (e) {
    yield put({type: overallConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED.FAILURE, message: e.message});
  }
}

export function* getGraphDataDailyUserOnboarding(data) {
  try {
    const response = yield call(overallService.getGraphDataDailyUserOnboarding, data.payload);
    yield put({type: overallConstants.GET_GRAPH_DATA_DAILY_USER_ONBOARDING.SUCCESS, data: response.data})
  } catch (e) {
    yield put({type: overallConstants.GET_GRAPH_DATA_DAILY_USER_ONBOARDING.FAILURE, message: e.message});
  }
}

export function* getOverallSearchResult(data) {
  try {
    const response = yield call(overallService.getOverallSearchResult, data.payload);
    yield put({type: overallConstants.GET_OVERALL_SEARCH_RESULT.SUCCESS, data: response.data})
  } catch (e) {
    yield put({type: overallConstants.GET_OVERALL_SEARCH_RESULT.FAILURE, message: e.message});
  }
}


export function* overallSagas() {
  yield takeLatest(overallConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED.REQUEST, getGraphDataDailyUsageAggregated);
  yield takeLatest(overallConstants.GET_GRAPH_DATA_DAILY_USER_ONBOARDING.REQUEST, getGraphDataDailyUserOnboarding);
  yield takeLatest(overallConstants.GET_OVERALL_SEARCH_RESULT.REQUEST, getOverallSearchResult);
}


