import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import IconButton from '@material-ui/core/IconButton';
import ListenNoText from "../../../../assets/images/698wListen.png";
import AddIcon from '@material-ui/icons/AddBoxOutlined';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
} from '@material-ui/core';

export default memo(({ data }, areEqual) => {
  var sectionStyle = {
    position: 'absolute',
    top: '18px',
    left: '5px',
    height: '79px',
    width: '90px',
    background: "url(" + data.imageUrl + ") no-repeat bottom / 100% auto",
    // backgroundSize: '100% auto'
  };
  return (
    <div> 
      <div style={sectionStyle } /> 
      {data.subtitle !== "" && 
          <Grid container 
            style={{ position: 'absolute', 
            color: '#8E8E93', 
            border: 'none',
            top: '78px',
            left: '5.5px', 
            minHeight: '13px',
            fontWeight: 900,
            width: '88px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(10, 10, 10, 0.6)',
            backdropFilter: 'blur(37px)',
          
          }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography 
            variant='body1'  
            style={{                            
            fontSize: 4,
            fontFamily: 'Lato',
            width: '87px',
            textAlign:'center',
            color:'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}>
              {data.subtitle}
            </Typography>
          </Grid>
        </Grid>
      }
      <img
        src={ListenNoText}
        style={{position: 'relative', width: '100px', height: 'auto'}}
      />
      <Handle
        type="target"
        position="left"
        id="target1"
        style={{ top: '98.5px', background: '#555' }}
      />
      <Handle
        type="source"
        position="right"
        id="exit0"
        style={{ top: '98.5px', background: '#555' }}
      />
  </div>
  );
});