import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {makeStyles, Typography} from "@material-ui/core";
import shortStoryImage from "assets/images/shortStoryIllustration.png";
import storyImage from "assets/images/narrativeIllustration.png";
import {AutoCourseCreatorNarrativeType} from "../../../../Logic/AutoCourseCreationConstants";
import ComingSoonSvg from "../Images/ComingSoonSvg";

const useStyles = makeStyles(theme => ({
    centerElements: 
        {
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            alignItems: "center",
            width: '100vv'
        },
    mainText:
        {
            width: '100%',
            textAlign: 'center',
            color: 'white', 
            fontSize: 32,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginBottom: 16
        },
    selectionBoxes:
        {
            display: "flex",
            width: 860,
            height: 290
        },
    selectionBox:
        {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 32,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 32,
            width: 390,
            height: 226,
            border: '2px rgba(255, 255, 255, 0.32) solid',
            background: 'rgba(255, 255, 255, 0.12)',
            borderRadius: 8,
            cursor: "pointer",
            '&:hover': {
                background: '2px rgba(255, 255, 255, 0.32) solid',
                border: '2px white solid'
            },
        },
    selectionBoxDisabled:
        {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 32,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 32,
            zIndex: 0,
            width: 390,
            height: 226,
            border: '2px rgba(255, 255, 255, 0.13) solid',
            background: 'rgba(255, 255, 255, 0.12)',
            borderRadius: 8,
            position: "relative",
            '&::after': {
                content: '""',
                position: 'absolute',
                zIndex: 1,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                borderRadius: 8
            },
        },
    images: 
        {
            width: 96,
            height: 104,
            marginBottom: 16
        },
    boxMainText:
        {
            width: '100%',
            textAlign: 'center', 
            color: 'white',
            fontSize: 24, 
            fontFamily: 'Rubik', 
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    boxSubText:
        {
            color: 'white', 
            fontSize: 14,
            fontFamily: 'Rubik',
            fontWeight: '400', 
            wordWrap: 'break-word',
            textAlign: 'center'
        },
    boxSubTextBold:
        {
            color: 'white',
            fontSize: 14, 
            fontFamily: 'Rubik',
            fontWeight: '600',
            wordWrap: 'break-word',
            textAlign: 'center'
        },
    selectionBoxSelected:
        {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 32,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 32,
            width: 390,
            height: 226,
            background: 'rgba(255, 255, 255, 0.12)',
            border: '2px white solid',
            borderRadius: 8,
            cursor: "pointer",
        }
}));

const AutoLanguageCourseNarrativeTypeScreen = (props) => {
    
    // props.selectedNarrativeOption(narrativeOptionEnum)
    
    const {} = props;
    const classes = useStyles();
    
    const handleChooseNarrative = (option) => {
        props.selectedNarrativeOption(option);
    }

    useEffect(() => {
        handleChooseNarrative(AutoCourseCreatorNarrativeType.Fictional)
    }, []);
    
    return (
        <div>
            <div className={classes.centerElements}>
                <Typography className={classes.mainText}>
                    How should the lesson be structured?
                </Typography>
                <div className={classes.selectionBoxes}>
                    <div className={props.autoCourseCreation.authorInputMetadata?.narrativeType === AutoCourseCreatorNarrativeType.Fictional ? classes.selectionBoxSelected : classes.selectionBox}
                         onClick={() => handleChooseNarrative(AutoCourseCreatorNarrativeType.Fictional)}>
                        <img src={storyImage} className={classes.images}/>
                        <Typography className={classes.boxMainText}>
                            Narrative
                        </Typography>
                        <Typography className={classes.boxSubText}>
                            I want the course to be 
                            <span className={classes.boxSubTextBold}> centred around a narrative</span>
                            , to make it
                            <span className={classes.boxSubTextBold}> more engaging </span>
                            for the students.
                            The narrative will be the thread for the language course
                            teaching the selected vocabulary items.
                        </Typography>
                    </div>
                    <div style={{ marginRight: 16 }}/>
                    <div className={classes.selectionBoxDisabled}>
                        <span style={{ position: "static", zIndex: 2 }}><ComingSoonSvg/></span>
                        <img src={shortStoryImage} className={classes.images}/>
                        <Typography className={classes.boxMainText}>
                            Short stories
                        </Typography>
                        <Typography className={classes.boxSubText}>
                            I want the course to be structured a 
                            <span className={classes.boxSubTextBold}> separate short stories. </span>
                            The short stories can be fictional stories or realistic 
                            <span className={classes.boxSubTextBold}> workplace scenarios.</span>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

AutoLanguageCourseNarrativeTypeScreen.propTypes = {};

const mapStateToProps = (state) => {
    return {
        autoCourseCreation: state.autoCourseCreation
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoLanguageCourseNarrativeTypeScreen)