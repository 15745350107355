import React, {useEffect, useState} from "react";
import {
  Typography,
  makeStyles, MenuItem, Button,
} from '@material-ui/core';
import {connect} from 'react-redux';
import Card from "@material-ui/core/Card";
import {Add, Delete} from "@material-ui/icons";
import Zoom from '@mui/material/Zoom';

import {
  getFlagAssetForLanguageId
} from "../../../../Logic/FlagUnicodeLogic";
import Grid from "@material-ui/core/Grid";
import {makeGetRequest} from "../../../../_state/helpers/api";
import {configWithAuth} from "../../../../_state/helpers";
import {convertDataURIToBinary} from "../../../../_state/helpers/base64Logic";

import Checkbox from "@material-ui/core/Checkbox";
import {FormattedMessage} from "react-intl";

import DefaultCourseGroupImage from "../../../../assets/images/courseGroup/FallbackCourseGroupImage.jpeg"

const useStyles = makeStyles(theme => ({
  cardContent: {
    flex: 'auto',
  },
  verticalContainer: {
    flex:1,
  },
  courseGroupCard: {
    display: 'flex',
    paddingTop: '20px',
    paddingBottom:'20px',
    marginBottom:'60px',
    // padding: '20px',
    transition: 'all .25s ease',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
    },
    height: "332px",
    width: '239px'
  },
  courseGroupCardAction: {
    right: '15px',
    height: 'fit-content',
    margin: 'auto',
  },
  button: {
    backgroundColor: 'primary',
    fontColor: 'white',
    color: 'white',
    borderRadius: 4,
    marginRight: '10px',
    textTransform: 'none'
  },
  
  imageContainer: {
    marginTop: '-20px',
    position:'relative',
    overflow: 'hidden',
    height: '124px',
  },
  
  
}));

const PreviewCourseGroupCard = (props) => {
  const {theme, courseGroup, index, slideDirection, handleRemoveCourseGroup, user, organization, metadata} = props;
  const classes = useStyles();

  const [numberOfCourses, setNumberOfCourses] = useState(0);

  const [description, setDescription] = useState("");
  const [topicNames, setTopicNames] = useState("");
  const [topicNameAsString, setTopicNameAsString] = useState("");

  const [imageUrl, setImageUrl] = useState("");
  const [imageBlobUrl, setImageBlobUrl] = useState("");

  const [supportedLanguagesString, setSupportedLanguagesString] = useState("");

  useEffect(() => {
    if (imageUrl && !imageBlobUrl) {
      let imageReferenceId = imageUrl.replace('image/file/', '');
      makeGetRequest('admin/organization/' + organization.selectedOrganization + '/image/file/reference/' + imageReferenceId + '/base64', configWithAuth())
        .then(result => {
          let type = null
          const typeImage = result.data.split(';')[0].split(':')[1];
          switch (typeImage) {
            case "image/jpeg":
              type = 'jpeg'
              break;
            case "image/png":
              type = 'png'
              break;
            default:
              type = 'unknown'
              break;
          }
          var binary = convertDataURIToBinary(result.data);
          var blob = new Blob([binary], {type: type});
          var blobUrl = URL.createObjectURL(blob);
          setImageBlobUrl(blobUrl);

        })
        .catch(error => {
          console.log("error", error);
        })
    }
  }, [imageUrl])

  useEffect(() => {
    recalculateCourseGroupData(props.courseGroup)
  }, [])

  useEffect(() => {
    recalculateCourseGroupData(props.courseGroup)
  }, [props.courseGroup])
  
  const recalculateCourseGroupData = (courseGroup) => {
    if (!courseGroup) {
      setDescription("");
      setImageUrl("");
      setImageBlobUrl("");
      return;
    }
    
    if (courseGroup.description) {
      setDescription(courseGroup.description);
    }
    else if (courseGroup.l1Description) {
      setDescription(courseGroup.description);
    }
    let tempTopicNames = []

    if (courseGroup.courseGroupSubPartDtos) {

      for(let i = 0; i < courseGroup.courseGroupSubPartDtos.length; i++) {
        let subPartDto = courseGroup.courseGroupSubPartDtos[i];
        if (subPartDto.courseGroupSubPartToTopicMapperDtos?.length > 0) {
          for(let j = 0; j < subPartDto.courseGroupSubPartToTopicMapperDtos.length; j++) {
            tempTopicNames.push(subPartDto.courseGroupSubPartToTopicMapperDtos[j].topicName);
          }
        }
      }
    }

    if (tempTopicNames.length > 0) {
      let tempLessonNameAsString = tempTopicNames.map(x => x).join(", ");
      if (tempLessonNameAsString.length > 120) {

      }

      setTopicNameAsString(tempLessonNameAsString);
      setTopicNames(tempTopicNames);
    }

    if (courseGroup.imageUrl) {
      setImageUrl(courseGroup.imageUrl);
    } else {
      setImageUrl("");
      setImageBlobUrl("");
    }

    if (courseGroup?.supportedLanguages) {
      var supportedLanguageNames = []
      courseGroup.supportedLanguages.forEach(supportedLanguage => {
        var languageDto = metadata.metadata.languages.find(x => x.id === supportedLanguage.l1Id);
        if (languageDto) {
          supportedLanguageNames.push(languageDto.friendlyName);
        }
      })

      setSupportedLanguagesString(supportedLanguageNames.join(", "));
    }


  }
  

  const handleCardClick = () => {
    if (props.openCourseGroup) {
      props.openCourseGroup(courseGroup.id);
    }
  }


  return (
    <Zoom in={true}>
      <div >
        <Card key={courseGroup?.id} className={classes.courseGroupCard} onClick={() => handleCardClick()}>
          <div className={classes.cardContent}>
            <Grid container
                  style={{
                    flex:1,
                    flexDirection:"row"
                  }}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.imageContainer}>
                {
                  imageUrl && imageBlobUrl &&
                  <img src={imageBlobUrl} 
                      style={{  
                        maxWidth:'100%',
                        maxHeight:'150%',
                        position:'absolute',
                        top:'50%',
                        left:'50%',
                        transform: 'translateY(-50%) translateX(-50%)'
                      }}/>
                }
                {
                  imageUrl && !imageBlobUrl &&
                  <Typography style={{ marginTop: '130px'}}>
                  </Typography>
                }
                {
                  !imageUrl &&
                  <img 
                    src={DefaultCourseGroupImage}
                    style={{
                      maxWidth:'100%',
                      maxHeight:'150%',
                      position:'absolute',
                      top:'50%',
                      left:'50%',
                      transform: 'translateY(-50%) translateX(-50%)'
                    }}/>
                }
              <div style={{width: '32px'}}>
                <img style={{borderRadius: '50%', position: 'absolute', top: 10, bottom:0, left:'83%', right: 0}} 
                      width="32" src={getFlagAssetForLanguageId(courseGroup?.l2Id)}/> 
              </div>
                
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{

              }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography style={{fontSize: '20px', lineHeight: '18px',  fontWeight:700, fontFamily: 'Lato', marginTop: '15px', marginBottom: '5px', color: 'black', marginLeft: '12px', marginRight: '12px'}}>
                          {courseGroup?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*<Grid item xs={12}>*/}
                  {/*  <Typography style={{*/}
                  {/*    fontSize:'15px',*/}
                  {/*    // fontWeight: 'bold'*/}
                  {/*  }}>*/}
                  {/*    <FormattedMessage id="dashboard.classroom.coursegroup.card.teaches" defaultMessage="Teaches"/> {metadata.metadata?.languages?.find(x => x.id === courseGroup?.l2Id)?.fullName}*/}
                  {/*  </Typography>*/}
                  {/*</Grid>*/}

                  {
                    description &&
                    <Grid item xs={12} style={{marginTop: '3px'}}>
                      <Typography variant={"body1"} style={{fontFamily: 'Lato', fontSize: '16px', color: 'black', marginLeft: '15px', marginRight: '15px'}} color={"black"}>
                        <b>Description:</b> {description}
                      </Typography>
                    </Grid>
                  }
                  {
                    topicNameAsString &&
                    <Grid item xs={12 } style={{marginTop: '3px'}}>
                      <Typography variant={"body1"}>
                        <b><FormattedMessage id="dashboard.classroom.coursegroup.card.content" defaultMessage="Content"/>:</b> {topicNameAsString}
                      </Typography>
                    </Grid>
                  }

                  {
                    courseGroup?.supportedLanguages?.length > 0 &&
                    <Grid item xs={12} style={{marginTop: '3px'}}>
                      <Typography variant={"body1"}>
                        <b><FormattedMessage id="dashboard.classroom.coursegroup.card.supportedlanguages" defaultMessage="Supported languages"/>: </b>
                        {
                          supportedLanguagesString
                        }
                      </Typography>
                    </Grid>

                  }
                </Grid>
              </Grid>
            </Grid>
          </div>

        </Card>
      </div>
    </Zoom>
  );
};

PreviewCourseGroupCard.propTypes = {};

const mapStateToProps = (state) => {
  const {user, organization, metadata} = state;
  return {
    metadata,
    user,
    organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCourseGroupCard)