import {Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {TextField} from "@mui/material";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";


const useStyles = makeStyles(theme => ({
  topicTextField: {
    minWidth: "200px",
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '20px',
  },
 
}));

const CreateNewTopicComponent = (props) => {

  // expected props
  // props.onCreateCourseGroup (name, description, l2Id, l1Id)

  const classes = useStyles();

  const [topicName, setTopicName] = useState('');
  
  return (


    <Dialog
      open={props.open}
      onClose={() => props.onCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Create new topic"}
      </DialogTitle>
      <DialogContent>

        <Grid container spacing={12}>

          <DialogContentText id="alert-dialog-description">
           
          </DialogContentText>
          <Grid item xs={12}>
            <Typography style={{width:"200px", textAlign: "left", marginLeft:'1px'}}>
              Topic Name
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              defaultValue={topicName}
              onChange={(event) => setTopicName(event.target.value)}
              label={"Type the topic name"}
              style={{borderRadius: '10px', paddingRight: '5px'}}
              InputProps={{ disableUnderline: true, shrink: "false" }}
              className={classes.topicTextField}
              variant="filled"
            />
          </Grid>
        </Grid>


      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onCancel()} autoFocus> Cancel </Button>
        <Button
          className={classes.leftSideButtons}
          variant={"contained"}
          color={"primary"}
          disabled={(topicName === "")}
          onClick={() => props.onCreateTopic(topicName)}
        >
          <FormattedMessage id="creator.create.course.group.button.title" defaultMessage="Create"/>
        </Button>
      </DialogActions>
    </Dialog>


  );
};


function mapStateToProps(state) {
  const {metadata} = state;
  return {
    metadata
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTopicComponent)