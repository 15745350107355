import React, {useEffect, useState} from "react";
import {
  Typography,
  makeStyles, MenuItem, Button,
} from '@material-ui/core';
import {connect} from 'react-redux';
import Card from "@material-ui/core/Card";
import {Add, Delete} from "@material-ui/icons";

import {
  getFlagAssetForLanguageId
} from "../../../Logic/FlagUnicodeLogic";
import Grid from "@material-ui/core/Grid";
import {makeGetRequest} from "../../../_state/helpers/api";
import {configWithAuth} from "../../../_state/helpers";
import {convertDataURIToBinary} from "../../../_state/helpers/base64Logic";

import Checkbox from "@material-ui/core/Checkbox";
import {FormattedMessage} from "react-intl";
import DefaultCourseGroupImage from "../../../assets/images/courseGroup/FallbackCourseGroupImage.jpeg";

const useStyles = makeStyles(theme => ({
  cardContent: {
    flex: 'auto',
  },
  verticalContainer: {
    flex:1, 
  },
  courseGroupCard: {
    display: 'flex',
    paddingTop: '20px',
    paddingBottom:'20px',
    // padding: '20px',
    transition: 'all .25s ease',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
    },
  },
  courseGroupCardAction: {
    right: '15px',
    height: 'fit-content',
    margin: 'auto',
  },
  button: {
    backgroundColor: 'primary',
    fontColor: 'white',
    color: 'white',
    borderRadius: 4,
    marginRight: '10px',
    textTransform: 'none'
  },
}));

const CourseGroupCard = (props) => {
  const {theme, courseGroup, index, slideDirection, handleRemoveCourseGroup, user, organization, metadata} = props;
  const classes = useStyles();
  
  const [numberOfCourses, setNumberOfCourses] = useState(0);
  
  const [description, setDescription] = useState("");
  const [topicNames, setTopicNames] = useState("");
  const [topicNameAsString, setTopicNameAsString] = useState("");
  
  const [imageUrl, setImageUrl] = useState("");
  const [imageBlobUrl, setImageBlobUrl] = useState("");

  const [supportedLanguagesString, setSupportedLanguagesString] = useState("");
  
  useEffect(() => {
    if (imageUrl && !imageBlobUrl) {
      let imageReferenceId = imageUrl.replace('image/file/', '');
      makeGetRequest('admin/organization/' + organization.selectedOrganization + '/image/file/reference/' + imageReferenceId + '/base64', configWithAuth())
        .then(result => {
          let type = null
          const typeImage = result.data.split(';')[0].split(':')[1];
          switch (typeImage) {
            case "image/jpeg":
              type = 'jpeg'
              break;
            case "image/png":
              type = 'png'
              break;
            default:
              type = 'unknown'
              break;
          }
          var binary = convertDataURIToBinary(result.data);
          var blob = new Blob([binary], {type: type});
          var blobUrl = URL.createObjectURL(blob);
          setImageBlobUrl(blobUrl);
          
        })
        .catch(error => {
          console.log("error", error);
        })
    }
  }, [imageUrl])
  
  useEffect(() => {
    const {courseGroup} = props;
    
    if (!courseGroup) {
      return;
    }
    
    if (courseGroup.description) {
      setDescription(courseGroup.description);
    }
    else if (courseGroup.l1Description) {
      setDescription(courseGroup.description);
    }
    let tempTopicNames = []
    
    if (courseGroup.courseGroupSubPartDtos) {

      for(let i = 0; i < courseGroup.courseGroupSubPartDtos.length; i++) {
        let subPartDto = courseGroup.courseGroupSubPartDtos[i];
        if (subPartDto.courseGroupSubPartToTopicMapperDtos?.length > 0) {
          for(let j = 0; j < subPartDto.courseGroupSubPartToTopicMapperDtos.length; j++) {
            tempTopicNames.push(subPartDto.courseGroupSubPartToTopicMapperDtos[j].topicName);
          }
        }
      }
    }
    
    if (tempTopicNames.length > 0) {
      
      let tempLessonNameAsString = tempTopicNames.map(x => x).join(", ");
      if (tempLessonNameAsString.length > 120) {
        
      }
      
      setTopicNameAsString(tempLessonNameAsString);
      setTopicNames(tempTopicNames);
    }

    if (courseGroup.imageUrl) {
      setImageUrl(courseGroup.imageUrl);
    }
    
    if (courseGroup?.supportedLanguages) {
      var supportedLanguageNames = []
      courseGroup.supportedLanguages.forEach(supportedLanguage => {
        var languageDto = metadata.metadata.languages.find(x => x.id === supportedLanguage.l1Id);
        if (languageDto) {
          supportedLanguageNames.push(languageDto.friendlyName);
        }
      })
      
      setSupportedLanguagesString(supportedLanguageNames.join(", "));
    }
    
    
  }, [])
  
  const handleCardClick = () => {
    if (props.handleCheckMarkCourseGroup) {
      props.handleCheckMarkCourseGroup(courseGroup.id);
    }
    
    if (props.onCourseGroupOpen) {
      props.onCourseGroupOpen(courseGroup.id)
    }
  }
  
  const handleAddCourseGroupButtonClicked = (event) => {
    event.stopPropagation()
    props.handleAddCourseGroup(courseGroup?.id)
  }
  
  const handleOpenCourseGroupClicked = (event) => {
    event.stopPropagation();
    props.openCourseGroup(courseGroup?.id)
  }
  
  const handleRemoveButtonClicked = (event) => {
    event.stopPropagation()
    props.handleRemoveCourseGroup(courseGroup?.id);
  }
  
  return (
    <div>
      <Card key={courseGroup?.id} className={classes.courseGroupCard} onClick={() => handleCardClick()}>
        <div className={classes.cardContent}>
          <Grid container 
                style={{
                  flex:1,
                  flexDirection:"row"
                }}> 
              <Grid item lg={2} md={3} sm={12} xs={12} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                {
                  imageUrl && imageBlobUrl &&
                  <img src={imageBlobUrl} style={{ maxWidth: '150px', padding:'1px'}}/>
                }

                {
                  !imageUrl &&
                  <img src={DefaultCourseGroupImage} style={{ maxWidth: '150px', padding:'1px'}}/>
                }
                
              </Grid>
              <Grid item lg={8} md={7} sm={12} xs={12} style={{
                
              }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography variant={"h4"} color={"primary"} style={{marginBottom: '5px'}}>
                          {courseGroup?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} style={{
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}>
                        <img width="32" height="24" src={getFlagAssetForLanguageId(courseGroup?.l2Id)}/>
                      </Grid>  
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{
                      fontSize:'20px',
                      fontWeight: 'bold'
                    }}>
                      <FormattedMessage id="dashboard.classroom.coursegroup.card.teaches" defaultMessage="Teaches"/> {metadata.metadata?.languages?.find(x => x.id === courseGroup?.l2Id)?.fullName}
                    </Typography>
                  </Grid>
                  
                  {
                    description &&
                    <Grid item xs={12} style={{marginTop: '3px'}}>
                      <Typography variant={"body1"} color={"primary"}>
                        {description}
                      </Typography>
                    </Grid>
                  }
                  {
                    topicNameAsString &&
                    <Grid item xs={12 } style={{marginTop: '3px'}}>
                      <Typography variant={"body1"}>
                        <b><FormattedMessage id="dashboard.classroom.coursegroup.card.content" defaultMessage="Content"/>:</b> {topicNameAsString}
                      </Typography>
                    </Grid>
                  }
                  
                  {
                    courseGroup?.supportedLanguages?.length > 0 &&
                    <Grid item xs={12} style={{marginTop: '3px'}}>
                      <Typography variant={"body1"}>
                        <b><FormattedMessage id="dashboard.classroom.coursegroup.card.supportedlanguages" defaultMessage="Supported languages"/>: </b>
                        {
                          supportedLanguagesString
                        }
                      </Typography>
                    </Grid>

                  }
                </Grid>
              </Grid>
            {
              props.handleRemoveCourseGroup &&
              <Grid item md={2} sm={12} xs={12} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>

                <Button onClick={(event) => handleRemoveButtonClicked(event)}
                        style={{
                          marginLeft:"10px",
                          height:"50px",
                          minWidth:"90px",
                          background: 'gray',
                          fontColor: 'black',
                        }}
                        className={classes.button} variant="contained" size="medium"
                >
                  <Delete style={{marginRight:"5px"}}/>
                  <FormattedMessage id="globalwords.remove" defaultMessage="Remove"/>
                </Button>
              </Grid>
            }
            
            {
              props.handleAddCourseGroup &&
                <Grid item md={2} sm={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Button onClick={(event) => handleAddCourseGroupButtonClicked(event)}
                          style={{
                            
                            height:"50px",
                            background: '#228B22'
                          }}
                          className={classes.button} variant="contained" size="medium"
                  >
                    <Add style={{marginRight:"5px"}}/>
                    <FormattedMessage id="globalwords.add" defaultMessage="Add"/>
                  </Button>    
                </Grid>
            }
            {
              props.openCourseGroup &&
              <Grid item md={2} sm={12} xs={12} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              >
                <Button onClick={(event) => handleOpenCourseGroupClicked(event)}
                        style={{

                          height:"50px",
                          background: '#228B22'
                        }}
                        className={classes.button} variant="contained" size="medium"
                >
                  <Add style={{marginRight:"5px"}}/>
                  <FormattedMessage id="globalwords.open" defaultMessage="Open"/>
                </Button>
              </Grid>
            }
            
            {
              props.handleCheckMarkCourseGroup &&
              <Grid item md={2} sm={12} xs={12} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              >
                <Checkbox
                  checked={props.checked}
                  tabIndex={-1}
                  disableRipple
                  onClick={() => props.handleCheckMarkCourseGroup(courseGroup.id)}
                />
              </Grid>
            }
            
            
            
          </Grid>
        </div>
        
      </Card>
    </div>
  );
};

CourseGroupCard.propTypes = {};

const mapStateToProps = (state) => {
  const {user, organization, metadata} = state;
  return {
    metadata,
    user,
    organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseGroupCard)