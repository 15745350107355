import {authConstants, lessonConstants} from '../actions';
import {ToggleModeViewTypes} from "../../Logic/ScenarioConstants";

const initialState = { 
     organizationLessons: [],
     selectedLesson: [],
     isUpdating: false,
     saving: false,
     topicRelationshipTypes: [],
     selectedImage: null,
     selectedImageUrl: null,
     selectedAudio: null,
     selectedAudioUrl: null,
     selectedScenario: null,
     imageReferences: [],
     audioReferences: [],
     lessonAudioReferences: [],
     isDownloadingAudio: false,
     scenarioImages: [],
     completedScenarioDownload: false,
     reloadingNodes: false,
     savingNode: false,
     updatedPosition: false,
     isBusy: false,
     geniusCreationProgress: -1,
     lessonBuilderViewMode: ToggleModeViewTypes.NormalEdit,
     canPublishCurrentScenario: false,
     latestVersionIsPublished: false,
     speechRecognitionL2: 1,
};

export function lesson(state = initialState, action) {
    switch (action.type) {
        case lessonConstants.GET_ORGANIZATION_LESSONS.REQUEST:
            return {
                ...state,
                organizationLessons: null 
            };
        case lessonConstants.GET_ORGANIZATION_LESSONS.SUCCESS:
            return {
                ...state,
                organizationLessons: action.data
            };
        case lessonConstants.GET_ORGANIZATION_LESSONS.FAILURE:
            return {
                ...state,
            };
        case lessonConstants.CREATE_CUSTOM_LESSON.REQUEST:
            return {
                ...state, 
            };
        case lessonConstants.CREATE_CUSTOM_LESSON.SUCCESS:
            return {
                ...state,
                selectedLesson: action.data
            };
        case lessonConstants.CREATE_CUSTOM_LESSON.FAILURE:
            return {
                ...state,
            };
        case lessonConstants.ADD_LESSON_QUESTION.REQUEST:
            return {
                ...state, 
            };
        case lessonConstants.ADD_LESSON_QUESTION.SUCCESS:
            return {
                ...state,
                selectedLesson: action.data,
            };
        case lessonConstants.ADD_LESSON_QUESTION.FAILURE:
            return {
                ...state,
                saving: false
            };
        case lessonConstants.GET_CUSTOM_LESSON.REQUEST:
            return {
                ...state, 
                selectedLesson: null,
                isUpdating: true
            };
        case lessonConstants.GET_CUSTOM_LESSON.SUCCESS:
            return {
                ...state,
                selectedLesson: action.data,
                isUpdating: false
            };
        case lessonConstants.GET_CUSTOM_LESSON.FAILURE:
            return {
                ...state,
            };
        case lessonConstants.EDIT_CUSTOM_LESSON_DETAILS.REQUEST:
            return {
                ...state, 
                isUpdating: true,
                saving: true
                //selectedLesson: null,
            };
        case lessonConstants.EDIT_CUSTOM_LESSON_DETAILS.SUCCESS:
            return {
                ...state,
                selectedLesson: action.data,
                isUpdating: false,
                saving: false
            };
        case lessonConstants.EDIT_CUSTOM_LESSON_DETAILS.FAILURE:
            return {
                ...state,
                saving: false
            };
        case lessonConstants.EDIT_QUESTION.REQUEST:
            return {
                ...state, 
                isUpdating: true,
                saving: true
                //selectedLesson: null,
            };
        case lessonConstants.EDIT_QUESTION.SUCCESS:
            return {
                ...state,
                saving: false
                
            };
        case lessonConstants.EDIT_QUESTION.FAILURE:
            return {
                ...state,
                saving: false
            };
        case lessonConstants.GENERAL_RESET.REQUEST:
        return initialState;
        case lessonConstants.GENERAL_RESET.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.GENERAL_RESET.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.DELETE_CUSTOM_LESSON.REQUEST:
            return {
                ...state,
                //selectedLesson: null
            };
        case lessonConstants.DELETE_CUSTOM_LESSON.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.DELETE_CUSTOM_LESSON.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.DELETE_QUESTION.REQUEST:
            return {
                ...state,
                //selectedLesson: null
            };
        case lessonConstants.DELETE_QUESTION.SUCCESS:
            return {
                ...state, 
                selectedLesson: action.data,
            };
        case lessonConstants.DELETE_QUESTION.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.ASSIGN_LESSON_TO_TOPIC.REQUEST:
            return {
                ...state,
                //selectedLesson: null
            };
        case lessonConstants.ASSIGN_LESSON_TO_TOPIC.SUCCESS:
            return {
                ...state, 
                selectedLesson: action.data,
            };
        case lessonConstants.ASSIGN_LESSON_TO_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.GET_CUSTOM_LESSON_RELATIONSHIPS.REQUEST:
            return {
                ...state,
                //selectedLesson: null
            };
        case lessonConstants.GET_CUSTOM_LESSON_RELATIONSHIPS.SUCCESS:
            return {
                ...state, 
                relationshipTypes: action.data,
            };
        case lessonConstants.GET_CUSTOM_LESSON_RELATIONSHIPS.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.EXPORT_LESSON.REQUEST:
            return {
                ...state,
                //selectedLesson: null
            };
        case lessonConstants.EXPORT_LESSON.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.EXPORT_LESSON.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.IMPORT_LESSON.REQUEST:
            return {
                ...state,
                //selectedLesson: null
            };
        case lessonConstants.IMPORT_LESSON.SUCCESS:
            return {
                ...state, 
                selectedLesson: action.data
            };
        case lessonConstants.IMPORT_LESSON.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.IMPORT_SCENARIO.REQUEST:
            return {
                ...state,
                //selectedLesson: null
            };
        case lessonConstants.IMPORT_SCENARIO.SUCCESS:
            return {
                ...state,
                selectedLesson: action.data
            };
        case lessonConstants.IMPORT_SCENARIO.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.IMPORT_SCENARIO_LINEAR.REQUEST:
            return {
                ...state,
                //selectedLesson: null
            };
        case lessonConstants.IMPORT_SCENARIO_LINEAR.SUCCESS:
            return {
                ...state,
                selectedLesson: action.data
            };
        case lessonConstants.IMPORT_SCENARIO_LINEAR.FAILURE:
            return {
                ...state,
                error: action.message,
            };
            
        case lessonConstants.DELETE_FROM_COURSE.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.DELETE_FROM_COURSE.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.DELETE_FROM_COURSE.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.ADD_IMAGE.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.ADD_IMAGE.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.ADD_IMAGE.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.GET_IMAGE_REFERENCES.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.GET_IMAGE_REFERENCES.SUCCESS:
            return {
                ...state, 
                imageReferences: action.data
            };
        case lessonConstants.GET_IMAGE_REFERENCES.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.GET_IMAGE.REQUEST:
            return {
                ...state,
                
            };
        case lessonConstants.GET_IMAGE.SUCCESS:
            const typeImage = action.data.selectedImage.split(';')[0].split(':')[1];
            return {
                ...state, 
                selectedImage: action.data.selectedImage,
                selectedImageType: typeImage
            };
        case lessonConstants.GET_IMAGE.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.ADD_AUDIO.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.ADD_AUDIO.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.ADD_AUDIO.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.GET_AUDIO_REFERENCES.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.GET_AUDIO_REFERENCES.SUCCESS:
            return {
                ...state, 
                audioReferences: action.data
            };
        case lessonConstants.GET_AUDIO_REFERENCES.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case lessonConstants.GET_LESSON_AUDIO_REFERENCES.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.GET_LESSON_AUDIO_REFERENCES.SUCCESS:
            return {
                ...state,
                lessonAudioReferences: action.data
            };
        case lessonConstants.GET_LESSON_AUDIO_REFERENCES.FAILURE:
            return {
                ...state,
                error: action.message,
            };
            
        case lessonConstants.GET_AUDIO.REQUEST:
            return {
                ...state,
                isDownloadingAudio: true
            };
        case lessonConstants.GET_AUDIO.SUCCESS:
            const typeAudio = action.data.selectedAudio.split(';')[0].split(':')[1];
            return {
                ...state, 
                selectedAudio: action.data.selectedAudio,
                selectedAudioType: typeAudio,
                isDownloadingAudio: false
            };
        case lessonConstants.GET_AUDIO.FAILURE:
            return {
                ...state,
                isDownloadingAudio: false,
                error: action.message,
            };
        case lessonConstants.GET_SCENARIO.REQUEST:
            return {
                ...state,
                completedScenarioDownload: false,
            };
        case lessonConstants.GET_SCENARIO.SUCCESS:
            return {
                ...state, 
                completedScenarioDownload: true,
                selectedScenario: action.data,
                validationErrors: action.data.validationErrors,
                validated: action.data.validated,
                savingNode: false,
                isBusy: false,
            };
        case lessonConstants.GET_SCENARIO.FAILURE:
            return {
                ...state,
                isBusy: false,
                error: action.message,
            };

        case lessonConstants.CAN_PUBLISH_SCENARIO.REQUEST:
            return {
                ...state,
                canPublishCurrentScenario: false
            }
        case lessonConstants.CAN_PUBLISH_SCENARIO.SUCCESS:
            return {
                ...state,
                canPublishCurrentScenario: action.data.canBePublished,
                latestVersionIsPublished: action.data.isPublished
            }
        case lessonConstants.CAN_PUBLISH_SCENARIO.FAILURE:
            return {
                ...state,
                error: action.message,
            }  
        case lessonConstants.PUBLISH_SCENARIO.REQUEST:
            return {
                ...state,
            }
        case lessonConstants.PUBLISH_SCENARIO.SUCCESS:
            return {
                ...state,
            }
        case lessonConstants.PUBLISH_SCENARIO.FAILURE:
            return {
                ...state,
                error: action.message,
            }
            
        case lessonConstants.GET_SCENARIO_IMAGES.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.GET_SCENARIO_IMAGES.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.GET_SCENARIO_IMAGES.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.CREATE_SCENARIO.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.CREATE_SCENARIO.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.CREATE_SCENARIO.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.PROGRESS_CHANGE.SUCCESS:
            return {
            ...state,
            geniusCreationProgress: action.data
        };
        // case lessonConstants.GENIUS_SCENARIO.REQUEST:
        //             return {
        //         ...state,
        //         geniusCreationProgress: 0
        //     };
        // case lessonConstants.GENIUS_SCENARIO.SUCCESS:
        //     return {
        //         ...state, 
        //         geniusCreationProgress: -1
        //     };
        // case lessonConstants.GENIUS_SCENARIO.FAILURE:
        //     return {
        //         ...state,
        //         error: action.message,
        //         geniusCreationProgress: -1
        //     };
        case lessonConstants.RESYNC_SCENARIO.REQUEST:
            return {
                ...state,
                completedScenarioDownload: false,
            };
        case lessonConstants.RESYNC_SCENARIO.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.RESYNC_SCENARIO.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.UPDATE_SCENARIO.REQUEST:
            return {
                ...state,
                savingNode: true,
            };
        case lessonConstants.UPDATE_SCENARIO.SUCCESS:
            return {
                ...state, 
                validated: action.data.validated,
                validationErrors: action.data.validationErrors,
                savingNode: false,
            };
        case lessonConstants.UPDATE_SCENARIO.FAILURE:
            return {
                ...state,
                error: action.message,
                savingNode: true,
            };
        case lessonConstants.UPDATE_SCENARIO_NODE.REQUEST:
            return {
                ...state,
                completedScenarioDownload: false,
                reloadingNodes: true,
                savingNode: true,
            };
        case lessonConstants.UPDATE_SCENARIO_NODE.SUCCESS:
            let newSelectedScenario = {...state.selectedScenario}
            let newNodeArray = state.selectedScenario.scenarioNodes
            for(let i = 0; i < newNodeArray.length; i++) {
                if(newNodeArray[i].nodeId && newNodeArray[i].nodeId  === action.data.data.nodeId) {
                    newNodeArray[i] = action.data.data
                    if(action.data.data.backgroundImageUrl) {
                        newNodeArray[i].data.backgroundImageUrl = action.data.data.backgroundImageUrl
                    }
                }
            }

            newSelectedScenario.scenarioNodes = newNodeArray 
            if (!!!state.selectedScenario.scenarioNodes.some(e => e.nodeId === action.data.data.nodeId)) {
                newSelectedScenario.scenarioNodes.push(action.data.data)
            }        
            return {
                ...state, 
                reloadingNodes: false,
                savingNode: false,
            };
        case lessonConstants.UPDATE_SCENARIO_NODE.FAILURE:
            return {
                ...state,
                savingNode: false,
                completedScenarioDownload: true,
                isBusy: false,
                error: action.message,
            };
        case lessonConstants.UPDATE_SCENARIO_NODE_POSITION.REQUEST:
            return {
                ...state,
                updatedPosition: false
            };
        case lessonConstants.UPDATE_SCENARIO_NODE_POSITION.SUCCESS:

            // let scenarioTempNodePosition = JSON.parse(JSON.stringify(state.selectedScenario));
            // scenarioTempNodePosition.scenarioNodes.forEach(node => {
            //     if(node.nodeId === action.data.nodeId) {
            //         node.xPos = action.data.xPos
            //         node.yPos = action.data.yPos
            //         node.position.x = action.data.xPos
            //         node.position.y = action.data.yPos
            //         }
            // });
            return {
                ...state, 
                // selectedScenario: scenarioTempNodePosition,
                updatedPosition: true,
            };
        case lessonConstants.UPDATE_SCENARIO_NODE_POSITION.FAILURE:
            return {
                ...state,
                error: action.message,
            };
            case lessonConstants.UPDATE_SCENARIO_NODE_COMMENT.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.UPDATE_SCENARIO_NODE_COMMENT.SUCCESS:
            let unresolvedComments = false
            let scenarioTemp = JSON.parse(JSON.stringify(state.selectedScenario));
            scenarioTemp.scenarioNodes.forEach(node => {
                if(node.nodeId === action.data?.nodeId) {
                    node.authorNoteDtos = action.data.authorNoteDtos
                    node.data.authorNoteDtos = action.data.authorNoteDtos
                } else {
                    if(node.authorNoteDtos) {
                        if (node.authorNoteDtos.filter(x => x.authorNoteType === 0).length > 0) {
                            unresolvedComments = true
                        }
                    }
                }
            });
            scenarioTemp.areCommentsUnresolved = (unresolvedComments || action.data.areCommentsUnresolved)
            return {
                ...state, 
                selectedScenario: scenarioTemp,
            };
        case lessonConstants.UPDATE_SCENARIO_NODE_COMMENT.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.DELETE_IMAGE.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.DELETE_IMAGE.SUCCESS:
            return {
                ...state, 
            };
        case lessonConstants.DELETE_IMAGE.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.REPLACE_IMAGE.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.REPLACE_IMAGE.SUCCESS:
            return {
                ...state,
            };
        case lessonConstants.REPLACE_IMAGE.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case lessonConstants.GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES.REQUEST:
            return {
                ...state,
                
            };
        case lessonConstants.GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES.SUCCESS:
            return {
                ...state,
                selectedLessonSupportedLanguages: action.data
            };
        case lessonConstants.GET_CUSTOM_LESSON_SUPPORTED_LANGUAGES.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case lessonConstants.ADD_CUSTOM_LESSON_SUPPORTED_LANGUAGE.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.ADD_CUSTOM_LESSON_SUPPORTED_LANGUAGE.SUCCESS:
            return {
                ...state,
                selectedLessonSupportedLanguages: action.data
            };
        case lessonConstants.ADD_CUSTOM_LESSON_SUPPORTED_LANGUAGE.FAILURE:
            return {
                ...state,
                error: action.message,
            };
            
        case lessonConstants.DELETE_CUSTOM_LESSON_SUPPORTED_LANGUAGE.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.DELETE_CUSTOM_LESSON_SUPPORTED_LANGUAGE.SUCCESS:
            return {
                ...state,
                selectedLessonSupportedLanguages: action.data
            };
        case lessonConstants.DELETE_CUSTOM_LESSON_SUPPORTED_LANGUAGE.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case lessonConstants.IMPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.IMPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.SUCCESS:
            return {
                ...state,
            };
        case lessonConstants.IMPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case lessonConstants.EXPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.EXPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.SUCCESS:
            return {
                ...state,
            };
        case lessonConstants.EXPORT_EXCEL_CUSTOM_LESSON_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
            };
            
        case lessonConstants.RESET_CURRENT_ORGANIZATION_LESSONS.REQUEST:
            return {
                ...state,
                organizationLessons: [],
            };
        case lessonConstants.RESET_CURRENT_ORGANIZATION_LESSONS.SUCCESS:
            return {
                ...state,
        };
        case lessonConstants.RESET_CURRENT_ORGANIZATION_LESSONS.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.CHECK_FOR_CHANGES.REQUEST:
            return {
                ...state,
            };
        case lessonConstants.CHECK_FOR_CHANGES.SUCCESS:
            return {
                ...state,
            };
        case lessonConstants.CHECK_FOR_CHANGES.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case lessonConstants.CHECK_FOR_CHANGES_CHANGED_CONTENT.REQUEST:
            return {
                ...state,
                completedScenarioDownload: false
            }
            
        case lessonConstants.LESSON_BUILDER_BUSY.REQUEST:
            let scenarioTempIsBusyRequest= JSON.parse(JSON.stringify(state.selectedScenario));
            scenarioTempIsBusyRequest.scenarioNodes.forEach(node => {
                    node.data.isBusy = action.payload.isBusy
            });
            return {
                ...state,
                isBusy: action.payload.isBusy,
                selectedScenario: scenarioTempIsBusyRequest
            };
        case lessonConstants.LESSON_BUILDER_BUSY.SUCCESS:
            let scenarioTempIsBusy= JSON.parse(JSON.stringify(state.selectedScenario));
            scenarioTempIsBusy.scenarioNodes.forEach(node => {
                    node.data.isBusy = action.payload.isBusy
            });
            return {
                ...state, 
                isBusy: action.payload.isBusy,
                selectedScenario: scenarioTempIsBusy
            };
        case lessonConstants.LESSON_BUILDER_BUSY.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case lessonConstants.LESSON_BUILDER_CAN_EDIT_LESSON.REQUEST:
            if (state.selectedScenario) {
                let scenarioTempCanEditLessonRequest= JSON.parse(JSON.stringify(state.selectedScenario));
                scenarioTempCanEditLessonRequest.scenarioNodes.forEach(node => {
                    node.data.canEditLesson = action.payload.canEditLesson
                });
                return {
                    ...state,
                    canEditLesson: action.payload.canEditLesson,
                    selectedScenario: scenarioTempCanEditLessonRequest
                };  
            } else {
                return {
                    ...state,
                    canEditLesson: action.payload.canEditLesson,
                };    
            }
            
        case lessonConstants.LESSON_BUILDER_VIEW_MODE.REQUEST:
            return {
                ...state,
                lessonBuilderViewMode: action.payload.lessonBuilderViewMode,
            };
            
        case lessonConstants.GET_LESSON_VERSIONS.REQUEST:
            return {
                ...state,
            }
        case lessonConstants.GET_LESSON_VERSIONS.SUCCESS:
            return {
                ...state,
                lessonVersions: action.data
            }
        case lessonConstants.GET_LESSON_VERSIONS.FAILURE:
            return {
                ...state,
                error: action.message
            }

        case lessonConstants.REVERT_TO_LESSON_VERSION.REQUEST:
            return {
                ...state,
            }
        case lessonConstants.REVERT_TO_LESSON_VERSION.SUCCESS:
            return {
                ...state,
            }
        case lessonConstants.REVERT_TO_LESSON_VERSION.FAILURE:
            return {
                ...state,
                error: action.message
            }
            
        case lessonConstants.SET_SPEECH_RECOGNITION_L2.REQUEST:
            console.log("Setting l2 for speech recogitions to " + action.payload.l2Id)
            return {
                ...state,
                speechRecognitionL2: action.payload.l2Id
            }
        case authConstants.LOGOUT.SUCCESS:
            return initialState;
        default:
            return state
    }
}