import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles/";
import {
    TextField,
    Grid,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Button,
    Select,
    Typography,
    Tab,
    Tabs,
    Slider
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { randdActions } from '../../../_state/actions';
import { CSVLink, CSVDownload } from "react-csv";
import CSVReader from 'react-csv-reader'
import {lessonActions} from "../../../_state/actions";

import Unsplash, { toJson } from "unsplash-js";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
const unsplash = new Unsplash({
  //accessKey: "MixXDsa8sgZuoklE2w9FPUjBhDpULeDEIziHN6gpsrI"
  accessKey: "_Sbkg3Lsuj6cia6OFTiZwhiULfe8-sz1fK0ibb1vaqo",
});



const useStyles = makeStyles(theme => ({
    gridContainer: {
        // height: '100vh',
         padding: 30,
     },
     root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
     gridList: {
      width: 900,
      cursor: 'pointer',
     // height: 450,
    },
    card: {
      height: 5000,
      
    }
}));


const ImageMatcher = (props) => {
    const classes = useStyles();
    const [images, setImages] = useState([])
    const [keywords, setKeywords] = useState("")
    const [filename, setFilename] = useState("")
    const [file, setFile] = useState("")
    const [fileId, setFileId] = useState("")
    const [includeExistingImages, setIncludeExistingImages] = useState(false)

    
    const [l2, setL2] = useState('nb');
    const [l1, setL1] = useState('en');
    const [data, setData] = useState([])
    const [current, setCurrent] = useState(0)
    const [isTestStarted, setIsTestStarted] = useState(false)
    const [promptFeedback, setPromptFeedback] = useState(false)
    const [correctAnswer, setCorrectAnswer] = useState('')
    const [userAnswer, setUserAnswer] = useState('')
    const [question, setQuestion] = useState('')
    const [showDescription, setShowDescription] = useState(false)
    const [userFeedbackText, setUserFeedbackText] = useState('')
    const [changeOrNext, setChangeOrNext] = useState(false)
    const [csvQuestionColumn, setCsvQuestionColumn] = useState(null)
    const [csvAnswerColumn, setCsvAnswerColumn] = useState(null)
    const [open, setOpen] = React.useState(false);

    
    
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleCloseYes = () => {
      const {organization} = props;

      setOpen(false);
      // replace image
      props.dispatch(lessonActions.replaceImage(organization.selectedOrganization, fileId, file, filename, true));  
     // props.dispatch(lessonActions.addImage(organization.selectedOrganization, filename, finalisedFile, true));  

    };

    const handleCloseNo = () => {
      setOpen(false);
    };

    const [output, setOutput] = useState(
      [
        ["correctAnswer", "question", "userAnswer", "outcome", "testerHappy", "feedbackText", "capeeshEvaluationText"]
      ])

      const [currentIndex, setCurrentIndex] = useState(0)

      

    const dummyData = [
      "instructions",
"screwdriver",
"bookshelf",
"handyperson",
"hammer",
      "I hope my boss will approve my one year in Australia",
  "Living abroad is going to give her a new perspective",
"He is going to extend his stay in Melbourne",
"on stage",
"excuse me!",
"Would you mind?",
"May I ask...?",
"I'll get this",
"I can do better than that",
"to go jogging",
"to get ill",
"to exercise",
"There trousers are too tight",
"you must lose that beer belly",
"Are you feeling better now?",
"Can you pick up the tickets?",
"The singer sings horribly.",
"It won't be sold out"

]

    const [maxNumberOfWords, setMaxNumberOfWords] = useState(5)
    const [difficultyLevel, setDifficultyLevel] = useState(90)
    const [maxWordLength, setMaxWordLength] = useState(10)

    useEffect(() => {
        if(data.length > 0) {
          setCorrectAnswer(data[current][csvAnswerColumn])
          setUserAnswer('')
          setQuestion(data[current][csvQuestionColumn])
        }
    }, [current]);

    useEffect(() => {
      //props.generalReset();
      const {organization} = props;
      props.dispatch(lessonActions.getImageReferences(organization.selectedOrganization, "?includeSharable=true"));
      setCorrectAnswer(null)
      setUserAnswer('')
      setQuestion(null) 
  }, []);
    
    const searchPhotos = async (e) => {
      let searchTerms = dummyData[currentIndex]
      if(e === null) {
        searchTerms = keywords
      } else {
        setKeywords(dummyData[currentIndex])
      }
      unsplash.search
        .photos(searchTerms, 1, 20, {})
        .then(toJson)
        .then((json) => {
          setImages(json.results)
        });
         if(currentIndex +1 < dummyData.length) {
          setCurrentIndex(currentIndex +1)
          
         } 
    };


    const searchPhotosFromImport = async (keywords) => {    
      unsplash.search
        .photos(keywords, 1, 70, {})
        .then(toJson)
        .then((json) => {
          setImages(json.results)
        });
         if(currentIndex +1 < data.length) {
          setCurrentIndex(currentIndex +1)
         } 
    };

    
    const nextItem = () => {
      
      let x = current
     while(findNextSuitableItem(data[x]) === false || x >= data.length) {
      x++
     }
      setCurrent(x+1)
      searchPhotosFromImport(data[x].text)
      setKeywords(data[x].text)
      setFilename(data[x].image_nice_name)
    };

    const findNextSuitableItem = (item) => {
       
      let shouldSearchForImage = true
      if(!!!includeExistingImages) {
        
        props.lesson.imageReferences.forEach(imageReference => {
          if(imageReference.niceName === item.image_nice_name) {
            shouldSearchForImage = false
          } 
        });
      } 
    return shouldSearchForImage 
    };

    const handleImageChosen = (image, e) => {
      download(image)
      let dataTemp = JSON.parse(JSON.stringify(data));//[...nodes]
      dataTemp[current]['completed'] = image
      setData(dataTemp)
    setTimeout(() => {  nextItem() }, 3000); 

    };

    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header =>
        header
          .toLowerCase()
          .replace(/\W/g, '_')
    }

    const handleForce = (data, fileInfo) => {
      setData(data)
    }

    const download = async (url) => {
      const {organization} = props;
      const response = await fetch(
        url
      );
      if (response.status === 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        //link.download = filename;

        // check org's image references to see if already exists

        var finalisedFile = new File([blob], filename,{type: "image/jpeg", lastModified:new Date()})

        let fileExists = false
        
        props.lesson.imageReferences.forEach(imageReference => {
          if(imageReference.niceName === filename) {
            fileExists = true
            setFileId(imageReference.id)
          } 
        });
        setFile(finalisedFile)

        if(!!!fileExists) {
          link.download = filename;
          link.click();
          link.remove();
        
          props.dispatch(lessonActions.addImage(organization.selectedOrganization, filename, finalisedFile, true));  
        } else {
          setOpen(true)
        }

        document.body.appendChild(link);
     
        return { success: true };
      }
    };

      return (
        <div
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "center"
        }}
      >
            <Dialog
        open={open}
        onClose={handleCloseNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Replace existing image?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An image already exists that is associated with that lesson. Would you like to replace the image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleCloseNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
        <Grid container
            direction="row"
            className={classes.headerContainer}
        >
          <Grid item xs={4} sm={4} lg={4} className={classes.gridItem}>
            <Card>
              <CardContent className={classes.approve} >
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
                      <TextField
                          style={{ width: '400px'}}
                          placeholder="Image description"
                          margin="normal"
                          value={keywords}
                          onChange={(event) => {
                              setKeywords(event.target.value)
                          }}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                    <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
                        <Button onClick={event => nextItem()}>
                            Next
                        </Button>
                      </Grid>
                      <Button onClick={event => searchPhotos(null)}>
                          Refine search
                      </Button>
                      <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
                        <CSVReader
                            cssClass="csv-reader-input"
                            label="Upload test data"
                            onFileLoaded={handleForce}
                            parserOptions={papaparseOptions}
                            inputId="ObiWan"
                            inputStyle={{color: 'red'}}
                        />
                                    <CSVLink filename={"image_reference_export_ENG"} data={data}>            
                          <Button
                              className={classes.initial}
                              variant="contained" color="primary"
                              >
                              Download CSV
                          </Button>
                        </CSVLink>
                        <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        //id="rememberMe"
                                                        checked={includeExistingImages}
                                                        onChange={event => setIncludeExistingImages(!!!includeExistingImages)}
                                                        value={includeExistingImages}
                                                        color="primary"
                                                    />
                                                }
                                                label={"Include already added images"}
                                            />
                                             <img src={"https://images.babbel.com/v1.0.0/images/" + filename.substring(4, filename.length - 4) + "/variations/square/resolutions/400x400.jpg"} alt={"an image"} />
                      </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8} sm={8} lg={8} className={classes.gridItem}>
            <Card>    
                <CardContent className={classes.card} >
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
                    <div className={classes.root}>
      <GridList cellHeight={320} className={classes.gridList} cols={3}>
      {images.length > 0 ?
        images.map((image, index) => (
          <GridListTile key={index} onClick={(e) => handleImageChosen(image.urls.raw+"&w=700&h=700&fit=crop&crop=faces", e) } >
            <img src={image.urls.raw+"&w=700&h=700&fit=crop&crop=faces"} alt={"an image"} />
            <GridListTileBar
            title={"Photo by " + image.user.name}
            subtitle={<span>On <a   style={{
              color: 'white'
            }} href="">unsplash.com</a></span>}
            actionIcon={
              <IconButton aria-label={`info about`} className={classes.icon}>
                <InfoIcon />
              </IconButton>
            }
          />
          </GridListTile>
        ))
        :
        <Typography variant="body1" component="p">
          No results
        </Typography>
      }
      </GridList>
    </div>


                    </Grid>
                  </Grid> 
                </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div> 
      );
}


ImageMatcher.propTypes = {};

const mapToProps = (state) => {
    return {
        randd: state.randd,
        organization: state.organization,
        lesson: state.lesson
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sentenceSuitable: (sentence, translation, language1, language2, maxNumberOfWords, maxWordLength, words, difficultyLevel) => dispatch(randdActions.sentenceSuitable(sentence, translation, language1, language2, maxNumberOfWords, maxWordLength, words, difficultyLevel)),
        generalReset: () => dispatch(randdActions.generalReset()),
  }
};

export default connect(mapToProps, mapDispatchToProps)(ImageMatcher)



