import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';

import configureStore from "./_state/store";
import {PersistGate} from 'redux-persist/integration/react'
import App from './App';
import { unregister } from './registerServiceWorker';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {persistStore} from "redux-persist";

const store = configureStore();
const persistor = persistStore(store);


//  addLocaleData([...en, ...nb]);

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
let language = (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

// Split locales with a region code
let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
// Try full locale, try locale without region code, fallback to 'en'




render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
                <App/>
        </PersistGate>
    </Provider>,
    document.getElementById('app')
);

unregister();