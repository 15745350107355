import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import {Box} from "@mui/material";
import TextField from "@mui/material/TextField";
import {AutoCourseCreateDialogStepType} from "../../../../Logic/AutoCourseCreationConstants";
import Divider from "@mui/material/Divider";
import whistle from "@iconify-icons/mdi/whistle";
import {CapeeshColors} from "../../../../assets/ColorPalette";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const useStyles = makeStyles(theme => ({
    mainContainer:
        {
            justifyContent: "center",
        },
    topMainText:
        {
            width: '100%', 
            textAlign: 'center',
            color: 'white',
            fontSize: 32,
            fontFamily: 'Rubik', 
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    topSecondaryText:
        {
            width: '100%', 
            textAlign: 'center', 
            color: 'rgba(255, 255, 255, 0.80)', 
            fontSize: 14,
            fontFamily: 'Rubik', 
            fontWeight: '400', 
            wordWrap: 'break-word',
            marginBottom: 16
        },
    dividerStyle:
        {
            width: '100%',
            height: '100%',
            border: '1px rgba(255, 255, 255, 0.32) solid',
            marginTop: 16, 
            marginBottom: 16
        },
    switchMainText:
        {
            color: 'white', 
            fontSize: 16, 
            fontFamily: 'Rubik',
            fontWeight: '500', 
            wordWrap: 'break-word'
        },
    switchSecondaryText:
        {
            width: '100%',
            color: 'rgba(255, 255, 255, 0.80)',
            fontSize: 16, 
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        }
}));

const AutoCourseCreateTeachingContent = (props) => {
    
    // props.handleSetAuthorInputMetaData
    // props.maxLenght
    // props.authorMetadataDto
    // props.handleToggleTranslations
    
    const classes = useStyles();

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "white",
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: CapeeshColors.BlueBright,
        },
    }));
    
    return (
        <div className={classes.mainContainer}>
            <Typography className={classes.topMainText}>
                What should the lessons be built on?
            </Typography>
            <Typography className={classes.topSecondaryText}>
                Type or paste up to {props.maxLenght} words of text that you would like to transform into interactive lessons.
            </Typography>
            <Box style={{ color: "var(--White, #FFF)", fontFamily: "Rubik", fontSize: 16, fontStyle: "normal", fontWeight: 500, lineHeight: "140%", minHeight: '50px' }}>
                <TextField
                    placeholder={"Copy and paste your text in here"}
                    multiline style={{ borderRadius:8, border: "1px solid var(--White, #FFF)", background: "rgba(255, 255, 255, 0.16)", width: 664, height: 186}} autoFocus margin="dense" id="name" fullWidth variant="standard"
                    sx={{
                        '& .MuiInputBase-input::placeholder': {
                            color: 'white',
                        },
                        '& .MuiInputBase-input': {
                            color: 'white',
                            fontSize: '14px',
                            marginLeft: '8px',
                        },
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => props.handleSetAuthorInputMetaData(AutoCourseCreateDialogStepType.SupportingText, e.target.value)}
                    rows={4}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
            </Box>
            <Typography
                variant="caption"
                component="div"
                color= {props.authorMetadataDto?.supportingText.split(" ").length > props.maxLenght ? "red" : 'white'}
                style={{textAlign: 'right', color: "white", fontSize: 14, fontFamily: 'Rubik', fontWeight: '400', padding: 8 }}
            >
                {props.authorMetadataDto?.supportingText.trim() ? props.authorMetadataDto?.supportingText.split(" ").length : 0} / {props.maxLenght}
            </Typography>
            <div className={classes.dividerStyle}/>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    <Typography className={classes.switchMainText}>
                        Translation support
                    </Typography>
                    <Typography className={classes.switchSecondaryText}>
                        Do we need a description to this option, or is the label self-explanatory?
                    </Typography>
                </div>
                <div>
                    <GreenSwitch inputProps={{ 'aria-label': 'controlled' }} 
                                 defaultChecked={true}
                                 onChange={() => props.handleToggleTranslations()} 
                                 checked={props.authorMetadataDto?.includeTranslation}/>
                </div>
            </div>
        </div>
    );
};

AutoCourseCreateTeachingContent.propTypes = {};

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseCreateTeachingContent)