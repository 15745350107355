import React, {useRef, useState} from "react";
import {  MenuItem,  ControlledMenu, useHover} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {connect} from "react-redux";
import {makeStyles, Typography} from "@material-ui/core";

import {history} from "../../../../_state/helpers";
import {CapeeshColors} from "../../../../assets/ColorPalette";
import {EditOutlined} from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  singleElementStyle: {
    fontSize: "20px",
    height: '50px',
  },
  hoverButtonStyle: {
    marginLeft:'20px',
    fontSize: '20px',
    cursor: 'pointer'
  }
}));


const CreateLessonMenu = (props) => {

  const classes = useStyles();
  
  
  // expected props
  // props.courseGroupId
  // props.topicId
  // props.l2Id
  // props.defaultL1Id
  
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  const handleCreateLessonManually = () => {
    const queryParams = "?createFromTopic=true&topicId=" + props.topicId + "&l2Id=" + props.l2Id + "&defaultL1Id=" + props.defaultL1Id

    let scenarioCreateUrl = ""
    if (props.courseGroupId && props.topicId) {
      scenarioCreateUrl = '/creator/course/group/' + props.courseGroupId + '/topic/' + props.topicId + '/lesson/setup' + queryParams;
    } else {
      scenarioCreateUrl = '/creator/topic/list/' + props.topicId + '/lesson/setup' + queryParams;
    }
    history.push(scenarioCreateUrl);
  }
  
  const handleCreateLessonWithAI = () => {
    const queryParams = "?createFromTopic=true&topicId=" + props.topicId + "&l2Id=" + props.l2Id + "&defaultL1Id=" + props.defaultL1Id

    let accUrl = '/creator/course/group/' + props.courseGroupId + '/topic/' + props.topicId + '/acc' + queryParams;
    history.push(accUrl);
  }

  const handleCreateLessonWithAILanguage = () => {
    const queryParams = "?createFromTopic=true&topicId=" + props.topicId + "&l2Id=" + props.l2Id + "&defaultL1Id=" + props.defaultL1Id

    let accUrl = '/creator/course/group/' + props.courseGroupId + '/topic/' + props.topicId + '/acc/language/' + queryParams;
    history.push(accUrl);
  }
  

  return (
    <React.Fragment>
      {
        (props.user.organizationRole === "admin" || props.user.organizationRole === "teacher") &&
        <React.Fragment>
          <div
            className={classes.hoverButtonStyle}
            ref={ref} {...anchorProps}
            style={{
              backgroundColor: CapeeshColors.Blue,
              borderRadius: '8px',
              height: '40px',
              minWidth: '180px',
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <Typography style={{marginTop: '8px', paddingLeft: '10px', color: 'white'}}>
                Create new lessons
              </Typography>
              <EditOutlined sx={{marginTop: '5px', marginLeft: '5px', fontSize: '15px', color: 'white', paddingRight: '5px'}}/>
            </div>
          </div>

          <ControlledMenu
            {...hoverProps}
            state={isOpen ? 'open' : 'closed'}
            anchorRef={ref}
            onClose={() => setOpen(false)}
          >
            <MenuItem
              disabled={false}
              onClick={() => handleCreateLessonManually()}
              className={classes.singleElementStyle}>
              Manually 
            </MenuItem>

            <MenuItem
              disabled={false}
              onClick={() => props.onGoToCreateAiComplience()}
              className={classes.singleElementStyle}>
              With AI
            </MenuItem>
            {
              props.showAttachExistingScenario &&
              <MenuItem
                onClick={() => props.onAttachExistingScenario()}
                className={classes.singleElementStyle}>
                Attach Existing
              
              </MenuItem>
              
            }
            <MenuItem
            disabled={false}
            onClick={() => props.onGoToCreateAiLanguage()}
            className={classes.singleElementStyle}>
              Language Lesson With AI
            </MenuItem>
          </ControlledMenu>
        </React.Fragment>
      }
    </React.Fragment>
  )
}


CreateLessonMenu.propTypes = {};

const mapStateToProps = (state) => {
  return {
    organization: state.organization,
    user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLessonMenu)
