import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid
} from '@material-ui/core'
import {ReactComponent as CapeeshLogo} from 'assets/images/capeesh_logo.svg';
import PasswordStepper from 'Components/PasswordStepper/PasswordStepper';

const styles = theme => ({
    gridContainer: {
        padding: 30,
    },
    margin: {
        margin: theme.spacing(1),
    },
    forgotpassword : {
        padding: '10px 0px 10px 0px', 
        cursor: 'pointer'
    },
    capeeshLogo: {
        display: 'inherit',
        margin: '20px auto',
    },
});

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            username: '',
            password: '',
            modalOpen: false,
            rememberMe: false,
            submitted: false,
            loadedModal: 0,
        };
    }

    render() {
        const { loggingIn, classes, messaging } = this.props;
        const { username, password, submitted, rememberMe, modalOpen } = this.state;

        return (
            <div>
                <Grid container 
                      alignItems="center" 
                      justify="center"
                      className={classes.gridContainer}
                >
                    <Grid item xs={8} sm={8} md={8}>
                        <CapeeshLogo width={300} height={100} className={classes.capeeshLogo} />
                        <PasswordStepper />
                    </Grid>    
                </Grid>
            </div>
        );
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const {user, messaging} = state;
    const { loggingIn } = state.auth;
    return {
        loggingIn, messaging, user
    };
}

const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles)
);

export default enhance(LoginPage)