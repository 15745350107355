import {asyncRequest} from "./asyncRequest";


export const autoCourseCreationConstants = {
  ACC_PARSE_TEXT_STATUS: new asyncRequest('ACC_PARSE_TEXT_STATUS'),

  ACC_SUGGEST_SECTIONS: new asyncRequest('ACC_SUGGEST_SECTIONS'),
  ACC_SUGGEST_LESSON_PLANS: new asyncRequest('ACC_SUGGEST_LESSON_PLANS'),

  ACC_CREATE_TOPIC: new asyncRequest('ACC_CREATE_TOPIC'),
  
  ACC_RESET_ALL_LESSON_PLANS: new asyncRequest('ACC_RESET_ALL_LESSON_PLANS'),
  ACC_RESET_ALL: new asyncRequest('ACC_RESET_ALL'),
  ACC_REFRESH_RESET_SOME: new asyncRequest('ACC_REFRESH_RESET_SOME'),
  ACC_RESET_ALL_SECTIONS: new asyncRequest('ACC_RESET_ALL_SECTIONS'),

  

  
  ACC_STORE_AUTHOR_INPUT_METADATA: new asyncRequest('ACC_STORE_AUTHOR_INPUT_METADATA'),
  ACC_STORE_AUTHOR_DIALOG_METADATA: new asyncRequest('ACC_STORE_AUTHOR_DIALOG_METADATA'),

  ACC_CREATE_LESSON: new asyncRequest('ACC_CREATE_LESSON'),
  ACC_CREATE_QUIZ: new asyncRequest('ACC_CREATE_QUIZ'),
  ACC_CREATE_STORY: new asyncRequest('ACC_CREATE_STORY'),
  ACC_CREATE_DIALOGUE: new asyncRequest('ACC_CREATE_DIALOGUE'),

  ACC_SUGGEST_SCENARIO: new asyncRequest('ACC_SUGGEST_SCENARIO'),


  ACC_CREATE_LESSON_WITH_SELECTED_STAGES: new asyncRequest('ACC_CREATE_LESSON_WITH_SELECTED_STAGES'),

  // Local updates below (no server saves)
  ACC_LOCAL_UPDATE_LESSON_SUGGESTION_DTO: new asyncRequest('ACC_LOCAL_UPDATE_LESSON_SUGGESTION_DTO'),
  ACC_LOCAL_UPDATE_SUGGEST_SECTIONS_RETURN_DTO: new asyncRequest('ACC_LOCAL_UPDATE_SUGGEST_SECTIONS_RETURN_DTO'),

  ACC_CREATE_FILL_IN_BLANKS: new asyncRequest('ACC_CREATE_FILL_IN_BLANKS'),
  ACC_TRANSITION_STAGE: new asyncRequest('ACC_TRANSITION_STAGE'),
  ACC_TRANSITION_LANGUAGE_STAGE: new asyncRequest('ACC_TRANSITION_LANGUAGE_STAGE'),

  ACC_UPDATE_TITLE_AND_DESCRIPTION: new asyncRequest('ACC_UPDATE_TITLE_AND_DESCRIPTION'),

  ACC_SUGGEST_IMAGES: new asyncRequest('ACC_SUGGEST_IMAGES'),
  ACC_SUGGEST_IMAGE: new asyncRequest('ACC_SUGGEST_IMAGE'),


  
  // ACC_LANGUAGE
  ACC_LANGUAGE_SUGGEST_VOCAB_SECTION: new asyncRequest('ACC_LANGUAGE_SUGGEST_VOCAB'),
  ACC_LANGUAGE_SUGGEST_EXTRA_VOCAB: new asyncRequest('ACC_LANGUAGE_SUGGEST_EXTRA_VOCAB'),
  ACC_LANGUAGE_ADD_VOCAB_ITEM: new asyncRequest('ACC_LANGUAGE_ADD_VOCAB_ITEM'),
  ACC_LANGUAGE_SUGGEST_CHAPTER_OPTIONS: new asyncRequest('ACC_LANGUAGE_SUGGEST_CHAPTER_OPTIONS'),


  ACC_LANGUAGE_SUGGEST_IMAGES_FOR_SELECTED: new asyncRequest('ACC_LANGUAGE_SUGGEST_IMAGES_FOR_SELECTED'),


  ACC_LANGUAGE_TOGGLE_CONCEPT_IN_USE: new asyncRequest('ACC_LANGUAGE_TOGGLE_CONCEPT_IN_USE'),
  
  ACC_LANGUAGE_DISTRIBUTE_LESSON_PLANS: new asyncRequest('ACC_LANGUAGE_DISTRIBUTE_LESSON_PLANS'),
  ACC_LANGUAGE_STORE_CHAPTER_OPTIONS: new asyncRequest('ACC_LANGUAGE_STORE_CHAPTER_OPTIONS'),
  ACC_LANGUAGE_LESSON_DISTRIBUTION: new asyncRequest('ACC_LESSON_DISTRIBUTION'),
  ACC_LANGUAGE_RECREATE_SINGLE_LESSON_PLAN: new asyncRequest('ACC_LANGUAGE_RECREATE_SINGLE_LESSON_PLAN'),
  
  ACC_LANGUAGE_LESSON_GENERATION: new asyncRequest('ACC_LANGUAGE_LESSON_GENERATION'),
  // lesson plan chapter fill up endpoints
  ACC_LESSON_PLAN_CHAPTER_EXPAND: new asyncRequest('ACC_LESSON_PLAN_CHAPTER_EXPAND'),

  // update any area of data after author change
  ACC_LANGUAGE_UPDATE_DATA: new asyncRequest('ACC_LANGUAGE_UPDATE_DATA'),

  
  
}


export const autoCourseCreationActions = {
  
  transitionStage(parseTextRequestDto) {
    return {
      type: autoCourseCreationConstants.ACC_TRANSITION_STAGE.REQUEST,
      payload: {
        parseTextRequestDto
      }
    }
  },
  transitionLanguageStage(parseTextRequestDto) {
    return {
      type: autoCourseCreationConstants.ACC_TRANSITION_LANGUAGE_STAGE.REQUEST,
      payload: {
        parseTextRequestDto
      }
    }
  },
  accStoreAuthorInputMetadata(authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_STORE_AUTHOR_INPUT_METADATA.REQUEST,
      payload: {
        authorInputMetadata,
      }
    }
  },
  
  suggestTextSections(parseTextRequestDto) {
    return {
      type: autoCourseCreationConstants.ACC_SUGGEST_SECTIONS.REQUEST,
      payload: {
        parseTextRequestDto
      }
    }
  },
  
  accOnRefreshResetSome() {
    return {
      type: autoCourseCreationConstants.ACC_REFRESH_RESET_SOME.REQUEST,
      payload: {}
    }
  },
  
  accResetAllLessonPlans() {
    return {
      type: autoCourseCreationConstants.ACC_RESET_ALL_LESSON_PLANS.REQUEST,
      payload: {}
    }
  },

  accResetAllSections() {
    return {
      type: autoCourseCreationConstants.ACC_RESET_ALL_SECTIONS.REQUEST,
      payload: {}
    }
  },

  accResetAll() {
    return {
      type: autoCourseCreationConstants.ACC_RESET_ALL.REQUEST,
      payload: {}
    }
  },
  
  accSuggestLessonPlanForSection(parseTextRequestDto) {
    return {
      type: autoCourseCreationConstants.ACC_SUGGEST_LESSON_PLANS.REQUEST,
      payload: {
        parseTextRequestDto
      }
    }
  },

  accLocalUpdateSuggestSectionsReturnDto(suggestSectionsReturnDto) {
    return {
      type: autoCourseCreationConstants.ACC_LOCAL_UPDATE_SUGGEST_SECTIONS_RETURN_DTO.REQUEST,
      payload: {
        suggestSectionsReturnDto: suggestSectionsReturnDto
      }
    }
    
  },

  accUpdateLessonSuggestionDto(localLessonSuggestionDto) {
    return {
      type: autoCourseCreationConstants.ACC_LOCAL_UPDATE_LESSON_SUGGESTION_DTO.REQUEST,
      payload: {
        localLessonSuggestionDto: localLessonSuggestionDto
      }
    }
    
  },
  
  accParseTextStatus() {
    return {
      type: autoCourseCreationConstants.ACC_PARSE_TEXT_STATUS.REQUEST,
      payload: {
        
      }
    }
  },
  
  accStoreAuthorDialog(dialogSteps) {
    return {
      type: autoCourseCreationConstants.ACC_STORE_AUTHOR_DIALOG_METADATA.REQUEST,
      payload: {
        dialogSteps,
      }
    }
  },
  
  accCreateTopic(parseTextRequestDto) {
    return {
      type: autoCourseCreationConstants.ACC_CREATE_TOPIC.REQUEST,
      payload: {
        parseTextRequestDto
      }
    }
  },

  accCreateLesson(authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_CREATE_LESSON.REQUEST,
      payload: {
        authorInputMetadata,
        sections: authorInputMetadata.sections,
        lesson: authorInputMetadata.lesson,
        attachToTopicId: authorInputMetadata.attachToTopicId
      }
    }
  },
  accCreateQuiz(authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_CREATE_QUIZ.REQUEST,
      payload: {
        ...authorInputMetadata,
        section: authorInputMetadata.section,
        lesson: authorInputMetadata.lesson,
        concepts: authorInputMetadata.concepts
      }
    }
  },

  accCreateStory(authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_CREATE_STORY.REQUEST,
      payload: {
        ...authorInputMetadata,
        section: authorInputMetadata.section,
        lesson: authorInputMetadata.lesson
      }
    }
  },

  accCreateDialogue(authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_CREATE_DIALOGUE.REQUEST,
      payload: {
        authorInputMetadata: authorInputMetadata.authorInputMetadata,
        section: authorInputMetadata.section,
        lesson: authorInputMetadata.lesson,
        text: authorInputMetadata.text,
        concepts: authorInputMetadata.concepts

      }
    }
  },

  accSuggestScenario(authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_SUGGEST_SCENARIO.REQUEST,
      payload: {
        authorInputMetadata,
        sections: authorInputMetadata.sections,
        lesson: authorInputMetadata.lesson,
        text: authorInputMetadata.supportingText
      }
    }
  },

  

  accCreateLessonWithSelectedStages(authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_CREATE_LESSON_WITH_SELECTED_STAGES.REQUEST,
      payload: {
        authorInputMetadata,
        sections: authorInputMetadata.sections,
        lesson: authorInputMetadata.lesson,
        concepts: authorInputMetadata.lesson.concepts,
        text: "",
        exercises: authorInputMetadata.exercises,
        attachToTopicId: authorInputMetadata.attachToTopicId
      }
    }
  },

  accCreateFillInBlanks(authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_CREATE_FILL_IN_BLANKS.REQUEST,
      payload: {
        authorInputMetadata,
        sections: authorInputMetadata.sections,
        lesson: authorInputMetadata.lesson,
        concepts: authorInputMetadata.lesson.concepts,
        text: "",
        exercises: authorInputMetadata.exercises
      }
    }
  },

  accUpdateTitleAndDescription(localLessonSuggestionDto) {
    return {
      type: autoCourseCreationConstants.ACC_UPDATE_TITLE_AND_DESCRIPTION.REQUEST,
      payload: {
        localLessonSuggestionDto: localLessonSuggestionDto

      }
    }
  },
  accSuggestImages(localLessonSuggestionDto) {
    return {
      type: autoCourseCreationConstants.ACC_SUGGEST_IMAGES.REQUEST,
      payload: {
        //localLessonSuggestionDto: localLessonSuggestionDto

      }
    }
  },

  accSuggestImage(localLessonSuggestionDto) {
    return {
      type: autoCourseCreationConstants.ACC_SUGGEST_IMAGE.REQUEST,
      payload: {
        //localLessonSuggestionDto: localLessonSuggestionDto

      }
    }
  },
  
  accLanguageSuggestVocab(vocabRequestDto) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_VOCAB_SECTION.REQUEST,
      payload: {
        vocabRequestDto
      }
    }
  },
  
  accLanguageChapterOptions(narrativeRequestDto){
    return{
      type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_CHAPTER_OPTIONS.REQUEST,
      payload: {
        narrativeRequestDto
      }
    }
  },
  
  accLanguageSuggestExtraVocab(authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_EXTRA_VOCAB.REQUEST,
      payload: {
        authorInputMetadata
      }
    }
  },
  
  accLanguageAddVocabItem(concepts) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_ADD_VOCAB_ITEM.REQUEST,
      payload: {
        concepts
      }
    }
  },
  
  accLanguageToggleConceptInUse(sectionId, conceptId, usedInLesson) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_TOGGLE_CONCEPT_IN_USE.REQUEST,
      payload: {
        sectionId,
        conceptId,
        usedInLesson
      }
    }
  },
  
  accLanguageStoreSelectedChapters(selectedChapters) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_STORE_CHAPTER_OPTIONS.REQUEST,
      payload: {
        selectedChapters
      }
    }
  },
  
  
  accLessonDistribution(languageLessonDistributionRequestDto) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_LESSON_DISTRIBUTION.REQUEST,
      payload: {
        languageLessonDistributionRequestDto
      }
    }
  },

  accLessonPlanChapterExpand(chapterDto, concepts, previousChapters, authorInputMetadata) {
    return {
      type: autoCourseCreationConstants.ACC_LESSON_PLAN_CHAPTER_EXPAND.REQUEST,
      payload: {
        chapterDto: chapterDto,
        concepts: concepts,
        previousChapters: previousChapters,
        authorInputMetadata: authorInputMetadata
      }
    }
  },
  accLanguageUpdateData(mainElement, attributeToUpdate, updateData) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_UPDATE_DATA.REQUEST,
      payload: {
        mainElement, 
        attributeToUpdate, 
        updateData
      }
    }
  },
  
  accLanguageSuggestImagesForSelected(authorInputMetadata, selectedConcepts) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_IMAGES_FOR_SELECTED.REQUEST,
      payload: {
        authorInputMetadata,
        concepts:selectedConcepts
      }
    }
  },
  
  accLessonPlanGeneration(authorInputMetadata, languageLessonPlanDto) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_LESSON_GENERATION.REQUEST,
      payload: {
        languageLessonPlanDto: languageLessonPlanDto,
        authorInputMetadata: authorInputMetadata
      }
    }
  },
  
  accRecreateSingleLessonPlan(authorInputMetadata, languageLessonPlanDto) {
    return {
      type: autoCourseCreationConstants.ACC_LANGUAGE_RECREATE_SINGLE_LESSON_PLAN.REQUEST,
      payload: {
        languageLessonPlanDto: languageLessonPlanDto,
        authorInputMetadata: authorInputMetadata
      }
    }
  },
  
};