import React from "react";
import {
    Card,
    Typography,
} from '@material-ui/core';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

const styles = theme => ({
    card: {
        boxShadow: '0 12px 20px -10px rgba(165, 165, 165, 0.07), 0 4px 20px 0px rgba(165, 165, 165, 0.04), 0 7px 8px -5px rgba(165, 165, 165, 0.02)',
        transition: 'all .25s ease',
        borderRadius: '10px',
        marginBottom: '28px',
    },
    answerText: {
        marginLeft: '7px',
        color: '#fcfcfc',
    },
    answerCard: {
        marginLeft: '5px',
        display: "flex",
        flexDirection: "column",
      
        borderRadius: 3,
        boxShadow: 'none',
        backgroundColor: '#5fb5ef',
        cursor: 'pointer'  
    }  
});

class ScenarioAnswer extends React.Component {
    state = {
    };

    render() {
        const {classes, user, handleEdit, node,text, index, textAlign = 'left', fontSize = 11, marginTop = 0, fontWeight = 'bold,', organization, metadata, course, usergroup, height} = this.props;  
        return (
            
            <Card 
            className={classes.answerCard} 
            style={{ position: 'absolute', 
                marginTop: marginTop,
                color: 'black', 
                top: this.props.answerPosition, 
                left: '55px', width: '195px', height: height ? height : null}}>
                    <Typography  style={{fontSize: fontSize, fontWeight: fontWeight}} className={classes.answerText}>
                        {text}
                    </Typography>
            </Card>
        );
    }
}

ScenarioAnswer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    const {} = state;
    return {
    
    };
}

const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles, {withTheme: true}),
);

export default enhance(ScenarioAnswer)