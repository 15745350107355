import { asyncRequest } from './asyncRequest'

export const backgroundJobsConstants = {
    GET_TOPIC_JOBS: new asyncRequest('GET_TOPIC_JOBS'),
    UPDATE_TOPIC_JOB: new asyncRequest('UPDATE_TOPIC_JOB'),
    REMOVE_TOPIC_JOB: new asyncRequest('REMOVE_TOPIC_JOB'),
    RESET_JOBS: new asyncRequest('RESET_JOBS'),
    WAIT_FOR_JOBS: new asyncRequest('WAIT_FOR_JOBS'),
    GENERAL_RESET: new asyncRequest('JOBS_GENERAL_RESET'),
};

export const backgroundJobsActions = {
    getTopicJobs(topicId) {
        return {
            type: backgroundJobsConstants.GET_TOPIC_JOBS.REQUEST,
            payload: {
                topicId,
            }
        }
    },
    removeTopicJob(topicId) {
        return {
            type: backgroundJobsConstants.REMOVE_TOPIC_JOB.REQUEST,
            payload: {
                topicId,
            }
        } 
    },
    generalReset() {
        return {
            type: backgroundJobsConstants.GENERAL_RESET.REQUEST,
            payload: {}
        }
    },
    waitForJobs(topicId, l1Id, jobType = 0) {
        return {
            type: backgroundJobsConstants.WAIT_FOR_JOBS.REQUEST,
            payload: {
                topicId,
                l1Id,
                jobType,
            }
        } 
    },
};


