import {courseGroupConstants} from "../actions/coursegroup.actions";
import {authConstants} from "../actions";

const initialState = {
  currentCourseGroup: null,
  creatingCourseGroup: false,
  newlyCreatedCourseGroupId: null,
  deleteCourseGroupFinished: false,
};

export function courseGroup(state = initialState, action) {
  switch (action.type) {
    case courseGroupConstants.GET_COURSE_GROUP.REQUEST:
      return {
        ...state,
        currentCourseGroup: null
      };
    case courseGroupConstants.GET_COURSE_GROUP.SUCCESS:
      return {
        ...state,
        currentCourseGroup: action.data
      };
    case courseGroupConstants.GET_COURSE_GROUP.FAILURE:
      return {
        ...state,
      };

    case courseGroupConstants.CREATE_COURSE_GROUP.REQUEST:
      return {
        ...state,
        currentCourseGroup: null,
        creatingCourseGroup: true
      };
    case courseGroupConstants.CREATE_COURSE_GROUP.SUCCESS:
      return {
        ...state,
        currentCourseGroup: action.data,
        newlyCreatedCourseGroupId: action.data.id,
      };
    case courseGroupConstants.CREATE_COURSE_GROUP.FAILURE:
      return {
        ...state,
        newlyCreatedCourseGroupId: null,
        creatingCourseGroup: false
      };
      
    case courseGroupConstants.CREATE_COURSE_GROUP_RESET.REQUEST:
      return {
        ...state,
        newlyCreatedCourseGroupId: null,
        creatingCourseGroup: false,
      }

    case courseGroupConstants.UPDATE_COURSE_GROUP.REQUEST:
      return {
        ...state,
        currentCourseGroup: null
      };
    case courseGroupConstants.UPDATE_COURSE_GROUP.SUCCESS:
      return {
        ...state,
        currentCourseGroup: action.data
      };
    case courseGroupConstants.UPDATE_COURSE_GROUP.FAILURE:
      return {
        ...state,
      };
    case courseGroupConstants.DELETE_COURSE_GROUP.REQUEST:
      return {
        ...state,
        currentCourseGroup: null
      };
    case courseGroupConstants.DELETE_COURSE_GROUP.SUCCESS:
      return {
        ...state,
        deleteCourseGroupFinished: true
      };
    case courseGroupConstants.DELETE_COURSE_GROUP.FAILURE:
      return {
        ...state,
        deleteCourseGroupFinished: true
      };
    case courseGroupConstants.DELETE_COURSE_GROUP_RESET.REQUEST:
      return {
        ...state,
        deleteCourseGroupFinished: false
      }
      
    case courseGroupConstants.ADD_TOPIC_TO_COURSE_GROUP_SUB_PART.REQUEST:
      return {
        ...state
      }
    case courseGroupConstants.ADD_TOPIC_TO_COURSE_GROUP_SUB_PART.SUCCESS:
      return {
        ...state,
        currentCourseGroup: action.data
      };
    case courseGroupConstants.ADD_TOPIC_TO_COURSE_GROUP_SUB_PART.FAILURE:
      return {
        ...state,
      };  
      
    case courseGroupConstants.DELETE_COURSE_GROUP_SUB_PART_TO_TOPIC_MAPPER.REQUEST:
      return {
        ...state,
      };
    case courseGroupConstants.DELETE_COURSE_GROUP_SUB_PART_TO_TOPIC_MAPPER.SUCCESS:
      return {
        ...state,
        currentCourseGroup: action.data
      };
    case courseGroupConstants.DELETE_COURSE_GROUP_SUB_PART_TO_TOPIC_MAPPER.FAILURE:
      return {
        ...state,
      };
      
    case authConstants.LOGOUT.SUCCESS:
      return initialState;
      
    default:
      return state
  }
}

