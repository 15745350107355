import React, {useState, useRef} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {CircularProgress, Grid, makeStyles} from "@material-ui/core";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';

import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({

}))


const UpcomingFeatures = (props) => {


  let margin = "5px"

  const classes = useStyles();
  return (
    <svg     style={{
      //position: 'absolute', // Position the SVG absolutely
      //right: '-2px', // Move it to the right to overlap the border
      //top: 8, // Align it to the top of the parent container
    }}
    xmlns="http://www.w3.org/2000/svg" width="174" height="33" viewBox="0 0 174 33" fill="none">
<path d="M157.301 32.5L174 0.5H0V32.5H157.301Z" fill="url(#paint0_linear_2906_57387)"/>
<defs>
<linearGradient id="paint0_linear_2906_57387" x1="91" y1="32.5" x2="176.882" y2="13.5856" gradientUnits="userSpaceOnUse">
<stop stop-color="#A0439F"/>
<stop offset="1" stop-color="#AE59AD"/>
</linearGradient>
</defs>
    <path
      d="M16.6986 32L0 0H83V32H16.6986Z"
      fill="url(#paint0_linear_628_9522)"
    />
  
    <text
      x="0"
      y="50%"
      dominant-baseline="middle"
      text-anchor="middle"
      fill="white"
      fontFamily="Rubik"
      fontSize="14"
      fontWeight="500"
lineHeight= "118%"    >
      <tspan x="80px" dy="0">
        Upcoming features
      </tspan>
    </text>
  
  </svg>

  

  )
}


UpcomingFeatures.propTypes = {
  
};


function mapStateToProps(state) {
  const {autoCourseCreation} = state;
  return {
    autoCourseCreation
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingFeatures)