import React from "react";
import {
    Card,
    Typography,
} from '@material-ui/core';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

const styles = theme => ({
    card: {
        boxShadow: '0 12px 20px -10px rgba(165, 165, 165, 0.07), 0 4px 20px 0px rgba(165, 165, 165, 0.04), 0 7px 8px -5px rgba(165, 165, 165, 0.02)',
        transition: 'all .25s ease',
        borderRadius: '10px',
        marginBottom: '28px',
    },
    answerText: {
        fontSize: 11,
        marginLeft: '7px',
        color: 'black',
    },
    answerCard: {
        marginLeft: '0px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: 0,
        boxShadow: 'none',
        backgroundColor: 'white',
        cursor: 'pointer'  
    } ,
    underscoreReplacement: {
        display: 'inline-block',
        background: 'rgba(68, 166, 239, 0.3)',
        width: '8px',
        height: '16px',
        verticalAlign: 'middle',
        //boxShadow: 'inset 0px 2px 0px rgba(102, 67, 160, 0.12)',
      },
});

class ScenarioAnswer extends React.Component {
    state = {
    };

    render() {
        const {classes, user, handleEdit, node, text, index, organization, metadata, course, usergroup, height} = this.props;  
        return (
            <Card className={classes.answerCard} 
                style={{ //position: 'absolute', 
                color: 'black', 
                backgroundColor: 'white',
                paddingTop: '10px',
                paddingBottom: '5px',
                top: 80, 
                 width: '220px',}}
                onClick={() => this.props.answerClick(node, index)}>
           <Typography className={classes.testImageText}  style={{  
                     fontSize: 11,
                     marginLeft: '7px',
                     color: 'black',}}>
                    {text?.split('_').map((part, index) => (
                    <React.Fragment key={index}>
                      {part}
                      {index < text?.split('_').length - 1 && (
                        <span className={classes.underscoreReplacement}></span>
                      )}
                    </React.Fragment>
      ))}</Typography>

            </Card>
        );
    }
}

ScenarioAnswer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    const {} = state;
    return {
    
    };
}

const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles, {withTheme: true}),
);

export default enhance(ScenarioAnswer)