

import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import {ImageFieldType, ScenarioImageSelection, ScenarioNodeType} from "../../../../Logic/ScenarioConstants";
import Dropzone from "react-dropzone";
import {TextField} from "@mui/material";
import ImageSearch from "../Images/ImageSearch";
import ImageLibrary from "../Images/ImageLibrary";
import imageCompression from "browser-image-compression";
import {v4 as uuidv4} from "uuid";
import ReactCrop from 'react-image-crop';
import {FileReferenceImageUsageType} from "../../../../Logic/FileReferenceConstants";

const useStyles = makeStyles(theme => ({

  imageLibraryButton: {
    textTransform: 'none',
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '150px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },

  imageViewer: {
    maxWidth: '150px',
    minWidth: '50px',
    height: 'auto'
  },
  dropZone: {
    position: 'relative',
    marginTop: '10px',
    marginLeft: '10px',
    height: '115px',
    width: '500px',
    paddingRight: '10px',
    color: '#ccc',
    border: 'dashed 2px #ccc',
    borderRadius: '15px',
    backgroundColor: '#f9f9f9',
    transition: 'all 200ms ease-out',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'block',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },

}))



const BackgroundImageSelection = (props) => {

  const classes = useStyles();

  // expected props:
  // props.canEditLesson
  // props.node.scenarioNodeType
  // props.lesson
  // props.updateImageDrop
  // props.scenarioImages
  // props.onUpdatePreviewImage

  const [imageSelectionType, setImageSelectionType] = useState(ScenarioImageSelection.InitialState)

  // image 
  const [src, setSrc] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [alertFileSize, setAlertFileSize] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [fileExtension, setFileExtension] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);
  const [imageAddress, setImageAddress] = useState('');
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const [imageFilename, setImageFilename] = useState(null);

  const imgRef = useRef(null);

  
  // crop related attributes
  const [isCroppedComplete, setIsCroppedComplete] = useState(false);
  const [isCorrectAspectRatio, setIsCorrectAspectRatio] = useState(true);



  const [cropWatch, setCropWatch] = useState({
    unit: '%',
    width: 30,
    aspect: 45 / 64,
  });
  const [cropBrief, setCropBrief] = useState({
    unit: '%',
    width: 30,
    aspect: 270 / 191,
  });
  const [cropListenSpeak, setCropListenSpeak] = useState({
    unit: '%',
    width: 30,
    aspect: 9 / 8,
  },);
  const [cropTestImage, setCropTestImage] = useState({
    unit: '%',
    width: 30,
    aspect: 1 / 1,
  });
  const [cropDefinition, setCropDefinition] = useState({
    unit: '%',
    width: 30,
    aspect: 1 / 1,
  });
    
  
  useEffect(() => {
    if (props.node.backgroundImageUrl?.startsWith("image/file") && props.canEditLesson) {
      
      var fileReferenceId = props.node.backgroundImageUrl.split("image/file/")[1]
      
      let imageTracker = props.scenarioImages.find(x => x.imageReferenceId === fileReferenceId);
      
      if (imageTracker) {
        setImageAddress(imageTracker.imageUrl);  
      }
    }
  }, [])
  
  useEffect(() => {
    // TODO: MORTEN REMOVE IF POSSIBLE TO SET IN INTERACTIVE IMAGE
    var img = new Image();
    let desiredAspectRatio = null
    img.onload = function() {
      switch(props.node.scenarioNodeType) {
        case ScenarioNodeType.Watch:
          desiredAspectRatio = cropWatch.aspect
          break
        case ScenarioNodeType.Listen:
          desiredAspectRatio = cropTestImage.aspect
          break
        case ScenarioNodeType.Speak:
          desiredAspectRatio = cropTestImage.aspect
          break
        case ScenarioNodeType.BranchingMultiChoice:
          desiredAspectRatio = cropTestImage.aspect
          break
        case ScenarioNodeType.Debrief:
          desiredAspectRatio = cropBrief.aspect
          break
        case ScenarioNodeType.Brief:
          desiredAspectRatio = cropBrief.aspect
          break
        case ScenarioNodeType.MultiChoice:
          desiredAspectRatio = cropTestImage.aspect
          break
        case ScenarioNodeType.ListenMultiChoice:
          desiredAspectRatio = cropTestImage.aspect
          break
        default:
          return null
      }
      
      if((this.width / this.height).toFixed(1) !== desiredAspectRatio.toFixed(1)) {
        setFileType("image/jpeg")
        setSrc(imageAddress)
        setIsCorrectAspectRatio(false)
        setIsCroppedComplete(false)
      } else {
        setIsCorrectAspectRatio(true)
      }
    };
    img.src = imageAddress;
  }, [imageAddress]);

  const setNewImageUrl = (imageUrl, croppedComplete) => {
    if (!croppedComplete) {
      return;
    }
    
    let imageResult = {
      type: fileType,
      image: imageFile,
      imageUrl: imageFileUrl,
      imageFileName: imageFilename,
      shouldUpload: true,
      imageFieldType: ImageFieldType.BackgroundImageUrl,
    }

    props.updateImageDrop(imageUrl, props.node.nodeId, imageResult)
  }

  const handleFinishCrop = (event, value) => {
    setIsCroppedComplete(true)
    setIsCorrectAspectRatio(true)

    
    if (imageFileUrl) {
      let imageResult = {
        type: fileType,
        image: imageFile,
        imageUrl: imageFileUrl,
        imageFileName: imageFilename,
        shouldUpload: true,
        imageFieldType: ImageFieldType.BackgroundImageUrl,
        
      }
      props.updateImageDrop(imageFileUrl, props.node.nodeId, imageResult)
    }
  };

  const handleCancelCrop = (event, value) => {
    setIsCroppedComplete(false)
    setSrc(null)
    setImageFile(null)
    setImageFileUrl(null)
  };

  const getCroppedImg = async (image, crop, fileName, state) => {
    const canvasPrimary = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;
    var targetWidth = originWidth,
      targetHeight = originHeight;

    // set canvas size
    canvasPrimary.width = targetWidth;
    canvasPrimary.height = targetHeight;
    const ctx = canvasPrimary.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );
    return new Promise((resolve, reject) => {
      canvasPrimary.toBlob(blob => {

        if (!blob) {
          console.error('Capeesh canvas is empty');
          return;
        }
        blob.name = fileName;
        blob.lastModifiedDate = new Date();
        window.URL.revokeObjectURL(imageFileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        let imageGuid = uuidv4();
        var thefile = new File([blob], imageGuid,{type:fileType, lastModified:new Date()})
        var fileUrl = window.URL.createObjectURL(thefile)
        setImageFile(thefile)
        setImageFileUrl(fileUrl)
        resolve(fileUrl);
      });
    });
  }


  const onCropChange = (crop, percentCrop) => {
    setCropWatch(crop)
    setCropListenSpeak(crop)
    setCropBrief(crop)
    setCropDefinition(crop)
    setCropTestImage(crop)
  };

  const handleImageFromExternalSource = (image, imageDescription=null) => {
    let imageFile = image[0]
    var blobUrlLocal = URL.createObjectURL(image);
    let imageGuid = uuidv4();
    setSrc(blobUrlLocal)
    setImageFile(imageFile)
    setImageFileUrl(blobUrlLocal)
    setImageAddress(blobUrlLocal)
    setReadyToLoad(true)
    switch(image.type) {
      case "image/jpeg":
        setFileExtension('jpeg')
        break;
      case "image/png":
        setFileExtension('png')
        break;
    }
    var finalisedFile = new File([image], imageGuid,{type: image.type, lastModified:new Date()})
    setUploadedFileName(finalisedFile.name.replace(/\.[^/.]+$/, ""))
    setIsCroppedComplete(false)
    setFileType(finalisedFile.type)
    if (!imageFilename && imageDescription !== null) {
      setImageFilename(imageDescription);
    } 
  };

  const onImageLoaded = image => {
    imgRef.current = image;
  };

  const onCropComplete = crop => {
    const cropListenSpeakHeight = crop.height / 2;
    let cropListenSpeak =
      {...crop,
        height: cropListenSpeakHeight,
      }
    makeClientCrop(crop, true, 'newFile.png');
  };

  const makeClientCrop = async (crop, isWatchStage, fileName) => {
    if (imgRef.current  && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imgRef.current,
        crop,
        fileName,
        this
      );
      if (isWatchStage) {
        setCroppedImageUrl(croppedImageUrl)
      }
      props.onUpdatePreviewImage(croppedImageUrl);
    }
  };

  const onImageDrop = async (files) => {
    const imageFile = files[0];
    const options = {
      maxSizeMB: 0.5,
      //maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      if(imageFile.size / 1024 / 1024 > 1.5) {
        // is compressing a fairly large file, might take a while so notify user
        setAlertFileSize(true)
      }
      const compressedFile = await imageCompression(imageFile, options);
      setAlertFileSize(false)
      // await uploadToServer(compressedFile); // write your own logic
      setUploadedFileName(files[0].name.replace(/\.[^/.]+$/, ""))
      setIsCroppedComplete(false)
      setIsCorrectAspectRatio(false)
      if (files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setSrc(reader.result)//this.setState({ src: reader.result })
        );
        reader.readAsDataURL(compressedFile);
        setFileType(files[0].type)
        switch(files[0].type) {
          case "image/jpeg":
            setFileExtension('jpeg')
            break;
          case "image/png":
            setFileExtension('png')
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }


  const handleImageDropFromLibrary = (blobUrl, nodeId, fileReferenceImageUsageType, results) => {

    var needsToCrop = true;
    
    // need to figure out if the image needs to be cropped, if not, just set the image on the node and update preview
    if (fileReferenceImageUsageType === fileReferenceImageUsageType.NotDefined) {
      needsToCrop = false;
    }
    if (props.node.scenarioNodeType === ScenarioNodeType.Watch && fileReferenceImageUsageType === FileReferenceImageUsageType.WatchStageBackground) {
      needsToCrop = false;
    }
    else if (props.node.scenarioNodeType === ScenarioNodeType.Listen || props.node.scenarioNodeType === ScenarioNodeType.MultiChoice || props.node.scenarioNodeType === ScenarioNodeType.Speak) {
      if  (fileReferenceImageUsageType === FileReferenceImageUsageType.ListenStageBackground || fileReferenceImageUsageType === FileReferenceImageUsageType.TestImage || fileReferenceImageUsageType === FileReferenceImageUsageType.SpeakStageBackground)
      needsToCrop = false;
    }
    else if ((props.node.scenarioNodeType === ScenarioNodeType.Speak || props.node.scenarioNodeType === ScenarioNodeType.BranchingMultiChoice) && (fileReferenceImageUsageType === FileReferenceImageUsageType.ListenStageBackground || fileReferenceImageUsageType === FileReferenceImageUsageType.TestImage || fileReferenceImageUsageType === FileReferenceImageUsageType.SpeakStageBackground)) {
      needsToCrop = false;
    }
    else if ((props.node.scenarioNodeType === ScenarioNodeType.Brief || props.node.scenarioNodeType === ScenarioNodeType.Debrief) && fileReferenceImageUsageType === FileReferenceImageUsageType.BriefStageBackground) {
      needsToCrop = false;
    }
    
    if (needsToCrop) {
      setIsCroppedComplete(false);
      setSrc(results.imageUrl)
      setImageFile(results.image)
      setImageFileUrl(results.imageUrl)
      setImageAddress(results.imageUrl)
      setReadyToLoad(true);
      setFileType(results.type);
    }
    else {
      setIsCroppedComplete(true);

      props.onUpdatePreviewImage(results.imageUrl)
      
      setNewImageUrl(blobUrl, true);
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} >
        <Typography
          variant='body1'
          style={{
            boxShadow: 'none',
            color: 'black',
            fontFamily: 'Lato',
            fontSize: 22,
            fontWeight: 'bold'

          }}>
          Image
        </Typography>
        {
          isCorrectAspectRatio === false &&
          <Typography
            variant='h6'
            style={{
              //fontSize: 36,
              //color: '#8E8E93',
              fontFamily: 'Lato',
              marginBottom: '10px'
            }}>
            The image aspect ratio needs updating to fit the stage type.
          </Typography>
        }
        <Typography
          variant='body1'
          style={{
            boxShadow: 'none',
            letterSpacing: '0.02em',
            fontSize: 14,
            color: '#8E8E93',
            fontFamily: 'Lato',
          }}>
          {src && isCorrectAspectRatio === false && isCroppedComplete === false ? 'Crop the image to the right shape, give it a name and click "finish crop".': 'Add an image' }
        </Typography>
        {
          alertFileSize &&
          <Typography
            variant='body1'
            style={{
              boxShadow: 'none',
              letterSpacing: '0.02em',
              fontSize: 14,
              color: '#8E8E93',
              fontFamily: 'Lato',
            }}>
            Your image has a large file size. Please wait whilst we compress it...
          </Typography>
        }

        {
          (src === null || (src && (isCroppedComplete === true || isCroppedComplete === false && isCorrectAspectRatio === true))) &&
          <Grid container style={{marginTop: '10px'}} justifyContent="space-evenly" alignItems="center" spacing={2}>
            <Grid item xs={4} sm={4} md={4} style={{ textAlign: 'center', alignItems: 'center',justifyContent: 'center'}}>
              <Button
                onClick={() => setImageSelectionType(ScenarioImageSelection.Upload)}
                disabled={!props.canEditLesson}
                style={
                  imageSelectionType === ScenarioImageSelection.Upload ?
                    {
                      backgroundColor: '#143349',
                      fontColor: 'white',
                      color: 'white',
                    } :
                    {
                      backgroundColor: 'white',
                      fontColor: 'black',
                      color: 'black',
                    }
                }
                className={classes.imageLibraryButton}
                variant={imageSelectionType === ScenarioImageSelection.Upload ? "contained": "outlined"}>
                Upload image
              </Button>
            </Grid>
            <Grid item xs={4} sm={4} md={4} style={{ textAlign: 'center', alignItems: 'center',justifyContent: 'center'}}>
              <Button
                onClick={() => setImageSelectionType(ScenarioImageSelection.Search)}
                disabled={!props.canEditLesson}
                style={
                  imageSelectionType === ScenarioImageSelection.Search ?
                    {
                      backgroundColor: '#143349',
                      fontColor: 'white',
                      color: 'white',
                    } :
                    {
                      backgroundColor: 'white',
                      fontColor: 'black',
                      color: 'black',
                    }
                }
                className={classes.imageLibraryButton}
                variant='outlined'>
                Search
              </Button>
            </Grid>

            <Grid item xs={4} sm={4} md={4} style={{ textAlign: 'center', alignItems: 'center',justifyContent: 'center'}}>
              <Button
                onClick={() => setImageSelectionType(ScenarioImageSelection.Library)}
                disabled={!props.canEditLesson}
                style={
                  imageSelectionType === ScenarioImageSelection.Library ?
                    {
                      backgroundColor: '#143349',
                      fontColor: 'white',
                      color: 'white',
                    } :
                    {
                      backgroundColor: 'white',
                      fontColor: 'black',
                      color: 'black',
                    }
                }
                className={classes.imageLibraryButton}
                variant='outlined'>
                Image library
              </Button>
            </Grid>

          </Grid>
        }
        {
          imageSelectionType === ScenarioImageSelection.Upload &&
          <Grid item xs={12} sm={12} md={12} className={classes.dropzoneContainer}>
            <div style={{marginTop: '10px', textAlign: 'center', alignItems: 'center',justifyContent: 'center'}}>
              {(src === null || (src && isCroppedComplete === true)) && props.canEditLesson &&
              <Dropzone
                disabled={!props.canEditLesson}
                accept="image/jpeg,image/png"
                onDrop={(file) => onImageDrop(file)}
              >
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()}
                         className={classes.dropZone}
                    >
                      <input {...getInputProps()} />
                      {
                        <Grid container spacing={1} >
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="body1" style={{color:'#35323C', marginTop: '10px'}}  >
                              Drag your voice image files here
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="body1" style={{color:'#35323C'}}  >
                              Or
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Button
                              variant='outlined'
                              size='small'
                              style={{textTransform: 'none',  fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px', marginBottom: '15px'}}>
                              Browse files
                            </Button>
                          </Grid>
                        </Grid >
                      }
                    </div>
                  </section>
                )}
              </Dropzone>
              }
            </div>
          </Grid>
        }
        <React.Fragment>
          <Grid item xs={6} sm={6} md={6} className={classes.dropzoneContainer}>
            {
              src && !!!isCroppedComplete && isCorrectAspectRatio === false && (
                <React.Fragment>
                  <ReactCrop
                    className={classes.imageViewer}
                    src={src}
                    //crop={cropListenSpeak}
                    crop={props.node.scenarioNodeType === 1 ? cropWatch : (props.node.scenarioNodeType === ScenarioNodeType.Debrief || props.node.scenarioNodeType === ScenarioNodeType.Brief ) ? cropBrief : props.node.scenarioNodeType === ScenarioNodeType.TestDefinition ? cropDefinition : cropTestImage}
                    ruleOfThirds
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                  />
                  <Typography style={{fontFamily: 'Lato', fontWeight: 'bold'}}  className={classes.answerHeaders} variant='body1'>
                    Image description
                  </Typography>
                  <TextField
                    placeholder={'Choose a desciption'}
                    value={imageFilename}
                    disabled={!props.canEditLesson}
                    onChange={(event) => setImageFilename(event.target.value)}
                    style={{borderRadius: '10px', minWidth: '500px'}}
                    className={classes.textField}
                    InputProps={{ disableUnderline: true, shrink: "false"  }}
                    variant="filled" />
                </React.Fragment>
              )
            }
          </Grid>
        </React.Fragment>

        <Grid item xs={12} sm={12} md={12} className={classes.dropzoneContainer}>
          {
            src && !!!isCroppedComplete && isCorrectAspectRatio === false && (
              <React.Fragment>
                <Button onClick={() => handleFinishCrop()}  style={{backgroundColor: imageFilename === null || imageFilename === "" ? 'grey': '#143349', fontColor: 'white', color: 'white', marginRight: '5px'}} variant='outlined' disabled={imageFilename === null || imageFilename === ""} >Finish Crop</Button>
                <Button onClick={() => handleCancelCrop()}  style={{backgroundColor: '#143349', fontColor: 'white', color: 'white'}} variant='outlined'   >Cancel</Button>
              </React.Fragment>
            )}
        </Grid>

        {
          imageSelectionType === ScenarioImageSelection.Search &&
          <div style={{marginTop: '10px', textAlign: 'center', alignItems: 'center',justifyContent: 'center'}}>
            <ImageSearch onSelectImage={handleImageFromExternalSource}/>
          </div>
        }
        {
          imageSelectionType === ScenarioImageSelection.Library &&
          <div style={{marginTop: '20px', textAlign: 'center', alignItems: 'center',justifyContent: 'center'}}>
            <ImageLibrary
              node={props.node}
              updateImageDrop={handleImageDropFromLibrary}
              lesson={props.lesson}
              scenarioImages={props.scenarioImages}/>
          </div>
        }
      </Grid>
    </React.Fragment>
  );
}


BackgroundImageSelection.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(BackgroundImageSelection)