import {Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {courseGroupActions, snackbarActions, organizationActions} from "../../../_state/actions";
import {Button} from "@mui/material";
import {configWithAuth, history} from "../../../_state/helpers";

import {FormattedMessage} from "react-intl";
import CapeeshStyledModal from "../../../Components/CapeeshStyledModal";
import Dropzone from "react-dropzone";
import ImageIcon from "@material-ui/icons/Image";
import {put} from "redux-saga/effects";
import {makeGetRequest, makePostRequest} from "../../../_state/helpers/api";
import {getFlagAssetForLanguageId} from "../../../Logic/FlagUnicodeLogic";
import {CapeeshColors} from "../../../assets/ColorPalette";
import {EditOutlined} from "@mui/icons-material";
import TopicPreviewCard from "./TopicCards/TopicPreviewCard";
import CreateNewTopicComponent from "./CreateNewModules/CreateNewTopicModule";
import {CourseGroupType, ImageCropType} from "../../../Logic/CourseGroupConstants";
import CreateNewSubPartComponent from "./CreateNewModules/CreateNewSubPartModule";
import TextFieldWithLanguageSelector from "../Scenarios/ScenarioComponents/TextFieldWithLanguageSelector";
import ImageUploadWithCrop from "../../../Components/ImageUpload/ImageUploadWithCrop";

const useStyles = makeStyles(theme => ({
  newCourseIcon: {
    height: '50px',
    width: '50px',
    margin: 'auto auto'
  },
  dropZone: {
    position: 'relative',
    height: '100px',
    color: '#ccc',
    border: 'dashed 2px #ccc',
    borderRadius: '15px',
    backgroundColor: '#f9f9f9',
    margin: '15px auto',
    padding: 15,
    transition: 'all 200ms ease-out',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '200px',
    display: 'block',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },

  capHeaderBorder: {
    margiTop: '20px',
    display: "flex",
    flexWrap: 'nowrap',
    flexGrow: 1,
    width: "100%",
    borderTop: "4px solid gray",
  },
  
}));


const CourseGroupOverview = (props) => {
  const classes = useStyles();
  const {computedMatch} = props;
  
  // create new sub part attriburtes
  const [showCreateNewSubPart, setShowCreateNewSubPart] = useState(false);
  const [newSubPartName, setNewSubPartName] = useState("");
  
  
  // create new topic
  const [showCreateNewTopic, setShowCreateNewTopic] = useState(false);
  const [newTopicSubPartId, setNewTopicSubPartId] = useState("");
  const [newTopicName, setNewTopicName] = useState("");
  
  // show delete topic
  const [deleteCourseGroupSubPartToTopicMapperDto, setDeleteCourseGroupSubPartToTopicMapperDto] = useState(null);
  const [showDeleteTopicConfirmation, setShowDeleteTopicConfirmation] = useState(false);
  
  // attach existing topic
  const [showAttachExistingTopicModal, setShowAttachExistingTopicModal] = useState(false);
  const [attachExistingTopicSubPartId, setAttachExistingTopicSubPartId] = useState("");
  
  const [courseGroup, setCourseGroup] = useState(null);
  
  // delete course group.. 
  const [showDeleteCourseGroupConfirmation, setShowDeleteCourseGroupConfirmation] = useState(false);
  
  // course group image

  const [showImageUpload, setShowImageUpload] = useState(false);
  const [courseGroupImageData, setCourseGroupImageData] = useState(null);
  
  // edit pane
  const [showEditPane, setShowEditPane] = useState(false);
  
  // edit state
  const [tempCourseGroupName, setTempCourseGroupName] = useState("")
  const [tempCourseGroupDescription, setTempCourseGroupDescription] = useState("");
  const [tempCourseGroupImageData, setTempCourseGroupImageData] = useState("");
  const [tempCourseGroupImageUrl, setTempCourseGroupImageUrl] = useState("");
  const [tempCourseGroupNewImageFileReferenceId, setTempCourseGroupNewImageFileReferenceId] = useState("");
  
  useEffect(() => {
    const courseGroupId = computedMatch.params.courseGroupId;
    const {dispatch} = props;
    dispatch(courseGroupActions.getCourseGroup(courseGroupId));
window.scrollTo(0, 0);
    
  }, []);
  
  useEffect(()  => {
    if (!props.courseGroup.currentCourseGroup) {
      return;
    }
    
    const courseGroupId = computedMatch.params.courseGroupId;
    if (props.courseGroup.currentCourseGroup?.id === courseGroupId) {
      setCourseGroup(props.courseGroup.currentCourseGroup);

      if (props.courseGroup.currentCourseGroup.imageUrl) {
        var fileReferenceId = props.courseGroup.currentCourseGroup.imageUrl.split("image/file/")[1]
        makeGetRequest(`admin/organization/${props.organization.selectedOrganization}/image/file/reference/${fileReferenceId}/base64`, configWithAuth())
          .then(result => {
            setCourseGroupImageData(result.data);
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
    props.dispatch(organizationActions.getAvailableCourseGroups(props.organization.selectedOrganization))

  }, [props.courseGroup.currentCourseGroup])
  
  useEffect(() => {
    if (props.courseGroup.deleteCourseGroupFinished) {
      history.push("/creator/")
      props.dispatch(courseGroupActions.deleteCourseGroupReset())
    }
  }, [props.courseGroup.deleteCourseGroupFinished])
  
  const handleDeleteTopicFromCourseGroup = (courseGroupSubPartToTopicMapperDto) => {
    setDeleteCourseGroupSubPartToTopicMapperDto(courseGroupSubPartToTopicMapperDto);
    setShowDeleteTopicConfirmation(true);
  }
  
  const handleTopicClicked = (topicId) => {
    history.push("/creator/course/group/" + courseGroup.id + "/topic/" + topicId);
  }
  
  const handleEditSubPartName = (subPartId, newSubPartName) => {
    
  }
  
  const handleCancelAddingNewSubPart = () => {
    setShowCreateNewSubPart(false);
    setNewSubPartName("");
  }
  const handleStartAddingNewSubPart = () => {
    setShowCreateNewSubPart(true);
    setNewSubPartName("");
  }
  
  const handleCreateSubPart = (subPartName) => {
    let newSubPart = {
      name: subPartName,
      courseGroupSubPartToTopicMapperDtos: []
    }
    if (!courseGroup.courseGroupSubPartDtos) {
      courseGroup.courseGroupSubPartDtos = [
        newSubPart
      ]
    } else {
      courseGroup.courseGroupSubPartDtos.push(newSubPart)
    }
    
    props.dispatch(courseGroupActions.updateCourseGroup(courseGroup));
    setNewSubPartName("");
    setShowCreateNewSubPart(false);
  }
  
  
  const handleUpdateCourseGroup = () => {
    // courseGroup.imageFileReferenceId = tempCourseGroupImageId;
    courseGroup.name = tempCourseGroupName;
    courseGroup.description = tempCourseGroupDescription
    if (tempCourseGroupNewImageFileReferenceId) {
      courseGroup.imageFileReferenceId = tempCourseGroupNewImageFileReferenceId;
    }
    props.dispatch(courseGroupActions.updateCourseGroup(courseGroup));
    
    setShowEditPane(false);
  }
  
  const handleDeleteCourseGroup = () => {
    setShowDeleteCourseGroupConfirmation(true);
  }
  
  const handleDeleteCourseGroupConfirmed = () => {
    props.dispatch(courseGroupActions.deleteCourseGroup(courseGroup.id));
  }

  const handleDeleteTopicFromCourseGroupConfirmed = () => {
    props.dispatch(courseGroupActions.deleteCourseGroupSubPartToTopicMapper(courseGroup.id, deleteCourseGroupSubPartToTopicMapperDto.courseGroupSubPartId, deleteCourseGroupSubPartToTopicMapperDto.id));
    setShowDeleteTopicConfirmation(false);
  }
  
  const handleDeleteCourseGroupCancelled = () => {
    setShowDeleteCourseGroupConfirmation(false);
  }

  const handleDeleteTopicFromCourseGroupCancelled = () => {
    setShowDeleteTopicConfirmation(false);
  }
  
  const handleCancelAddingNewTopic = () => {
    setShowCreateNewTopic(false);
    setNewTopicName("");
    setNewTopicSubPartId("");
  }
  
  const handleStartAddingNewTopic = (subPartId) => {
    setShowCreateNewTopic(true);
    setNewTopicSubPartId(subPartId);
    setNewTopicName("");
  }
  
  const handleShowAttachExistingTopic = (subPartId) => {
    setAttachExistingTopicSubPartId(subPartId);
    setShowAttachExistingTopicModal(true);
    
  }
  
  const handleCancelAttachExistingTopic = () => {
    setAttachExistingTopicSubPartId("");
    setShowAttachExistingTopicModal(false);
  }
  
  const handleAttachExistingTopic = (topicId) => {

    let subPartToTopicMapper = {
      courseGroupSubPartId: attachExistingTopicSubPartId,
      topicId: topicId
    }

    courseGroup.courseGroupSubPartDtos.forEach(x => {
        if (x.id === attachExistingTopicSubPartId) {
          if (!x.courseGroupSubPartToTopicMapperDtos) {
            x.courseGroupSubPartToTopicMapperDtos = [subPartToTopicMapper]
          } else {
            x.courseGroupSubPartToTopicMapperDtos.push(subPartToTopicMapper)
          }
        }
      }
    )
    props.dispatch(courseGroupActions.updateCourseGroup(courseGroup));
    
  }
  
  const handleCreateTopic = (topicName) => {
    if (newTopicSubPartId === "" || topicName === "") {
      return;
    }
    
    props.dispatch(courseGroupActions.addTopicToCourseGroupSubPart(topicName, newTopicSubPartId, courseGroup))
    setShowCreateNewTopic(false);
    setNewTopicSubPartId("");
    setNewTopicName("");
    
  }
  
  const handleCreateTopicSingleSubPart = (subPartId) => {
    setNewTopicSubPartId(subPartId);
    setShowCreateNewTopic(true);
  }
  
  

  const onDropImage = (fileUploadResult) => {
  
    let organizationId = props.organization.selectedOrganization;
    const formData = new FormData();
    formData.append(fileUploadResult.imageFileName, fileUploadResult.image);

    // Upload Image
    makePostRequest("admin/organization/" + organizationId + "/image/?sharable=true", formData, configWithAuth('multipart/form-data'))
      .then(result => {
        const imageFileReferenceId = result.data["id"]
        
        // Get uploaded Image.
        makeGetRequest("admin/organization/" + organizationId + "/image/file/reference/" + imageFileReferenceId + "/base64", configWithAuth())
          .then(result => {
            setCourseGroupImageData(result.data);
            setTempCourseGroupImageUrl('image/file/' + imageFileReferenceId);
            setTempCourseGroupNewImageFileReferenceId(imageFileReferenceId)
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
    
    setShowImageUpload(false);
    
  };
  const handleShowEditPane = (shouldEdit) => {
    console.log("click  ")
    if (shouldEdit) {
      setTempCourseGroupName(courseGroup.name);
      setTempCourseGroupDescription(courseGroup.description);
      setTempCourseGroupImageData(courseGroupImageData);
      setTempCourseGroupImageUrl(courseGroup.imageUrl);
    }
    setShowEditPane(shouldEdit);
  }
  
  return (
    <div>

      {
        showImageUpload &&
        <ImageUploadWithCrop
          open={showImageUpload}
          updateImageDrop={onDropImage}
          aspectRatioType={ImageCropType.CourseGroup}
          onCancel={() => setShowImageUpload(false)}
          title={"Upload new course image"}
        />
      }
      
      {
        showCreateNewTopic &&

        <CreateNewTopicComponent
          onCreateTopic={handleCreateTopic}
          onCancel={() => setShowCreateNewTopic(false)}
          open={showCreateNewTopic}
        />
      }
      
      {
        showCreateNewSubPart &&
        <CreateNewSubPartComponent
          onCreateSubPart={handleCreateSubPart}
          onCancel={() => setShowCreateNewSubPart(false)}
          open={showCreateNewSubPart}
          />
      }
      
      {
        showDeleteCourseGroupConfirmation &&
        <CapeeshStyledModal
          open={showDeleteCourseGroupConfirmation}
          textTitle={<FormattedMessage id="globalwords.deleteconfirmation" defaultMessage="Delete confirmation"/>}
          textLine1={<FormattedMessage id="creator.course.group.delete.course.group" defaultMessage="Do you really want to delete this course?"/>}
          onButton2={() => handleDeleteCourseGroupConfirmed()}
          onButton1={() => handleDeleteCourseGroupCancelled()}
          button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel"/>}
          button1Variant={'outlined'}
          button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes"/>}
          onClose={() => handleDeleteCourseGroupCancelled()}
        />
      }
      
      {
        showDeleteTopicConfirmation &&
        <CapeeshStyledModal
          open={showDeleteTopicConfirmation}
          textTitle={<FormattedMessage id="globalwords.deleteconfirmation" defaultMessage="Delete confirmation"/>}
          textLine1={<FormattedMessage id="creator.course.group.delete.course.group" defaultMessage="Do you really want to delete this topic from the course? The course will not be deleted, only removed from the course."/>}
          onButton2={() => handleDeleteTopicFromCourseGroupConfirmed()}
          onButton1={() => handleDeleteTopicFromCourseGroupCancelled()}
          button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel"/>}
          button1Variant={'outlined'}
          button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes"/>}
          onClose={() => handleDeleteTopicFromCourseGroupCancelled()}
        />  
      }


      <Grid container>
        <Grid container style={{backgroundColor: 'white', padding: '20px'}}>
          <Grid item xs={8} style={{
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left'
          }}>
            <Typography variant='h4'  style={{paddingLeft: '180px', display: 'inline-block', color: CapeeshColors.DeepBlue}}>
              Course: {courseGroup?.name}
            </Typography>
          </Grid>

          <Grid item xs={4} style={{
            display: 'flex',
            alignItems: 'right',
            justifyContent: 'right',
          }}>
            <Button
              className={classes.leftSideButtons}
              variant={"contained"}
              disabled={!courseGroup}
              style={{
                background: CapeeshColors.Blue,
                color: 'white',
                height: '40px', 
                marginRight: '15px',
                textTransform: 'none'
                
              }}
              onClick={() => handleShowEditPane(!showEditPane)}
            >
              <FormattedMessage id="dashboard.creator.button.title" defaultMessage={!showEditPane ? "Edit" : "Finish editing"}/>
              <EditOutlined sx={{marginLeft: '5px', fontSize: '15px', color: 'white'}}/>
            </Button>

          </Grid>
        </Grid>
        
        {
          showEditPane && courseGroup &&
          <div style={{paddingLeft: '200px'}}>
          <Grid container>
          <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                Course language {props.metadata.metadata?.languages?.find(x => x.id === courseGroup.l2Id).name}
                <img width="32" height="24" style={{marginLeft: '7px', marginRight: '15px'}} src={getFlagAssetForLanguageId(courseGroup.l2Id)}/>

                Default help provided in {props.metadata.metadata?.languages?.find(x => x.id === courseGroup.l1Id).name}
                <img width="32" height="24" style={{marginTop: '5px', marginLeft: '7px'}} src={getFlagAssetForLanguageId(courseGroup.l1Id)}/>
              </Typography>
            </Grid>
            
            <Grid item xs={12} style={{marginTop: '15px', marginBottom:'10px'}}>
              <Typography style={{width:"200px", fontFamily: 'Lato', fontSize: '20px', textAlign: "left", marginLeft:'1px'}}>
                Course Name
              </Typography>
            </Grid>
            <Grid item xs={12}>
              
              <TextFieldWithLanguageSelector
                text={tempCourseGroupName}
                // fullWidth={true}
                onChange={(event) => setTempCourseGroupName(event.target.value)}
                l2Id={null}
                l1Id={null}
                soloLanguageId={courseGroup.l2Id}
                canChangeLanguage={false}
              />
              
            </Grid>

            <Grid item xs={12} style={{marginTop: '15px',  marginBottom:'10px'}}>
              <Typography style={{width:"300px", fontFamily: 'Lato', fontSize: '20px', textAlign: "left", marginLeft:'1px'}}>
                Course Description
              </Typography>
            </Grid>
            <Grid item xs={12}>

              <TextFieldWithLanguageSelector
                text={tempCourseGroupDescription}
                //width={'450px'}
                onChange={(event) => setTempCourseGroupDescription(event.target.value)}
                l2Id={null}
                l1Id={null}
                soloLanguageId={courseGroup.l2Id}
                canChangeLanguage={false}
              />
            </Grid>
            </Grid>
            </Grid>
            <Grid item xs={6} >
            <Grid container>

            <Grid item xs={12} style={{}}>
              <Typography style={{fontFamily: 'Lato', fontSize: '20px', textAlign: "left", marginLeft:'1px'}}>
                Upload a new image
              </Typography>
              <Button id='settings-button'
                      variant={'contained'}
                      style={{marginBottom: '15px', textTransform: 'none',}}
                      onClick={() => (setShowImageUpload(!showImageUpload))}
              >
                Upload course image
              </Button>
            </Grid>
            <Grid item xs={12} style={{marginTop: '15px'}}>
              <Typography style={{fontFamily: 'Lato', fontSize: '20px', textAlign: "left", marginBottom: '10px', marginLeft:'1px'}}>
                Current background image
              </Typography>
              {
                courseGroupImageData ?
                <div key={courseGroupImageData}>
                  <img src={courseGroupImageData} style={{borderRadius: '10px'}} height={160}/>
                </div>
                :
              <Typography variant='h4'style={{ fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '23px'}}>
                No image has been uploaded
              </Typography>
              }
              
            </Grid>


              </Grid>

            </Grid>
            </Grid>
            <Grid container style={{marginTop: '40px'}}>
            <Grid item xs={6} style={{ marginBottom: '15px', display: 'flex'}}>
              <Button 
                disabled={courseGroup.name === tempCourseGroupName && courseGroup.description === tempCourseGroupDescription && courseGroup.imageUrl === tempCourseGroupImageUrl} 
                style={{textTransform: 'none', float: 'left', background:CapeeshColors.Green}}
                variant={"contained"}
                onClick={() => handleUpdateCourseGroup()}>
                  Save changes                
              </Button>
              
              <Button
                style={{textTransform: 'none', float: 'left', marginLeft: '40px', background:CapeeshColors.Orange}}
                variant={"contained"}
                onClick={() => setShowEditPane(false)}>
                Cancel
              </Button>
              </Grid>
              <Grid item xs={6} style={{}}>
              {
                courseGroup.courseGroupType === CourseGroupType.NotDefined &&
                <div
                  style={{ color: 'red', textDecorationLine:'underline', cursor: 'pointer'}}
                  onClick={() => handleDeleteCourseGroup()}>
                  Delete this course
                </div>  
              
              }
              </Grid>
              </Grid>
          </div>
        }
        
        <Grid item xs={12} className={classes.capHeaderBorder}>

        </Grid>
        
        <Grid item xs={12}>
          <Grid container> 
            
            <Grid item xs={12} style={{
              backgroundColor: CapeeshColors.EggShell1,
              backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(238, 237, 234  ) 1px, transparent 0)',
              backgroundSize: '12px 12px',
            }}>
              <Grid container style={{marginTop:'20px', paddingLeft:'200px'}}>
                <Grid item xs={8}>
                  <Typography variant='h4'style={{ display: 'inline-block', color: CapeeshColors.DeepBlue}}>
                    Topics 
                  </Typography>
              
                  <Grid item xs={12} style={{marginBottom: '20px', marginTop: '10px'}}>
                    <Typography variant="h4"  style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '23px', background: 'white', backgroundColor:'white', borderRadius: '10px', padding: '20px',}}>
                      The list of topics within the {courseGroup?.name} course and listed below. {courseGroup?.courseGroupSubPartDtos?.length > 1  && "The topics are split into sections."}
                    </Typography>
                  </Grid>
                
                </Grid>
                <Grid item xs={4} style={{
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: 'right'
                }}>
                  {/*{*/}
                  {/*  !(courseGroup?.courseGroupSubPartDtos?.length === 1 && courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.length === 0) &&*/}
                  {/*  <Button*/}
                  {/*    className={classes.leftSideButtons}*/}
                  {/*    variant={"contained"}*/}
                  {/*    style={{*/}
                  {/*      background: CapeeshColors.Green,*/}
                  {/*      color: 'white',*/}
                  {/*      marginRight: '15px',*/}
                  {/*      textTransform: 'none'*/}
                  {/*    }}*/}
                  {/*    onClick={() => setShowCreateNewSubPart(true)}*/}
                  {/*  >*/}
                  {/*    <FormattedMessage id="dashboard.creator.section.new" defaultMessage="Create section"/>*/}
                  {/*  </Button>*/}
                  {/*}*/}
                  
                  {
                    courseGroup?.courseGroupSubPartDtos?.length < 2 &&
                    <React.Fragment>
                      <Button
                        className={classes.leftSideButtons}
                        variant={"contained"}
                        style={{
                          background: CapeeshColors.Green,
                          color: 'white',
                          marginRight: '15px',
                          textTransform: 'none',
                          height: '40px'

                        }}
                        onClick={() => handleCreateTopicSingleSubPart(courseGroup?.courseGroupSubPartDtos[0].id)}
                      >
                        <FormattedMessage id="dashboard.creator.newtopic.button.title" defaultMessage="Create topic"/>
                      </Button>
                      
                      
                    </React.Fragment>
                    
                  }
                </Grid>
              </Grid>
              
              <Grid item xs={12} style={{marginLeft:'200px', marginRight: '200px', display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "stretch",}}>
                
                {
                  courseGroup?.courseGroupSubPartDtos?.length === 1 &&
                  <div style={{marginTop:courseGroup?.courseGroupSubPartDtos?.length > 1 ? '30px' : '0px'}}>
                    {
                      courseGroup.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos.map((topicMapper, index2) => {
                        return (
                          <TopicPreviewCard
                            key={index2}
                            courseGroupSubPartToTopicMapperDto={topicMapper}
                            openTopic={handleTopicClicked}
                            onDeleteTopicFromCourseGroup={handleDeleteTopicFromCourseGroup}
                          />
                        )
                      })  
                    }

                  </div>
                  
                }
                  {courseGroup?.courseGroupSubPartDtos[0].courseGroupSubPartToTopicMapperDtos?.length === 0 &&
                      <Typography variant='h4'style={{ fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '23px'}}>
                        No courses have been created
                      </Typography>
                    }
 
                {
                  courseGroup?.courseGroupSubPartDtos?.length > 1 &&
                  courseGroup.courseGroupSubPartDtos.map((subPart, index) => {
                    return (
                      <React.Fragment >
                        <Grid container style={{marginTop: '35px'}}>
                          <Grid item xs={8}>
                            <Typography style={{ fontFamily:'Lato', marginBottom:'7px', fontSize: '25px', fontWeight:'bold', display: 'inline-block', color: CapeeshColors.DeepBlue}}>
                              {subPart.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} style={{
                            display: 'flex',
                            alignItems: 'right',
                            justifyContent: 'right',
                            marginBottom: '10px'
                          }}>
                            <Button
                              className={classes.leftSideButtons}
                              variant={"contained"}
                              style={{
                                background: CapeeshColors.Blue,
                                color: 'white',
                                marginRight: '15px',
                                minHeight: '30px',
                                textTransform: 'none'
                              }}
                              onClick={() => handleCreateTopicSingleSubPart(subPart?.id)}
                            >
                              <FormattedMessage id="dashboard.creator.newtopic.button.title" defaultMessage={"Create topic in " + subPart.name}/>
                            </Button>
                          </Grid>
                        </Grid>
                        {subPart.courseGroupSubPartToTopicMapperDtos.length === 0 && 
                          <Typography variant='h4'style={{ fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '23px'}}>
                            No courses have been created within the {subPart.name} section of the course.
                          </Typography>}
                        {
                          subPart.courseGroupSubPartToTopicMapperDtos.map((topicMapper, index2) => {
                            return (
                              <TopicPreviewCard
                                key={index2}
                                courseGroupSubPartToTopicMapperDto={topicMapper}
                                openTopic={handleTopicClicked}
                                onDeleteTopicFromCourseGroup={handleDeleteTopicFromCourseGroup}
                              />
                            )
                          })
                        }
                      </React.Fragment>
                    )
                  })
                }
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
  }
  

function mapStateToProps(state) {
  const {organization, courseGroup, metadata, user} = state;
  return {
    organization,
    courseGroup,
    metadata,
    user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseGroupOverview)