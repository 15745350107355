import React, {useState} from "react";
import {
  Button,
  Grid,
  Select,
  Typography,
  MenuItem
} from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import {
  AudioFieldType,
  AudioSelectionOptionType, MentorEnum,
  ScenarioInteractiveElementType, ScenarioL1L2SelectorOptions,
  ScenarioNodeType,
} from "../../../../Logic/ScenarioConstants";
import InteractionSelection from "../ScenarioComponents/InteractionSelection";
import UploadFile from "../ScenarioComponents/UploadFile";
import AudioPlayer from "../ScenarioComponents/AudioPlayer";
import AudioRecorder from "../ScenarioComponents/AudioRecorder";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import AudioLibrary from "../Audio/AudioLibrary";
import TextFieldWithLanguageSelector from "../ScenarioComponents/TextFieldWithLanguageSelector";
import LinHead from  "../../../../assets/images/LinHead.svg";
import RonHead from  "../../../../assets/images/RonHead.svg";
import AbeHead from  "../../../../assets/images/AbeHead.svg";
import BellHead from  "../../../../assets/images/BellHead.svg";
import SolinaHead from  "../../../../assets/images/SolinaHead.svg";

const useStyles = makeStyles(theme => ({
  interactions: {
    paddingTop: '10px',
    marginLeft: '10px'
  },
  activeVoiceOverButton: {
    backgroundColor: '#143349',
    fontColor: 'white',
    color: 'white',
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '150px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inactiveVoiceOverButton: {
    backgroundColor: 'white',
    fontColor: 'black',
    color: 'black',
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '150px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));


const InteractionDialog = (props) => {
  
  // expected props
  // props.testOption
  // props.onUpdateTestOptionOnCurrentNode
  // props.onChangeInteraction TODO: should be a logic function so that it can be reused without the hook all the way up in the chain
  // props.onChangeTextImageQuestionL2 TODO: should be a logic function so that it can be reused without the hook all the way up in the chain
  // props.l1Id
  
  const classes = useStyles();
  const [buttonSelected, setButtonSelected] = useState(0);
  const [isBlocked, setIsBlocked] = React.useState(true);

  const handleOnVoiceDrop = (file, testOptionId) => {
    props.onVoiceDrop(file, testOptionId)
  }

  const recordModeSelected = (file, testOptionId) => {
    setButtonSelected(AudioSelectionOptionType.RecordAudio)
    if (isBlocked) {
      navigator.getUserMedia({audio: true},
        () => {
          console.log('Permission Granted');
          setIsBlocked(false)
        },
        () => {
          console.log('Permission Denied');
          setIsBlocked(true)
        },
      );
    }
  }
  
  
  
  const setText = (newText) => {
    
    if (props.testOption.textLanguageId === props.l1Id) {
      props.testOption.text = newText;
      props.onUpdateTestOptionOnCurrentNode(props.testOption);
    } else {
      props.onChangeTextImageQuestionL2(newText, props.index)      
    }
  }
  const setLanguageForText = (languageSelector) => {
    if (languageSelector === ScenarioL1L2SelectorOptions.L1) {
      props.testOption.textLanguageId = props.l1Id;
    } else if (languageSelector === ScenarioL1L2SelectorOptions.L2) {
      props.testOption.textLanguageId = props.languageId;
    }
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
  }
  
  
  
  const handleChangeMentor = (mentor) => {
    if (mentor !== MentorEnum.NoMentor) {
      // force text option back to l2
      props.testOption.textLanguageId = props.languageId;
    } else {
      props.testOption.scenarioInteractiveElementType = ScenarioInteractiveElementType.Text;
      props.testOption.alternatives = []
    }
    
    props.testOption.mentor = mentor;
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
  }
  
  const handleAudioLibraryClick = (urlType, apiUrl) => {
    props.onAudioLibraryClick(AudioFieldType.TestOption, apiUrl, props.testOption.testOptionId)
  }

  const isHumanVoiceAdded = () => {
    return (props.testOption?.textAudioUrl?.includes('snippet') === false)
  }
  
  const toggleShouldBeScored = (shouldBeScored) => {
    props.testOption.interactionScoringDynamicConfigurationV1Dto = {
      'shouldBeScored': shouldBeScored
    }
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
    props.onUpdateSelectedDialogueUtterance(props.index)
  }
  
  const changeWhenSpoken = (audioPlayType) => {
    props.testOption.audioPlayType = audioPlayType;
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
    props.onUpdateSelectedDialogueUtterance(props.index)
  }
  
  const changeTranslation = (newTranslation) => {
    props.testOption.explanation = newTranslation;
    props.testOption.explanationLanguageId = props.lesson.l1Id;
    props.onUpdateTestOptionOnCurrentNode(props.testOption);
    
  }
  
  return (
    <React.Fragment>
      <Box 
        style={{
          background: '#F2F2F2',
          border: '2px solid #E5E5EA',
          boxSizing: 'border-box',
          borderRadius: '5px',
          marginBottom: '10px'
        }} 
        sx={{boxShadow: 0}}
        onHo
        onMouseEnter={() => props.onUpdateSelectedDialogueUtterance(props.index)} 
      >
        <Grid container>
          {
            props.shouldMinimise &&
            <Grid item xs={1} sm={1} md={1} style={{background: '#EDEDED'}}>
              <DragIndicatorIcon  style={{ width: 32, height: 32, marginTop: '5px'}} size="small"/>
            </Grid>
          }
          <Grid item xs={props.shouldMinimise ? 11 : 12} sm={props.shouldMinimise ? 11 : 12} md={props.shouldMinimise ? 11 : 12} style={{background: '#EDEDED'}}>
            <Typography style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: props.shouldMinimise ? 'normal' : 'bold',
              fontSize: props.shouldMinimise ? '12px' : '22px',
              lineHeight: '22px',
              marginTop: '10px',
              marginLeft: props.shouldMinimise ? '-10px' : '15px',
              marginBottom: '10px',
              color: '#35323C'
            }}>
              {props.shouldMinimise ? props.testOption.text === '' ? 'No dialogues added': props.testOption.text : "Dialogue " + (props.index + 1)}
            </Typography>
            {!!!props.shouldMinimise &&
            <FormControlLabel
              style={{
                marginTop: '-40px',
                marginRight: '10px',
                minWidth: '200px',
                display: 'flex',
                textAlign: 'right',
                alignItems: 'right',
                justifyContent: 'right',
                boxShadow: 'none',
                fontSize: 6,
                color: 'black',
                fontFamily: 'Lato'
              }}
              control={
                <Switch
                  disabled={!props.canEditLesson || props.testOption.scenarioInteractiveElementType === ScenarioInteractiveElementType.Text}
                  checked={props.testOption.interactionScoringDynamicConfigurationV1Dto?.shouldBeScored}
                  onChange={(e, checked) => {
                    toggleShouldBeScored(checked)
                  }}
                />
              }
              label={props.testOption.interactionScoringDynamicConfigurationV1Dto?.shouldBeScored ? "Scoring Enabled" : "Scoring Disabled"}
              labelPlacement="start"
            />
            }
          </Grid>
          {
            props.shouldMinimise === false &&
            <React.Fragment>
              <Grid container xs={12} sm={12} md={12} style={{marginLeft: '15px'}}>

              <Grid item xs={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{
                    boxShadow: 'none',
                    color: 'black',
                    fontFamily: 'Lato',
                    //marginLeft: '15px',
                    fontWeight: '500',
                    letterSpacing: '0.02em',
                    marginTop: '10px'

                  }}>
                  Choose the character
                </Typography>
              </Grid>
                  <Select style={{
                    marginLeft: '22px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginBottom: '5px',
                    width: '60px',
                    height: '60px',
                    //border: '5px solid #143349',
                    boxSizing: 'border-box',
                    borderRadius: '10px',
                    maxHeight: '60px',
                    minWidth: props.testOption.mentor === MentorEnum.NoMentor ? '110px' : '100px',
                    minHeight: '40px',
                    //marginTop: '10px'
                  }} disableUnderline
                          value={props.testOption.mentor}
                          autoWidth
                          onChange={(e) => handleChangeMentor(e.target.value)}
                  >
                    <MenuItem disabled={!props.canEditLesson} value={MentorEnum.NoMentor}>Narrator</MenuItem>
                    <MenuItem disabled={!props.canEditLesson} value={MentorEnum.Abe}><img component="img"  style={{ width: '43px'}}src={AbeHead}/></MenuItem>
                    <MenuItem disabled={!props.canEditLesson} value={MentorEnum.Lin}><img component="img"  style={{ width: '43px'}}src={LinHead}/></MenuItem>
                    <MenuItem disabled={!props.canEditLesson} value={MentorEnum.Bell}><img component="img"  style={{ width: '43px'}}src={BellHead}/></MenuItem>
                    <MenuItem disabled={!props.canEditLesson} value={MentorEnum.Ron}><img component="img"  style={{ width: '43px'}}src={RonHead}/></MenuItem>
                    <MenuItem disabled={!props.canEditLesson} value={MentorEnum.Solina}><img component="img"  style={{ width: '43px'}}src={SolinaHead}/></MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={8}>
                  <Select style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginBottom: '5px',
                    width: '60px',
                    height: '60px',
                    border: '5px solid #143349',
                    boxSizing: 'border-box',
                    borderRadius: '10px',
                    maxHeight: '40px',
                    minWidth: '300px',
                    minHeight: '40px',
                    marginTop: '45px'
                  }} disableUnderline
                          value={props.testOption.audioPlayType}
                          autoWidth
                          disabled={props.testOption.textLanguageId !== props.languageId}
                          onChange={(e) => changeWhenSpoken(e.target.value)}
                  >
                    <MenuItem disabled={!props.canEditLesson} value={0}>Don't speak out the dialogue</MenuItem>
                    <MenuItem disabled={!props.canEditLesson} value={1}>Speak the dialogue at the start</MenuItem>
                    <MenuItem disabled={!props.canEditLesson} value={2}>Speak the dialogue at the end</MenuItem>
                  </Select>
                </Grid>

              </Grid>
              
              <Grid item xs={12} sm={12} md={12} style={{marginBottom: '10px', marginLeft: '5px'}}>
                <InteractionSelection
                  testOption={props.testOption}
                  stageType={"rule"}
                  onChangeInteraction={props.onChangeInteraction}
                  testOptionsIndex={props.index}
                  title={"What interaction should this dialogue use?"}
                  detectInteractionMode={props.detectInteractionMode}
                  canEditLesson={props.canEditLesson}
                  disableInteraction={props.testOption.mentor === MentorEnum.NoMentor}
                />
              </Grid>
              
              
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{
                    boxShadow: 'none',
                    color: 'black',
                    fontFamily: 'Lato',
                    marginLeft: '15px',
                    fontWeight: '500',
                    letterSpacing: '0.02em'

                  }}>
                  Dialogue text
                </Typography>
              </Grid>
            </React.Fragment>
          }
          {
            props.shouldMinimise === false &&  props.testOption.mentor === MentorEnum.NoMentor  &&
            <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px', marginLeft: '15px'}} onMouseEnter={() => props.onUpdateSelectedDialogueUtterance(props.index)} >
               <TextFieldWithLanguageSelector 
                text={props.testOption.text}  
                fullWidth={true}
                disabled={!props.canEditLesson}
                onChange={(event) => setText(event.target.value)}
                onChangeLanguageLanguage={ (event) => setLanguageForText(event.target.value)}
                l2Id={props.languageId}
                l1Id={props.l1Id}
                canChangeLanguage={true}
                displayL1OrL2={props.testOption.textLanguageId === props.languageId ? 1 : 0}
              />
            </Grid>
          }
          {
            props.shouldMinimise === false &&  props.detectInteractionMode(props.testOption.alternatives, props.testOption.text) === true && props.testOption.mentor !== MentorEnum.NoMentor  &&
            <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px', marginLeft: '15px'}} onMouseEnter={() => props.onUpdateSelectedDialogueUtterance(props.index)} >
              <TextFieldWithLanguageSelector
                  text={props.node.testOptions[props.index].text}
                  fullWidth={true}
                  disabled={!props.canEditLesson}
                  onChange={(event) => setText(event.target.value)}
                  l2Id={null}
                  l1Id={null}
                  canChangeLanguage={false}
                  soloLanguageId={props.languageId}
              />
            </Grid>
          }
        </Grid>

        {
          props.shouldMinimise === false && props.testOption.mentor !== MentorEnum.NoMentor &&
            <Grid item xs={12} sm={12} md={12} className={classes.interactions}>
            {props.displayInteraction(props.testOption.alternatives, props.testOption.scenarioInteractiveElementType, props.index, ScenarioNodeType.TestRule, "Dialog Text", false)}
          </Grid>
        }

        {
          props.shouldMinimise === false && props.testOption.mentor !== MentorEnum.NoMentor &&
            <React.Fragment>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{
                    boxShadow: 'none',
                    color: 'black',
                    fontFamily: 'Lato',
                    marginLeft: '15px',
                    fontWeight: '500',
                    letterSpacing: '0.02em'

                  }}>
                  Translation (optional)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px', marginBottom: '20px', marginLeft: '15px', marginRight: '35px'}}>
                <TextFieldWithLanguageSelector 
                  text={props.testOption.explanation}              
                  fullWidth={true}
                  disabled={!props.canEditLesson} 
                  onChange={(event) => changeTranslation(event.target.value)}              
                  l2Id={null}
                  l1Id={null}
                  soloLanguageId={props.l1Id}
                  canChangeLanguage={false}
                />
              </Grid>    
            </React.Fragment>
          
        }


        {
          props.shouldMinimise === false && props.testOption.audioPlayType !== 0 &&
          <React.Fragment>
            <Grid item xs={12} sm={12} md={12} style={{marginTop: '20px'}}>
              <Typography
                style={{
                  boxShadow: 'none',
                  color: 'black',
                  fontFamily: 'Lato',
                  marginLeft: '15px',
                  fontWeight: '500',
                  letterSpacing: '0.02em'
                }}>
                Voice-over
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center"
                  style={{marginTop: '10px', marginBottom: '20px'}}>
              {
                !!!isHumanVoiceAdded() && props.canEditLesson &&
                <React.Fragment>
                  <Grid item xs={4} sm={4} md={4}
                        style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center',  textTransform: 'none',}}>
                    <Button onClick={() => setButtonSelected(AudioSelectionOptionType.UploadAudio)} className={buttonSelected === AudioSelectionOptionType.UploadAudio ? classes.activeVoiceOverButton : classes.inactiveVoiceOverButton } variant='outlined'>
                      Upload audio
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}
                        style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center',  textTransform: 'none',}}>
                    <Button onClick={() => recordModeSelected()} className={buttonSelected === AudioSelectionOptionType.RecordAudio ? classes.activeVoiceOverButton : classes.inactiveVoiceOverButton }  variant='outlined'>
                      Record audio
                    </Button>
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}
                        style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center',  textTransform: 'none',}}>
                    <Button onClick={() => setButtonSelected(AudioSelectionOptionType.LibraryAudio)} className={buttonSelected === AudioSelectionOptionType.LibraryAudio ? classes.activeVoiceOverButton : classes.inactiveVoiceOverButton }  variant='outlined'>
                      Library
                    </Button>
                  </Grid>

                </React.Fragment>
              }

              <Grid item xs={12} sm={12} md={12}
                    style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', minHeight: '50px'}}>

                <AudioPlayer
                  scenarioNodeType={ScenarioNodeType.TestDialog}
                  humanAudioAdded={isHumanVoiceAdded()}
                  audioPlaying={props.audioPlaying}
                  isAudioDownloading={false}
                  onPlayVoice={props.onPlayVoice}
                  onRemoveVoice={props.onRemoveVoice}
                  textAudioUrl={props.testOption.textAudioUrl}
                  testOptionId={props.testOption.testOptionId}
                  currentAudioBlobUrl={props.currentAudioBlobUrl}
                  currentAudioTracker={props.currentAudioTracker}
                />
                {
                  buttonSelected === AudioSelectionOptionType.UploadAudio && !!!isHumanVoiceAdded() && props.canEditLesson &&
                  <UploadFile onVoiceDrop={(file) => handleOnVoiceDrop(file, props.testOption.testOptionId)}/>
                }
                {
                  buttonSelected === AudioSelectionOptionType.RecordAudio && !!!isHumanVoiceAdded() && props.canEditLesson &&
                  <AudioRecorder
                    scenarioNodeType={ScenarioNodeType.TestDialog}
                    onMicVoiceDrop={props.onMicVoiceDrop}
                    isBlocked={isBlocked}
                    humanAudioAdded={isHumanVoiceAdded()}
                    audioPlaying={props.audioPlaying}
                    isAudioDownloading={false}
                    onPlayVoice={props.onPlayVoice}
                    onRemoveVoice={props.onRemoveVoice}
                    textAudioUrl={props.testOption.textAudioUrl}
                    testOptionId={props.testOption.testOptionId}/>
                }

                {
                  buttonSelected === AudioSelectionOptionType.LibraryAudio && !!!isHumanVoiceAdded() && props.canEditLesson &&
                  <div style={{marginTop: '10px', textAlign: 'center', alignItems: 'center',justifyContent: 'center'}}>
                    <AudioLibrary
                      isAudioDownloading={props.isAudioDownloading}
                      onPlayVoice={props.onPlayVoice}
                      audioPlaying={props.audioPlaying}
                      lesson={props.lesson}
                      onAudioLibraryClick={handleAudioLibraryClick}
                      audioFieldType={AudioFieldType.TestOption}
                      filterForVoiceOnlyAudio={true}
                    />
                  </div>
                }

              </Grid>
            </Grid>
          </React.Fragment>
        }
      </Box>
    </React.Fragment>
  );
};

InteractionDialog.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
    organization: state.organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(InteractionDialog)