import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import DefinitionWizard from "../../../../assets/images/DefinitionWizard.png";
import Typography from '@material-ui/core/Typography';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';

export default memo(({ data }) => {
  return (
    <>
    <div> 
      <img
        src={DefinitionWizard}
        style={{position: 'relative', width: '100px',
        height: 'auto'
        }}
      />
      <Grid style={{position: 'absolute', top: 70, left: 0}} container>
        {data?.testOptions?.length > 1 && 
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              {
                <Typography 
                  variant='body2'  
                  style={{
                  marginTop: '12px',
                  marginLeft: '3px',  
                  width: '70px',
                  boxShadow: 'none',
                  flex: 1,
                  fontSize: '5px',
                  fontWeight: 'bold',
                  color: '#44A6EF',
                  justifyContent: 'left',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  alignItems: 'center'}}>
                  {data.testOptions[1].text}
                </Typography>
              }
              </Grid>
              <Grid item xs={12} sm={12}>
              {
                <Typography 
                  variant='body2'  
                  style={{
                  marginTop: '10px',
                  marginLeft: '3px',  
                  width: '70px',
                  boxShadow: 'none',
                  flex: 1,
                  fontSize: '4px',
                  fontWeight: 'bold',
                  color: '#8E8E93',
                  justifyContent: 'left',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  alignItems: 'center'}}>
                  {data.testOptions[1].explanation}
                </Typography>
              }
              </Grid>
          </React.Fragment>
        }
      </Grid>
    </div>
    <Handle
      type="target"
      position="left"
      id="target1"
      style={{ top: '98.5px', background: '#555' }}
    />
    <Handle
      type="source"
      position="right"
      id="exit2"
      style={{ top: '98.5px', background: '#555' }}
    />
  </>
  );
});

