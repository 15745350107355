import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import compose from "recompose/compose";
import {connect} from "react-redux";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  Grid,
} from '@material-ui/core'


const styles = theme => ({

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  textFieldWide: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 800,
  },
});

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  label: {
    margin: theme.spacing(1),
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h5">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
        ) : null}
      </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class CustomizedDialogDemo extends React.Component {
  state = {
    open: false,
    value: '',
    l1Translation: '',
    l1Original: '',
    currentLang: [],
    selectedRadio: null
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (event) => {
    this.setState({ open: false });
    this.props.onButton1();
  };

  handleChange = event => {
    this.setState({selectedRadio: event.target.value});
    this.props.onRadioChange(event.target.value)
  };

  handleButtonSnippetText = (value) => {
    this.props.onButton2(this.state.l1Translation, this.state.snippetId,this.state.l1Original, value)
  };

  handleChangeText= (e) => {
    this.setState({value: e.target.value, selectedRadio: null});

    if(this.props.onTextFieldChange)
      this.props.onTextFieldChange(e)
  };

  onTextFieldChange = (e) => {
    const {value} = e.target;
    this.setState({l1Translation: value});
    this.setState({selectedRadio: null});
  };

  componentDidUpdate(oldProps) {
    const newProps = this.props
    if(oldProps.translationArray !== newProps.translationArray) {
      let currentLang = []
      if(newProps.translationArray) {
        currentLang = newProps.translationArray.filter(lang => {
          return lang.l1Id == newProps.translationLanguageId
        })
        if(currentLang.length > 0) {
          this.setState({ l1Translation: currentLang[0].l1SnippetText, l1Original: currentLang[0].l1SnippetText, snippetId: currentLang[0].id})
        } }
    }
  }

  render() {
    const {  open = false, title ="", textTitle, radios, textLine1, textLine2, translationLanguageId, onButton1, onButton2, button1Text, translationArray, button2Text,textfield1Text, textfield2Text, textfield1Value, onTranslationSnippetClick,onTextField2Change, textfieldid,textfield2id, classes } = this.props;
    const { l1Translation, l1Original } = this.state

    return (
        <div>
          <Dialog
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
          >
            <DialogTitle onClose={this.handleClose}>
              {textTitle}
            </DialogTitle>
            <DialogContent>
              <Typography gutterBottom>
                {textLine1}
              </Typography>
              <Typography gutterBottom variant="h5" className={classes.label}>
                {textLine2}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  {
                    textfield1Text &&
                    <TextField
                        id={textfieldid}
                        label={textfield1Text}
                        fullWidth
                        multiline
                        value={textfieldid}
                        onChange={this.handleChangeText}
                        style={{paddingTop: '10px'}}
                    />
                  }
                  {
                    this.state.l1Translation &&
                    <React.Fragment>
                      <TextField
                          fullWidth
                          multiline
                          id={l1Translation}
                          value={l1Translation}
                          onClick={onTranslationSnippetClick}
                          onChange={this.onTextFieldChange}
                          style={{paddingTop: '10px'}}
                      />
                    </React.Fragment>
                  }
                </Grid>
                {
                  radios && radios.length > 0 &&
                  <Grid item xs={12} sm={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" style={{paddingTop: '30px'}}>{title}</FormLabel>
                      <RadioGroup
                          aria-label="position"
                          name="position"
                          onChange={this.handleChange}
                          row
                          style={{paddingTop: '5px'}}
                      >
                        {radios && radios.map((alternative) => (
                            <FormControlLabel
                                value={alternative.id}
                                control={<Radio color="primary" checked={this.props.selectedRadio === alternative.id}/>}
                                label={alternative.l1WordText || alternative.name}
                                labelPlacement="top"
                            />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              {button1Text && <Button onClick={onButton1} color="primary">
                {button1Text}
              </Button>
              }
              {button2Text && <Button onClick={() => this.handleButtonSnippetText(this.state.value)} color="primary">
                {button2Text}
              </Button>
              }
            </DialogActions>
          </Dialog>
        </div>
    );
  }
}

function mapStateToProps(state) {
  const {} = state;
  return {};
}

const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles),
);

export default enhance(CustomizedDialogDemo);