import {connect} from "react-redux";
import CourseViewCard from "../../../Components/CourseView/CourseViewCard";
import React, {useEffect, useRef, useState} from "react";
import OrganizationOverviewSidebar from "./Components/OrganizationOverviewSidebar";
import {Grid, makeStyles, Typography} from "@material-ui/core";
import {organizationActions} from "../../../_state/actions";
import SkillsLibrary from "../../../Components/Library/SkillsLibrary";
import {CapeeshColors} from "../../../assets/ColorPalette";
import {Button} from "@mui/material";
import {history} from "../../../_state/helpers";

import AddIcon from '@mui/icons-material/Add';
import CreateRightImage from "../../../assets/images/dashboard/createright.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import CourseDialogWithLessonPreview from "../../../Components/CustomDialog/CourseDialogWithLessonPreview";
import {courseGroup} from "../../../_state/reducers/coursegroup.reducer";
import {FormattedMessage} from "react-intl";

;

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px'
    },
    
    grayBoxStyle: {
        borderRadius: '16px',
        backgroundColor: CapeeshColors.OffWhite,
        padding: '24px'
    },
    
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
        width: '100%',
    },
    pageTitle: {
        color: CapeeshColors.DarkText,
        fontSize: 24,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word'
    },
    
    boxTitle: {
        color: CapeeshColors.DarkText,
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
        textAlign: 'left'
    },
    boxDescription: {
        color: '#6E6C71',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        textAlign: 'left',
        marginTop: '4px'
    },

    createCardTitle: {
        color: CapeeshColors.DeepBlue,
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '400',
        textTransform: 'uppercase',
        letterSpacing: 0.64,
        wordWrap: 'break-word'
    },
    createCardLeft: {
        maxWidth: '800px',
        minWidth: '300px',
        justifyContent:"flex-start",
        marginRight: '80px',
    },
    createCardRight: {
        maxWidth: '800px',
        minWidth: '300px',
        justifyContent:"flex-end"
    },
    createCardLargeText: {
        color: CapeeshColors.DeepBlue,
        fontSize: 40,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
        letterSpacing: 0.64
    },
    cardDescription: {
        color: CapeeshColors.DeepBlue,
        fontSize: 20,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        letterSpacing: 0.64
    }

}))

const OrganizationCourses = (props) => {
    const classes = useStyles();
    
    const [availableSkillsCourses, setAvailableSkillsCourses] = useState([]);
    const [allCreatedCourseGroups, setAllCreatedCourseGroups] = useState([]);
    const [allCreatedCourseGroupsCalculatedOnce, setAllCreatedCourseGroupsCalculatedOnce] = useState(false);
    
    const [availableSkillsCoursesFromCapeesh, setAvailableSkillsCoursesFromCapeesh] = useState([]);
    
    const [openCourseGroup, setOpenCourseGroup] = useState(null);
    const [openCourseGroupModal, setOpenCourseGroupModal] = useState(false);
    
    const [courseGroupIdsDisplayedInYoursAndSkills, setCourseGroupIdsDisplayedInYoursAndSkills] = useState([]);
    const [shouldDisplaySkillsLibrary, setShouldDisplaySkillsLibrary] = useState(false);
    
    const recalculateAvailableCourseGroupData = () => {

        let tempAvailableCourseGroups = [];
        
        if (props.organization.organizationCourseGroups?.length > 0) {
            props.organization.organizationCourseGroups.forEach(x => {
                if (tempAvailableCourseGroups.indexOf(y => y.id === x.id) < 0) {
                    tempAvailableCourseGroups.push(x);
                }
            })
        }

        let tempCreatedCourseGroups = [];
        if (props.organization.organizationCourseGroups?.length > 0) {
            props.organization.organizationCourseGroups.forEach(x => {
                    if (x.organizationId === props.organization.selectedOrganization && tempAvailableCourseGroups.indexOf(y => y.id === x.id) < 0) {
                        tempCreatedCourseGroups.push(x);
                    }
                }
            )
        }

        tempAvailableCourseGroups.sort((a, b) => a.name.localeCompare(b.name));
        tempCreatedCourseGroups.sort((a, b) => a.name.localeCompare(b.name));

        let tempSkillsCourses = [];
        tempAvailableCourseGroups.forEach(x => {
            
            let found = false;
           tempCreatedCourseGroups.forEach(y => {
               if(x.id === y.id){
                   found = true;
               }
           });
           
           if(!found){
               tempSkillsCourses.push(x);
           }
        });

        setAllCreatedCourseGroups(tempCreatedCourseGroups);
        setAllCreatedCourseGroupsCalculatedOnce(true);
        setAvailableSkillsCourses(tempSkillsCourses);
        
        const tempUsedCourseGroupIds = []
        tempCreatedCourseGroups.forEach(courseGroupDto => {
            if (!tempUsedCourseGroupIds.includes(courseGroupDto.id)) {
                tempUsedCourseGroupIds.push(courseGroupDto.id);    
            }
            
        })
        tempSkillsCourses.forEach(courseGroupDto => {
            if (!tempUsedCourseGroupIds.includes(courseGroupDto.id)) {
                tempUsedCourseGroupIds.push(courseGroupDto.id);
            }
        })
        setCourseGroupIdsDisplayedInYoursAndSkills(tempUsedCourseGroupIds);
            
        var tempShouldDisplaySkillsLibrary = false;
        if (props.organization.skillsCourses?.length > 1) {
            let foundUnusedSkillsCourse = false;
            props.organization.skillsCourses.forEach(courseGroup => {
                if (!foundUnusedSkillsCourse && !tempUsedCourseGroupIds.includes(courseGroup.id)) {
                    foundUnusedSkillsCourse = true;
                }
            })
            if (foundUnusedSkillsCourse) {
                tempShouldDisplaySkillsLibrary = true;
            }
        }
        
        setShouldDisplaySkillsLibrary(tempShouldDisplaySkillsLibrary);
    }
    
    const handleCloseCourseGroupModal = () => {
        setOpenCourseGroupModal(false);
        setOpenCourseGroup(null);
    }

    const handleCourseGroupOpen = (courseGroupId) => {
        let allCourses = [...availableSkillsCourses, ...allCreatedCourseGroups];
        
        for (let courseGroup of allCourses){
            if (courseGroup.id === courseGroupId){
                setOpenCourseGroup(courseGroup);
                break;
            }
        }
        
        setOpenCourseGroupModal(true);
    }
        
    const handleGoToCreator = () => {
        
        if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') {
            history.push("/creator/")    
        } else {
            history.push("/creator/overview/")
        }
        
    }
    
    
    const handleShowDemoVideo = () => {
        if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') {
            history.push("/creator/")
        } else {
            history.push("/creator/overview/")
        }
    }
    
    useEffect(() => {
        props.dispatch(organizationActions.getOrganizationCourseGroups(props.organization.selectedOrganization));
        props.dispatch(organizationActions.getOrganizationSkillsCourses(props.organization.selectedOrganization));
    }, []);
    
    useEffect(() => {
        recalculateAvailableCourseGroupData();
    }, [props.organization]);

    return (
        <div className={classes.root}>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {
                    openCourseGroupModal &&
                    <CourseDialogWithLessonPreview isOpen={openCourseGroupModal}
                                                   onClose={handleCloseCourseGroupModal}
                                                   title={openCourseGroup?.name}
                                                   dispatch={props.dispatch}
                                                   openCourseGroup={openCourseGroup}>
                    </CourseDialogWithLessonPreview>
                }
            </div>
            
            <OrganizationOverviewSidebar
                path={props.computedMatch?.path}
            />
            <div className={classes.textContainer}>
                <Typography className={classes.pageTitle}>
                    <FormattedMessage id="dashboard.courses" defaultMessage="Courses"/>
                </Typography>
                <Typography className={classes.boxDescription}>
                    <FormattedMessage id="dashboard.courses.description" defaultMessage="Your organizations courses."/>
                </Typography>
            </div>
            <Grid container style={{paddingTop: "20px"}}>
                {
                  allCreatedCourseGroups?.length > 0 &&
                  <Grid item xs={12}>
                      
                      <div className={classes.grayBoxStyle}>
                          <Grid container>
                              <Grid item xs={8}>
                                  <div className={classes.textContainer}>
                                      <Typography className={classes.boxTitle}>
                                          <FormattedMessage id="dashboard.courses.your.courses.title" defaultMessage="Your Courses"/>
                                      </Typography>
                                      <Typography className={classes.boxDescription}>
                                          <FormattedMessage id="dashboard.courses.your.courses.description" defaultMessage="Courses created by your organization."/></Typography>

                                  </div>
                              </Grid>
                              <Grid item xs={4}>
                                  <div style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'right',
                                      width: '100%',
                                  }}>
                                      {
                                        (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') &&
                                        <Button
                                          onClick={() => history.push("/creator/")}
                                          style={{
                                              textTransform: 'none',
                                              fontSize: 14,
                                              fontFamily: 'Rubik',
                                              fontWeight: '500',
                                              color: CapeeshColors.Pink,

                                          }}><AddIcon style={{height: '14px', color: CapeeshColors.Pink}}/>
                                            <FormattedMessage id="dashboard.courses.your.courses.createnewcoursebuttontext" defaultMessage="Create new course "/>
                                        </Button>
                                      }
                                      
                                      
                                  </div>
                                  
                              </Grid>
                          </Grid>


                          <Grid container style={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(auto-fill, 273px)',
                              alignContent: 'space-evenly',
                              justifyItems: 'center',
                              paddingTop: '20px'
                          }}>
                              {
                                  allCreatedCourseGroups.sort((a, b) => a.name.localeCompare(b.name)).map((courseGroup, index) =>
                                    <CourseViewCard
                                      courseGroup={courseGroup}
                                      index={index}
                                      openCourseGroup={handleCourseGroupOpen}
                                      showProgression={false}
                                      courseGroupProgression={null}
                                      handleRequestAccess={null}
                                    />
                                  )
                              }
                          </Grid>
                      </div>
                    </Grid>
                    

                }
                {
                    allCreatedCourseGroupsCalculatedOnce && !(allCreatedCourseGroups?.length > 0) &&
                    <Grid container style={{
                        backgroundColor: CapeeshColors.OffWhite,
                        borderRadius: '16px',
                        marginTop: '20px',
                    }}>
    
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.createCardLeft} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '400px',
                                marginLeft: '40px',
                            }}>
                                <div>
                                    <Typography className={classes.createCardTitle}>
                                        Capeesh Create
                                    </Typography>
            
                                    <Typography className={classes.createCardLargeText}>
                                        <FormattedMessage id="dashboard.courses.your.courses.createpitch.title" defaultMessage="Create your own courses with"/> {" "}<Typography display={"inline"}
                                                                                 className={classes.createCardLargeText}
                                                                                 style={{
                                                                                     color: '#A0439F'
                                                                                 }}>
                                        Capeesh Create
                                    </Typography>
                                    </Typography>
                                    
                                    <Typography className={classes.cardDescription}>
                                        <FormattedMessage id="dashboard.courses.your.courses.createpitch.description" defaultMessage="You have not created any courses (yet). With Capeesh Create you can create engaging and game-based training in minutes."/>
                                    </Typography>
            
                                    <div
                                      style={{
                                          marginTop: '10px',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          width: '100%',
                                      }}>
            
                                        <Button variant="outlined"
                                                onClick={() => handleGoToCreator()}
                                                style={{
                                                    backgroundColor: CapeeshColors.DeepBlue,
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Rubik',
                                                    fontWeight: '400',
                                                    paddingLeft: '16px',
                                                    paddingRight: '16px',
                                                    textTransform: 'none'
            
                                                }}>
                                            <FormattedMessage id="dashboard.courses.your.courses.createpitch.buttontext" defaultMessage="Go to Create"/>
                                            <ArrowRightAltIcon sx={{marginLeft: '5px', fontSize: '15px', color: 'white', paddingRight: '5px'}}/>
                                        </Button>
                                        <Button variant="outlined"
                                                onClick={() => handleShowDemoVideo()}
                                                style={{
                                                    color: CapeeshColors.DeepBlue,
                                                    backgroundColor: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Rubik',
                                                    fontWeight: '400',
                                                    paddingLeft: '16px',
                                                    paddingRight: '16px',
                                                    textTransform: 'none',
                                                    marginLeft: '10px'
                                                }}>
                                            <FormattedMessage id="dashboard.courses.your.courses.createpitch.watchdemotext" defaultMessage="Watch a demo"/> {" "}
                                             <PlayCircleOutlineIcon style={{marginLeft: '4px', color: CapeeshColors.DeepBlue, backgroundColor: 'white', fontSize: 16}}/>
    
                                        </Button>
                                        
                                    </div>
            
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.createCardRight}>
                                <img style={{minWidth: '400px', maxWidth: "550px"}} src={CreateRightImage}/>
                            </div>
                        </Grid>
                    </Grid>

                }        
                
                
                {
                    availableSkillsCourses?.length > 0 &&
                    <Grid item xs={12}>
                      <div style={{marginTop: '24px'}} className={classes.grayBoxStyle}>
                          <div className={classes.textContainer}>
                              <Typography className={classes.boxTitle}>Capeesh Skills Courses</Typography>
                              <Typography className={classes.boxDescription}>
                                  <FormattedMessage id="dashboard.courses.your.courses.skills.description" defaultMessage="Capeesh Skills courses assigned to your organization."/>
                              </Typography>
                          </div>

                          <Grid container style={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(auto-fill, 273px)',
                              alignContent: 'space-evenly',
                              justifyItems: 'center',
                              paddingTop: '20px'
                          }}>
                              {
                                  availableSkillsCourses.sort((a, b) => a.name.localeCompare(b.name)).map((courseGroup, index) =>
                                    <CourseViewCard
                                      key={courseGroup.id}
                                      courseGroup={courseGroup}
                                      index={index}
                                      openCourseGroup={handleCourseGroupOpen}
                                      showProgression={false}
                                      courseGroupProgression={null}
                                      handleRequestAccess={null}
                                    />
                                  )
                              }
                          </Grid>
                      </div>
                  </Grid>
                }

                {
                  shouldDisplaySkillsLibrary &&
                  <Grid item xs={12}>
                      <div style={{marginTop: '24px'}} className={classes.grayBoxStyle}>
                          <div className={classes.textContainer}>
                              <Typography className={classes.boxTitle}>
                                  <FormattedMessage id="dashboard.courses.your.courses.capeesh.skills.title" defaultMessage="Available courses from Capeesh."/>
                              </Typography>
                              <Typography className={classes.boxDescription}>
                                  <FormattedMessage id="dashboard.courses.your.courses.capeesh.skills.description" defaultMessage="Other Capeesh Skills courses available."/>
                              </Typography>
                          </div>

                          <SkillsLibrary
                            filterOutCourseGroupIds={courseGroupIdsDisplayedInYoursAndSkills}
                            dispatch={props.dispatch}
                            closeModal={handleCloseCourseGroupModal}
                            fetchSkills={false}
                            canOpenRequestAccess={true}
                          />
                      </div>
                  </Grid>
                }

            </Grid>
        </div>
)
}

function mapStateToProps(state) {
    const {organization, user} = state;
    return {
        organization,
        user,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCourses)