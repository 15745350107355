import {asyncRequest} from './asyncRequest'
import {authConstants} from "./auth.actions";

export const translatorConstants = {
    GET_TRANSLATION_JOBS: new asyncRequest("GET_TRANSLATION_JOBS"),
    CREATE_TRANSLATOR_JOB: new asyncRequest("CREATE_TRANSLATOR_JOB"),
    GET_TRANSLATORS: new asyncRequest("GET_TRANSLATORS"),
    ADD_TRANSLATOR_LANGUAGE:  new asyncRequest("ADD_TRANSLATOR_LANGUAGE"),
    GET_TRANSLATORS_FOR_L1:  new asyncRequest("GET_TRANSLATORS_FOR_L1"),
    GET_TRANSLATOR_JOBS:  new asyncRequest("GET_TRANSLATOR_JOBS"),
    UPDATE_JOB: new asyncRequest('UPDATE_JOB'),
};

export const translatorActions = {
    getTranslationJobs(organizationId) {
        return {
            type: translatorConstants.GET_TRANSLATION_JOBS.REQUEST,
            payload: {
                organizationId,
            }
        }
    },
    createTranslatorJob(topicId, translatorUserId, l1Id, dueDate) {
        return {
            type: translatorConstants.CREATE_TRANSLATOR_JOB.REQUEST,
            payload: {
                topicId,
                translatorUserId,
                l1Id,
                dueDate
            }

        }
    },
    getTranslators(organizationId) {
        return {
            type: translatorConstants.GET_TRANSLATORS.REQUEST,
            payload: {
                organizationId,
            }
        }
    },
    addTranslatorLanguage(translatorLanguages) {
        return {
            type: translatorConstants.ADD_TRANSLATOR_LANGUAGE.REQUEST,
            payload: {
                translatorLanguages,
            }
        }
    },
    getTranslatorsForL1(language) {
        return {
            type: translatorConstants.GET_TRANSLATORS_FOR_L1.REQUEST,
            payload: {
                language,
            }
        }
    },
    getTranslatorJobs() {
        return {
            type: translatorConstants.GET_TRANSLATOR_JOBS.REQUEST,
            payload: {
                //language,
            }
        }
    },
    updateJob(translationJobId, status, topicId) {
        return {
            type: translatorConstants.UPDATE_JOB.REQUEST,
            payload: {
                translationJobId,
                status,
                topicId
                //language,
            }
        }
    }
}