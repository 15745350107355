import React, {useState, useRef} from 'react';
import {Typography,makeStyles, } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {AutoCourseCreatorRuleCardPlotTypes } from "../../../../../Logic/AutoCourseCreationConstants";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import {v4 as uuidv4} from "uuid";
import AccStageSettingsInteractionSelection from "./ACCStageSettingsInteractionSelection";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles(theme => ({
  stagePlotType: {
    display: 'inline-block', color: "#35323C",
    fontFamily: "Nunito",
    fontSize:14,
    fontStyle: "normal",
    fontWeight:400,
    letterSpacing:0.41,borderRadius:5, border: "1px solid var(--Gray-1, #8E8E93)", marginTop: '10px', padding: '5px'
  },
  stagePlotTypeSelected: {
    display: 'inline-block', color: "white",
    fontFamily: "Nunito",
    fontSize:14,
    fontStyle: "normal",
    fontWeight:400,
    letterSpacing:0.41,borderRadius:5, border: "1px solid var(--Gray-1, #8E8E93)", marginTop: '10px', padding: '5px'
    ,background: '#44A6EF'
  }
}));

const ACCStageSettingsStory = (props) => {
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogExistingText, setDialogExistingText] = useState("");
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [dalogueText, setDialogueText] = useState(false);
  const classes = useStyles();
  let circleStyle = {
    padding:10,
    margin:20,
    //display:"inline",
   // backgroundColor: 'green',
    borderRadius: "50%",
    width:30,
    height:30,
  };

  const handleTextChange = (event) => {
    setDialogueText(event.target.value)
  }

  const handleSelectChangePlot = (element) => {
    console.log("change plot text", element.target.value)
    props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "storyText", element.target.value)
   }

   const handleClickOpen = (roleType, title, message, existingText = "") => {
    setOpenRoleDialog(true);
    setDialogTitle(title)
    setDialogMessage(message)
    setDialogExistingText(existingText)
    //handleTextFocus(roleType, props.selectedLesson.lessonPlanConfigurationDto.dialogue., roleType)
  };

  const handleCloseModal = () => {
    setOpenRoleDialog(false);
    setDialogExistingText("")
  };

  const handleSaveModal = () => {
    setOpenRoleDialog(false);

    props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "storyText", dalogueText)
  };


  return (
          <Grid container>
              <Dialog open={openRoleDialog} onClose={handleCloseModal}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMessage}
          </DialogContentText>
          <TextField multiline defaultValue={dialogExistingText} autoFocus margin="dense" id="name" fullWidth variant="standard" 
            onChange={handleTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{textTransform: 'none'}}>Cancel</Button>
          <Button onClick={handleSaveModal} style={{textTransform: 'none'}}>OK</Button>
        </DialogActions>
      </Dialog>
          <Grid item xs={12} > 

      <Typography style={{ fontFamily: 'Rubik', fontSize: '16px',   color: 'black', letterSpacing: '0.31px', textAlign:'left', }}>
        The learner is given a short story related to the teaching material.
      </Typography>
      <Typography style={{ fontFamily: 'Rubik', fontSize: '16px',   color: 'black', letterSpacing: '0.31px', textAlign:'left', marginTop: '10px' }}>
        They must listen to or read the story and answer comprehension questions on it.
      </Typography>
      </Grid>
      <Grid item xs={12} > 

    <Typography style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '16px',   color: 'black', letterSpacing: '0.31px', textAlign:'left', marginTop: '20px'}}>
      Should the learner read or listen to the story?
    </Typography>
    </Grid>
    <Grid item xs={12} > 

      <Grid container spacing={1} style={{borderRadius:5, background: "#F8F8F8", marginTop: '10px'}}>
        <Grid item xs={12} > 
          <Button onClick={() => props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "storyMode", "Listening")} 
            style={{color: props.interactionType === "Listening" ? 'white' : '#35323C', borderRadius: '5px', background: props.interactionType === "Listening" ? '#44A6EF': 'white', border: props.interactionType === "Listening" ? 0 :'1px solid var(--gray-1, #8E8E93)'  , display: 'inline', height: '31px', padding: '0 15px', alignItems: 'center', justifyContent: 'center', gap: '20px', textTransform: 'none', marginRight: '10px'}}>
            Listen to the story
          </Button>
          <Button onClick={() => props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, "storyMode", "Reading")} 
            style={{color: props.interactionType === "Reading" ? 'white' : '#35323C', borderRadius: '5px', background: props.interactionType === "Reading" ? '#44A6EF': 'white', border: props.interactionType === "Reading" ? 0 :'1px solid var(--gray-1, #8E8E93)'  , display: 'inline', height: '31px', padding: '0 15px', alignItems: 'center', justifyContent: 'center', gap: '20px', textTransform: 'none', marginRight: '10px'}}>
              Read the story
          </Button>
          </Grid>
          </Grid>

      </Grid>
      <Typography style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '16px',   color: 'black', letterSpacing: '0.31px', textAlign:'left', marginTop: '20px'}}>
      Choose a plotline or write your own. The AI will write a script based on it.
    </Typography>
    <Grid item xs={12} style={{marginTop: '5px'}}>
      <FormControl fullWidth variant="outlined" style={{marginTop: '10px'}}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            style={{height: '30px', color: '#35323C', fontFamily: 'Nunito', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '19px', letterSpacing: '0.41px', justifyContent: 'center', paddingTop: '5px', maxWidth: '100%', }}

            value={props.selectedLesson.lessonPlanConfigurationDto?.story?.plot}
            onChange={handleSelectChangePlot}
            renderValue={(selected) => {
              return (
                <Box 
                  component="span"  
                  sx={{ 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    maxWidth: 'calc(100% - 24px)', // 24px is icon's width
                  }}
                >
                  {selected}
                </Box>
              );
            }}
          >
            <div>
              <Button onClick={()=>handleClickOpen("plotText", "Write the story's plot", "Write your own plot. The AI will write a story based on it. In the lesson, the learner will be asked comprehension questions based on the story.")} style={{textTransform: 'none', width: '100%', textAlign: 'left', textDecoration: 'underline'}}>Write a new plot</Button>
            </div>
            {props.selectedLesson.lessonPlanConfigurationDto?.story?.alternativePlots.map((plot) => (
              <MenuItem key={plot} value={plot} style={{color: '#35323C', fontFamily: 'Nunito', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '19px', letterSpacing: '0.41px'}}
              >                        
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {plot}
              <IconButton size="small" onClick={()=>handleClickOpen("plotText", "Write the lesson plot", "Write the plot that the lesson will be based on. Give as much detail as you wish on what should happen in the story.", plot)}>
                <EditIcon fontSize="inherit" />
              </IconButton>
            </div> </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

ACCStageSettingsStory.propTypes = {};

const mapStateToProps = (state) => {
  return {
    languages: state.metadata.metadata.languages,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCStageSettingsStory)