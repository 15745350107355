import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactComponent as Close } from '../Images/StageRemove.svg';
import {TextField} from "@mui/material";
import {CapeeshColors} from "../../../../assets/ColorPalette";

const useStyles = makeStyles(theme => ({
    titleDiv:
        {
            width: '100%',
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            display: "flex"
        },
    titleText:
        {
            width: '100%', 
            color: '#232B35', 
            fontSize: 20,
            fontFamily: 'Rubik', 
            fontWeight: '400', 
            wordWrap: 'break-word'
        },
    textBoxes:
        {
            width: 822,
            height: '100%',
            padding: 16,
            background: CapeeshColors.OffWhite,
            borderRadius: 8,
            overflow: 'hidden',
            border: '2px solid',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 10,
            display: 'inline-flex',
            marginBottom: 16
        },
    mainDialog:
        {
            width: 635,
            height: 342,
            padding: 16,
            background: 'white',
            borderRadius: 16,
            flexDirection: 'column',
            display: "flex",
            gap: 16,
        },
    mainContainer:
        {
            width: '100%',
            height: '100%',
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex"
        },
    secondaryTitleStyle:
        {
            width: '100%',
            color: '#394553', 
            fontSize: 16, 
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginBottom: 16
        },
    textBoxTitleStyle:
        {
            marginTop: 16,
            color: '#232B35', 
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '500', 
            wordWrap: 'break-word',
            marginBottom: 8
        },
    divider:
        {
            width: 822,
            height: '100%',
            border: '1px #D7DDE4 solid',
            marginTop: 16,
            marginBottom: 16
        },
    bottomButtonContainer:
        {
            display: "flex",
            flexDirection: "row-reverse",
            width: '100%'
        },
    mainButtonText:
        {
            textAlign: 'center',
            color: 'white', 
            fontSize: 16, 
            fontFamily: 'Rubik', 
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    secondaryButtonText:
        {
            textAlign: 'center', 
            color: '#232B35',
            fontSize: 16,
            fontFamily: 'Rubik',
            fontWeight: '400',
            wordWrap: 'break-word'
        },
    secondaryButton:
        {
            height: 48,
            paddingLeft: 16,
            paddingRight: 16, 
            borderRadius: 8, 
            border: '2px #BBC4CF solid', 
            justifyContent: 'center',
            alignItems: 'center', 
            gap: 4,
            display: 'inline-flex',
            cursor: "pointer"
        },
    mainButton:
        {
            height: 48,
            paddingLeft: 16, 
            paddingRight: 16, 
            background: '#186ED7', 
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4, 
            display: 'inline-flex',
            cursor: "pointer",
            marginLeft: 8
        },
    mainButtonDisabled:
        {
            height: 48,
            paddingLeft: 16,
            paddingRight: 16,
            background: 'gray',
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            display: 'inline-flex',
            marginLeft: 8
        }
}));

const TeachingMaterialEditModal = (props) => {
    
    const classes = useStyles();
    
    const [firstTextBoxValue, setFirstTextBoxValue] = useState("");
    const [secondTextBoxValue, setSecondTextBoxValue] = useState("");
    const [secondTextBoxPlaceholderText, setSecondTextBoxPlaceholderText] = useState("");
    const [firstTextBoxPlaceholderText, setFirstTextBoxPlaceholderText] = useState("");
    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        if(firstTextBoxValue.length <= 0 || secondTextBoxValue.length <= 0){
            setCanSave(false);
            
            return;
        }
        
        setCanSave(true);
    }, [firstTextBoxValue, secondTextBoxValue]);
    
    useEffect(() => {
        setFirstTextBoxPlaceholderText("Placeholder text goes here");
        setSecondTextBoxPlaceholderText("Placeholder text goes here");
        
        setFirstTextBoxValue(props.firstTextBoxValue);
        setSecondTextBoxValue(props.secondInputFieldText);
    }, []);
    
    return (
        <div className={classes.mainContainer}>
            <Dialog open={props.openDialog} onClose={() => props.closeDialog()} maxWidth={"md"}>
                <DialogTitle>
                    <div className={classes.titleDiv}>
                        <Typography className={classes.titleText}>
                            { props.dialogTitle }
                        </Typography>
                        <Close onClick={() => props.closeDialog()} style={{ cursor: "pointer" }}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Typography className={classes.secondaryTitleStyle}>
                        { props.dialogSecondaryText }
                    </Typography>
                    <Typography className={classes.textBoxTitleStyle}>
                        { props.firstInputFieldTitle }
                    </Typography>
                    <TextField fullWidth variant="filled"
                               defaultValue={props.firstTextBoxValue}
                               value={firstTextBoxValue}
                               onChange={(event) => setFirstTextBoxValue(event.target.value)}
                               inputProps={{ maxLength: 100 }} 
                               className={classes.textBoxes} 
                               label={firstTextBoxPlaceholderText}/>
                    <Typography className={classes.textBoxTitleStyle}>
                        { props.secondInputFieldTitle }
                    </Typography>
                    <TextField fullWidth variant="filled"
                               defaultValue={props.secondInputFieldText}
                               value={secondTextBoxValue}
                               onChange={(event) => setSecondTextBoxValue(event.target.value)}
                               multiline
                               minRows={3}
                               inputProps={{ maxLength: 150 }}
                               className={classes.textBoxes} 
                               label={secondTextBoxPlaceholderText}/>
                    <div className={classes.divider}/>
                    <div className={classes.bottomButtonContainer}>
                        <div className={canSave ? classes.mainButton : classes.mainButtonDisabled} onClick={() => {
                            if(!canSave) return;
                            
                            props.save(props.selectedSection, props.attributeText, secondTextBoxValue, props.selectedConcept, firstTextBoxValue);
                            props.closeDialog();
                        }}> 
                            <span className={classes.mainButtonText}>
                                { props.mainButtonText }
                            </span>
                        </div>
                        <div className={classes.secondaryButton} onClick={() => props.closeDialog()}> 
                            <span className={classes.secondaryButtonText}>
                                { props.secondaryButtonText }
                            </span>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

TeachingMaterialEditModal.propTypes = {};

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(TeachingMaterialEditModal)