import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
  makeStyles, Typography,
} from '@material-ui/core'
import {
} from "@material-ui/icons";
import {CapeeshColors} from "../../assets/ColorPalette";
import {Button} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {history} from "../../_state/helpers";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
  footer: {
    textAlign: 'center',
    // margin: '30px 0px',
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
    backgroundColor: CapeeshColors.DeepBlue,
  },
  
}));

const OrganizationSelectFooter = (props) => {

  const classes = useStyles();
  
  const [showOrganizationChooser, setShowOrganizationChooser] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState(null);

  useEffect(() => {
    var org = props.organization.myOrganizations.find(x => x.id === props.organization.selectedOrganization);
    if (org) {
      setCurrentOrganization(org);
    }
  }, [props.organization.myOrganizations, props.organization.selectedOrganization])
  
  
  const handleSwitchOrganizationClicked = () => {
      if(props.user.organizationRole === 'admin'){
          history.push('/admin/organizations');
      }
      else {
          props.openOrgModal();
      }
  }
  
  const handleGoToOrgStats = () => {
    history.push('/org/stats');
  }
  
  
  return (
    <div className={classes.footer}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
      }}>
        <Typography style={{
          fontSize: 24,
          fontFamily: 'Rubik',
          fontWeight: '400',
          color: "white",
          marginTop: '12px',
        }}>
          <FormattedMessage id="top.level.dashboard.footer.prefix" defaultMessage="You are currently viewing"/> {currentOrganization?.name}
        </Typography>

        <Button variant="outlined"
                onClick={() => handleSwitchOrganizationClicked()}
                style={{
                  color: CapeeshColors.DeepBlue,
                  backgroundColor: 'white',
                  fontSize: 16,
                  fontFamily: 'Rubik',
                  fontWeight: '400',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  textTransform: 'none',
                  marginLeft: '25px',
                  marginTop: '10px'
                }}>
          <FormattedMessage id="top.level.dashboard.footer.switchbuttontext" defaultMessage="Switch organization"/>
          <ArrowRightAltIcon sx={{marginLeft: '5px', fontSize: '15px', color: CapeeshColors.DeepBlue, paddingRight: '5px'}}/>
        </Button>

        <Button variant="outlined"
                onClick={() => handleGoToOrgStats()}
                style={{
                  color: 'white',
                  backgroundColor: CapeeshColors.DeepBlue,
                  fontSize: 16,
                  fontFamily: 'Rubik',
                  fontWeight: '400',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  textTransform: 'none',
                  marginLeft: '25px',
                  marginTop: '10px'
                }}>
          <FormattedMessage id="top.level.dashboard.footer.orgstatsbuttontext" defaultMessage="Organization statistics"/>
        </Button>
        
      </div>
      
    </div>
  )
};


OrganizationSelectFooter.propTypes = {};

const mapStateToProps = (state) => {
  const {user, organization, metadata} = state;
  return {
    metadata,
    user,
    organization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSelectFooter)