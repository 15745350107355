import React, { memo, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Zoom from '@mui/material/Zoom';
import TextField from '@mui/material/TextField';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DeleteCircleOutline from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {ScenarioNodeType} from "../../../../Logic/ScenarioConstants";
import CircleIcon from '@mui/icons-material/Circle';

export default memo(({ data }) => {
  const [hasEdited, setHasEdited] = useState(false);
  const [hasRequestedAdd, setHasRequestedAdd] = useState(false);
  const [isTextInFocus, setIsTextInFocus] = useState(false);

  const handleDuplicate = (event) => {
    data.onDuplicate(data.id, ScenarioNodeType.Listen)
    event.stopPropagation();
    event.preventDefault();
   };
 
   const handleAdd = (event) => {
     data.onAdd(data.id, ScenarioNodeType.Speak)
     event.stopPropagation();
     event.preventDefault();
    };
 
    const handleAddOther = (event) => {
     data.onAddOther(data.id, ScenarioNodeType.Speak)
     event.stopPropagation();
     event.preventDefault();
    };

    const handleChangeSelectedNode = () => {
      setIsTextInFocus(true)
     }

    const handleSuggest = (event) => {
      data.onSuggest(data.id)
      event.stopPropagation();
      event.preventDefault();
     };

    const handleEndSelection = () => {
      if(hasEdited === true) {
       data.onUpdateSelection(data.id, hasRequestedAdd)
       setHasEdited(false)
      }
      setIsTextInFocus(false)
    }

    const changeText = (e, index) => {
      if (!data.canEditLesson) {
        return;
      }
     setHasEdited(true)
     data.onUpdateAnswerText(e.target.value, index, data.id, false, 0, 0)
    }

    const handlePlayClick = () => {
      data.onPlayAppFromNode(data.id)
    }
    const handleDeleteClick = () => {
      data.onDeleteNode(data.id)
    }

  return (
    <>
      <Zoom in={true}>
        <div>
          <div style={{marginLeft: '20px', width: '150px' ,display:"flex", flexDirection:"row", textAlign: 'center', height: '25px', borderradius: '5px 5px 0px 0px'}}>
            <div style={{backgroundColor: '#EFEFF4', width: '41px'}} sx={{ float: 'left'}}>
              <IconButton onMouseDown={(event) => handlePlayClick()} sx={{ fontSize: "17px",  display: 'center', alignItems: 'left', marginLeft: '4px', justifyContent: 'center',  height: '26px', color: 'black'}}>
                <PlayCircleOutlineIcon sx={{ fontSize: "17px"}}/>
              </IconButton>
            </div>

            {
              data.canEditLesson &&
              <div style={{backgroundColor: '#EFEFF4',marginLeft: '105px', width: '41px'}} sx={{ float: 'right'}}>
                <IconButton onMouseDown={(event) => handleDeleteClick()} sx={{ fontSize: "17px",  display: 'center', alignItems: 'left', marginLeft: '4px', justifyContent: 'center',  height: '26px', color: 'black'}}>
                  <DeleteCircleOutline sx={{ fontSize: "17px"}}/>
                </IconButton>
              </div>
            }
          </div>
          
        <Card style={{minHeight: '135px', width: '220px', backgroundColor: 'white', borderStyle: 'solid',
          borderWidth: data?.validationErrors?.length > 0 ? '2px' : 0,
          borderColor: data?.validationErrors?.length > 0 ? 'red' : 'white',
          boxShadow: '0px 4px 4px 0px #00000040'}}>
            <Grid container >
              <Grid style={{backgroundColor: '#4aa7ec', height: '35px'}} item xs={12} sm={12} md={12}>
                <Typography variant="body1" style={{
                  height: '100px',
                  paddingTop: '4px',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontFamily: 'Lato',
                }}>
                    Listen Stage
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}  style={{marginTop: '12px'}} >
                <Typography variant="body1" style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  letterSpacing: '0.5px',
                  marginLeft: '11px',
                  marginRight: '5px',
                  marginBottom: '5px',
                  color: '#000000'
                  }}>
                    Text being spoken:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{backgroundColor: 'white', minHeight: '70px'}}>
                { data && data.options && data.options.map((el, index) => {
                  return <React.Fragment key={index}>
                    <Card 
                      style={{minHeight: '70px', width: '220px',backgroundColor: 'white',
                      boxShadow: 'none',
                      borderRadius: '0',
                      display: 'inline-flex',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center'}}
                    >
                      <Zoom in={true} style={{ transitionDelay:  0}}>
                        <TextField  
                          style={{  
                            fontStyle: 'normal', fontWeight: 400,fontSize: '14px',
                            letterSpacing: '0.5px',
                            width: '200px',
                            color: '#000000',
                            boxSizing: 'border-box',
                            borderRadius: '10px'
                          }}
                          onChange={(event) => changeText(event, 0)}
                          disabled={!data.canEditLesson}
                          hiddenLabel id="filled-basic" variant="filled" multiline value={el}  InputProps={{ disableUnderline: true,margin:'dense' }} onBlur={handleEndSelection} onFocus={handleChangeSelectedNode} />
                        </Zoom>
                    </Card>
                  </React.Fragment>;
                  })
                }
              </Grid>
              <Divider style={{width:'100%'}}/>
              {
                data.canEditLesson &&
                <Grid style={{backgroundColor: 'white', height: '40px'}} className={'nodrag'} item xs={12} sm={12} md={12}>
                  <Button 
                    disabled={data.isBusy}
                    onClick={handleDuplicate}
                    style={{
                      color: data.isBusy? 'lightgrey' : '#4aa7ec',
                      width: '67px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }}
                    variant="outlined" 
                    size="small" 
                    color="primary">
                    Duplicate
                  </Button>
                  <Button 
                    disabled={data.isBusy}
                    onClick={handleAdd} 
                    style={{
                      color: data.isBusy? 'lightgrey' : '#4aa7ec',
                      width: '66px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }} 
                    variant="outlined"
                    size="medium"
                    color="primary">
                    Branching
                  </Button>
                  <Button 
                    disabled={data.isBusy}
                    onClick={handleAddOther} 
                    style={{
                      color: data.isBusy? 'lightgrey' : '#4aa7ec',
                      width: '66px',
                      boxShadow: 'none',
                      borderRadius: '0',
                      borderLeft: '0px',
                      borderTop: '0px',
                      borderBottom: '0px',
                      borderColor: '#D1D1D6',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      height: '40px'
                    }}
                    variant="outlined"
                    size="medium"
                    color="primary">
                    Other
                  </Button>
                </Grid>
              }
          </Grid>
        </Card>
      </div>
     </Zoom>

      <CircleIcon
      disabled={true} style={{position: 'absolute', top: '120px', left: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
    <Handle
        type="target"
        position="left"
        id="target1"
        style={{ top: '124px',background: '#555', left: -9, width: '30px', height: '60px', background: 'transparent',
        zIndex: 999,
        border: 'none', }}
      >
    </Handle>

        <CircleIcon
      disabled={true} style={{position: 'absolute', top: '120px', right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
    <Handle
      type="source"
      position="right"
      id="exit0"
      style={{ top: '124px',  width: '30px', height: '60px', borderRadius: 0, right: '-6px', background: 'transparent', zIndex: 999,border: 'none',
      }}
    />
    </>
  );
});