import React, {useEffect, useRef, useState} from "react";
import { Menu, MenuItem, MenuButton, ControlledMenu, SubMenu, useHover, useMenuState } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {connect} from "react-redux";
import {makeStyles, Typography} from "@material-ui/core";

import {history} from "../../_state/helpers";
import {CapeeshColors} from "../../assets/ColorPalette";

const useStyles = makeStyles(theme => ({
  singleElementStyle: {
    fontSize: "20px",
    height: '50px',
  },
  hoverButtonStyle: {
    marginLeft:'20px',
    fontSize: '20px',
    cursor: 'pointer',
  },
}));


const AdminMenu = (props) => {

  const classes = useStyles();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  const [courseGroups, setCourseGroups] = useState([]);
  
  const [editOrganizationTitle, setEditOrganizationTitle] = useState("Edit organization");
  
  useEffect(() => {


    if (props.organization.myOrganizations && props.organization.selectedOrganization) {
      let currentOrganization = props.organization.myOrganizations.find(x => x.id === props.organization.selectedOrganization);
      if (currentOrganization) {
        setEditOrganizationTitle("Edit " + currentOrganization.name);
      } else {
        setEditOrganizationTitle("Edit organization");
      }
      
    }
    
    setCourseGroups(props.organization.availableCourseGroups)
  }, props.organization.availableCourseGroups)

  useEffect(() => {
    if (props.organization.myOrganizations && props.organization.selectedOrganization) {
      let currentOrganization = props.organization.myOrganizations.find(x => x.id === props.organization.selectedOrganization);
      if (currentOrganization) {
        setEditOrganizationTitle("Edit " + currentOrganization.name);  
      } else {
        setEditOrganizationTitle("Edit organization");
      }
    }
  }, [props.organization.selectedOrganization])  
  
  
  const subMenuLabel = (menuLabel) => {
    return (
      <Typography style={{fontSize:'20px'}}>
        {menuLabel}
      </Typography>
    );
  }

  return (
    <React.Fragment>
      {
        props.user.organizationRole !== "admin" &&
        <div className={classes.hoverButtonStyle}
             onClick={() => history.push("/admin/organizations/")}
             >
          Organizations
        </div>
      } 
      
      {
        props.user.organizationRole === "admin" &&
          <React.Fragment>
            <div 
              style={{textDecorationLine: props.underline ? 'underline':  'none', textUnderlineOffset: '10px'}}
              className={classes.hoverButtonStyle} onClick={() => history.push("/admin/organizations/")}
                 ref={ref} {...anchorProps}>
              Admin
            </div>

            <ControlledMenu
              {...hoverProps}
              state={isOpen ? 'open' : 'closed'}
              anchorRef={ref}
              onClose={() => setOpen(false)}
            >
              <MenuItem onClick={() => history.push("/admin/organizations/")} className={classes.singleElementStyle}>My Organizations</MenuItem>
              {/*<MenuItem onClick={() => history.push("/admin/edit-organization/")} className={classes.singleElementStyle}>{editOrganizationTitle}</MenuItem>*/}
              {
                props.user.organizationRole === "admin" && props.user.info?.showOverallFunctionality &&
                <MenuItem onClick={() => history.push("/admin/overall/stats")} className={classes.singleElementStyle}>Overall Stats</MenuItem>
              }
              {
                props.user.organizationRole === "admin" && props.user.info?.showOverallFunctionality &&
                <MenuItem onClick={() => history.push("/admin/overall/search")} className={classes.singleElementStyle}>Admin Search</MenuItem>
              }
            </ControlledMenu>
          </React.Fragment>
      }
      
    </React.Fragment>
  )
}


AdminMenu.propTypes = {};

const mapStateToProps = (state) => {
  return {
    organization: state.organization,
    user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu)
