import { configWithAuth, configWithoutAuth } from '../helpers';
import { makeGetRequest, makePostRequest, makePutRequest, makeDeleteRequest } from "../helpers/api";

export const lessonService = {
    getOrganizationLessons: (organizationId) => makeGetRequest('admin/lesson/custom/organization/' + organizationId, configWithAuth()),
    createCustomLesson: (payload) => makePostRequest('admin/lesson/custom/multichoice/v1', JSON.stringify(payload), configWithAuth()),
    deleteCustomLesson: (payload) => makeDeleteRequest('admin/lesson/custom/' + payload.customLessonId, JSON.stringify(payload), configWithAuth()),
    addLessonQuestion: (payload) => makePostRequest('admin/lesson/custom/' + payload.customLessonId + '/multichoice/v1', JSON.stringify(payload.questions), configWithAuth()),
    getCustomLesson: (payload) => makeGetRequest('admin/lesson/custom/' + payload.customLessonId + '/multichoice/v1', configWithAuth()),
     
    editCustomLessonDetails: (payload) => makePutRequest('admin/lesson/custom/' + payload.customLessonId + '/multichoice/v1', JSON.stringify(payload), configWithAuth()),
    editQuestion: (payload) => makePostRequest('admin/lesson/custom/' + payload.customLessonId + '/multichoice/v1/question/' + payload.questionId, JSON.stringify(payload), configWithAuth()),
    deleteQuestion: (payload) => makeDeleteRequest('admin/lesson/custom/' + payload.customLessonId + '/multichoice/v1/question/' + payload.questionId, JSON.stringify(payload), configWithAuth()),
    assignLessonToTopic: (payload) => makePostRequest('admin/lesson/custom/' + payload.customLessonId + '/assign/' + payload.topicId, JSON.stringify(payload), configWithAuth()),
    getCustomLessonRelationships: (payload) => makeGetRequest('admin/lesson/custom/assign/types', configWithAuth()),
    exportLesson: (payload) => makeGetRequest('admin/custom/lesson/'+ payload.customLessonId +'/multichoice/v1/export/csv', configWithAuth()),
    importLesson: (payload) => makePostRequest('admin/custom/lesson/' + payload.customLessonId + '/multichoice/v1/import/csv', payload.formData, configWithAuth('multipart/form-data')),
    importScenario: (payload) => makePostRequest('admin/custom/lesson/' + payload.customLessonId + '/scenario/v1/import/csv', payload.formData, configWithAuth('multipart/form-data')),
    importScenarioLinear: (payload) => makePostRequest('admin/custom/lesson/' + payload.customLessonId + '/scenario/linear/v1/import/csv', payload.formData, configWithAuth('multipart/form-data')),
    deleteFromCourse: (payload) => makeDeleteRequest('admin/lesson/custom/' + payload.customLessonId + '/assign/' + payload.topicId + '/type/' + payload.topicRelationshipType, JSON.stringify(payload), configWithAuth()),
    
    addImage: (payload) => makePostRequest("admin/organization/"+payload.organizationId + "/image/" + payload.queryParams, payload.formData, configWithAuth('multipart/form-data')),
    deleteImage: (payload) => makeDeleteRequest('admin/organization/' + payload.organizationId + '/image/' + payload.fileReferenceId, JSON.stringify(payload), configWithAuth()),
    getImageReferences: (payload) => makeGetRequest('admin/organization/'+payload.organizationId + '/image/file/references' + payload.queryParams, configWithAuth()),
    addAudio: (payload) => makePostRequest("admin/organization/"+payload.organizationId + "/audio/" + payload.queryParams, payload.formData, configWithAuth('multipart/form-data')),
    getAudioReferences: (payload) => makeGetRequest('admin/organization/'+payload.organizationId + '/audio/file/references', configWithAuth()),
    getLessonAudioReferences: (payload) => makeGetRequest('admin/organization/'+payload.organizationId + '/custom/lesson/' + payload.customLessonId + '/audio/file/references/', configWithAuth()),
    getImage: (payload) => makeGetRequest('admin/organization/'+payload.organizationId + '/image/file/reference/' + payload.fileReferenceId + '/base64', configWithAuth()),
    getAudio: (payload) => makeGetRequest('admin/organization/'+payload.organizationId + '/audio/file/reference/' + payload.fileReferenceId + '/base64', configWithAuth()),
    
    getScenario: (payload) => makeGetRequest('admin/lesson/custom/'+payload.customLessonId + '/scenario/v1', configWithAuth("application/json", payload.ifModifiedSince)),
    
    createScenario: (payload) => makePostRequest('admin/lesson/custom/scenario/v1', JSON.stringify(payload), configWithAuth()),
    geniusScenario: (payload) => makePostRequest('admin/lesson/custom/scenario/v1', JSON.stringify(payload), configWithAuth()),
    updateScenario: (payload) => makePostRequest('admin/lesson/custom/' +payload.id + '/scenario/v1', JSON.stringify(payload), configWithAuth()),
    updateScenarioNode: (payload) => makePutRequest('admin/lesson/custom/' +payload.customLessonId + '/scenario/node/v1', JSON.stringify(payload), configWithAuth()),

    canPublishScenario: (customLessonId, lessonGenerationIndex) => makeGetRequest('admin/lesson/custom/' + customLessonId + '/scenario/generation/index/' + lessonGenerationIndex + '/can/publish', configWithAuth()),
    publishScenario: (payload) => makePostRequest('admin/lesson/custom/'+payload.customLessonId + '/scenario/v1/publish', JSON.stringify({}), configWithAuth()),


    getCustomLessonSupportedLanguages: (payload) => makeGetRequest('admin/custom/lesson/' + payload.customLessonId + '/supported/language', configWithAuth()),
    addCustomLessonSupportedLanguage: (payload) => makePostRequest('admin/custom/lesson/' + payload.customLessonId + '/supported/language/' + payload.l1Id, JSON.stringify(payload), configWithAuth()),
    deleteCustomLessonSupportedLanguage: (payload) => makeDeleteRequest('admin/custom/lesson/' + payload.customLessonId + '/supported/language/' + payload.l1Id, JSON.stringify(payload), configWithAuth()),

    importExcelCustomLessonTranslations: (payload) => makePostRequest('admin/lesson/custom/' + payload.customLessonId + '/language/' + payload.l1Id + '/translation/excel/import', payload.formData, configWithAuth('multipart/form-data')),
    exportExcelCustomLessonTranslations: (payload) => makeGetRequest('admin/lesson/custom/' + payload.customLessonId + '/language/' + payload.l1Id + '/translation/excel/export?useBase64=true', configWithAuth()),
    
    getLessonVersions: (payload) => makeGetRequest('admin/lesson/custom/' + payload.customLessonId + '/versions', configWithAuth()),
    revertToLessonVersion: (payload) => makePutRequest('admin/lesson/custom/' + payload.customLessonId + '/version/choose/' + payload.customLessonVersionId, JSON.stringify({}), configWithAuth()),
    
}
