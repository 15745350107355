import {call, put, takeLatest} from 'redux-saga/effects'
import {backgroundJobsService, topicService} from '../services'
import {backgroundJobsConstants, topicConstants} from '../actions'
import {delay} from 'redux-saga/effects'
import {snackbarActions} from "../actions/snackbar.actions";

export function* getTopicJobs(action) {
    try {
        let refresh = true
        const response = yield call(backgroundJobsService.getTopicStatus, action.payload);
        yield put({type: backgroundJobsConstants.GET_TOPIC_JOBS.SUCCESS, data: response.data});
    } catch (e) {
        let  usefulInformation = "Unable to download the course data.";
        if (e.response && e.response.data && e.response.data != "Server error") {
            usefulInformation = e.response.data;
        }
        yield put(snackbarActions.enqueueSnackbar({
                message: usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: backgroundJobsConstants.GET_TOPIC_JOBS.FAILURE, message: e.message});
    }
}

export function* waitForJobs(action) {
    try {
        let refresh = true;
        let completeUpdate = true;
        const englishSupport = checkEnglishSupport(action);
        switch(action.payload.jobType)
        {
            case 0:
                while(refresh) {
                    const response = yield call(backgroundJobsService.getTopicStatus, action.payload);
                    yield put({type: backgroundJobsConstants.GET_TOPIC_JOBS.SUCCESS, data: response.data});
                    if(response.data.length > 0) {
                        let l1NotReady = false;
                        response.data.forEach(element => {
                            if(element.l1Id === action.payload.l1Id && element.progression === 100 && l1NotReady === false) {
                                refresh = false;
                            }
                            if(element.l1Id === action.payload.l1Id && element.progression != 100) {
                                l1NotReady = true;
                                refresh = true;
                            }
                        });
                        yield delay(1000);
                    } else {
                        completeUpdate = false;
                        refresh = false;
                    }
                }
                if(completeUpdate) {
                    const responseTopicRefresh = yield call(topicService.getCurrentTopic, action.payload);
                    yield put({type: topicConstants.GET_CURRENT_TOPIC.SUCCESS, data: responseTopicRefresh.data});
                    //yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefresh.data});
                    const responseTopicRefreshL1 = yield call(topicService.getTopicWordsForL1, action.payload);
                    yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseTopicRefreshL1.data});
                    if(englishSupport) {
                        const responseEnglish = yield call(topicService.getTopicWordsForEnglish, action.payload);
                        yield put({type: topicConstants.GET_TOPIC_WORDS_ENGLISH.SUCCESS, data: responseEnglish.data});
                    } else {
                        yield put({type: topicConstants.UPDATE_TOGGLE.SUCCESS, data: 'false'});
                    }
                    yield put({type: backgroundJobsConstants.WAIT_FOR_JOBS.SUCCESS, data: responseTopicRefresh.data});
                    if (action.payload.selectedWords) yield put({type: topicConstants.CHANGE_SELECTED_WORD.SUCCESS, payload: action.payload});
                    yield put({type: topicConstants.CHANGE_TRANSLATION.SUCCESS, data: action.payload});
                    yield put({type: topicConstants.ADD_SUPPORTED_LANGUAGE.SUCCESS, data: action});
                }
                break;
            case 1:
                while(refresh) {
                    //yield put({type: backgroundJobsConstants.WAIT_FOR_JOBS.REQUEST, data: action.payload});
                    yield delay(3000);
                    const response = yield call(backgroundJobsService.getTopicStatus, action.payload);
                    yield put({type: backgroundJobsConstants.GET_TOPIC_JOBS.SUCCESS, data: response.data});
                    refresh = false
                    response.data.forEach(element => {
                        if( element.progression !== 100) {
                            refresh = true;
                        }
                    });
                }
                const responseTopicRefreshAdd = yield call(topicService.getTopic, action.payload);
                yield put({type: topicConstants.GET_TOPIC.SUCCESS, data: responseTopicRefreshAdd.data});
                const responseTopicRefreshL1Add = yield call(topicService.getTopicWordsForL1, action.payload);
                yield put({type: topicConstants.GET_TOPIC_WORDS_L1.SUCCESS, data: responseTopicRefreshL1Add.data});
                if(englishSupport) {
                    const responseEnglish = yield call(topicService.getTopicWordsForEnglish, action.payload);
                    yield put({type: topicConstants.GET_TOPIC_WORDS_ENGLISH.SUCCESS, data: responseEnglish.data});
                } else {
                    yield put({type: topicConstants.UPDATE_TOGGLE.SUCCESS, data: 'false'});
                }
                yield put({type: backgroundJobsConstants.WAIT_FOR_JOBS.SUCCESS, data: action.payload});
                yield put({type: topicConstants.GET_IGNORED_WORDS.REQUEST, payload:  {topicId: action.payload.topicId}});
                break;
            default: break;
        }
    } catch (e) {
        let  usefulInformation = "Unable to download the course data.";
        if (e.response && e.response.data && e.response.data != "Server error") {
            usefulInformation = e.response.data;
        }
        yield put(snackbarActions.enqueueSnackbar({
                message: usefulInformation,
                options: {
                    variant: "warning"
            }
        }));
        yield put({type: backgroundJobsConstants.GET_TOPIC_JOBS.FAILURE, message: e.message});
    }
}

function checkEnglishSupport(data) {
    let englishSupport = false
    if (data.payload.supportedLanguages == null || data.payload.supportedLanguages.length == 0) {
        return false;
    }
    data.payload.supportedLanguages.forEach(language => {
        if(language.l1Id == 26 || language.l1Id == 1) {
            englishSupport =  true;
        }
    });
    return englishSupport

}

export function* generalReset(action) {
    try {
        yield put({type: topicConstants.GENERAL_RESET.REQUEST});
        yield put({type: topicConstants.GENERAL_RESET.SUCCESS});
    } catch (e) {
        yield put(snackbarActions.enqueueSnackbar({
                message: e.message,
                options: {
                    variant: "warning"
            }
        }));
    }
}

//Listeners
export function* backgroundJobsSagas() {
    yield takeLatest(backgroundJobsConstants.GET_TOPIC_JOBS.REQUEST, getTopicJobs);
    yield takeLatest(backgroundJobsConstants.WAIT_FOR_JOBS.REQUEST, waitForJobs);
}