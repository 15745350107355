import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import {
  Area,
  AreaChart, BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer, Tooltip,
  XAxis,
  YAxis,
  
} from "recharts";
import Card from "@material-ui/core/Card";
import React, {useState} from "react";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {CapeeshColors} from "../../assets/ColorPalette";
import {FormattedMessage} from "react-intl";


export const DailyUsageYLabelType = {
  "NumberOfLessons": 0,
  "DailyUsage":1,
  "AggreatedUsageOverTime": 2,
  "NoLocalization": 3,
  
};
Object.freeze(DailyUsageYLabelType);


const DailyUsageGraph = (props) => {

  const formatValues = (value) => {
    if (!props.isMinutes) {
      return value
    }
    
    let min = value;
    const hours = (min / 60);
    const flooredHours = Math.floor(hours);
    const minutes = (hours - flooredHours) * 60;
    const roundedMinutes = Math.round(minutes);

    if(flooredHours === 0 && roundedMinutes === 0)
      return "";

    let minutesString = roundedMinutes + " m";
    
    if(flooredHours === 0) {
      return minutesString + ".";
    }

    let rhoursString = flooredHours + " h";
    return rhoursString + " " + minutesString + ".";
  };

  const formatMinutesYAxis = (value) => {
    if (props.isMinutes) {
      if (value < 5) {
        return value.toFixed(1) + " m";
      }
      return value.toFixed(0) + " m";
    }
    return value
    
  }
  
  const getNormalColor = () => {
    return CapeeshColors.Orange
  }
  const getBrightColor = () => {
    return CapeeshColors.Orange
  }
  
  
  
  return (
    <Card style={{overflow: 'visible'}}>
      <CardHeader
        title={props.title}
        subheader={props.canShowChart ? props.headerMessage : props.defaultHeaderMessage}
      />
      <CardContent>
        <Grid item xs={12}>
          {
            props.canShowChart &&
            <div style={{width: '100%', height: 400}}>
              <ResponsiveContainer>
                <AreaChart
                  data={props.graphData}
                  margin={{
                    top: 50, right: 30, left: 30, bottom: 80,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tickFormatter={tick => moment(tick).format("MMM Do")} angle={-60} dx={-20} dy={30}>
                  </XAxis>
                  <YAxis 
                    dataKey={'value'}
                    tickFormatter={tick => `${formatMinutesYAxis(tick)}`}
                    label={{ value: props.yLabel, angle: -90, position: 'left'}}
                  >
                  </YAxis>
                  {
                    props.showReferenceLine &&
                    <ReferenceLine y={(props.graphData.reduce((a, b) => a + b["value"], 0) / props.graphData.length).toFixed(2)} stroke="grey" label="Average" />  
                  }
                  
                  <Tooltip content={({ active, payload, label }) => {
                    if (active && payload) {
                      return (
                        <Card>
                          <CardContent>
                            <Typography variant={"h6"}>
                              {`${moment(label).format("LL")}`}
                            </Typography>
                            {
                              <Typography variant={"body1"}>
                                {
                                  props.toolTipYLabelType === DailyUsageYLabelType.NumberOfLessons &&
                                  <FormattedMessage id="dashboard.classroom.graphs.dailylessons.tooltip" defaultMessage="Number of lessons">
                                    {(numberOfLessonsText) => numberOfLessonsText + ": " + formatValues(props.graphData.find(x => x.name === label).value) }
                                  </FormattedMessage>
                                }
                                {
                                  props.toolTipYLabelType === DailyUsageYLabelType.DailyUsage &&
                                  <FormattedMessage id="dashboard.classroom.graphs.dailyusage.title" defaultMessage="Daily usage">
                                    {(dailyUsageText) => dailyUsageText + ": " + formatValues(props.graphData.find(x => x.name === label).value) }
                                  </FormattedMessage>
                                }
                                {
                                  props.toolTipYLabelType === DailyUsageYLabelType.AggreatedUsageOverTime &&
                                  <FormattedMessage id="dashboard.classroom.graphs.dailyusage.tooltip" defaultMessage="Aggregated usage over time">
                                    {(aggUsageOverTimeText) => aggUsageOverTimeText + ": " + formatValues(props.graphData.find(x => x.name === label).value) }
                                  </FormattedMessage>
                                }
                                {
                                  (!props.toolTipYLabelType || props.toolTipYLabelType === DailyUsageYLabelType.NoLocalization) &&
                                  props.toolTipPrefix}: {formatValues(props.graphData.find(x => x.name === label).value)
                                }
                              </Typography>
                            }
                          </CardContent>
                        </Card>
                      );
                    }
                    return null;
                  }}/>
                  {
                    <Area type="monotone" dataKey="value" stackId="1" strokeWidth={2} stroke={getNormalColor()} fill={getBrightColor()}/>
                  }
                </AreaChart>
              </ResponsiveContainer>
            </div>
          }
          
        </Grid>
      </CardContent>
    </Card>
  )
}


function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyUsageGraph)