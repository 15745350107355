import React, {useState, useRef} from 'react';
import {Typography,makeStyles, } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import {AutoCourseCreatorRuleCardPlotTypes } from "../../../../../Logic/AutoCourseCreationConstants";
import ButtonBase from '@mui/material/ButtonBase';

import {v4 as uuidv4} from "uuid";
import { Droppable, Draggable } from "react-beautiful-dnd";
import AccStageSettingsInteractionSelection from "./ACCStageSettingsInteractionSelection";


const useStyles = makeStyles(theme => ({
  stagePlotType: {
    display: 'inline-block', color: "#35323C",
    fontFamily: "Nunito",
    fontSize:14,
    fontStyle: "normal",
    fontWeight:400,
    letterSpacing:0.41,borderRadius:5, border: "1px solid var(--Gray-1, #8E8E93)", marginTop: '10px', padding: '5px'
  },
  stagePlotTypeSelected: {
    display: 'inline-block', color: "white",
    fontFamily: "Nunito",
    fontSize:14,
    fontStyle: "normal",
    fontWeight:400,
    letterSpacing:0.41,borderRadius:5, border: "1px solid var(--Gray-1, #8E8E93)", marginTop: '10px', padding: '5px'
    ,background: '#44A6EF'
  }
}));

const ACCStageSettingsRuleCard = (props) => {
  const classes = useStyles();
  let circleStyle = {
    padding:10,
    margin:20,
    //display:"inline",
   // backgroundColor: 'green',
    borderRadius: "50%",
    width:30,
    height:30,
  };

  return (
    <React.Fragment>
      <Typography style={{ fontFamily: 'Rubik', fontSize: '16px',   color: 'black', letterSpacing: '0.31px', textAlign:'left', }}>
        The Rule Card stage is great for displaying short or long passages of text, such as longer explanations and lesson welcome and summaries. 
      </Typography>
    <Typography style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '16px',   color: 'black', letterSpacing: '0.31px', textAlign:'left', marginTop: '20px'}}>
      Choose how you would like to use the rule card stage in your lesson:
    </Typography>
    <Grid container>
      {AutoCourseCreatorRuleCardPlotTypes.map((exercise, index) => (
        <Grid item xs={12} style={{}}>
          <ButtonBase onClick={() => props.onToggleStagePlot("RuleCard", exercise.plotType, props.selectedLesson.lessonPlanConfigurationDto.exercises.some(ex => ex.exerciseType === "RuleCard" && ex.plotType === exercise.plotType) )}  >
            <Typography className={
              props.selectedLesson.lessonPlanConfigurationDto.exercises.some(ex => 
                ex.exerciseType === "RuleCard" && 
                ex.plotType === exercise.plotType) ? 
                classes.stagePlotTypeSelected : classes.stagePlotType
            }>
              {exercise.friendlyText}
            </Typography>
          </ButtonBase>
        </Grid>
      ))}
      <AccStageSettingsInteractionSelection headerText="How should the learner answer the questions?" selectedInteractionStyle={props.interactionType} onUpdateLessonSuggestionDto={props.onUpdateLessonSuggestionDto} exerciseType={"RuleCard"} selectedLesson={props.selectedLesson} />
    </Grid>
    </React.Fragment>
  );
};

ACCStageSettingsRuleCard.propTypes = {};

const mapStateToProps = (state) => {
  return {
    languages: state.metadata.metadata.languages,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCStageSettingsRuleCard)