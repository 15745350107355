import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import Button from "@mui/material/Button";

const useStyles = makeStyles(theme => ({
}));

const ACCContinueButton = (props) => {
    
    // props.click
    // props.text
    // props.disabledCondition
    
    const classes = useStyles();

    return (
        <div style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", display: "flex", marginTop: 16 }}>
            <Button
                disabled={props.disabledCondition}
                onClick={(e) => props.click()}
                style={{
                    borderRadius: 8,
                    background: "var(--Blue, #44A6EF)",
                    transition: "border-radius 1s ease, opacity 1.1s ease",
                    opacity: props.disabledCondition ? 0.5 : 1,
                    textTransform: 'none',
                    color: 'white',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    height: '50px',
                    fontSize: '16px',
                    fontFamily: "Rubik",
                }}>
                {props.text}
            </Button>
        </div>
    );
};

ACCContinueButton.propTypes = {};

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ACCContinueButton)