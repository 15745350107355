import React, {useState, useRef} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

const FocusAreaListeningToken = (props) => {
  const color = (!props.disabled ? props.selected ? props.hover ? "rgba(255, 221, 129, 0.32)" : "#E5E5EA" :  "url(#paint0_linear_1433_23271)" : "#E5E5EA");
  return (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill={color}/>
    <g clip-path="url(#clip0_1433_23271)">
    <path d="M10.6673 10.6663H21.334V18.6663H11.4473L10.6673 19.4463V10.6663ZM10.6673 9.33301C9.93398 9.33301 9.34065 9.93301 9.34065 10.6663L9.33398 22.6663L12.0007 19.9997H21.334C22.0673 19.9997 22.6673 19.3997 22.6673 18.6663V10.6663C22.6673 9.93301 22.0673 9.33301 21.334 9.33301H10.6673ZM12.0007 15.9997H17.334V17.333H12.0007V15.9997ZM12.0007 13.9997H20.0007V15.333H12.0007V13.9997ZM12.0007 11.9997H20.0007V13.333H12.0007V11.9997Z" fill="white"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_1433_23271" x1="0" y1="0" x2="32" y2="32" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFDD81"/>
    <stop offset="1" stop-color="#FFD256"/>
    </linearGradient>
    <clipPath id="clip0_1433_23271">
    <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
    </clipPath>
    </defs>
  </svg>
  )
}

FocusAreaListeningToken.propTypes = {
  
};


function mapStateToProps(state) {
  return {
    
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FocusAreaListeningToken)