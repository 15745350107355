import React, {useState} from "react";
import {
    Grid,
} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {TutorialSection} from "../../../../Logic/ScenarioConstants";
import Carousel from 'react-material-ui-carousel'
import GuideWatchHotel from "../../../../assets/images/GuideWatchHotel.png";
import GuideWatchCar from "../../../../assets/images/GuideWatchCar.png";
import GuideWatchHouseParty from "../../../../assets/images/GuideWatchHouseParty.png";


const useStyles = makeStyles(theme => ({
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    //marginRight: '15px',
    cursor: 'pointer',
  },
  leftBox: {
/* Gray 3 */
background: '#EFEFEF',
border: '1px solid #D1D1D6',
boxSizing: 'border-box',
boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
borderRadius: '20px'
  },
  leftPanel: {
    minWidth: '400px'
  },
  mainContainer: {
    paddingLeft: '25px',
    paddingRight: '25px'
  }, 
  rightPaper: {
    /* White */
  //  marginLeft: '20px',
marginTop: '30px',
background: '#FFFFFF',
/* Gray 3 */

border: '1px solid #D1D1D6',
boxSizing: 'border-box',
borderRadius: '15px',
  }


}));

const TutorialWatch = (props) => {
    const classes = useStyles();
    const [selectedSection, setSelectedSection] = useState(TutorialSection.Home);
    const CarouselItem = (props) => 
    {
        return (
          <Grid container >
            <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'left',}}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12}>
                  {tutorialSubHeader(props.item.name)}
                  {tutorialParagraph(props.item.text1)}
                  {tutorialParagraph(props.item.text2)}
                  {tutorialParagraph(props.item.text3)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '5px'}}>
              <img style={{ flex: 1, color: 'black', height: '400px', textAlign: 'center', alignItems: 'center'}} src={props.item.imageAddress} />          
            </Grid>
          </Grid>
        )
    }

    var items = [
      {
          name: "Hotel reception desk worker",
          text1: "This Cutscene stage is looking out at a hotel lobby. This sets the scene for a hotel check-in role play. Imagine hotel lobby ambient sounds helping to set the scene and create a little atmosphere.",
          text2: "Depending on the course, a customer could be shown in the scene also, preparing the learner for a conversation with the customer.",
          text3: "After the stage passes, the role play moves on to a Branching stage, where the learner must welcome the hotel guest.",
          imageAddress: GuideWatchHotel
      },
      {
          name: "Emergency Rescue Department",
          text1: "A Cutscene stage showing a blurry road passing by. A car brake screeching sound effect plays. Together this sets the scene nicely for a Fire Department role play at the scene of a car accident.",
          text2: "The role play then moves onto a Branching stage, where the learner introduces themselves to a crash victim.",
          text3: "The entire stage passes by in around two or three seconds, but makes a huge difference to how the role plays begins and helps to drive engagement.",
          imageAddress: GuideWatchCar
      },
      {
          name: "House party role play",
          text1: "In this role play for a progressive language course, the learner has just arrived at a friend's house party. The scene is set by their friend walking to the door to let them in.",
          text2: "An additional Cutscene stage could even play before this one, in which a doorbell sound effect plays",
          text3: "Once again, the entire stage only lasts for a couple of seconds.",
          imageAddress: GuideWatchHouseParty
      }
    
      
    ]
    const tutorialParagraph = (text) => {
      return (
        <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontSize: '16px', marginBottom: '10px'}}>
          {text}
        </Typography>
      )
     }

     const tutorialSubHeader = (text) => {
      return (
        <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', marginTop: '10px'}}>
          {text}
        </Typography>
      )
     }

     const tutorialHeader = (text) => {
      return (
        <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', marginBottom: '20px'}}>
          {text}
        </Typography>

      )
     }


    return (
      <Grid container style={{paddingLeft: '50px', paddingTop: '40px', paddingRight: '20px'}}> 
      {tutorialHeader("The Cutscene Stage")}
    <Grid item xs={12} sm={12} md={12} style={{display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
      <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: '35px'}}>
          <iframe src="https://player.vimeo.com/video/684241985?h=25ccc60d8a" style={{alignItems: 'center'}} width="768" height="432"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </div>
    </Grid>
      {tutorialSubHeader("How it works")}
      {tutorialParagraph("Cutscene stages can be thought of giving us the opportunity to help build a narrative for a role play. They help set the scene, or create more engaging stories. Learners are typically more engaged in a lesson that starts with a Cutscene stage.")}
      {tutorialSubHeader("Adding some heart and soul to your lesson")}
      {tutorialParagraph("Let's say you are creating a furniture delivery role play. You may wish to start the role play with a Cutscene stage that shows a doorbell ringing. This helps set the scene, and adds some support to the story you are trying to create.")}
      {tutorialParagraph("Another example might be a role play for an employee working at a hotel reception, serving customers. The Cutscene stage could show a customer walking towards the desk, nicely setting the scene for the role play.")}
      {tutorialSubHeader("Moving characters")}
      {tutorialParagraph("Cutscene stages enable you to make a caricature walk around the screen. Let's say you are creating a role play where the learner plays the role of working in a coffee shop. You may wish to create a Cutscene stage showing a customer walking towards you.")}
      {tutorialSubHeader("Breaking up the pace of a role play")}
      {tutorialParagraph("Role plays can be used to break up longer role plays, and to offer a relief to a learner after long conversation dialogues. You can make your role play's caricature walk off screen, and into a new cutscene.")}
    <div style={{marginTop: '10px'}} >
      {tutorialHeader("Cutscene stage examples")}
    </div>
      {tutorialParagraph("Scroll through the carousel below to look at different examples of how Cutscene stages are used.")}
    <Grid item xs={12} sm={12} md={12} style={{marginTop:'25px'}} >
      <Carousel interval={8000}>
      {
          items.map( (item, i) => <CarouselItem key={i} item={item} /> )
      }
</Carousel>
</Grid>
    {tutorialParagraph("Scroll through the carousel above to look at different examples of how Cutscene stages are used.")}
  <div style={{marginTop: '10px'}} >
      {tutorialHeader("Making characters walk")}
    </div>
    {tutorialParagraph("In a Cutscene stage, it is possible to make the Capeesh characters walk around the phone screen. This is great for adding some life to your role play. Adding animations to your character takes only seconds to implement.")}
    {tutorialSubHeader("Walking from A to B")}
    {tutorialParagraph("To make your character walk from position A to position B is very simple: First, create a Cutscene stage and position the character where you would like on the screen. Also resize the character as appropriate. Once you are happy, save the stage.")}
    {tutorialParagraph("Next, next click the duplicate button on the cutscene stage. This creates an exact copy of the Cutscene stage. Connect the first cutscene stage up to the second.")}
    {tutorialParagraph("We now have two idenical Cutscene stages that are connected to each other. All we need to do is move the character in the second Cutscene stage to the position and size that we would like. If you try the stage in the app, you will see that the mentor walks from position A to position B automatically.")}
    {tutorialParagraph("The video above shows a walk-through of doing this.")}

  </Grid>
    );
    }

    TutorialWatch.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(TutorialWatch)