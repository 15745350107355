import {asyncRequest} from "./asyncRequest";

export const overallConstants = {
  GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED: new asyncRequest('GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED'),
  GET_GRAPH_DATA_DAILY_USER_ONBOARDING: new asyncRequest('GET_GRAPH_DATA_DAILY_USER_ONBOARDING'),
  GET_OVERALL_SEARCH_RESULT: new asyncRequest('GET_OVERALL_SEARCH_RESULT'),
  RESET_OVERALL_SEARCH: new asyncRequest('RESET_OVERALL_SEARCH'),
};

export const overallActions = {
  getGraphDataDailyUsageAggregated() {
    return {
      type: overallConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED.REQUEST,
      payload: {}
    }
  },
  
  getGraphDataDailyUserOnboarding() {
    return {
      type: overallConstants.GET_GRAPH_DATA_DAILY_USER_ONBOARDING.REQUEST,
      payload: {}
    }
  },

  getOverallSearchResult(searchWord, maxResultsPerPage, offset) {
    
    var queryParams = []
    queryParams.push("searchWord=" + searchWord)
    queryParams.push("maxResultsPerPage=" + maxResultsPerPage)
    queryParams.push("offset=" + offset)
    
    var queryParamAsText = ""
    if (queryParams.length > 0) {
      queryParamAsText = "?" + queryParams.join("&", queryParams);
    }
    return {
      type: overallConstants.GET_OVERALL_SEARCH_RESULT.REQUEST,
      payload: {
        queryParamAsText
      }
    }
  },
  
  resetOverallSearch() {
    return {
      type: overallConstants.RESET_OVERALL_SEARCH.REQUEST,
      payload: {
        
      }
    }
  }
  
}