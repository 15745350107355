export const CapeeshColors = {
  "TealBright": "#3CE0D3",
  "GreenBright":"#80D387",
  "YellowBright":"#FFD256",
  "PinkBright":"#FF666E",
  "BlueBright":"#5AB4F1",
  "OrangeBright":"#FF7A3B",
  "PurpleBright":"#AE59AD",
  "Yellow": "#FFBE40",
  "Orange": "#FF7A3B",
  "Pink": "#FF5159",
  "Purple": "#A0439F",
  "Blue": "#44A6EF",
  "Teal": "#50B4B9",
  "Green": "#63C276",
  "DeepBlue": '#003C58',
  "Gray1": "#8E8E93",
  "Gray4": "#E5E5EA",
  "Gray5": '#EFEFF4',
  "DarkText": '#35323C',
  "OffWhite": '#F8F8F8',
  "Gray2": '#C7C7CC',
  "EggShell1": '#FBFAF7',
  "CorporateBlue2024": "rgba(9, 40, 79, 1)"
};
Object.freeze(CapeeshColors);




export const DAY_OF_WEEK_HIGH_CONTRAST_COLORS_BRIGHT = [
  CapeeshColors.PurpleBright,
  CapeeshColors.PinkBright,
  CapeeshColors.BlueBright,
  CapeeshColors.YellowBright,
  CapeeshColors.GreenBright,
  CapeeshColors.OrangeBright,
  CapeeshColors.TealBright,
];

export const DAY_OF_WEEK_HIGH_CONTRAST_COLORS = [
  CapeeshColors.Purple,
  CapeeshColors.Pink,
  CapeeshColors.Blue,
  CapeeshColors.Yellow,
  CapeeshColors.Green,
  CapeeshColors.Orange,
  CapeeshColors.Teal,
];