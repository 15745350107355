import {organizationConstants, topicConstants} from '../actions';
import { authConstants } from '../actions';

const initialState = {
    error: '',
    files: [],
    topicDocuments: [],
    topics: [],
    filesProcessing: [],
    processingFiles: false,
    documentWords: [],
    topicWords: [],
    loadingTopicWords: false,
    topicWordsForL1: [],
    loadingTopicWordsForL1: false,
    ignoredWords: [],
    creatingTopic: false,
    createTopicStep: 0,
    completedProcessing: false,
    notifyPublished: false,
    usergroupsForTopic: [],
    cloneCompleted: true,
    clonedCourseId: null,
    updatingTranslation: false,
    snippetsReadyForConfirm: false,
    snippetsCreatedTranslationArray: [],
    sharedCourses: [],
    loadingTopicWordsForEnglish: false,
    topicWordsForEnglish: [],
    processingTopicWords: false,
    currentTopicSelectedLanguage: null,
    loadingCurrentTopic: false,
    currentTopicRequestedL1s: [],
    isImporting: false,
    manualWordsRequiredNLP: null,
    specifyWords: [
        [{ value: '',  width: '300px' }, { value: '',  width: '100px' }],
      ],
    topicDifficulties: [],
    lessonOrder: [],
    exportingTranslations: false,
    
    topicLessonOrderIndex: {}
    
};

export function topic(state = initialState, action) {
    switch (action.type) {
        case topicConstants.ADD_FILE.REQUEST:
            return {
                ...state,
                filesProcessing: [...(state.filesProcessing ? state.filesProcessing : []), action.payload.name],
                processingFiles: true,
            };
        case topicConstants.ADD_FILE.SUCCESS:
            return {
                ...state,
                filesProcessing: [],
                processingFiles: false,
                completedProcessing: true
            };
        case topicConstants.ADD_FILE.FAILURE:
            return {
                ...state,
                error: action.message,
                filesProcessing: [],
                processingFiles: false,
            };

        case topicConstants.DELETE_FILE.REQUEST:
            return {
                ...state,
            };
        case topicConstants.DELETE_FILE.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.DELETE_FILE.FAILURE:
            return {
                ...state,
                error: action.message
            };
        case topicConstants.PUBLISH_TOPIC.REQUEST:
            return {
                ...state,
                notifyPublished: false
            };
        case topicConstants.PUBLISH_TOPIC.SUCCESS:
            return {
                ...state,
                notifyPublished: true
            };
        case topicConstants.PUBLISH_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message
            };
        case topicConstants.ADD_URL.REQUEST:
            return {
                ...state,
                filesProcessing: [...(state.filesProcessing ? state.filesProcessing : []), action.payload.url],
                processingFiles: true,
            };
        case topicConstants.ADD_URL.SUCCESS:
            return {
                ...state,
                filesProcessing: [],
                processingFiles: false,
                completedProcessing: true
            };
        case topicConstants.ADD_URL.FAILURE:
            return {
                ...state,
                error: action.message,
                filesProcessing: [],
                processingFiles: false,
            };
            
        case topicConstants.EDIT_TOPIC_RESET.REQUEST:
            return {
                ...state,
                processingFiles: false,
                filesProcessing: [],
                generatingSnippets: false,
                completedProcessing: false,
                notifyPublished: false,
                creatingTopic: false,
                clonedCourseId: null,
                cloneCompleted: false,
                updatingTranslation: false,
                snippetsReadyForConfirm: false,
                snippetsCreatedTranslationArray: [],
                topicWordsForL1: [],
                topicWordsForEnglish: [],
                isImporting: false,
                manualWordsRequiredNLP: null,
                specifyWords: [
                    [{ value: '',  width: '300px' }, { value: '',  width: '100px' }],
                ],
                topicDifficulties: [],
                lessonOrder: []
            };
        case topicConstants.EDIT_TOPIC_RESET.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.EDIT_TOPIC_RESET.FAILURE:
            return {
                ...state,
                error: action.message,
            };
            
        case topicConstants.GENERAL_RESET.REQUEST:
            return {
                ...state,
                processingFiles: false,
                filesProcessing: [],
                generatingSnippets: false,
                completedProcessing: false,
                notifyPublished: false,
                creatingTopic: false,
                clonedCourseId: null,
                cloneCompleted: false,
                updatingTranslation: false,
                snippetsReadyForConfirm: false,
                snippetsCreatedTranslationArray: [],
                topics: [],
                topicWordsForL1: [],
                topicWordsForEnglish: [],
                isImporting: false,
                manualWordsRequiredNLP: null,
                specifyWords: [
                    [{ value: '',  width: '300px' }, { value: '',  width: '100px' }],
                  ],
                topicDifficulties: [],
                lessonOrder: []

            };
        case topicConstants.GENERAL_RESET.SUCCESS:
            return {
                ...state, 
            };
        case topicConstants.GENERAL_RESET.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.SET_COMPLETED_PROCESSING.REQUEST:
            return {
                ...state,
                completedProcessing: false
            };
        case topicConstants.SET_COMPLETED_PROCESSING.SUCCESS:
            return {
                ...state, 
            };
        case topicConstants.SET_COMPLETED_PROCESSING.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.GET_FILES.REQUEST:
            return {
                ...state,
                documentWords: [],
            };
        case topicConstants.GET_FILES.SUCCESS:
            return {
                ...state,
                files: action.data.documents
            };
        case topicConstants.GET_FILES.FAILURE:
            return {
                ...state,
                error: action.message
            };

        case topicConstants.GET_TOPIC_DOCUMENTS.REQUEST:
            return {
                ...state,
            };
        case topicConstants.GET_TOPIC_DOCUMENTS.SUCCESS:
            return {
                ...state,
                topicDocuments: action.data
            };
        case topicConstants.GET_TOPIC_DOCUMENTS.FAILURE:
            return {
                ...state,
                error: action.message
            };
            
        case topicConstants.DELETE_TOPIC_DOCUMENT.REQUEST:
            return {
                ...state,
            };
        case topicConstants.DELETE_TOPIC_DOCUMENT.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.DELETE_TOPIC_DOCUMENT.FAILURE:
            return {
                ...state,
                error: action.message
            };

        case topicConstants.GET_DOCUMENT_WORDS.REQUEST:
            return {
                ...state,
                fetchingDocumentWords: true,
            };
        case topicConstants.GET_DOCUMENT_WORDS.SUCCESS:
            return {
                ...state,
                documentWords: action.data,
                fetchingDocumentWords: false,
            };
        case topicConstants.GET_DOCUMENT_WORDS.FAILURE:
            return {
                ...state,
                error: action.message,
                fetchingDocumentWords: false,
            };
        case topicConstants.CLONE_COURSE.REQUEST:
            return {
                ...state,
            };
        case topicConstants.CLONE_COURSE.SUCCESS:
            return {
                ...state,
                cloneCompleted: true,
                clonedCourseId: action.data.id
            };
        case topicConstants.CLONE_COURSE.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case topicConstants.UPDATE_TOPIC.REQUEST:
            return {
                ...state,
            };
        case topicConstants.UPDATE_TOPIC.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.UPDATE_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message
            };

        case topicConstants.CREATE_TOPIC.REQUEST:
            return {
                ...state,
                creatingTopic: true,
                files: [],
                documentWords: [],
            };
        case topicConstants.CREATE_TOPIC.SUCCESS:
            return {
                ...state,
                creatingTopic: false,
                topics: [...state.topics, action.data]
            };
        case topicConstants.CREATE_TOPIC.FAILURE:
            return {
                ...state,
                creatingTopic: false,
                error: action.message,
            };
        case topicConstants.GET_ALL_TOPICS.REQUEST:
            return {
                ...state,
                loadingTopics: true,
            };
        case topicConstants.GET_ALL_TOPICS.SUCCESS:
            return {
                ...state,
                loadingTopics: false,
                topics: action.data.topics
            };
        case topicConstants.GET_ALL_TOPICS.FAILURE:
            return {
                ...state,
                loadingTopics: false,
                error: action.message,
            };
        case topicConstants.ADD_SUPPORTED_LANGUAGE.REQUEST:
            return {
                ...state,
            };
        case topicConstants.ADD_SUPPORTED_LANGUAGE.SUCCESS:
            return {
                ...state,
                currentTopicSelectedLanguage: action.data.payload.l1Id,
                updatingTranslation: false
            };
        case topicConstants.ADD_SUPPORTED_LANGUAGE.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.GET_IGNORED_WORDS.REQUEST:
            return {
                ...state,
                loadingIgnoredWords: true,
            };
        case topicConstants.GET_IGNORED_WORDS.SUCCESS:
            return {
                ...state,
                loadingIgnoredWords: false,
                ignoredWords: action.data
            };
        case topicConstants.GET_IGNORED_WORDS.FAILURE:
            return {
                ...state,
                loadingIgnoredWords: false,
                error: action.message,
            };
        case topicConstants.GET_TOPIC_WORDS.REQUEST:
            return {
                ...state,
                loadingTopicWords: true,
            };
        case topicConstants.GET_TOPIC_WORDS.SUCCESS:
            return {
                ...state,
                loadingTopicWords: false,
                topicWords: action.data
            };
        case topicConstants.GET_TOPIC_WORDS.FAILURE:
            return {
                ...state,
                loadingTopicWords: false,
                error: action.message,
            };    
        case topicConstants.GET_TOPIC_WORDS_L1.REQUEST:
            return {
                ...state,
                topicWordsForL1: [],
                loadingTopicWordsForL1: true,
                processingTopicWords: true
            };
        case topicConstants.GET_TOPIC_WORDS_L1.SUCCESS:
            return {
                ...state,
                loadingTopicWordsForL1: false,
                topicWordsForL1: action.data
            };
        case topicConstants.GET_TOPIC_WORDS_L1.FAILURE:
            return {
                ...state,
                loadingTopicWordsForL1: false,
                error: action.message,
            };
        case topicConstants.GET_TOPIC_WORDS_ENGLISH.REQUEST:
            return {
                ...state,
                loadingTopicWordsForEnglish: true,
            };
        case topicConstants.UPDATE_TOGGLE.SUCCESS:
            return {
                ...state,
                processingTopicWords: false,
            };
        case topicConstants.GET_TOPIC_WORDS_ENGLISH.SUCCESS: 
            let englishAddition = []
            state.topicWordsForL1.forEach(l1Word => {
                action.data.forEach(englishWord => {
                    if(englishWord.l2WordId === l1Word.l2WordId) {
                        englishAddition.push({...l1Word, english: englishWord.l1Text, englishId: englishWord.l2WordId, englishDisambiguation: englishWord.disambiguatedWordDto});
                    } 
                });
            });
        return {
                ...state,
                loadingTopicWordsForEnglish: false,
                topicWordsForEnglish: englishAddition,
                topicWordsForL1: englishAddition,
                processingTopicWords: false
            };
        case topicConstants.GET_TOPIC_WORDS_ENGLISH.FAILURE:
            return {
                ...state,
                loadingTopicWordsForEnglish: false,
                error: action.message,
            };

        case topicConstants.DELETE_TOPIC.REQUEST:
            return {
                ...state,
            };
        case topicConstants.DELETE_TOPIC.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.DELETE_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            };

        case topicConstants.RESTORE_TOPIC_WORD.REQUEST:
          return {
                ...state,
                ignoredWords: state.ignoredWords.filter(word => (action.payload.wordId != word.id)),
                loadingTopicWords: true,
            };
        case topicConstants.RESTORE_TOPIC_WORD.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.RESTORE_TOPIC_WORD.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.DELETE_TOPIC_WORD_DISTRACTOR.REQUEST:
            return {
                ...state,
            };
        case topicConstants.DELETE_TOPIC_WORD_DISTRACTOR.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.DELETE_TOPIC_WORD_DISTRACTOR.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.DELETE_TOPIC_WORDS.REQUEST:
            return {
                ...state,
                topicWords: state.topicWords.filter(word => !(action.payload.ignoreWordIds.includes(word.wordId))),
                loadingTopicWords: true,
            };
        case topicConstants.DELETE_TOPIC_WORDS.SUCCESS:
            return {
                ...state,
                loadingTopicWords: false,
            };
        case topicConstants.DELETE_TOPIC_WORDS.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.UPDATE_TOPIC_WORDS.REQUEST:
            return {
                ...state,
            };
        case topicConstants.UPDATE_TOPIC_WORDS.SUCCESS:
            return {
                ...state,
               // loadingTopicWords: false,
            };
        case topicConstants.UPDATE_TOPIC_WORDS.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.APPROVE_SNIPPET.REQUEST:
            return {
                ...state,
                processingTopicWords: true
            };
        case topicConstants.APPROVE_SNIPPET.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.APPROVE_SNIPPET.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.DISAPPROVE_SNIPPET.REQUEST:
            return {
                ...state,
            };
        case topicConstants.DISAPPROVE_SNIPPET.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.DISAPPROVE_SNIPPET.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.CHANGE_TO_ALT_TRANSLATION.REQUEST:
            return {
                ...state,
            };
        case topicConstants.CHANGE_TO_ALT_TRANSLATION.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.CHANGE_TO_ALT_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.APPROVE_WORD.REQUEST:
            return {
                ...state,
                processingTopicWords: true
            };
        case topicConstants.APPROVE_WORD.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.APPROVE_WORD.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.DISAPPROVE_WORD.REQUEST:
            return {
                ...state,
                processingTopicWords: true
            };
        case topicConstants.DISAPPROVE_WORD.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.DISAPPROVE_WORD.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.DELETE_TOPIC_SNIPPET.REQUEST:
            return {
                ...state,
            };
        case topicConstants.DELETE_TOPIC_SNIPPET.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.DELETE_TOPIC_SNIPPET.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.SET_CREATETOPIC_STEP.REQUEST:
            return {
                ...state,
                createTopicStep: action.payload.step,
            };
        case topicConstants.CLEAR_TOPIC_DATA.REQUEST:
            return {
                ...state,
                files: [],
                filesProcessing: [],
                documentWords: [],
                topicWords: [],
                updatingTranslation: false
            };
        case topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.REQUEST:
        
            return {
                ...state,
            };
        case topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.SUCCESS:
            return {
                ...state,
                usergroupsForTopic: action.data
            };
        case topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
                usergroups: []
            };

        case topicConstants.GET_TOPIC_GENERATE_STATUS.REQUEST:
            return {
                ...state,
            };
        case topicConstants.GET_TOPIC_GENERATE_STATUS.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.GET_TOPIC_GENERATE_STATUS.FAILURE:
            return {
                ...state,
            };
        case topicConstants.GET_CLUSTERS.REQUEST:
            return {
                ...state,
            };
        case topicConstants.GET_CLUSTERS.SUCCESS:
            return {
                ...state,
                clusters: action.data,
            };
        case topicConstants.GET_CLUSTERS.FAILURE:
            return {
                ...state,
                error: action.message,
                clusters: [],
            };
        case topicConstants.FORCE_REGENERATION.REQUEST:
            return {
                ...state,
            };
        case topicConstants.FORCE_REGENERATION.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.FORCE_REGENERATION.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.GET_TOPIC.REQUEST:
            return {
                ...state,
                //topicWordsForEnglish: []
            };
        case topicConstants.GET_TOPIC.SUCCESS:
        {
            let topicsCopy = [];
            const map = new Map();
            for (const item of state.topics) {
                if(!map.has(item.id)){
                    map.set(item.id, true);
                    topicsCopy.push(item);
                }
            }
            const existingTopicIndex = topicsCopy.findIndex(x => x.id == action.data.id);
            if(existingTopicIndex >= 0) {
                topicsCopy[existingTopicIndex] = action.data;
            }
            else {
                topicsCopy.push(action.data);
            }

            return {
                ...state,
                topics: topicsCopy,
            };
        }
        case topicConstants.GET_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.CHANGE_TRANSLATION.REQUEST:
            return {
                ...state,
                updatingTranslation: true
            };
        case topicConstants.CHANGE_TRANSLATION.SUCCESS:
            return {
                ...state,
                 updatingTranslation: false
            };
        case topicConstants.CHANGE_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
                updatingTranslation: false
            };
        case topicConstants.CHANGE_SNIPPET_L1.REQUEST:
            return {
                ...state,
                processingTopicWords: true
            };
        case topicConstants.CHANGE_SNIPPET_L1.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.CHANGE_SNIPPET_L1.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.CHANGE_SNIPPET_L2.REQUEST:
            return {
                ...state,
                processingTopicWords: true
            };
        case topicConstants.CHANGE_SNIPPET_L2.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.CHANGE_SNIPPET_L2.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.CHANGE_SNIPPET_QUESTION_L1.REQUEST:
            return {
                ...state,
                processingTopicWords: true
            };
        case topicConstants.CHANGE_SNIPPET_QUESTION_L1.SUCCESS:
            return {
                ...state,
            };
        case topicConstants.CHANGE_SNIPPET_QUESTION_L1.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.CREATE_SNIPPET_L2.REQUEST:
            return {
                ...state,
                snippetsReadyForConfirm: false,
                snippetsCreatedTranslationArray: []
            };
        case topicConstants.CREATE_SNIPPET_L2.SUCCESS:
            return {
                ...state,
                snippetsReadyForConfirm: true,
                snippetsCreatedTranslationArray: action.data
            };
            
        case topicConstants.CREATE_SNIPPET_L2.FAILURE:
            return {
                ...state,
                snippetsReadyForConfirm: false,
                error: action.message,
            };
        case topicConstants.GET_SHARABLE_COURSES.REQUEST:
            return {
                ...state,
            };
        case topicConstants.GET_SHARABLE_COURSES.SUCCESS:
             return {
                ...state,
                sharedCourses: action.data
            };
        case topicConstants.GET_SHARABLE_COURSES.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.GET_CURRENT_TOPIC.REQUEST:
            return {
                ...state,
                loadingCurrentTopic: true
            };
        case topicConstants.GET_CURRENT_TOPIC.SUCCESS:
            let topicsCopy = [];
            const map = new Map();
            for (const item of state.topics) {
                if(!map.has(item.id)){
                    map.set(item.id, true);
                    topicsCopy.push(item);
                }
            }
            const existingTopicIndex = topicsCopy.findIndex(x => x.id == action.data.id);
            if(existingTopicIndex >= 0) {
                topicsCopy[existingTopicIndex] = action.data;
            }
            else {
                topicsCopy.push(action.data);
            }
            
            return {
                ...state,
                topics: topicsCopy,
                currentTopicSelectedLanguage: action.data.defaultL1Id,
                loadingCurrentTopic: false,
            };
        case topicConstants.GET_CURRENT_TOPIC.FAILURE:
            return {
                ...state,
                loadingCurrentTopic: false,
                error: action.message,
            };
        case topicConstants.CHANGE_SELECTED_L1.REQUEST:
            return {
               ...state,
           };
        case topicConstants.CHANGE_SELECTED_L1.SUCCESS:
           return {
              ...state,
              currentTopicSelectedLanguage: action.data
          };
        case topicConstants.CHANGE_SELECTED_L1.FAILURE:
          return {
              ...state,
              error: action.message,
          };
        case topicConstants.UPDATE_LOCAL_COURSE_NAME.REQUEST:
        return {
            ...state,
        };
        case topicConstants.UPDATE_LOCAL_COURSE_NAME.SUCCESS:
          return {
              ...state,
          };
        case topicConstants.UPDATE_LOCAL_COURSE_NAME.FAILURE:
          return {
              ...state,
              error: action.message,
          };
        case topicConstants.GET_REQUESTED_L1S.REQUEST:
          return {
              ...state,
          };
        case topicConstants.GET_REQUESTED_L1S.SUCCESS:
            return {
                ...state,
                currentTopicRequestedL1s: action.data
            };
        case topicConstants.GET_REQUESTED_L1S.FAILURE:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.EXPORT_COURSE.REQUEST:
            return {
                ...state,
            };
        case topicConstants.EXPORT_COURSE.SUCCESS:
              return {
                  ...state,
              };
        case topicConstants.EXPORT_COURSE.FAILURE:
              return {
                  ...state,
                  error: action.message,
              };
        case topicConstants.IMPORT_COURSE.REQUEST:
              return {
                  ...state,
                  isImporting: true
              };
        case topicConstants.IMPORT_COURSE.SUCCESS:
                return {
                    ...state,
                    isImporting: false
                };
        case topicConstants.IMPORT_COURSE.FAILURE:
                return {
                    ...state,
                    isImporting: false,
                    error: action.message,
                };
        case topicConstants.DELETE_TOPIC_WORD.REQUEST:
              return {
                  ...state,
              };
        case topicConstants.DELETE_TOPIC_WORD.SUCCESS:
                return {
                    ...state,
                };
        case topicConstants.DELETE_TOPIC_WORD.FAILURE:
                return {
                    ...state,
                    error: action.message,
                };
        case topicConstants.CHANGE_DESCRIPTION.REQUEST:
              return {
                  ...state,
              };
        case topicConstants.CHANGE_DESCRIPTION.SUCCESS:
                return {
                    ...state,
                };
        case topicConstants.CHANGE_DESCRIPTION.FAILURE:
                return {
                    ...state,
                    error: action.message,
                };
        case topicConstants.GET_TOPIC_DIFFICULTIES.REQUEST:
                return {
                    ...state,
                };
        case topicConstants.GET_TOPIC_DIFFICULTIES.SUCCESS:
                  return {
                      ...state,
                      topicDifficulties: action.data
                  };
        case topicConstants.GET_TOPIC_DIFFICULTIES.FAILURE:
                  return {
                      ...state,
                      error: action.message,
                  };
        case topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.REQUEST:
                  return {
                      ...state,
                  };
        case topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS:
                    return {
                        ...state,
                        lessonOrder: action.data
                    };
        case topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.FAILURE:
                    return {
                        ...state,
                        error: action.message,
                    };
                    
        case topicConstants.GET_LESSON_ORDER_FOR_INDEXING.REQUEST:
            return {
                ...state,
            };
        case topicConstants.GET_LESSON_ORDER_FOR_INDEXING.SUCCESS:
            let topicLessonOrderIndex = state.topicLessonOrderIndex ? state.topicLessonOrderIndex : {}
            topicLessonOrderIndex[action.data.topicId] = action.data.topicLessonOrder
            return {
                ...state,
                topicLessonOrderIndex: topicLessonOrderIndex
            }
        case topicConstants.GET_LESSON_ORDER_FOR_INDEXING.FAILURE:
            return {
                ...state,
            }
                    
        case topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.REQUEST:
                    return {
                        ...state,
                    };
          case topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS:
                      return {
                          ...state,
                          lessonOrder: action.data
                      };
          case topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.FAILURE:
              return {
                  ...state,
                  error: action.message,
              };
                    
        case topicConstants.UPDATE_LOCAL_LESSON_ORDER.REQUEST:
            return {
                ...state,
                error: action.message,
            };
        case topicConstants.UPDATE_LOCAL_LESSON_ORDER.SUCCESS:
            return {
                ...state,
                lessonOrder: action.data.payload.lessonArray
            };
        case topicConstants.UPDATE_LOCAL_LESSON_ORDER.FAILURE:
            return {
                ...state,
                error: action.message,
            };
                    
        case topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.REQUEST:
            return {
                ...state,
            };

        case topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            }
            
        case topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.REQUEST:
            return {
                ...state,
            };

        case topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.REQUEST:
            return {
                ...state,
            };

        case topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
            }
            
        case topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.REQUEST:
            return {
                ...state,
            };

        case topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.REQUEST:
            return {
                ...state,
            };
        case topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.REQUEST:
            return {
                ...state,
            };
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.SUCCESS:
            return {
                ...state,
                topicDataPickerForLessonData: action.data
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.FAILURE:
            return {
                ...state,
                error: action.message,
            }
        

        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.REQUEST:
            return {
                ...state,
            };
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.SUCCESS:
            return {
                ...state,
                topicDataPickerForNLP: action.data
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.REQUEST:
            return {
                ...state,
            };
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.SUCCESS:
            return {
                ...state,
                topicDataPickerForNLPDistractors: action.data
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.FAILURE:
            return {
                ...state,
                error: action.message,
            }
            
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.REQUEST:
            return {
                ...state,
            };
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.SUCCESS:
            return {
                ...state,
                topicDataPickerForSentenceSuggestion: action.data
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case authConstants.LOGOUT.SUCCESS:
            return initialState;
        default:
            return state
    }
}