import {connect} from "react-redux";
import React from "react";
import InteractionListenButtonWithSlider from "../Interactions/InteractionListenButtonWithSlider";
import {Grid, MenuItem, Select, Typography} from "@material-ui/core";
import {TextField} from "@mui/material";
import {
  AudioLibraryType,
  ScenarioAudioPlayType as AudioPlayType,
  ScenarioL1L2SelectorOptions,
  ScenarioNodeType
} from "../../../../Logic/ScenarioConstants";
import {makeStyles} from "@material-ui/core/styles";
import TextFieldWithLanguageSelector from "../ScenarioComponents/TextFieldWithLanguageSelector";


const useStyles = makeStyles(theme => ({
  textFields: {
    width: '428px',
    textColor: 'white'
  },
  interactions: {
    paddingTop: '10px'
  },
  nodeTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '22px',
    paddingBottom: '8px',
    color: '#35323C'
  },
}));

const ListenWithSliderEditNode = (props) => {
  
  // WARNING: made really fast for the OUP project
  
  // expected props:
  // props.interactionSelectionSection
  // props.displayInteraction
  
  const classes = useStyles();

  const listenButtonWithSliderTestOptionIndex = 0
  const explanationTestOptionIndex = 1
  const interactionTestOptionIndex = 2

  const setExplanationText = (newExplanation) => {
    if (props.node.testOptions?.length < 2) {
      return;
    }
    props.node.testOptions[explanationTestOptionIndex].text = newExplanation;
    props.onUpdateTestOptionOnCurrentNode(props.node.testOptions[explanationTestOptionIndex]);
  }

  const setLanguageForExplanation = (languageSelector) => {
    if (languageSelector.target.value === ScenarioL1L2SelectorOptions.L1) {
      props.node.testOptions[explanationTestOptionIndex].textLanguageId = props.l1Id;
    } else if (languageSelector.target.value === ScenarioL1L2SelectorOptions.L2) {
      props.node.testOptions[explanationTestOptionIndex].textLanguageId = props.languageId;
    }
    props.onUpdateTestOptionOnCurrentNode(props.node.testOptions[explanationTestOptionIndex]);
  }
  
  
  const setAudioPlayTypeForInteractive = (audioPlayType) => {
    if (audioPlayType === AudioPlayType.DontPlay || audioPlayType === AudioPlayType.PlayBefore || audioPlayType === AudioPlayType.PlayAfter) {
      props.node.testOptions[interactionTestOptionIndex].audioPlayType = audioPlayType;
      props.onUpdateTestOptionOnCurrentNode(props.node.testOptions[interactionTestOptionIndex]);
    }
  }
  
  
  return (
    <React.Fragment>
          <Grid item xs={12} style={{marginTop: '70px'}}>
            <Typography 
              variant='h6' style={{  boxShadow: 'none',
              color: 'black',
              fontFamily: 'Lato',
              fontSize: 32,
              fontWeight: 'bold'}} >
              Audio story
            </Typography>
            <Typography
              style={{
                boxShadow: 'none',
                letterSpacing: '0.02em',
                fontSize: 14,
                color: '#8E8E93',
                fontFamily: 'Lato',
              }}>
              Provide a longer voice clip for your learners to listen to. Add multiple choice, spelling or sentence builder interactions to test their knowledge.
            </Typography>
              <Typography
                style={{
                  boxShadow: 'none',
                  letterSpacing: '0.02em',
                  fontSize: 14,
                  color: '#8E8E93',
                  fontFamily: 'Lato',
                  marginTop: '10px'
                }}>
                  The learner can rewind and listen again to the audio as many times as they like.            
                </Typography>
          </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.ruleTitle} style={{paddingRight: '50px', marginTop: '20px' }}>
        <Typography style={{ 
          boxShadow: 'none',
          color: 'black',
          fontFamily: 'Lato',
          fontSize: 22,
          fontWeight: 'bold',
        }}>
          Title
        </Typography>
      </Grid>
      {props.node.texts && props.node.texts.map((textItem, index) => (
        <Grid container >
          <Grid item xs={12} sm={12} md={12} className={classes.ruleTextField}>
              <TextFieldWithLanguageSelector 
                text={props.node.texts[index].text}  
                width='210px'
                disabled={!props.canEditLesson} 
                onChange={(event) => props.onRuleCardTitleChange(event, 0)} 
                l2Id={null}
                l1Id={null}
                soloLanguageId={props.languageId}
                canChangeLanguage={false}
            />
          </Grid>
        </Grid>
      ))
      }
      <InteractionListenButtonWithSlider
        canEditLesson={props.canEditLesson}
        audioLibrary={props.audioLibrary}
        onAudioLibrary={props.onAudioLibrary}
        testOption={props.node.testOptions[listenButtonWithSliderTestOptionIndex]}
        languageId={props.languageId}
        onUpdateTestOptionOnCurrentNode={props.onUpdateTestOptionOnCurrentNode}
      />
      <Grid container style={{marginTop: '30px', marginBottom: '10px'}}>
        <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
          <Typography
            xs={10}
            variant='body1'
            style={{ 
              boxShadow: 'none',
              color: 'black',
              fontFamily: 'Lato',
              fontSize: 22,
              fontWeight: 'bold',
            }}>
            Task description
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.scenario} >
          <Typography
            xs={12}
            autoWidth
            variant='body1'
            style={{
              marginTop:'10px',
              fontFamily: 'Lato',
              color: 'gray',
            }}>
            Optionally write a description of what the learner should do
          </Typography>
          <div style={{marginRight: '10px', marginTop:'10px'}}>
              <TextFieldWithLanguageSelector 
                text={props.node.testOptions[explanationTestOptionIndex].text}
                fullWidth={true}
                disabled={!props.canEditLesson} 
                onChange={(event) => setExplanationText(event.target.value)}
                onChangeLanguageLanguage={(event) => setLanguageForExplanation(event)}
                l2Id={props.languageId}
                l1Id={props.l1Id}
                canChangeLanguage={true}
                displayL1OrL2={props.node.testOptions[explanationTestOptionIndex].textLanguageId === props.languageId ? 1 : 0}
            />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} style={{marginTop: '20px'}} >
        {props.interactionSelectionSection("testImage", 2, "Interaction")}
      </Grid>
      {
        props.detectInteractionMode(props.node.testOptions[interactionTestOptionIndex].alternatives, props.node.testOptions[interactionTestOptionIndex].text) === true &&
        <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px' }}>
          <TextFieldWithLanguageSelector 
              text={props.node.testOptions[interactionTestOptionIndex].text} 
              width='210px'
              fullWidth={true}
              disabled={!props.canEditLesson} 
              onChange={(event) => props.onChangeTextImageQuestionL2(event.target.value, interactionTestOptionIndex)}
              l2Id={null}
              l1Id={null}
              soloLanguageId={props.languageId}
              canChangeLanguage={false}
          />
        </Grid>
      }
      
      
      
      {
        props.node.testOptions && props.node.testOptions.length > 2 && props.node.testOptions[interactionTestOptionIndex].alternatives &&
        <Grid item xs={12} sm={12} md={12} className={classes.interactions}>
          {props.displayInteraction(props.node.testOptions[interactionTestOptionIndex].alternatives, props.node.testOptions[interactionTestOptionIndex].scenarioInteractiveElementType, 2, ScenarioNodeType.TestImage, "Write the text to interact with", false)}
        </Grid>
      }
      
      {
        props.node.testOptions[interactionTestOptionIndex].audioPlayType === AudioPlayType.DontPlay &&
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            style={{
              boxShadow: 'none',
              color: 'black',
              fontFamily: 'Lato',
              marginLeft: '15px',
              fontWeight: '500',
              letterSpacing: '0.02em'

            }}>
            Should the interaction text be spoken out?
          </Typography>
        </Grid>
      }
      {
        props.node.testOptions[interactionTestOptionIndex].audioPlayType !== AudioPlayType.DontPlay &&
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            style={{
              boxShadow: 'none',
              color: 'black',
              fontFamily: 'Lato',
              marginLeft: '15px',
              fontWeight: 'bold',
              letterSpacing: '0.02em'

            }}>
            Interaction audio
          </Typography>
        </Grid>
      }
      
      <Grid item xs={12} sm={12} md={12} style={{marginBottom: '20px'}} >
        <Select style={{
          marginLeft: '22px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginBottom: '5px',
          width: '60px',
          height: '60px',
          border: '5px solid #143349',
          boxSizing: 'border-box',
          borderRadius: '10px',
          maxHeight: '40px',
          minWidth: '250px',
          minHeight: '40px',
          marginTop: '10px',
        }} disableUnderline
                value={props.node.testOptions[interactionTestOptionIndex].audioPlayType}
                autoWidth
                onChange={(e) => setAudioPlayTypeForInteractive(e.target.value)}
        >
          <MenuItem disabled={!props.canEditLesson} value={0}>No, don’t speak it out</MenuItem>
          <MenuItem disabled={true} value={1}>Yes, at the start</MenuItem>
          <MenuItem disabled={!props.canEditLesson} value={2}>Yes, at the end</MenuItem>
        </Select>
      </Grid>
      
      {
        props.node.testOptions[interactionTestOptionIndex].audioPlayType !== AudioPlayType.DontPlay &&
        props.onAudioLibrary(AudioLibraryType.TestOptionVoice, interactionTestOptionIndex)
      }
      
    </React.Fragment>
  );
}

ListenWithSliderEditNode.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapToProps, mapDispatchToProps)(ListenWithSliderEditNode)