import React, {useState, useEffect}  from 'react';
import {
  Typography,
  makeStyles,
} from '@material-ui/core';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import {CapeeshColors} from "../../../../assets/ColorPalette";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EastIcon from '@mui/icons-material/East';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';


const useStyles = makeStyles(theme => ({
  cardContent: {
    flex: 'auto',
  },
  topicCard: {
    display: 'flex',
    padding: '20px',
    boxShadow: 'none',
  },
  topicCardAction: {
    right: '15px',
    height: 'fit-content',
    margin: 'auto',
  },
  editable: {
    border: '2px solid transparent',
    transition: 'all 0.3s ease',
    '&:focus, &:hover, &$active': {
      border: '2px solid #AAC8E'
    },
  }
}));

const AutoCourseCreationLessonCard = (props) => {
  
  const classes = useStyles();
  
  
  const [showAddButton, setShowAddButton] = useState(false); 
  const [showPreviewLessonButton, setShowPreviewLessonButton] = useState(false);
  
  useEffect(() => {
    if (props.lessonSuggestionDto?.createdLessonId && props.lessonSuggestionDto.createdLessonId.length > 0) {
      setShowAddButton(true);
      setShowPreviewLessonButton(true);
    } else {
      setShowAddButton(false);
      setShowPreviewLessonButton(false);
    }
  }, []);
  
  
  const handleOnClick = () => {
    console.log("handle on click")
    props.onLessonPlanClicked(props.lessonSuggestionDto?.lessonPlanId)
  }
  const stopEvent = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }
  
  const addToTopic = (event) => {
    // addd current lesson to topic
    event.stopPropagation();
    event.preventDefault();
    if (props.lessonSuggestionDto?.createdLessonId && props.lessonSuggestionDto.createdLessonId.length > 0) {
      let numberOfLessons = props.lessonSuggestionDto.createdLessonId.length;
      let customLessonId = props.lessonSuggestionDto.createdLessonId[numberOfLessons - 1]

      props.onAddLesson(customLessonId);
    }
  }
  
  const showLessonPreview = (event) => {

    event.stopPropagation();
    if (props.lessonSuggestionDto?.createdLessonId && props.lessonSuggestionDto.createdLessonId.length > 0) {
      let numberOfLessons = props.lessonSuggestionDto.createdLessonId.length;
      let customLessonId = props.lessonSuggestionDto.createdLessonId[numberOfLessons - 1]

      props.onPreviewLesson(customLessonId);
    }
  }
  
  
  return (
    <React.Fragment>
      <div onClick={handleOnClick} 
           style={{
             borderRadius: '5px',
             background: 'white',
             boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', 
             marginBottom: props.isLastInTopic === true ? '10px' : '20px', 
             padding: '10px', 
            }}>
       
          <Grid container style={{cursor: props.selectedLesson?.lessonPlanId !== props.lessonSuggestionDto?.lessonPlanId  ? 'pointer': '',}}>
            <Grid item xs={12} sm={ showAddButton ? 10 : 12}  md={ showAddButton ? 10 : 12} lg={ showAddButton ? 10 : 12} style={{cursor: 'pointer'}} >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{ display:'flex', alignItems: 'center' }}>
                  <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center', minWidth:'28px', width: '28px', height: 28, borderRadius: 28 / 2, backgroundColor: props.lessonSuggestionDto.lessonType === "Introduction" ? "#15D0BC" : props.lessonSuggestionDto.lessonType === "Repetition" ? "#A0439F" : "#FF7A3B", marginRight: 5, textAlign: 'center', color: 'white', fontSize: '18.781px', fontFamily: 'Nunito', fontWeight: 700, lineHeight: '19.824px' }} >
                    {props.lessonNumber}
                  </div>
                  <Typography component="div">
                  <span style={{marginLeft: '8px', color: "var(--Gray-1, #8E8E93)", textAlign: "center", fontFamily: "Rubik", fontSize:20, fontStyle: "normal", fontWeight:400, letterSpacing:0.41, marginRight: '12px'}}>
                    {props.lessonSuggestionDto.lessonType}
                  </span>
                    <span style={{color: "#000", textAlign: "center", fontFamily: "Rubik", fontSize:20, fontStyle: "normal", fontWeight:400, letterSpacing:0.41,}}>
                    {props.lessonSuggestionDto.lessonName} 
                  </span>

                  </Typography>
                  
                </div>
              </div>
            
            </Grid>
            {
                showAddButton &&
                <Grid item xs={12} sm={12} md={2}>
                  <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'space-between'}}>
                    {
                        showPreviewLessonButton &&
                        <IconButton
                            onClick={(event) => showLessonPreview(event)}
                            sx={{ fontSize: "25px",  display: 'center', alignItems: 'left', justifyContent: 'center',  height: '35px', color: CapeeshColors.PurpleBright}}
                            // style={{marginRight: '15px'}}
                        >
                          <PlayCircleIcon sx={{ fontSize: "25px"}}/>
                        </IconButton>
                    }

                    <IconButton
                        onClick={(event) => addToTopic(event)}
                        sx={{ fontSize: "25px",  display: 'center', alignItems: 'left', justifyContent: 'center',  height: '35px', color: 'gray'}}>
                      <EastIcon sx={{ fontSize: "25px"}}/>
                    </IconButton>
                  </div>
                </Grid>
            }
            
            
            
            <Grid container direction="row" style={{marginLeft: '40px', marginTop: '5px', marginBottom: '3px'}}>
              
              
            </Grid>
          </Grid>
        
      </div>

    </React.Fragment>

  );
};

AutoCourseCreationLessonCard.propTypes = {};

const mapStateToProps = (state) => {
  return {
    languages: state.metadata.metadata.languages,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseCreationLessonCard)