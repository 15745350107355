

export const TopicTranslationConfigurationOptions = {
  "AllTranslations":0,
  "DeltaTranslationsOnly":1,
  "RemoveCrossLessonDuplicates":2,
  
};
Object.freeze(TopicTranslationConfigurationOptions);

export const TranslationImportExportVersion = {
  V1: 1, // deprecated and removed
  V2: 2,
  MachineTranslationV1: 3,
}
Object.freeze(TranslationImportExportVersion);


export const TranslationApprovalType = 
{
  VerifiedByHuman:  0,
  MachineTranslation: 1,
  MarkedAsWrong: 2, // used for single translations (not a supported language yet
}
Object.freeze(TranslationApprovalType);