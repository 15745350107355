import {asyncRequest} from './asyncRequest'
import {lessonConstants} from "./lesson.actions";
import {exportExcelTopicTranslationForAllLanguages} from "../sagas/topic.sagas";

export const topicConstants = {
    ADD_FILE: new asyncRequest('ADD_FILE'),
    DELETE_FILE: new asyncRequest('DELETE_FILE'),
    ADD_URL: new asyncRequest('ADD_URL'),
    GET_FILES: new asyncRequest('GET_FILES'),
    GET_TOPIC_DOCUMENTS: new asyncRequest('GET_TOPIC_DOCUMENTS'),
    DELETE_TOPIC_DOCUMENT: new asyncRequest('DELETE_TOPIC_DOCUMENT'),
    UPDATE_TOPIC: new asyncRequest('UPDATE_TOPIC'),
    CREATE_TOPIC: new asyncRequest('ORGANIZATION_CREATE_TOPIC'),
    PUBLISH_TOPIC: new asyncRequest('ORGANIZATION_PUBLISH_TOPIC'),
    GET_ALL_TOPICS: new asyncRequest('ORGANIZATION_GET_ALL_TOPICS'),
    GET_DOCUMENT_WORDS: new asyncRequest('GET_DOCUMENT_WORDS'),
    SET_CREATETOPIC_STEP: new asyncRequest('SET_CREATETOPIC_STEP'),
    GET_TOPIC_WORDS: new asyncRequest('GET_TOPIC_WORDS'),
    GET_TOPIC_WORDS_L1: new asyncRequest('GET_TOPIC_WORDS_L1'),
    GET_TOPIC_WORDS_ENGLISH: new asyncRequest('GET_TOPIC_WORDS_ENGLISH'),
    GET_IGNORED_WORDS: new asyncRequest('GET_IGNORED_WORDS'),
    CLEAR_TOPIC_DATA: new asyncRequest('CLEAR_TOPIC_DATA'),
    DELETE_TOPIC: new asyncRequest('DELETE_TOPIC'),
    RESTORE_TOPIC_WORD: new asyncRequest('RESTORE_TOPIC_WORD'),
    DELETE_TOPIC_WORDS: new asyncRequest('DELETE_TOPIC_WORDS'),
    UPDATE_TOPIC_WORDS: new asyncRequest('UPDATE_TOPIC_WORDS'),
    DELETE_TOPIC_SNIPPET: new asyncRequest('DELETE_TOPIC_SNIPPET'),
    DELETE_TOPIC_WORD_DISTRACTOR: new asyncRequest('DELETE_TOPIC_WORD_DISTRACTOR'),
    GENERAL_RESET: new asyncRequest('TOPIC_GENERAL_RESET'),
    EDIT_TOPIC_RESET: new asyncRequest('EDIT_TOPIC_RESET'),
    SET_COMPLETED_PROCESSING: new asyncRequest('SET_COMPLETED_PROCESSING'),
    GET_ALL_USERGROUPS_FOR_TOPIC: new asyncRequest('GET_ALL_USERGROUPS_FOR_TOPIC'),
    GET_DISAMBIGUATION_PREVIEW: new asyncRequest('GET_DISAMBIGUATION_PREVIEW'),
    GET_TOPIC_GENERATE_STATUS: new asyncRequest('GET_TOPIC_GENERATE_STATUS'),
    GET_CLUSTERS: new asyncRequest('GET_CLUSTERS'),
    UPDATE_STATUS_CLUSTERS: new asyncRequest('UPDATE_STATUS_CLUSTERS'),
    FORCE_REGENERATION: new asyncRequest('FORCE_REGENERATION'),
    APPROVE_SNIPPET: new asyncRequest('APPROVE_SNIPPET'),
    DISAPPROVE_SNIPPET: new asyncRequest('DISAPPROVE_SNIPPET'),
    APPROVE_WORD: new asyncRequest('APPROVE_WORD'),
    DISAPPROVE_WORD: new asyncRequest('DISAPPROVE_WORD'),
    CHANGE_TO_ALT_TRANSLATION: new asyncRequest('CHANGE_TO_ALT_TRANSLATION'),
    ADD_SUPPORTED_LANGUAGE: new asyncRequest('ADD_SUPPORTED_LANGUAGE'),
    CLONE_COURSE: new asyncRequest('CLONE_COURSE'),
    GET_TOPIC: new asyncRequest('GET_TOPIC'),
    CHANGE_TRANSLATION: new asyncRequest('CHANGE_TRANSLATION'),
    CHANGE_SNIPPET_L1: new asyncRequest('CHANGE_SNIPPET_L1'),
    CHANGE_SNIPPET_L2: new asyncRequest('CHANGE_SNIPPET_L2'),
    CHANGE_SNIPPET_QUESTION_L1: new asyncRequest('CHANGE_SNIPPET_QUESTION_L1'),
    CREATE_SNIPPET_L2: new asyncRequest('CREATE_SNIPPET_L2'),
    EDIT_CREATE_SNIPPET: new asyncRequest('EDIT_CREATE_SNIPPET'),
    GET_SHARABLE_COURSES: new asyncRequest('GET_SHARABLE_COURSES'),
    UPDATE_TOGGLE: new asyncRequest('UPDATE_TOGGLE'),
    UPDATE_TOPIC_LANGUAGE_STATUS: new asyncRequest('UPDATE_TOPIC_LANGUAGE_STATUS'),
    GET_CURRENT_TOPIC: new asyncRequest('GET_CURRENT_TOPIC'),
    CHANGE_SELECTED_L1: new asyncRequest('CHANGE_SELECTED_L1'),
    UPDATE_LOCAL_COURSE_NAME: new asyncRequest('UPDATE_LOCAL_COURSE_NAME'),
    GET_REQUESTED_L1S: new asyncRequest('GET_REQUESTED_L1S'),
    EXPORT_COURSE: new asyncRequest('EXPORT_COURSE'),
    IMPORT_COURSE: new asyncRequest('IMPORT_COURSE'),
    DELETE_TOPIC_WORD: new asyncRequest('DELETE_TOPIC_WORD'),
    CHANGE_DESCRIPTION: new asyncRequest('CHANGE_DESCRIPTION'),
    GET_TOPIC_DIFFICULTIES: new asyncRequest('GET_TOPIC_DIFFICULTIES'),
    GET_LESSON_ORDER_FOR_DIFFICULTY: new asyncRequest('GET_LESSON_ORDER_FOR_DIFFICULTY'),
    UPDATE_LOCAL_LESSON_ORDER: new asyncRequest('UPDATE_LOCAL_LESSON_ORDER'),
    UPDATE_LESSON_ORDER_FOR_DIFFICULTY: new asyncRequest('UPDATE_LESSON_ORDER_FOR_DIFFICULTY'),
    ATTACH_CUSTOM_LESSON_TO_TOPIC: new asyncRequest('ATTACH_CUSTOM_LESSON_TO_TOPIC'),
    
    IMPORT_EXCEL_TOPIC_TRANSLATION: new asyncRequest('IMPORT_EXCEL_TOPIC_TRANSLATION'),
    EXPORT_EXCEL_TOPIC_TRANSLATION: new asyncRequest('EXPORT_EXCEL_TOPIC_TRANSLATION'),
    EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION: new asyncRequest('EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION'),
    
    
    EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE: new asyncRequest('EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE'),
    
    
    // lesson builder data picker
    GET_TOPIC_DATA_PICKER_FOR_NLP: new asyncRequest('GET_TOPIC_DATA_PICKER_FOR_NLP'),
    GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION: new asyncRequest('GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION'),
    GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA: new asyncRequest('GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA'),
    GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS: new asyncRequest('GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS'),

    GET_LESSON_ORDER_FOR_INDEXING: new asyncRequest('GET_LESSON_ORDER_FOR_INDEXING'),


};

export const topicActions = {
    addFile(organizationId, topicId, name, file, lessonsOnly) {
        const formData = new FormData();
        formData.append(name, file);
        return {
            type: topicConstants.ADD_FILE.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId,
                name: name,
                lessonsOnly: lessonsOnly,
                formData: formData,
            }
        }
    },

    deleteFile(organizationId, topicId, documentId) {
        return {
            type: topicConstants.DELETE_FILE.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId,
                documentId: documentId
            }
        }
    },
    
    
    deleteTopicDocument(organizationId, topicId, topicDocumentId) {
        return {
            type: topicConstants.DELETE_TOPIC_DOCUMENT.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId,
                topicDocumentId: topicDocumentId
            }
        }
    },
    
    addUrl(organizationId, topicId, name, url, doRecursive) {
        let recursive = "";
        if (doRecursive) recursive = "recursive::";
        return {
            type: topicConstants.ADD_URL.REQUEST,
            payload: {
                name: name + ".url",
                url: recursive + url,
                organizationId: organizationId,
                topicId: topicId
            }
        }
    },
    
    generalReset() {
        return {
            type: topicConstants.GENERAL_RESET.REQUEST,
            payload: {}
        }
    },

    editTopicReset() {
        return {
            type: topicConstants.EDIT_TOPIC_RESET.REQUEST,
            payload: {}
        }
    },
    
    setCompletedProcessing(resetProcessing) {
        return {
            type: topicConstants.SET_COMPLETED_PROCESSING.REQUEST,
            payload: {
                resetProcessing: resetProcessing
            }
        }
    },
    getFiles(organizationId, topicId) {
        return {
            type: topicConstants.GET_FILES.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId,
            }
        }
    },
    getTopicDocuments(organizationId, topicId) {
        return {
            type: topicConstants.GET_TOPIC_DOCUMENTS.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId,
            }
        }
    },
    
    getDocumentWords(organizationId, documentId) {
        return {
            type: topicConstants.GET_DOCUMENT_WORDS.REQUEST,
            payload: {
                organizationId: organizationId,
                documentId: documentId,
            }
        }
    },

    updateTopic(organizationId, topicId, name, topWordsCount, description = '', tags = [], lessonsOnly, imageFileReferenceId) {
        return {
            type: topicConstants.UPDATE_TOPIC.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId,
                name: name,
                topWordsCount: topWordsCount,
                description,
                tags,
                lessonsOnly: lessonsOnly,
                imageFileReferenceId: imageFileReferenceId
            }
        }
    },

    updateTopicLanguageStatus(organizationId, topicId, name, topWordsCount, description = '', tags = [], supportedLanguages) {
        return {
            type: topicConstants.UPDATE_TOPIC_LANGUAGE_STATUS.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId,
                name: name,
                topWordsCount: topWordsCount,
                description,
                tags,
                supportedLanguages
            }
        }
    },

    createTopic(organisationId, name, l2Id, defaultL1Id = null, classroomId = null, demoCourse = false, autoPublish = false,  lessonsOnly = false) {
        return {
            type: topicConstants.CREATE_TOPIC.REQUEST,
            payload: {
                organizationId: organisationId,
                name: name,
                l2Id: l2Id,
                defaultL1Id: defaultL1Id,
                classroomId: classroomId,
                demoTopic: demoCourse,
                autoPublish: autoPublish,
                lessonsOnly: lessonsOnly,
            }
        }
    },


    publishTopic(topicId, l1Id, reroute) {
        return {
            type: topicConstants.PUBLISH_TOPIC.REQUEST,
            payload: {
                topicId,
                l1Id,
                reroute: reroute,
            }
        }
    },

    getAllTopicsForOrganisation(organizationId) {
        return {
            type: topicConstants.GET_ALL_TOPICS.REQUEST,
            payload: {
                organizationId
            }
        }
    },

    setCreateTopicStep(currentStep) {
        return {
            type: topicConstants.SET_CREATETOPIC_STEP.REQUEST,
            payload: {
                step: currentStep
            }
        }
    },

    getTopicWords(topicId) {
        return {
            type: topicConstants.GET_TOPIC_WORDS.REQUEST,
            payload: {
                topicId: topicId
            }
        }
    },

    getTopicWordsForL1(topicId, l1Id, supportedLanguages, l2Id) {
        return {
            type: topicConstants.GET_TOPIC_WORDS_L1.REQUEST,
            payload: {
                topicId: topicId,
                l1Id: l1Id,
                supportedLanguages: supportedLanguages,
                l2Id: l2Id
            }
        }
    },

    getTopicWordsForEnglish(topicId, l1Id) {
        return {
            type: topicConstants.GET_TOPIC_WORDS_ENGLISH.REQUEST,
            payload: {
                topicId: topicId,
                l1Id: 1
            }
        }
    },

    getIgnoredWords(topicId) {
        return {
            type: topicConstants.GET_IGNORED_WORDS.REQUEST,
            payload: {
                topicId: topicId
            }
        }
    },

    deleteTopic(organizationId, topicId) {
        return {
            type: topicConstants.DELETE_TOPIC.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId
            }
        }
    },

    restoreTopicWord(topicId, wordId) {
        return {
            type: topicConstants.RESTORE_TOPIC_WORD.REQUEST,
            payload: {
                topicId: topicId,
                wordId: wordId
            }
        }
    },

    deleteTopicWordDistractor(topicId, distractorId, l1Id) {
        return {
            type: topicConstants.DELETE_TOPIC_WORD_DISTRACTOR.REQUEST,
            payload: {
                topicId: topicId,
                distractorId: distractorId,
                l1Id,
            }
        }
    },

    deleteTopicWords(topicId, ignoreWordIds, generateSnippets) {
        return {
            type: topicConstants.DELETE_TOPIC_WORDS.REQUEST,
            payload: {
                topicId: topicId,
                ignoreWordIds: ignoreWordIds,
                generateSnippets: generateSnippets
            }
        }
    },

    updateTopicWords(topicId, ignoreWordIds, unignoreWordIds, generateSnippets) {
        return {
            type: topicConstants.UPDATE_TOPIC_WORDS.REQUEST,
            payload: {
                topicId: topicId,
                ignoreWordIds: ignoreWordIds,
                removeIgnoreWordIds: unignoreWordIds,
                generateSnippets: generateSnippets
            }
        }
    },

    deleteTopicSnippet(topicId, ignoreSnippetIds, l1Id) {
        return {
            type: topicConstants.DELETE_TOPIC_SNIPPET.REQUEST,
            payload: {
                topicId,
                ignoreSnippetIds,
                l1Id
            }
        }
    },
    
    clearTopicData() {
        return {
            type: topicConstants.CLEAR_TOPIC_DATA.REQUEST,
        }
    },

    getAllUserGroupsForTopic(topicId) {
        return {
            type: topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.REQUEST,
            payload: {
                topicId
            }
        }
    },

    getClusters(topicId) {
        return {
            type: topicConstants.GET_CLUSTERS.REQUEST,
            payload: {
                topicId,
            }
        }
    },
    getTopicGenerateStatus(topicId) {
        return {
            type: topicConstants.GET_TOPIC_GENERATE_STATUS.REQUEST,
            payload: {
                topicId,
            }
        }
    },

    forceRegeneration(topicId, l1Id) {
        return {
            type: topicConstants.FORCE_REGENERATION.REQUEST,
            payload: {
                topicId,
                l1Id,
            }
        }
    },

    approveSnippet(topicId, snippetId, l1Id, supportedLanguages, l2Id) {
        return {
            type: topicConstants.APPROVE_SNIPPET.REQUEST,
            payload: {
                topicId,
                disambiguatedSnippetId: snippetId,
                l1Id,
                supportedLanguages,
                l2Id
            }
        }
    },

    disapproveSnippet(topicId, snippetId, l1Id, supportedLanguages, l2Id) {

        return {
            type: topicConstants.DISAPPROVE_SNIPPET.REQUEST,
            payload: {
                topicId,
                disambiguatedSnippetId: snippetId,
                l1Id,
                supportedLanguages,
                l2Id
            }
        }
    },

    approveWord(topicId, disambiguatedWordId, disambiguatedSnippets, l1Id, supportedLanguages, l2Id) {
        return {
            type: topicConstants.APPROVE_WORD.REQUEST,
            payload: {
                topicId,
                disambiguatedWordId,
                disambiguatedSnippets,
                l1Id,
                supportedLanguages,
                l2Id
            }
        }
    },

    disapproveWord(topicId, disambiguatedWordId, disambiguatedSnippets, l1Id, supportedLanguages, l2Id) {
        return {
            type: topicConstants.DISAPPROVE_WORD.REQUEST,
            payload: {
                topicId,
                disambiguatedWordId,
                disambiguatedSnippets,
                l1Id,
                supportedLanguages,
                l2Id
            }
        }
    },

    changeToAltTranslation(topicId, alternativeDisambiguationId, l1Id, l2Id) {
        return {
            type: topicConstants.CHANGE_TO_ALT_TRANSLATION.REQUEST,
            payload: {
                topicId,
                alternativeDisambiguationId,
                l1Id,
                l2Id
            }
        }
    },

    addSupportedLanguage(topicId, l1Id, autoPublish) {
        return {
            type: topicConstants.ADD_SUPPORTED_LANGUAGE.REQUEST,
            payload: {
                topicId,
                l1Id,
                autoPublish: autoPublish,
            }
        }
    },
    cloneCourse(topicId, copyAllowedWords, CopyApprovedWords, CopySupportedLanguages) {
        return {
            type: topicConstants.CLONE_COURSE.REQUEST,
            payload: {
                topicId,
                copyAllowedWords,
                CopyApprovedWords,
                CopySupportedLanguages
            }
        }
    },
    getTopic(topicId) {
        return {
            type: topicConstants.GET_TOPIC.REQUEST,
            payload: {
                topicId,
                l1Id: 1,
            }
        }
    },
    changeTranslation(topicId, l2WordId, l1Id, l1WordText, selectedWords, jobType = 0, l2Id) {
        return {
            type: topicConstants.CHANGE_TRANSLATION.REQUEST,
            payload: {
                topicId,
                l2WordId,
                l1Id,
                l1WordText,
                selectedWords,
                jobType,
                l2Id
            }
        }
    },    

    changeSnippetL1(topicId, topicDisambiguationSnippetId, newSnippetTranslationText, setSnippetAsMatching = true, l1Id, l2Id) {
        return {
            type: topicConstants.CHANGE_SNIPPET_L1.REQUEST,
            payload: {
                topicId,
                topicDisambiguationSnippetId,
                newSnippetTranslationText,
                setSnippetAsMatching: true,
                l1Id,
                l2Id
            }
        }
    },

    changeSnippetQuestionL1(topicId, topicDisambiguationSnippetId, l1Question, l2Question, l1Id) {
            return {
            type: topicConstants.CHANGE_SNIPPET_QUESTION_L1.REQUEST,
            payload: {
                topicId,
                topicDisambiguationSnippetId,
                l1Question,
                l2Question,
                l1Id
            }
        }
    },

    changeSnippetL2(topicId, l2WordId, l2SnippetId, newSnippetText, setSnippetAsMatching = true, l1Id, l2Id) {
        return {
            type: topicConstants.CHANGE_SNIPPET_L2.REQUEST,
            payload: {
                topicId,
                l2WordId,
                l2SnippetId,
                newSnippetText,
                setSnippetAsMatching: true,
                l1Id,
                l2Id
            }
        }
    },    

    createSnippetL2(topicId, l2WordId, newSnippetText, l1Id) {
        return {
            type: topicConstants.CREATE_SNIPPET_L2.REQUEST,
            payload: {
                topicId,
                l2WordId,
                newSnippetText,
                l1Id
            }
        }
    }, 

    getSharableCourses(){
        return {
            type: topicConstants.GET_SHARABLE_COURSES.REQUEST,
            payload: {
            }
        }
    },

    getCurrentTopic(topicId){
        return {
            type: topicConstants.GET_CURRENT_TOPIC.REQUEST,
            payload: {
                topicId
            }
        }
    },
    changeSelectedL1(l1Id) {
        return {
            type: topicConstants.CHANGE_SELECTED_L1.REQUEST,
            payload: {
                l1Id
            }
        }
    },
    updateLocalCourseName(name) {
        return {
            type: topicConstants.UPDATE_LOCAL_COURSE_NAME.REQUEST,
            payload: {
                name
            }
        }
    },
    exportCourse(topicId, l1Id, includeTranslations, useEnglishAsCourseLanguage, onlyExportApprovedContent, onlyExportNonApprovedContent, filename) {
        return {
            type: topicConstants.EXPORT_COURSE.REQUEST,
            payload: {
                topicId,
                l1Id,
                includeTranslations,
                useEnglishAsCourseLanguage,
                onlyExportApprovedContent,
                onlyExportNonApprovedContent,
                filename
            }
        }
    },
    importCourse(topicId, l1Id, file) {
        const formData = new FormData();
        var newfile = new File([file], file.name);
        formData.append(file.name, newfile);
        return {
            type: topicConstants.IMPORT_COURSE.REQUEST,
            payload: {
                topicId,
                l1Id,
                formData: formData,
            }
        }
    },
    deleteTopicWord(topicId, wordId, l1Id) {
        return {
            type: topicConstants.DELETE_TOPIC_WORD.REQUEST,
            payload: {
                topicId: topicId,
                wordId: wordId,
                l1Id: l1Id
            }
        }
    },
    changeDescription(l2WordId, description, topicId, l1Id) {
        return {
            type: topicConstants.CHANGE_DESCRIPTION.REQUEST,
            payload: {
                l2WordId,
                description,
                topicId,
                l1Id
            }
        }
    },
    
    getTopicDifficulties(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DIFFICULTIES.REQUEST,
            payload: {
                topicId
            }
        }
    },
    getLessonOrderForDifficulty(topicId, topicLessonDifficultyId) {
        return {
            type: topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.REQUEST,
            payload: {
                topicId,
                topicLessonDifficultyId
            }
        }
    },
    
    getLessonOrderForIndexing(topicId) {
        return {
            type: topicConstants.GET_LESSON_ORDER_FOR_INDEXING.REQUEST,
            payload: {
                topicId,
                topicLessonDifficultyId: 0
            }
        }
    },
    
    updateLessonOrderForDifficulty(topicId, topicLessonDifficultyId, topicLessonOrderItems) {
        return {
            type: topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.REQUEST,
            payload: {
                topicId,
                topicLessonDifficultyId,
                topicLessonOrderItems
            }
        }
    },
    
    attachCustomLessonToTopic(topicId, topicLessonDifficultyId, customLessonId) {
        return {
            type: topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.REQUEST,
            payload: {
                topicId,
                topicLessonDifficultyId,
                customLessonId
            }
        }
    },
    
    updateLocalLessonOrder(lessonArray, topicId, difficultyFirst) {
        return {
            type: topicConstants.UPDATE_LOCAL_LESSON_ORDER.REQUEST,
            payload: {
                topicId: topicId,
                topicLessonDifficultyId: difficultyFirst,
                topicLessonOrderItems: lessonArray
            }
        }
    },

    exportExcelTopicTranslationForAllLanguages(topicId, l1Ids, filenames, noCrossLessonDuplicates, useEnglishTranslationText, ignoreAlreadyTranslated, zipFileName) {
        if (l1Ids.length != filenames.length) {
            return;
        }
        
        var queryParams = ["useBase64=true"]
        if (noCrossLessonDuplicates) {
            queryParams.push('noCrossLessonDuplicates=true')
        }
        if (useEnglishTranslationText) {
            queryParams.push('useEnglishTranslationText=true')
        }
        if (ignoreAlreadyTranslated) {
            queryParams.push('ignoreAlreadyTranslated=true')
        }

        var queryParamAsText = ""
        if (queryParams.length > 0) {
            queryParamAsText = "?" + queryParams.join("&");
        }

        let exportLanguageConfigs = []
        l1Ids.map((l1Id, index) =>
        {
            exportLanguageConfigs.push({
                l1Id,
                filename: filenames[index]
            })
        });
        
        return {
            type: topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.REQUEST,
            payload: {
                topicId,
                exportLanguageConfigs: exportLanguageConfigs,
                queryParams: queryParamAsText,
                zipFileName
            }
        }
    },
    
    exportExcelTopicTranslation(topicId, l1Id, filename, noCrossLessonDuplicates, useEnglishTranslationText, ignoreAlreadyTranslated, translationVersion) {

        var queryParams = ["useBase64=true"]
        if (noCrossLessonDuplicates) {
            queryParams.push('noCrossLessonDuplicates=true')
        }
        if (useEnglishTranslationText) {
            queryParams.push('useEnglishTranslationText=true')
        }
        if (ignoreAlreadyTranslated) {
            queryParams.push('ignoreAlreadyTranslated=true')
        }
        
        var queryParamAsText = ""
        if (queryParams.length > 0) {
            queryParamAsText = "?" + queryParams.join("&");    
        }
        
        return {
            type: topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.REQUEST,
            payload: {
                topicId,
                l1Id,
                filename,
                queryParams: queryParamAsText,
                translationVersion: translationVersion
            }
        }
    },
    
    importExcelTopicTranslation(topicId, l1Id, file, version) {
        const formData = new FormData();
        var newfile = new File([file], file.name);
        formData.append(file.name, newfile);
        return {
            type: topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.REQUEST,
            payload: {
                topicId,
                l1Id,
                formData: formData,
                version: version
            }
        }
    },
    
    exportSentenceSuggestionsFromFile(l2Id, file, filename) {
        const formData = new FormData();
        var newfile = new File([file], file.name);
        formData.append(file.name, newfile);
        return {
            type: topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.REQUEST,
            payload: {
                l2Id,
                filename,
                formData: formData,
                
            }
        }
    },
    
    
    getTopicDataPickerForLessonData(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.REQUEST,
            payload: {
                topicId
            }
        }   
    },
    getTopicDataPickerForNLP(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.REQUEST,
            payload: {
                topicId
            }
        }
    },
    getTopicDataPickerForNLPDistractors(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.REQUEST,
            payload: {
                topicId
            }
        }
    },
    getTopicDataPickerForSentenceSuggestion(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.REQUEST,
            payload: {
                topicId
            }
        }
    },
    
};


