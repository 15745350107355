import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import {Grid, Button, Divider} from "@material-ui/core";
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import Badge from '@mui/material/Badge';
import {CustomLessonAuthorNoteType, ScenarioNodeType} from "../../../../Logic/ScenarioConstants";
import Zoom from '@mui/material/Zoom';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import  { ReactComponent as RecordVoiceOverHuman } from ".././Images/RecordVoiceOverHuman.svg";
import  { ReactComponent as RecordVoiceOverRobot } from ".././Images/RecordVoiceOverRobot.svg";
import {voiceTypeSearch} from './../ScenarioStages/StageHelperFiles'
import CircleIcon from '@mui/icons-material/Circle';


export default memo(({ data }) => {
  const handleDuplicate = (event) => {
    data.onDuplicate(data.id, ScenarioNodeType.TestRule) 
    event.stopPropagation();
    event.preventDefault();
   };
 
   const handleAdd = (event) => {
     data.onAdd(data.id, 12)
     event.stopPropagation();
     event.preventDefault();
    };
 
    const handleAddOther = (event) => {
     data.onAddOther(data.id)
     event.stopPropagation();
     event.preventDefault();
    };


  return (
    <>
      <Zoom in={true}>
        <div>
        <Card style={{minHeight: '135px', width: '200px', backgroundColor: 'white', borderStyle: 'solid',
              borderWidth: data?.validationErrors?.length > 0 ? '2px' : 0,
              borderColor: data?.validationErrors?.length > 0 ? 'red' : 'white',
              boxShadow: '0px 4px 4px 0px #00000040',}}>   
          <Grid container >
              <Grid style={{backgroundColor: '#FFAE65', height: '35px'}} item xs={12} sm={12} md={12}>
                  <Typography variant="body1" style={{
                    height: '100px',
                    paddingTop: '4px',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontFamily: 'Lato',
                  }}>
                      Rule Card Stage
                  </Typography>
              </Grid>
              <Grid container style={{marginLeft: '10px', marginRight: '15px', marginTop: '10px'}}>
                  <Grid item xs={10} sm={10} md={10}>
                      <Grid container spacing={1}>
                        {
                          voiceTypeSearch('robot', data.testOptions) === 1 && 
                          <Grid item >
                            <div style={{}}>
                                <RecordVoiceOverRobot 
                                  style={{
                                    justifyContent: 'right',width: '20px', height: '20px',
                                    borderColor: 'white', backgroundColor: '#EFEFF4',
                                    textAlign: 'right',
                                    alignItems: 'right',borderRadius: '10px', padding: '5px'
                                  }} 
                                  sx={{"& .MuiBadge-badge": {color: "#FF5159", backgroundColor: "#FF5159"}}}>
                                  <MessageRoundedIcon />
                                </RecordVoiceOverRobot>
                            </div>
                          </Grid>
                        }
                        {
                          voiceTypeSearch('human', data.testOptions) === 1 && 
                          <Grid item >
                            <div style={{}}>
                                <RecordVoiceOverHuman 
                                  style={{
                                    justifyContent: 'right',width: '20px', height: '20px',
                                    borderColor: 'white', backgroundColor: '#EFEFF4',
                                    textAlign: 'right',
                                    alignItems: 'right',borderRadius: '10px', padding: '5px'
                                  }} 
                                  sx={{"& .MuiBadge-badge": {color: "#FF5159", backgroundColor: "#FF5159"}}}>
                                <MessageRoundedIcon />
                                </RecordVoiceOverHuman>
                            </div>
                          </Grid>
                        }
                        {
                          voiceTypeSearch('none', data.testOptions) === 1 && 
                          <Grid item >
                            <div style={{}}>
                              <VolumeOffIcon style={{justifyContent: 'right',width: '20px', height: '20px',
                                  borderColor: 'white', backgroundColor: '#EFEFF4',
                                  textAlign: 'right',
                                  alignItems: 'right',borderRadius: '10px', padding: '5px'}} sx={{"& .MuiBadge-badge": {color: "#FF5159", backgroundColor: "#FF5159"}}}>
                                  <MessageRoundedIcon />
                                </VolumeOffIcon>    
                            </div>
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                    { 
                      data?.authorNoteDtos?.filter(x => x.authorNoteType === CustomLessonAuthorNoteType.Created).length > 0 &&
                      <Grid item xs={2} sm={2} md={2} style={{display: 'inline', width: '100%'}}>
                        <Badge variant="dot" 
                               style={{
                                 width: '20px', height: '20px', borderColor: 'white',
                                 borderRadius: '10px', padding: '2px'
                               }} 
                               sx={{"& .MuiBadge-badge": {color: "#FF5159", backgroundColor: "#FF5159"}}}>
                          <MessageRoundedIcon style={{}}/>
                        </Badge>
                      </Grid>
                    }
              </Grid>
              {data?.testOptions?.map((el, index) =>
                <Grid item xs={12} sm={12} key={index}>
                  {
                    <React.Fragment>
                      <Card
                        style={{minHeight: '45px', width: '180px',
                          boxShadow: 'none',
                          display: 'inline-flex',
                          flex: 1,
                          justifyContent: 'left',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          alignItems: 'center'}}>
                          <Typography variant="body1" component="p">
                            {data.testOptions[index].text}
                          </Typography>
                      </Card>
                      <Divider variant="middle" />
                      
                    </React.Fragment>
                  }
                </Grid>
              )
            }
            </Grid>
            <Divider style={{width:'100%'}}/>
            
            {
              data.canEditLesson &&
              <Grid style={{backgroundColor: 'white', height: '40px'}} className={'nodrag'} item xs={12} sm={12} md={12}>
                <Button disabled={data.isBusy} onClick={handleDuplicate} style={{color: '#4aa7ec', width: '67px', boxShadow: 'none', borderRadius: '0', borderLeft: '0px', borderTop: '0px', borderBottom: '0px', borderColor: '#D1D1D6', textTransform: 'none', fontWeight: 'bold', height: '40px'}} variant="outlined" size="small" color="primary">
                  Duplicate
                </Button>
                <Button disabled={data.isBusy} onClick={handleAdd} style={{color: data.isBusy? 'lightgrey' : '#4aa7ec', width: '66px', boxShadow: 'none', borderRadius: '0', borderLeft: '0px', borderTop: '0px', borderBottom: '0px', borderColor: '#D1D1D6', textTransform: 'none', fontWeight: 'bold', height: '40px'}} variant="outlined" size="medium" color="primary">
                  Test
                </Button>
                <Button disabled={data.isBusy} onClick={handleAddOther} style={{color: data.isBusy? 'lightgrey' : '#4aa7ec', width: '66px', boxShadow: 'none', borderRadius: '0', borderLeft: '0px', borderTop: '0px', borderBottom: '0px', borderColor: '#D1D1D6', textTransform: 'none', fontWeight: 'bold', height: '40px'}} variant="outlined" size="medium" color="primary">
                  Other
                </Button>
              </Grid>
            }
          </Card>
        </div>
       </Zoom>
      <CircleIcon
      disabled={true} style={{position: 'absolute', top: '70px', left: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
    />
    <Handle
        type="target"
        position="left"
        id="target1"
        style={{ top: '77px', left: -9, width: '30px', height: '60px', background: 'transparent',
        zIndex: 999,
        border: 'none', }}
      >
      </Handle>
      <CircleIcon
        disabled={true} style={{position: 'absolute', top: '70px', right: '-5px', height: '10px', width: '10px', backgroundColor:'black', color: 'black', disabled:'true', borderRadius: '50%',zIndex: 1,}} // Fix the position of the icon over here
      />
      <Handle
        type="source"
        position="right"
        id="exit0"
        style={{ top: '74px',  width: '30px', height: '60px', borderRadius: 0, right: '-6px', background: 'transparent', zIndex: 999,border: 'none',
      }}
      />
    </>
  );
});