import React, {useEffect, useRef, useState} from 'react';
import {
  Grid,
  makeStyles,
  Button
} from '@material-ui/core'
import {connect} from 'react-redux';

import TopicLessonOrder from "Views/Private/CreatorTopic/TopicLessonOrder";
import TopicAddComponent from "Views/Private/CreatorTopic/TopicAddComponent";
import {autoCourseCreationActions, topicActions} from '_state/actions';
import Typography from '@material-ui/core/Typography';
import {lessonActions} from "../../../_state/actions";
import {history} from "_state/helpers";

import CapeeshResponsiveListDialog from "../../../Components/CapeeshResponsiveListDialog/CapeeshResponsiveListDialog";
import LessonInformationPane from "./Components/LessonInformationPane";
import {TopicLessonType} from "../TopicConstants";
import CourseSupportedLanguagePane from "./Components/CourseSupportedLanguagePane";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import {CapeeshColors} from "../../../assets/ColorPalette";
import {EditOutlined} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import AutoCourseCreationOrder from "./Components/AutoCourseCreationOrder";
import UnityLessonPreview from "../Scenarios/UnityLessonPreview/UnityLessonPreview";
import WestIcon from '@mui/icons-material/West';
import CreateLessonMenu from "./Menues/CreateLessonMenu";
import CapeeshStyledModal from "../../../Components/CapeeshStyledModal";
import SettingsIcon from '@mui/icons-material/Settings';
import {AutoCourseCreateType} from "../../../Logic/AutoCourseCreationConstants";
import {GlobalEvents} from "../../../assets/GlobalEvents";



const useStyles = makeStyles(theme => ({
  
  lessonOrderContainer: {
    paddingLeft: '75px',
    paddingRight: '75px',

    [theme.breakpoints.down('lg')]: {
      paddingLeft: "10px",
      paddingRight: '10px'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '75px',
      paddingRight: '75px'
    },
  },
  topicLessonOrderContainer: {
    paddingLeft: '25px',

    [theme.breakpoints.down('lg')]: {
      paddingLeft: "8px",
      
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '25px',
    },
  },
  editTopicContainer: {
    paddingLeft: '200px',
    paddingRight: '200px',

    [theme.breakpoints.down('lg')]: {
      paddingLeft: "20px",
      paddingRight: '20px'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '200px',
      paddingRight: '200px'
    },
  },
  titleContainer: {
    paddingLeft: '75px',
    paddingRight: '75px',

    [theme.breakpoints.down('md')]: {
      paddingLeft: "10px",
      paddingRight: '10px'
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '75px',
      paddingRight: '75px'
    },
  },
  
  progressContainer: {
    position: 'relative',
    width: '64px',
    height: '64px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: '10px auto'
  },
  progressCircle: {
    position: 'absolute',
    width: '100% !important',
    height: '100% !important',
    color: '#63C276',
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
  },
  progressText: {
    width: '100%',
    height: '100%',
    color: '#63C276',
    display: 'contents'
  },
  topicJobsStatus: {
    height: '200px',
    width: '100%',
    minWidth: '200px',
    display: '-webkit-box',
    overflow: 'scroll',
  },
  topicJobStatusCard: {
    width: '200px',
  },
  topicJobStatusCardContent: {
    width: '-webkit-fill-available',
    height: '-webkit-fill-available',
    padding: '15px',
  },
  topicAddComponentSection: {
    paddingLeft: '20px'
  },
  dialogTitle: {
    backgroundColor: '#143349',
    fontColor: 'white'
  },
  previewDialogTitle: {
    fontColor: CapeeshColors.DeepBlue
  },
  
  dialogTitleDiv: {
    display: 'flex'
  },
  capHeader: {
    margiTop: '20px',
    display: "flex",
    flexWrap: 'nowrap',
    flexGrow: 1,
    width: "100%",
    borderTop: "4px solid lightgray",
  },
  capHeaderBorder: {
    margiTop: '20px',
    display: "flex",
    flexWrap: 'nowrap',
    flexGrow: 1,
    width: "100%",
    borderTop: "4px solid gray",
  },
}));

const CreatorEditTopic = (props) => {
  const classes = useStyles();
  const {
    computedMatch,
    user,
    metadata,
    topic,
    lesson,
    location,
    role
  } = props;
  const [activeTab, setActiveTab] = useState(1);
  const [remindToSave, setRemindToSave] = useState(false);
  const difficultyFirst = 0;
  const [scenarios, setScenarios] = useState([]);
  const [showAttachScenarioModal, setShowAttachScenarioModal] = useState(false);
  const [showLanguagesPaneModal, setShowLanguagesPaneModal] = useState(false);
  
  let tempTopicId = parseInt(computedMatch.params.topicId);
  if (tempTopicId <= 0) {
    tempTopicId = null;
  }
  const topicId = tempTopicId;
  
  const courseGroupId = computedMatch.params.courseGroupId;
  const [topicData, setTopicData] = useState(null);
  
  // edit pane
  const [showEditPane, setShowEditPane] = useState(false);
  
  // preview mode

  const [showLessonPreview, setShowLessonPreview] = useState(false);
  const unityStagePreviewControllerRef = useRef(null);
  const [customLessonIdToPreview, setCustomLessonIdToPreview] = useState(null);
  
  const [topicCustomLessonIds, setTopicCustomLessonIds] = useState([])
  
  
  // delete lessons from the list
  const [showDeleteLessonConfirmation, setShowDeleteLessonConfirmation] = useState(false);
  const [customLessonIdToDelete, setCustomLessonIdToDelete] = useState(null);
  
  
  // reset acc confirmation and state handling
  const [showResetACCConfirmation, setShowResetACCConfirmation] = useState(false);
  const [transitionToACCType, setTransitionToACCType] = useState(null);
  
  
  useEffect(() => {
    
    props.getCurrentTopic(computedMatch.params.topicId);
    props.dispatch(lessonActions.getOrganizationLessons(props.organization.selectedOrganization))
    //TODO: should be done by the modal instead and refetched
    
    if (props.topic?.lessonOrder) {
      let tempTopicCustomLessonIds = props.topic.lessonOrder.filter(x => x.customLessonId).map(x => x.customLessonId);
      setTopicCustomLessonIds(tempTopicCustomLessonIds);
    }
    
  }, []) 

  useEffect(() => {
    let topicDataFromDataLayer = props.topic.topics.find(x => x.id.toString() == topicId);
    if (topicDataFromDataLayer) {
      setTopicData(topicDataFromDataLayer);

      const filteredScenarios = props.lesson.organizationLessons?.filter(x => x.customLessonTypeName === "Scenario" && x.languageId === topicDataFromDataLayer.l2Id);
      setScenarios(filteredScenarios);
      
    }
  }, [props.topic.topics])
  
  useEffect(() => {
    if (props.topic?.lessonOrder) {
      let tempTopicCustomLessonIds = props.topic.lessonOrder.filter(x => x.customLessonId).map(x => x.customLessonId);
      setTopicCustomLessonIds(tempTopicCustomLessonIds);
    }
  }, [props.topic.lessonOrder])
  
  useEffect(() => {
    let topicDataFromDataLayer = props.topic.topics.find(x => x.id.toString() == topicId);
    if (topicDataFromDataLayer) {
      const filteredScenarios = props.lesson.organizationLessons?.filter(x => x.customLessonTypeName === "Scenario" && x.languageId === topicDataFromDataLayer.l2Id);
      setScenarios(filteredScenarios);  
    }
  }, [props.lesson.organizationLessons])
  
  const handleBackToCourseGroup = () => {
    history.push("/creator/course/group/" + courseGroupId);
  }
  const handleChangeTab = (event, value) => {
    if (remindToSave) {
      setActiveTab(value);
      setRemindToSave(false);
    }
  };


  const handleCloseTopicSupportedLanguages = () => {
    setShowLanguagesPaneModal(false);
  };

  const handleOnDragEnd = (e) => {
    let updatedArray = []
    e.forEach((element, index) => {
      let newElement = {...element}
      newElement.index = index
      updatedArray.push(newElement)
    });
    props.updateLocalLessonOrder(updatedArray, computedMatch.params.topicId, difficultyFirst)

  };
  
  const handleDetachLessonConfirmed = () => {
    if (!customLessonIdToDelete) {
      return;
    }
    
    let tempNewLessonOrderList = []
    props.topic.lessonOrder.forEach((element, index) => {
      if (customLessonIdToDelete !== element.customLessonId) {
        tempNewLessonOrderList.push(element)
      }
    });
    props.updateLocalLessonOrder(tempNewLessonOrderList, computedMatch.params.topicId, difficultyFirst)
  }
  const handleDetachLessonCanceled = () => {
    setCustomLessonIdToDelete(null);
    setShowDeleteLessonConfirmation(false);
  }
  
  const handleDetachScenario = (customLessonId) => {
    setCustomLessonIdToDelete(customLessonId);
    setShowDeleteLessonConfirmation(true);
  };

  const handleToggleGateable = (customLessonId) => {

    props.topic.lessonOrder.forEach((element, index) => {
      if (element.customLessonId === customLessonId) {
        element.gateable = !element.gateable;
      }
    });

    props.updateLocalLessonOrder(props.topic.lessonOrder, computedMatch.params.topicId, difficultyFirst)
  }

  const handleAddScenario = (e) => {
    setShowAttachScenarioModal(true)
  };

  const handleSaveToServer = () => {
    props.updateLessonOrderForDifficulty(computedMatch.params.topicId, difficultyFirst, props.topic.lessonOrder)
  };

  const handleShowLanguagesPane = () => {
    setShowLanguagesPaneModal(true);
  }
  const handleExportAllTranslations = () => {
    handleExportOfAllTranslations(false);
  };

  const handleExportOfAllTranslations = (ignoreAlreadyTranslated) => {
    const {metadata} = props;

    let now = new Date();
    let dateString = now.toISOString().substring(0, 10);
    let l1Ids = [];
    let filenames = []

    var downloadTypeName = ignoreAlreadyTranslated ? "_delta_" : "_full_";
    var zipFileName = topicData.name + downloadTypeName + 'translations_' + dateString + '.zip'

    for (const supportedLanguage of topicData.supportedLanguages) {

      if (supportedLanguage.l1Id === topicData.defaultL1Id) {
        continue;
      }

      var l1Language = metadata.metadata.languages.find(x => x.id === supportedLanguage.l1Id);

      if (l1Language) {
        var filename = topicData.name + '_translation_' + l1Language.languageTag + "_" + l1Language.friendlyName + "_" + dateString + '.xlsx';
        l1Ids.push(supportedLanguage.l1Id);
        filenames.push(filename);
      }
    }
    props.exportExcelTopicTranslationForAllLanguages(topicData.id, l1Ids, filenames, false, false, ignoreAlreadyTranslated, zipFileName);
  };

  const handleExportAllDeltaTranslations = () => {
    handleExportOfAllTranslations(true);
  };

  const handleToggleAttachScenarioCancel = event => {
    setShowAttachScenarioModal(false)
  };


  const handleDeleteTopicSupportedL1Id = (l1Id) => {
    //TODO: fill in when deleting topic languages are supported
  }
  const handleAddTopicSupportedL1Id = (l1Id) =>  {
    props.addSupportedLanguage(topicData.id, l1Id, true);
  }

  const handleShowEditPane = (shouldEdit) => {

    setShowEditPane(shouldEdit);
  }

  const handleTopicCustomLessonTranslationExport = (l1Id, ignoreAlreadyTranslated, noCrossLessonDuplicates, translationVersion) => {
    const {metadata} = props;

    var now = new Date();
    var dateString = now.toISOString().substring(0, 10);

    var l1Language = metadata.metadata.languages.find(x => x.id === l1Id);

    let filename = topicData.name + '_translation_' + l1Id + "_" + dateString + '.xlsx';
    if (l1Language) {
      filename = topicData.name + '_translation_' + l1Language.languageTag + "_" + l1Language.friendlyName + "_" + dateString + '.xlsx';
    }
    var useEnglishTranslationText = false;

    props.exportExcelTopicTranslation(topicData.id, l1Id, filename, noCrossLessonDuplicates, useEnglishTranslationText, ignoreAlreadyTranslated, translationVersion);
  }

  const handleTopicCustomLessonTranslationImport = (l1Id, version) => {
    var input = document.createElement('input');
    input.type = 'file';
    input.onchange = event => {
      // getting a hold of the file reference
      var file = event.target.files[0];
      props.importExcelTopicTranslation(topicData.id, l1Id, file, version);
    }
    input.click();
  }

  const handleResetUnityState = async () => {
    if (unityStagePreviewControllerRef.current) {
      await unityStagePreviewControllerRef.current.unloadUnity();
    }
  }
  
  const handleAddLesson = (customLessonId) => {
    if (!customLessonId) {
      // TODO SHOW ERROR
      return;
    }
    
    props.dispatch(topicActions.attachCustomLessonToTopic(topicId, 0, customLessonId));   
  }
  const closeLessonPreviewDialog = () => {
    setShowLessonPreview(false);
    setCustomLessonIdToPreview(null);
  }

  const handleSelectDifficulty = () => {
    //TODO: need to remove from passed in props also
  }

  const handleToggleAttachScenario = event => {

    setShowAttachScenarioModal(false)
    if (event.length > 0) {
      let newItems = []
      props.lesson.organizationLessons.map(scenario => {
        event.forEach(selectedScenario => {
          if (selectedScenario != null && selectedScenario === scenario.id) {
            let found = false
            props.topic.lessonOrder.forEach(item => {
              if (item.customLessonId === scenario.id) {
                found = true
              }
            });
            if (found === false) {
              let temp =
                {
                  customLessonId: scenario.id,
                  tempId: scenario.id,
                  content: scenario.name,
                  name: scenario.name,
                  description: scenario.description,
                  topicLessonType: TopicLessonType.Scenario,
                  topicLessonTypeName: 'Scenario'
                }
              newItems.push(temp)
            }
          }
        });
      });
      newItems.push(...props.topic.lessonOrder)
      props.updateLocalLessonOrder(newItems,  computedMatch.params.topicId, 0)
    }
    ;
  }
  
  const goToCreateAiLanguage = () => {
    setTransitionToACCType(AutoCourseCreateType.Language)
    
    let needsAccReset = checkIfACCNeedsReset(AutoCourseCreateType.Language)
    if (needsAccReset) {
      setShowResetACCConfirmation(true)
    } else {
      transitionToAccLanguage()
    }
    
  }
  
  const checkIfACCNeedsReset = (autoCourseCreateType) => {
    if (!props.autoCourseCreation?.authorInputMetadata) {
      return false;
    }
    
    if (props.autoCourseCreation.authorInputMetadata.topicId !== topicId) {
      return true;
    }
    
    if (props.autoCourseCreation.authorInputMetadata.autoCourseCreateType !== autoCourseCreateType) {
      return true;
    }
    
    return false;
    
  }

  const handleOpenLessonPreview = (customLessonId) => {

    document.dispatchEvent(GlobalEvents.UnityLessonPreviewHideShowToggle);
    document.dispatchEvent(new CustomEvent("initLessonPreviewWithInfo",
        { detail: { customLessonId: customLessonId, l1Id: topicData.defaultL1Id, nodeId: null, clickableSkipButton: true, l2Id: topicData.l2Id }}));

  }
  
  const goToCreateAiComplience = () => {
    setTransitionToACCType(AutoCourseCreateType.Complience)

    let needsAccReset = checkIfACCNeedsReset(AutoCourseCreateType.Complience)
    if (needsAccReset) {
      setShowResetACCConfirmation(true)
    } else {
      transitionToAccComplience()
    }
  }

  const transitionToAccLanguage = () => {
    const queryParams = "?createFromTopic=true&topicId=" + topicId + "&l2Id=" + topicData.l2Id + "&defaultL1Id=" + topicData.defaultL1Id

    let accUrl = '/creator/course/group/' + courseGroupId + '/topic/' + topicId + '/acc/language/' + queryParams;
    history.push(accUrl);
  }
  
  const transitionToAccComplience = () => {
    const queryParams = "?createFromTopic=true&topicId=" + topicId + "&l2Id=" + topicData.l2Id + "&defaultL1Id=" + topicData.defaultL1Id

    let accUrl = '/creator/course/group/' + courseGroupId + '/topic/' + topicId + '/acc' + queryParams;
    history.push(accUrl);
  }
  
  const resetAutoCourseCreateFromAnotherTopicConfirmed = () => {

    setShowResetACCConfirmation(false);

    props.dispatch(autoCourseCreationActions.accResetAll())
    props.dispatch(autoCourseCreationActions.accResetAllLessonPlans())
    props.dispatch(autoCourseCreationActions.accResetAllSections())
    
    if (transitionToACCType == AutoCourseCreateType.Language) {
      transitionToAccLanguage()
    } 
    if (transitionToACCType === AutoCourseCreateType.Complience) {
      transitionToAccComplience()
    }
  }
  
  
  if (!topicData)
    return (
      <div>
      </div>
    );
  let originList = []
  originList = topic.lessonOrder.filter((listItem) => {
    if (lesson.organizationLessons?.find(x => x.id === listItem.customLessonId))
      return listItem.customLessonId
  });

  return (
    <div>

      {
        showResetACCConfirmation &&
        <CapeeshStyledModal open={showResetACCConfirmation}
                            textTitle={"Do you want to remove your current AI-assisted project and start again?"}
                            textLine1={"Currently, you can only get AI assistance to work on one project at a time."}
                            onButton1={() => setShowResetACCConfirmation(false)}
                            onButton2={() => resetAutoCourseCreateFromAnotherTopicConfirmed()}
                            button1Variant={'outlined'}
                            button1Text={"Cancel"}
                            button2Text={"Yes"}
                            onClose={() => setShowResetACCConfirmation(false)}
        />
      }
      
      {
          showDeleteLessonConfirmation &&
          <CapeeshStyledModal
              open={showDeleteLessonConfirmation}
              textTitle={"Lesson Detach Confirmation"}
              textLine1={"Do you want to detach  the lesson? It will not be deleted, but cannot be added to the topic again."}
              onButton2={() => handleDetachLessonConfirmed()}
              onButton1={() => handleDetachLessonCanceled()}
              button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel"/>}
              button1Variant={'outlined'}
              button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Detach"/>}
              onClose={() => handleDetachLessonCanceled()}
          />
      }

      {
        showLanguagesPaneModal &&
        <Dialog fullWidth={true} maxWidth={'md'} open={showLanguagesPaneModal} onClose={handleCloseTopicSupportedLanguages}>
          <DialogTitle className={classes.dialogTitle}>
            <div className={classes.dialogTitleDiv}>
              <Typography variant='body2'
                          style={{
                            marginTop: '3px',
                            boxShadow: 'none',
                            flex: 1,
                            fontSize: 36,
                            color: 'white',
                            justifyContent: 'center',
                            paddingLeft: '15px',
                            textAlign: 'left',
                            alignItems: 'center'
                          }}>
                Course Supported Languages
              </Typography>
              <IconButton onClick={() => setShowLanguagesPaneModal(false)} size="medium" color={'white'}>
                <CancelOutlinedIcon className={classes.closeBustton} style={{fill: "white", width: 38, height: 38,}}
                                    size="medium"/>
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <CourseSupportedLanguagePane open={showLanguagesPaneModal}
                                         topicSupportedLanguages={topicData.supportedLanguages}
                                         topicId={topicData.id}
                                         onDeleteL1Id={handleDeleteTopicSupportedL1Id}
                                         onAddL1Id={handleAddTopicSupportedL1Id}
                                         onExportTranslationForL1Id={handleTopicCustomLessonTranslationExport}
                                         onImportTranslationForL1Id={handleTopicCustomLessonTranslationImport}
            />
          </DialogContent>
        </Dialog>
      }

      <CapeeshResponsiveListDialog
        open={showAttachScenarioModal}
        dialogTitle="Lesson Library"
        dialogContentText="Select a lesson to attach to this course."
        handleCancel={handleToggleAttachScenarioCancel} //TODO: Was not linking to anything
        handleButton2={handleToggleAttachScenario}
        handleButton1={handleToggleAttachScenarioCancel}
        button1Text="Cancel"
        button1Variant={'outlined'}
        button2Text="Attach Lesson"
        list={
          scenarios?.map((entry) => {
            const singleObj = {};
            const nameAndType = entry.name;
            singleObj['id'] = entry.id;
            singleObj['name'] = nameAndType;
            singleObj['invalid'] = entry.languageId !== topicData.l2Id;
            singleObj['invalidReason'] = "(The scenario teaches a different language)";
            return singleObj;
          })
        }
        originList={topic.lessonOrder.map(function (elem) {
          return {"id": elem.customLessonId};
        })}
      />
      
      <Grid container className={classes.titleContainer} style={{backgroundColor: 'white', padding: '20px'}}>
          <Grid item xs={12} sm={7} mb={7} style={{
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left',
          }}>

            {
              courseGroupId &&
              <IconButton
                onMouseDown={(event) => handleBackToCourseGroup(event)}
                sx={{ fontSize: "35px",  display: 'center', alignItems: 'left', marginLeft: '4px', justifyContent: 'center',  height: '40px', color: 'gray'}}>
                <WestIcon sx={{ fontSize: "35px"}}/>
              </IconButton>
            }
                        
            <Typography variant='h4'  style={{display: 'inline-block', color: CapeeshColors.DeepBlue}}>
              Topic: {topicData.name}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={5} mb={5} style={{
            display: 'flex',
            alignItems: 'right',
            justifyContent: 'right',
          }}>
            <Button
              className={classes.leftSideButtons}
              style={{
                color: 'gray',
                height: '40px', 
                marginRight: '15px',
                textTransform: 'none',
                marginLeft: '20px',
                minWidth: '120px',
              }}
              onClick={() => handleShowEditPane(!showEditPane)}
            >
              <FormattedMessage id="dashboard.creator.button.title" defaultMessage={ !showEditPane ? "Topic info" : "Finish editing"}/>
              <SettingsIcon sx={{marginLeft: '5px', fontSize: '15px', color: 'gray'}}/>
            </Button>

            <CreateLessonMenu 
              courseGroupId={courseGroupId}
              topicId={topicId}
              l2Id={topicData.l2Id}
              defaultL1Id={topicData.defaultL1Id}
              onAttachExistingScenario={handleAddScenario}
              showAttachExistingScenario={user.organizationRole === "admin"}
              onGoToCreateAiLanguage={goToCreateAiLanguage}
              onGoToCreateAiComplience={goToCreateAiComplience}
            />

          </Grid>
        </Grid>
   
        <div>
          {
            showEditPane &&
            <Grid container className={classes.editTopicContainer}>
              <Grid item xs={12} sm={6} mb={6} >
                    <TopicAddComponent
                      difficulties={props.topic.topicDifficulties}
                      selectedDifficulty={difficultyFirst}
                      onSelectDifficulty={handleSelectDifficulty}
                      onAddScenario={handleAddScenario}
                      onSaveToServer={handleSaveToServer}
                      topicId={topicId}
                      l2Id={topicData.l2Id}
                      l2Name={metadata.metadata?.languages?.find(x => x.id === topicData.l2Id)?.friendlyName}
                      defaultL1Id={topicData.defaultL1Id}
                      courseGroupId={courseGroupId}
                      showEditLanguage={user.organizationRole === "admin" && topicData.lessonsOnly}
                      onShowLanguagesPane={handleShowLanguagesPane}
                      computedMatch={computedMatch}
                      deleteTopic={props.deleteTopic}
                      topicData={topicData} 
  
                    />
                 </Grid>
                
                 <Grid item xs={12} sm={6} mb={6} >
                   <LessonInformationPane
                    onSaveToServer={handleSaveToServer}
                    onShowLanguagesPane={handleShowLanguagesPane}
                    showEditLanguage={user.organizationRole === "admin" && topicData.lessonsOnly}
                    onExportAllTranslations={handleExportAllTranslations}
                    onExportAllDeltaTranslations={handleExportAllDeltaTranslations}
                    disableExportTranslationButtons={true} 
                    topicData={topicData} 
                    computedMatch={computedMatch} 
                    role={role}
                    deleteTopic={props.deleteTopic}
                  />
                  </Grid>
              </Grid>
                            }
            {/*<Grid item xs={12} className={classes.capHeaderBorder}>*/}
            {/*</Grid>*/}

          
           <div style={{background: 'white', backgroundColor: 'white'}}>
          
            <Grid container className={classes.lessonOrderContainer}>
              <Grid item xs={12} sm={5} mb={5} lg={5}
                    style={{
                      backgroundColor: CapeeshColors.EggShell1,
                      // backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(238, 237, 234  ) 1px, transparent 0)',
                      // backgroundSize: '12px 12px',
                      borderRadius: '16px'}}>
                    <AutoCourseCreationOrder
                        courseGroupId={courseGroupId}
                        topicId={topicId}
                        l2Id={topicData.l2Id}
                        defaultL1Id={topicData.defaultL1Id}
                        dispatch={props.dispatch}
                        onPreviewLesson={handleOpenLessonPreview}
                        onAddLesson={handleAddLesson}
                        topicCustomLessonOrderIds={topicCustomLessonIds}
                        onGoToCreateAiLanguage={goToCreateAiLanguage}
                        onGoToCreateAiComplience={goToCreateAiComplience}
                    >
                    </AutoCourseCreationOrder>
                  
              </Grid>
              
              <Grid item xs={12} sm={7} mb={7} lg={7} className={classes.topicLessonOrderContainer}>
                <div style={{
                  backgroundColor: CapeeshColors.EggShell1,
                  // backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(238, 237, 234  ) 1px, transparent 0)',
                  // backgroundSize: '12px 12px',
                  borderRadius: '16px'
                }}>
                  <div style={{paddingLeft:'15px', paddingRight:'15px'}}>
                    <TopicLessonOrder
                      selectedDifficulty={difficultyFirst}
                      difficulties={props.topic.topicDifficulties}
                      onDragEnd={handleOnDragEnd}
                      onDetachScenario={handleDetachScenario}
                      onToggleGateable={handleToggleGateable}
                      courseGroupId={courseGroupId}
                      topicId={topicId}
                      onSaveToServer={handleSaveToServer}
                      topicData={topicData} 
                      computedMatch={computedMatch} 
                      role={role}
                      onAddScenario={handleAddScenario}
                      l2Id={topicData.l2Id}
                      defaultL1Id={topicData.defaultL1Id}
                      onPreviewLesson={handleOpenLessonPreview}
                      dispatch={props.dispatch}
                    />
                  </div>
                </div>
              </Grid>
              
            </Grid>
          </div>
        </div>
    </div>
  );
};

CreatorEditTopic.propTypes = {};

const mapToProps = (state) => {
  return {
    organization: state.organization,
    topic: state.topic,
    user: state.user,
    metadata: state.metadata,
    backgroundJobs: state.backgroundJobs,
    auth: state.auth,
    role: state.role,
    lesson: state.lesson,
    autoCourseCreation: state.autoCourseCreation,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentTopic: (topicId) => dispatch(topicActions.getCurrentTopic(topicId)),
    addSupportedLanguage: (topicId, l1Id, autoPublish) => dispatch(topicActions.addSupportedLanguage(topicId, l1Id, autoPublish)),
    getTopicDifficulties: (topicId) => dispatch(topicActions.getTopicDifficulties(topicId)),
    getLessonOrderForDifficulty: (topicId, topicLessonDifficultyId) => dispatch(topicActions.getLessonOrderForDifficulty(topicId, topicLessonDifficultyId)),
    updateLocalLessonOrder: (lessonArray, topicId, difficultyFirst, lessonOrder) => dispatch(topicActions.updateLocalLessonOrder(lessonArray, topicId, difficultyFirst, lessonOrder)),
    updateLessonOrderForDifficulty: (topicId, difficulty, topicLessonOrder) => dispatch(topicActions.updateLessonOrderForDifficulty(topicId, difficulty, topicLessonOrder)),
    exportExcelTopicTranslation: (topicId, l1Id, filename, noCrossLessonDuplicates, useEnglishTranslationText, ignoreAlreadyTranslated, translationVersion) => dispatch(topicActions.exportExcelTopicTranslation(topicId, l1Id, filename, noCrossLessonDuplicates, useEnglishTranslationText, ignoreAlreadyTranslated, translationVersion)),
    importExcelTopicTranslation: (topicId, l1Id, file, version) => dispatch(topicActions.importExcelTopicTranslation(topicId, l1Id, file, version)),
    exportSentenceSuggestionsFromFile: (l2Id, file, filename) => dispatch(topicActions.exportSentenceSuggestionsFromFile(l2Id, file, filename)),
    exportExcelTopicTranslationForAllLanguages: (topicId, l1Ids, filenames, noCrossLessonDuplicates, useEnglishTranslationText, ignoreAlreadyTranslated, zipFileName) => dispatch(topicActions.exportExcelTopicTranslationForAllLanguages(topicId, l1Ids, filenames, noCrossLessonDuplicates, useEnglishTranslationText, ignoreAlreadyTranslated, zipFileName)),
    generalReset: () => dispatch(lessonActions.generalReset()),
    deleteTopic: (organizationId, topicId) => dispatch(topicActions.deleteTopic(organizationId, topicId)),

  }
};

export default connect(mapToProps, mapDispatchToProps)(CreatorEditTopic)

