import React, {useState} from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
} from '@material-ui/core';
import {connect} from 'react-redux';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  card: {
    cursor: 'pointer',
    transition: 'all .25s ease',
    '&:hover': {
      boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
    },
  },
  cardContent: {
    position: 'relative',
  },
  cardActionArea: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    padding: '10px',
    width: '-webkit-fill-available',
  },
  cardAction: {
    color: '#cdcdcd',
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    float: 'right',
    transition: 'all .25s ease',
    '&:hover': {
      color: '#262626',
      background: 'rgba(0, 0, 0, 0.0)',
    },
  },
  
  organizationHeader: {
    marginLeft: '20px'
  },
  userGroupHeader: {
    marginLeft: '100px'
  }
  
}));

const UserSearchResultCard = (props) => {
  const {user, metadata} = props;
  const classes = useStyles();

  const [anchorSettingsMenu, setAnchorSettingsMenu] = useState(null);
  
  return (
    <Card 
          className={classes.card}
    >
      <CardContent className={classes.cardContent}>
        <div className={classes.cardActionArea}>
          
        </div>
        <Grid container justify='center' alignContent='center'>

          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='caption'>
                  Username
                </Typography>
                <Typography variant='subtitle2'>
                  {`${user.name} (${user.email})`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='caption'>
                  L1: {metadata.metadata?.languages?.find(x => x.id === user.l1Id)?.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='caption'>
                  L2: {metadata.metadata?.languages?.find(x => x.id === user.l2Id)?.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12} sm={12}>
            <Grid container>
              {
                user.organizations?.map((organization, index) => (
                  <Grid item xs={12} key={organization.organizationId} className={classes.organizationHeader}>
                    <Typography variant='caption'>
                      Organization: <b>{organization.organizationName}</b> with role <b>{organization.organizationRole}</b>
                    </Typography>
                    
                    {
                      organization.userGroups?.map((userGroup, userGroupIndex) => (
                        <Grid item xs={12} key={userGroup.userGroupId} className={classes.userGroupHeader}>
                          <Typography variant='caption'>
                            UserGroup: <b>{userGroup.userGroupName}</b> with role <b>{userGroup.userGroupRole}</b>
                          </Typography>
                        </Grid>
                      ))
                    }
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

UserSearchResultCard.propTypes = {};

const mapToProps = (state) => {
  return {
    metadata: state.metadata,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapToProps, mapDispatchToProps)(UserSearchResultCard)