import { asyncRequest } from './asyncRequest'

export const snackbarConstants = {
    ENQUEUE_SNACKBAR: new asyncRequest('ENQUEUE_SNACKBAR'),
    REMOVE_SNACKBAR: new asyncRequest('REMOVE_SNACKBAR'),
    CLEAR_SNACKBAR: new asyncRequest('CLEAR_SNACKBAR'),
};

export const snackbarActions = {
    enqueueSnackbar(notification) {
        return {
            type: snackbarConstants.ENQUEUE_SNACKBAR.REQUEST,
            notification: {
                key: new Date().getTime() + Math.random(),
                ...notification,
            },
        }
    },

    removeSnackbar (key) {
            return {
                type: snackbarConstants.REMOVE_SNACKBAR.REQUEST,
                key,
            }
    },

    clearSnackbar () {
            return {
                type: snackbarConstants.CLEAR_SNACKBAR.REQUEST,
            }
    },
};



