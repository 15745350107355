import React, {useEffect, useState} from "react";
import {
    Grid,
} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';


const useStyles = makeStyles(theme => ({
  playIcon: {
    color: '#eee',
    fontSize: 36,
    marginLeft: '15px',
    //marginRight: '15px',
    cursor: 'pointer',
  },
  leftBox: {
/* Gray 3 */
background: '#EFEFEF',
border: '1px solid #D1D1D6',
boxSizing: 'border-box',
boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
borderRadius: '20px'
  },
  leftPanel: {
    minWidth: '400px'
  },
  mainContainer: {
    paddingLeft: '25px',
    paddingRight: '25px'
  }, 
  rightPaper: {
    /* White */
  //  marginLeft: '20px',
marginTop: '30px',
background: '#FFFFFF',
/* Gray 3 */

border: '1px solid #D1D1D6',
boxSizing: 'border-box',
borderRadius: '15px',
  }


}));

const TutorialConnecting = (props) => {
    const classes = useStyles();
    const tutorialParagraph = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontSize: '16px', marginBottom: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialSubHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', marginTop: '10px'}}>
            {text}
          </Typography>
        </Grid>
      )
     }

     const tutorialHeader = (text) => {
      return (
        <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px'}} >
          <Typography variant="h6" style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', marginBottom: '20px'}}>
            {text}
          </Typography>
        </Grid>

      )
     }


    return (
  
      <Grid container style={{paddingLeft: '50px', paddingTop: '40px', paddingRight: '20px'}}> 
      {tutorialHeader("Connecting lesson stages together")}
      {tutorialParagraph("How do we specify the order in which our lesson stages should play? This is a simple task that takes a matter of seconds. In this section, we will explore this process.")}
      {tutorialSubHeader("How it works")}  
      {tutorialParagraph("There are no rules on how to build a lesson, other than that it must have a beginning (Brief stage) and en end (Debrief stage), and that all stages in between are connected.")}                        
      {tutorialParagraph("When first creating a stage, it is not yet connected to any other stage. We can see this as it has no wires connecting to or from it.")}  
      {tutorialParagraph("The video below explains this, showing a series of lesson stages being connected, and ready to play in the app.")}                        
      <Grid item xs={12} sm={12} md={12} style={{display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: '35px'}}>
            <iframe src="https://player.vimeo.com/video/677699817?h=20d39993f3" style={{alignItems: 'center'}} width="768" height="432"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Grid>
    </Grid>
    );
    }


const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(TutorialConnecting)