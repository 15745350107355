import React, {useEffect, useState} from "react";
import {
    Grid,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Slider from '@material-ui/core/Slider';
import { HighlightWithinTextarea } from 'react-highlight-within-textarea'
import CapeeshHighlighter from './CapeeshHighlighter';

const useStyles = makeStyles(theme => ({
  root: {
    //width: 300,
  },
  chip: {
    marginRight: '5px',
    marginBottom: '5px',
  },
  scenario: {
    paddingTop: '30px'
  },
  textFields: {
    marginBottom: '5px'
  },
  slider: {
    marginLeft: '20px',
    marginRight: '50px'
  }
}));

const SentenceBuilderSlider = withStyles({
  root: {
    color: '#143349',
    height: 8,
    marginLeft: '10px',
    marginRight: '10px'
  },
  thumb: {
    height: 24,
    width: 24,
    background: '#F8F8F8',
    border: '7px solid #143349',
    boxSizing: 'border-box',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);


const InteractionFillInBlankDistractors = (props) => {
    const classes = useStyles();
    const [toggleString, setToggleString] = useState("Words");
    const [userHasEdited, setUserHasEdited] = useState(false);
    const [firstWordInRange, setFirstWordInRange] = React.useState([0,0]);
    const [selectionStart, setSelectionStart] = React.useState(-1);
    const [selectionEnd, setSelectionEnd] = React.useState(-1);
    const [isSelection, setIsSelection] = React.useState(false);
    const [highlighter, setHighlighter] = React.useState([]);
    
    const [currentChunkMode, setCurrentChunkMode] = useState(null); 
    
    useEffect(() => {
      handleToggle(props.chunkingMode, "props")
      //detectExistingChunkSetting()
    }, [props.text]);

    useEffect(() => {
      detectExistingChunkSetting()
    }, []);

    useEffect(() => {
      setHighlighter(highlightIterator(props.testOption.alternatives))
  }, [props.testOption.alternatives]);

    const handleAddChip = (chip, index) => {
      props.onAddDistractor("sentenceBuilder", chip, index, props.testOption.testOptionId)
    };

    const handleDeleteChip = (chip, index) => {
      props.onDeleteDistractor(null, "sentenceBuilder", index, )
    };

    const handleEditText = (text) => {
      setUserHasEdited(true)
      props.onEditText(text.target.value, "textImageSentenceBuilder")
    };
    
    const chunkSentence = (sentence, chunks) => {
      let maxChunks = sentence.split(" ")
      let split = maxChunks.length / chunks
      let alternativesArray = []
      for(let x = 0; x < maxChunks.length; x++) {
          if(alternativesArray[parseInt((x / split), 10)]) {
            alternativesArray[parseInt((x / split), 10)] = alternativesArray[parseInt((x / split), 10)] + " " + maxChunks[x]
          } else {
            alternativesArray[parseInt((x / split), 10)] = maxChunks[x]
          }
      }
      return alternativesArray
    }

    const detectExistingChunkSetting = () => {
      // determines the existing setting for sentence builder, since there is no backend concept of this:
      // Are the chunks currently single letters, syllables, words or sentence chunks?
      let individualWords = props.text.split(" ")
      let isSentence = true
      let couldBeLettersMode = true
      let couldBeChunnksMode = false
      if(individualWords.length > props.alternatives.length) {
        couldBeChunnksMode = true
      }

      props.alternatives.forEach(chunk => {
        let isWordSetting = false
        let isChecked = false
        if(chunk.text.length > 1) {
          couldBeLettersMode = false
        }
        individualWords.forEach(wordsFromText => {
          if(chunk.text === wordsFromText && isChecked === false) {
            isWordSetting = true
            isChecked = true
          } 
        });
       if(isWordSetting === false) {
        isSentence = false
       }
      });

      if(isSentence) {
        setCurrentChunkMode(2);
        props.onUpdateChunkingMode(props.testOption.testOptionId, 2)

      } else if(couldBeLettersMode) {
        setCurrentChunkMode(0);
        props.onUpdateChunkingMode(props.testOption.testOptionId, 0)


      } else if(!couldBeLettersMode && !isSentence && !couldBeChunnksMode) {
        setCurrentChunkMode(1);
        props.onUpdateChunkingMode(props.testOption.testOptionId, 1)


      } else if(!couldBeLettersMode && !isSentence && couldBeChunnksMode) {
        setCurrentChunkMode(3);
        props.onUpdateChunkingMode(props.testOption.testOptionId, 3)

      } else {
        alert("went wrong", currentChunkMode)
      }
    }

    const handleToggle = (newValue, cause) => {
        if(userHasEdited || cause === "slider") {
          switch(newValue) {
            case 0: 
              // every letter
              setCurrentChunkMode(0);
              props.onUpdateChunkingMode(props.testOption.testOptionId, 0);

              setToggleString("Letters")
              let tempArraySyllables = props.text.split("")
              props.onEditDistractors(tempArraySyllables, "letters", props.text, 0, props.testOptionIndex)
              break;
            case 1: 
            // every letter
              setCurrentChunkMode(1);
              props.onUpdateChunkingMode(props.testOption.testOptionId, 1);
              setToggleString("Syllables")
              let tempArrayLetters = props.text.split(" ")
              props.onEditDistractors(tempArrayLetters.map(syllabify), "syllables", props.text, 1, props.testOptionIndex)
              break;
            case 2: 
              // every word
              setCurrentChunkMode(2);
              props.onUpdateChunkingMode(props.testOption.testOptionId, 2);
              setToggleString("Words")
              let tempArrayWords = props.text.split(" ")
              props.onEditDistractors(tempArrayWords, "words", props.text, 2, props.testOptionIndex)
              break;
            case 3: 
              // small chunks
              setCurrentChunkMode(3);
              props.onUpdateChunkingMode(props.testOption.testOptionId, 3);
              setToggleString("Chunks")
              let chunkedLarge = chunkSentence(props.text, 4)
              props.onEditDistractors(chunkedLarge, "chunks4", props.text,3, props.testOptionIndex)
              break;
            }
          }
      
    }

  const syllableRegex = /[^aeiouy]*[aeiouy]+(?:[^aeiouy]*$|[^aeiouy](?=[^aeiouy]))?/gi;
  const syllabify = (words)  => {
    if(words.match(syllableRegex) === null) {
      return [words]
    }
      return words.match(syllableRegex);
  }


  const checkExistingAlternative = (alternatives, selectionStart, selectionEnd) => {
    let isAvailableWord = true
    alternatives.forEach(alternative => {    
      if((selectionStart > alternative.startIndex && selectionStart <= (alternative.startIndex + alternative.text.length)) ) { 
        isAvailableWord = false
      }
      if((selectionStart < alternative.startIndex && selectionEnd > (alternative.startIndex + alternative.text.length)) && 
      (selectionEnd - selectionStart > alternative.startIndex + alternative.text.length - alternative.startIndex) ) { 
        isAvailableWord = false
      }
      if((selectionEnd > alternative.startIndex && selectionEnd < (alternative.startIndex + alternative.text.length)) ) { 
        isAvailableWord = false
      }
    });
    return isAvailableWord
  }

  const highlightIterator = (alternatives) => {
    let highlightArray = []
    let addedStartIndeces = []
    alternatives.forEach(alternative => {
      if(!!!addedStartIndeces.includes(alternative.startIndex)) {
        highlightArray.push([alternative.startIndex, alternative.startIndex + alternative.text.length])
        addedStartIndeces.push(alternative.startIndex)
      }
    });
    return highlightArray
  }




    const handleChange = (event, newValue) => {
      if(newValue !== currentChunkMode) {
        handleToggle(newValue, "slider")
      }
    };

    const marks = [
      {
        value: 0,
        label: 'Letters',
      },
      {
        value: 1,
        label: 'Syllables',
      },
      {
        value: 2,
        label: 'Words',
      },
      {
        value: 3,
        label: 'Chunks',
      },
    ];

    return (
<React.Fragment> 
  <div className={classes.root}>
    <Grid container>
      <Grid className={classes.slider} item xs={12} sm={12} md={12}  >
        <SentenceBuilderSlider
          value={currentChunkMode}
          defaultValue={1}
          onChange={handleChange}
          aria-labelledby="discrete-slider-restrict"
          step={1}
          valueLabelDisplay="auto"
          min={0}
          max={3}
          marks={marks}
        />
      </Grid>
    </Grid>
    </div>
  </React.Fragment>  
  );
};

InteractionFillInBlankDistractors.propTypes = {};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(InteractionFillInBlankDistractors)