import { configWithAuth, configWithoutAuth } from '../helpers';
import { makeGetRequest, makePostRequest } from "../helpers/api";

export const authService = {
    login: (payload) => makePostRequest('admin/auth/sign-in', JSON.stringify(payload), configWithoutAuth()),
    logout: () => makePostRequest('admin/auth/sign-out', configWithAuth()),
    register: (payload) => makePostRequest('admin/auth/sign-up', JSON.stringify(payload), configWithoutAuth()),
    forgotPasswordPin: (payload) => makePostRequest('auth/forgot-password-pin', JSON.stringify(payload), configWithoutAuth()),
    updateForgotPassword: (payload) => makePostRequest('auth/update-forgot-password', JSON.stringify(payload), configWithoutAuth()),
};
