import React from "react";
import {connect} from 'react-redux';
import {ReactFlowProvider} from 'react-flow-renderer';
import LessonBuilderController from "./LessonBuilderController";

const LessonBuilderEncapsulator = (props) => {
  return (
      <ReactFlowProvider>
        <LessonBuilderController computedMatch={props.computedMatch} dispatch={props.dispatch} props={props} />
      </ReactFlowProvider>
    );
};

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(LessonBuilderEncapsulator)