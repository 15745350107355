import React, {useState, useRef} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {CircularProgress, Grid, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ACCLessonCardSimplified from './ACCLessonCardSimplifiedImproved';
import AutoCourseSuggestLessonDetailsLoading from './../AutoCourseCreateContentEditMode/LessonDetailsComponents/AutoCourseSuggestLessonDetailsLoading'
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import Add from '@material-ui/icons/Add';
import ACCLessonCardSimplifiedImproved from "./ACCLessonCardSimplifiedImproved";


const useStyles = makeStyles((theme) => ({

}))


const AutoCourseSuggestLessonPlanImproved = (props) => {
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState(false);

  const refs = React.useRef([]);

  const handleClick = (lessonPlanId, index) => {
    props.onUpdateSelectedLesson(lessonPlanId)
  }

  const handleCreateLesson = (type, sectionId) => {    
    props.onCreateLesson(props.autoCourseCreation.lessonPlansDtos.lessonPlanId, sectionId, type)
  }
  
  const containsLessonForConcept = () => {
    return false
  }

  const getGradiantColour = (type) => {   
    
    switch (type){
      case "Introduction":
       return "#15D0BC"
       break;
      case "Repetition":
      return "#A0439F"
      break;

      default:
        return "#FF7A3B"
    }

    

  }
 let counter = 0
  return (
<div style={{borderRadius: '5px', padding: '10px', paddingLeft: '20px', width: '95%', maxHeight: 800, minWidth: 600 }}>
  <Grid container style={{ alignContent: "flex-start"}}>
<Grid item xs={12}>
{!!!props.autoCourseCreation?.lessonPlansDtos.length > 0 &&

  <div onMouseLeave={() => setIsFocus(false)}>



  <Typography style={{color: "var(--darker-60, rgba(10, 10, 10, 0.60))",
            fontFamily: "Rubik",
            fontSize:14,
            fontStyle: "normal",
            fontWeight:400,
            letterSpacing:0.41, marginBottom: '10px', display: 'flex', alignItems: 'center', position: 'relative'}}
            >
              Add another lesson
          
              <Add onMouseEnter={() => setIsFocus(-1)}
                sx={{ fontSize: "2px",  height: '2px', color: '#8E8E93', marginTop: '20px'}} style={{fontSize: '17px', cursor: 'pointer', marginLeft: '5px'}}
              />
              {isFocus === -1 && 
                <div  
                style={{ position: 'absolute', display: "inline-flex", flexDirection: "column", gap:5,borderRadius:5, background: "#FFF", zIndex:1000, width: '120px',left: 170,cursor: 'pointer',}}
                >
                  <Grid container>
                    <Grid onClick={() => handleCreateLesson("Introduction", null)} item xs={12} style={{width:166, height:33, padding: '20px'}}>
                      <Typography>
                      Introduction
                      </Typography>
                    </Grid>
                    <Grid onClick={() => handleCreateLesson("Repetition", null)} item xs={12} style={{width:166, height:33, padding: '20px'}}>
                      <Typography>
                      Repeat
                      </Typography>
                    </Grid>
                    <Grid onClick={() => handleCreateLesson("Assessment", null)} item xs={12} style={{width:166, height:33, padding: '20px', marginBottom: '4px'}}>
                      <Typography>
                      Exam
                      </Typography>
                    </Grid>
            
                  </Grid>
                  </div>
                  }
              </Typography>
              </div>
}

{  props.autoCourseCreation?.suggestSectionsReturnDto?.sections && 
      props.autoCourseCreation.suggestSectionsReturnDto.sections.map((section, indexSections) => (
        <div style={{marginBottom: '0px', position: 'relative'}}>
            {props.autoCourseCreation.isSuggestingLessonPlans?.map((lessonBeingMade, i) => {
  if(lessonBeingMade === section.sectionId){
    counter = counter + 2
   }
   if(lessonBeingMade === section.sectionId){
    return (
    <Grid key={i} item xs={12} style={{width: '100%', marginTop: '10px', marginBottom: '10px', background: 'white', borderRadius: '5px'}}>
       <AutoCourseSuggestLessonDetailsLoading lessonNumber={counter - 1} key={i}/>      
    </Grid>
    );
   }
   
   
})}


        {props.autoCourseCreation?.lessonPlansDtos?.map((lessonPlanDto, index) => 
        lessonPlanDto.lessons?.map((lessonSuggestionDto, index2) => {
          
            if (lessonSuggestionDto.sectionId === section.sectionId) {
              counter++
              return            <div>
        
              <ACCLessonCardSimplifiedImproved 
                ref={ref => refs.current[index2] = ref}
                isSelectedLesson={false}
                onUpdateStagePlotSelection={props.onUpdateStagePlotSelection}
                onUpdateLessonSuggestionDto={props.onUpdateLessonSuggestionDto} 
                selectedLesson={props.selectedLesson} 
                selectedStage={props.selectedStage}
                onChangeSelectedStage={(e)=>props.onChangeSelectedStage(e)}
                onClick={(e)=>handleClick(e, index2)}
                lessonNumber={counter} 
                lessonSuggestionDto={lessonSuggestionDto} 
                key={index2}
                onSuggestQuiz={props.onSuggestQuiz}
                onSuggestScenario={props.onSuggestScenario}
                onMakeLesson={props.onMakeLesson}
                onLessonFromLessonPlan={props.onLessonFromLessonPlan}
                onPreviewLessson={props.onPreviewLesson}
                isQuizGenerating={props.autoCourseCreation.isGeneratingQuizForLessonPlanIds?.includes(lessonSuggestionDto.lessonPlanId)}
                onWriteQuizQuestion={props.onWriteQuizQuestion}
                isScenarioGenerating={props.autoCourseCreation.isGeneratingScenarioForLessonPlanIds?.includes(lessonSuggestionDto.lessonPlanId)}
                isDragging={props.isDragging}
                draggedWidth={props.draggedWidth}
                onGetStageDetails={props.onGetStageDetails}
                isDialogueGenerating={props.autoCourseCreation.isGeneratingDialogForLessonPlanIds?.includes(lessonSuggestionDto.lessonPlanId)}
                onRemoveConcept={props.onRemoveConcept}
                onRemoveLesson={props.onRemoveLesson}
                isLastInTopic={lessonPlanDto.lessons.length === index2 + 1}
                lessonPlansId={lessonPlanDto.lessonPlanId}
                onAddNewStages={props.onAddNewStages}
                onDeleteQuizQuestion={props.onDeleteQuizQuestion}
                lessonPlanDto={lessonPlanDto}
              />
        {     lessonPlanDto.lessons.length === index2 + 1 &&

             <Grid item xs={12} style={{}}>
              <div    style={{width: '300px'}}           
              onMouseLeave={() => setIsFocus(false)}

              >

          <Typography style={{color: "var(--darker-60, rgba(10, 10, 10, 0.60))",
          fontFamily: "Rubik",
          fontSize:14,
          fontStyle: "normal",
          fontWeight:400,
          letterSpacing:0.41, marginBottom: '10px', display: 'flex', alignItems: 'center'}}
          >
            Add another lesson
        
                      <Add                       onMouseEnter={() => setIsFocus(lessonSuggestionDto.lessonPlanId)}
                     sx={{ fontSize: "2px",  height: '2px', color: '#8E8E93', marginTop: '20px'}} style={{fontSize: '17px', cursor: 'pointer', marginLeft: '5px'}}
/>
                      {isFocus === lessonSuggestionDto.lessonPlanId && 
            <div style={{ position: 'absolute', display: "inline-flex", flexDirection: "column", gap:5,borderRadius:5, background: "#FFF", zIndex:1000, width: '120px',left: 170,cursor: 'pointer',
            }}>
                <Grid container>
                  <Grid onClick={() => handleCreateLesson("Introduction", section.sectionId)} item xs={12} style={{width:166, height:33, padding: '20px'}}>
                    <Typography>
                    Introduction
                    </Typography>
                  </Grid>
                  <Grid onClick={() => handleCreateLesson("Repetition", section.sectionId)} item xs={12} style={{width:166, height:33, padding: '20px'}}>
                    <Typography>
                    Repeat
                    </Typography>
                  </Grid>
                  <Grid onClick={() => handleCreateLesson("Assessment", section.sectionId)} item xs={12} style={{width:166, height:33, padding: '20px', marginBottom: '4px'}}>
                    <Typography>
                    Exam
                    </Typography>
                  </Grid>
          
                </Grid>
                </div>}
            </Typography>
    
                </div>

                  </Grid>}
    </div>  
            }
            return null;
          })
        )}
</div>
))
}
</Grid>

        
    
  </Grid>
</div>
  )
}


AutoCourseSuggestLessonPlanImproved.propTypes = {
  
};


function mapStateToProps(state) {
  const {autoCourseCreation} = state;
  return {
    autoCourseCreation
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseSuggestLessonPlanImproved)