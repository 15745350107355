import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {
    Grid, makeStyles, MenuItem, Select, FormControlLabel, FormControl, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import CreateIcon from '@material-ui/icons/Create';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BriefWizard from "../../../assets/images/698wBriefDebrief.png";
import ShortDescriptionWizard from "../../../assets/images/ShortDescriptionWizard.png";
import ShortDescriptionNoImage from "../../../assets/images/ShortDescriptionNoImage.png";
import LinearProgress from '@material-ui/core/LinearProgress';
import CrossfadeImage from "react-crossfade-image"
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {lessonActions} from '_state/actions';
import {makeGetRequest} from "../../../_state/helpers/api";
import {configWithAuth} from "../../../_state/helpers";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import MentorNoneIcon from "../../../assets/images/MentorNoneIcon.png";
import MentorLin from "../../../assets/images/MentorLin.png";
import MentorAbe from "../../../assets/images/MentorAbe.png";
import ImageSearch from "./Images/ImageSearch";
import { v4 as uuidv4 } from 'uuid';
import {useLocation} from "react-router-dom";
import {convertDataURIToBinary} from "../../../_state/helpers/base64Logic";
import TextFieldWithLanguageSelector from "./ScenarioComponents/TextFieldWithLanguageSelector";
import {ExtractMimeType} from "../../../Logic/Base64Helpers";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white'
  },
  imageViewer: {
    maxWidth: '300px',
    minWidth: '200px',
    height: 'auto'
  },
  
  button: {
    marginTop: '20px',
    marginBottom: '20px',
    marginRight: '10px'
  },
  
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    marginTop: 0
  },
  
  leftPanel: {
    paddingTop: '10px'
  },
  
  middlePanel: {
    marginTop: '20px',
    paddingLeft: '200px',
    width: '300px'
  },
  
  stepperSectionLinearProgress: {
    paddingTop: '8px'
  },
  
  scenario: {
    paddingTop: '30px'
  },
  
  scenarioDescription: {
    paddingTop: '8px'
  },
  
  scenarioDescriptionText: {
    color: '#8e8e93',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
  }, 
  
  scenarioTextField: {
    borderRadius: '10px',
    paddingTop: '8px'
  },
  
  scenarioShortDescription: {
    borderRadius: '10px',
    paddingTop: '8px'
  },
  
  
  listItemDone: {
    borderRadius: "1em",
    "&,&:focus,&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  
  scenarioScenesPreviewSection: {
    marginTop: '20px'
  },
  
  phoneStepper: {
    padding: 0,
    backgroundColor: 'white'
  },
  
  input: {
    display: 'none',
  }, 
  

  sectionHeaderStyle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '22px',
    paddingBottom: '8px',
    color: '#35323C'
  }
}));


const ScenarioCreate = (props) => {
  const {computedMatch} = props;
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [phoneScreenIndex, setPhoneScreenIndex] = React.useState(1);    
  const [selectedMentor, setSelectedMentor] = React.useState(0);
  const [title, setTitle] = React.useState("");
  const [contentTabIndex, setContentTabIndex] = React.useState(0);
  const [completedManualImageCrop, setCompletedManualImageCrop] = React.useState(false);
  const [completedManualImageName, setCompletedManualImageName] = React.useState(false);
  const [imageName, setImageName] = React.useState('');
  const [imageFilename, setImageFilename] = useState(title + '- brief');
  const [uploadedFileName, setUploadedFileName] = useState(""); // the position in the list of answers for the answer the user has clicked on
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [files, setFiles] = useState(null);
  const [imageType, setImageType] = useState(0);
  const [fileUrl, setFileUrl] = useState(null);
  const [cropBrief, setCropBrief] = useState({
    unit: '%',
    width: 30,
    aspect: 270 / 191,
  }); 
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, [])
    
  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }
    const pixelRatio = 1
    const image = imgRef.current;
    const crop = completedCrop;
    const canvasPrimary = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvasPrimary.getContext("2d");
    canvasPrimary.width = crop.width * pixelRatio;
    canvasPrimary.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    async function asyncHandleContainer(canvasPrimary) {
      await handleImageCropToFile(canvasPrimary);
    }
    asyncHandleContainer(canvasPrimary);

  }, [completedCrop]);

  const [src, setSrc] = React.useState(null);
  const [fileExtension, setFileExtension] = React.useState(null);
  const [fileType, setFileType] = React.useState(null);
  const [askingForImage, setAskingForImages] = React.useState(false);
  const [brief, setBrief] = React.useState("");
  const [shortDescription, setShortDescription] = React.useState("");
  const [selectedImageUrl, setSelectedImageUrl] = React.useState(null);
  const [backgroundImageBlob, setBackgroundImageBlob] = React.useState(null);
  const [serverImageUrl, setServerImageUrl] = React.useState("");
  const [backgroundImageBlobURL, setBackgroundImageBlobURL] = React.useState(null);
  const [scenarioImages, setScenarioImages] = React.useState([]);
  const [textFocus, setTextFocus] = React.useState(0);
  const [languageId, setLanguageId] = React.useState(1);
  const [defaultLanguageId, setDefaultLanguageId] = React.useState(3);

  // auto attatch to course (added from a course)
  const queryParamsSearch = useLocation().search;
  const [attachToTopicId, setAttachToTopicId] = React.useState(null);
  const [disableLanguageSelection, setDisableLanguageSelection] = React.useState(false);

  useEffect(() => {
    
    const createFromTopic = new URLSearchParams(queryParamsSearch).get("createFromTopic");
    if (createFromTopic === "true") {
      const l2Id = new URLSearchParams(queryParamsSearch).get("l2Id");
      const defaultL1Id = new URLSearchParams(queryParamsSearch).get("defaultL1Id");
      const topicId = new URLSearchParams(queryParamsSearch).get("topicId");
      if (l2Id && defaultL1Id && topicId) {
        setAttachToTopicId(topicId);
        setLanguageId(l2Id);
        setDefaultLanguageId(defaultL1Id);
        setDisableLanguageSelection(true);
      }
    }
  }, []);
    
  
  
  const [titleLanguageSelectionOption, setTitleLanguageSelectionOption] = React.useState(1)
  const [briefLanguageSelectionOption, setBriefLanguageSelectionOption] = React.useState(0)
  const [shortDescriptionLanguageSelectionOption, setShortDescriptionLanguageSelectionOption] = React.useState(0)
  
  const MIN = 0
  const MAX = 2
  const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

  useEffect(() => {
    const {lesson, organization} = props
    if(props.lesson.imageReferences.length > 0) {
        const promises = lesson.imageReferences.filter(x => x.niceName.toLowerCase().includes('scene')).map(imageReferenceId => new Promise((resolve, reject) => {
          setAskingForImages(true)
          makeGetRequest('admin/organization/'+organization.selectedOrganization + '/image/file/reference/' + imageReferenceId.apiUrl.replace('image/file/','')+ '/base64', configWithAuth())
          .then(result => {
              let type = null
              const mimeTypeImage = ExtractMimeType(result.data);
              switch(mimeTypeImage) {
                case "image/jpeg":
                    //this.setState({fileExtension: 'jpeg'});
                    type = 'jpeg'
                    break;
                case "image/png":
                    type = 'png'
                    //this.setState({fileExtension: 'png'});
                    break;
                default:
                  type = 'unknown'
                  break;
            }
          var binary= convertDataURIToBinary(result.data);
          var blob=new Blob([binary], {type : type});
          var blobUrl = URL.createObjectURL(blob);
         resolve({type: type, image: blob, imageUrl: blobUrl, imageReferenceId: imageReferenceId});
          })
          .catch(error => {
              console.log("error",error);
              return reject(error);
          })
      }));
      Promise.all(promises).then(results => {
        let arrayCombined = scenarioImages.concat(results);
        arrayCombined = [...new Set([...scenarioImages,...results])]
        setScenarioImages(arrayCombined)      
      });
    }
  }, [props.lesson.imageReferences]);
  
  useEffect(() => {
      const {user} = props
      if (user?.organizationRole === 'admin')
      {
          setContentTabIndex(0);
      }
      else
      {
          setContentTabIndex(1);
      }
  }, [props.user?.organizationRole]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    switch(index) {
      case 1:
      setPhoneScreenIndex(1)
        break;
      case 2:
      setPhoneScreenIndex(1)
        break;
    }
  };
    
  const handleUpdateTitleAndImageName = (value) => {
    setTitle(value)
    setImageFilename(value + "- brief")
  }
  const handleMetadataIsInL1 = (value, type) => {
    if (type === "title") {
      setTitleLanguageSelectionOption(value);
    }
    else if (type ===  "brief") {
      setBriefLanguageSelectionOption(value);
    }
    else if (type === "summary") {
      setShortDescriptionLanguageSelectionOption(value);
    }
  }
  
    const handleChangeSceneTab = (event, value) => {
      setContentTabIndex(value)
    };

    const handleImageFromExternalSource = (image) => {
      setCompletedManualImageName(false)
      setCompletedManualImageCrop(false)
      let imageFile = image[0]
      var blobLocal=new Blob(imageFile, {type : 'jpeg'});
      var blobUrlLocal = URL.createObjectURL(image);
      let imageGuid = uuidv4();
      setSrc(blobUrlLocal)
      setFiles(imageFile)
      setFileUrl(blobUrlLocal)
      setUpImg(blobUrlLocal)
      switch(image.type) {
        case "image/jpeg":
          setFileExtension('jpeg')
        break;
        case "image/png":
          setFileExtension('png')
      break;
    }   
      var finalisedFile = new File([image], imageName+"."+fileExtension,{type: image.type, lastModified:new Date()})
      setUploadedFileName(imageFilename)
      setCompletedManualImageCrop(false) //setIsCroppedComplete(false)
      setFileType(finalisedFile.type)
    };

    const handleCreateScenario = (event, value) => {
        const {organization} = props;
        var finalisedFile = null
        if(backgroundImageBlob) {
            finalisedFile = new File([backgroundImageBlob], imageFilename+"."+fileExtension,{type: fileType, lastModified:new Date()})
        } 
        var briefLanguageId = briefLanguageSelectionOption === 0 ? defaultLanguageId : languageId;
        var shortDescriptionLanguageId = shortDescriptionLanguageSelectionOption === 0 ? defaultLanguageId : languageId;
        var titleLanguageId = titleLanguageSelectionOption === 0 ? defaultLanguageId : languageId;
        let courseGroupId = computedMatch.params.courseGroupId;
        props.dispatch(lessonActions.createScenario(organization.selectedOrganization, title, titleLanguageId, brief, briefLanguageId, shortDescription, shortDescriptionLanguageId, serverImageUrl, finalisedFile, languageId, defaultLanguageId, attachToTopicId, courseGroupId));
    };

    const updateLanguage= (event) => {
        setLanguageId(event.target.value)
    };

    const updateDefaultL1Language= (event) => {
        setDefaultLanguageId(event.target.value)
    };
  
    const handleMentorSelect = (event, value) => {
      setSelectedMentor(event)
    };
  
  const handleImageCropToFile = (canvasPrimary) => {
    return new Promise((resolve, reject) => {
      canvasPrimary.toBlob(blob => {
      if (!blob) {
        console.error('Capeesh canvas is empty');
        return;
      }
      blob.name = "tempname";
      blob.lastModifiedDate = new Date();
      window.URL.revokeObjectURL(fileUrl);
      fileUrl = window.URL.createObjectURL(blob);
      var thefile = new File([blob], "tempname",{type:fileType, lastModified:new Date()})
      var fileUrl = window.URL.createObjectURL(thefile)
      setBackgroundImageBlob(thefile)
      setSelectedImageUrl(fileUrl)
      resolve(fileUrl);
    });
  });
  };



  const briefPreview = (() => {
    return (
      <div style={{position: 'absolute', top: 55, left: 20}} container>
        <img 
            src={ selectedImageUrl === null ? ShortDescriptionNoImage : selectedImageUrl  }
            style={{position: 'relative',objectFit: 'cover',
            objectPosition: '0 100%',
            color: 'black', 
            top: '0px',
            left: '0px',
            width: '260px',
            height: '187px'
            }}/>
      </div>
      )
    })

    const speakPreview = (() => {
      return (
        <div style={{position: 'absolute', top: 55, left: 20}} container>
          <img 
            src={ selectedImageUrl}
            style={{position: 'relative', width: '250px', height: 'auto' }}/>
        </div>
      )
    })

    
      const mentorPreview = ((stageType) => {
        let mentorImage = null
        let position = {top: 177, left: 80, width: '150px'}
        switch(selectedMentor) {
          case 0: mentorImage = MentorNoneIcon
                  return <div />
          case 1: mentorImage = MentorLin
                  break;
          case 2: mentorImage = MentorAbe;
                  break;
          default: return <div />
        }
        switch(stageType) {
          case 'watch': 
            position = {top: 177, left: 80, width: '150px'}
            break;
          case 'brief': 
          position = {top: 97, left: 110, width: '80px'}
          break;
        }
        return (
          <div style={{position: 'absolute', top: position.top, left: position.left}} container>
            <img 
            src={mentorImage}
            style={{position: 'relative', width: position.width, height: 'auto' }}/>
          </div>
        )
      })

  const shortDescriptionText  = (() => {
    return (
      <Grid style={{position: 'absolute', top: 250, left: 45}} container>
        <Grid item xs={12} sm={12}>
          <Typography 
            variant='body1'  
            style={{
            //paddingLeft: '50px',
            fontWeight: 500,
            //width: '70px',
            boxShadow: 'none',
            letterSpacing: 0.29174,
            fontFamily: [
              "Nunito",
              "Tahoma", 
              "Rubik",
              'Roboto',
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              '"Helvetica Neue"',
              'Arial',
          ].join(','),
            flex: 1,
            fontSize: 16,
            lineHeight: 1.2,
            minHeight: '30px',
            width: '200px',
            color: 'white',
            justifyContent: 'center',
            //paddingLeft: '16px',
            textAlign: 'left',
            alignItems: 'center'}}>
              {title}
          </Typography>  
        </Grid>
        <Grid item xs={12} sm={12} 
          style={{
          marginTop: '44px',
          color: '#8E8E93',
          fontFamily: [
            "Tahoma", 
            "Rubik",
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
        ].join(',')
          }}>
            <Typography 
              variant='body2'  
              style={{
              marginTop: '3px',
              width: '207px',
              // marginLeft: '35px',
              boxShadow: 'none',
              flex: 12,
              fontSize: 10,
              color: '#8E8E93',
              justifyContent: 'center',
              // paddingLeft: '15px',
              textAlign: 'center',
              letterSpacing: 0.41,
              alignItems: 'center'}}>
                {shortDescription}
            </Typography>
          </Grid>
        </Grid>
      )
    })

    const briefText = (() => {
      return (
        <Grid style={{position: 'absolute', top: 233, left: 0, maxHeight: '30px'}} container>
                  <Grid item xs={12} sm={12}>
                    <Typography 
                      variant='body1'  
                      style={{
                      //marginTop: '6px',
                      //width: '70px',
                      marginLeft: '40px',
                      width: '220px',
                      maxHeight: '30px',
                      boxShadow: 'none',
                      "fontWeight": 525,
                      letterSpacing: 0.29174,
                      fontFamily: [
                        "Tahoma", 
                        "Rubik",
                        'Roboto',
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        '"Helvetica Neue"',
                        'Arial',
                    ].join(','),
                      flex: 1,
                      fontSize: 17,
                      color: 'white',
                      justifyContent: 'center',
                      //paddingLeft: '16px',
                      textAlign: 'center',
                      alignItems: 'center'}}>
                        {title}

                    </Typography>  
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography 
                        variant='body2'  
                        style={{
                        marginTop: '36px',
                        width: '198px',
                        marginLeft: '35px',
                        maxHeight: '30px',
                        boxShadow: 'none',
                        flex: 12,
                        letterSpacing: 0.1,
                        fontSize: 10,
                        color: 'white',
                        justifyContent: 'center',
                        paddingLeft: '15px',
                        textAlign: 'center',
                        alignItems: 'center'}}>
                          {brief}
                      </Typography>
                    </Grid>
                  </Grid>
      )
    })

    
  const shortDescriptionPreview = (() => {
    return (
      <div style={{position: 'absolute', top: 237, left: 37}} container>
        <CrossfadeImage 
        src={selectedImageUrl}
        style={{position: 'relative', width: '225px', height: '130px', objectFit: 'cover',
        objectPosition: '0 100%', }}/>
      </div>
    )
  })

  const onSelectFile = (e) => {
    setCompletedManualImageName(false)
    setCompletedManualImageCrop(false)
    setPhoneScreenIndex(1)
    if (e.target.files && e.target.files.length > 0) {
      setFileType(e.target.files[0].type)
      switch(e.target.files[0].type) {
        case "image/jpeg":
        setFileExtension('jpeg')
        break;
        case "image/png":
        setFileExtension('jpeg')
      break;
      }

      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onCropChange = (crop, percentCrop) => {
    //setCropWatch(crop)
    setCropBrief(crop)
   };

   const onImageLoaded = image => {
    imgRef.current = image;
  };

  const handleCompletedManualImageCrop = (e) => {
    setCompletedManualImageCrop(true)
    setCompletedManualImageName(false)
  };

  const onCropComplete = crop => {
     makeClientCrop(crop, true, 'newFile.png');
};

const makeClientCrop = async (crop, isWatchStage, fileName) => {
  if (imgRef.current  && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
      imgRef.current,
      crop,
      fileName,
      this
    );
    if (isWatchStage) {
      setCroppedImageUrl(croppedImageUrl)
    } 
  }    
}

const getCroppedImg = async (image, crop, fileName, state) => {
  const canvasPrimary = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  var originWidth = crop.width * scaleX;
  var originHeight = crop.height * scaleY;
  var targetWidth = originWidth,
    targetHeight = originHeight;

  // set canvas size
  canvasPrimary.width = targetWidth;
  canvasPrimary.height = targetHeight;
  const ctx = canvasPrimary.getContext("2d");
  ctx.drawImage(
    image, 
    crop.x * scaleX, 
    crop.y * scaleY, 
    crop.width * scaleX, 
    crop.height * scaleY, 
    0, 
    0, 
    targetWidth, 
    targetHeight 
  );
  return new Promise((resolve, reject) => {
    canvasPrimary.toBlob(blob => {
    if (!blob) {
      console.error('Capeesh canvas is empty');
      return;
    }
    blob.name = fileName;
    blob.lastModifiedDate = new Date();
    window.URL.revokeObjectURL(fileUrl);
    fileUrl = window.URL.createObjectURL(blob);
    var thefile = new File([blob], "tempname",{type:fileType, lastModified:new Date()})
    var fileUrl = window.URL.createObjectURL(thefile)
    setFiles(thefile)
    setFileUrl(fileUrl)
    setBackgroundImageBlob(thefile)
    setSelectedImageUrl(fileUrl)  
    resolve(fileUrl);
  });
});

}

  const handleSetImageType = (value) => {
    setUpImg(null)
    setImageType(value)
    setFiles(null)
    setFileUrl(null)
    setBackgroundImageBlob(null)
    setSelectedImageUrl(null) 
    setPhoneScreenIndex(1)
  }

  const scene = (() => {
    const {lesson} = props
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
        <Typography
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '36px',
            lineHeight: '22px',
            paddingBottom: '8px',
            color: '#35323C'
                  }} >
          Image    
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.scenarioDescription}>
        <Typography variant="body" className={classes.scenarioDescriptionText}  >
          Choose the background for your lesson front screen. 
        </Typography>
      </Grid>

      <React.Fragment>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl component="fieldset" style={{ marginTop: '20px'}}>
                    <RadioGroup
                      row
                      value={imageType}
                      onChange={(e) => handleSetImageType(e.target.value) } 
                    >
                      <FormControlLabel value='0' control={<Radio />} label="Upload" />
                      <FormControlLabel value='1' control={<Radio />} label="Search" />
                    </RadioGroup>
                </FormControl>
                {imageType === '1' && (!!!upImg || completedManualImageName) &&
              <React.Fragment>
                 <ImageSearch onSelectImage={handleImageFromExternalSource}/>
                </React.Fragment>
              }
                {imageType === '0' && 
              <React.Fragment>
                <input id="contained-button-file" type="file" accept="image/*" onChange={onSelectFile} className={classes.input} />
                <label htmlFor="contained-button-file">
                    <Button className={classes.button} variant="outlined" size="small" color="primary" component="span">
                      {completedManualImageName ? 'Change image' : 'Choose image'}           
                    </Button>
                </label>
                </React.Fragment>
              }
              <React.Fragment>
              {completedManualImageName && 
                <Typography variant='body1' className={classes.nameyourimage}>
                  Your image has been added.
                </Typography>
              }
              {upImg && !!!completedManualImageCrop && !!!completedManualImageName &&
              <Button className={classes.button} onClick={handleCompletedManualImageCrop} variant="outlined" size="small" color="primary" component="span">
                Done           
              </Button>
              }
            </React.Fragment>
          </Grid>            
          <Grid item xs={6} sm={6}>
            {!!!completedManualImageName &&
            <ReactCrop
                  className={classes.imageViewer}
                  src={upImg}
                  crop={cropBrief}
                  ruleOfThirds
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
            />
            }
        </Grid>
      </Grid>
     </React.Fragment>
      <Grid item xs={12} sm={12} md={12} className={classes.scensarioButton} style={{marginTop: '0px'}}>
        <Button variant="contained" size="large" color="primary" onClick={handleCreateScenario} className={classes.margin} style={{maxWidth: '240px', minWidth: '240px', fontSize: '26px', textTransform: "none"}}>
          Create lesson
        </Button>
      </Grid>
    </React.Fragment>
    );
  });

  const description = (() => {
    return (
      <React.Fragment>
      <Grid item xs={12} sm={12} md={12} className={classes.scenario}onClick={() => setPhoneScreenIndex(1)} >
        <Grid container>
          <Typography className={classes.sectionHeaderStyle}>
                Lesson title
            </Typography>
          <Grid item xs={12} sm={12} md={12} className={classes.scenarioDescription}>
            <Typography variant="body" className={classes.scenarioDescriptionText} style={{marginBottom: '200px',}}  >
              Name your lesson! Take a look at the phone screen to the right to see how this will 
              look to your students in the app. 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{marginTop: '10px'}} >
            <TextFieldWithLanguageSelector 
                text={title}  
                fullWidth={false}
                width={'400px'}
                disabled={false} 
                onChange={(event) => {handleUpdateTitleAndImageName(event.target.value)}}
                onChangeLanguageLanguage={(e) =>  handleMetadataIsInL1(e.target.value, "title")}
                l2Id={languageId}
                l1Id={defaultLanguageId}
                canChangeLanguage={true}
                displayL1OrL2={titleLanguageSelectionOption}
              /> 
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className={classes.scenario} style={{}} >
            <Typography className={classes.sectionHeaderStyle}>
              Brief
            </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.scenarioDescription}>
        <Typography variant="body" className={classes.scenarioDescriptionText}  >
          Write a short brief for your lesson. Give your students an explanation of 
          the lesson's objectives. 
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.scenarioShortDescription} onClick={() => setPhoneScreenIndex(1)}>
            <TextFieldWithLanguageSelector 
                text={brief}  
                fullWidth={false}
                width={'600px'}
                disabled={false} 
                onChange={(event) => {setBrief(event.target.value)}}
                onChangeLanguageLanguage={(e) =>  handleMetadataIsInL1(e.target.value, "brief")}
                l2Id={languageId}
                l1Id={defaultLanguageId}
                canChangeLanguage={true}
                displayL1OrL2={briefLanguageSelectionOption}
              /> 
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} >
          <Typography className={classes.sectionHeaderStyle} style={{marginTop: '30px'}}>
            Summary
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.scenarioShortDescription} onClick={() => setPhoneScreenIndex(2)}>
          <Grid item xs={12} sm={12} md={12} className={classes.scenarioDescription}>
            <Typography variant="body" className={classes.scenarioDescriptionText}  >
              Write one sentence that summarises your lesson.
            </Typography>
          </Grid>
          <TextFieldWithLanguageSelector 
              text={shortDescription}  
              fullWidth={false}
              width={'600px'}
              disabled={false} 
              value={shortDescription} 
              onChange={(event) => {setShortDescription(event.target.value)}}                
              onChangeLanguageLanguage={(e) =>  handleMetadataIsInL1(e.target.value, "summary")}
              l2Id={languageId}
              l1Id={defaultLanguageId}
              canChangeLanguage={true}
              displayL1OrL2={shortDescriptionLanguageSelectionOption}
                /> 
          </Grid>
        </Grid>
      </React.Fragment>
    )
 });

const classes = useStyles();
  return (
    <Grid container className={classes.root} >
        <Grid className={classes.middlePanel} item xs={7} sm={7} md={7}>
            <Grid container className={classes.stepperSection}>
              {description()}
              {scene()}
          </Grid>
        </Grid>
        <Grid item xs={3} sm={3} md={3} style={{}}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <div style={{position: 'relative'}}> 
            {
                phoneScreenIndex === 1 ? briefPreview() : shortDescriptionPreview()
            }
            {
                phoneScreenIndex === 1 ? mentorPreview('brief') : <div/>
            }
                <CrossfadeImage 
                  src={phoneScreenIndex === 1 ? BriefWizard : ShortDescriptionWizard}
                  style={{position: 'relative', maxWidth:'300px', width: '300px', height: 'auto' }}/>

                {
                    phoneScreenIndex === 1 ? briefText() : shortDescriptionText()
                }
            </div>
          </Grid>
        </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
    const {organization, usergroup, topic, lesson, metadata, user} = state;
    return {
        organization,
        topic,
        usergroup,
        lesson,
        metadata,
        user
    };
}

ScenarioCreate.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioCreate)