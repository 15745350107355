import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { organizationActions} from "_state/actions";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Typography, CircularProgress, List, ListItem, MenuItem, MenuList, Select, makeStyles
} from "@material-ui/core";

import { FormattedMessage } from 'react-intl';

import {Add} from "@material-ui/icons";
import Dropzone from "react-dropzone";
import {snackbarActions} from "../../../_state/actions";
import {put} from "redux-saga/effects";
import {makeGetRequest, makePostRequest} from "../../../_state/helpers/api";
import {configWithAuth} from "../../../_state/helpers";
import {OrganizationFileAssetLoadTypes, OrganizationFileAssetTypes} from "../../../Logic/OrganizationAssetConstants";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import _ from 'lodash';
import OrganizationOverviewSidebar from "../Organization/Components/OrganizationOverviewSidebar";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
    marginLeft: 'calc(15% + 25px)',
    marginRight:'25px',

  },
  divider: {
    margin: '0 30'
  },
  dropZone: {
    position: 'relative',
    height: '100px',
    color: '#ccc',
    border: 'dashed 2px #ccc',
    borderRadius: '15px',
    backgroundColor: '#f9f9f9',
    margin: '15px auto',
    padding: 15,
    transition: 'all 200ms ease-out',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'block',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}));

const flexContainer = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
};

const EditOrganizatinoImageAssets = (props) => {

  const classes = useStyles();

  // organizatino file assets
  const [organizationFileAssets, setOrganizationFileAssets] = useState([]);

  const [organizationLogoContent, setOrganizationLogoContent] = useState('');
  const [tempOrganizationLogoContent, setTempOrganizationLogoContent] = useState('');

  const [loadingLogoContent, setLoadingLogoContent] = useState(false);

  const [organizationMainMenuContent, setOrganizationMainMenuContent] = useState([]);
  const [tempOrganizationMainMenuContent, setTempOrganizationMainMenuContent] = useState([])

  const [loadingOrganizationMainMenuContent, setLoadingOrganizationMainMenuContent] = useState(false);

  const [selectedMainMenuContentIdx, setSelectedMainMenuContentIdx] = useState(0);

  const [organizationReviewContent, setOrganizationReviewContent] = useState([]);
  const [tempOrganizationReviewContent, setTempOrganizationReviewContent] = useState([]);
  const [loadingOrganizationReviewContent, setLoadingOrganizationReviewContent] = useState(false);
  const [selectedReviewContentIdx, setSelectedReviewContentIdx] = useState(0);

  const [assetTypeSelected, setAssetTypeSelected] = useState(1);
  const [fileChooserType, setFileChooserType] = useState(1);

  const [loadingFileReferences, setLoadingFileReferences] = useState(false);
  const [fileReferences, setFileReferences] = useState([]);

  const [selectedFileReferenceIdx, setSelectedFileReferenceIdx] = useState([]);

  const [displayedFileReference, setDisplayedFileReference] = useState('');
  const [displayedImageFile, setDisplayedImageFile] = useState('');



  useEffect( () => {
    const {location} = props;
    const organizationId = (location.state && location.state.organizationId) ? location.state.organizationId : props.organization.selectedOrganization;

    props.dispatch(organizationActions.getOrganizationAssets(organizationId));

  }, []);


  useEffect(() => {
    if (props.organization.organizationFileAssets)
    {
      const organizationId = props.organization.selectedOrganization;

      let newOrganizationLogo = props.organization.organizationFileAssets.find(x => x.organizationFileAssetType === OrganizationFileAssetTypes.Logo && x.organizationId === organizationId);
      let prevOrganizationLogo = organizationFileAssets.find(x => x.organizationFileAssetType === OrganizationFileAssetTypes.Logo && x.organizationId === organizationId);

      if (!loadingLogoContent && newOrganizationLogo !== undefined && !_.isEqual(newOrganizationLogo, prevOrganizationLogo)) {
        LoadLogoContent(newOrganizationLogo);
      }

      let newMainMenuFileAssets = props.organization.organizationFileAssets.filter(x => x.organizationFileAssetType === OrganizationFileAssetTypes.MainMenu && x.organizationId === organizationId);
      let prevMainMenuFileAssets = organizationFileAssets.filter(x => x.organizationFileAssetType === OrganizationFileAssetTypes.MainMenu && x.organizationId === organizationId);

      if (!loadingOrganizationMainMenuContent && !_.isEqual(newMainMenuFileAssets, prevMainMenuFileAssets)) {
        LoadMainMenuContent(newMainMenuFileAssets);
      }

      let newReviewFileAssets = props.organization.organizationFileAssets.filter(x => x.organizationFileAssetType === OrganizationFileAssetTypes.Review && x.organizationId === organizationId);
      let prevReviewFileAssets = organizationFileAssets.filter(x => x.organizationFileAssetType === OrganizationFileAssetTypes.Review && x.organizationId === organizationId);

      if (!loadingOrganizationReviewContent && !_.isEqual(newReviewFileAssets, prevReviewFileAssets)) {
        LoadReviewContent(newReviewFileAssets);
      }
    }
  }, [props.organization.organizationFileAssets]);


  const LoadMainMenuContent = (mainMenuAssets) => {

    setOrganizationMainMenuContent([]);
    if (mainMenuAssets.length === 0)
    {
      return;
    }

    setLoadingOrganizationMainMenuContent(true);


    const len = mainMenuAssets.length;
    let downloadMark = mainMenuAssets.length;

    for (let i = 0; i < len; i++) {
      makeGetRequest(`admin/organization/${mainMenuAssets[i].organizationId}/image/file/reference/${mainMenuAssets[i].fileReferenceId}/base64`, configWithAuth())
        .then(result => {

          let content = organizationMainMenuContent;
          if (content.find(x => x && x.id === mainMenuAssets[i].id) === undefined)
          {
            content.push({
              id: mainMenuAssets[i].organizationFileAssetId,
              image: result.data
            });
            setOrganizationMainMenuContent(content);
          } else {
            downloadMark--;
          }

          if (organizationMainMenuContent.length >= downloadMark) {
            setLoadingOrganizationMainMenuContent(false);
          }
        })
        .catch(error => {
          console.log(error);

          downloadMark--;

          if (organizationMainMenuContent.length >= downloadMark) {
            setLoadingOrganizationMainMenuContent(false);
          }
        });
    }

  }

  const LoadReviewContent = (reviewAssets) => {
    setOrganizationReviewContent([]);
    if (reviewAssets.length === 0) {
      return;
    }

    setLoadingOrganizationReviewContent(true);

    const len = reviewAssets.length;
    let downloadMark = reviewAssets.length;

    for (let i = 0; i < len; i++) {
      makeGetRequest(`admin/organization/${reviewAssets[i].organizationId}/image/file/reference/${reviewAssets[i].fileReferenceId}/base64`, configWithAuth())
        .then(result => {

          let content = organizationReviewContent;
          if (content.find(x => x && x.id === reviewAssets[i].id) === undefined)
          {
            content.push({
              id: reviewAssets[i].organizationFileAssetId,
              image: result.data
            });
            setOrganizationReviewContent(content);
          } else {
            downloadMark--;
          }

          if (organizationReviewContent.length >= downloadMark) {
            setLoadingOrganizationReviewContent(false);
          }
        })
        .catch(error => {
          console.log(error);

          downloadMark--;

          if (organizationReviewContent.length >= downloadMark) {
            setLoadingOrganizationReviewContent(false);
          }
        });
    }
  }

  const LoadLogoContent = (logoFileAsset) => {
    if (logoFileAsset !== undefined && !loadingLogoContent) {
      setLoadingLogoContent(true)
      setTempOrganizationLogoContent(organizationLogoContent)
      setOrganizationLogoContent('')
      makeGetRequest(`admin/organization/${logoFileAsset.organizationId}/image/file/reference/${logoFileAsset.fileReferenceId}/base64`, configWithAuth())
        .then(result => {
          setOrganizationLogoContent(result.data);
          setTempOrganizationLogoContent('')
          setLoadingLogoContent(false);
        })
        .catch(error => {
          console.log(error);
          setOrganizationLogoContent(tempOrganizationLogoContent);
          setTempOrganizationLogoContent('');
          setLoadingLogoContent(false);
        })
    }
  }

  


  const onFileReferenceSelected = (index) => {
    let fileRefIdx = index;
    setSelectedFileReferenceIdx(fileRefIdx);

    if (fileReferences.length === 0 ||
      fileRefIdx < 0 ||
      fileRefIdx >= fileReferences.length) {
      return;
    }

    let fileReference = fileReferences[fileRefIdx];
    if (fileReference === undefined) {
      return;
    }

    setDisplayedFileReference(fileReference);


    let organizationId = props.organization.selectedOrganization;

    // Get uploaded Image.
    makeGetRequest("admin/organization/" + organizationId + "/image/file/reference/" + fileReference.id + "/base64", configWithAuth())
      .then(result => {

        // Storing the uploaded Image.
        setDisplayedImageFile(result.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDrop = (acceptedFiles) => {

    if (acceptedFiles.length <= 0)
    {
      return;
    }
    else if (acceptedFiles.length > 1)
    {
      return put(snackbarActions.enqueueSnackbar({
        message: "You can only select 1 file to upload as logo.",
        options: {
          variant: "warning"
        }
      }));
    }

    let organizationId = props.organization.selectedOrganization;

    let file = acceptedFiles[0];

    // Need to make a new file in order to change the file name to a unique one.
    let date = Date.now().toString();
    let newFileName = date + "_" + file.name;
    let newFile = new File([file], newFileName, {
      type: file.type,
      lastModified: file.lastModified,
    });

    const formData = new FormData();
    formData.append(newFile.name, newFile);

    // Upload Image
    makePostRequest("admin/organization/" + organizationId + "/image/?sharable=true", formData, configWithAuth('multipart/form-data'))
      .then(result => {
        setDisplayedFileReference(result.data);

        // Get uploaded Image.
        makeGetRequest("admin/organization/" + organizationId + "/image/file/reference/" + result.data.id + "/base64", configWithAuth())
          .then(result => {

            // Storing the uploaded Image.
            setDisplayedImageFile(result.data);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSaveFileAssetClicked = () => {

    let organizationId = props.organization.selectedOrganization;
    let fileReferenceId = displayedFileReference.id;
    let assetType = assetTypeSelected;

    props.dispatch(organizationActions.updateOrganizationAsset(organizationId, fileReferenceId, assetType));
  };

  const handleDeleteMainMenuContentClicked = () => {
    let organizationId = props.organization.selectedOrganization;
    let organizationFileAssetId = organizationMainMenuContent[selectedMainMenuContentIdx].id;

    props.dispatch(organizationActions.deleteOrganizationAsset(organizationId, organizationFileAssetId, OrganizationFileAssetTypes.MainMenu));
  };

  const handleDeleteReviewContentClicked = () => {
    let organizationId = props.organization.selectedOrganization;
    let organizationFileAssetId = organizationReviewContent[selectedReviewContentIdx].id;

    props.dispatch(organizationActions.deleteOrganizationAsset(organizationId, organizationFileAssetId, OrganizationFileAssetTypes.Review));
  };

  const handleUpdateOrganizationFileAssetWithFileReference = () => {
    let type = OrganizationFileAssetLoadTypes.FileReference;
    setFileChooserType(type);
    const organizationId = props.organization.selectedOrganization;
    if (!loadingFileReferences)
    {
      setLoadingFileReferences(true)
      makeGetRequest(`admin/organization/${organizationId}/image/file/references`, configWithAuth())
        .then(result => {
          setFileReferences(result.data);
          setLoadingFileReferences(false);
        })
        .catch(error => {
          console.log(error);
          setLoadingFileReferences(false);
        });
    }
  }

  return (
    <div className={classes.root} style={{padding: '20px'}}>

      <OrganizationOverviewSidebar
        path={props.computedMatch?.path}
      />

      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7}>
            <Card>
              <CardHeader
                title={<FormattedMessage id="admin.edit.org.orgUploadOrganizationAsset"
                                         defaultMessage="Upload Organization Asset"/>}
                subheader={<FormattedMessage id="admin.edit.org.orgUploadOrganizationAssetHeader"
                                             defaultMessage="Upload an asset to the organization by dropping it here."/>}
              />
              <CardContent>
                <ButtonGroup className={classes.buttongroupSection}>
                  <Button
                    onClick={() => setFileChooserType(OrganizationFileAssetLoadTypes.Computer)}
                    style={ fileChooserType === OrganizationFileAssetLoadTypes.FileReference ?{ backgroundColor: 'white', fontColor: 'black', color: 'black'}: {backgroundColor: '#143349', fontColor: 'white', color: 'white'}}
                    variant={fileChooserType === OrganizationFileAssetLoadTypes.FileReference ? "contained": "outlined"}>
                    Upload from computer
                  </Button>
                  <Button
                    onClick={handleUpdateOrganizationFileAssetWithFileReference}
                    style={ fileChooserType === OrganizationFileAssetLoadTypes.Computer ?{ backgroundColor: 'white', fontColor: 'black', color: 'black'}: {backgroundColor: '#143349', fontColor: 'white', color: 'white'}}
                    variant='outlined'>
                    Choose from library
                  </Button>
                </ButtonGroup>

                {
                  fileChooserType === OrganizationFileAssetLoadTypes.Computer ?
                    <Dropzone
                      accept="image/jpeg, 
                                                image/png"
                      onDrop={onDrop}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div {...getRootProps()}
                               className={classes.dropZone}
                          >
                            <input {...getInputProps()} />
                            <Add className={classes.uploadIcon}/>
                            <Typography color='inherit' component={"span"}
                                        variant="body1"
                                        align="center">
                              <FormattedMessage id="admin.edit.org.image.droporclick"
                                                defaultMessage="Drop or click to add a logo for the organization."/>
                            </Typography>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    :
                    <div className={classes.divList}
                         style={{maxHeight: 200, overflow: 'auto'}}>
                      <List component="nav">
                        {
                          fileReferences &&
                          fileReferences.length > 0 &&
                          fileReferences.map(
                            (file, index) => (
                              <ListItem
                                key={index}
                                value={file}
                                selected={selectedFileReferenceIdx === index}
                                onClick={() => {
                                  console.log("Clicked list index: " + index);
                                  onFileReferenceSelected(index);
                                }}>
                                <Typography variant='body1' className={classes.nameyourimage}>
                                  {file.niceName}
                                </Typography>
                              </ListItem>
                            )
                          )
                        }
                      </List>
                    </div>
                }
                {
                  displayedImageFile &&
                  <div key={displayedImageFile}>
                    <img src={displayedImageFile} height={160}/>
                  </div>
                }

                <h3>
                  Choose what type of organization asset you want the image to be.
                </h3>

                <ToggleButtonGroup value={assetTypeSelected}>
                  {
                    Object.keys(OrganizationFileAssetTypes).map(
                      (radio, idx) => (
                        idx > 0 &&
                        <ToggleButton
                          key={idx}
                          id={`radio-${idx}`}
                          name="radio"
                          value={idx}
                          onClick={() => setAssetTypeSelected(idx)}
                          checked={assetTypeSelected === idx}
                          defaultChecked={idx === 1}
                        >
                          {radio}
                        </ToggleButton>
                      )
                    )
                  }
                </ToggleButtonGroup>

                <CardActions>
                  <Button
                    size="large"
                    onClick={handleSaveFileAssetClicked}
                    style={{backgroundColor: '#143349', fontColor: 'white', color: 'white'}}
                    variant={"contained"}
                    color={"primary"}
                    disabled={
                      (fileChooserType === OrganizationFileAssetLoadTypes.FileReference && fileReferences[selectedFileReferenceIdx] === undefined) ||
                      (fileChooserType === OrganizationFileAssetLoadTypes.Computer && displayedImageFile === '')
                    }
                  >
                    <FormattedMessage id="globalwords.saveOrgAsset" defaultMessage="Save"/>
                  </Button>
                </CardActions>

              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <Card>
              <CardHeader
                title={<FormattedMessage id="admin.edit.org.orgCurrentOrganizationLogo"
                                         defaultMessage="Current Organization Logo"/>}
                subheader={<FormattedMessage id="admin.edit.org.orgCurrentOrganizationLogoHeader"
                                             defaultMessage="Look how fine it is."/>}
              />
              <CardContent>

                {
                  loadingLogoContent  ?
                    <CircularProgress size={100} />
                    :
                    organizationLogoContent &&
                    <div key={organizationLogoContent}>
                      <img src={organizationLogoContent} height={160}/>
                    </div>
                }

              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          <Card>
            <CardHeader
              title={<FormattedMessage id="admin.edit.org.orgMainMenuImages"
                                       defaultMessage="Main Menu Backgrounds"/>}
              subheader={<FormattedMessage id="admin.edit.org.orgMainMenuImagesHeader"
                                           defaultMessage="The list of selected main menu background images."/>}
            />
            <CardContent>
              {
                loadingOrganizationMainMenuContent ?
                  <CircularProgress size={100} />
                  :
                  <MenuList style={flexContainer}>
                    {
                      organizationMainMenuContent?.map(
                        (data, idx) =>
                          data.image &&
                          <MenuItem
                            key={data.id}
                            selected={idx === selectedMainMenuContentIdx}
                            onClick={() => setSelectedMainMenuContentIdx(idx)}>
                            <img src={data.image} height={60} />
                          </MenuItem>
                      )
                    }
                  </MenuList>
              }
            </CardContent>
            <CardActions>
              <Button
                size="large"
                onClick={handleDeleteMainMenuContentClicked}
                style={{backgroundColor: '#143349', fontColor: 'white', color: 'white'}}
                variant={"contained"}
                color={"primary"}
                disabled={
                  loadingOrganizationMainMenuContent == null ||
                  organizationMainMenuContent.length === 0
                }
              >
                <FormattedMessage id="globalwords.deleteMainMenuOrgAsset" defaultMessage="Delete"/>
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          <Card>
            <CardHeader
              title={<FormattedMessage id="admin.edit.org.orgReviewImages"
                                       defaultMessage="Review Screen Backgrounds"/>}
              subheader={<FormattedMessage id="admin.edit.org.orgReviewImagesHeader"
                                           defaultMessage="The list of selected review screen background images."/>}
            />
            <CardContent>
              {
                <MenuList style={flexContainer}>
                  {
                    loadingOrganizationReviewContent ?
                      <CircularProgress size={100} />
                      :
                      organizationReviewContent?.map(
                        (data, idx) =>
                          data.image &&
                          <MenuItem key={data.id}
                                    selected={idx === selectedReviewContentIdx}
                                    onClick={() => setSelectedReviewContentIdx(idx)}>
                            <img src={data.image} height={60} />
                          </MenuItem>
                      )
                  }
                </MenuList>
              }
            </CardContent>
            <CardActions>
              <Button
                size="large"
                onClick={handleDeleteReviewContentClicked}
                style={{backgroundColor: '#143349', fontColor: 'white', color: 'white'}}
                variant={"contained"}
                color={"primary"}
                disabled={
                  loadingOrganizationReviewContent == null ||
                  organizationReviewContent.length === 0
                }
              >
                <FormattedMessage id="globalwords.deleteReviewOrgAsset" defaultMessage="Delete"/>
              </Button>
            </CardActions>
          </Card>
        </Grid>

      </Grid>
    </div>
  );
}


EditOrganizatinoImageAssets.propTypes = {
};

const mapStateToProps = (state) => {
  const { organization } = state;
  return {
    organization
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganizatinoImageAssets)
