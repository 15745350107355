import React, {useEffect, useState} from 'react';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Fab,
  Menu,
  MenuItem, makeStyles, useTheme
} from '@material-ui/core'
import Divider from "@material-ui/core/Divider";
import {connect} from 'react-redux';
import {topicActions, authActions} from '_state/actions';
import {FormattedMessage} from 'react-intl';
import TextFieldWithLanguageSelector from "../Scenarios/ScenarioComponents/TextFieldWithLanguageSelector";
import {CapeeshColors} from "../../../assets/ColorPalette";

import {makeGetRequest, makePostRequest} from "../../../_state/helpers/api";
import {configWithAuth} from "../../../_state/helpers";
import {history} from "_state/helpers";
import ImageUploadWithCrop from "../../../Components/ImageUpload/ImageUploadWithCrop";
import {ImageCropType} from "../../../Logic/CourseGroupConstants";
import CapeeshStyledModal from "../../../Components/CapeeshStyledModal";

const useStyles = makeStyles(theme => ({
  cardContent: {
    minHeight: '400px',
    position: 'relative',
    overflowY: 'scroll',
  },

  wordCloud: {
    textAlign: 'center',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  loader: {
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    alignText: 'center',
    alignItems: 'center',
    height: '200px',
  },
  nameField: {
    width: '300px',
  },
  descriptionField: {
    width: '300px',
  },
  languageForm: {
    marginTop: '15px',
    width: '200px',
  },
  texts: {
    marginTop: '10px',
  },
  classrooms: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  mainleft: {
    marginTop: '20px',
  },
  nameInput: {
    color: theme.palette.primary.main,
  },
  center: {
    margin: 'auto auto',
    display: 'block',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  cardActionArea: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    padding: '10px',
  },
  cardAction: {
    color: '#cdcdcd',
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    float: 'right',
    transition: 'all .25s ease',
    '&:hover': {
      color: '#262626',
      background: 'rgba(0, 0, 0, 0.0)',
    },
  },
  dropZone: {
    position: 'relative',
    height: '100px',
    color: '#ccc',
    border: 'dashed 2px #ccc',
    borderRadius: '15px',
    backgroundColor: '#f9f9f9',
    margin: '15px auto',
    padding: 15,
    transition: 'all 200ms ease-out',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '200px',
    display: 'block',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  languageSelectorItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));


const CreatorTopicMetadata = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {user, organization, topic, computedMatch, usergroup, metadata, newCourse, auth, topicData,
    submitText = <FormattedMessage id="globalwords.save1" defaultMessage="Save"/>} = props;

  const [subjectName, setSubjectName] = useState('');
  const [description, setDescription] = useState('');
  
  const [language, setLanguage] = useState('');
  const [defaultL1Language, setDefaultL1Language] = useState('');
  const [editMode, setEditMode] = useState(false);
  
  const [hasAdminRights, setHasAdminRights] = useState(false);
  const [anchorSettingsMenu, setAnchorSettingsMenu] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  
  // image upload
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [topicImageData, setTopicImageData] = useState(false);
  
  useEffect(() => {
    if (topicData?.imageFileReferenceId) {
      makeGetRequest(`admin/organization/${organization.selectedOrganization}/image/file/reference/${topicData.imageFileReferenceId}/base64`, configWithAuth())
        .then(result => {
          setTopicImageData(result.data);
        })
        .catch(error => {
          console.log(error);
        });
    }

  }, [])

  useEffect(() => {
    if (topicData && user.organizationRole === "admin" ) {
      setLanguage(topicData.l2Id);
      setDefaultL1Language(topicData.defaultL1Id);
      setEditMode(true);
    }
    
    user.info.roles.forEach(role => {
      if(role.organizationId == organization.selectedOrganization && role.role === "Admin")
        setHasAdminRights(true);
    });

    if( subjectName === "" && topic.loadingCurrentTopic === false) {
      setSubjectName(topicData.name);
      setDescription(topicData.description);
    }
    
    if(topicData){
      setLanguage(topicData.l2Id);
      setDefaultL1Language(topicData.defaultL1Id);
    }
  }, [topic]);

  const deleteTopic = () => {
    props.deleteTopic(organization.selectedOrganization, topicData.id);
    setDeleteConfirmation(false);

    //TODO: MORTEN show course group instead?
    if (computedMatch?.params?.courseGroupId) {
      history.push('/creator/course/group/' + computedMatch.params.courseGroupId);
    } else {
      history.push("/creator/");
    }
  };

  const handleSubmitForm = () => {
    props.updateTopic(organization.selectedOrganization, topicData.id, subjectName, topicData.topWordsCount, description, topicData.tags, topicData.lessonsOnly, null);
  };


  const handleCancel = () => {
    setAnchorSettingsMenu(null);
    setDeleteConfirmation(false);
  };

  const onDrop = (fileUploadResult) => {
    
    let organizationId = organization.selectedOrganization;
    
    const formData = new FormData();
    formData.append(fileUploadResult.imageFileName, fileUploadResult.image);
    
    // Upload Image
    makePostRequest("admin/organization/" + organizationId + "/image/?sharable=true", formData, configWithAuth('multipart/form-data'))
      .then(result => {
        const imageFileReferenceId = result.data["id"]
        props.updateTopic(organization.selectedOrganization, topicData.id, subjectName, topicData.topWordsCount, description, topicData.tags, topicData.lessonsOnly, imageFileReferenceId);

        // Get uploaded Image.
        makeGetRequest("admin/organization/" + organizationId + "/image/file/reference/" + imageFileReferenceId + "/base64", configWithAuth())
          .then(result => {
            setTopicImageData(result.data)
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
    
    setShowImageUpload(false);
  };

  const handleClickedSettingsMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setAnchorSettingsMenu(event.currentTarget);
  };

  const handleCloseMenus = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setAnchorSettingsMenu(null);
  };

  let title = <FormattedMessage id="cap.courses.coursegeneral.newcourse" defaultMessage="New Course"/>;


  return (
    <Grid container >
      {
        showImageUpload &&
        <ImageUploadWithCrop
          open={showImageUpload}
          updateImageDrop={onDrop}
          aspectRatioType={ImageCropType.Course}
          onCancel={() => setShowImageUpload(false)}
          title={"New topic image"}
        />
      }
      <Grid item xs={12}>
        <div style={{paddingeLeft: '20px'}}>
          <Typography
            title={title}
          />
          <form noValidate
                autoComplete="off"
                onSubmit={handleSubmitForm}
          >
           
              
            <Grid container>
                <Grid container>
                    <Grid item xs={12} sm={12} >
                      <Typography
                        xs={12}
                        autoWidth
                        variant='body1'
                        style={{
                          fontFamily: 'Lato',
                          color: 'gray',
                        }}>
                        Topic name
                      </Typography>
                      
                      <TextFieldWithLanguageSelector
                        text={subjectName || ''}
                        onChange={(event) => {
                          setSubjectName(event.target.value);
                          props.updateLocalCourseName(event.target.value);
                        }}
                        multiline={true}
                        l2Id={null}
                        l1Id={null}
                        soloLanguageId={topicData.l2Id}
                        canChangeLanguage={false}
                      />
                      
                    </Grid>
                  
                    {
                      !newCourse && (user.organizationRole === "admin") && (auth.teacherDebug === 0 || auth.teacherDebug === 1) &&
                      <Grid item xs={12} sm={12} className={classes.texts}>
                        <Typography
                          xs={12}
                          autoWidth
                          variant='body1'
                          style={{
                            marginTop: '10px',
                            fontFamily: 'Lato',
                            color: 'gray',
                          }}>
                          Topic description
                        </Typography>
                        <TextFieldWithLanguageSelector
                          text={description || ''}
                          onChange={(event) => {
                            setDescription(event.target.value);
                          }}
                          multiline={true}
                          l2Id={null}
                          l1Id={null}
                          soloLanguageId={topicData.l2Id}
                          canChangeLanguage={false}
                        />
                    </Grid>
                    }
                </Grid>

              </Grid>
          </form>
        </div>
        <Grid xs={12}>
          <Button 
            style={{textTransform: 'none', float: 'left', background:CapeeshColors.Green, marginTop: '40px', marginBottom: '20px', color: 'white'}}
            variant={"contained"}
            disabled={( (topicData.name === subjectName && topicData.description === description) && (user.organizationRole !== "admin" || user.organizationRole !== "teacher"))}
            onClick={() => handleSubmitForm()}>
              Save changes                
          </Button>
        </Grid>
      </Grid>

      <CapeeshStyledModal
        open={deleteConfirmation}
        textTitle={"Delete the topic?"}
        textLine1={"Are you sure you wish to delete the topic? This cannot be undone."}
        onButton2={() => deleteTopic()}
        onButton1={() => handleCancel()}
        button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel"/>}
        button1Variant={'outlined'}
        button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes, delete"/>}
        onClose={() => handleCancel()}
      />
    </Grid>
  );
};

CreatorTopicMetadata.propTypes = {};

const mapToProps = (state) => {
  return {
    organization: state.organization,
    topic: state.topic,
    user: state.user,
    metadata: state.metadata,
    auth: state.auth,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUserGroupsForTopic: (topicId) => dispatch(topicActions.getAllUserGroupsForTopic(topicId)),
    deleteTopic: (organizationId, topicId) => dispatch(topicActions.deleteTopic(organizationId, topicId)),
    teacherDebug: (teacherDebug) => dispatch(authActions.teacherDebug(teacherDebug)),
    updateLocalCourseName: (name) => dispatch(topicActions.updateLocalCourseName(name)),
    updateTopic: (organizationId, topicId, name, topWordsCount, description, tags, lessonsOnly, imageFileReferenceId) => dispatch(topicActions.updateTopic(organizationId, topicId, name, topWordsCount, description, tags, lessonsOnly, imageFileReferenceId)),
  }
};

export default connect(mapToProps, mapDispatchToProps)(CreatorTopicMetadata)