import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Button,
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText, Typography,
} from '@material-ui/core';
import {
    MultilineChart,
    Group,
    School,
    Settings,
} from '@material-ui/icons';
import { makeStyles} from '@material-ui/core/styles';
import {AutoGraph} from "@mui/icons-material";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PaymentIcon from '@mui/icons-material/Payment';

import {FormattedMessage} from "react-intl";
import {CapeeshColors} from "../../../../assets/ColorPalette";
import ClassIcon from '@mui/icons-material/Class';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import {UserStatisticsVisibility} from "../../CapeeshConstants";

const useStyles = makeStyles(theme => ({
    menu: {
        position: 'fixed',
        left: '0px',
        top: '120px',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        width: '15%',
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '60px',
        },
        "& .Mui-selected": {
            backgroundColor: CapeeshColors.Pink,
            color: 'white',
            borderRadius: '10px'
        },
    },
    menuItem: {
        margin: '5px',
        [theme.breakpoints.down('sm')]: {
            margin: '5px 0px',
        },
    },
}));

const SidebarOptions =
  {
      "Dashboard": 0,
      "Classrooms": 1,
      "Courses": 2,
      "Students": 3,
      "Statistics": 4,
      "Licenses": 5,
      "Administrators": 6,
      "Billing": 7,
      "AdminEditInfo": 8,
      "AdminCourse": 9,
      "AdminAdmins": 10,
      "AdminImageAssets": 11,
  }


const OrganizationOverviewSidebar = (props) => {
    const classes = useStyles();
    
    const [shouldShowStudents, setShouldShowStudents] = useState(false);
    const [canEditOrganization, setCanEditOrganization] = useState(false);
    const [canEditAdministrators, setCanEditAdministrators] = useState(false);
        
    const [canShowCapeeshAdminPages, setCanShowCapeeshAdminPages] = useState(false);
    const [canShowLicenses, setCanShowLicenses] = useState(false);
    
    const [canShowOrgnizationStatistics, setCanShowOrgnizationStatistics] = useState(false);
    
    const [currentSelectedSideMenu, setCurrentSelectedSideMenu] = useState(null);
    
    useEffect(() => {

        let currentOrganization = props.organization.myOrganizations.find(x => x.id === props.organization.selectedOrganization);
        
        if (props.user.organizationRole === "reporting" && props.user.classroomOnlyReportingUser === true) {
            setShouldShowStudents(true);
            setCanEditOrganization(false);
            setCanEditAdministrators(false);
            setCanShowCapeeshAdminPages(false);
            setCanShowLicenses(false);
            setCanShowOrgnizationStatistics(false);
            
        } else if (props.user.organizationRole === "reporting" && props.user.classroomOnlyReportingUser === false) {

            if (currentOrganization?.userStatisticsVisibility === UserStatisticsVisibility.Strict) {
                setShouldShowStudents(false);
            } else {
                setShouldShowStudents(true);
            }
            setCanEditOrganization(true);
            setCanEditAdministrators(true);
            setCanShowCapeeshAdminPages(false);
            setCanShowLicenses(true);
            setCanShowOrgnizationStatistics(true);
            
        } else if (props.user.organizationRole === "admin") {
            setShouldShowStudents(true);
            setCanEditOrganization(true);
            setCanEditAdministrators(true);
            setCanShowCapeeshAdminPages(true);
            setCanShowLicenses(true);
            setCanShowOrgnizationStatistics(true);
            
        } else if (props.user.organizationRole === "teacher") {
            setShouldShowStudents(true);
            setCanEditOrganization(false);
            setCanEditAdministrators(false);
            setCanShowCapeeshAdminPages(false);
            setCanShowLicenses(false);
            setCanShowOrgnizationStatistics(false);
            
        } else if (props.user.organizationRole === "qa") {
            setShouldShowStudents(false);
            setCanEditOrganization(false);
            setCanEditAdministrators(false);
            setCanShowCapeeshAdminPages(false);
            setCanShowLicenses(false);
            setCanShowOrgnizationStatistics(false);
        }

        if (props.path) {
            switch (props.path) {
                case "/dashboard/":
                    setCurrentSelectedSideMenu(SidebarOptions.Dashboard)
                    break;
                case "dashboard/dashboard":
                    setCurrentSelectedSideMenu(SidebarOptions.Dashboard)
                    break;
                case "/dashboard/classrooms":
                    setCurrentSelectedSideMenu(SidebarOptions.Classrooms)
                    break;
                case "/dashboard/courses":
                    setCurrentSelectedSideMenu(SidebarOptions.Courses)
                    break;
                case "/dashboard/students":
                    setCurrentSelectedSideMenu(SidebarOptions.Students)
                    break;
                case "/dashboard/statistics":
                    setCurrentSelectedSideMenu(SidebarOptions.Statistics)
                    break;
                case "/dashboard/licenses":
                    setCurrentSelectedSideMenu(SidebarOptions.Licenses)
                    break;
                case "/dashboard/admins":
                    setCurrentSelectedSideMenu(SidebarOptions.Administrators)
                    break;
                case "/dashboard/settings":
                    setCurrentSelectedSideMenu(SidebarOptions.Billing)
                    break;
                case "/dashboard/admin/editorg":
                    setCurrentSelectedSideMenu(SidebarOptions.AdminEditInfo)
                    break;
                case "/dashboard/admin/organization/courses":
                    setCurrentSelectedSideMenu(SidebarOptions.AdminCourse)
                    break;
                case "/dashboard/admin/organization/admins":
                    setCurrentSelectedSideMenu(SidebarOptions.AdminAdmins)
                    break;
                case "/dashboard/admin/organization/assets":
                    setCurrentSelectedSideMenu(SidebarOptions.AdminImageAssets)
                    break;
            }
        }
        
       
        
        
    }, []);
    
    
    const getSideMenuListForUser = () => {
        return (
            <MenuList className={classes.menu}>
                <MenuItem selected={currentSelectedSideMenu === SidebarOptions.Dashboard} className={classes.menuItem} button key='dashboard' component={Link} to={'/dashboard/'}>
                    <ListItemIcon><MultilineChart/></ListItemIcon>
                    <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.dashboard" defaultMessage="Dashboard"/>}/>
                </MenuItem>

                <MenuItem selected={currentSelectedSideMenu === SidebarOptions.Classrooms}  className={classes.menuItem} button key='classrooms' component={Link} to={'/dashboard/classrooms/'}>
                    <ListItemIcon><ClassIcon/></ListItemIcon>
                    <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.classrooms" defaultMessage="Classrooms"/>}/>
                </MenuItem>

                <MenuItem selected={currentSelectedSideMenu === SidebarOptions.Courses} className={classes.menuItem} button key='courses' component={Link} to={'/dashboard/courses/'}>
                    <ListItemIcon><School/></ListItemIcon>
                    <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.courses" defaultMessage="Courses"/>}/>
                </MenuItem>
                
                {
                    shouldShowStudents &&
                    <MenuItem selected={currentSelectedSideMenu === SidebarOptions.Students} className={classes.menuItem} button key='students' component={Link} to={'/dashboard/students/'}>
                        <ListItemIcon><Group/></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.students" defaultMessage="Students"/>}/>
                    </MenuItem>
                }
                
                {
                    canShowOrgnizationStatistics &&
                    <MenuItem selected={currentSelectedSideMenu === SidebarOptions.Statistics} className={classes.menuItem} button key='statistics' component={Link} to={'/dashboard/statistics/'}>
                      <ListItemIcon><AutoGraph/></ListItemIcon>
                      <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.statistics" defaultMessage="Statistics"/>}/>
                    </MenuItem>
                }

                {
                    canShowLicenses &&
                    <MenuItem selected={currentSelectedSideMenu === SidebarOptions.Licenses} className={classes.menuItem} button key='licenses' component={Link} to={'/dashboard/licenses/'}>
                      <ListItemIcon><PeopleAltIcon/></ListItemIcon>
                      <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.licenses" defaultMessage="Licenses"/>}/>
                    </MenuItem>
                }
                
                {
                    canEditAdministrators &&
                    <MenuItem selected={currentSelectedSideMenu === SidebarOptions.Administrators} className={classes.menuItem} button key='admins' component={Link} to={'/dashboard/admins/'}>
                        <ListItemIcon><Settings/></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.administrators" defaultMessage="Administrators"/>}/>
                    </MenuItem>
                }

                {
                    canEditOrganization &&
                    <MenuItem selected={currentSelectedSideMenu === SidebarOptions.Billing} className={classes.menuItem} button key='settings' component={Link} to={'/dashboard/settings/'}>
                        <ListItemIcon><PaymentIcon/></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.settings" defaultMessage="Billing"/>}/>
                    </MenuItem>
                }
                
                
                {
                  canShowCapeeshAdminPages &&
                  <Typography style={{marginLeft:'10px', fontWeight: 'bold', marginTop:'35px'}}>
                      Capeesh Admins
                  </Typography>
                }
                {
                  canShowCapeeshAdminPages &&
                  <MenuItem selected={currentSelectedSideMenu === SidebarOptions.AdminEditInfo} style={{ marginTop:'10px', color: CapeeshColors.OrangeBright}} className={classes.menuItem} button key='admin.editorg' component={Link} to={'/dashboard/admin/editorg/'}>
                      <ListItemIcon style={{color: CapeeshColors.OrangeBright}}><PaymentIcon/></ListItemIcon>
                      <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.edit" defaultMessage="Edit info"/>}/>
                  </MenuItem>
                }
                {
                  canShowCapeeshAdminPages &&
                  <MenuItem selected={currentSelectedSideMenu === SidebarOptions.AdminCourse} style={{ color: CapeeshColors.OrangeBright}} className={classes.menuItem} button key='admin.editcourses' component={Link} to={'/dashboard/admin/organization/courses/'}>
                      <ListItemIcon style={{color: CapeeshColors.OrangeBright}}><School/></ListItemIcon>
                      <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.courses" defaultMessage="Courses"/>}/>
                  </MenuItem>
                }
                {
                  canShowCapeeshAdminPages &&
                  <MenuItem selected={currentSelectedSideMenu === SidebarOptions.AdminAdmins} style={{ color: CapeeshColors.OrangeBright}} className={classes.menuItem} button key='admin.admins' component={Link} to={'/dashboard/admin/organization/admins/'}>
                      <ListItemIcon style={{color: CapeeshColors.OrangeBright}}><Settings/></ListItemIcon>
                      <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.admins" defaultMessage="Admins"/>}/>
                  </MenuItem>
                }
                {
                  canShowCapeeshAdminPages &&
                  <MenuItem selected={currentSelectedSideMenu === SidebarOptions.AdminImageAssets} style={{ color: CapeeshColors.OrangeBright}} className={classes.menuItem} button key='admin.editassets' component={Link} to={'/dashboard/admin/organization/assets/'}>
                      <ListItemIcon style={{color: CapeeshColors.OrangeBright}}><ImageSearchIcon/></ListItemIcon>
                      <ListItemText primary={<FormattedMessage id="dashboard.org.sidemenu.assets" defaultMessage="Image Assets"/>}/>
                  </MenuItem>
                }    
                
            </MenuList>
        )
    }

    return (
        getSideMenuListForUser()
    );
};

OrganizationOverviewSidebar.propTypes = {
};

const mapStateToProps = (state) => {
    const {user, organization} = state;
    return {
        user,
        organization,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationOverviewSidebar)